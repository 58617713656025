import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import TransferUserContentFormValues from 'domain/user/transferContent/formValues';

export default function useTransferUserContent() {
  const transferContentRequest = useHttpPutRequest('/privates/user/transfer');
  const { mutateAsync } = useHttpMutation(transferContentRequest);

  return async function transferUserContent(
    sourceUser: string,
    { selectedUser: destinationUser, ...transferContentSettings }: TransferUserContentFormValues
  ) {
    return mutateAsync({
      sourceUser,
      destinationUser,
      ...transferContentSettings,
    });
  };
}
