import React from 'react';

import StoresLink from 'app/common/components/legacyNavMenu/menu/network/links/stores';
import LibraryLink from 'app/common/components/legacyNavMenu/menu/network/links/library';
import SurveyLink from 'app/common/components/legacyNavMenu/menu/network/links/survey';
import ReportLink from 'app/common/components/legacyNavMenu/menu/network/links/report';
import SharedDocumentLink from 'app/common/components/legacyNavMenu/menu/network/links/sharedDocument';
import useCurrentApplicationModule from 'app/common/hooks/useCurrentApplicationModule';
import NavLinks from 'app/common/components/legacyNavMenu/navLinks';
import { APP_NETWORK } from 'app/common/ui/constants';
import useIsNetworkMenuVisible from 'app/common/hooks/legacyNavMenu/useIsNetworkMenuVisible';

export default function NavRetailNetwork() {
  const isVisible = useIsNetworkMenuVisible();
  const module = useCurrentApplicationModule();

  if (!isVisible || module !== APP_NETWORK) {
    return null;
  }

  return (
    <NavLinks>
      <StoresLink />
      <LibraryLink />
      <SurveyLink />
      <ReportLink />
      <SharedDocumentLink />
    </NavLinks>
  );
}
