import moment from 'moment';
import i18next from 'i18next';

const unitObjectToString = obj => {
  const value = obj?.value;
  const unit = i18next.t(`units.${obj?.unit}`);
  if (value != null && unit != null) {
    return `${value.toLocaleString(i18next.language)} ${unit}`;
  }

  return '';
};

const currencyObjectToString = obj => {
  const value = obj?.value;
  const currency = obj?.currency;
  if (value != null && currency != null) {
    return new Intl.NumberFormat(i18next.language, { style: 'currency', currency }).format(value);
  }

  return '';
};

export default function format(value, formatType, lng) {
  if (value instanceof Date) {
    switch (formatType) {
      case 'numerical':
        return moment(value).locale(lng).format('L');
      case 'numericalUtc':
        return moment.utc(value).locale(lng).format('L');
      case 'relative':
        return moment(value).locale(lng).fromNow();
      default:
        return moment(value).locale(lng).format(formatType);
    }
  } else {
    switch (formatType) {
      case 'formatUnit':
        return unitObjectToString(value);
      case 'formatCurrency':
        return currencyObjectToString(value);
      case 'formatNumber':
        return value.toLocaleString(lng);
      default:
        return value;
    }
  }
}
