import ScrollableList from 'app/display/common/components/list/scrollable';
import React from 'react';
import { GuidelineTag } from 'domain/guideline/tag';
import { BodyText2 } from 'app/common/typography';
import i18next from 'i18next';
import styles from './index.css';

export const GUIDELINES_TAG_LIST = 'guidelineTagList';

type GuidelinesTagListBodyProps = {
  toggleTagSelection: (id: string) => void;
  list?: GuidelineTag[];
  selectedTagIds?: string[];
};

export default function GuidelinesTagListBody({
  toggleTagSelection,
  list = [],
  selectedTagIds = [],
}: GuidelinesTagListBodyProps) {
  return (
    <div className={styles.tagListBody} data-testid={GUIDELINES_TAG_LIST}>
      <ScrollableList>
        {list.length === 0 ? (
          <div className={styles.row}>
            <div className={styles.leftColumn}>
              <BodyText2>{i18next.t('network.guidelines.tag.list.body.noResults')}</BodyText2>
            </div>
          </div>
        ) : (
          list.map(guidelineTag => {
            const checked = selectedTagIds.some(id => guidelineTag.id === id);
            return (
              <div id={`guidelineTag-${guidelineTag.id}`} className={styles.row} key={guidelineTag.id}>
                <div className={styles.leftColumn}>
                  <input
                    type="checkbox"
                    name={`guideline-tag-${guidelineTag.id}`}
                    id={`guideline-tag-${guidelineTag.id}`}
                    className="checkbox"
                    checked={checked}
                    onChange={() => toggleTagSelection(guidelineTag.id)}
                  />
                  <label className={styles.label} htmlFor={`guideline-tag-${guidelineTag.id}`}>
                    {guidelineTag.name}
                  </label>
                </div>
                <div className={styles.rightColumn}>{guidelineTag.usage.guideline}</div>
              </div>
            );
          })
        )}
      </ScrollableList>
    </div>
  );
}
