import { useMemo } from 'react';
import first from 'lodash/first';
import useLicenseData from 'app/directory/user/sheet/common/hooks/useLicenseData';
import useLicenseProjects from 'app/directory/user/sheet/common/hooks/useLicenseProjects';
import { USER_SHEET_LICENSE_TAB_FORM_INITIAL_VALUES } from 'app/directory/user/sheet/common/constants';

export default function useEditUserFormInitialValues(userId: string) {
  const { licenseData, isLoading: isLoadingLicenseData } = useLicenseData(userId);
  const { projects, isLoading: isLoadingProjects } = useLicenseProjects();

  const isLoading = isLoadingLicenseData || isLoadingProjects;

  return useMemo(() => {
    if (isLoading) {
      return {
        initialValues: undefined,
        isLoading,
      };
    }

    if (!licenseData) {
      return {
        initialValues: {
          ...USER_SHEET_LICENSE_TAB_FORM_INITIAL_VALUES,
          'license-projectId': projects.length === 1 ? first(projects)?.projectId : null,
        },
        isLoading,
      };
    }

    return {
      initialValues: {
        'license-projectId':
          licenseData?.license?.projectId || (projects.length === 1 ? first(projects)?.projectId : null),
        'license-serviceCode': licenseData?.license?.serviceCode,
        createRole: licenseData?.createRole,
        executeRole: licenseData?.executeRole,
        has2d: licenseData?.has2d,
        has3d: licenseData?.has3d,
      },
      isLoading,
    };
  }, [licenseData, isLoading, projects]);
}
