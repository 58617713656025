import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import loadConfig from 'app/common/actions/iwdConfig';
import fetchCurrentUser from 'app/common/user/actions/fetchCurrentUser';
import fetchCurrentUserACL from 'app/common/user/actions/fetchCurrentUserACL';

export default function useFetchAppShellData(): boolean {
  const dispatch = useDispatch();
  const [isAppShellReady, setIsAppReady] = useState(false);

  const fetchData = useCallback(async () => {
    await dispatch(loadConfig());

    await Promise.all([dispatch(fetchCurrentUser()), dispatch(fetchCurrentUserACL())]);

    setIsAppReady(true);
  }, [dispatch, setIsAppReady]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isAppShellReady;
}
