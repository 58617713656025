export default class WebSocketClient {
  wss: WebSocket | undefined;

  private static instance: WebSocketClient;

  static getInstance(socketUrl: string): WebSocketClient {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new WebSocketClient(socketUrl);
    return this.instance;
  }

  constructor(socketUrl: string) {
    this.wss = new WebSocket(socketUrl);
  }

  onOpen(callback: () => void): void {
    if (this.wss) {
      this.wss.onopen = callback;
    }
  }

  onError(callback: () => void): void {
    if (this.wss) {
      this.wss.onerror = callback;
    }
  }

  onMessage(callback: (message: MessageEvent) => any): void {
    if (this.wss) {
      this.wss.onmessage = callback;
    }
  }

  sendMessage(data: string) {
    this.wss?.send(data);
  }
}
