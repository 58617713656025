import qs from 'query-string';

import { createSelector } from 'reselect';
import { getCurrentLocation } from 'app/common/selectors/router';
import { AppState } from 'lib/providers/store';

export const ORDER_ASC = 'asc';
export const ORDER_DESC = 'desc';

export const SORT_PARAMETER_NAME = 'sort';
export const ORDER_PARAMETER_NAME = 'order';

export const getCurrentSearch = (state: AppState): string => {
  const location = getCurrentLocation(state);
  return location.search;
};

const getParameterName = (state: AppState, name: string): string => name;

export const getParameters = createSelector([getCurrentSearch], search => {
  return qs.parse(search, { arrayFormat: 'bracket' });
});

export const getUrlParameterByName = createSelector([getParameters, getParameterName], (parameters, name) => {
  if (name == null) {
    throw new Error('A name is required in getUrlParameterByName selector');
  }

  if (parameters[name] != null) {
    return parameters[name];
  }

  return undefined;
});
