function getCurrentUserAcl(state) {
  return state.currentUser.acl.rights;
}

function getCurrentUserAclSection(state, section) {
  const rights = getCurrentUserAcl(state);

  return rights[section];
}

function getCurrentUserAclSubSection(state, section, subSection) {
  const sectionObject = getCurrentUserAclSection(state, section);

  if (sectionObject != null && sectionObject[subSection] != null) {
    return sectionObject[subSection];
  }

  return null;
}

export default function getCurrentUserAclRight(state, section, subSection, right) {
  const subSectionObject = getCurrentUserAclSubSection(state, section, subSection);

  if (subSectionObject != null && subSectionObject[right] != null) {
    return subSectionObject[right] === true;
  }

  return false;
}
