import { useMutation } from '@tanstack/react-query';
import { HttpRequest } from 'app/common/hooks/useHttpRequest';
import { UseMutationOptionsType, UseMutationResultType, MutationFunctionType } from 'domain/lib/react-query-types';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import assertIsHandledException from 'app/common/helpers/api/assertIsHandledException';
import { ErrorType } from 'domain/lib/errors';

export type UseHttpMutationReturnType<ResponseData = unknown, RequestData = unknown> = Omit<
  UseMutationResultType<ResponseData, RequestData>,
  'mutateAsync'
> & {
  mutateAsync: (
    data: RequestData,
    mutateAsyncOptions?: UseMutationOptionsType<ResponseData, RequestData>
  ) => Promise<ResponseData>;
};

export default function useHttpMutation<ResponseData, RequestData = unknown>(
  requestFn: HttpRequest<ResponseData, RequestData>,
  options: UseMutationOptionsType<ResponseData, RequestData> = {},
  errorMessage?: string
): UseHttpMutationReturnType<ResponseData, RequestData> {
  const { mutateAsync, ...restMutationResult } = useMutation<ResponseData, ErrorType, RequestData, unknown>(
    requestFn as MutationFunctionType<ResponseData, RequestData>,
    options
  );

  const errorSafeMutation = async (
    data: RequestData,
    mutateAsyncOptions?: UseMutationOptionsType<ResponseData, RequestData>
  ): Promise<ResponseData> => {
    try {
      return await mutateAsync(data, mutateAsyncOptions);
    } catch (exception) {
      assertIsNotCriticalHttpException(exception);
      assertIsHandledException(exception);

      if (errorMessage) {
        throw new Error(errorMessage);
      }

      throw exception;
    }
  };

  return { mutateAsync: errorSafeMutation, ...restMutationResult };
}
