import React from 'react';
import { addToolbarButton, PluginProps } from 'yet-another-react-lightbox';
import SlideshowBestPracticePhotoIcon from 'app/common/components/slideshow/bestPracticePhoto/icon';
import SlideshowBestPracticeTag from 'app/common/components/slideshow/bestPracticePhoto/statusTag';

export const SLIDESHOW_BEST_PRACTICE_PHOTO_PLUGIN_ID = 'BestPractice';
export default function BestPracticePhoto({ augment }: PluginProps) {
  augment(({ toolbar, statusTags, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_BEST_PRACTICE_PHOTO_PLUGIN_ID, <SlideshowBestPracticePhotoIcon />),
      statusTags: {
        ...statusTags,
        [SLIDESHOW_BEST_PRACTICE_PHOTO_PLUGIN_ID]: SlideshowBestPracticeTag,
      },
      ...restProps,
    };
  });
}
