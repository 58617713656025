export const DISCOVER_GUIDELINES = 'guidelines';
export const DISCOVER_CATALOG = 'catalog';
export const DISCOVER_STORES = 'stores';
export const DISCOVER_SURVEYS = 'surveys';
export const DISCOVER_LIBRARY = 'library';
export const DISCOVER_DIRECTORY = 'directory';
export const DISCOVER_DOCUMENTS = 'shareddocument';
export const DISCOVER_REPORTS = 'reports';

export const AVAILABLE_MODULES = [
  DISCOVER_GUIDELINES,
  DISCOVER_CATALOG,
  DISCOVER_STORES,
  DISCOVER_SURVEYS,
  DISCOVER_LIBRARY,
  DISCOVER_DIRECTORY,
  DISCOVER_DOCUMENTS,
  DISCOVER_REPORTS,
];
