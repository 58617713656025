import { STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import { FETCH_ITEM_TYPES_START, FETCH_ITEM_TYPES_SUCCESS } from 'app/display/catalog/common/actions/items';

export const initialState = {
  status: STATUS_INITIAL,
  list: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEM_TYPES_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_ITEM_TYPES_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        list: action.types,
      };
    default:
      return state;
  }
}
