import { isArray } from 'lodash';

export default class Collection<T> extends Array<T> {
  public constructor(args?: T[]) {
    if (isArray(args)) {
      super(...args);
    } else {
      super(0);
    }
  }

  // Return an Array object instead of the default constructor when creating derived objects with map, filter, etc...
  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol/species
  static get [Symbol.species]() {
    return Array;
  }
}
