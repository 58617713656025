import React from 'react';
import AccountIcon from 'assets/icons/account.svg';
import AccountDeactivatedIcon from 'assets/icons/account-deactivated.svg';
import { UserStatus } from 'domain/user/fullUser';

export default function UserStatusAnnotationIcon({
  className,
  userStatus,
}: {
  className: string;
  userStatus?: UserStatus;
}) {
  return userStatus === UserStatus.ACTIVATED ? (
    <AccountIcon className={className} />
  ) : (
    <AccountDeactivatedIcon className={className} />
  );
}
