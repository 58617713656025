import PropTypes from 'prop-types';
import React from 'react';
import i18next from 'i18next';

import Modal from '..';
import ReportLink from './reportLink';

class NotFoundModal extends React.PureComponent {
  static propTypes = {
    error: PropTypes.instanceOf(Error).isRequired,
    onHideModal: PropTypes.func.isRequired,
  };

  renderFooter = () => {
    return <ReportLink error={this.props.error} />;
  };

  render() {
    console.info(__filename, 'NotFoundModal::render()', this.props);
    return (
      <Modal
        onClose={this.props.onHideModal}
        title={i18next.t('popup.error.notfound.title')}
        footer={this.renderFooter}
      >
        <div>
          <span>{i18next.t('popup.error.notfound.message')}</span>
          <br />
          <span>{i18next.t('popup.error.apology')}</span>
        </div>
      </Modal>
    );
  }
}

export { NotFoundModal };
export default NotFoundModal;
