import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import { Drawing } from 'domain/slideshow/drawing';
import { compile } from 'path-to-regexp';

export type DrawingUrlWithPhotoId = {
  photoId: string;
  url: string | null;
};

export type UseDrawingRequest = {
  selectedPhotoId: string;
};

export default function useDrawingRequest() {
  const request = useHttpGetRequest<Drawing | null, UseDrawingRequest>(`/stores/photos/:selectedPhotoId/drawing`);

  request.addMiddleware(requestParams => {
    const { selectedPhotoId } = requestParams.getData();
    const generateUrl = compile(requestParams.getUrl());

    requestParams.setUrl(generateUrl({ selectedPhotoId }));
    return requestParams;
  });

  return async (selectedPhotoId: string): Promise<DrawingUrlWithPhotoId> => {
    try {
      const result = await request({ selectedPhotoId });
      return {
        photoId: selectedPhotoId,
        url: result?.url ?? null,
      };
    } catch (error: any) {
      console.warn(`Drawing not found for ID: ${selectedPhotoId}`);
      return { photoId: selectedPhotoId, url: null };
    }
  };
}
