import React from 'react';
import classNames from 'classnames';
import styles from 'app/display/common/components/modals/userNotification/content/notificationReadDate.css';

const UserNotificationReadDate = ({ isRead }: { isRead: boolean }) => {
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.redDot, { [styles.hide]: isRead })} />
    </div>
  );
};

export default UserNotificationReadDate;
