import Guideline, { GuidelineCatalog, GuidelineType } from 'domain/guideline';
import GuidelineStateFactory, { GuidelineStatePayload } from 'domain/guideline/factory/state';
import FileFactory, { GuidelineFilePayload } from 'domain/guideline/factory/file';
import PeriodFactory from 'domain/period/factory';
import GuidelineCollection from 'domain/guideline/list/guidelineCollection';
import { RelationPayloadType, RelationWithUrlPayloadType } from 'domain/types';
import { Cluster } from 'domain/store/cluster';
import { StoreInGuideline } from 'domain/store';

export type GuidelinePayload = {
  id: string;
  type: GuidelineType;
  name: string;
  catalog: GuidelineCatalog;
  markets: RelationWithUrlPayloadType[];
  files: GuidelineFilePayload[];
  state: GuidelineStatePayload;
  owners: RelationPayloadType[];
  folder: RelationPayloadType | null;
  personalFolder: RelationPayloadType | null;
  startDate: string | null;
  endDate: string | null;
  campaign: RelationWithUrlPayloadType | null;
  useCampaignDates: boolean;
  idv4: number | null;
  description: string | null;
  caption: string | null;
  linkedStores: StoreInGuideline[];
  linkedClusters: Cluster[];
  tags: string[];
};

export default class GuidelineFactory {
  static createFromApiPayload(guidelinePayload: GuidelinePayload): Guideline {
    const state = GuidelineStateFactory.createFromApiPayload(guidelinePayload.state);
    const globalFolderId = guidelinePayload.folder?.id ?? null;
    const personalFolderId = guidelinePayload.personalFolder?.id ?? null;
    const campaignId = guidelinePayload.campaign?.id ?? null;
    const files = FileFactory.createCollectionFromApiPayload(guidelinePayload.files);
    const period = PeriodFactory.createFromStringDatesIfNotNull(guidelinePayload.startDate, guidelinePayload.endDate);

    return new Guideline(
      guidelinePayload.id,
      guidelinePayload.type,
      guidelinePayload.name,
      guidelinePayload.catalog,
      guidelinePayload.markets,
      files,
      state,
      guidelinePayload.owners,
      globalFolderId,
      personalFolderId,
      period,
      campaignId,
      guidelinePayload.useCampaignDates,
      guidelinePayload.idv4,
      guidelinePayload.description,
      guidelinePayload.caption,
      guidelinePayload.linkedStores,
      guidelinePayload.linkedClusters,
      guidelinePayload.tags
    );
  }

  static createCollectionFromApiPayload(guidelineListPayload: GuidelinePayload[]): GuidelineCollection {
    return new GuidelineCollection(guidelineListPayload.map(this.createFromApiPayload));
  }

  static createCollectionFromGuideline(guideline: Guideline | undefined): GuidelineCollection {
    return new GuidelineCollection(guideline ? [guideline] : []);
  }
}
