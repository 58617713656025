import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Button from 'app/display/common/components/button';
import { LINK_COLOR_PALETTE, ALT_COLOR_PALETTE, HeadText5 } from 'app/common/typography';
import { COLOR_PRIMARY } from 'app/common/style/colors';
import styles from 'app/display/common/components/modals/confirmModal/buttons.css';
import { GUIDELINE_MANAGE_CATALOG_MODALE_CONFIRM_BUTTON } from 'app/common/constants/userflow';

const Buttons = ({ onConfirm, onClose, confirmEnabled, confirmLabel }) => (
  <>
    <Button className={styles.cancelButton} onClick={onClose}>
      <HeadText5 palette={ALT_COLOR_PALETTE} on={COLOR_PRIMARY} interactive>
        {i18next.t('button.cancel')}
      </HeadText5>
    </Button>
    <Button
      className={styles.primary}
      onClick={onConfirm}
      isDisabled={confirmEnabled === false}
      dataTestId={GUIDELINE_MANAGE_CATALOG_MODALE_CONFIRM_BUTTON}
    >
      <HeadText5 palette={LINK_COLOR_PALETTE} on={COLOR_PRIMARY} interactive>
        {confirmLabel}
      </HeadText5>
    </Button>
  </>
);

Buttons.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmEnabled: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string,
};

Buttons.defaultProps = {
  confirmLabel: i18next.t('button.confirm'),
};

export default Buttons;
