import { createReducer } from '@reduxjs/toolkit';
import { STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';
import {
  FETCH_GLOBAL_FOLDERS_START,
  FETCH_GLOBAL_FOLDERS_SUCCESS,
  FETCH_GLOBAL_FOLDERS_ERROR,
  MOVE_GLOBAL_FOLDER,
} from 'app/display/guideline/common/actions';
import {
  RENAME_GLOBAL_FOLDER_SUCCESS,
  CREATE_GLOBAL_FOLDER_SUCCESS,
  DELETE_GLOBAL_FOLDER_SUCCESS,
} from 'app/display/guideline/list/common/actions';

export const initialState = {
  status: STATUS_INITIAL,
  list: [],
};

function removeFolderAndChildren(folderId, list) {
  const children = list.filter(({ parent }) => parent != null && parent.id === folderId);
  const filteredList = children.reduce(
    (acc, child) => {
      return removeFolderAndChildren(child.id, acc);
    },
    [...list]
  );
  return filteredList.filter(({ id }) => id !== folderId);
}

const globalFoldersReducer = createReducer(initialState, builder => {
  builder
    .addCase(FETCH_GLOBAL_FOLDERS_START, draftState => {
      draftState.status = STATUS_LOADING;
    })
    .addCase(FETCH_GLOBAL_FOLDERS_SUCCESS, (draftState, action) => {
      draftState.status = STATUS_SUCCESS;
      draftState.list = action.folders;
    })
    .addCase(FETCH_GLOBAL_FOLDERS_ERROR, draftState => {
      draftState.status = STATUS_ERROR;
    })
    .addCase(CREATE_GLOBAL_FOLDER_SUCCESS, (draftState, action) => {
      draftState.list.push(action.folder);
    })
    .addCase(RENAME_GLOBAL_FOLDER_SUCCESS, (draftState, action) => {
      const folderIndex = draftState.list.findIndex(({ id }) => id === action.folder.id);
      if (folderIndex !== -1) {
        draftState.list[folderIndex] = action.folder;
      }
    })
    .addCase(DELETE_GLOBAL_FOLDER_SUCCESS, (draftState, action) => {
      draftState.list = removeFolderAndChildren(action.folderId, draftState.list);
    })
    .addCase(MOVE_GLOBAL_FOLDER, (draftState, action) => {
      const folderIndex = draftState.list.findIndex(({ id }) => id === action.folder.id);
      if (folderIndex !== -1) {
        draftState.list[folderIndex] = action.folder;
      }
    });
});

export default globalFoldersReducer;
