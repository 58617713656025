import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'app/display/common/components/select';
import { BodyText1 } from 'app/common/typography';
import { ExportFiles } from 'app/display/import/constants/export';
import classNames from 'classnames';
import styles from './index.css';

type ExportSelectorProps = {
  selectedValue: string;
  onChange: (value: string) => void;
  availableExportValues: ExportFiles[];
  isLoading: boolean;
};

const ExportSelector = ({ selectedValue, onChange, availableExportValues, isLoading }: ExportSelectorProps) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);
  const selectClasses = classNames(styles.select, {
    [styles.disabled]: isLoading,
  });
  const { t } = useTranslation();

  return (
    <BodyText1 className={styles.export}>
      <Select
        className={selectClasses}
        value={selectedValue}
        onChange={handleSelectChange}
        data-testid="exportSelector"
      >
        {availableExportValues?.map(exportValue => (
          <option key={exportValue.key} value={exportValue.key}>
            {t(`drive.import.modal.export.filename.${exportValue.fileName}`)}
          </option>
        ))}
      </Select>
    </BodyText1>
  );
};

export default ExportSelector;
