import React from 'react';
import { BodyText1 } from 'app/common/typography';
import { components, OptionProps } from 'react-select';
import highlightSearchTermInText from 'app/display/common/components/search/highlightSearchTermInText';
import styles from './option.css';

export default function SearchDropDownOption(props: OptionProps) {
  const { inputValue } = props.selectProps;
  return (
    <BodyText1 className={styles.option}>
      <components.Option {...props}>{highlightSearchTermInText(props.label, inputValue)}</components.Option>
    </BodyText1>
  );
}
