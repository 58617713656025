import useCurrentUser from 'app/common/hooks/useCurrentUser';
import { useEffect, useMemo } from 'react';
import userflowClient from 'lib/userflow/userflowClient';
import getUserflowAttributesFromUserAcl from 'app/common/helpers/userflow';
import useCurrentUserAcl from 'app/common/hooks/useCurrentUserAcl';
import useInstanceName from 'app/common/hooks/useInstanceName';
import i18next from 'i18next';
import { UserflowAttributes } from 'domain/userflow/userflowAttributes';
import useInstanceSettings from 'app/common/hooks/useInstanceSettings';

export default function useUserflow() {
  const { currentUser } = useCurrentUser();
  const { userAcl } = useCurrentUserAcl();
  const { instance: instanceName } = useInstanceName();
  const { instanceSettings } = useInstanceSettings();

  const attributes = useMemo((): UserflowAttributes | undefined => {
    if (currentUser == null || userAcl == null || !instanceSettings) {
      return undefined;
    }
    return {
      ...currentUser.getUserflowAttributes(),
      ...getUserflowAttributesFromUserAcl(userAcl),
      instance_name: instanceName,
      locale_code: i18next.language,
      signed_up_at: new Date().toISOString(),
      is_instance_v5: !!instanceSettings?.isV5,
    };
  }, [currentUser, instanceName, userAcl, instanceSettings]);

  useEffect(() => {
    if (currentUser != null && attributes != null && instanceName != null) {
      userflowClient.getInstance().identify(currentUser.id, attributes);
    }
  }, [currentUser, attributes, instanceName]);
}
