import { showModal } from 'app/common/actions/modals';
import { useMemo } from 'react';
import useAction from 'app/display/common/hooks/useAction';

const useShowModal = (modalComponent, options = {}) => {
  const showModalBound = useAction(showModal);
  return useMemo(() => () => showModalBound(modalComponent, options), [modalComponent, options, showModalBound]);
};

export default useShowModal;
