import GuidelineCampaign from 'domain/guideline/campaign';
import StateFactory, { StatePayload } from 'domain/state/factory';
import PeriodFactory from 'domain/period/factory';
import { RelationWithUrlPayloadType } from 'domain/types';

export type GuidelineCampaignPayload = {
  id: string;
  url: string;
  idv4: number | null;
  name: string;
  code: string | null;
  description: string;
  startDate: string | null;
  endDate: string | null;
  markets: RelationWithUrlPayloadType[];
  state: Omit<StatePayload, 'enabled' | 'enabledModifierId' | 'enabledModificationDate'>;
};

export default class GuidelineCampaignFactory {
  static createFromApiPayload(campaignPayload: GuidelineCampaignPayload): GuidelineCampaign {
    const state = StateFactory.createFromApiPayload(campaignPayload.state);
    const period = PeriodFactory.createFromStringDatesIfNotNull(campaignPayload.startDate, campaignPayload.endDate);

    return new GuidelineCampaign(
      campaignPayload.id,
      campaignPayload.name,
      campaignPayload.description,
      campaignPayload.markets,
      state,
      campaignPayload.code,
      period,
      campaignPayload.idv4
    );
  }

  static createCollectionFromApiPayload(campaignsListPayload: GuidelineCampaignPayload[]): GuidelineCampaign[] {
    return campaignsListPayload.map(GuidelineCampaignFactory.createFromApiPayload);
  }
}
