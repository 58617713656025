import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAccessGuidelineDocumentOnClick from 'app/network/store/sheet/tabs/guidelines/hooks/useAccessGuidelineDocumentOnClick';
import { toggleGuidelineSelectionInCampaign } from 'app/network/store/sheet/tabs/guidelines/actions';
import isGuidelineSelected from 'app/network/store/sheet/tabs/guidelines/selectors/isGuidelineSelected';
import Guideline from 'domain/guideline';
import Thumbnail from 'app/common/components/thumbnail';
import Selection from 'app/display/common/components/list/entry/selection';
import GuidelinePlaceholder from 'assets/images/guideline-default.svg';
import GuidelinePlaceholder3d from 'assets/images/guideline-default-3d.svg';
import { BodyText1 } from 'app/common/typography';
import { GuidelineFileType } from 'domain/guideline/file';
import { AppState } from 'lib/providers/store';
import styles from './index.css';

type GuidelineListEntryProps = {
  campaignName: string;
  guideline: Guideline;
};

export const GUIDELINE_LIST_ENTRY_TESTID = 'guidelineListEntry';
export default function GuidelineListEntry({ guideline }: GuidelineListEntryProps) {
  const dispatch = useDispatch();
  const isGuidelineChecked = useSelector((state: AppState) =>
    isGuidelineSelected(state, guideline.campaignId, guideline.id)
  );
  const onClick = useAccessGuidelineDocumentOnClick(guideline.id);
  const onSelect = () => dispatch(toggleGuidelineSelectionInCampaign(guideline.campaignId, guideline.id));

  return (
    <div className={styles.guidelineEntry}>
      <div
        className={styles.thumbnailWrapper}
        onClick={onClick}
        data-testid={`${GUIDELINE_LIST_ENTRY_TESTID}-${guideline.id}`}
      >
        <Thumbnail
          src={guideline.files.getFileByType(GuidelineFileType.THUMBNAIL)?.fileURL}
          alt={guideline.name}
          Placeholder={guideline.is3D() ? GuidelinePlaceholder3d : GuidelinePlaceholder}
          className={styles.thumbnail}
        />
      </div>
      <div className={styles.nameAndSelection}>
        <Selection isChecked={isGuidelineChecked} onChange={onSelect} />
        <BodyText1 className={styles.name}>{guideline.name}</BodyText1>
      </div>
    </div>
  );
}
