import { Photo } from 'domain/photo';
import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import queryClient from 'lib/queryClient';
import { PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';

export default function useUpdatePhoto({ state, ...photo }: Photo) {
  const updatePhotoRequest = useHttpPutRequest<Photo>(`privates/stores/${photo.storeId}/photos/${photo.id}`);

  const refreshLegacyPhoto = useLegacyEvent('UPDATE_PHOTO');
  const onSuccess = (result: Photo) => {
    queryClient.getInstance().setQueryData([PHOTOS_CACHE_KEY, photo.id], result);
    refreshLegacyPhoto({ photoId: photo.idv4 });
  };

  const { mutateAsync } = useHttpMutation(updatePhotoRequest, { onSuccess });

  return function updatePhoto(updateData: Partial<Photo>) {
    return mutateAsync({
      ...photo,
      ...updateData,
      bestpractice: state.bestpractice,
    });
  };
}
