import {
  CATALOG_LIST_MODE_CHANGE,
  CATALOG_ITEM_OPEN_SECTION,
  CATALOG_ITEM_CLOSE_SECTION,
  CATALOG_ITEM_SET_SECTIONS_STATUS,
  CATALOG_ITEM_LOAD_SECTIONS_STATUS,
  SECTIONS_STATUS_KEY,
  SECTION_OPENED,
  SECTION_CLOSED,
  BREADCRUMB_TOGGLE,
  BREADCRUMB_OPEN,
  BREADCRUMB_CLOSE,
} from 'app/display/catalog/common/reducers/ui';
import { getLocalData, setLocalData } from 'lib/helpers/localStore';
import { CATALOG_LIST_MODE_DETAILS, CATALOG_LIST_MODE_THUMBNAILS } from 'app/display/common/ui/constants';

export function openSection(section) {
  return {
    type: CATALOG_ITEM_OPEN_SECTION,
    section,
  };
}

function closeSection(section) {
  return {
    type: CATALOG_ITEM_CLOSE_SECTION,
    section,
  };
}

function setSectionsStatus(sections) {
  return {
    type: CATALOG_ITEM_SET_SECTIONS_STATUS,
    sections,
  };
}

export function toggleCollapsePanel(section, isOpen) {
  return (dispatch, getState) => {
    if (section != null) {
      const { status } = getState().display.ui.catalog.collapse;
      const newStatus = { ...status };
      if (newStatus[section] != null) {
        if (newStatus[section] === SECTION_CLOSED) {
          newStatus[section] = SECTION_OPENED;
          dispatch(openSection(section));
        } else if (newStatus[section] === SECTION_OPENED) {
          newStatus[section] = SECTION_CLOSED;
          dispatch(closeSection(section));
        }
      } else if (isOpen) {
        newStatus[section] = SECTION_CLOSED;
        dispatch(closeSection(section));
      } else {
        newStatus[section] = SECTION_OPENED;
        dispatch(openSection(section));
      }
      setLocalData(SECTIONS_STATUS_KEY, newStatus);
    }
  };
}

export function loadClosedSections() {
  return dispatch => {
    dispatch({
      type: CATALOG_ITEM_LOAD_SECTIONS_STATUS,
    });
    const sections = getLocalData(SECTIONS_STATUS_KEY);
    dispatch(setSectionsStatus(sections));
  };
}

export function changeListMode(mode) {
  return {
    type: CATALOG_LIST_MODE_CHANGE,
    mode,
  };
}

export function toggleListMode() {
  return (dispatch, getState) => {
    let { mode } = getState().display.ui.catalog.list;
    switch (mode) {
      case CATALOG_LIST_MODE_DETAILS:
        mode = CATALOG_LIST_MODE_THUMBNAILS;
        break;
      case CATALOG_LIST_MODE_THUMBNAILS:
      default:
        mode = CATALOG_LIST_MODE_DETAILS;
        break;
    }

    dispatch(changeListMode(mode));
  };
}

export function toggleBreadCrumb() {
  return {
    type: BREADCRUMB_TOGGLE,
  };
}

export function openBreadCrumb() {
  return {
    type: BREADCRUMB_OPEN,
  };
}

export function closeBreadCrumb() {
  return {
    type: BREADCRUMB_CLOSE,
  };
}
