export type ParsedMessage = {
  text: string;
  isBold: boolean;
};
export default function parseMessageContent(str: string): ParsedMessage[] {
  return str
    .split(/(\*\*.*?\*\*)/)
    .map(part => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return { text: part.slice(2, -2), isBold: true };
      }
      return { text: part, isBold: false };
    })
    .filter(part => part.text !== '');
}
