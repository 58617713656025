import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText2, BodyText1 } from 'app/common/typography';
import { Field } from 'redux-form';
import styles from './index.css';

export default function ExecuteFieldNoRole() {
  const { t } = useTranslation();
  return (
    <div className={styles.fieldWrapper}>
      <div className={styles.fieldLabel}>
        <BodyText2>{t('directory.userSheet.licenseTab.execute.label')}</BodyText2>
      </div>
      <div className={styles.fieldInput}>
        <BodyText1>{t('directory.userSheet.licenseTab.noRole')}</BodyText1>
      </div>
      <Field name="executeRole" type="hidden" component="input" />
    </div>
  );
}
