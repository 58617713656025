import React from 'react';
import { PATH_DASHBOARD } from 'config/routePaths';
import AuthenticatedRoute from 'app/common/components/route/authenticated';
import useCurrentUserHasDisplayAccess from 'app/display/common/hooks/useCurrentUserHasDisplayAccess';
import { ReactComponentLike } from 'prop-types';

type RedirectObject = {
  pathname?: string;
  search?: string;
  state: Record<any, any>;
};

// TODO - reuse these proptypes to refactor other routing components
type DisplayRouteProps = {
  isAuthorized?: boolean;
  forbiddenComponent?: ReactComponentLike;
  component?: ReactComponentLike;
  path?: string | string[];
  exact?: boolean;
  strict?: boolean;
  redirectTo?: string | RedirectObject;
  redirectDelay?: number;
};

export default function DisplayRoute({
  isAuthorized = true,
  path,
  component,
  forbiddenComponent,
  exact,
  strict,
  redirectTo,
  redirectDelay,
}: DisplayRouteProps) {
  const hasDisplayAccess = useCurrentUserHasDisplayAccess();

  let to = redirectTo;
  if (!hasDisplayAccess && !to) {
    to = PATH_DASHBOARD;
  }

  return (
    <AuthenticatedRoute
      path={path}
      component={component}
      forbiddenComponent={forbiddenComponent}
      isAuthorized={hasDisplayAccess && isAuthorized}
      redirectTo={to}
      exact={exact}
      strict={strict}
      redirectDelay={redirectDelay}
    />
  );
}
