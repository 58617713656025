import moment from 'moment';
import bugsnagClient from 'lib/bugsnag/client';
import isBugsnagEnabled from 'lib/bugsnag/helpers/isBugsnagEnabled';

export default async function pingBugsnag() {
  return new Promise((resolve, reject) => {
    try {
      if (!isBugsnagEnabled()) {
        reject(new Error('Bugsnag is not enabled.'));
        return;
      }

      const request = new XMLHttpRequest();

      request.onreadystatechange = () => {
        if (request.readyState === XMLHttpRequest.DONE) {
          if (request.response === '') {
            reject(new Error('No response received from Bugsnag.'));
          } else {
            resolve();
          }
        }
      };

      request.open('POST', bugsnagClient.getInstance().config.endpoints.sessions);
      request.setRequestHeader('Content-Type', 'application/json');
      request.setRequestHeader('Bugsnag-Api-Key', process.env.BUGSNAG_API_KEY);
      request.setRequestHeader('Bugsnag-Payload-Version', '1');
      request.setRequestHeader('Bugsnag-Sent-At', moment().toISOString());
      request.send();
    } catch (exception) {
      reject(exception);
    }
  });
}
