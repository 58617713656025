import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import useCurrentUserNetworkAccess from 'app/common/hooks/useCurrentUserNetworkAccess';
import useCurrentUserDisplayAccess from 'app/common/hooks/useCurrentUserDisplayAccess';

type ManageUser = {
  canEditUser: boolean | null;
  isLoading: boolean;
};

export default function useCurrentUserUserManage(): ManageUser {
  const { hasCurrentUserNetworkAccess } = useCurrentUserNetworkAccess();
  const { hasCurrentUserDisplayAccess } = useCurrentUserDisplayAccess();

  const { aclRight, isLoading } = useCurrentUserAclRight('admin.directory.manage');
  const { aclRight: networkAclRight, isLoading: networkAclLoading } = useCurrentUserAclRight('legacy.user.manage');

  return {
    canEditUser: (hasCurrentUserDisplayAccess && aclRight) || (hasCurrentUserNetworkAccess && networkAclRight),
    isLoading: isLoading || networkAclLoading,
  };
}
