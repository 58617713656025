import React from 'react';
import HTTPNotFoundError from 'lib/errors/http/notFoundError';
import HTTPForbiddenError from 'lib/errors/http/forbiddenError';
import HTTPUnauthorizedError from 'lib/errors/http/unauthorizedError';
import HTTPServiceUnavailableError from 'lib/errors/http/serviceUnavailableError';
import NotFound from 'app/common/components/errors/notFound';
import Forbidden from 'app/common/components/errors/forbidden';
import ServiceUnavailable from 'app/common/components/errors/serviceUnavailable';
import UnexpectedError from 'app/common/components/errors/unexpected';
import UnauthorizedError from 'app/common/components/errors/unauthorized';
import { ErrorType } from 'domain/lib/errors';

export const ERROR_PAGE_GENERAL = 'error-page-general';

type ErrorPageProps = { error?: ErrorType };

const ErrorPage = ({ error }: ErrorPageProps) => {
  if (error instanceof HTTPNotFoundError) {
    return <NotFound />;
  }

  if (error instanceof HTTPForbiddenError) {
    return <Forbidden />;
  }

  if (error instanceof HTTPUnauthorizedError) {
    return <UnauthorizedError />;
  }

  if (error instanceof HTTPServiceUnavailableError) {
    return <ServiceUnavailable />;
  }

  return <UnexpectedError />;
};

const ErrorPageWrapper = ({ error }: ErrorPageProps) => {
  return (
    <div data-testid={ERROR_PAGE_GENERAL}>
      <ErrorPage error={error} />
    </div>
  );
};

export default ErrorPageWrapper;
