/**
 * Gets a value by key from the local store.
 *
 * Will return undefined if the key does not exist in the local storage.
 */
export function getLocalData(key) {
  try {
    const serializedData = localStorage.getItem(key);
    if (serializedData != null) {
      return JSON.parse(serializedData);
    }
  } catch (exception) {
    console.warn(`Failed to get data by key "${key}" from local store`, exception);
  }
  return undefined;
}

/**
 * Set a value by key in the local store.
 *
 * The key will be removed if the value is undefined.
 */
export function setLocalData(key, data) {
  try {
    const serializedData = data !== undefined ? JSON.stringify(data) : undefined;
    localStorage.setItem(key, serializedData);
  } catch (exception) {
    console.warn(`Failed to set data at key "${key}" in local storage`, exception);
  }
}
