import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';

export const PresetVariantCreatePage = React.lazy(() =>
  import(/* webpackChunkName: "preset-variant-settings" */ 'app/display/settings/sheet/create/components/page')
);

const PresetVariantSettings = () => (
  <Suspense fallback={<Spinner />}>
    <PresetVariantCreatePage />
  </Suspense>
);

export default PresetVariantSettings;
