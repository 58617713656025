import HTTPUnprocessableEntityError from 'lib/errors/http/unprocessableEntityError';
import HTTPServerError from 'lib/errors/http/serverError';
import isExceptionMappedToAnErrorMessage from 'app/common/helpers/api/isExceptionMappedToAnErrorMessage';

export default function shouldReportApiException(exception) {
  // Don't report an exception that is mapped to an error message (because that means it is expected)
  if (isExceptionMappedToAnErrorMessage(exception)) {
    return false;
  }

  return exception instanceof HTTPServerError || exception instanceof HTTPUnprocessableEntityError;
}
