import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ToastContainer, cssTransition } from 'react-toastify';
import styles from './index.css';

const EaseOutCubic = cssTransition({
  enter: styles.easeOutCubicEnter,
  exit: styles.easeOutCubicExit,
  duration: 300,
});

const Notifications = ({ className }) => {
  return (
    <div className={classNames(styles.notifications, className)} data-testid="notification">
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
        closeOnClick={false}
        rtl={false}
        draggable={true}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
        transition={EaseOutCubic}
      />
    </div>
  );
};

Notifications.propTypes = {
  className: PropTypes.string,
};

Notifications.defaultProps = {
  className: null,
};

export default Notifications;
