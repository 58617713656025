import { unstable_batchedUpdates } from 'react-dom'; // eslint-disable-line camelcase
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import getBasePathFromLoginCookie from 'app/common/helpers/auth/getBasePathFromLoginCookie';
import createSingleton from 'lib/helpers/createSingleton';

function createAppRouter() {
  const {
    createReduxHistory: createHistory,
    routerMiddleware,
    routerReducer,
  } = createReduxHistoryContext({
    history: createBrowserHistory({
      basename: getBasePathFromLoginCookie(),
    }),
    batch: unstable_batchedUpdates,
  });

  return { createHistory, routerMiddleware, routerReducer };
}

export default createSingleton(createAppRouter);
