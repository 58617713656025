import State from 'domain/state';

export enum GuidelineStatus {
  PRIVATE = 'private',
  SHARED_WITH_HQ = 'sharedWithHq',
  SHARED = 'shared',
  ARCHIVED = 'archived',
}

export default class GuidelineState extends State {
  constructor(
    public readonly creatorId: string,
    public readonly modifierId: string,
    public readonly statusModifierId: string,
    public readonly creationDate: Date = new Date(),
    public readonly modificationDate: Date = new Date(),
    public readonly statusModificationDate: Date = new Date(),
    public enabled: boolean = true,
    public archived: boolean = false,
    public status: GuidelineStatus = GuidelineStatus.SHARED
  ) {
    super(creatorId, modifierId, creationDate, modificationDate);
  }

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }

  archive() {
    this.archived = true;
  }

  unarchive() {
    this.archived = false;
  }

  isArchived(): boolean {
    return this.status === GuidelineStatus.ARCHIVED;
  }

  isPrivate(): boolean {
    return this.status === GuidelineStatus.PRIVATE;
  }

  isShared(): boolean {
    return this.status === GuidelineStatus.SHARED;
  }

  isSharedWithHq(): boolean {
    return this.status === GuidelineStatus.SHARED_WITH_HQ;
  }
}
