import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Autosuggest from 'react-autosuggest';
import noop from 'lib/helpers/noop';

import styles from './index.css';

function getSuggestionValue(/* suggestion */) {
  return ''; // we want to empty the input after an element has been chosen
}

export default class Autocomplete extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    suggestions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    onSuggestionsClearRequested: PropTypes.func.isRequired,

    /**
     * A function called when the user has selected a suggestion.
     * See https://github.com/moroshko/react-autosuggest#onSuggestionSelectedProp
     * for arguments
     */
    onSuggestionSelected: PropTypes.func,

    /**
     * The placeholder to put on the input.
     */
    placeholder: PropTypes.string,

    /**
     * The input component to use. This can either be the constructor of a
     * Component or a string to represent a build-in element.
     */
    inputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

    /**
     * A function to render the visible value of a suggestions. By default,
     * suggestions are converted to a string, if a suggestion is an object
     * without a `toString` method, you can implement this function and pass it
     * to Autosuggest, for example:
     *
     * function renderSuggestion(suggestion) {
     *   return `${suggestion.value} (${suggestion.weight})`;
     * }
     *
     * <Autosuggest renderSuggestion={renderSuggestion} {...otherProps} />
     */
    renderSuggestion: PropTypes.func,

    // eslint-disable-next-line react/forbid-prop-types
    theme: PropTypes.object,
    onClear: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    onSuggestionSelected: noop,
    inputComponent: 'input',
    renderSuggestion: String,
    theme: null,
    onClear: noop,
  };

  constructor() {
    super();
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.renderSuggestionItem = this.renderSuggestionItem.bind(this);
  }

  renderInputComponent(inputProps) {
    const Input = this.props.inputComponent;

    return <Input {...inputProps} />;
  }

  renderSuggestionItem(suggestion) {
    return (
      <div className={this.props.theme?.suggestionText || styles.suggestionText}>
        {this.props.renderSuggestion(suggestion)}
      </div>
    );
  }

  render() {
    const {
      value,
      suggestions,
      onChange,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      onSuggestionSelected,
      theme,
      onClear,
    } = this.props;
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange,
      type: 'search',
      onClear,
    };

    return (
      <Autosuggest
        theme={theme || styles}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
        suggestions={suggestions}
        renderSuggestion={this.renderSuggestionItem}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        highlightFirstSuggestion={true} // this allows to use enter to select first
        focusInputOnSuggestionClick={false}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={() => true}
      />
    );
  }
}
