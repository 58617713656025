import useUserNotifications from 'app/display/common/hooks/modals/userNotifications/useUserNotifications';
import useStoreTabs from 'app/display/common/hooks/modals/userNotifications/useStoreTabs';
import processNotificationsByCategory from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';
import { defaultMemoize } from 'reselect';

const processNotificationsByCategoryMemoized = defaultMemoize(processNotificationsByCategory);

type UseUserNotificationsEnabled = {
  notificationsByCategory: ReturnType<typeof processNotificationsByCategory>;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
};

export default function useUserNotificationsEnabled(): UseUserNotificationsEnabled {
  const {
    notifications,
    isLoading: areNotificationsLoading,
    isError: isErrorNotifications,
    error: errorNotifications,
  } = useUserNotifications();
  const {
    storeTabs,
    isLoading: areStoreTabsLoading,
    isError: isErrorStoreTabs,
    error: errorStoreTabs,
  } = useStoreTabs();
  const isLoading = areNotificationsLoading || areStoreTabsLoading;
  const isError = isErrorNotifications || isErrorStoreTabs;
  const error = errorNotifications || errorStoreTabs;

  const notificationsByCategory = processNotificationsByCategoryMemoized(notifications, storeTabs);

  return {
    notificationsByCategory,
    isLoading,
    isError,
    error,
  };
}
