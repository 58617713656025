import useHttpMutation from 'app/common/hooks/useHttpMutation';
import useHttpPostRequest from 'app/common/hooks/useHttpPostRequest';
import { GuidelineTag } from 'domain/guideline/tag';
import queryClient from 'lib/queryClient';
import { GUIDELINE_TAGS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';

export type CreateGuidelineTagRequestData = {
  tags: string[];
};

export default function useCreateGuidelineTag() {
  const request = useHttpPostRequest<GuidelineTag[], CreateGuidelineTagRequestData>('/tags');

  const { mutateAsync } = useHttpMutation(request, {
    onSuccess: data => {
      queryClient.getInstance().setQueryData<GuidelineTag[]>([GUIDELINE_TAGS_CACHE_KEY], data, {});
    },
  });

  return async (tags: string[]) => {
    return mutateAsync({ tags });
  };
}
