import { useSelector } from 'react-redux';
import { PATH_GUIDELINE_SEARCH } from 'config/routePaths';
import queryString from 'query-string';
import getDefaultSortCriterion from 'app/display/guideline/list/common/selectors/getDefaultSortCriterion';
import getDefaultSortOrder from 'app/display/guideline/list/common/selectors/getDefaultSortOrder';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';

export default function useDefaultGuidelineSearchUrl(storeId: string): string {
  const sort = useSelector(getDefaultSortCriterion);
  const order = useSelector(getDefaultSortOrder);

  const params = {
    sort,
    order,
    storeId: [storeId],
  };

  return useParamSelector(
    getAbsoluteUrl,
    `${PATH_GUIDELINE_SEARCH}?${queryString.stringify(params, { arrayFormat: 'bracket' })}`
  );
}
