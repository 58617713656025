import { StylesConfig, Theme } from 'react-select';

export const controlStyles: StylesConfig<any, true> = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: 'var(--color-cloud)',
    borderColor: isFocused ? 'transparent' : 'var(--color-cloud)',
    ':hover': { border: 'solid 1px transparent' },
  }),
  option: styles => ({
    ...styles,
    backgroundColor: 'var(--color-snow)',
    cursor: 'pointer',
    zIndex: 9999,
    ':hover': { backgroundColor: 'var(--color-pebble)' },
  }),
  menuPortal: styles => ({
    ...styles,
    zIndex: 9999,
  }),
  indicatorSeparator: () => ({ display: 'none' }), // might need to remove it when showClearAll is set to true
};

function ThemeConfig(theme: Theme) {
  return {
    ...theme,
    borderRadius: 5,
    spacing: {
      baseUnit: 3.5,
      menuGutter: 1,
      controlHeight: 34,
    },
    colors: {
      ...theme.colors,
      primary: 'var(--color-pigeon)',
    },
  };
}

export default ThemeConfig;
