import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useCurrentUserUserManage from 'app/common/hooks/useCurrentUserUserManage';

export default function useCanEditUser(userId: string) {
  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const { canEditUser: canEditOtherUsers, isLoading: isLoadingAcl } = useCurrentUserUserManage();

  return {
    canEditUser: canEditOtherUsers || (!canEditOtherUsers && currentUser?.id === userId),
    isLoading: isLoadingAcl || isLoadingCurrentUser,
  };
}
