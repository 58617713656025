import React from 'react';
import styles from 'app/display/common/components/input/block/inputBlockSize.css';
import classNames from 'classnames';
import { INPUT_SIZE_S, INPUT_SIZE_M, INPUT_SIZE_L } from 'app/display/common/constants/inputSize';

const InputBlockSize = ({
  children,
  size,
  className,
}: {
  size: typeof INPUT_SIZE_S | typeof INPUT_SIZE_M | typeof INPUT_SIZE_L;
  children: React.ReactNode;
  className: string;
}) => {
  return <div className={classNames(className, styles[size])}>{children}</div>;
};

export default InputBlockSize;
