import React from 'react';
import { useTranslation } from 'react-i18next';
import Action from 'app/common/components/popup/actions/action';
import TransferIcon from 'assets/icons/transfer.svg';
import useAction from 'app/display/common/hooks/useAction';
import { toggleDirectoryActionsPopup } from 'app/directory/user/sheet/common/actions';
import useShowModal from 'app/display/common/hooks/useShowModal';
import TransferContentModal from 'app/directory/user/sheet/view/components/modals/transferContent';

type DirectoryTransferContentPlatformActionProps = {
  userId: string;
};

export default function DirectoryTransferContentPlatformAction({
  userId,
}: DirectoryTransferContentPlatformActionProps) {
  const { t } = useTranslation();
  const togglePopup = useAction(toggleDirectoryActionsPopup);
  const showModal = useShowModal(TransferContentModal, { userId });
  return (
    <Action
      label={t('directory.actionsPopup.actions.transferContent')}
      icon={<TransferIcon />}
      onClick={() => {
        togglePopup();
        showModal();
      }}
      dataTestId="popupTransferContentPlatformButton"
    />
  );
}
