import { useQueries } from '@tanstack/react-query';
import { DRAWINGS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useDrawingRequest, { DrawingUrlWithPhotoId } from 'app/network/common/hooks/useDrawingRequest';

export default function useDrawings(photoIds: string[]) {
  const drawingRequest = useDrawingRequest();
  const drawings = useQueries({
    queries: photoIds.map(photoId => ({
      queryKey: [DRAWINGS_CACHE_KEY, photoId],
      queryFn: async () => {
        return drawingRequest(photoId);
      },
    })),
  });

  const processedDrawings = drawings.reduce((acc, el) => {
    if (el.data) {
      acc.push(el.data);
    }
    return acc;
  }, [] as DrawingUrlWithPhotoId[]);

  return {
    drawings: processedDrawings,
    isLoading: drawings.some(result => result.isLoading),
    isError: drawings.some(result => result.isError),
    error: drawings.find(result => result.error)?.error,
  };
}
