import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_AMBER } from 'app/common/style/colors';

import generator from 'app/common/typography/colors/palettes/helpers/generator';

export const WARNING_COLOR_PALETTE = 'warning-color-palette';

export default generator({
  basic: {
    [COLOR_PRIMARY]: COLOR_AMBER,
    [COLOR_SECONDARY]: COLOR_AMBER,
  },
  hover: {
    [COLOR_PRIMARY]: COLOR_AMBER,
    [COLOR_SECONDARY]: COLOR_AMBER,
  },
  active: {
    [COLOR_PRIMARY]: COLOR_AMBER,
    [COLOR_SECONDARY]: COLOR_AMBER,
  },
});
