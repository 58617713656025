import React from 'react';
import { motion } from 'framer-motion';
import { PREVIOUS_ARROW_TEST_ID } from 'app/display/common/components/modals/wizard';
import noop from 'lib/helpers/noop';
import BackButtonIcon from 'assets/icons/arrow-left.svg';
import classNames from 'classnames';
import styles from './backButton.css';

type WizardBackButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
};

const WizardBackButton = ({ onClick = noop, disabled = false }: WizardBackButtonProps) => {
  return (
    <motion.div
      initial={{ width: 0, opacity: 0, marginRight: 0, height: '30px' }}
      animate={{ opacity: 1, width: '30px', marginRight: '20px' }}
      exit={{ width: 0, opacity: 0, marginRight: 0 }}
    >
      <BackButtonIcon
        className={classNames(styles.backButton, { [styles.disabled]: disabled })}
        onClick={onClick}
        data-testid={PREVIOUS_ARROW_TEST_ID}
      />
    </motion.div>
  );
};

export default WizardBackButton;
