import UserNotificationState, { UserNotificationStatePayload } from 'domain/userNotifications/state';

export default class UserNotificationStateFactory {
  public static createFromApiPayload(notificationStatePayload: UserNotificationStatePayload): UserNotificationState {
    const isRead = !!notificationStatePayload.readDate;
    const created = {
      user: { ...notificationStatePayload.created.user },
      date: new Date(notificationStatePayload.created.date),
    };

    return new UserNotificationState(created, isRead);
  }
}
