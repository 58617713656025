import React from 'react';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import IconHelp from 'assets/icons/help.svg';
import { BodyText2 } from 'app/common/typography';
import { useSelector } from 'react-redux';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import { PATH_HELP_CENTER } from 'config/routePaths';
import useCurrentUserHasHelpAccess from 'app/network/hooks/useCurrentUserHasHelpAccess';
import i18next from 'i18next';
import styles from './index.css';

const HelpLink = () => {
  const instanceBaseUrl = useSelector(getInstanceBaseUrl);
  const showHelp = useCurrentUserHasHelpAccess();

  if (!showHelp) {
    return null;
  }

  return (
    <UserMenuLink to={`${instanceBaseUrl}${PATH_HELP_CENTER}`} newTab>
      <UserMenuIcon IconComponent={IconHelp} />
      <BodyText2 className={styles.uppercase}>{i18next.t('network.userMenu.help')}</BodyText2>
    </UserMenuLink>
  );
};

export default HelpLink;
