import React from 'react';
import Checkbox from 'app/display/acl/instance/components/selection/checkbox';
import { CHECKED, UNCHECKED } from 'app/display/common/components/constants/checkboxStates';
import classNames from 'classnames';
import Link from 'app/common/components/link';
import StoreIcon from 'assets/icons/store.svg';
import styles from './photoThumbnail.css';

type PhotoThumbnailNameProps = {
  posParams: Record<string, any>;
  isSelected: boolean;
  isDisabled?: boolean;
};

export const LEGACY_PHOTO_THUMBNAIL_LINK_TESTID = 'photo-thumbnail-link';

export default function PhotoThumbnailName({ posParams, isSelected, isDisabled = false }: PhotoThumbnailNameProps) {
  const [selected, setSelected] = React.useState<boolean>(isSelected);
  const handleOnCheck = () => {
    setSelected(!selected);
  };

  return (
    <div className={styles.link}>
      <Checkbox
        state={isSelected ? CHECKED : UNCHECKED}
        disabled={isDisabled}
        onClick={handleOnCheck}
        className={classNames('basic', styles.checkbox, {
          'is-checked': isSelected,
        })}
      />
      <Link
        to={null}
        href={posParams.url}
        className={null}
        icon={null}
        label={null}
        data-testid={LEGACY_PHOTO_THUMBNAIL_LINK_TESTID}
      >
        <StoreIcon className={styles.icon} />
        <label className={styles.label} title={posParams.name}>
          {posParams.name}
        </label>
      </Link>
    </div>
  );
}
