import { HeadText5, LINK_COLOR_PALETTE } from 'app/common/typography';
import React from 'react';
import IconApproval from 'assets/icons/validation.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useManageApprovePhoto from 'app/network/common/hooks/useManageApprovePhoto';

import { SlideImage } from 'yet-another-react-lightbox';
import styles from './index.css';

type ApprovedByButtonTypes = {
  currentSlide: SlideImage;
  dataTestid: string;
  isCurrentUserApproval: boolean;
};

const ApprovedByButton = ({ currentSlide, dataTestid, isCurrentUserApproval }: ApprovedByButtonTypes) => {
  const { t } = useTranslation();
  const manageApprovalPhoto = useManageApprovePhoto(currentSlide, isCurrentUserApproval);

  return !isCurrentUserApproval ? (
    <div onClick={manageApprovalPhoto}>
      <HeadText5 palette={LINK_COLOR_PALETTE} interactive={true} className={styles.link} data-testid={dataTestid}>
        <IconApproval className={styles.icon} />
        <span className={styles.text}>{t('slideshow.infoPanel.approve')}</span>
      </HeadText5>
    </div>
  ) : (
    <div onClick={manageApprovalPhoto}>
      <HeadText5 palette={LINK_COLOR_PALETTE} interactive={true} className={styles.link}>
        <IconApproval className={classNames(styles.icon, styles.iconRemove)} />
        {t('slideshow.infoPanel.removeMyApproval')}
      </HeadText5>
    </div>
  );
};

export default ApprovedByButton;
