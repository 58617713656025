import React from 'react';
import { useSelector } from 'react-redux';
import { PATH_LOGOUT } from 'config/routePaths';
import AuthenticatedRoute from 'app/common/components/route/authenticated';
import hasCurrentUserNetworkAccess from 'app/common/user/selectors/hasCurrentUserNetworkAccess';

const NetworkRoute = ({
  isAuthorized,
  path,
  component,
  forbiddenComponent,
  exact,
  strict,
  redirectTo,
  redirectDelay,
}) => {
  const hasNetworkAccess = useSelector(hasCurrentUserNetworkAccess);

  let to = redirectTo;
  if (!hasNetworkAccess) {
    to = PATH_LOGOUT;
  }

  return (
    <AuthenticatedRoute
      path={path}
      component={component}
      forbiddenComponent={forbiddenComponent}
      isAuthorized={hasNetworkAccess && isAuthorized}
      redirectTo={to}
      exact={exact}
      strict={strict}
      redirectDelay={redirectDelay}
    />
  );
};

NetworkRoute.propTypes = {
  ...AuthenticatedRoute.propTypes,
};

NetworkRoute.defaultProps = {
  ...AuthenticatedRoute.defaultProps,
};

export default NetworkRoute;
