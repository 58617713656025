import { createSelector } from 'reselect';
import { PATH_INDEX } from 'config/routePaths';
import getCurrentUserDisplayProfile from 'app/common/user/selectors/getCurrentUserDisplayProfile';
import { PROFILE_DISPLAY_NOROLE } from 'app/common/user/helpers/profile/constants';
import { WEBSITE_DISPLAY_PAGE } from 'app/common/helpers/website';

import getState from 'app/common/selectors/getState';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';

const asAbsolute = (_, absolute = false) => absolute;

export default createSelector(
  [getCurrentUserDisplayProfile, asAbsolute, getState],
  (displayProfile, asAbsoluteUrl, state) => {
    if (displayProfile === PROFILE_DISPLAY_NOROLE) {
      return WEBSITE_DISPLAY_PAGE;
    }

    if (asAbsoluteUrl === true) {
      return getAbsoluteUrl(state, PATH_INDEX);
    }

    return PATH_INDEX;
  }
);
