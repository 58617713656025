import React from 'react';
import classNames from 'classnames';
import { WIDTHS } from 'app/common/style/sizes';

import sizes from 'app/common/style/sizes/index.css';
import { onColor } from 'domain/item/breadcrumb/palette';
import styles from './skeleton.css';

type StyleWidth = 'width-xl' | 'width-l' | 'width-m' | 'width-s';

type SkeletonProps = {
  width?: number | StyleWidth;
  height?: number | string | null;
  circle?: boolean;
  className?: string | null;
  on?: onColor;
  alt?: boolean;
  dataTestid?: string;
};

const Skeleton = ({
  width = 200,
  height = null,
  circle = false,
  className = null,
  on = onColor.COLOR_PRIMARY,
  alt = false,
  dataTestid,
}: SkeletonProps) => {
  const skeletonClassNames = classNames(styles.skeleton, styles[on], className, {
    [styles.circle]: circle,
    [styles.alt]: alt,
    [sizes[width]]: typeof width === 'string' && WIDTHS.includes(width),
  });

  const style = {} as React.CSSProperties;
  if (typeof width === 'number') {
    style.width = width;
  }

  if (height !== null) {
    style.height = height;
  }

  return <span className={skeletonClassNames} style={style} data-testid={dataTestid} />;
};

export default Skeleton;
