import React, { useState } from 'react';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal } from 'app/common/actions/modals';
import { useTranslation } from 'react-i18next';
import { Photo } from 'domain/photo';
import WizardModal from 'app/display/common/components/modals/wizard';
import AddPhotoTag from 'app/common/components/slideshow/tagPhoto/modal/addPhotoTag';
import CreatePhotoTag from 'app/common/components/slideshow/tagPhoto/modal/createPhotoTag';
import PhotoTag from 'domain/photo/tag';

export type TagPhotoModalProps = {
  photo: Photo;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  selectedTags: string[];
  setSelectedTags: (selectedTags: string[]) => void;
  newTags: PhotoTag[];
  setNewTags: (newTags: PhotoTag[]) => void;
};

export default function TagPhotoModal({ photo }: TagPhotoModalProps) {
  const handleClose = useAction(hideModal);
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>(photo.tags);
  const [newTags, setNewTags] = useState<PhotoTag[]>([]);

  const stepData = {
    searchTerm,
    photo,
    setSearchTerm,
    selectedTags,
    setSelectedTags,
    newTags,
    setNewTags,
  };

  return (
    <WizardModal
      onClose={handleClose}
      steps={[
        {
          id: 'addTags',
          component: AddPhotoTag,
          finalStep: true,
          title: t('slideshow.tagPhoto.title'),
          stepData,
        },
        {
          id: 'createTags',
          component: CreatePhotoTag,
          finalStep: true,
          shouldNotClose: true,
          title: t('slideshow.tagPhoto.new'),
          confirmLabel: t('slideshow.tagPhoto.group.create'),
          stepData,
        },
      ]}
    />
  );
}
