import React from 'react';
import { Switch } from 'react-router-dom';
import {
  PATH_GUIDELINE_BROWSE,
  PATH_IMPORT_FILES,
  PATH_INDEX,
  PATH_CATALOG_BROWSE,
  PATH_ACL_INDEX,
  PATH_SETTINGS,
} from 'config/routePaths';
import useAdblockerNotification from 'app/display/common/hooks/useAdblockerNotification';
import NotFound from 'app/common/components/errors/notFound';
import Catalog from 'app/display/catalog/routes';
import Guideline from 'app/display/guideline/routes';
import Acl from 'app/display/acl/routes';
import Import from 'app/display/import/routes';
import DisplayRoute from 'app/display/common/components/route/display';
import ImportRoute from 'app/display/import/components/route/import';
import ACLRoute from 'app/display/acl/instance/components/route/acl';
import SettingsRoutes from 'app/display/settings/routes';
import AuthenticatedRoute from 'app/common/components/route/authenticated';

const DisplayRoutes = () => {
  useAdblockerNotification();

  return (
    <Switch>
      <ImportRoute path={PATH_IMPORT_FILES} component={Import} redirectTo={PATH_INDEX} />
      <ACLRoute path={PATH_ACL_INDEX} component={Acl} redirectTo={PATH_INDEX} />
      <AuthenticatedRoute path={PATH_CATALOG_BROWSE} component={Catalog} />
      <AuthenticatedRoute path={PATH_GUIDELINE_BROWSE} component={Guideline} />
      <DisplayRoute path={PATH_SETTINGS} component={SettingsRoutes} />
      <DisplayRoute component={NotFound} />
    </Switch>
  );
};

export default DisplayRoutes;
