import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery, { UseHttpQueryOptions } from 'app/common/hooks/useHttpQuery';
import { PHOTO_TAGS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import PhotoTagFactory from 'domain/photo/factory/tag';
import PhotoTagPayload from 'domain/photo/factory/tagPayload';
import PhotoTagCollection from 'domain/photo/tagCollection';

export default function usePhotoTags(options: UseHttpQueryOptions<PhotoTagCollection> = {}) {
  const getPhotoTags = useHttpGetRequest<PhotoTagCollection>('privates/stores/photos/tags', {
    transformResponse: (photoTags: PhotoTagPayload[]) => PhotoTagFactory.createCollectionFromApiPayload(photoTags),
  });

  const { data, isLoading, isError, error } = useHttpQuery([PHOTO_TAGS_CACHE_KEY], getPhotoTags, {
    ...options,
    structuralSharing: false,
  });

  return { photoTags: data, isLoading, isError, error };
}
