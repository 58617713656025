import Collection from 'domain/collection';
import PhotoTag from 'domain/photo/tag';

type PhotoTagsByCategory = {
  [categoryName: string]: PhotoTag[];
  noCategory: PhotoTag[];
};

const escapeString = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export default class PhotoTagCollection extends Collection<PhotoTag> {
  public groupByCategory(): PhotoTagsByCategory {
    return this.reduce(
      (photoTagsByCategory, photoTag) => {
        if (photoTag.category) {
          if (!photoTagsByCategory[photoTag.category]) {
            photoTagsByCategory[photoTag.category] = [];
            photoTagsByCategory[photoTag.category] = [photoTag];
          } else {
            photoTagsByCategory[photoTag.category].push(photoTag);
          }
        } else {
          photoTagsByCategory.noCategory.push(photoTag);
        }

        return photoTagsByCategory;
      },
      { noCategory: [] } as PhotoTagsByCategory
    );
  }

  public filterBySearchTerm(searchTerm: string) {
    const reg = new RegExp(escapeString(searchTerm), 'gi');
    return new PhotoTagCollection(this.filter(tag => tag.fullTagName.search(reg) >= 0));
  }

  public getTagCategories(searchTerm: string): string[] {
    const reg = new RegExp(escapeString(searchTerm), 'gi');
    const list = this.filter(tag => {
      if (tag.category) {
        return tag.category.search(reg) >= 0;
      }
      return false;
    });
    return list.map(category => (category.category ? category.category : '')).filter(noCategory => noCategory !== '');
  }
}
