import useCurrentUserAcl from 'app/common/hooks/useCurrentUserAcl';
import get from 'lodash/get';
import {
  EXPORT_ACTIVITY,
  EXPORT_CATALOG,
  EXPORT_CATALOG_MARKET,
  EXPORT_MARKET,
  EXPORT_NETWORK_DATA,
  EXPORT_NETWORK_DATA_TEMPLATES,
  ExportFiles,
} from 'app/display/import/constants/export';

export default function useAvailableExportValues(hasNetworkExport: boolean) {
  const { userAcl, isLoading } = useCurrentUserAcl();
  const availableExportValues = [] as ExportFiles[];

  if (get(userAcl, 'admin.export.market', null)) {
    availableExportValues.push(EXPORT_MARKET);
  }

  if (get(userAcl, 'admin.export.exportCatalog', null)) {
    availableExportValues.push(EXPORT_CATALOG);
  }

  if (get(userAcl, 'admin.export.catalogMarket', null)) {
    availableExportValues.push(EXPORT_CATALOG_MARKET);
  }

  if (get(userAcl, 'admin.export.activity', null)) {
    availableExportValues.push(EXPORT_ACTIVITY);
  }

  if (hasNetworkExport) {
    availableExportValues.push(EXPORT_NETWORK_DATA);
    availableExportValues.push(EXPORT_NETWORK_DATA_TEMPLATES);
  }

  return { isLoading, availableExportValues };
}
