import React from 'react';
import UserStatusAnnotation from 'app/directory/user/common/components/statusAnnotation';
import useAction from 'app/display/common/hooks/useAction';
import { showModal } from 'app/common/actions/modals';
import ToggleUserStatusModal from 'app/directory/user/list/components/modals/toggleUserStatus';
import {
  TOGGLE_STATUS_ACTIVATE,
  TOGGLE_STATUS_DEACTIVATE,
} from 'app/directory/user/list/components/toggleStatusButton';
import useUser from 'app/common/hooks/useUser';
import useCanEditUser from 'app/directory/user/sheet/common/hooks/useCanEditUser';
import styles from './index.css';

export default function ListUserStatusAnnotation({
  userId,
  userIdv4,
  url,
  isIwdUser = false,
}: {
  userId: string;
  userIdv4: string | number | null;
  url: string;
  isIwdUser: boolean;
}) {
  const { user } = useUser(userId);
  const { canEditUser, isLoading } = useCanEditUser(userId);
  const showModalFn = useAction(showModal);

  const handleModalOpen = () => {
    return showModalFn(ToggleUserStatusModal, {
      userIds: [userIdv4],
      status: user?.state.enabled ? TOGGLE_STATUS_DEACTIVATE : TOGGLE_STATUS_ACTIVATE,
      url,
    });
  };

  const onClick = canEditUser && !isIwdUser ? handleModalOpen : undefined;

  return (
    <div className={styles.listAnnotationsContainer}>
      <UserStatusAnnotation userId={userId} onClick={onClick} isLoading={isLoading} />
    </div>
  );
}
