import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Link from 'app/common/components/link';

import { DISPLAY_COLOR_PALETTE, DISPLAY_ALT_COLOR_PALETTE, HeadText5, TextHoverArea } from 'app/common/typography';
import styles from './link.css';

const DisplayLink = ({ className, href, to, children, isActive, 'data-testid': dataTestId }) => {
  const palette = isActive ? DISPLAY_ALT_COLOR_PALETTE : DISPLAY_COLOR_PALETTE;
  return (
    <TextHoverArea
      className={classNames(styles.hoverArea, className, { [styles.isActive]: isActive })}
      palette={palette}
    >
      <Link href={href} to={to} data-testid={dataTestId}>
        <HeadText5 palette={palette}>{children}</HeadText5>
      </Link>
    </TextHoverArea>
  );
};

DisplayLink.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

DisplayLink.defaultProps = {
  className: null,
  'data-testid': null,
  href: null,
  to: null,
};

export default DisplayLink;
