import React, { useRef, useState } from 'react';
import { SelectInstance } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { BodyText2 } from 'app/common/typography';
import DropdownIndicator from 'app/display/common/components/searchDropDown/dropDownIndicator';
import NoOptionsMessage from 'app/display/common/components/searchDropDown/noOptionsMessage';
import ThemeConfig, { controlStyles } from 'app/display/common/components/searchDropDown/themeConfig';
import Placeholder from 'app/display/common/components/searchDropDown/placeholder';
import createLabel from 'app/display/common/helpers/searchDropDown/createLabel';
import { OptionSearchDropDown } from 'app/display/common/helpers/searchDropDown/convertValuesToOptions';
import classNames from 'classnames';
import SearchDropDownOption from 'app/display/common/components/searchDropDown/option';
import styles from './searchDropDownSingle.css';

type SearchDropDownSingleProps = {
  fieldName: string;
  canCreateEntries: boolean;
  createLabelTranslationKey?: string;
  onChange: (value: any) => void;
  label?: string;
  defaultValue?: OptionSearchDropDown;
  placeholder?: string;
  options?: OptionSearchDropDown[];
  closeMenuOnSelect?: boolean;
  isDisabled?: boolean;
  showClearAll?: boolean;
  isRemoveEntries?: boolean;
  wrapperClassName?: string;
};

const SearchDropDownSingle = ({
  fieldName,
  label,
  defaultValue,
  options,
  placeholder,
  onChange,
  canCreateEntries,
  createLabelTranslationKey,
  closeMenuOnSelect = true,
  isDisabled = false,
  showClearAll = false,
  isRemoveEntries = false,
  wrapperClassName,
}: SearchDropDownSingleProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selected, setSelected] = useState<OptionSearchDropDown | undefined>(defaultValue);
  const selectRef = useRef<SelectInstance<OptionSearchDropDown, true> | null>(null);

  const hasLabel = label !== undefined;
  const inputClassName = classNames(styles.input, {
    [styles.noLabel]: !hasLabel,
  });

  const handleSelectChange = (value: any) => {
    if (value) {
      setSelected(value);
      onChange(value);
    } else {
      setSelected(undefined);
      onChange('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    // Add search value as tag on Enter key press if it doesn't exist already
    // and current user has the rights
    const tagExists = selected?.label.toLowerCase() === searchTerm.toLowerCase();
    if (
      searchTerm.length >= 50 &&
      e.code !== 'Delete' &&
      e.code !== 'Backspace' &&
      e.code !== 'ArrowLeft' &&
      e.code !== 'ArrowRight' &&
      e.code !== 'ShiftLeft' &&
      e.code !== 'ShiftRight'
    ) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.code === 'Enter' && searchTerm !== '' && !tagExists && canCreateEntries && !isRemoveEntries) {
      setSelected({ value: searchTerm, label: searchTerm });
    }
  };

  const onInputChange = (newValue: string) => {
    setSearchTerm(newValue.trim());
  };

  const isClearable = showClearAll && !isDisabled;

  return (
    <BodyText2 className={classNames(styles.wrapper, wrapperClassName)}>
      {hasLabel && (
        <div className={styles.label} onClick={() => selectRef.current?.focus()}>
          {label}
        </div>
      )}
      <div className={inputClassName}>
        <CreatableSelect
          ref={selectRef}
          name={fieldName}
          id={fieldName}
          className="searchDropDown"
          classNamePrefix="searchDropDown"
          styles={controlStyles}
          closeMenuOnSelect={closeMenuOnSelect}
          defaultValue={defaultValue}
          options={options}
          isDisabled={isDisabled}
          onInputChange={onInputChange}
          onChange={handleSelectChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          isClearable={isClearable}
          components={{ DropdownIndicator, NoOptionsMessage, Placeholder, Option: SearchDropDownOption }}
          formatCreateLabel={(value: string) => createLabel(value, createLabelTranslationKey)}
          theme={ThemeConfig}
          menuPortalTarget={document.body}
        />
      </div>
    </BodyText2>
  );
};

export default SearchDropDownSingle;
