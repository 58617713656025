import React from 'react';
import { Store } from 'domain/store';
import StoreDefault from 'assets/images/store-default.svg';
import getThumbnailFromImages from 'app/display/guideline/common/helpers/manageStores/getThumbnailFromImages';
import styles from './index.css';

export default function StoreNameImage({ store, className }: { store: Store; className: string }) {
  const storeImage = getThumbnailFromImages(store.images);

  if (!storeImage) {
    return <StoreDefault className={styles.defaultImage} />;
  }

  return <img src={storeImage.fileURL} className={className} alt={store.name} />;
}
