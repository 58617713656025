import React from 'react';
import i18next from 'i18next';
import Link from 'app/common/components/offCanvasPlatformMenu/links/link';
import GuidelineIcon from 'assets/icons/guideline-2d-icon-grid.svg';
import { PATH_GUIDELINE_BROWSE } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useCurrentUserGuidelinesAccess from 'app/common/hooks/useCurrentUserGuidelinesAccess';

const GuidelinesLink = () => {
  const isActive = useLocationMatch(PATH_GUIDELINE_BROWSE, { exact: false });
  const { canAccess, discoveryPagesEnabled } = useCurrentUserGuidelinesAccess();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <Link to={PATH_GUIDELINE_BROWSE} Icon={GuidelineIcon} isActive={isActive} isDisabled={!canAccess}>
      {i18next.t('display.submenu.guidelines')}
    </Link>
  );
};

export default GuidelinesLink;
