import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import { CURRENT_USER_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import FullUserFactory from 'domain/user/factory/fullUser';
import FullUser from 'domain/user/fullUser';

type Options = {
  useCache?: boolean;
  cacheTime?: number;
};

type Result = {
  currentUser: FullUser | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
};

export default function useCurrentUser(options: Options = {}): Result {
  const fetchCurrentUser = useHttpGetRequest<FullUser>(`privates/me`, {
    transformResponse: FullUserFactory.createFromApiPayload,
  });
  const { data, isLoading, isError, error } = useHttpQuery([CURRENT_USER_QUERY_CACHE_KEY], fetchCurrentUser, options);

  return { currentUser: data, isLoading, isError, error };
}
