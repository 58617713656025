import { useEffect } from 'react';

export default function useScrollEvent(domElement, callback) {
  useEffect(() => {
    let requestID;

    function handleScroll(event) {
      cancelAnimationFrame(requestID);

      requestID = requestAnimationFrame(() => {
        callback(event);
      });
    }

    domElement?.addEventListener('scroll', handleScroll);

    return () => {
      cancelAnimationFrame(requestID);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      domElement?.removeEventListener('scroll', handleScroll);
    };
  }, [domElement, callback]);
}
