import { useTranslation } from 'react-i18next';
import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import notifySuccess from 'app/display/common/helpers/notifications/notifySuccess';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import updateUserNotificationsOnReadAll from 'app/display/common/helpers/userNotifications/updateUserNotificationsOnReadAll';
import { UserNotificationPayloadData } from 'domain/userNotifications';
import { UserNotificationFilterCategory } from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';
import DateFormatter from 'lib/date/dateFormatter';

export default function useReadAllUserNotifications(selectedTab: UserNotificationFilterCategory) {
  const { t } = useTranslation();
  const markAllAsRead = useHttpPutRequest<UserNotificationPayloadData>(`/notifications/read`);
  const markAllAsReadSuccessMsg = t('display.modal.notifications.markAsRead.success');
  const markAllAsReadErrorMsg = t('display.modal.notifications.markAsRead.error', { count: 100 });

  return async () => {
    try {
      const category = selectedTab === UserNotificationFilterCategory.ALL ? undefined : selectedTab;
      const data = await markAllAsRead({ readAt: DateFormatter.getTimeNow(), category });

      updateUserNotificationsOnReadAll(data);
      notifySuccess(markAllAsReadSuccessMsg);
    } catch {
      notifyError(markAllAsReadErrorMsg);
    }
  };
}
