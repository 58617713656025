import React from 'react';
import i18next from 'i18next';
import AnimatedIwdLogo from 'app/common/components/loading/app/animatedIwdLogo';
import Spinner from 'app/common/components/spinner';
import NavMenuSkeleton from 'app/common/components/legacyNavMenu/index.skeleton';
import ContentMask from 'app/common/components/appShell/contentMask';
import styles from './index.css';

const IS_NEW_APP_LOADING_ENABLED = false;

const AppLoading = () =>
  IS_NEW_APP_LOADING_ENABLED ? (
    <div className={styles.appLoading}>
      <div className={styles.content}>
        <AnimatedIwdLogo className={styles.logo} />
        <span className={styles.text}>{i18next.t('app.loading')}</span>
      </div>
    </div>
  ) : (
    <>
      <NavMenuSkeleton />
      <ContentMask>
        <Spinner />
      </ContentMask>
    </>
  );

export default AppLoading;
