import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import toggleAppNavigation from 'app/common/actions/appNavigation/toggle';
import isAppNavigationOpen from 'app/common/selectors/isAppNavigationOpen';
import IconMenu from 'assets/icons/platforms-menu.svg';
import useAction from 'app/display/common/hooks/useAction';
import styles from './index.css';

export const useAppToggleButton = () => {
  const toggleNavigation = useAction(toggleAppNavigation);
  const isOpen = useSelector(isAppNavigationOpen);

  return {
    isOpen,
    toggleNavigation,
  };
};

const AppToggleButton = () => {
  const { isOpen, toggleNavigation } = useAppToggleButton();

  const classSet = classNames(styles.appToggle, {
    [styles.isOpen]: isOpen,
  });

  return <IconMenu className={classSet} onClick={toggleNavigation} />;
};

export default AppToggleButton;
