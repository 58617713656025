import React from 'react';
import LicenseTabField from 'app/directory/user/sheet/view/components/license/fields/field';
import useLicenseData from 'app/directory/user/sheet/common/hooks/useLicenseData';
import LicenseTabSkeleton from 'app/directory/user/sheet/view/components/license/index.skeleton';
import { useTranslation } from 'react-i18next';
import formatRoleField from 'app/directory/user/sheet/common/helpers/formatRoleField';
import { HeadText3 } from 'app/common/typography';
import styles from './index.css';

export default function LicenseTab({ userId }: { userId: string }) {
  const { licenseData, isLoading } = useLicenseData(userId);
  const { t } = useTranslation();

  if (isLoading || !licenseData) {
    return <LicenseTabSkeleton />;
  }

  return (
    <div className={styles.licenseTabContainer}>
      <LicenseTabField
        label={t('directory.userSheet.licenseTab.project.label')}
        value={licenseData?.license?.projectName}
      />
      <LicenseTabField
        label={t('directory.userSheet.licenseTab.license.label')}
        value={licenseData?.license?.serviceName}
      />
      <hr className={styles.divider} />
      <div className={styles.rolesContainer}>
        <HeadText3 className={styles.rolesLabel}>{t('directory.userSheet.licenseTab.roles.label')}</HeadText3>
        <LicenseTabField
          label={t('directory.userSheet.licenseTab.create.label')}
          value={formatRoleField(t(`acl.role.${licenseData.createRole}.title`), licenseData)}
          className={styles.roleRow}
        />
        <LicenseTabField
          label={t('directory.userSheet.licenseTab.execute.label')}
          value={t(`acl.role.${licenseData.executeRole}.title`)}
          className={styles.roleRow}
        />
      </div>
    </div>
  );
}
