export default function createSingleton<Type>(createInstance: () => Type): { getInstance: () => Type } {
  let isInstanceCreated = false;
  let instance: Type;

  return {
    getInstance: () => {
      if (!isInstanceCreated) {
        isInstanceCreated = true;
        instance = createInstance();
      }
      return instance;
    },
  };
}
