import React from 'react';
import Avatar from 'app/common/user/containers/avatar';
import Username from 'app/common/user/components/username';
import { LegendText2 } from 'app/common/typography';
import ActivityDate from 'app/common/components/activity/activityDate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.css';

const LastUpdate = ({ userId, date, className }) => {
  return (
    <div className={classNames(styles.lastUpdate, className)}>
      <div className={styles.infos}>
        <div className={styles.avatar}>
          <Avatar userId={userId} />
        </div>
        <div className={styles.username}>
          <Username userId={userId} />
        </div>
      </div>

      <LegendText2 className={styles.date}>
        <ActivityDate date={date} format="now" />
      </LegendText2>
    </div>
  );
};

LastUpdate.propTypes = {
  userId: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  /**
   * Additionnal CSS class.
   */
  className: PropTypes.string,
};

LastUpdate.defaultProps = {
  className: null,
};

export default LastUpdate;
