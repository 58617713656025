import React from 'react';
import { Provider } from 'react-redux';
import store from 'lib/store';

const storeInstance = store.getInstance();
export type AppState = ReturnType<typeof storeInstance.getState>;
const AppStoreProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Provider store={storeInstance}>{children}</Provider>
);

export default AppStoreProvider;
