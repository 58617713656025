import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import history from 'lib/history';

const AppRouterProvider = ({ children }) => <Router history={history.getInstance()}>{children}</Router>;

AppRouterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppRouterProvider;
