/*
 * Javascript RegExp are compiled: as this regex will not change, it is better to create a closure,
 * hence optimizing `generatePathname` function performances
 */
const paramRegex = /(:[a-zA-Z]+)/g;

// @see https://www.npmjs.com/package/route-parser
//      If we need more complete reverse
export default function generatePathname(path: string, parameters?: Record<string, any>) {
  // always reset regex starting point before using it
  paramRegex.lastIndex = 0;
  return path.replace(paramRegex, parameter => parameters?.[parameter.substring(1)] ?? parameter);
}
