import React from 'react';
import Button from 'app/display/common/components/button';
import DownloadCSVIcon from 'assets/icons/download-csv.svg';
import InteractiveIcon from 'app/common/typography/text/icon';
import useAction from 'app/display/common/hooks/useAction';
import exportAsCSV from 'app/network/store/sheet/tabs/guidelines/actions/exportAsCSV';
import { useSelector } from 'react-redux';
import getSelectedGuidelineIds from 'app/network/store/sheet/tabs/guidelines/selectors/getSelectedGuidelineIds';
import classNames from 'classnames';
import styles from './index.css';

export const EXPORT_GUIDELINES_CSV_TEST_ID = 'exportGuidelinesCSV';

export default function ExportGuidelinesCSV({ storeId }: { storeId: string }) {
  const exportAction = useAction(exportAsCSV);
  const selectedGuidelineIds = useSelector(getSelectedGuidelineIds);

  return (
    <Button
      type="button"
      className={classNames(styles.button, styles.exportGuidelineCSV)}
      onClick={() => exportAction(selectedGuidelineIds, storeId)}
      isDisabled={selectedGuidelineIds.length <= 0}
      dataTestId={EXPORT_GUIDELINES_CSV_TEST_ID}
    >
      <InteractiveIcon>
        <DownloadCSVIcon className={styles.icon} />
      </InteractiveIcon>
    </Button>
  );
}
