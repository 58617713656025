import UserNotification, { UserNotificationPayloadData } from 'domain/userNotifications';
import queryClient from 'lib/queryClient';
import { USER_NOTIFICATIONS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';

export default function updateUserNotificationsOnReadAll(data: UserNotificationPayloadData) {
  queryClient.getInstance().setQueryData<UserNotification[]>([USER_NOTIFICATIONS_CACHE_KEY], oldData => {
    if (!oldData) {
      return undefined;
    }
    const updatedIds = data.notifications.map(payload => payload.id);
    const updatedCache = oldData.map(el => {
      if (updatedIds.includes(el.id)) {
        return el.markAsRead();
      }

      return el;
    });

    return [...updatedCache];
  });
}
