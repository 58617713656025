import { USER_LICENSE_DATA_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpPostRequest from 'app/common/hooks/useHttpPostRequest';
import useHttpQuery, { UseHttpQueryResult } from 'app/common/hooks/useHttpQuery';
import { UserLicenseData } from 'domain/user/licenseData';
import HTTPNotFoundError from 'lib/errors/http/notFoundError';

type Result = Pick<UseHttpQueryResult<UserLicenseData | null | undefined>, 'isLoading' | 'isError' | 'error'> & {
  licenseData?: UserLicenseData | null;
};

export default function useLicenseData(userId: string): Result {
  const getLicenseData = useHttpPostRequest<UserLicenseData[]>('privates/licensing/users/status', {
    data: {
      userIds: [userId],
    },
  });

  const {
    data: licenseData,
    isLoading,
    isError,
    error,
  } = useHttpQuery([USER_LICENSE_DATA_CACHE_KEY, userId], () => {
    return getLicenseData();
  });

  if (isLoading) {
    return { licenseData: undefined, isLoading, isError, error };
  }

  if (licenseData && licenseData.length < 1) {
    return {
      licenseData: undefined,
      isLoading,
      isError: true,
      error: new HTTPNotFoundError('User license data not found for this user'),
    };
  }

  return {
    licenseData: licenseData?.find((license: UserLicenseData) => license.userId === userId),
    isLoading,
    isError,
    error,
  };
}
