import { DRAWINGS_CACHE_KEY, PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import useHttpPostRequest from 'app/common/hooks/useHttpPostRequest';
import { Photo } from 'domain/photo';
import queryClient from 'lib/queryClient';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import { SlideImage } from 'yet-another-react-lightbox';

export default function useRotatePhoto(photo?: SlideImage) {
  const rotateImageRequest = useHttpPostRequest<Photo>(`/stores/photos/${photo?.id}/rotate`);
  const refreshLegacyPhoto = useLegacyEvent('UPDATE_PHOTO');

  const { mutateAsync, isLoading } = useHttpMutation(rotateImageRequest, {
    onSuccess: async updatedPhoto => {
      await queryClient.getInstance().invalidateQueries([DRAWINGS_CACHE_KEY, photo?.id]);
      queryClient.getInstance().setQueryData([PHOTOS_CACHE_KEY, photo?.id], updatedPhoto);
      refreshLegacyPhoto({ photoId: photo?.idv4 });
    },
  });

  const rotatePhoto = async () => {
    await mutateAsync({});
  };

  return { isLoading, rotatePhoto };
}
