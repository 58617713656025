import React from 'react';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import { PATH_NETWORK_TAG } from 'config/routePaths';
import IconTag from 'assets/icons/tag.svg';
import { BodyText2 } from 'app/common/typography';
import useCurrentUserHasTagsAccess from 'app/network/hooks/useCurrentUserHasTagsAccess';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import styles from './index.css';

const TagsLink = () => {
  const baseUrl = useSelector(getInstanceBaseUrl);
  const showTags = useCurrentUserHasTagsAccess();

  if (!showTags) {
    return null;
  }

  return (
    <UserMenuLink to={`${baseUrl}${PATH_NETWORK_TAG}`}>
      <UserMenuIcon IconComponent={IconTag} />
      <BodyText2 className={styles.uppercase}>{i18next.t('network.userMenu.tags')}</BodyText2>
    </UserMenuLink>
  );
};

export default TagsLink;
