import React from 'react';
import Tooltip from 'app/common/components/tooltip';
import { LegendText4 } from 'app/common/typography';
import classNames from 'classnames';
import IconBestPractice from 'assets/icons/bestPractice.svg';
import IconTag from 'assets/icons/tag.svg';
import IconValidation from 'assets/icons/validation.svg';
import IconSurvey from 'assets/icons/survey.svg';
import IconThreads from 'assets/icons/threads.svg';
import styles from './photoThumbnail.css';

type PhotoThumbnailIconBarProps = {
  commentResolved: boolean;
  isCommented: boolean;
  isBestPractice: boolean;
  isFromSurvey: boolean;
  isValidated: boolean;
  validationList: string[];
  tagList: string[];
};

export default function PhotoThumbnailIconBar({
  commentResolved,
  isCommented,
  isBestPractice,
  isFromSurvey,
  isValidated,
  validationList,
  tagList,
}: PhotoThumbnailIconBarProps) {
  const hasTags = tagList.length > 0;
  const commentedClassSet = classNames(styles.number, styles.discussion, { [styles.commentResolved]: commentResolved });

  return (
    <>
      {isBestPractice && (
        <div className={styles.bestPractice}>
          <IconBestPractice />
        </div>
      )}
      <div className={styles.imageToolbar}>
        {hasTags && (
          <Tooltip arrow={true} html={tagList.join(', ')}>
            <div className={styles.toolbarIcon}>
              <IconTag />
              <LegendText4 className={styles.number}>
                <span>{tagList.length}</span>
              </LegendText4>
            </div>
          </Tooltip>
        )}
        {isValidated && (
          <Tooltip arrow={true} html={validationList.join(', ')}>
            <div className={styles.toolbarIcon}>
              <IconValidation />
              <LegendText4 className={classNames(styles.number, 'validate-icon')}>{validationList.length}</LegendText4>
            </div>
          </Tooltip>
        )}
        {isFromSurvey && (
          <div className={styles.toolbarIcon}>
            <IconSurvey />
          </div>
        )}
        {isCommented && (
          <div className={styles.toolbarIcon}>
            <IconThreads />
            <LegendText4 className={commentedClassSet}>?</LegendText4>
          </div>
        )}
      </div>
    </>
  );
}
