import useStoreById from 'app/common/hooks/useStoreById';
import useStoreSheetPath from 'app/common/hooks/useStoreSheetPath';
import React from 'react';
import { useLightboxState } from 'yet-another-react-lightbox';
import { useTranslation } from 'react-i18next';
import Skeleton from 'app/common/components/skeleton';
import styles from 'app/common/components/slideshow/information/content/store.css';
import NetworkPjaxLink from 'app/network/common/components/pjaxLink';
import { HeadText5, LegendText2, LINK_COLOR_PALETTE } from 'app/common/typography';
import { COLOR_PRIMARY } from 'app/common/style/colors';
import StoreNameImage from '../../storeName/image';
import Section from '../section';

const StoreInformation = () => {
  const { currentSlide } = useLightboxState();
  const { store, isLoading } = useStoreById(currentSlide?.storeId);
  const storePath = useStoreSheetPath(store?.idv4);
  const { t } = useTranslation();

  if (isLoading || !store) {
    return (
      <Section title={t('slideshow.infoPanel.store.title')} className={styles.storeContent}>
        <Skeleton width={50} height={50} />
      </Section>
    );
  }

  return (
    <Section title={t('slideshow.infoPanel.store.title')}>
      <div className={styles.storeContent}>
        <StoreNameImage store={store} className={styles.image} />
        <div className={styles.storeInfo}>
          <LegendText2 className={styles.storeCode}>{store.code}</LegendText2>
          <NetworkPjaxLink linkData={{ href: storePath || '', replaceId: '#main' }}>
            <HeadText5 on={COLOR_PRIMARY} palette={LINK_COLOR_PALETTE} interactive>
              {store.name}
            </HeadText5>
          </NetworkPjaxLink>
          <LegendText2>{store.address}</LegendText2>
        </div>
      </div>
    </Section>
  );
};

export default StoreInformation;
