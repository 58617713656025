import useInstanceSettings from 'app/common/hooks/useInstanceSettings';

type UseDisplayVersionResult = {
  version: string;
  matchV5: boolean;
  matchV4: boolean;
};

type UseDisplayVersionEmptyResult = {
  version: null;
  matchV5: false;
  matchV4: false;
};

export default function useDisplayVersion(): UseDisplayVersionResult | UseDisplayVersionEmptyResult {
  const { instanceSettings } = useInstanceSettings();

  return {
    version: instanceSettings?.isV5 ? '5.0.0' : '4.0.0',
    matchV5: !!instanceSettings?.isV5,
    matchV4: !instanceSettings?.isV5,
  };
}
