import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserThumbnail from 'app/common/components/thumbnail/user';
import styles from './avatar.css';

const Avatar = ({ url, name, className }) => (
  <div className={classNames(styles.avatar, className)}>
    <UserThumbnail src={url} alt={name} />
  </div>
);

Avatar.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  url: null,
  name: '',
  className: null,
};

export default Avatar;
