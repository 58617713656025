import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import UserNotification, { UserNotificationPayloadData } from 'domain/userNotifications';
import UserNotificationFactory from 'domain/userNotifications/factory';
import { USER_NOTIFICATIONS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';

export default function useUserNotifications() {
  const request = useHttpGetRequest<UserNotification[]>('notifications', {
    transformResponse: (data: UserNotificationPayloadData) =>
      UserNotificationFactory.createCollectionFromApiPayload(data.notifications),
  });

  const { data, isLoading, isError, error } = useHttpQuery([USER_NOTIFICATIONS_CACHE_KEY], request, {
    structuralSharing: false,
    throwOnUnhandledError: false,
  });

  return {
    notifications: data,
    isLoading,
    isError,
    error,
  };
}
