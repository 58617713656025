import React from 'react';
import Skeleton from 'app/common/components/skeleton';
import styles from './index.css';

export default function LicenseTabSkeleton() {
  return (
    <div className={styles.skeletonContainer} data-testid="licenseTabSkeleton">
      <Skeleton width={400} height={18} className={styles.rowSkeleton} />
      <Skeleton width={400} height={18} className={styles.rowSkeleton} />
      <hr className={styles.divider} />
      <Skeleton width={400} height={18} className={styles.rowSkeleton} />
      <Skeleton width={400} height={18} className={styles.rowSkeleton} />
    </div>
  );
}
