import { ORDER_ASC, ORDER_DESC } from 'app/common/selectors/url';
import getSortOrderFromSavedListPreferences from 'app/display/guideline/list/common/selectors/savedPreferences/getSortOrderFromSavedListPreferences';
import { AppState } from 'lib/providers/store';

const ORDERS = [ORDER_ASC, ORDER_DESC];

const isOrderValid = (order: string) => ORDERS.includes(order);

export default function getDefaultSortOrder(state: AppState) {
  const sortOrderFromSavedListPreferences = getSortOrderFromSavedListPreferences(state);
  if (isOrderValid(sortOrderFromSavedListPreferences)) {
    return sortOrderFromSavedListPreferences;
  }

  return ORDER_DESC;
}
