import { COLOR_PRIMARY } from 'app/common/style/colors';

function colorFunction(options) {
  return (on = COLOR_PRIMARY) => {
    return options[on];
  };
}

export default function generator(rules) {
  return Object.keys(rules).reduce((palette, rule) => {
    return {
      ...palette,
      [rule]: colorFunction(rules[rule]),
    };
  }, {});
}
