import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { PATH_GUIDELINE_BROWSE } from 'config/routePaths';
import HTTPForbiddenError from 'lib/errors/http/forbiddenError';
import DocumentTitle from 'app/common/helpers/translated-document-title';
import ErrorPage from 'app/common/components/errors/error';
import DisplayRoute from 'app/display/common/components/route/display';

const GuidelineViewErrorBoundary = ({ error }) => {
  if (error instanceof HTTPForbiddenError) {
    return (
      <>
        <DocumentTitle title="display.guideline.detail.forbiddenError.title" />
        <Switch>
          <DisplayRoute isAuthorized={false} redirectTo={PATH_GUIDELINE_BROWSE} />
        </Switch>
      </>
    );
  }

  return (
    <>
      <DocumentTitle title="display.guideline.detail.unexpectedError.title" />
      <ErrorPage error={error} />
    </>
  );
};

GuidelineViewErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
};

GuidelineViewErrorBoundary.defaultProps = {
  error: null,
};

export default GuidelineViewErrorBoundary;
