import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import sleep from 'lib/helpers/async';
import RequiredSymbol from 'app/common/components/requiredSymbol';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import {
  LegendText2,
  BodyText2,
  BodyText1,
  ALT_COLOR_PALETTE,
  ERROR_COLOR_PALETTE,
  WARNING_COLOR_PALETTE,
} from 'app/common/typography';
import Numeric from './numeric';

import styles from './input.css';

class Input extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    onMouseUp: PropTypes.func,
    inputRef: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    legend: PropTypes.node,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    vertical: PropTypes.bool,
    maxLength: PropTypes.number,
    style: PropTypes.string,
    labelClass: PropTypes.string,
    infoClass: PropTypes.string,
    wrapClass: PropTypes.string,
    errorClass: PropTypes.string,
  };

  static defaultProps = {
    placeholder: null,
    disabled: false,
    required: false,
    vertical: true,
    maxLength: null,
    label: null,
    legend: null,
    style: null,
    labelClass: null,
    infoClass: null,
    wrapClass: null,
    errorClass: null,
    inputRef: null,
    onMouseUp: null,
  };

  debouncedOnChange = event => {
    const {
      input: { onChange },
    } = this.props;

    // cf https://reactjs.org/docs/events.html#event-pooling
    event.persist();

    if (this.onChangeTimeoutId) {
      clearTimeout(this.onChangeTimeoutId);
    }

    this.onChangeTimeoutId = setTimeout(() => {
      onChange(event);
      this.onChangeTimeoutId = undefined;
    }, 200);
  };

  async triggerInputBlurOnMouseUp(event) {
    const {
      input: { onBlur },
      onMouseUp,
    } = this.props;

    // We have to persist the event as it will be asynchronously forwarded to onBlur
    event.persist();

    // Trigger the original callback passed as prop
    if (onMouseUp) {
      onMouseUp(event);
    }

    // Call onBlur asynchronously (to stack at the end of the current queue) to trigger validation
    await sleep(0);
    onBlur(event);
  }

  render() {
    const {
      meta: { error, touched, submitting },
    } = this.props;
    const wrapperClass = classNames(styles.inputContainer, {
      [this.props.wrapClass]: this.props.wrapClass !== null,
    });
    const inputClass = classNames(styles.input, {
      [this.props.style]: this.props.style !== null,
      [this.props.infoClass]: this.props.infoClass !== null,
      [styles.inputError]: touched && error !== undefined,
      [styles.disabled]: submitting,
    });
    const labelClass = classNames({
      [styles.inputLabel]: this.props.vertical,
      [styles.inputLabelHorizontal]: !this.props.vertical,
      [this.props.labelClass]: this.props.labelClass !== null,
      [this.props.labelClass]: this.props.labelClass !== null,
    });
    const errorClass = classNames(this.props.errorClass);

    const {
      input: {
        value,
        onChange, // remove default onChange to debounce it
        ...input
      },
      id,
      disabled,
      label,
      placeholder,
      legend,
      maxLength,
      type,
      required,
      meta: { warning },
    } = this.props;

    if (type === 'date' || type === 'datetime') {
      // With Firefox, resetting the field (via the cross) won't trigger any of
      // the blur/focus/change events, therefore the field won't be validated as
      // redux-form relies on these events.
      // To force this validation, we rely on the mouseUp event that is triggered
      // on reset, and thus trigger the blur event.
      input.onMouseUp = this.triggerInputBlurOnMouseUp.bind(this);
    }

    return type === 'number' ? (
      <Numeric {...this.props} />
    ) : (
      <BodyText2 className={wrapperClass}>
        {label && (
          <label htmlFor={id} className={labelClass}>
            <BodyText2 className={styles.uppercase}>{label}</BodyText2>
            <RequiredSymbol required={required} />
          </label>
        )}
        <input
          {...input} // contains name, onBlur...
          className={inputClass}
          id={id}
          type={type}
          placeholder={placeholder}
          disabled={disabled || submitting}
          defaultValue={value}
          maxLength={maxLength}
          ref={this.props.inputRef}
          onChange={this.debouncedOnChange}
        />
        {legend && (
          <LegendText2 className={styles.legend} palette={ALT_COLOR_PALETTE}>
            {legend}
          </LegendText2>
        )}
        {touched &&
          ((error && (
            <BodyText1 palette={ERROR_COLOR_PALETTE} className={errorClass}>
              <span>{error}</span>
            </BodyText1>
          )) ||
            (warning && <BodyText1 palette={WARNING_COLOR_PALETTE}>{warning}</BodyText1>))}
      </BodyText2>
    );
  }
}

export default Input;
