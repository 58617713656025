import React, { useEffect, useState } from 'react';
import LoadingBar from 'app/common/components/loading/bar';
import { SET_LEGACY_LOADING_BAR } from 'app/network/common/constants/legacyEvents';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import isLoadingBarVisible from 'app/common/selectors/isLoadingBarVisible';

type SetLegacyLoadingEvent = {
  enabled: boolean;
};

export default function LegacyLoadingBar() {
  const [isVisible, setIsVisible] = useState(false);
  const showLoadingBar = useSelector(isLoadingBarVisible);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  useEffect(() => {
    const handleLegacyLoadingBar = (event: CustomEvent<SetLegacyLoadingEvent>) => {
      setIsVisible(event.detail.enabled);
    };

    document.addEventListener(SET_LEGACY_LOADING_BAR, handleLegacyLoadingBar);

    return () => {
      document.removeEventListener(SET_LEGACY_LOADING_BAR, handleLegacyLoadingBar);
    };
  }, []);

  return isFetching || isMutating || isVisible || showLoadingBar ? <LoadingBar /> : null;
}
