import React from 'react';
import { useTranslation } from 'react-i18next';
import TransferIcon from 'assets/icons/transfer.svg';
import Action from 'app/common/components/popup/actions/action';
import useAction from 'app/display/common/hooks/useAction';
import { toggleDirectoryActionsPopup } from 'app/directory/user/sheet/common/actions';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';

export default function DirectoryTransferContentAction({ transferContentUrl }: { transferContentUrl: string }) {
  const { t } = useTranslation();
  const togglePopup = useAction(toggleDirectoryActionsPopup);
  const transferContent = useLegacyEvent('TRANSFER_CONTENT');
  return (
    <Action
      icon={<TransferIcon />}
      label={t('directory.actionsPopup.actions.transferContent')}
      onClick={() => {
        togglePopup();
        transferContent({ transferContentUrl });
      }}
      dataTestId="popupTransferContentButton"
    />
  );
}
