import State from 'domain/state';

/**
 * Only exported (for now) to handle BC until we can break it.
 */
export type StatePayloadElement = {
  user: {
    id: string;
  };
  date: string;
};

export type StatePayload = {
  created: StatePayloadElement;
  updated: StatePayloadElement;
  bestpractice?: boolean;
  enabled?: boolean;
  enabledModifierId?: string;
  enabledModificationDate?: string;
};

export default class StateFactory {
  static createFromApiPayload(statePayload: Partial<StatePayload>): State {
    const creatorId = statePayload.created ? statePayload.created.user.id : '';
    const creationDate = statePayload.created ? new Date(statePayload.created.date) : new Date();
    const enableModifierId = statePayload.enabledModifierId ? statePayload.enabledModifierId : '';
    const modifierId = statePayload.updated ? statePayload.updated.user.id : enableModifierId;
    const enabledModificationDate = statePayload.enabledModificationDate
      ? new Date(statePayload.enabledModificationDate)
      : new Date();
    const modificationDate = statePayload.updated ? new Date(statePayload.updated.date) : enabledModificationDate;
    return new State(creatorId, modifierId, creationDate, modificationDate, statePayload.enabled);
  }
}
