import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import { PROFILE_DISPLAY_NOROLE } from 'app/common/user/helpers/profile/constants';

export default function useDisplayAccessControl(right) {
  const { aclRight, isLoading: aclIsLoading } = useCurrentUserAclRight(right);
  const { isLoading: userIsLoading, currentUser } = useCurrentUser();

  return {
    isLoading: aclIsLoading || userIsLoading,
    canAccess: aclRight && currentUser?.profile?.display !== PROFILE_DISPLAY_NOROLE,
  };
}
