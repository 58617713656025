import useCurrentUser from 'app/common/hooks/useCurrentUser';
import { useSelector } from 'react-redux';
import getIwdConfig from 'app/common/selectors/iwdConfig/getIwdConfig';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';

export default function useCurrentUserHasTagsAccess(): boolean {
  const { currentUser } = useCurrentUser();
  const iwdConfig = useSelector(getIwdConfig);
  const { aclRight: hasTagManage } = useCurrentUserAclRight('legacy.tag.manage');

  return !iwdConfig.isAsp && !!currentUser?.settings.network.hasNetwork && hasTagManage;
}
