import React, { useCallback } from 'react';
import i18next from 'i18next';
import store from 'lib/store';
import logoutAction from 'app/auth/logout/actions/logout';
import useEffectDelayed from 'app/common/hooks/useEffectDelayed';
import styles from './unexpected.css';

const DisconnectedError = () => {
  /**
   * We can't use Redux hooks in this component because it is rendered outside of the Redux provider.
   * So instead we use the Redux store directly to dispatch actions.
   */
  const logout = useCallback(() => store.getInstance()?.dispatch(logoutAction()), []);
  useEffectDelayed(logout, 3000);

  return (
    <div className={styles.unexpectedError}>
      <div className={styles.content}>
        <h2 className={styles.oops}>{i18next.t('disconnectedError.oops')}</h2>
        <h1 className={styles.title}>{i18next.t('disconnectedError.title')}</h1>
        <p className={styles.subtitle}>{i18next.t('disconnectedError.message')}</p>
        <p className={styles.support}>{i18next.t('disconnectedError.support')}</p>
      </div>
    </div>
  );
};

export default DisconnectedError;
