import React from 'react';
import { useLightboxState } from 'yet-another-react-lightbox';
import { useTranslation } from 'react-i18next';
import FullUserAvatar from 'app/common/user/components/fullUserAvatar';
import { BodyText2 } from 'app/common/typography';
import moment from 'moment/moment';
import styles from 'app/common/components/slideshow/information/content/publishedBy.css';
import Skeleton from 'app/common/components/skeleton';
import useUser from 'app/common/hooks/useUser';
import Section from '../section';

const PublishedBy = () => {
  const { currentSlide } = useLightboxState();
  const { user, isLoading } = useUser(currentSlide?.state.created.user.id || '');
  const { t } = useTranslation();
  const momentDate = moment(currentSlide?.state.created.date);

  if (isLoading || !user) {
    return (
      <div className={styles.skeletons}>
        <Skeleton width={22} height={22} circle={true} />
        <Skeleton width={200} height={22} />
      </div>
    );
  }

  return (
    <Section title={t('slideshow.infoPanel.publishBy.title')}>
      <div className={styles.publishBy}>
        <FullUserAvatar className={styles.avatar} user={user} />
        <BodyText2 className={styles.userName}>{user.name}</BodyText2>
        <BodyText2 className={styles.date}>{momentDate.fromNow()}</BodyText2>
      </div>
    </Section>
  );
};

export default PublishedBy;
