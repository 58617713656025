import { useSelector } from 'react-redux';
import useLegacyAcl from 'app/common/hooks/useLegacyAcl';
import hasCurrentUserNetworkAccess from 'app/common/user/selectors/hasCurrentUserNetworkAccess';
import useInstanceSettingsDiscoveryPage from 'app/common/hooks/useInstanceSettingsDiscoveryPage';

type LibraryAccess = {
  canAccess: boolean;
  discoveryPagesEnabled?: boolean;
};

export default function useCurrentUserLibraryAccess(): LibraryAccess {
  const { isAllowed } = useLegacyAcl('library', 'access');
  const hasNetworkAccess = useSelector(hasCurrentUserNetworkAccess);
  const { discoveryPagesEnabled } = useInstanceSettingsDiscoveryPage();

  return { canAccess: hasNetworkAccess && isAllowed, discoveryPagesEnabled };
}
