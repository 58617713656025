import React, { useState } from 'react';
import classNames from 'classnames';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import { useTranslation, Trans } from 'react-i18next';
import useUser from 'app/common/hooks/useUser';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal } from 'app/common/actions/modals';
import TransferContentModalSkeleton from 'app/directory/user/sheet/view/components/modals/transferContent/index.skeleton';
import { BodyText2, BodyText1 } from 'app/common/typography';
import TransferContentSelectUser from 'app/directory/user/sheet/view/components/modals/transferContent/selectUser';
import useTransferUserContent from 'app/directory/user/sheet/common/hooks/useTransferUserContent';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import { useDispatch } from 'react-redux';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import TransferUserContentFormValues, { TransferUserContentSettings } from 'domain/user/transferContent/formValues';
import styles from './index.css';

type TransferContentModalProps = {
  userId: string;
};

export default function TransferContentModal({ userId }: TransferContentModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, isLoading } = useUser(userId);
  const closeModal = useAction(hideModal);
  const name = user ? user.name : '';

  const [transferContentForm, setTransferContentForm] = useState<TransferUserContentFormValues>({
    shouldTransferFolders: false,
    shouldTransferGuidelines: false,
    shouldTransferPhotos: false,
    shouldTransferSurveys: false,
    shouldTransferDocument: false,
    selectedUser: '',
  });

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value, type, checked } = event.currentTarget;
    setTransferContentForm(prevState => ({
      ...prevState,
      [inputName]: type === 'checkbox' ? checked : value,
    }));
  };

  const onChangeSelectedUser = (value: string) => {
    setTransferContentForm(prevState => ({
      ...prevState,
      selectedUser: value,
    }));
  };

  const transferUserContent = useTransferUserContent();

  return (
    <ProcessingConfirmModal
      onClose={closeModal}
      onConfirmError={() =>
        dispatch(closeModalWithErrorNotificationMessage(t('directory.modals.transferContent.error')))
      }
      onConfirm={() => transferUserContent(userId, transferContentForm)}
      onConfirmSuccess={() =>
        dispatch(closeModalWithSuccessNotificationMessage(t('directory.modals.transferContent.success')))
      }
      title={t('directory.modals.transferContent.title')}
      confirmEnabled={Object.values(TransferUserContentSettings).some(
        inputName => transferContentForm[inputName] === true
      )}
    >
      {isLoading ? (
        <TransferContentModalSkeleton />
      ) : (
        <>
          <Trans
            i18nKey="directory.modals.transferContent.subTitle"
            defaults="You are about to transfer content from <0>{{ name }}</0> to another user."
            values={{ name }}
            components={[<strong />]}
          />
          <BodyText2 className={styles.optionsTitle}>{t('directory.modals.transferContent.optionsTitle')}</BodyText2>
          {Object.values(TransferUserContentSettings).map(inputName => (
            <div className={styles.optionRow} key={inputName}>
              <input
                type="checkbox"
                onChange={onChange}
                name={inputName}
                checked={transferContentForm[inputName]}
                className={classNames('checkbox', styles.checkbox)}
                id={inputName}
                data-testid={`checkbox-${inputName}`}
              />
              <label htmlFor="personalFolders">
                <BodyText1>{t(`directory.modals.transferContent.options.${inputName}`)}</BodyText1>
              </label>
            </div>
          ))}
          <TransferContentSelectUser
            className={styles.selectUser}
            onChange={onChangeSelectedUser}
            currentUserId={userId}
          />
        </>
      )}
    </ProcessingConfirmModal>
  );
}
