import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';
import GuidelineViewErrorBoundary from 'app/display/guideline/sheet/view/components/errorBoundary';
import NonCriticalErrorBoundary from 'app/display/common/components/errorBoundary/nonCritical';

export const GuidelineOpenPage = React.lazy(() =>
  import(/* webpackChunkName: "display-guideline-details" */ 'app/display/guideline/sheet/open/components/page')
);

const GuidelineOpen = () => (
  <Suspense fallback={<Spinner />}>
    <NonCriticalErrorBoundary fallback={GuidelineViewErrorBoundary}>
      <GuidelineOpenPage />
    </NonCriticalErrorBoundary>
  </Suspense>
);

export default GuidelineOpen;
