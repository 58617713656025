import useHttpMutation from 'app/common/hooks/useHttpMutation';
import { GuidelineTag } from 'domain/guideline/tag';
import queryClient from 'lib/queryClient';
import { GUIDELINE_TAGS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpDeleteRequest from 'app/common/hooks/useHttpDeleteRequest';

export type DeleteGuidelineTagRequestData = {
  ids: string[];
};

type oldDataType = {
  tags?: GuidelineTag[];
};

export default function useDeleteGuidelineTag() {
  const request = useHttpDeleteRequest<unknown, DeleteGuidelineTagRequestData>('/tags');

  const { mutateAsync } = useHttpMutation(request, {
    onSuccess: (_, variables) =>
      queryClient.getInstance().setQueryData([GUIDELINE_TAGS_CACHE_KEY], (oldData: oldDataType | undefined) => {
        if (!oldData?.tags) {
          return undefined;
        }
        return { tags: oldData.tags.filter(tag => !variables.ids.includes(tag.id)) };
      }),
  });

  return async (ids: string[]) => {
    return mutateAsync({ ids });
  };
}
