import React, { ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import noop from 'lib/helpers/noop';
import CloseIcon from 'assets/icons/close.svg';
import { HeadText3 } from 'app/common/typography';
import { ACTION_POPUP_LIST } from 'app/common/constants/userflow';
import ClickBoundary from 'app/common/components/clickBoundary';
import styles from './index.css';

type PopupActionsProps = {
  title: string;
  children?: ReactNode;
  className?: string | null;
  visible?: boolean;
  onClose?: () => void;
  onOutOfBoundsClick?: () => void;
};

export function PopupActions({
  title,
  children = null,
  className = null,
  visible = false,
  onClose = noop,
  onOutOfBoundsClick = noop,
}: PopupActionsProps) {
  const classSet = classNames(styles.popup, className, {
    [styles.isVisible]: visible,
  });

  const safeOnClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const safeOnOutOfBoundsClick = useCallback(() => {
    onOutOfBoundsClick();
  }, [onOutOfBoundsClick]);

  return (
    <ClickBoundary onClickOutside={safeOnOutOfBoundsClick} isActive={visible}>
      <aside className={classSet}>
        <header className={styles.header}>
          <HeadText3 className={styles.uppercase}>{title}</HeadText3>
          <CloseIcon className={styles.closeIcon} onClick={safeOnClose} />
        </header>
        <div className={styles.actions} data-testid={ACTION_POPUP_LIST}>
          {children}
        </div>
      </aside>
    </ClickBoundary>
  );
}

export default PopupActions;
