import React from 'react';
import i18next from 'i18next';
import Link from 'app/common/components/offCanvasPlatformMenu/links/link';
import PhotoIcon from 'assets/icons/photo-grid.svg';
import { PATH_NETWORK_LIBRARY } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useCurrentUserLibraryAccess from 'app/common/hooks/useCurrentUserLibraryAccess';

const useLibraryLink = () => {
  const isActive = useLocationMatch(PATH_NETWORK_LIBRARY, { exact: false });
  const href = useParamSelector(getAbsoluteUrl, PATH_NETWORK_LIBRARY);
  const { canAccess, discoveryPagesEnabled } = useCurrentUserLibraryAccess();

  return {
    href,
    isActive,
    canAccess,
    discoveryPagesEnabled,
  };
};

const LibraryLink = () => {
  const { href, isActive, canAccess, discoveryPagesEnabled } = useLibraryLink();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <Link href={href} Icon={PhotoIcon} isActive={isActive} isDisabled={!canAccess}>
      {i18next.t('library')}
    </Link>
  );
};

export default LibraryLink;
