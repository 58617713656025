import { connect } from 'react-redux';
import getDashboardUrl from 'app/common/selectors/getDashboardUrl';
import UnexpectedErrorModal from 'app/common/components/modals/errors/unexpected';
import { hideModal } from 'app/common/actions/modals';

function mapStateToProps(state) {
  return {
    dashboardUrl: getDashboardUrl(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => {
      dispatch(hideModal());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UnexpectedErrorModal);
