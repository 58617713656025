import { createSelector } from 'reselect';
import { PATH_NETWORK_INDEX } from 'config/routePaths';

import getCurrentUserNetworkProfile from 'app/common/user/selectors/getCurrentUserNetworkProfile';
import { PROFILE_NETWORK_NOROLE } from 'app/common/user/helpers/profile/constants';
import { WEBSITE_NETWORK_PAGE } from 'app/common/helpers/website';
import getState from 'app/common/selectors/getState';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';

const asAbsolute = (_, absolute = false) => absolute;

export default createSelector(
  [getCurrentUserNetworkProfile, asAbsolute, getState],
  (networkProfile, asAbsoluteUrl, state) => {
    if (networkProfile === PROFILE_NETWORK_NOROLE) {
      return WEBSITE_NETWORK_PAGE;
    }

    if (asAbsoluteUrl === true) {
      return getAbsoluteUrl(state, PATH_NETWORK_INDEX);
    }

    return PATH_NETWORK_INDEX;
  }
);
