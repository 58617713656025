import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLightboxState } from 'yet-another-react-lightbox';
import useFolderPhotoByStore from 'app/network/common/hooks/useFolderPhotoByStore';
import IconFolder from 'assets/icons/folder.svg';
import { HeadText5 } from 'app/common/typography';
import styles from 'app/common/components/slideshow/information/content/folder.css';
import Section from '../section';

const Folder = () => {
  const { currentSlide } = useLightboxState();
  const { t } = useTranslation();
  const { folder } = useFolderPhotoByStore(currentSlide?.storeId, currentSlide?.folderId);

  return (
    <Section title={t('slideshow.infoPanel.folder.title')}>
      <div className={styles.folderContent}>
        <IconFolder className={styles.folderIcon} />
        <HeadText5 className={styles.folderName}>{folder?.name}</HeadText5>
      </div>
    </Section>
  );
};

export default Folder;
