import { useEffect } from 'react';
import sleep from 'lib/helpers/async';

export default function useEffectDelayed(callback, delay) {
  useEffect(() => {
    const delayedCallback = async () => {
      await sleep(delay);
      callback();
    };
    delayedCallback();
  }, [callback, delay]);
}
