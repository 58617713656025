import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import RoundedButton from 'app/display/common/components/roundedButton';
import styles from 'app/display/common/components/plusMenu/style.css';
import { BodyText2 } from 'app/common/typography';

/**
 * A Link in the PlusMenu.
 *
 * A ReactRouter Link wrapping a RoundedButton and a custom label (internal).
 * For navigation need.
 *
 * @visibleName PlusMenuLink
 * @see see [PlusMenu](#!/PlusMenu)
 * @see see [ReactRouter Link](https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/Link.md)
 */
const Link = ({ to, label, icon, className, dataTestId }) => (
  <RouterLink className={classNames(styles.entry, className)} to={to} data-testid={dataTestId}>
    <BodyText2 className={styles.label}>{label}</BodyText2>
    <RoundedButton className={styles.icon}>{icon}</RoundedButton>
  </RouterLink>
);

Link.propTypes = {
  /**
   * Same as ReactRouter `<Link to={} />`
   * @see see [ReactRouter Link](https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/api/Link.md#to-string)
   */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Link.defaultProps = {
  className: null,
  dataTestId: null,
};

export default Link;
