import React from 'react';
import { useSelector } from 'react-redux';

import CatalogLink from 'app/common/components/legacyNavMenu/menu/display/links/catalog';
import GuidelineLink from 'app/common/components/legacyNavMenu/menu/display/links/guideline';
import hasCurrentUserDisplayAccess from 'app/common/user/selectors/hasCurrentUserDisplayAccess';
import useCurrentApplicationModule from 'app/common/hooks/useCurrentApplicationModule';
import NavLinks from 'app/common/components/legacyNavMenu/navLinks';
import { APP_DISPLAY } from 'app/common/ui/constants';
import useSmallScreen from 'app/common/hooks/useSmallScreen';

export default function NavDisplay() {
  const isSmallScreen = useSmallScreen();
  const hasDisplayAccess = useSelector(hasCurrentUserDisplayAccess);
  const module = useCurrentApplicationModule();

  if (!hasDisplayAccess || module !== APP_DISPLAY || isSmallScreen) {
    return null;
  }

  return (
    <NavLinks>
      <CatalogLink />
      <GuidelineLink />
    </NavLinks>
  );
}
