import React from 'react';
import i18next from 'i18next';
import MaintenanceIcon from 'assets/images/maintenance.svg';
import MaintenanceHint from 'app/common/components/errors/layout/hint/maintenance';
import ErrorLayout from 'app/common/components/errors/layout';

const ServiceUnavailable = () => (
  <ErrorLayout
    title={i18next.t('display.error.serviceUnavailable.title')}
    header={<MaintenanceIcon style={{ height: 120, width: 120 }} />}
    hint={<MaintenanceHint />}
  />
);

export default ServiceUnavailable;
