import GuidelineData from 'domain/guideline/data';
import GuidelineDataPayload from 'domain/guideline/data/factory/GuidelineDataPayload';
import GuidelineDataSettingsFactory from 'domain/guideline/data/settings/factory';
import GuidelineDataFileFactory from 'domain/guideline/data/factory/file';

export default class GuidelineDataFactory {
  static createFromApiPayload(payload: GuidelineDataPayload): GuidelineData {
    const settings = GuidelineDataSettingsFactory.createFromApiPayload(payload.settings);
    const files = GuidelineDataFileFactory.createCollectionFromApiPayload(payload.files);
    const retryAfterDate = payload.temporaryResourceRetryAfter
      ? new Date(payload.temporaryResourceRetryAfter)
      : undefined;

    return new GuidelineData(
      payload.id,
      payload.guidelineId,
      payload.guidelineName,
      payload.guidelineMarkets,
      payload.guidelineCampaignName,
      settings,
      files,
      payload.children,
      payload.guidelineDescription,
      payload.guidelineStart,
      payload.guidelineEnd,
      retryAfterDate
    );
  }
}
