import { SELECT_ALL, DESELECT_ALL } from 'app/display/common/actions/list';

export const initialState = {
  all: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ALL:
      return { all: true };
    case DESELECT_ALL:
      return { all: false };
    default:
      return state;
  }
}
