export const FETCH_USER_APP_INFOS = 'FETCH_USER_APP_INFOS';
export const FETCH_USER_APP_INFOS_SUCCESS = 'FETCH_USER_APP_INFOS_SUCCESS';
export const FETCH_USER_APP_INFOS_ERROR = 'FETCH_USER_APP_INFOS_ERROR';

export const initialState = {};

export default function users(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER_APP_INFOS: {
      let infos = {};
      if (state[action.userUUID] && state[action.userUUID][action.appName]) {
        infos = {
          ...state[action.userUUID][action.appName],
        };
      }
      return {
        ...state,
        [action.userUUID]: {
          ...state[action.userUUID],
          [action.appName]: {
            ...infos,
            isLoading: true,
            error: null,
          },
        },
      };
    }
    case FETCH_USER_APP_INFOS_SUCCESS:
      return {
        ...state,
        [action.userUUID]: {
          ...state[action.userUUID],
          [action.appName]: {
            ...action.appInfos,
            isLoading: false,
            error: null,
          },
        },
      };
    case FETCH_USER_APP_INFOS_ERROR:
      return {
        ...state,
        [action.userUUID]: {
          ...state[action.userUUID],
          [action.appName]: {
            isLoading: false,
            error: action.error,
          },
        },
      };
    default:
      return state;
  }
}
