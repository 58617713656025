import React from 'react';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import { PATH_LOGOUT_LEGACY } from 'config/routePaths';
import IconLogout from 'assets/icons/logout.svg';
import { BodyText2 } from 'app/common/typography';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import styles from './index.css';

const LogoutLink = () => {
  const baseUrl = useSelector(getInstanceBaseUrl);

  return (
    <UserMenuLink to={`${baseUrl}${PATH_LOGOUT_LEGACY}`}>
      <UserMenuIcon IconComponent={IconLogout} />
      <BodyText2 className={styles.uppercase}>{i18next.t('network.userMenu.logout')}</BodyText2>
    </UserMenuLink>
  );
};

export default LogoutLink;
