import React, { useState } from 'react';
import {
  addToolbarButton,
  ComponentProps,
  createModule,
  PluginProps,
  useLightboxState,
} from 'yet-another-react-lightbox';
import { useSelector } from 'react-redux';
import isCommentsPanelOpen from 'app/common/selectors/slideshow/isCommentsPanelOpen';
import SlideshowCommentsIcon from 'app/common/components/slideshow/comments/icon';
import classNames from 'classnames';
import SlideshowCommentsContainer from 'app/common/components/slideshow/comments/container';
import { HeadText3 } from 'app/common/typography';
import CloseIcon from 'assets/icons/close.svg';
import useAction from 'app/display/common/hooks/useAction';
import { toggleCommentsPanel } from 'app/common/actions/slideshow';
import { useTranslation } from 'react-i18next';
import isFilmStripOpen from 'app/common/selectors/slideshow/isFilmStripOpen';
import CommentsSkeleton from 'app/common/components/slideshow/comments/skeleton';
import CommentsList from 'app/common/components/slideshow/comments/list';
import CommentsWarning from 'app/common/components/slideshow/comments/warning';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useUser from 'app/common/hooks/useUser';
import moment from 'moment/moment';
import ScrollableList from 'app/display/common/components/list/scrollable';
import styles from './index.css';

export const SLIDESHOW_COMMENTS_PANEL_TESTID = 'slideshow-comments-panel';
export const SLIDESHOW_COMMENTS_PLUGIN_ID = 'comments';

function SlideshowComments({ children }: ComponentProps) {
  const isOpen = useSelector(isCommentsPanelOpen);
  const onCloseClick = useAction(toggleCommentsPanel);
  const { currentSlide } = useLightboxState();
  const { user, isLoading: isSlideUserLoading } = useUser(currentSlide?.state.created.user.id || '');
  const momentDate = moment(currentSlide?.state.created.date);
  const { t } = useTranslation();
  const { currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();

  const isLoading = isCurrentUserLoading || isSlideUserLoading;

  // TODO: replace with the actual values - hook is not part of this US
  // const { comments, isLoading, isError, error } = useComments();
  // Remove this section when hook is implemented
  // Implemented commentStep to be able to test all 3 states of the panel
  // just click on the panel to switch between states
  const [commentStep, setCommentStep] = useState(0);
  const isError = false;

  const onViewClick = () => {
    if (commentStep === 2) {
      setCommentStep(0);
    } else {
      setCommentStep(commentStep + 1);
    }
  };
  // Remove end

  const panelClassName = classNames(styles.commentsPanel, {
    [styles.isOpen]: isOpen,
  });

  if (!currentSlide) {
    return null;
  }

  if (!currentUser || !user || isLoading || commentStep === 1) {
    return (
      <>
        <div className={panelClassName} data-testid={SLIDESHOW_COMMENTS_PANEL_TESTID}>
          <header className={styles.header}>
            <HeadText3 className={styles.uppercase}>{t('slideshow.comments.title')}</HeadText3>
            <CloseIcon className={styles.closeIcon} onClick={onCloseClick} />
          </header>
          <div className={styles.content} onClick={onViewClick}>
            <ScrollableList>
              <CommentsSkeleton />
            </ScrollableList>
          </div>
        </div>
        {children}
      </>
    );
  }

  return (
    <>
      <div className={panelClassName} data-testid={SLIDESHOW_COMMENTS_PANEL_TESTID}>
        <header className={styles.header}>
          <HeadText3 className={styles.uppercase}>{t('slideshow.comments.title')}</HeadText3>
          <CloseIcon className={styles.closeIcon} onClick={onCloseClick} />
        </header>
        <div className={styles.content} onClick={onViewClick}>
          {(isError || commentStep === 2) && (
            <ScrollableList>
              <CommentsWarning />
            </ScrollableList>
          )}
          {commentStep === 0 && (
            <ScrollableList>
              <CommentsList
                currentUser={currentUser}
                slideUser={user}
                slideUrl={currentSlide?.url}
                date={momentDate.fromNow()}
              />
            </ScrollableList>
          )}
        </div>
      </div>
      {children}
    </>
  );
}

export default function Information({ addModule, augment }: PluginProps) {
  const informationModule = createModule(SLIDESHOW_COMMENTS_PLUGIN_ID, SlideshowComments);
  addModule(informationModule);
  const isFilmstripOpen = useSelector(isFilmStripOpen);

  augment(({ toolbar, render, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_COMMENTS_PLUGIN_ID, <SlideshowCommentsIcon />),
      render: {
        slideContainer: props => <SlideshowCommentsContainer isFilmstripOpen={isFilmstripOpen} {...props} />,
        ...render,
      },
      ...restProps,
    };
  });
}
