import isCurrentUserAllowedToExportActivityCsv from 'app/common/selectors/acl/isCurrentUserAllowedToExportActivityCsv';
import isCurrentUserAllowedToExportCatalogCsv from 'app/common/selectors/acl/isCurrentUserAllowedToExportCatalogCsv';
import isCurrentUserAllowedToExportMarketCsv from 'app/common/selectors/acl/isCurrentUserAllowedToExportMarketCsv';

export default function isCurrentUserAllowedToExportAnyCsv(state) {
  return (
    isCurrentUserAllowedToExportActivityCsv(state) ||
    isCurrentUserAllowedToExportCatalogCsv(state) ||
    isCurrentUserAllowedToExportMarketCsv(state)
  );
}
