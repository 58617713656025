import useHttpPostRequest from 'app/common/hooks/useHttpPostRequest';
import { PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import { Photo } from 'domain/photo';
import { create, keyResolver, windowScheduler } from '@yornaath/batshit';
import { useQueries } from '@tanstack/react-query';

type UsePhotosResult = {
  photos: Photo[];
  isLoading: boolean;
  isError: boolean;
  error?: Error | null;
};

const isPhoto = (photo: Photo | undefined): photo is Photo => {
  return !!photo;
};

export default function usePhotos(photoIds: string[]): UsePhotosResult {
  const fetchPhotos = useHttpPostRequest<Photo[]>(`stores/photos/search`, {
    data: {
      photoIds,
    },
  });

  const photosBatcher = create({
    fetcher: async (queryPhotoIds: string[]) => {
      return fetchPhotos({ photoIds: queryPhotoIds });
    },
    resolver: keyResolver('id'),
    scheduler: windowScheduler(10),
  });

  const results = useQueries({
    queries: photoIds.map(photoId => ({
      queryKey: [PHOTOS_CACHE_KEY, photoId],
      queryFn: () => photosBatcher.fetch(photoId),
    })),
  });

  return {
    photos: results.map(result => result.data).filter(isPhoto),
    isLoading: results.some(result => result.isLoading),
    isError: results.some(result => result.isError),
    error: results.find(result => result.error)?.error as Error,
  };
}
