import React, { ReactNode } from 'react';
import classNames from 'classnames';

export type NetworkPjaxLinkData = {
  href: string;
  replaceId?: string;
};

type NetworkPjaxLinkProps = {
  linkData: NetworkPjaxLinkData;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  dataTestId?: string;
};

export default function NetworkPjaxLink({
  linkData: { href, replaceId = '#main' },
  className,
  children,
  onClick,
  dataTestId,
}: NetworkPjaxLinkProps) {
  return (
    <a
      href={href}
      className={classNames('pjax', className)}
      data-parts={replaceId}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      data-testid={dataTestId}
    >
      {children}
    </a>
  );
}
