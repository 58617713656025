import i18next from 'i18next';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import useAction from 'app/display/common/hooks/useAction';
import { BodyText1, ERROR_COLOR_PALETTE } from 'app/common/typography';
import { hideModal } from 'app/common/actions/modals';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import { GuidelineTag } from 'domain/guideline/tag';
import useUpdateGuidelineTag from 'app/network/tag/guideline/hooks/useUpdateGuidelineTag';
import getMessageFromApiException from 'app/common/helpers/api/getMessageFromApiException';
import classNames from 'classnames';
import ModalInput from 'app/display/common/components/modals/modalInput';
import RequiredSymbol from 'app/common/components/requiredSymbol';
import useIsGuidelineTagDuplicate from 'app/network/tag/guideline/hooks/useIsGuidelineTagDuplicate';
import styles from './index.css';

export const GUIDELINE_TAG_MODAL_EDIT = 'guideline-tag-modal-edit';

type EditGuidelineTagModalProps = {
  selectedTag: GuidelineTag;
  setTagName: (value: string) => void;
};

const EditGuidelineTagModal = ({ selectedTag, setTagName }: EditGuidelineTagModalProps) => {
  const editGuidelineTag = useUpdateGuidelineTag(selectedTag.id);
  const isDuplicateTag = useIsGuidelineTagDuplicate();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [confirmEnabled, setConfirmEnabled] = useState(false);
  const [userValue, setUserValue] = useState(selectedTag.name);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const closeModal = useAction(hideModal);
  const handleConfirm = async () => {
    if (userValue.length > 0) {
      setIsSubmitting(true);
      await editGuidelineTag(userValue);
      setTagName(userValue);
      setIsSubmitting(false);
    }
  };

  const onChangeHandler = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    const initialTagName = selectedTag.name;
    const userValueChanged = value !== initialTagName; // case-sensitive check
    const isDuplicate = isDuplicateTag(value, selectedTag.id); // case in-sensitive check
    const isEmpty = value === '';
    const isEnabled = userValueChanged && !isEmpty && !isDuplicate;

    setIsValid(isEnabled || !userValueChanged);
    setConfirmEnabled(isEnabled);
    setUserValue(value.trim());
  };

  const onConfirmSuccess = useAction(() =>
    closeModalWithSuccessNotificationMessage(i18next.t('network.guidelines.tag.modal.rename.success'))
  );
  const onConfirmError = useAction((exception: DOMException) => {
    const { message } = getMessageFromApiException(exception, i18next.t('network.guidelines.tag.modal.rename.error'));
    return closeModalWithErrorNotificationMessage(message);
  });

  const title = i18next.t('network.guidelines.tag.modal.rename.title');
  const inputClasses = classNames(styles.input, {
    [styles.disabled]: isSubmitting,
    [styles.error]: !isValid,
  });

  return (
    <ProcessingConfirmModal
      onClose={closeModal}
      onConfirm={handleConfirm}
      confirmEnabled={confirmEnabled}
      onConfirmSuccess={onConfirmSuccess}
      onConfirmError={onConfirmError}
      title={title}
      submitOnEnter={true}
    >
      <ModalInput
        inputRef={inputRef}
        type="text"
        required={true}
        pattern=".*\S+.*"
        onChange={onChangeHandler}
        className={inputClasses}
        isDisabled={isSubmitting}
        id="modal-guideline-tag-edit"
        name="modal-guideline-tag-edit"
        maxLength={50}
        placeholder=""
        dataTestId={GUIDELINE_TAG_MODAL_EDIT}
        defaultValue={selectedTag.name}
      />
      {!isValid && (
        <div className={styles.errorMessage}>
          <RequiredSymbol required />
          <BodyText1 className={styles.error} palette={ERROR_COLOR_PALETTE}>
            {i18next.t('network.guidelines.tag.create.error.sameName')}
          </BodyText1>
        </div>
      )}
    </ProcessingConfirmModal>
  );
};

export default EditGuidelineTagModal;
