export default class NotificationSettings {
  constructor(
    public readonly commentNew: boolean,
    public readonly documentShared: boolean,
    public readonly guidelineShared: boolean,
    public readonly photoApprove: boolean,
    public readonly photoBestPractices: boolean,
    public readonly photoReplaced: boolean,
    public readonly photoDeleted: boolean,
    public readonly surveyCompletionReminder: boolean,
    public readonly surveyPublicationAvailable: boolean,
    public readonly surveyPublicationOwner: boolean
  ) {}
}

export enum PhotoReportFrequency {
  monthly = 'monthly',
  weekly = 'weekly',
  daily = 'daily',
}
