import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lib/helpers/noop';

import RoundedButton from 'app/display/common/components/roundedButton';
import styles from 'app/display/common/components/plusMenu/style.css';
import { BodyText2 } from 'app/common/typography';

/**
 * A Button in the PlusMenu.
 *
 * Composed with a RoundedButton and a custom label (internal).
 * Use it when you need to respond to click only, no navigation.
 *
 * @visibleName PlusMenuButton
 * @see see [PlusMenu](#!/PlusMenu)
 */
const Button = ({ label, icon, className, onClick, dataTestId }) => (
  <div className={classNames(styles.entry, className)} onClick={onClick} data-testid={dataTestId}>
    <BodyText2 className={styles.label}>{label}</BodyText2>
    <RoundedButton className={styles.icon}>{icon}</RoundedButton>
  </div>
);

Button.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  dataTestId: PropTypes.string,
};

Button.defaultProps = {
  className: null,
  onClick: noop,
  dataTestId: null,
};

export default Button;
