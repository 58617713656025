import { createReducer } from '@reduxjs/toolkit';
import {
  deselectAllGuidelinesInCampaign,
  selectAllGuidelinesInCampaign,
  toggleGuidelineSelectionInCampaign,
} from 'app/network/store/sheet/tabs/guidelines/actions';
import toggleSelectionFromArrayOfSelections from 'app/display/common/helpers/toggleSelectionFromArrayOfSelections';

type StateType = {
  selection: Record<string, string[]>;
};

export const initialState: StateType = {
  selection: {},
};

const storeSheetGuidelineSelectionReducer = createReducer(initialState, builder => {
  builder
    .addCase(toggleGuidelineSelectionInCampaign, (state, action) => {
      const { campaignId, guidelineId } = action.payload;

      const selections = state.selection[campaignId] ?? [];

      state.selection[campaignId] = toggleSelectionFromArrayOfSelections(selections, guidelineId);
    })
    .addCase(selectAllGuidelinesInCampaign, (state, action) => {
      state.selection[action.payload.campaignId] = action.payload.guidelineIds;
    })
    .addCase(deselectAllGuidelinesInCampaign, (state, action) => {
      state.selection[action.payload.campaignId] = [];
    });
});

export default storeSheetGuidelineSelectionReducer;
