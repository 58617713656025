import deprecated from 'lib/deprecated';

type Editors = {
  has2d: boolean;
  has3d: boolean;
};

type Network = {
  hasNetwork: boolean;
};

export type UnitOfLength = 'mm' | 'inch';

export default class Settings {
  constructor(
    public readonly editors: Editors,
    private unitOfLengthEnum: UnitOfLength,
    public readonly network: Network
  ) {
    // do nothing.
  }

  /**
   * @deprecated use `unitOfLengthEnum` instead. When all usage of this method have been deleted,
   * rename `unitOfLengthEnum` to `unitOfLength`.
   */
  @deprecated()
  get unitOfLength(): string {
    return this.unitOfLengthEnum.toString();
  }
}
