import { ORDER_DESC } from 'app/common/selectors/url';
import { CRITERION_LAST_UPDATE } from 'app/display/guideline/list/common/sortCriteria';
import queryString from 'query-string';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import { GUIDELINES_BY_STORE_ID } from 'app/common/constants/queryCacheKeys';
import convertSortCriterionToApi from 'app/display/guideline/list/common/helpers/convertSortCriterionToApi';
import Guideline from 'domain/guideline';
import GuidelineFactory, { GuidelinePayload } from 'domain/guideline/factory';
import moment from 'moment';

type GuidelineSearchResponse = {
  guidelines: GuidelinePayload[];
};

export default function useGuidelinesByStoreId(storeId: string, filters: Record<string, any> = {}) {
  const searchParams = {
    order: ORDER_DESC,
    sort: convertSortCriterionToApi(CRITERION_LAST_UPDATE),
    storeId: [storeId],
    per_page: 500,
    endDateAfter: moment().startOf('day').toJSON(),
    ...filters,
  };
  const searchParamsString = queryString.stringify(searchParams, { arrayFormat: 'bracket' });

  const fetchGuidelines = useHttpGetRequest<Guideline[]>(`privates/guidelines/search?${searchParamsString}`, {
    transformResponse: (response: GuidelineSearchResponse) => {
      return response?.guidelines.map(guideline => GuidelineFactory.createFromApiPayload(guideline));
    },
  });

  const { data, isLoading, isError, error } = useHttpQuery([GUIDELINES_BY_STORE_ID, storeId], fetchGuidelines);

  return { guidelines: data, isLoading, isError, error };
}
