import useCurrentUser from 'app/common/hooks/useCurrentUser';
import { PROFILE_NETWORK_NOROLE } from 'app/common/user/helpers/profile/constants';

type ResultType = {
  hasCurrentUserNetworkAccess: boolean;
};

export default function useCurrentUserNetworkAccess(): ResultType {
  const { currentUser, isLoading } = useCurrentUser();
  const hasNetworkAccess = currentUser?.profile?.network !== PROFILE_NETWORK_NOROLE;

  if (isLoading) {
    return { hasCurrentUserNetworkAccess: false };
  }

  return { hasCurrentUserNetworkAccess: hasNetworkAccess };
}
