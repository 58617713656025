import { useTranslation } from 'react-i18next';
import DisplayUncontrolledCollapsePanel from 'app/display/common/components/collapsePanel/uncontrolled';
import styles from 'app/directory/user/sheet/common/components/form/notifications/index.css';
import NotificationsSettingRow from 'app/directory/user/sheet/common/components/form/notifications/settingRow';
import React from 'react';

export default function NotificationsTabSurveySection() {
  const { t } = useTranslation();
  return (
    <DisplayUncontrolledCollapsePanel
      title={t('directory.userSheet.notificationsTab.survey.title')}
      classNameContent={styles.panelContent}
    >
      <NotificationsSettingRow name="surveyCompletionReminder" />
      <NotificationsSettingRow name="surveyPublicationAvailable" />
      <NotificationsSettingRow name="surveyPublicationOwner" />
    </DisplayUncontrolledCollapsePanel>
  );
}
