import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'lib/icons'; // allow icons to be bundled only in parent chunk
import 'app/common/style/index.css';
import initApp from 'lib/init';
import App from 'app';

initApp().then(() => {
  const rootNode = document.getElementById('app-layer');

  if (rootNode) {
    createRoot(rootNode).render(<App />);
  }
});
