/*
 * Recursive function to return a flattened array of all rights user has
 */
function getAclRightsEnabled(aclRights: any, keyPrefix = '', result: string[] = []) {
  return Object.entries(aclRights).reduce((acc, [rightName, rightValue]) => {
    const rightString = `${keyPrefix}${rightName}`;
    if (typeof rightValue === 'object') {
      getAclRightsEnabled(rightValue, `${rightString}.`, acc);
    } else if (rightValue === true) {
      acc.push(rightString);
    }
    return acc;
  }, result);
}

export default function getUserflowAttributesFromUserAcl(userAcl: any) {
  return {
    acl_rights: getAclRightsEnabled(userAcl),
  };
}
