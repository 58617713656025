import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './index.css';

type ModalInputPropTypes = {
  inputRef: any;
  id: string;
  name: string;
  type: string;
  maxLength: number;
  required: boolean;
  pattern: string;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  className: string;
  placeholder: string;
  dataTestId: any;
  defaultValue: any;
  isDisabled: boolean;
};

// TODO: it needs to be re-factored to use a proper controller input.
// See note: https://gitlab.iwd.re/dev/iwd-webapp/-/merge_requests/5624#note_700878

const ModalInput = ({
  inputRef,
  id,
  name,
  type,
  maxLength,
  required,
  pattern,
  onChange,
  className,
  placeholder,
  dataTestId,
  defaultValue,
  isDisabled,
}: ModalInputPropTypes) => {
  const setInputClass = classNames(className, {
    [styles.disabled]: isDisabled,
  });
  return (
    <input
      ref={inputRef}
      id={id}
      name={name}
      type={type}
      maxLength={maxLength}
      required={required}
      pattern={pattern}
      onChange={onChange}
      className={setInputClass}
      placeholder={placeholder}
      data-testid={dataTestId}
      defaultValue={defaultValue}
      disabled={isDisabled}
    />
  );
};

export default ModalInput;
