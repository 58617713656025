import { StatePayload } from 'domain/state/factory';
import GuidelineState, { GuidelineStatus } from 'domain/guideline/state';

export type GuidelineStatePayload = StatePayload & {
  enabled: boolean;
  archived: boolean;
  status: GuidelineStatus;
  statusModifierId: string;
  statusModificationDate: string;
};

export default class GuidelineStateFactory {
  static createFromApiPayload(statePayload: GuidelineStatePayload): GuidelineState {
    return new GuidelineState(
      statePayload.created.user.id,
      statePayload.updated.user.id,
      statePayload.statusModifierId,
      new Date(statePayload.created.date),
      new Date(statePayload.updated.date),
      new Date(statePayload.statusModificationDate),
      statePayload.enabled,
      statePayload.archived,
      statePayload.status
    );
  }
}
