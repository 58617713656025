import isAppNavigationOpen from 'app/common/selectors/isAppNavigationOpen';
import { CLOSE_APP_NAVIGATION } from './types';

export default function closeAppNavigation() {
  return (dispatch, getState) => {
    const isOpen = isAppNavigationOpen(getState());
    if (isOpen) {
      dispatch({ type: CLOSE_APP_NAVIGATION });
    }
  };
}
