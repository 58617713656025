import React, { useEffect, useRef } from 'react';
import { useLightboxState } from 'yet-another-react-lightbox';
import styles from 'app/common/components/slideshow/drawing/drawingSvg.css';

const DrawingSVG = ({ width, height, zoom }: { width: number; height: number; zoom: number }) => {
  const { currentSlide } = useLightboxState();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const drawSVGOnCanvas = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      canvas.width = width * zoom;
      canvas.height = height * zoom;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Failed to get canvas context');
        return;
      }

      if (!currentSlide?.drawingUrl) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        return;
      }

      const img = new Image();
      img.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };

      img.onerror = err => {
        console.warn('Failed to load the SVG', err);
      };

      img.src = currentSlide?.drawingUrl;
    };

    drawSVGOnCanvas();
  }, [zoom, width, height, currentSlide?.drawingUrl, currentSlide?.id]);

  return <canvas ref={canvasRef} className={styles.canvas} style={{ transform: `scale(calc(${1 / zoom}))` }} />;
};

export default DrawingSVG;
