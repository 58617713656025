import React from 'react';
import classNames from 'classnames';
import ClickBoundary from 'app/common/components/clickBoundary';
import Links from 'app/common/components/offCanvasPlatformMenu/links';
import useOffCanvasPlatformMenu from './index.hook';
import styles from './index.css';

const OffCanvasPlatformMenu = () => {
  const { isVisible, closeNavigation } = useOffCanvasPlatformMenu();

  const offCanvasPlatformMenuClass = classNames(styles.offCanvasPlatformMenu, { [styles.isVisible]: isVisible });

  return (
    <ClickBoundary
      className={offCanvasPlatformMenuClass}
      isActive={isVisible}
      onClick={closeNavigation}
      onClickOutside={clickEvent => {
        clickEvent.stopPropagation();
        clickEvent.preventDefault();
      }}
    >
      <Links />
    </ClickBoundary>
  );
};

export default OffCanvasPlatformMenu;
