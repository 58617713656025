import React from 'react';
import i18next from 'i18next';
import DisconnectedError from 'app/common/components/errors/disconnected';
import hasLoginCookie from 'app/common/helpers/auth/hasLoginCookie';
import { UNAUTHORIZED } from 'app/common/constants/userflow';
import styles from './unexpected.css';

const UnauthorizedError = () => {
  /**
   * The disconnected error page automatically logout the user and redirect him to the login page,
   * but in order to do that we need the base URL, which is present in the login cookie.
   * So we can only display this page when we have the login cookie.
   */
  if (hasLoginCookie()) {
    return <DisconnectedError />;
  }

  return (
    <div className={styles.unexpectedError} data-testid={UNAUTHORIZED}>
      <div className={styles.content}>
        <h2 className={styles.oops}>{i18next.t('unauthorizedError.oops')}</h2>
        <h1 className={styles.title}>{i18next.t('unauthorizedError.title')}</h1>
        <p className={styles.subtitle}>{i18next.t('unauthorizedError.message')}</p>
        <p className={styles.support}>{i18next.t('unauthorizedError.support')}</p>
      </div>
    </div>
  );
};

export default UnauthorizedError;
