import { push } from 'redux-first-history';
import queryString from 'query-string';
import { getCurrentSearch } from 'app/common/selectors/url';
import { PATH_INDEX } from 'config/routePaths';

/**
 * navigates to the dashboard or the returnTo url
 * checks for "goto" in url search
 */
const loginSuccessRedirect = () => {
  return (dispatch, getState) => {
    const state = getState();
    const search = getCurrentSearch(state);
    let returnTo = queryString.parse(search).goto;
    if (returnTo) {
      returnTo = decodeURIComponent(returnTo);
      return dispatch(push(returnTo));
    }
    return dispatch(push(PATH_INDEX));
  };
};

export default loginSuccessRedirect;
