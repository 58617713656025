export type DeprecatedRelation = {
  id: string;
};

export function getDeprecatedRelation(relationId: string): DeprecatedRelation {
  return { id: relationId };
}

export function getDeprecatedNullableRelation(relationId: string | null): DeprecatedRelation | null {
  return relationId != null ? getDeprecatedRelation(relationId) : null;
}

/**
 * Accessor decorator - Display a console warning when the accessor property is call
 * To enable console warning: enableConsoleWarning = process.env.NODE_ENV !== 'production'
 */
export default function deprecated(enableConsoleWarning = false) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor): void => {
    const { set, get } = descriptor;

    if (get !== undefined) {
      descriptor.get = function deprecatedGet() {
        if (enableConsoleWarning) {
          console.warn(
            `[Deprecated] Update usage of ${target.constructor.name}.${propertyKey}, it will be removed in future versions.`
          );
        }
        return get.call(this);
      };
    }

    if (set !== undefined) {
      descriptor.set = function deprecatedSet(value: any) {
        if (enableConsoleWarning) {
          console.warn(
            `[Deprecated] Update usage of ${target.constructor.name}.${propertyKey}, it will be removed in future versions.`
          );
        }
        return set.call(this, value);
      };
    }
  };
}
