import useCurrentUserAcl from 'app/common/hooks/useCurrentUserAcl';
import get from 'lodash/get';

export default function useCurrentUserAclRight(right, options = {}) {
  const { userAcl, isLoading } = useCurrentUserAcl(options);

  if (isLoading) {
    return { aclRight: null, isLoading };
  }

  return { aclRight: get(userAcl, right, null), isLoading };
}
