import React from 'react';
import { HeadText6, DEFAULT_COLOR_PALETTE } from 'app/common/typography';
import Skeleton from 'app/common/components/skeleton';
import styles from './index.css';

export default function UserStatusAnnotationSkeleton() {
  return (
    <div data-testid="userStatusAnnotationSkeleton">
      <div className={styles.annotation}>
        <HeadText6 palette={DEFAULT_COLOR_PALETTE} className={styles.text}>
          <Skeleton width={100} height={22} />
        </HeadText6>
      </div>
    </div>
  );
}
