import GuidelineDataFile, { GuidelineDataFileType } from 'domain/guideline/data/file';
import GuidelineDataFileCollection from 'domain/guideline/data/fileCollection';
import FileStatus from 'domain/guideline/fileStatus';

export type GuidelineDataFilePayload = {
  id: string;
  url: string;
  type: GuidelineDataFileType;
  fileURL: string;
  status: FileStatus;
};

export default class GuidelineDataFileFactory {
  public static createFromApiPayload(filePayload: GuidelineDataFilePayload): GuidelineDataFile {
    return new GuidelineDataFile(
      filePayload.id,
      filePayload.type,
      filePayload.fileURL,
      filePayload.url,
      filePayload.status
    );
  }

  static createCollectionFromApiPayload(filesListPayload: GuidelineDataFilePayload[]): GuidelineDataFileCollection {
    return new GuidelineDataFileCollection(filesListPayload.map(GuidelineDataFileFactory.createFromApiPayload));
  }
}
