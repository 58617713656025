import useCurrentUser from 'app/common/hooks/useCurrentUser';
import { PROFILE_DISPLAY_NOROLE } from 'app/common/user/helpers/profile/constants';

type ResultType = {
  hasCurrentUserDisplayAccess: boolean;
};

export default function useCurrentUserDisplayAccess(): ResultType {
  const { currentUser, isLoading } = useCurrentUser();
  const hasDisplayAccess = currentUser?.profile?.display !== PROFILE_DISPLAY_NOROLE;

  if (isLoading) {
    return { hasCurrentUserDisplayAccess: false };
  }

  return { hasCurrentUserDisplayAccess: hasDisplayAccess };
}
