import { query } from 'app/common/actions/api';
import { fetchSuccess, fetchError } from 'app/common/user/actions';
import BasicUserFactory from 'domain/user/factory';

export default function fetchAllUsers() {
  return async dispatch => {
    try {
      const users = await dispatch(query('users'));

      const list = BasicUserFactory.createCollectionFromApiPayload(users);

      return dispatch(fetchSuccess(list));
    } catch (error) {
      dispatch(fetchError(error));
      throw error;
    }
  };
}
