import React from 'react';
import i18next from 'i18next';
import { PATH_NETWORK_REPORTS, PATH_SURVEY_REPORT_VISITS } from 'config/routePaths';
import NetworkLink from 'app/common/components/legacyNavMenu/menu/network/link';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useIsNetworkReportVisible from 'app/common/hooks/legacyNavMenu/useIsNetworkReportVisible';

const useReportLink = () => {
  const href = useParamSelector(getAbsoluteUrl, PATH_NETWORK_REPORTS);
  const isVisible = useIsNetworkReportVisible();
  const isActive = useLocationMatch(PATH_SURVEY_REPORT_VISITS, { exact: false });

  return {
    href,
    isVisible,
    isActive,
  };
};

const ReportLink = () => {
  const { isVisible, isActive, href } = useReportLink();
  if (!isVisible) {
    return null;
  }

  return (
    <NetworkLink isActive={isActive} href={href}>
      {i18next.t('reports')}
    </NetworkLink>
  );
};

export default ReportLink;
