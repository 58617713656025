import React, { MutableRefObject } from 'react';
import i18next from 'i18next';
import { ALT_COLOR_PALETTE, HeadText3 } from 'app/common/typography';
import styles from 'app/display/common/components/search/list.css';

type ListTypes = {
  children: React.ReactNode;
  isEmpty: boolean;
  listRef?: MutableRefObject<null>;
  dataTestId?: string;
};

const List = ({ children, isEmpty, listRef, dataTestId }: ListTypes) => {
  return (
    <div className={styles.list} ref={listRef} data-testid={dataTestId}>
      {isEmpty ? (
        <HeadText3 className={styles.emptyList} palette={ALT_COLOR_PALETTE}>
          {i18next.t('list.search.empty')}
        </HeadText3>
      ) : (
        children
      )}
    </div>
  );
};

export default List;
