import React from 'react';
import styles from 'app/display/common/components/actionBar/actionBarSeparator.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * A vertical seperator for the `ActionBar` component.
 *
 * @example ./actionBarSeparator.md
 */
const ActionBarSeparator = ({ className }) => {
  const classSet = classNames(styles.separator, className);
  return <div className={classSet} />;
};

ActionBarSeparator.propTypes = {
  className: PropTypes.string,
};

ActionBarSeparator.defaultProps = {
  className: null,
};
export default ActionBarSeparator;
