export default class PhotoTag {
  public fullTagName: string;

  public tag: string;

  public category?: string;

  constructor(tag: string) {
    const [firstTag, secondTag] = tag.split('/');
    if (secondTag) {
      this.category = firstTag;
      this.tag = secondTag;
    } else {
      this.tag = firstTag;
    }

    this.fullTagName = tag;
  }
}
