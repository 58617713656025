import { DEFAULT_COLOR_PALETTE, TextHoverArea } from 'app/common/typography';
import React from 'react';
import colorPalettes from 'app/common/typography/colors/palettes';
import { COLOR_PRIMARY, COLORS_ON } from 'app/common/style/colors';
import classNames from 'classnames';
import colorStyles from 'app/common/style/colors/index.css';

import paletteClassName from 'app/common/typography/colors/palettes/helpers/getClassName';
import styles from './style.css';

type InteractiveIconProps = {
  on?: (typeof COLORS_ON)[number];
  palette?: keyof typeof colorPalettes;
  disabled?: boolean;
  className?: string;
  children: NonNullable<React.ReactNode>;
};

export default function InteractiveIcon({
  on = COLOR_PRIMARY,
  className,
  palette = DEFAULT_COLOR_PALETTE,
  children,
  disabled = false,
}: InteractiveIconProps) {
  const { basic } = paletteClassName(colorPalettes[palette]);

  return (
    <TextHoverArea on={on} palette={palette} className={className}>
      <div
        className={classNames(styles.text, colorStyles[basic(on)], className, {
          [styles.isDisabled]: disabled === true,
        })}
      >
        {children}
      </div>
    </TextHoverArea>
  );
}
