import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import CurrentUserAvatar from 'app/common/user/components/currentUserAvatar';

import NetworkUserMenu from 'app/network/common/components/userMenu';
import isUserMenuVisible from 'app/common/selectors/appShell/isUserMenuVisible';
import useAction from 'app/display/common/hooks/useAction';
import toggleUserMenuVisibility from 'app/common/components/actions/toggleUserMenuVisibility';
import styles from './index.css';

export default function Settings() {
  const toggleMenu = useAction(toggleUserMenuVisibility);
  const isOpen = useSelector(isUserMenuVisible);

  return (
    <>
      <button
        type="button"
        className={classNames(styles.settings, {
          [styles.isOpen]: isOpen === true,
        })}
        onClick={toggleMenu}
      >
        <CurrentUserAvatar />
      </button>
      <NetworkUserMenu />
    </>
  );
}
