import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AvatarIwdLogo from 'assets/icons/iwd-logo.svg';
import styles from './iwdUserAvatar.css';

const IwdUserAvatar = ({ className }) => (
  <div className={classNames(styles.avatar, className)}>
    <AvatarIwdLogo className={classNames(styles.placeholder)} />
  </div>
);

IwdUserAvatar.propTypes = {
  className: PropTypes.string,
};

IwdUserAvatar.defaultProps = {
  className: null,
};

export default IwdUserAvatar;
