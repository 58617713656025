import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';

export const FETCH_SURVEY_CAMPAIGN_REQUEST = 'survey.fetch.campaign.request';
export const FETCH_SURVEY_CAMPAIGN_SUCCESS = 'survey.fetch.campaign.success';
export const FETCH_SURVEY_CAMPAIGN_ERROR = 'survey.fetch.campaign.error';

export const initialState = {
  entity: undefined,
  error: null,
  status: undefined,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case FETCH_SURVEY_CAMPAIGN_REQUEST:
      return {
        entity: null,
        error: null,
        status: STATUS_LOADING,
      };
    case FETCH_SURVEY_CAMPAIGN_SUCCESS:
      return {
        entity: action.campaign,
        error: null,
        status: STATUS_SUCCESS,
      };
    case FETCH_SURVEY_CAMPAIGN_ERROR:
      return {
        entity: undefined,
        error: action.error,
        status: STATUS_ERROR,
      };
    default:
      return state;
  }
}
