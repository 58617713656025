import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * Generic activity date component.
 * Expected to be used in components that display an entity activity (created at, updated at...).
 *
 * @example ./activityDate.md
 */
const ActivityDate = ({ date, format, className }) => {
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return '';
  }

  return (
    <time className={className} dateTime={date}>
      {format === 'now' ? momentDate.fromNow() : momentDate.format(format)}
    </time>
  );
};

ActivityDate.propTypes = {
  /**
   * The date as a string.
   */
  date: PropTypes.string.isRequired,
  /**
   * The Moment format used to format the date.
   */
  format: PropTypes.string,
  /**
   * Additional styles.
   */
  className: PropTypes.string,
};

ActivityDate.defaultProps = {
  format: 'now',
  className: null,
};

export default ActivityDate;
