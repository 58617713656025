import { useMemo } from 'react';
import { USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM_INITIAL_VALUES } from 'app/directory/user/sheet/common/constants';
import useUser from 'app/common/hooks/useUser';

export default function useEditUserNotificationsSettingsInitialValues(
  userId: string,
  initialValues: Record<string, boolean> = {}
) {
  const { user, isLoading } = useUser(userId);

  return useMemo(() => {
    if (isLoading) {
      return {
        initialValues: undefined,
        isLoading,
      };
    }

    return {
      initialValues: {
        ...USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM_INITIAL_VALUES,
        ...initialValues,
        ...user?.notificationSettings,
      },
      isLoading,
    };
  }, [isLoading, user, initialValues]);
}
