const svgIcons = require.context('assets', true, /.*\.svg$/);

function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
}

const icons = requireAll(svgIcons).reduce(
  (state, icon) => ({
    ...state,
    [icon.displayName]: icon,
  }),
  {}
);

export default icons;
