import { PRIMARY, SECONDARY } from 'app/display/common/components/block/theme/constants';

import primaryTheme from 'app/display/common/components/block/theme/primary.css';
import secondaryTheme from 'app/display/common/components/block/theme/secondary.css';

/**
 * Return the right skin class name corresponding to given theme.
 * If no valid theme is provided, use PRIMARY
 *
 * @param {string} theme - any valid theme. see all theme constants
 *
 * @return {string}
 */
export default function getSkinClassName(theme) {
  switch (theme) {
    case SECONDARY:
      return secondaryTheme['skin-secondary'];
    case PRIMARY:
    default:
      return primaryTheme['skin-primary'];
  }
}
