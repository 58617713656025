import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lib/helpers/noop';
import { HeadText6 } from 'app/common/typography';
import styles from './action.css';

export default class Action extends PureComponent {
  static propTypes = {
    icon: PropTypes.node,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    icon: null,
    onClick: noop,
    dataTestId: null,
  };

  renderIcon() {
    if (this.props.icon != null) {
      return <div className={styles.icon}>{this.props.icon}</div>;
    }

    return null;
  }

  render() {
    return (
      <div className={styles.action} onClick={this.props.onClick} data-testid={this.props.dataTestId}>
        {this.renderIcon()}
        <HeadText6 className={classNames(styles.uppercase, styles.label)}>{this.props.label}</HeadText6>
      </div>
    );
  }
}
