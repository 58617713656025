import React from 'react';
import classNames from 'classnames';
import escapeStringForRegex from 'app/display/common/helpers/escapeStringForRegex';
import styles from './highlightSearchTermInText.css';

export default function highlightSearchTermInText(text: string, searchTerm: string | undefined) {
  let result;
  if (typeof text !== 'string') return text;
  if (searchTerm) {
    let uniqKey = 0;
    const pattern = escapeStringForRegex(searchTerm);
    const searchWordRegex = new RegExp(pattern, 'gi');

    result = (
      <span key={`search-term-${uniqKey++}`}>
        {text.split(searchWordRegex).map(chunk => (
          <span
            key={`wrapper-${uniqKey++}`}
            className={classNames({ [styles.highlight]: chunk.toLowerCase() === searchTerm.toLowerCase() })}
          >
            {chunk}
          </span>
        ))}
      </span>
    );
  } else {
    result = <span>{text}</span>;
  }
  return result;
}
