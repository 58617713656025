import React from 'react';
import PlusMenu from 'app/display/common/components/plusMenu';
import { showModal } from 'app/common/actions/modals';
import { useDispatch } from 'react-redux';
import CreateGuidelineTagModal from 'app/network/tag/guideline/components/modals/create';

export const PLUS_BUTTON_GUIDELINE_TAG = 'plus-button-guideline-tag';

type GuidelineTagPagePlusMenuProps = {
  className?: string;
  setNewTagName: (value: string) => void;
};

const GuidelineTagPagePlusMenu = ({ className, setNewTagName }: GuidelineTagPagePlusMenuProps) => {
  const dispatch = useDispatch();

  const actions = [
    {
      label: '',
      icon: null,
      onClick: () => {
        dispatch(
          showModal(CreateGuidelineTagModal, {
            setNewTagName,
          })
        );
      },
      dataTestId: PLUS_BUTTON_GUIDELINE_TAG,
    },
  ];

  return (
    <div className={className}>
      <PlusMenu actions={actions} />
    </div>
  );
};
export default GuidelineTagPagePlusMenu;
