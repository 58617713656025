import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';
import {
  FETCH_SURVEY_REQUEST,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_ERROR,
} from 'app/network/survey/report/actions/survey/types';

export const initialState = {
  entity: undefined,
  status: undefined,
  error: null,
};

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case FETCH_SURVEY_REQUEST:
      return {
        entity: null,
        status: STATUS_LOADING,
        error: null,
      };
    case FETCH_SURVEY_SUCCESS:
      return {
        entity: action.survey,
        status: STATUS_SUCCESS,
        error: null,
      };
    case FETCH_SURVEY_ERROR:
      return {
        entity: undefined,
        error: action.error,
        status: STATUS_ERROR,
      };
    default:
      return state;
  }
}
