import React from 'react';
import DisplayUncontrolledCollapsePanel from 'app/display/common/components/collapsePanel/uncontrolled';
import NotificationRow from 'app/directory/user/sheet/view/components/notifications/actionRow';
import { useTranslation } from 'react-i18next';
import styles from 'app/directory/user/sheet/view/components/notifications/index.css';
import classNames from 'classnames';
import { BodyText1 } from 'app/common/typography';

const EmailReportNotificationsSection = ({
  userId,
  testPhotoReportHref,
  testSurveyReportHref,
  userIdV4,
  photoReportFrequency,
  isActivityReportEnabled,
  isSurveyOwnerActivityReportEnabled,
}: {
  userId: string;
  testPhotoReportHref: string;
  testSurveyReportHref: string;
  userIdV4: string;
  photoReportFrequency: string;
  isActivityReportEnabled: boolean;
  isSurveyOwnerActivityReportEnabled: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <DisplayUncontrolledCollapsePanel
      title={t('directory.userSheet.notificationsTab.emailReports.title')}
      classNameContent={styles.panelContent}
    >
      <NotificationRow name="isActivityReportEnabled" userId={userId} isActivityReportEnabled={isActivityReportEnabled}>
        <BodyText1 className={styles.infoActionText}>
          {t('directory.userSheet.notificationsTab.setting.isActivityReportEnabled.intoText.partOne')}
          {photoReportFrequency}
          {t('directory.userSheet.notificationsTab.setting.isActivityReportEnabled.intoText.partTwo')}
          <a
            href={testPhotoReportHref}
            id="directory-activityReport-test"
            className={classNames('directory-activityReport-test', styles.testLink)}
            data-userid={userIdV4}
            data-frequency="weekly"
            data-parts="#empty"
          >
            {t('directory.userSheet.notificationsTab.emailReports.testNotificationLink')}
          </a>
        </BodyText1>
      </NotificationRow>
      <NotificationRow
        name="isSurveyOwnerActivityReportEnabled"
        userId={userId}
        isSurveyOwnerActivityReportEnabled={isSurveyOwnerActivityReportEnabled}
      >
        <BodyText1 className={styles.infoActionText}>
          {t('directory.userSheet.notificationsTab.setting.isSurveyOwnerActivityReportEnabled.intoText')}
          <a
            href={testSurveyReportHref}
            id="directory-activityReport-test"
            className={classNames('pjax', 'directory-activityReport-test', styles.testLink)}
            data-parts="#empty"
            data-notification="#sending"
            data-history="false"
          >
            {t('directory.userSheet.notificationsTab.emailReports.testNotificationLink')}
          </a>
        </BodyText1>
      </NotificationRow>
    </DisplayUncontrolledCollapsePanel>
  );
};

export default EmailReportNotificationsSection;
