import UserNotification, { UserNotificationPayload } from 'domain/userNotifications';
import UserNotificationStateFactory from 'domain/userNotifications/state/factory';

export default class UserNotificationFactory {
  public static createFromApiPayload(notificationPayload: UserNotificationPayload): UserNotification {
    const state = UserNotificationStateFactory.createFromApiPayload(notificationPayload.state);
    return new UserNotification(
      notificationPayload.id,
      notificationPayload.titles,
      notificationPayload.contents,
      notificationPayload.type,
      notificationPayload.category,
      notificationPayload.recipientId,
      notificationPayload.subjectId,
      notificationPayload.thumbnailUrl,
      notificationPayload.context,
      state
    );
  }

  static createCollectionFromApiPayload(notificationListPayload: UserNotificationPayload[]): UserNotification[] {
    return notificationListPayload.map(UserNotificationFactory.createFromApiPayload);
  }
}
