import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { CATALOG_SEARCH_ITEM_CHECKBOX } from 'app/common/constants/userflow';
import styles from 'app/display/common/components/list/entry/selection.css';

const Selection = ({ isChecked, onChange, isDisabled }) => {
  const checkboxClassSet = classNames('checkbox', styles.selection);
  return (
    <input
      type="checkbox"
      className={checkboxClassSet}
      checked={isChecked}
      onChange={onChange}
      disabled={isDisabled}
      data-testid={CATALOG_SEARCH_ITEM_CHECKBOX}
    />
  );
};

Selection.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

Selection.defaultProps = {
  isDisabled: false,
};

export default Selection;
