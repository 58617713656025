import React from 'react';
import classNames from 'classnames';
import { HALF_CHECKED, CHECKED } from 'app/display/common/components/constants/checkboxStates';
import styles from './checkbox.css';

type CheckboxType = {
  state: string;
  onClick: () => void;
  disabled: boolean;
  dataTestId?: string;
  className?: string | null;
};

const Checkbox = ({ state, onClick, disabled, dataTestId, className = null }: CheckboxType) => {
  const checkboxClassSet = classNames('checkbox', styles.checkbox, className, {
    halfchecked: state === HALF_CHECKED,
  });

  return (
    <input
      type="checkbox"
      className={checkboxClassSet}
      checked={state === CHECKED}
      onClick={onClick}
      readOnly
      disabled={disabled}
      data-testid={dataTestId}
    />
  );
};

export default Checkbox;
