import { LegendText2, LegendText4, HeadText5, HeadText6, BodyText1, LegendText1 } from 'app/common/typography';
import React from 'react';
import FullUserAvatar from 'app/common/user/components/fullUserAvatar';
import FullUser from 'domain/user/fullUser';
import Thumbnail from 'app/common/components/thumbnail';
import UnavailableImage from 'assets/images/failed-image.svg';
import NewPhotoIcon from 'assets/icons/icons-photo-thread.svg';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './list.css';

export type CommentsListProps = {
  currentUser: FullUser;
  slideUser: FullUser;
  date: string;
  slideUrl: string;
};

// TODO: implement
const onNewPhoto = () => {
  console.warn('onNewPhoto');
};
const actionReply = () => {
  console.warn('reply');
};
const actionDelete = () => {
  console.warn('delete');
};

const msgData = {
  date: '5 hours',
  message: 'This image is a bit blurry, can you please retake it?',
  userName: 'John Doe',
};

export default function CommentsList({ currentUser, slideUser, slideUrl, date }: CommentsListProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.comments}>
      <div className={styles.tagsRow}>
        <LegendText2 className={styles.gray}>{t('slideshow.comments.versionPhoto', { version: '1' })}</LegendText2>
        <LegendText2 className={styles.green}>{t('slideshow.comments.libraryPhoto')}</LegendText2>
      </div>
      <div className={styles.photoRow}>
        <FullUserAvatar user={slideUser} className={styles.avatar} />
        <div className={styles.data}>
          <div className={styles.user}>
            <HeadText6 className={styles.userName}>{slideUser.name}</HeadText6>
            <LegendText4 className={styles.date}>{date}</LegendText4>
          </div>
          <div className={classnames(styles.photo, styles.selected)}>
            <Thumbnail src={slideUrl} Placeholder={UnavailableImage} />
          </div>
        </div>
      </div>
      <div className={styles.commentRow}>
        <div className={styles.messageHeader}>
          <FullUserAvatar user={currentUser} className={styles.avatar} />
          <div className={styles.user}>
            <HeadText6 className={styles.userName}>{msgData.userName}</HeadText6>
            <LegendText4 className={styles.date}>{msgData.date}</LegendText4>
          </div>
        </div>
        <div className={styles.messageBody}>
          <BodyText1 className={styles.message}>{msgData.message}</BodyText1>
          <div className={styles.actions}>
            <div onClick={actionReply}>
              <LegendText1 className={styles.action}>{t('slideshow.comments.reply')}</LegendText1>
            </div>
            <div onClick={actionDelete}>
              <LegendText1 className={styles.action}>{t('slideshow.comments.delete')}</LegendText1>
            </div>
          </div>
          <div className={styles.commentRow}>
            <div className={styles.messageHeader}>
              <FullUserAvatar user={currentUser} className={styles.avatar} />
              <div className={styles.user}>
                <HeadText6 className={styles.userName}>{msgData.userName}</HeadText6>
                <LegendText4 className={styles.date}>{msgData.date}</LegendText4>
              </div>
            </div>
            <div className={styles.messageBody}>
              <BodyText1 className={styles.message}>{msgData.message}</BodyText1>
              <div className={styles.actions}>
                <div onClick={actionReply}>
                  <LegendText1 className={styles.action}>{t('slideshow.comments.reply')}</LegendText1>
                </div>
                <div onClick={actionDelete}>
                  <LegendText1 className={styles.action}>{t('slideshow.comments.delete')}</LegendText1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.replyRow}>
        <FullUserAvatar user={currentUser} className={styles.avatar} />
        <div className={styles.reply}>
          <HeadText5 className={styles.replyText}>{t('slideshow.comments.addNew')}</HeadText5>
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <div onClick={onNewPhoto} className={styles.btnNewPhoto}>
          <NewPhotoIcon className={styles.icon} />
          <HeadText5 className={styles.btnText}>{t('slideshow.comments.newPhoto')}</HeadText5>
        </div>
      </div>
    </div>
  );
}
