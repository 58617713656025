import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_STORM, COLOR_FERN } from 'app/common/style/colors';

import generator from 'app/common/typography/colors/palettes/helpers/generator';

export const GUIDELINE_STATUS_COLOR_PALETTE = 'guideline-status-color-palette';

export default generator({
  basic: {
    [COLOR_PRIMARY]: COLOR_FERN,
    [COLOR_SECONDARY]: COLOR_STORM,
  },
  hover: {
    [COLOR_PRIMARY]: COLOR_FERN,
    [COLOR_SECONDARY]: COLOR_STORM,
  },
  active: {
    [COLOR_PRIMARY]: COLOR_STORM,
    [COLOR_SECONDARY]: COLOR_STORM,
  },
});
