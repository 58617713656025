import { matchPath, useLocation } from 'react-router-dom';

type MatchPathProps = { exact?: boolean; strict?: boolean };

export default function useLocationMatch(
  pathToMatch: string,
  { exact = undefined, strict = undefined }: MatchPathProps
): boolean {
  const { pathname } = useLocation();
  const match = matchPath(pathname, {
    path: pathToMatch,
    exact,
    strict,
  });

  return match !== null;
}
