import Cookies from 'js-cookie';
import getLoginPayloadFromCookie from 'app/common/helpers/auth/getLoginPayloadFromCookie';
import { LoginCookieType } from 'domain/auth/loginCookie';
import moment from 'moment';

const getOneYearFromNow = () => moment().add(1, 'y').hours(0).minutes(0).seconds(0).milliseconds(0).toDate();

export const IWD_LOGIN_COOKIE_NAME = 'iwd-login';

export default function setLoginPayloadCookie(
  { token, baseUrl }: Partial<LoginCookieType>,
  expires = getOneYearFromNow()
) {
  const loginPayloadFromCookie = getLoginPayloadFromCookie() as LoginCookieType | undefined | null;
  if (loginPayloadFromCookie != null) {
    const newLoginPayloadFromCookie = {
      token: token ?? loginPayloadFromCookie.token,
      baseUrl: baseUrl ?? loginPayloadFromCookie.baseUrl,
    } as Required<LoginCookieType>;
    try {
      Cookies.set(IWD_LOGIN_COOKIE_NAME, JSON.stringify(newLoginPayloadFromCookie), {
        expires,
        secure: true,
        path: `/${new URL(newLoginPayloadFromCookie.baseUrl).pathname.split('/')[1]}/`,
      });
    } catch (e) {
      // nothing to do
    }
  }
}
