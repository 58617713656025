import { createReducer } from '@reduxjs/toolkit';

export const GUIDELINE_EDITOR_ACTIONSPOPUP_OPEN = 'guideline.editor.view.actionsPopup.open';
export const GUIDELINE_EDITOR_ACTIONSPOPUP_CLOSE = 'guideline.editor.view.actionsPopup.close';
export const GUIDELINE_EDITOR_ACTIONSPOPUP_RESET = 'guideline.editor.view.actionsPopup.reset';

export const initialState = {
  visible: false,
};

const actionsPopupReducer = createReducer(initialState, builder => {
  builder
    .addCase(GUIDELINE_EDITOR_ACTIONSPOPUP_RESET, draftState => {
      draftState.visible = false;
    })
    .addCase(GUIDELINE_EDITOR_ACTIONSPOPUP_OPEN, draftState => {
      draftState.visible = true;
    })
    .addCase(GUIDELINE_EDITOR_ACTIONSPOPUP_CLOSE, draftState => {
      draftState.visible = false;
    });
});

export default actionsPopupReducer;
