import PhotoTag from 'domain/photo/tag';
import { OptionSearchDropDown } from 'app/display/common/helpers/searchDropDown/convertValuesToOptions';

function getGroupListOptions(groupList: string[] | undefined, newTags: PhotoTag[]) {
  const groupListOptions: OptionSearchDropDown[] = [];
  groupList?.forEach(group => {
    const groupExists = groupListOptions.some(groupListOption => groupListOption.value === group);
    if (!groupExists) {
      groupListOptions.push({ label: group, value: group });
    }
  });
  newTags.forEach(photoTag => {
    const groupExists = groupListOptions.some(groupListOption => groupListOption.value === photoTag.category);
    if (photoTag.category && !groupExists) {
      groupListOptions.push({ label: photoTag.category, value: photoTag.category });
    }
  });
  return groupListOptions;
}

export default getGroupListOptions;
