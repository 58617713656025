import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';

import loginSuccessRedirect from 'app/auth/login/actions';
import { authenticate } from 'app/common/actions/api';
import LoginForm from 'app/auth/login/components/form';
import reduxFormChangeOnEnterKeyDecorator from 'app/common/hoc/form';

import validate from './validate';

const LoginFormContainer = ({ error, onSubmit, handleSubmit }) => (
  <LoginForm handleSubmit={handleSubmit} onSubmit={onSubmit} error={error} />
);

LoginFormContainer.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired, // redux-form required to handle submission
  onSubmit: PropTypes.func.isRequired,
};

LoginFormContainer.defaultProps = {
  error: undefined,
};

const mapStateToProps = state => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: async values => {
      const { email, password } = values;
      try {
        await dispatch(authenticate(email, password));
        dispatch(loginSuccessRedirect());
      } catch (e) {
        throw new SubmissionError({
          // _error is a redux-form special key to display a general form error
          // it's passed as "error" prop in the decorated component
          // see https://redux-form.com/7.2.1/examples/submitvalidation/
          _error: e.response.data.message,
        });
      }
    },
  };
};

const ReduxFormComponent = reduxFormChangeOnEnterKeyDecorator(
  reduxForm({
    validate,
    touchOnChange: true,
  })(LoginFormContainer),
  'loginForm'
);

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormComponent);
