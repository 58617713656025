import React from 'react';
import SlideshowZoomInIcon from 'assets/icons/slideshow-zoom.svg';
import SlideshowZoomOutIcon from 'assets/icons/slideshow-zoomOut.svg';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import SlideshowButtonSeparator from 'app/common/components/slideshow/buttons/separator';
import Button from 'app/display/common/components/button';
import styles from './zoom.css';

type ZoomProps = {
  zoomIn: () => void;
  zoomOut: () => void;
  zoom: number;
  maxZoom: number;
};

export default function ZoomButton({ zoomIn, zoomOut, zoom, maxZoom }: ZoomProps) {
  const isZoomInDisabled = maxZoom === zoom;
  const isZoomOutDisabled = zoom === 1;

  return (
    <div className={styles.zoom}>
      <SlideshowButtonSeparator isLeft />
      <Button type="button" className="yarl__button" onClick={zoomIn} isDisabled={isZoomInDisabled}>
        <SlideshowButton Icon={SlideshowZoomInIcon} />
      </Button>
      <Button type="button" className="yarl__button" onClick={zoomOut} isDisabled={isZoomOutDisabled}>
        <SlideshowButton Icon={SlideshowZoomOutIcon} />
      </Button>
      <SlideshowButtonSeparator />
    </div>
  );
}
