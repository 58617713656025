import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';
import NonCriticalErrorBoundary from 'app/display/common/components/errorBoundary/nonCritical';
import GuidelineDocumentViewErrorBoundary from 'app/display/guideline/document/view/components/errorBoundary';

export const GuidelineDocumentViewPage = React.lazy(() =>
  import(
    /* webpackChunkName: "display-guideline-document-view" */ 'app/display/guideline/document/view/components/page'
  )
);

const GuidelineDocumentView = () => (
  <Suspense fallback={<Spinner />}>
    <NonCriticalErrorBoundary fallback={GuidelineDocumentViewErrorBoundary}>
      <GuidelineDocumentViewPage />
    </NonCriticalErrorBoundary>
  </Suspense>
);

export default GuidelineDocumentView;
