import useDisplayVersion from 'app/common/hooks/useDisplayVersion';

type PlatformResult = {
  isEnabled: boolean;
};

export default function usePlatform(): PlatformResult {
  const { matchV5 } = useDisplayVersion();
  return {
    isEnabled: matchV5,
  };
}
