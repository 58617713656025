import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText1, HeadText4 } from 'app/common/typography';
import ErrorUserNotifications from 'assets/icons/error-notifications.svg';
import styles from 'app/display/common/components/modals/userNotification/content/error.css';

const ErrorUserNotificationsList = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <ErrorUserNotifications className={styles.svg} />
      <HeadText4 className={styles.title}>{t(`display.modal.notifications.tab.error.title`)}</HeadText4>
      <BodyText1 className={styles.text}>{t(`display.modal.notifications.tab.error.description1`)}</BodyText1>
      <BodyText1 className={styles.text}>{t(`display.modal.notifications.tab.error.description2`)}</BodyText1>
    </div>
  );
};

export default ErrorUserNotificationsList;
