import React from 'react';
import { LegendText4 } from 'app/common/typography';
import styles from 'app/common/components/slideshow/buttons/pill.css';

const Pill = ({ countNumber }: { countNumber: number | undefined }) => {
  return (
    <LegendText4>
      <div className={styles.validationPill}>{countNumber}</div>
    </LegendText4>
  );
};

export default Pill;
