import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_HIBISCUS } from 'app/common/style/colors';

import generator from 'app/common/typography/colors/palettes/helpers/generator';

export const ERROR_COLOR_PALETTE = 'error-color-palette';

export default generator({
  basic: {
    [COLOR_PRIMARY]: COLOR_HIBISCUS,
    [COLOR_SECONDARY]: COLOR_HIBISCUS,
  },
  hover: {
    [COLOR_PRIMARY]: COLOR_HIBISCUS,
    [COLOR_SECONDARY]: COLOR_HIBISCUS,
  },
  active: {
    [COLOR_PRIMARY]: COLOR_HIBISCUS,
    [COLOR_SECONDARY]: COLOR_HIBISCUS,
  },
});
