import { SHOW_MODAL, HIDE_MODAL } from 'app/common/actions/modals/modalTypes';

export const showModal = (modal, props) => {
  return {
    type: SHOW_MODAL,
    payload: {
      modal,
      props,
    },
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};
