import React from 'react';
import useGuidelinesByStoreId from 'app/network/store/sheet/tabs/guidelines/hooks/useGuidelinesByStoreId';
import sortGuidelinesByCampaignName from 'app/network/store/sheet/tabs/guidelines/helpers/sortGuidelinesByCampaignName';
import StoreGuidelinesListCampaign from 'app/network/store/sheet/tabs/guidelines/components/guidelinesList/campaign';
import useCampaigns from 'app/display/guideline/common/hooks/useCampaigns';

import compareStringUsingNaturalOrder from 'lib/helpers/sort';
import first from 'lodash/first';
import styles from './index.css';

type StoreGuidelinesListProps = {
  storeId: string;
};
export default function StoreGuidelinesList({ storeId }: StoreGuidelinesListProps) {
  const { guidelines, isLoading } = useGuidelinesByStoreId(storeId);
  const { campaigns, isLoading: isLoadingCampaigns } = useCampaigns();

  if (isLoading || isLoadingCampaigns) {
    return null;
  }

  const guidelinesByCampaignName = sortGuidelinesByCampaignName(guidelines || [], campaigns || []);

  return (
    <div className={styles.guidelinesList}>
      {Object.keys(guidelinesByCampaignName)
        .sort(compareStringUsingNaturalOrder)
        .map(campaignName => {
          const guideline = first(guidelinesByCampaignName[campaignName]);
          const campaignId = guideline?.campaignId || null;

          return (
            <StoreGuidelinesListCampaign
              campaignName={campaignName}
              guidelines={guidelinesByCampaignName[campaignName]}
              key={campaignId}
              campaignId={campaignId}
            />
          );
        })}
    </div>
  );
}
