export const GUIDELINE_QUERY_CACHE_KEY = 'guideline';
export const GUIDELINES_QUERY_CACHE_KEY = 'guidelines';
export const GUIDELINES_GLOBAL_FILTERS_QUERY_CACHE_KEY = 'filters';
export const GUIDELINE_DATA_QUERY_CACHE_KEY = 'guidelineData';
export const GUIDELINE_DATA_EXPORT_QUERY_CACHE_KEY = 'guidelineDataExport';
export const CAMPAIGN_QUERY_CACHE_KEY = 'campaign';
export const INSTANCE_ACL_QUERY_CACHE_KEY = 'instanceAcl';
export const USER_ACL_QUERY_CACHE_KEY = 'userAcl';
export const CAMPAIGNS_QUERY_CACHE_KEY = 'campaigns';
export const MARKETS_QUERY_CACHE_KEY = 'markets';
export const PERSONAL_FOLDERS_QUERY_CACHE_KEY = 'personalFolders';
export const GLOBAL_FOLDERS_QUERY_CACHE_KEY = 'globalFolders';
export const ITEM_QUERY_CACHE_KEY = 'item';
export const ITEM_FILES_QUERY_CACHE_KEY = 'itemFiles';

export const ITEM_VARIANT_SHAPES_CACHE_KEY = 'itemVariantShapes';
export const ITEM_VARIANT_MODEL_3D_CACHE_KEY = 'itemVariantModel3Ds';
export const ITEM_TYPES_CACHE_KEY = 'itemTypes';
export const ITEM_MARKET_DATA_CACHE_KEY = 'itemMarketData';
export const ITEM_NAV_LIST_CACHE_KEY = 'itemNavList';
export const ITEMS_QUERY_CACHE_KEY = 'items';
export const ITEM_SEARCH_CACHE_KEY = 'itemSearch';
export const LINKED_ITEMS_CACHE_KEY = 'linkedItems';
export const ITEM_MODEL_3D_CACHE_KEY = 'itemModel3d';
export const CURRENCIES_CACHE_KEY = 'currencies';
export const INSTANCE_SETTINGS_CACHE_KEY = 'instanceSettings';
export const BRANDS_QUERY_CACHE_KEY = 'brands';
export const FOLDERS_QUERY_CACHE_KEY = 'folders';
export const MARKETS_CACHE_KEY = 'markets';
export const GUIDELINE_TAGS_CACHE_KEY = 'guidelineTags';
export const CURRENT_USER_QUERY_CACHE_KEY = 'currentUser';
export const PRESET_VARIANTS_QUERY_CACHE_KEY = 'presetVariants';
export const PRESET_VARIANT_QUERY_CACHE_KEY = 'presetVariant';
export const GUIDELINE_GLOBAL_FILTERS = 'guidelineGlobalFilters';
export const USERS_QUERY_CACHE_KEY = 'users';
export const USER_LICENSE_DATA_CACHE_KEY = 'userLicenseData';
export const LICENSE_PROVISIONING_DATA_CACHE_KEY = 'licenseProvisioningData';
export const AVAILABLE_ACTIVITIES_QUERY_CACHE_KEY = 'availableActivities';
export const AVAILABLE_STORES_CACHE_KEY = 'availableStores';
export const SELECTED_STORES_CACHE_KEY = 'selectedStores';
export const STORES_CACHE_KEY = 'stores';
export const GUIDELINES_BY_STORE_ID = 'guidelinesByStoreId';
export const PHOTOS_CACHE_KEY = 'photos';
export const PHOTO_TAGS_CACHE_KEY = 'photoTags';
export const PHOTO_FOLDER_CACHE_KEY = 'photoFolder';
export const USER_NOTIFICATIONS_CACHE_KEY = 'notifications';
export const STORE_TABS_CACHE_KEY = 'storeTabs';
export const DRAWINGS_CACHE_KEY = 'drawings';

// Use this with cacheTime: 0 when we do not need caching
export const GENERAL_QUERY_KEY_FOR_NO_CACHING = 'general-query-key-do-not-use-this-cache';
