import React from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import HelpIcon from 'assets/icons/help.svg';
import { BodyText2 } from 'app/common/typography';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';

import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import { PATH_HELP_CENTER } from 'config/routePaths';
import styles from './index.css';

const Help = () => {
  const instanceBaseUrl = useSelector(getInstanceBaseUrl);

  return (
    <UserMenuLink to={`${instanceBaseUrl}${PATH_HELP_CENTER}`} newTab={true}>
      <UserMenuIcon IconComponent={HelpIcon} />
      <BodyText2 className={styles.uppercase}>{i18next.t('display.user.popup.link.help')}</BodyText2>
    </UserMenuLink>
  );
};

export default Help;
