import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';

export const ItemEditorPage = React.lazy(() =>
  import(/* webpackChunkName: "display-item-editor-page" */ 'app/display/2dEditor/fixture')
);

const ItemEditor = () => (
  <Suspense fallback={<Spinner />}>
    <ItemEditorPage />
  </Suspense>
);

export default ItemEditor;
