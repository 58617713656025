export const PROFILE_DISPLAY_NOROLE = 'norole';
export const PROFILE_DISPLAY_IWD = 'iwd';
export const PROFILE_DISPLAY_SUPERADMIN = 'superadmin';
export const PROFILE_DISPLAY_SAMARKET = 'samarket';
export const PROFILE_DISPLAY_ADMIN = 'admin';
export const PROFILE_DISPLAY_ENDUSER = 'enduser';

export const PROFILE_NETWORK_NOROLE = 'norole';
export const PROFILE_NETWORK_IWD = 'iwd';
export const PROFILE_NETWORK_INTER = 'inter';
export const PROFILE_NETWORK_ZONE = 'zone';
export const PROFILE_NETWORK_COUNTRY = 'country';
export const PROFILE_NETWORK_SALES = 'sales';
export const PROFILE_NETWORK_POS = 'pos';
export const PROFILE_NETWORK_BASIC = 'rnreadonly_plus';
export const PROFILE_NETWORK_FREE = 'rnreadonly';
