import React from 'react';
import NetworkPjaxLink from 'app/network/common/components/pjaxLink';
import { HeadText4, LINK_COLOR_PALETTE } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import useCanEditUser from 'app/directory/user/sheet/common/hooks/useCanEditUser';

export const ACTION_BAR_EDIT_BUTTON_TEST_ID = 'actionBarEditButton';

export default function DirectoryActionBarEditButton({ modifyUrl, userId }: { modifyUrl: string; userId: string }) {
  const { t } = useTranslation();
  const { canEditUser, isLoading } = useCanEditUser(userId);

  if (isLoading || !canEditUser) {
    return null;
  }

  return (
    <NetworkPjaxLink linkData={{ href: modifyUrl, replaceId: '#main' }} dataTestId={ACTION_BAR_EDIT_BUTTON_TEST_ID}>
      <HeadText4 interactive={true} palette={LINK_COLOR_PALETTE} className="uppercase">
        {t('directory.actionsPopup.actions.modify')}
      </HeadText4>
    </NetworkPjaxLink>
  );
}
