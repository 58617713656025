import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import DirectoryIcon from 'assets/icons/directory.svg';
import { PATH_DIRECTORY_INDEX } from 'config/routePaths';
import { BodyText2 } from 'app/common/typography';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';

import styles from './index.css';

const Directory = () => {
  const instanceBaseUrl = useSelector(getInstanceBaseUrl);
  return (
    <UserMenuLink to={`${instanceBaseUrl}${PATH_DIRECTORY_INDEX}`}>
      <UserMenuIcon IconComponent={DirectoryIcon} />
      <BodyText2 className={styles.uppercase}>{i18next.t('display.user.popup.link.directory')}</BodyText2>
    </UserMenuLink>
  );
};

export default Directory;
