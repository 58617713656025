import React, { MouseEvent, useEffect } from 'react';
import { HeadText4, LINK_COLOR_PALETTE } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import useAction from 'app/display/common/hooks/useAction';
import { submit } from 'redux-form';
import { USER_SHEET_LICENSE_TAB_FORM } from 'app/directory/user/sheet/common/constants';
import LegacyEvents from 'app/network/common/constants/legacyEvents';
import { useSelector } from 'react-redux';
import isUserSheetSaveButtonEnabled from 'app/directory/user/sheet/common/selectors/isUserSheetSaveButtonEnabled';
import { enableUserSheetSaveButton } from 'app/directory/user/sheet/common/actions';

export const USER_SHEET_SAVE_BUTTON_TEST_ID = 'userSheetSaveButton';

type UserSheetSaveButtonProps = {
  [key: string]: any;
};

export default function UserSheetSaveButton(props: UserSheetSaveButtonProps) {
  const { t } = useTranslation();
  const submitForm = useAction(submit);
  const isSaveButtonEnabled = useSelector(isUserSheetSaveButtonEnabled);
  const enableSaveButton = useAction(enableUserSheetSaveButton);

  const handleSubmitButtonEnable = () => {
    enableSaveButton();
  };

  useEffect(() => {
    document.addEventListener(LegacyEvents.ENABLE_SAVE_BUTTON, handleSubmitButtonEnable);

    return () => document.removeEventListener(LegacyEvents.ENABLE_SAVE_BUTTON, handleSubmitButtonEnable);
  });

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    submitForm(USER_SHEET_LICENSE_TAB_FORM);
  };

  return (
    <button
      onClick={onClick}
      {...props}
      type="button"
      disabled={!isSaveButtonEnabled}
      data-testid={USER_SHEET_SAVE_BUTTON_TEST_ID}
    >
      <HeadText4 interactive={true} palette={LINK_COLOR_PALETTE}>
        {t('button.save')}
      </HeadText4>
    </button>
  );
}
