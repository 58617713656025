import React from 'react';
import { addToolbarButton, PluginProps } from 'yet-another-react-lightbox';
import TagPhotoIcon from 'app/common/components/slideshow/tagPhoto/icon';

export const SLIDESHOW_TAG_PHOTO_PLUGIN_ID = 'tagPhoto';
export default function TagPhoto({ augment }: PluginProps) {
  augment(({ toolbar, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_TAG_PHOTO_PLUGIN_ID, <TagPhotoIcon />),
      ...restProps,
    };
  });
}
