import React, { ReactElement, useCallback, useState } from 'react';
import useTimeout from 'app/display/common/hooks/useTimeout';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import classNames from 'classnames';
import Modal from 'app/display/common/components/modals';
import Title from 'app/display/common/components/modals/processingConfirmModal/title';
import SubTitle from 'app/display/common/components/modals/processingConfirmModal/subtitle';
import { BodyText1 } from 'app/common/typography';
import WizardButtons from 'app/display/common/components/modals/wizard/buttons';
import { AnimatePresence } from 'framer-motion';
import WizardBackButton from 'app/display/common/components/modals/wizard/backButton';
import styles from './index.css';

type WizardStep<T> = {
  id: string;
  component: (props: WizardModalStepProps<T>) => ReactElement;
  title: string;
  finalStep?: boolean;
  shouldNotClose?: boolean;
  confirmLabel?: string;
  hasNextButton?: boolean;
  hasPreviousButton?: boolean;
  stepData: T;
};

export type WizardModalProps<T> = {
  onClose: (args?: any) => void;
  closeEnabled?: boolean;
  submitOnEnter?: boolean;
  subTitle?: string;
  contentClassName?: string;
  steps: WizardStep<T>[];
};

type WizardConfirmHandlers = {
  isLoading?: boolean;
  confirmEnabled?: boolean;
  onConfirm?: () => Promise<unknown>;
  onConfirmSuccess?: (args: unknown) => void;
  onConfirmError?: (args: unknown) => void;
};

export interface WizardModalStepProps<T> {
  nextStep: (args?: unknown) => void;
  previousStep: () => void;
  goToStep: (step: number) => void;
  previousStepData: unknown;
  setConfirmHandlers: (confirmHandler: WizardConfirmHandlers) => void;
  stepData: T;
}

export const PREVIOUS_ARROW_TEST_ID = 'previousArrow';

export default function WizardModal<T>({ onClose, subTitle, contentClassName, steps }: WizardModalProps<T>) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [previousStepNumber, setPreviousStep] = useState<number | null>(null);
  const [previousStepData, setPreviousStepData] = useState(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isFadeOut, setIsFadOut] = useState(false);
  const { setCallback } = useTimeout();
  const [confirmHandlers, setConfirmHandlers] = useState<WizardConfirmHandlers>({
    isLoading: false,
    confirmEnabled: false,
  });

  const nextStep = (data: any) => {
    if (currentStep < steps.length - 1) {
      setPreviousStep(currentStep);
      setCurrentStep(currentStep + 1);
      setPreviousStepData(data);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(previousStepNumber != null ? previousStepNumber : currentStep - 1);
    }
  };

  const goToStep = (step: number) => {
    if (step <= steps.length) {
      setPreviousStep(currentStep);
      setCurrentStep(step - 1);
    }
  };

  const handleClose = useCallback(() => {
    if (!isProcessing && !isFadeOut) {
      setIsFadOut(true);
      setCallback(() => () => {
        onClose();
      });
    }
  }, [isProcessing, isFadeOut, onClose, setCallback]);

  const handleConfirm = useCallback(async () => {
    if (confirmHandlers.confirmEnabled && !isProcessing) {
      setIsProcessing(true);
      try {
        const result = await confirmHandlers.onConfirm?.();
        if (!steps[currentStep].shouldNotClose) {
          handleClose();
        }
        setIsProcessing(false);
        setCallback(() => () => {
          confirmHandlers.onConfirmSuccess?.(result);
        });
      } catch (exception) {
        assertIsNotCriticalHttpException(exception);
        setIsProcessing(false);
        handleClose();
        setCallback(() => () => {
          confirmHandlers.onConfirmError?.(exception);
        });
      }
    }
  }, [isProcessing, confirmHandlers, handleClose, setCallback]);

  const contentClass = classNames(styles.label, {
    [styles.hasSubtitle]: subTitle,
    [contentClassName || '']: contentClassName,
    [styles.disabled]: isProcessing,
  });

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <Modal
      isLoading={isProcessing || (confirmHandlers.isLoading ?? false)}
      onClose={handleClose}
      onClick={handleConfirm}
      isFadeOut={isFadeOut}
      headerLeft={
        <div className={styles.headerLeft}>
          <AnimatePresence>
            {previousStepNumber != null && currentStep > 0 && (
              <WizardBackButton onClick={previousStep} disabled={isProcessing} />
            )}
          </AnimatePresence>
          <Title title={steps[currentStep].title} />
        </div>
      }
      headerRight={
        <WizardButtons
          onClose={handleClose}
          onConfirm={handleConfirm}
          confirmEnabled={!isProcessing && (confirmHandlers.confirmEnabled || false)}
          confirmLabel={steps[currentStep].confirmLabel}
          closeEnabled={!isProcessing}
          hasConfirm={steps[currentStep].finalStep}
        />
      }
    >
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <BodyText1 className={contentClass} disabled={isProcessing}>
        <AnimatePresence initial={false} mode="wait">
          <CurrentStepComponent
            nextStep={nextStep}
            previousStep={previousStep}
            goToStep={goToStep}
            previousStepData={previousStepData}
            setConfirmHandlers={setConfirmHandlers}
            stepData={steps[currentStep].stepData}
          />
        </AnimatePresence>
      </BodyText1>
    </Modal>
  );
}
