import React from 'react';
import { Field } from 'redux-form';
import CheckboxLabel from 'app/display/common/components/checkboxlabel';
import { HeadText5 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './index.css';

export default function NotificationsSettingActionRow({ name, children }: { name: string; children: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <Field component={CheckboxLabel} id={name} name={name} className={styles.settingRow} labelStyles={styles.label}>
      <HeadText5>{t(`directory.userSheet.notificationsTab.setting.${name}.label`)}</HeadText5>
      {children}
    </Field>
  );
}
