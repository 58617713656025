import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lib/helpers/noop';

import './index.css';

/**
 * A Mask that prevent user interactions on its children.
 * It can be dimmed or blurred.
 *
 * @example ./index.md
 */

const Mask = ({ className, dim, blur, onClick, children, 'data-testid': dataTestId }) => {
  const isActive = dim === true || blur === true;
  const behavior = classNames('can-dim', {
    'is-dimmed': dim === true,
    'is-blurred': blur === true,
    'is-active': isActive,
  });

  const handleClick = useCallback(() => {
    if (isActive) {
      onClick();
    }
  }, [onClick, isActive]);

  const classSet = classNames(className, 'mask', behavior);

  return (
    <div className={classSet} onClick={handleClick} data-testid={dataTestId}>
      {children}
    </div>
  );
};

Mask.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  dim: PropTypes.bool,
  blur: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Mask.defaultProps = {
  className: null,
  children: null,
  'data-testid': null,
  dim: false,
  blur: false,
  onClick: noop,
};

export default Mask;
