import State from 'domain/state';
import FileStatus from 'domain/guideline/fileStatus';

export default class FileState extends State {
  constructor(
    public readonly creatorId: string,
    public readonly modifierId: string,
    public readonly creationDate: Date = new Date(),
    public readonly modificationDate: Date = new Date(),
    public readonly status: FileStatus = FileStatus.READY
  ) {
    super(creatorId, modifierId, creationDate, modificationDate);
  }

  public isGenerating(): boolean {
    return this.status === FileStatus.GENERATING;
  }

  public isReady(): boolean {
    return this.status === FileStatus.READY;
  }
}
