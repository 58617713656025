import React from 'react';
import classNames from 'classnames';
import NetworkPjaxLink from 'app/network/common/components/pjaxLink';
import ArrowIcon from 'assets/icons/arrow.svg';
import styles from './arrowButtons.css';

type DirectoryActionBarArrowButtonsProps = {
  nextUserUrl: string | null;
  previousUserUrl: string | null;
};

export default function DirectoryActionBarArrowButtons({
  nextUserUrl,
  previousUserUrl,
}: DirectoryActionBarArrowButtonsProps) {
  return (
    <div className={styles.arrowButtons}>
      {previousUserUrl ? (
        <NetworkPjaxLink
          linkData={{ href: previousUserUrl, replaceId: '#main' }}
          dataTestId="actionBarPreviousArrow"
          className={styles.arrowLink}
        >
          <ArrowIcon className={styles.icon} />
        </NetworkPjaxLink>
      ) : (
        <ArrowIcon className={classNames(styles.icon, styles.disabled)} />
      )}
      {nextUserUrl ? (
        <NetworkPjaxLink
          linkData={{ href: nextUserUrl, replaceId: '#main' }}
          dataTestId="actionBarNextArrow"
          className={styles.arrowLink}
        >
          <ArrowIcon className={classNames(styles.icon, styles.arrowNext)} />
        </NetworkPjaxLink>
      ) : (
        <ArrowIcon className={classNames(styles.icon, styles.disabled, styles.arrowNext)} />
      )}
    </div>
  );
}
