import React from 'react';
import PropTypes from 'prop-types';
import getErrorBoundaryComponent from 'lib/bugsnag/helpers/getErrorBoundaryComponent';
import UnexpectedErrorBoundary from 'app/common/components/errors/boundary/unexpected';
import Error from 'app/common/components/errors/error';

const ErrorBoundary = ({ children }) => {
  const BugsnagErrorBoundary = getErrorBoundaryComponent();

  return (
    <BugsnagErrorBoundary DefaultErrorBoundaryComponent={UnexpectedErrorBoundary} FallbackComponent={Error}>
      {children}
    </BugsnagErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
