import { PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpDeleteRequest from 'app/common/hooks/useHttpDeleteRequest';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import queryClient from 'lib/queryClient';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import { SlideImage } from 'yet-another-react-lightbox';

export default function useAprovePhoto(photo: SlideImage | undefined, isCurrentUserApproval: boolean | undefined) {
  const putApprovePhoto = useHttpPutRequest<SlideImage>(`/stores/photos/${photo?.id}/approvals`);
  const deleteApprovePhoto = useHttpDeleteRequest<SlideImage>(`/stores/photos/${photo?.id}/approvals`);
  const handleApprovePhoto = isCurrentUserApproval ? deleteApprovePhoto : putApprovePhoto;

  const refreshLegacyPhoto = useLegacyEvent('UPDATE_PHOTO');

  const onSuccess = () => {
    queryClient.getInstance().invalidateQueries([PHOTOS_CACHE_KEY, photo?.id]);
    refreshLegacyPhoto({ photoId: photo?.idv4 });
  };

  const { mutateAsync } = useHttpMutation(handleApprovePhoto, { onSuccess });

  return async () => {
    await mutateAsync({});
  };
}
