import deprecated from 'lib/deprecated';

/**
 * Common features of all webapp users.
 */
export default class User {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly photoUrl: string | undefined = undefined,
    public readonly isIwd: boolean = false,
    public readonly idv4: number | null = null
  ) {
    // do nothing.
  }

  /**
   * @deprecated use `photoUrl` instead.
   */
  @deprecated()
  get photo(): { url: string } | undefined {
    if (this.photoUrl === undefined) {
      return undefined;
    }

    return {
      url: this.photoUrl,
    };
  }
}
