import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.css';

const Thumbnail = ({ src, alt, className, Placeholder, dataTestId }) => {
  const placeholderClassSet = classNames(styles.placeholder, className);

  return src != null ? (
    <img className={className} src={src} alt={alt} data-testid={dataTestId} />
  ) : (
    <Placeholder className={placeholderClassSet} />
  );
};

Thumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  Placeholder: PropTypes.elementType,
  dataTestId: PropTypes.string,
};

Thumbnail.defaultProps = {
  className: null,
  src: null,
  alt: null,
  Placeholder: null,
  dataTestId: null,
};

export default Thumbnail;
