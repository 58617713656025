import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import useDropZone from 'app/display/common/hooks/useDropZone';
import dropPayloadPropTypes from 'app/display/common/propTypes/dropPayload';
import { DRAG_TYPE_FOLDER, DRAG_TYPE_ITEM, DRAG_TYPE_PHOTO } from 'app/display/common/constants/dragDrop';

const DropZone = ({ dropPayload, acceptedDragTypes, canDrop, children }) => {
  const { dropEnabled, isOver, drop } = useDropZone(dropPayload, acceptedDragTypes, canDrop);

  // We use global classes because it's a global state, thus it's easier
  // for styling children components depending on drag and drop state
  const className = classNames('dropZone', {
    isHovered: isOver,
    isDisabled: !dropEnabled,
  });

  return (
    <div ref={drop} className={className}>
      {children}
    </div>
  );
};

DropZone.propTypes = {
  children: PropTypes.node.isRequired,
  dropPayload: dropPayloadPropTypes.isRequired,
  acceptedDragTypes: PropTypes.arrayOf(PropTypes.oneOf([DRAG_TYPE_ITEM, DRAG_TYPE_FOLDER, DRAG_TYPE_PHOTO])).isRequired,
  canDrop: PropTypes.func,
};

DropZone.defaultProps = {
  canDrop: () => true,
};

export default DropZone;
