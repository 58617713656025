import React, { ReactElement } from 'react';
import classNames from 'classnames';

import styles from 'app/display/common/components/button/style.css';

export type ButtonProps = {
  children: ReactElement;
  type?: 'button' | 'reset' | 'submit';
  className?: string | null;
  isDisabled?: boolean;
  dataTestId?: string | null;
  submitting?: boolean;
  onClick?: (args: any) => any;
};

/**
 * Basic Button, all styles reset
 *
 * @example ./index.md
 */
const Button = ({
  children,
  type = 'button',
  className = null,
  isDisabled = false,
  dataTestId = null,
  submitting = false,
  ...props
}: ButtonProps) => (
  /* eslint-disable react/button-has-type */
  <button
    type={type}
    className={classNames(styles.button, className)}
    disabled={isDisabled || submitting}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </button>
  /* eslint-enable react/button-has-type */
);

export default Button;
