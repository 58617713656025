import GuidelineDataSettings from 'domain/guideline/data/settings';
import GuidelineDataFileCollection from 'domain/guideline/data/fileCollection';

export default class GuidelineData {
  constructor(
    public id: string,
    public guidelineId: string,
    public guidelineName: string,
    // TODO change when guideline (data?) markets implemented
    public guidelineMarkets: any[],
    public guidelineCampaignName: string,
    public settings: GuidelineDataSettings,
    public files: GuidelineDataFileCollection,
    // TODO change when guideline data children implemented
    public children: any[],
    public guidelineDescription: string | null = null,
    public guidelineStart: string | null = null,
    public guidelineEnd: string | null = null,
    public temporaryResourceRetryAfter: Date | undefined = undefined
  ) {
    // do nothing.
  }
}
