import React from 'react';
import { LegendText4 } from 'app/common/typography';
import NotificationBellIcon from 'assets/icons/notificationBell.svg';
import { useDispatch } from 'react-redux';
import { showModal } from 'app/common/actions/modals';
import UserNotification from 'app/display/common/components/modals/userNotification';
import useOffCanvasPlatformMenu from 'app/common/components/offCanvasPlatformMenu/index.hook';
import useUserNotificationsEnabled from 'app/display/common/hooks/modals/userNotifications/useUserNotificationsEnabled';
import styles from './userNotificationCenter.css';

const UserNotificationCenter = () => {
  const dispatch = useDispatch();
  const { closeNavigation } = useOffCanvasPlatformMenu();
  const { notificationsByCategory, isLoading } = useUserNotificationsEnabled();
  const { unreadCount } = notificationsByCategory.ALL;
  const showCounter = !isLoading && unreadCount !== 0;

  const handleOnClick = () => {
    closeNavigation();
    dispatch(showModal(UserNotification));
  };
  return (
    <div className={styles.notificationWrapper} onClick={handleOnClick}>
      <NotificationBellIcon className={styles.notificationBell} />
      {showCounter && <LegendText4 className={styles.notificationNumber}>{unreadCount}</LegendText4>}
    </div>
  );
};

export default UserNotificationCenter;
