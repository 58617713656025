import PropTypes from 'prop-types';
import React from 'react';

import LegacyNavMenu from 'app/common/components/legacyNavMenu';
import PlatformMenu from 'app/common/components/platformMenu';
import OffCanvasMenu from 'app/common/components/offCanvasMenu';
import OffCanvasPlatformMenu from 'app/common/components/offCanvasPlatformMenu';
import ContentMask from 'app/common/components/appShell/contentMask';
import GlobalMask from 'app/common/components/appShell/globalMask';
import LoadingBar from 'app/common/components/appShell/loadingBar';

export default function AppShell({ children }) {
  return (
    <>
      <OffCanvasMenu />
      <OffCanvasPlatformMenu />
      <LoadingBar />
      <GlobalMask>
        <LegacyNavMenu />
        <PlatformMenu />
        <ContentMask>{children}</ContentMask>
      </GlobalMask>
    </>
  );
}

AppShell.propTypes = {
  children: PropTypes.node.isRequired,
};
