import { CRITERIA, CRITERION_LAST_UPDATE } from 'app/display/guideline/list/common/sortCriteria';
import getSortCriterionFromSavedListPreferences from 'app/display/guideline/list/common/selectors/savedPreferences/getSortCriterionFromSavedListPreferences';
import { AppState } from 'lib/providers/store';

const isCriterionValid = (criterion: string) => CRITERIA.includes(criterion);

export default function getDefaultSortCriterion(state: AppState) {
  const sortCriterionFromSavedListPreferences = getSortCriterionFromSavedListPreferences(state);
  if (isCriterionValid(sortCriterionFromSavedListPreferences)) {
    return sortCriterionFromSavedListPreferences;
  }

  return CRITERION_LAST_UPDATE;
}
