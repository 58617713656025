import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './animatedIwdLogo.css';

const AnimatedIwdLogo = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 175" className={classNames(styles.appSpinner, className)}>
    <g className={styles.letterI}>
      <rect className={styles.letter} x="0" y="0" width="30" height="6" />
      <rect className={styles.letter} x="0" y="13" width="30" height="6" />
      <rect className={styles.letter} x="0" y="26" width="30" height="6" />
      <rect className={styles.letter} x="0" y="39" width="30" height="6" />
      <rect className={styles.letter} x="0" y="52" width="30" height="6" />
      <rect className={styles.letter} x="0" y="65" width="30" height="6" />
      <rect className={styles.letter} x="0" y="78" width="30" height="6" />
      <rect className={styles.letter} x="0" y="91" width="30" height="6" />
      <rect className={styles.letter} x="0" y="104" width="30" height="6" />
      <rect className={styles.letter} x="0" y="117" width="30" height="6" />
      <rect className={styles.letter} x="0" y="130" width="30" height="6" />
      <rect className={styles.letter} x="0" y="143" width="30" height="6" />
      <rect className={styles.letter} x="0" y="156" width="30" height="6" />
      <rect className={styles.letter} x="0" y="169" width="30" height="6" />
    </g>
    <g className={styles.letterW}>
      <polygon className={styles.letter} points="48.1,0.5 113.9,169.1 116.6,162.1 53.8,0.5 " />
      <polygon className={styles.letter} points="61.5,0.5 120.5,152.1 123.4,144.8 67.2,0.5 " />
      <polygon className={styles.letter} points="75,0.5 127.3,134.9 130.1,127.5 80.5,0.5 " />
      <polygon className={styles.letter} points="117.7,75.5 134,117.6 136.9,110.3 120.5,68.2 " />
      <polygon className={styles.letter} points="124.4,58.2 140.8,100.3 143.6,93 127.3,50.9 " />
      <polygon className={styles.letter} points="131.2,41 180.6,167.5 183.4,160.4 134.1,33.8 " />
      <polygon className={styles.letter} points="138,24 187.2,150.6 190.1,143.2 140.9,16.9 " />
      <polygon className={styles.letter} points="147.7,0 144.9,7.1 194,133.3 196.9,126 " />
      <polygon className={styles.letter} points="184.6,74.6 200.7,116 203.6,108.7 187.4,67.2" />
      <polygon className={styles.letter} points="191.3,57.1 207.5,98.8 210.4,91.4 194.1,49.7 " />
      <polygon className={styles.letter} points="198,39.7 214.2,81.5 217.1,74.2 200.8,32.3 " />
      <polygon className={styles.letter} points="204.6,22.2 221,64.2 223.8,56.9 207.5,14.8 " />
      <polygon className={styles.letter} points="213,0.5 211.3,4.8 227.7,47 230.6,39.7 215.3,0.5 " />
      <polygon className={styles.letter} points="223.1,0.5 234.5,29.7 237.3,22.4 228.8,0.5 " />
      <polygon className={styles.letter} points="236.2,0.5 241,13 243.9,5.6 241.9,0.5 " />
    </g>
    <g className={styles.letterD}>
      <rect className={styles.letter} x="263" y="0.5" width="5.3" height="168" />
      <rect className={styles.letter} x="275.1" y="0.5" width="5.3" height="168" />
      <rect className={styles.letter} x="287.3" y="0.5" width="5.3" height="168" />
      <path className={styles.letter} d="M304.7,0.5l0,168c-0.1,0-0.1,0-0.2,0h-5.1V0.5h5.1L304.7,0.5z" />
      <path
        className={styles.letter}
        d="M311.5,0.7v167.5c1.8-0.1,3.6-0.3,5.3-0.5l0-166.5C315.1,1,313.3,0.8,311.5,0.7"
      />
      <path
        className={styles.letter}
        d="M323.6,2.4v164.1c1.8-0.4,3.6-0.8,5.3-1.3l0-161.6C327.2,3.2,325.4,2.7,323.6,2.4"
      />
      <path className={styles.letter} d="M335.7,5.8V163c1.8-0.7,3.6-1.4,5.3-2.1L341,8C339.3,7.2,337.5,6.5,335.7,5.8" />
      <path
        className={styles.letter}
        d="M347.8,11.4v146.1c1.8-1,3.6-2.1,5.3-3.2l0-139.6C351.4,13.5,349.7,12.4,347.8,11.4"
      />
      <path
        className={styles.letter}
        d="M360,19.7v129.6c1.8-1.5,3.6-3.1,5.3-4.7l0-120.1C363.6,22.7,361.8,21.2,360,19.7"
      />
      <path
        className={styles.letter}
        d="M372.1,32.2v104.6c1.9-2.6,3.7-5.3,5.3-8.2l0-88.3C375.8,37.5,374,34.8,372.1,32.2"
      />
      <path className={styles.letter} d="M384.2,56.5v56.1C389.7,94.6,389.7,74.4,384.2,56.5" />
    </g>
  </svg>
);

AnimatedIwdLogo.propTypes = {
  className: PropTypes.string,
};

AnimatedIwdLogo.defaultProps = {
  className: null,
};

export default AnimatedIwdLogo;
