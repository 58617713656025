import React from 'react';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BodyText1, LINK_COLOR_PALETTE } from 'app/common/typography';
import { PATH_INDEX } from 'config/routePaths';
import styles from './dashboard.css';

const DashboardHint = () => {
  return (
    <BodyText1>
      <Trans
        i18nKey="display.error.not.found.subtitle"
        values={{
          dashboard: i18next.t('display.error.not.found.dashboard'),
        }}
        components={[<DashboardLink />]}
      />
    </BodyText1>
  );
};

/**
 * <Trans components={}> does not manage nested component easily.
 * Here, we only provide 1 component to <Trans>
 */
const DashboardLink = () => {
  return (
    <BodyText1 palette={LINK_COLOR_PALETTE} interactive={true} className={styles.link}>
      <Link to={PATH_INDEX}>dashboard</Link>
    </BodyText1>
  );
};

export default DashboardHint;
