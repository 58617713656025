import pick from 'lodash/pick';
import buildGdprCompliantUser from 'lib/bugsnag/helpers/buildGdprCompliantUser';
import getDecodedUserTokenFromLoginCookie from 'app/common/helpers/auth/getDecodedUserTokenFromLoginCookie';

export default function getGdprCompliantUserToken() {
  const userToken = getDecodedUserTokenFromLoginCookie();

  if (!userToken) {
    return null;
  }

  return {
    ...pick(userToken, ['exp', 'instance', 'iss', 'jti']),
    user: buildGdprCompliantUser(userToken.user),
  };
}
