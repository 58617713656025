import React from 'react';
import Skeleton from 'app/common/components/skeleton';
import styles from './index.css';

const TransferContentModalSkeleton = () => (
  <>
    <Skeleton width={450} height={19} dataTestid="transferContentSkeleton" />
    <Skeleton className={styles.optionsTitle} width={140} height={17} />
    <Skeleton className={styles.optionRow} width={400} height={22} />
    <Skeleton className={styles.optionRow} width={400} height={22} />
    <Skeleton className={styles.selectUser} width={200} height={17} />
    <Skeleton height={38} width={510} />
  </>
);

export default TransferContentModalSkeleton;
