import React from 'react';
import { useSelector } from 'react-redux';
import useAction from 'app/display/common/hooks/useAction';
import isCommentsPanelOpen from 'app/common/selectors/slideshow/isCommentsPanelOpen';
import { toggleCommentsPanel } from 'app/common/actions/slideshow';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import CommentsIcon from 'assets/icons/icons-discussion.svg';
import SlideshowButtonSeparator from 'app/common/components/slideshow/buttons/separator';
import styles from './icon.css';

export const SLIDESHOW_INFORMATION_ICON_TESTID = 'slideshowInformationIcon';

export default function SlideshowCommentsIcon() {
  const isOpen = useSelector(isCommentsPanelOpen);
  const toggleSlideshow = useAction(toggleCommentsPanel);

  return (
    <div className={styles.icon}>
      <button
        className="yarl__button"
        onClick={toggleSlideshow}
        type="button"
        data-testid={SLIDESHOW_INFORMATION_ICON_TESTID}
      >
        <SlideshowButton Icon={CommentsIcon} isActive={isOpen} className={styles.isActive} />
      </button>
      <SlideshowButtonSeparator />
    </div>
  );
}
