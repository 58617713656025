import React from 'react';
import i18next from 'i18next';
import { PATH_SURVEY, PATH_SURVEY_PUBLISHED } from 'config/routePaths';
import NetworkLink from 'app/common/components/legacyNavMenu/menu/network/link';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useIsNetworkSurveyVisible from 'app/common/hooks/legacyNavMenu/useIsNetworkSurveyVisible';

const useSurveyLink = () => {
  const href = useParamSelector(getAbsoluteUrl, PATH_SURVEY_PUBLISHED);
  const isActive = useLocationMatch(PATH_SURVEY, { exact: false });
  const isVisible = useIsNetworkSurveyVisible();

  return { href, isVisible, isActive };
};

const SurveyLink = () => {
  const { href, isVisible, isActive } = useSurveyLink();
  if (!isVisible) {
    return null;
  }

  return (
    <NetworkLink isActive={isActive} href={href}>
      {i18next.t('surveys')}
    </NetworkLink>
  );
};

export default SurveyLink;
