import classNames from 'classnames';
import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import ModalInput from 'app/display/common/components/modals/modalInput';
import { BodyText1, ERROR_COLOR_PALETTE } from 'app/common/typography';
import i18next from 'i18next';
import RequiredSymbol from 'app/common/components/requiredSymbol';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal } from 'app/common/actions/modals';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import getMessageFromApiException from 'app/common/helpers/api/getMessageFromApiException';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import useCreateGuidelineTag from 'app/network/tag/guideline/hooks/useCreateGuidelineTag';
import useIsGuidelineTagDuplicate from 'app/network/tag/guideline/hooks/useIsGuidelineTagDuplicate';
import styles from './index.css';

export const GUIDELINE_TAG_MODAL_ADD = 'guideline-tag-modal-add';

type GuidelineTagModalAddProps = {
  setNewTagName: (value: string) => void;
};

const CreateGuidelineTagModal = ({ setNewTagName }: GuidelineTagModalAddProps) => {
  const isAlreadyTag = useIsGuidelineTagDuplicate();
  const createGuidelineTags = useCreateGuidelineTag();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [confirmEnabled, setConfirmEnabled] = useState(false);
  const [userValue, setUserValue] = useState('');

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const closeModal = useAction(hideModal);

  const handleConfirm = async () => {
    if (userValue.length > 0) {
      setIsSubmitting(true);
      await createGuidelineTags([userValue]);
      setNewTagName(userValue);
      setIsSubmitting(false);
    }
  };

  const onChangeHandler = ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    const isDuplicate = isAlreadyTag(value);
    setIsValid(value === '' || !isDuplicate);
    setConfirmEnabled(value !== '' && !isDuplicate);
    setUserValue(value.trim());
  };

  const onConfirmSuccess = useAction(() =>
    closeModalWithSuccessNotificationMessage(i18next.t('network.guidelines.tag.modal.create.success'))
  );
  const onConfirmError = useAction((exception: DOMException) => {
    const { message } = getMessageFromApiException(exception, i18next.t('network.guidelines.tag.modal.create.error'));
    return closeModalWithErrorNotificationMessage(message);
  });

  const title = i18next.t('network.guidelines.tag.modal.create.title');
  const inputClasses = classNames(styles.input, {
    [styles.disabled]: isSubmitting,
    [styles.error]: !isValid,
  });

  return (
    <ProcessingConfirmModal
      onClose={closeModal}
      onConfirm={handleConfirm}
      confirmEnabled={confirmEnabled}
      onConfirmSuccess={onConfirmSuccess}
      onConfirmError={onConfirmError}
      title={title}
      submitOnEnter={true}
    >
      <ModalInput
        inputRef={inputRef}
        type="text"
        required={true}
        pattern=".*\S+.*"
        onChange={onChangeHandler}
        className={inputClasses}
        isDisabled={isSubmitting}
        id="modal-guideline-tag-add"
        name="modal-guideline-tag-add"
        maxLength={50}
        placeholder=""
        dataTestId={GUIDELINE_TAG_MODAL_ADD}
        defaultValue=""
      />
      {!isValid && (
        <div className={styles.errorMessage}>
          <RequiredSymbol required />
          <BodyText1 className={styles.error} palette={ERROR_COLOR_PALETTE}>
            {i18next.t('network.guidelines.tag.create.error.sameName')}
          </BodyText1>
        </div>
      )}
    </ProcessingConfirmModal>
  );
};

export default CreateGuidelineTagModal;
