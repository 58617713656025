import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { BodyText1, BodyText2, ERROR_COLOR_PALETTE } from 'app/common/typography';

import styles from './radio.css';

/**
 * Render a group of input radio in a <Field/> component from redux-form.
 * It avoid creating multiple Label / <Field> every time in your form.
 *
 * @example ./radio.md
 *
 * @param input
 * @param mainLabel Optional, main label for the whole radio group
 * @param options Object containing label / value for each radio
 * @param disabled
 * @param required
 * @param meta
 * @constructor
 */
const Radio = ({
  input,
  mainLabel,
  options,
  disabled,
  required,
  meta,
  labelClass,
  infoClass,
  optionClass,
  errorClass,
}) => (
  <>
    {mainLabel && <BodyText2 className={classNames(labelClass)}>{mainLabel}</BodyText2>}
    <div className={infoClass}>
      {options.map(({ label, value, disabledOption, moreInfo }) => (
        <label key={`${input.name}-${label}`} className={optionClass}>
          <BodyText1 className={styles.align} interactive={!disabledOption}>
            <input
              {...input}
              type="radio"
              value={value}
              checked={value === input.value}
              disabled={disabled || disabledOption || meta?.submitting}
              className={classNames('radio', styles.input, {
                [styles.disabled]: meta?.submitting || disabledOption,
              })}
            />
            <span
              className={classNames(styles.text, {
                [styles.disabled]: meta?.submitting || disabledOption,
              })}
            >
              {label}
            </span>
            {moreInfo && <span className={styles.moreInfo}>{moreInfo}</span>}
          </BodyText1>
        </label>
      ))}
    </div>
    {required && <span className={styles.error}>*</span>}
    {meta.touched && meta.error && (
      <BodyText1 palette={ERROR_COLOR_PALETTE} className={classNames(styles.error, errorClass)}>
        {meta.error}
      </BodyText1>
    )}
  </>
);

Radio.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  mainLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
      disabledOption: PropTypes.bool,
      moreInfo: PropTypes.object,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  vertical: PropTypes.bool,
  inputFirst: PropTypes.bool,
  labelClass: PropTypes.string,
  infoClass: PropTypes.string,
  optionClass: PropTypes.string,
  errorClass: PropTypes.string,
};

Radio.defaultProps = {
  mainLabel: null,
  disabled: false,
  required: false,
  vertical: true,
  inputFirst: false,
  labelClass: null,
  infoClass: null,
  optionClass: null,
  errorClass: null,
};

export default Radio;
