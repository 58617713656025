import { connect } from 'react-redux';
import getCurrentUser from 'app/common/user/selectors/getCurrentUser';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import { PATH_DIRECTORY_MYACCOUNT } from 'config/routePaths';
import UserProfileLink from 'app/common/components/appShell/header/userMenu/userProfileLink';

const mapStateToProps = state => {
  const user = getCurrentUser(state);
  const baseUrl = getInstanceBaseUrl(state);
  return {
    name: user.name,
    job: user.job,
    to: `${baseUrl}${PATH_DIRECTORY_MYACCOUNT}`,
  };
};

export default connect(mapStateToProps)(UserProfileLink);
