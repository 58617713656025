import React from 'react';
import PropTypes from 'prop-types';
import IconWarning from 'assets/icons/warning.svg';
import { BodyText1 } from 'app/common/typography';
import { COLOR_ERROR } from 'app/common/style/colors';
import styles from './error.css';

const Error = ({ children }) => (
  <>
    <IconWarning className={styles.iconWarning} />
    <BodyText1 on={COLOR_ERROR}>{children}</BodyText1>
  </>
);

Error.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Error;
