import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { BodyText1 } from 'app/common/typography';
import { OptionSearchDropDown } from 'app/display/common/helpers/searchDropDown/convertValuesToOptions';
import styles from './index.css';

const DropdownIndicator = (props: DropdownIndicatorProps<OptionSearchDropDown, true>) => {
  return (
    <components.Placeholder {...props}>
      <BodyText1 className={styles.placeholder}>{props.selectProps.placeholder}</BodyText1>
    </components.Placeholder>
  );
};

export default DropdownIndicator;
