import BasicUser from 'domain/user/basicUser';
import isIWD from 'app/common/user/helpers/profile/isIWD';
import { FullUserPayload } from 'domain/user/fullUserPayload';

export default class BasicUserFactory {
  private static createFromApiPayload(apiUser: FullUserPayload): BasicUser {
    return new BasicUser(apiUser.id, apiUser.name, apiUser.photo?.url, isIWD(apiUser), apiUser.idv4);
  }

  static createCollectionFromApiPayload(fullUserListPayload: FullUserPayload[]): BasicUser[] {
    return fullUserListPayload.map(BasicUserFactory.createFromApiPayload);
  }

  static createDefaultBasicUser(): BasicUser {
    return new BasicUser('fake-id', 'IWD Support', undefined, true);
  }
}
