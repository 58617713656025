import React from 'react';
import i18next from 'i18next';
import { PATH_CATALOG_BROWSE } from 'config/routePaths';
import DisplayLink from 'app/common/components/legacyNavMenu/menu/display/link';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import { APPSHELL_CATALOG } from 'app/common/constants/userflow';

const useCatalogLink = () => {
  const isActive = useLocationMatch(PATH_CATALOG_BROWSE, { exact: false });

  return {
    to: PATH_CATALOG_BROWSE,
    isActive,
  };
};

const CatalogLink = () => {
  const { to, isActive } = useCatalogLink();

  return (
    <DisplayLink isActive={isActive} to={to} data-testid={APPSHELL_CATALOG}>
      {i18next.t('display.submenu.catalog')}
    </DisplayLink>
  );
};

export default CatalogLink;
