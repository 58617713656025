import { STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';

export const FETCH_CURRENTUSER_ACL_START = 'currentuser.acl.fetch.start';
export const FETCH_CURRENTUSER_ACL_SUCCESS = 'currentuser.acl.fetch.success';
export const FETCH_CURRENTUSER_ACL_ERROR = 'currentuser.acl.fetch.error';

export const initialState = {
  status: STATUS_INITIAL,
  rights: {},
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENTUSER_ACL_START:
      return {
        ...state,
        status: STATUS_LOADING,
        error: null,
      };
    case FETCH_CURRENTUSER_ACL_SUCCESS:
      return {
        status: STATUS_SUCCESS,
        rights: action.acl,
        error: null,
      };
    case FETCH_CURRENTUSER_ACL_ERROR:
      return {
        status: STATUS_ERROR,
        rights: {},
        error: action.error,
      };
    default:
      return state;
  }
}
