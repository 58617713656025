import { HIDE_MODAL } from 'app/common/actions/modals/modalTypes';

export const CLOSE_ALL_FOLDERS = 'ui.modal.selectFolder.close.all.folders';
export const SELECT_FOLDER = 'ui.modal.selectFolder.select.folder';
export const TOGGLE_FOLDER = 'ui.modal.selectFolder.toggle.folder';
export const RESET_STATE = 'ui.modal.selectFolder.reset.state';

export const initialState = {
  openedFolders: [],
  selectedFolderId: null,
  selectedBrandId: null,
};

export default function reducer(state = initialState, action) {
  let index;

  switch (action.type) {
    case TOGGLE_FOLDER:
      index = state.openedFolders.indexOf(action.folderId);
      if (index !== -1 && !action.forceOpen) {
        return {
          ...state,
          openedFolders: [...state.openedFolders.slice(0, index), ...state.openedFolders.slice(index + 1)],
        };
      }

      return {
        ...state,
        openedFolders: [...state.openedFolders, ...[action.folderId]],
      };
    case SELECT_FOLDER:
      return {
        ...state,
        selectedFolderId: action.folderId,
        selectedBrandId: action.brandId,
      };
    case CLOSE_ALL_FOLDERS:
      return {
        ...state,
        openedFolders: [],
      };
    case HIDE_MODAL:
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
