import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import styles from 'app/display/common/components/select.css';

export class Select extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    dataTestId: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    disabled: false,
    dataTestId: 'exportSelector',
  };

  render() {
    const props = { ...this.props, 'data-testid': this.props.dataTestId };
    const { dataTestId, ...selectProps } = props;
    const classSet = classNames(styles.select, this.props.className);
    return (
      <select {...selectProps} className={classSet} disabled={this.props.disabled}>
        {this.props.children}
      </select>
    );
  }
}

export default Select;
