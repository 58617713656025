import { createAction } from '@reduxjs/toolkit';
import { GuidelinesWithNoCampaign } from 'app/network/store/sheet/tabs/guidelines/helpers/sortGuidelinesByCampaignName';

export const toggleGuidelineSelectionInCampaign = createAction(
  'store.sheet.guidelineInCampaign.selection',
  (campaignId: string | null, guidelineId: string) => {
    return { payload: { campaignId: campaignId || GuidelinesWithNoCampaign, guidelineId } };
  }
);

export const selectAllGuidelinesInCampaign = createAction(
  'store.sheet.guidelineInCampaign.selectAll',
  (campaignId: string | null, guidelineIds: string[]) => {
    return { payload: { campaignId: campaignId || GuidelinesWithNoCampaign, guidelineIds } };
  }
);
export const deselectAllGuidelinesInCampaign = createAction(
  'store.sheet.guidelineInCampaign.deselectAll',
  (campaignId: string | null) => {
    return { payload: { campaignId: campaignId || GuidelinesWithNoCampaign } };
  }
);
