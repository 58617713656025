import { RUN_IMPORT_START, RUN_IMPORT_SUCCESS, RUN_IMPORT_ERROR, IMPORT_RESET } from 'app/display/import/actions';
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR, STATUS_INITIAL } from 'app/common/reducers/status';

export const initialState = {
  status: STATUS_INITIAL,
  errors: {
    csv: {},
  },
  report: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RUN_IMPORT_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case RUN_IMPORT_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        report: action.report,
      };
    case RUN_IMPORT_ERROR: {
      const errors = { ...initialState.errors };
      if (action.errors !== undefined) {
        action.errors.forEach(file => {
          const { type, name } = file;
          if (errors[type] !== undefined) {
            errors[type] = {
              ...errors[type],
              [name]: file.importErrors.map(error => {
                return {
                  line: error.line,
                  messages: error.errors.map(message => ({
                    code: message.code,
                    message: message.message,
                  })),
                };
              }),
            };
          } else {
            console.warn('Unsupported error type', type);
          }
        });
      }

      return {
        ...state,
        status: STATUS_ERROR,
        errors,
      };
    }
    case IMPORT_RESET:
      return initialState;
    default:
      return state;
  }
}
