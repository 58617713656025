import React from 'react';
import { UserNotificationFilterCategory } from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';
import UserNotificationRow from 'app/display/common/components/modals/userNotification/content/notificationRow';
import UserNotification from 'domain/userNotifications';
import EmptyUserNotificationsList from 'app/display/common/components/modals/userNotification/content/empty';
import styles from 'app/display/common/components/modals/userNotification/content/index.css';
import ErrorUserNotificationsList from 'app/display/common/components/modals/userNotification/content/error';

const UserNotificationRows = ({
  selectedTab,
  notifications,
  isLoading,
  isError,
}: {
  selectedTab: UserNotificationFilterCategory;
  notifications: UserNotification[];
  isLoading: boolean;
  isError: boolean;
}) => {
  if (isError) {
    return <ErrorUserNotificationsList />;
  }

  if (!isLoading && notifications.length === 0) {
    return <EmptyUserNotificationsList selectedTab={selectedTab} />;
  }

  return (
    <div className={styles.content}>
      {notifications.map(notification => {
        return <UserNotificationRow notification={notification} key={notification.id} />;
      })}
    </div>
  );
};

export default UserNotificationRows;
