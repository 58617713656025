import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import { useSelector } from 'react-redux';
import generatePathname from 'lib/helpers/router/generatePathname';
import { PATH_NETWORK_STORE_SHEET } from 'config/routePaths';

export default function useStoreSheetPath(storeId?: number) {
  const baseUrl = useSelector(getInstanceBaseUrl);

  if (!storeId) {
    return null;
  }

  const storeSheetPath = generatePathname(PATH_NETWORK_STORE_SHEET, { id: storeId });

  return `${baseUrl}${storeSheetPath}`;
}
