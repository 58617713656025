export const CATALOG_LIST_ADDENTRYMENU_TOGGLEMASK = 'CATALOG_LIST_ADDENTRYMENU_TOGGLEMASK';

export const initialState = {
  isOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CATALOG_LIST_ADDENTRYMENU_TOGGLEMASK:
      return {
        ...state,
        isOpen: typeof action.isOpen === 'boolean' ? action.isOpen : !state.isOpen,
      };
    default:
      return state;
  }
}
