import { createReducer } from '@reduxjs/toolkit';
import {
  ADD_GUIDELINES,
  REMOVE_GUIDELINES,
  UPDATE_GUIDELINES,
  CLEAR_GUIDELINES,
  ACTIVATE_GUIDELINES_SUCCESS,
  DEACTIVATE_GUIDELINES_SUCCESS,
  ARCHIVE_GUIDELINES_SUCCESS,
  UNARCHIVE_GUIDELINES_SUCCESS,
} from 'app/display/guideline/common/actions';

export const initialState = {};

const guidelinesByIdReducer = createReducer(initialState, builder => {
  builder
    .addCase(ADD_GUIDELINES, (draftState, action) => {
      action.guidelines.forEach(guideline => {
        draftState[guideline.id] = guideline;
      });
    })
    .addCase(UPDATE_GUIDELINES, (draftState, action) => {
      action.guidelines.forEach(guideline => {
        draftState[guideline.id] = guideline;
      });
    })
    .addCase(REMOVE_GUIDELINES, (draftState, action) => {
      action.guidelines.forEach(guideline => {
        delete draftState[guideline.id];
      });
    })
    .addCase(CLEAR_GUIDELINES, () => initialState)
    .addCase(ACTIVATE_GUIDELINES_SUCCESS, (draftState, action) => {
      action.guidelineIds.forEach(guidelineId => {
        draftState[guidelineId].enable();
      });
    })
    .addCase(DEACTIVATE_GUIDELINES_SUCCESS, (draftState, action) => {
      action.guidelineIds.forEach(guidelineId => {
        draftState[guidelineId].disable();
      });
    })
    .addCase(ARCHIVE_GUIDELINES_SUCCESS, (draftState, action) => {
      action.guidelineIds.forEach(guidelineId => {
        draftState[guidelineId].archive();
      });
    })
    .addCase(UNARCHIVE_GUIDELINES_SUCCESS, (draftState, action) => {
      action.guidelineIds.forEach(guidelineId => {
        draftState[guidelineId].unarchive();
      });
    });
});

export default guidelinesByIdReducer;
