import { FETCH, FETCH_SUCCESS, FETCH_ERROR } from 'app/common/actions/marketsTypes';
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';

export const initialState = {
  status: STATUS_LOADING,
  list: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return { ...state, status: STATUS_LOADING, list: [] };
    case FETCH_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        list: action.markets,
      };
    case FETCH_ERROR:
      return {
        ...state,
        status: STATUS_ERROR,
      };
    default:
      return state;
  }
}
