import React from 'react';
import PropTypes from 'prop-types';

class UnexpectedErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error(error, errorInfo);
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, errorInfo } = this.state;

    if (errorInfo) {
      return <FallbackComponent error={error} errorInfo={errorInfo} />;
    }

    return children;
  }
}

UnexpectedErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  FallbackComponent: PropTypes.elementType.isRequired,
};

export default UnexpectedErrorBoundary;
