import React from 'react';
import Proptypes from 'prop-types';
import CurrentUserAvatar from 'app/common/user/components/currentUserAvatar';
import { HeadText5, BodyText1, DEFAULT_COLOR_PALETTE, TextHoverArea } from 'app/common/typography';

import styles from './userProfileLink.css';

const UserProfileLink = ({ name, job, to }) => (
  <TextHoverArea palette={DEFAULT_COLOR_PALETTE} className={styles.linkWrapper}>
    <a href={to} className={styles.link}>
      <CurrentUserAvatar className={styles.avatar} />
      <div className={styles.info}>
        <HeadText5 className={styles.name}>{name}</HeadText5>
        {job && (
          <BodyText1 interactive={false} className={styles.job}>
            {job}
          </BodyText1>
        )}
      </div>
    </a>
  </TextHoverArea>
);

UserProfileLink.propTypes = {
  to: Proptypes.string.isRequired,
  name: Proptypes.string.isRequired,
  job: Proptypes.string,
};

UserProfileLink.defaultProps = {
  job: undefined,
};

export default UserProfileLink;
