/**
 * Well know sizes used for input / skeleton / text width
 *
 * @see https://zpl.io/VKrMPLR
 */

export const WIDTH_XL = 'width-xl';
export const WIDTH_L = 'width-l';
export const WIDTH_M = 'width-m';
export const WIDTH_S = 'width-s';

export const WIDTHS = [WIDTH_XL, WIDTH_L, WIDTH_M, WIDTH_S];
