import React from 'react';
import {
  ProcessedUserNotifications,
  UserNotificationFilterCategory,
} from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';
import UserNotificationTab from 'app/display/common/components/modals/userNotification/tab';
import styles from 'app/display/common/components/modals/userNotification/tabs.css';

export default function UserNotificationTabs({
  notificationsByCategory,
  selectedTab,
  onChange,
}: {
  notificationsByCategory: ProcessedUserNotifications;
  selectedTab: UserNotificationFilterCategory;
  onChange: (el: UserNotificationFilterCategory) => void;
}) {
  return (
    <div className={styles.header}>
      {Object.values(UserNotificationFilterCategory).map((currentTab: UserNotificationFilterCategory) => {
        if (!notificationsByCategory[currentTab].isTabEnabled) {
          return null;
        }
        return (
          <UserNotificationTab selectedTab={selectedTab} currentTab={currentTab} onChange={onChange} key={currentTab} />
        );
      })}
    </div>
  );
}
