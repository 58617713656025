import { STATUS_LOADING, STATUS_ERROR, STATUS_SUCCESS } from 'app/common/reducers/status';
import {
  FETCH_ITEM_START,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEM_ERROR,
  RESET_ITEM_STATUS,
  FETCH_CURRENT_ITEM_FACING_SUCCESS,
} from 'app/display/catalog/common/reducers/items';
import {
  CREATE_ITEM_BY_FORM_VALUES_SUCCESS,
  EDIT_ITEM_BY_FORM_VALUES_SUCCESS,
} from 'app/display/catalog/common/actions/items';
import getFacingFromItem from 'app/display/catalog/sheet/view/helpers/getFacingFromItem';
import getTextureFromItem from 'app/display/catalog/sheet/view/helpers/getTextureFromItem';
import leaveItemSheet from 'app/display/catalog/sheet/view/actions/leaveItemSheet';

export const initialState = {
  status: STATUS_LOADING,
  error: undefined,
  isFacingBeingGenerated: false,
};

function isFacingBeingGeneratedAfterCreation(item) {
  return item.display.model3d?.id != null;
}

function isFacingBeingGeneratedAfterEdition(oldItem, newItem) {
  const oldTexture = getTextureFromItem(oldItem);
  const newTexture = getTextureFromItem(newItem);
  const model3dHasChanged = newItem.display.model3d?.id !== oldItem.display.model3d?.id;
  const textureHasChanged = oldTexture?.fileURL !== newTexture?.fileURL;
  const colorHasChanged = newItem.display.color !== oldItem.display.color;

  return model3dHasChanged || textureHasChanged || colorHasChanged;
}

function facingHasChanged(oldItem, newItem) {
  const oldFacing = getFacingFromItem(oldItem);
  const newFacing = getFacingFromItem(newItem);

  return oldFacing?.fileURL !== newFacing?.fileURL;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ITEM_STATUS:
    case FETCH_ITEM_START:
      return {
        ...state,
        status: STATUS_LOADING,
        error: undefined,
      };
    case FETCH_ITEM_ERROR:
      return {
        ...state,
        status: STATUS_ERROR,
        error: action.error,
      };
    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        error: undefined,
      };
    case CREATE_ITEM_BY_FORM_VALUES_SUCCESS:
      if (isFacingBeingGeneratedAfterCreation(action.item)) {
        return {
          ...state,
          isFacingBeingGenerated: true,
        };
      }
      return state;
    case EDIT_ITEM_BY_FORM_VALUES_SUCCESS:
      if (isFacingBeingGeneratedAfterEdition(action.oldItem, action.item)) {
        return {
          ...state,
          isFacingBeingGenerated: true,
        };
      }
      return state;
    case FETCH_CURRENT_ITEM_FACING_SUCCESS:
      if (facingHasChanged(action.oldItem, action.item)) {
        return {
          ...state,
          isFacingBeingGenerated: false,
        };
      }
      return state;
    case leaveItemSheet.type:
      return {
        ...state,
        isFacingBeingGenerated: false,
      };
    default:
      return state;
  }
}
