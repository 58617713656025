import React from 'react';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal } from 'app/common/actions/modals';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import { useDispatch } from 'react-redux';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import useDeletePhoto from 'app/common/hooks/useDeletePhoto';
import { useTranslation } from 'react-i18next';
import { BodyText1, ERROR_COLOR_PALETTE, HeadText5 } from 'app/common/typography';
import { SlideImage } from 'yet-another-react-lightbox';
import styles from './modal.css';

export default function SlideshowDeletePhotoModal({
  handleDeleteSuccess,
  photo,
}: {
  handleDeleteSuccess: () => void;
  photo: SlideImage;
}) {
  const handleClose = useAction(hideModal);
  const dispatch = useDispatch();
  const deletePhoto = useDeletePhoto(photo.storeId, photo.id);
  const { t } = useTranslation();

  return (
    <ProcessingConfirmModal
      onClose={handleClose}
      onConfirmError={() => {
        dispatch(closeModalWithErrorNotificationMessage(t('slideshow.deletePhoto.failed')));
      }}
      onConfirm={deletePhoto}
      onConfirmSuccess={() => {
        dispatch(closeModalWithSuccessNotificationMessage(t('slideshow.deletePhoto.success')));
        handleDeleteSuccess();
      }}
      title={t('slideshow.deletePhoto.title')}
      confirmLabel={t('slideshow.deletePhoto.confirmButton')}
    >
      <BodyText1>{t('slideshow.deletePhoto.bodyText')}</BodyText1>
      <HeadText5 className={styles.warning} palette={ERROR_COLOR_PALETTE}>
        {t('slideshow.deletePhoto.warning')}
      </HeadText5>
    </ProcessingConfirmModal>
  );
}
