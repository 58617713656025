import { STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import { UPDATE_FOLDER_SUCCESS } from 'app/display/common/actions';

export const FETCH_FOLDERS_START = 'fetch.folders.start';
export const FETCH_FOLDERS_SUCCESS = 'fetch.folders.success';

export const RENAME_FOLDER = 'catalog.rename.folder';

export const CREATE_FOLDER_START = 'catalog.create.folder.start';
export const CREATE_FOLDER_SUCCESS = 'catalog.create.folder.success';
export const CREATE_FOLDER_ERROR = 'catalog.create.folder.error';

export const DELETE_FOLDER_START = 'catalog.delete.folder.start';
export const DELETE_FOLDER_SUCCESS = 'catalog.delete.folder.success';
export const DELETE_FOLDER_ERROR = 'catalog.delete.folder.error';

export const ACTION_FOLDER_MOVE = 'catalog.action.folder.move';
export const ACTION_FOLDER_DELETE = 'catalog.action.folder.delete';

export const MOVE_FOLDER_ON_DRAG_DROP_START = 'catalog.folder.moveOnDragDrop.start';
export const MOVE_FOLDER_ON_DRAG_DROP_SUCCESS = 'catalog.folder.moveOnDragDrop.success';
export const MOVE_FOLDER_ON_DRAG_DROP_ERROR = 'catalog.folder.moveOnDragDrop.error';

export const initialState = {
  status: STATUS_INITIAL,
  folders: [],
  frozenFolderIds: [],
};

function freezeFolders(state, folderIds) {
  const oldFrozenFolderIds = state.frozenFolderIds.filter(id => !folderIds.includes(id));
  return { ...state, frozenFolderIds: [...oldFrozenFolderIds, ...folderIds] };
}

function unfreezeFolders(state, folderIds) {
  return {
    ...state,
    frozenFolderIds: state.frozenFolderIds.filter(id => !folderIds.includes(id)),
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FOLDERS_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_FOLDERS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        folders: action.folders,
      };
    case UPDATE_FOLDER_SUCCESS: {
      if (state.folders) {
        const folderIndex = state.folders.findIndex(folder => folder.id === action.folder.id);
        if (folderIndex > -1) {
          const folders = [...state.folders];
          folders[folderIndex] = action.folder;
          return {
            ...state,
            folders,
          };
        }
      }
      return state;
    }
    case CREATE_FOLDER_SUCCESS: {
      if (state.folders) {
        return {
          ...state,
          folders: [...state.folders, action.folder],
        };
      }
      return state;
    }
    case DELETE_FOLDER_SUCCESS: {
      if (state.folders) {
        const folderIndex = state.folders.findIndex(folder => folder.id === action.folderId);
        if (folderIndex > -1) {
          const folders = [...state.folders];
          folders.splice(folderIndex, 1);
          return {
            ...state,
            folders,
          };
        }
      }
      return state;
    }
    case MOVE_FOLDER_ON_DRAG_DROP_START:
      return freezeFolders(state, [action.folderId, action.targetFolderId]);
    case MOVE_FOLDER_ON_DRAG_DROP_SUCCESS:
    case MOVE_FOLDER_ON_DRAG_DROP_ERROR:
      return unfreezeFolders(state, [action.folderId, action.targetFolderId]);
    default:
      return state;
  }
}
