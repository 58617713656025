import React from 'react';
import useIsCurrentUserAllowedToBestPractice from 'app/common/hooks/acl/useIsCurrentUserAllowedToBestPractice';
import useManageBestPracticePhoto from 'app/common/hooks/slideshow/useManageBestPracticePhoto';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import Button from 'app/display/common/components/button';
import BestPracticeIcon from 'assets/icons/best-practice.svg';
import { useLightboxState } from 'yet-another-react-lightbox';
import styles from './icon.css';

export const SLIDESHOW_BEST_PRACTICE_PHOTO_ICON_TESTID = 'slideshowBestPracticePhotoIcon';

export default function SlideshowBestPracticePhotoIcon() {
  const { currentSlide } = useLightboxState();
  const canBestPractice = useIsCurrentUserAllowedToBestPractice();
  const manageBestPractice = useManageBestPracticePhoto(currentSlide);

  return canBestPractice ? (
    <div className={styles.icon}>
      <Button
        className="yarl__button"
        onClick={manageBestPractice}
        dataTestId={SLIDESHOW_BEST_PRACTICE_PHOTO_ICON_TESTID}
      >
        <SlideshowButton Icon={BestPracticeIcon} />
      </Button>
    </div>
  ) : null;
}
