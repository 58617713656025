import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';
import NonCriticalErrorBoundary from 'app/display/common/components/errorBoundary/nonCritical';

export const GuidelineDocumentSettingsPage = React.lazy(() =>
  import(
    /* webpackChunkName: "display-guideline-document-settings" */ 'app/display/guideline/document/settings/components/page'
  )
);

const GuidelineDocumentSettings = () => (
  <Suspense fallback={<Spinner />}>
    <NonCriticalErrorBoundary>
      <GuidelineDocumentSettingsPage />
    </NonCriticalErrorBoundary>
  </Suspense>
);

export default GuidelineDocumentSettings;
