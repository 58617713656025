import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ControlledCollapsePanel from 'app/common/components/collapsePanel/controlled';

class UncontrolledCollapsePanel extends PureComponent {
  static propTypes = {
    renderHeader: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    children: PropTypes.node,
    classNameContent: PropTypes.string,
  };

  static defaultProps = {
    isOpen: true,
    children: undefined,
    classNameContent: null,
  };

  constructor(props) {
    super(props);
    this.state = { isOpen: props.isOpen };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => {
      return { isOpen: !prevState.isOpen };
    });
  }

  render() {
    const { renderHeader, children } = this.props;
    const { isOpen } = this.state;
    return (
      <ControlledCollapsePanel
        renderHeader={renderHeader}
        isOpen={isOpen}
        onClick={this.handleClick}
        classNameContent={this.props.classNameContent}
      >
        {children}
      </ControlledCollapsePanel>
    );
  }
}

export default UncontrolledCollapsePanel;
