import { useDrop } from 'react-dnd';

export default function useDropZone(dropPayload, acceptedDragTypes, canDrop) {
  const [{ dropEnabled, isOver }, drop] = useDrop({
    accept: acceptedDragTypes,
    canDrop,
    collect: monitor => ({
      dropEnabled: monitor.canDrop() && canDrop(monitor.getItem()),
      isOver: monitor.isOver(),
    }),
    drop: () => dropPayload,
  });

  return { dropEnabled, isOver, drop };
}
