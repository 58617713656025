import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import { Store } from 'domain/store';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import { STORES_CACHE_KEY } from 'app/common/constants/queryCacheKeys';

export default function useStoreById(storeId?: string) {
  const request = useHttpGetRequest<Store>(`stores/${storeId}`);
  const { data, isLoading, isError, error } = useHttpQuery([STORES_CACHE_KEY, storeId], request, {
    enabled: !!storeId,
  });

  return { store: data, isLoading, isError, error };
}
