import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_MANTIS } from 'app/common/style/colors';

import generator from 'app/common/typography/colors/palettes/helpers/generator';

export const SUCCESS_COLOR_PALETTE = 'success-color-palette';

export default generator({
  basic: {
    [COLOR_PRIMARY]: COLOR_MANTIS,
    [COLOR_SECONDARY]: COLOR_MANTIS,
  },
  hover: {
    [COLOR_PRIMARY]: COLOR_MANTIS,
    [COLOR_SECONDARY]: COLOR_MANTIS,
  },
  active: {
    [COLOR_PRIMARY]: COLOR_MANTIS,
    [COLOR_SECONDARY]: COLOR_MANTIS,
  },
});
