import { combineReducers } from 'redux';

import surveyReducer from 'app/network/survey/report/reducers/survey';
import campaignReducer from 'app/network/survey/report/reducers/campaign';
import isActionsPopupVisibleReducer from 'app/network/survey/report/reducers/actionsPopup/isVisible';
import reportReducer from 'app/network/survey/report/reducers/report';
import inventoryItemsByIdReducer from 'app/network/survey/report/reducers/inventory/inventoryItemsById';
import storesByIdReducer from 'app/network/survey/report/reducers/stores/storesById';

export default combineReducers({
  survey: surveyReducer,
  campaign: campaignReducer,
  isActionsPopupVisible: isActionsPopupVisibleReducer,
  report: reportReducer,
  inventoryItemsById: inventoryItemsByIdReducer,
  storesById: storesByIdReducer,
});
