import React from 'react';
import { Field } from 'redux-form';
import Select from 'app/common/components/forms/select';
import styles from 'app/directory/user/sheet/common/components/form/license/index.css';
import { useTranslation } from 'react-i18next';
import useExecuteRoleField from 'app/directory/user/sheet/common/components/form/license/execute/index.hook';
import ExecuteFieldNoRole from 'app/directory/user/sheet/common/components/form/license/execute/noRole';

export default function ExecuteField() {
  const { t } = useTranslation();
  const { roles, isDisabled } = useExecuteRoleField();

  if (!roles || roles.length === 0) {
    return <ExecuteFieldNoRole />;
  }

  return (
    <Field
      id="executeRole"
      name="executeRole"
      component={Select}
      mainLabel={t('directory.userSheet.licenseTab.execute.label')}
      wrapClass={styles.roleWrapper}
      labelClass={styles.fieldLabel}
      selectStyle={styles.fieldInput}
      errorClass={styles.selectError}
      disabled={isDisabled}
    >
      <option value="norole" />
      {roles?.map(role => (
        <option key={role} value={role}>
          {t(`acl.role.${role}.title`)}
        </option>
      ))}
    </Field>
  );
}
