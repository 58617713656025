import i18next from 'i18next';
import React from 'react';
import useAction from 'app/display/common/hooks/useAction';
import { BodyText1 } from 'app/common/typography';
import { hideModal } from 'app/common/actions/modals';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import { GuidelineTag } from 'domain/guideline/tag';
import useDeleteGuidelineTag from 'app/network/tag/guideline/hooks/useDeleteGuidelineTag';

type DeleteVariantModalProps = {
  selectedTags: GuidelineTag[];
  clearTagSelection: () => void;
};

const DeleteGuidelineTagModal = ({ selectedTags, clearTagSelection }: DeleteVariantModalProps) => {
  const deleteGuidelineTags = useDeleteGuidelineTag();
  const count = selectedTags.length;
  const title = i18next.t('network.guidelines.tag.modal.delete.title', { count });
  const message = i18next.t('network.guidelines.tag.modal.delete.body', { name: selectedTags[0].name, count });
  const handleOnConfirm = async () => {
    await deleteGuidelineTags(selectedTags.map(tag => tag.id));
    clearTagSelection();
  };

  return (
    <ProcessingConfirmModal
      onClose={useAction(hideModal)}
      onConfirm={handleOnConfirm}
      onConfirmSuccess={useAction(() =>
        closeModalWithSuccessNotificationMessage(i18next.t('network.guidelines.tag.modal.delete.success', { count }))
      )}
      onConfirmError={useAction(() =>
        closeModalWithErrorNotificationMessage(i18next.t('network.guidelines.tag.modal.delete.error'))
      )}
      title={title}
      confirmLabel={i18next.t('button.delete')}
    >
      <BodyText1>{message}</BodyText1>
    </ProcessingConfirmModal>
  );
};

export default DeleteGuidelineTagModal;
