import useAssertGuidelineHasDocument from 'app/display/guideline/common/hooks/useAssertGuidelineHasDocument';
import useWindowOpen from 'app/display/common/hooks/useWindowOpen';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import generatePathname from 'lib/helpers/router/generatePathname';
import { PATH_GUIDELINE_DOCUMENT } from 'config/routePaths';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';

export default function useAccessGuidelineDocumentOnClick(guidelineId: string) {
  const guidelineDocumentUrl = useParamSelector(
    getAbsoluteUrl,
    generatePathname(PATH_GUIDELINE_DOCUMENT, { guidelineId })
  );

  const assertGuidelineHasDocument = useAssertGuidelineHasDocument(guidelineId);
  const windowOpen = useWindowOpen();

  return async function accessGuidelineDocumentOnClick() {
    try {
      await assertGuidelineHasDocument();
      windowOpen(guidelineDocumentUrl);
    } catch (exception: any) {
      notifyError(exception.message);
    }
  };
}
