import { createReducer } from '@reduxjs/toolkit';
import { STATUS_ERROR, STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import {
  CREATE_MODEL_3D_ERROR,
  CREATE_MODEL_3D_START,
  CREATE_MODEL_3D_SUCCESS,
  FETCH_MODEL_3DS_ERROR,
  FETCH_MODEL_3DS_START,
  FETCH_MODEL_3DS_SUCCESS,
} from 'app/display/common/actions';

export const initialState = {
  status: STATUS_INITIAL,
  models: [],
};

const model3dsReducer = createReducer(initialState, builder => {
  builder
    .addCase(FETCH_MODEL_3DS_START, draftState => {
      draftState.status = STATUS_LOADING;
    })
    .addCase(FETCH_MODEL_3DS_SUCCESS, (draftState, action) => {
      draftState.status = STATUS_SUCCESS;
      draftState.models = action.models;
    })
    .addCase(FETCH_MODEL_3DS_ERROR, draftState => {
      draftState.status = STATUS_ERROR;
    })
    .addCase(CREATE_MODEL_3D_START, draftState => {
      draftState.status = STATUS_LOADING;
    })
    .addCase(CREATE_MODEL_3D_SUCCESS, (draftState, action) => {
      draftState.status = STATUS_SUCCESS;
      draftState.models.push(action.model3d);
    })
    .addCase(CREATE_MODEL_3D_ERROR, draftState => {
      draftState.status = STATUS_ERROR;
    })
    .addDefaultCase(() => {});
});

export default model3dsReducer;
