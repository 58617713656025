import React from 'react';
import SlideshowStatusTag from 'app/common/components/slideshow/statusTag';
import styles from 'app/common/components/slideshow/statusTags/index.css';
import { useTranslation } from 'react-i18next';
import { SlideImage } from 'yet-another-react-lightbox';

export default function SlideshowBestPracticeTag({ currentSlide }: { currentSlide: SlideImage }) {
  const { t } = useTranslation();
  return currentSlide.state.bestpractice ? (
    <SlideshowStatusTag className={styles.bestPracticeTag}>{t('slideshow.statusTags.bestPractice')}</SlideshowStatusTag>
  ) : null;
}
