import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'app/display/common/components/actionBar/actionBarIcon.css';

/**
 * An icon component for the `ActionBar` component.
 *
 * @example ./actionBarIcon.md
 */
const ActionBarIcon = ({ Icon, onClick, width, height, className, dataTestId }) => (
  <Icon
    className={classNames(styles.icon, className)}
    style={{ width, height }}
    onClick={onClick}
    data-testid={dataTestId}
  />
);

ActionBarIcon.propTypes = {
  /**
   * Icon component to render.
   */
  Icon: PropTypes.func.isRequired,
  /**
   * Called when the user click on the icon.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Icon's width.
   */
  width: PropTypes.number.isRequired,
  /**
   * Icon's height.
   */
  height: PropTypes.number.isRequired,
  /**
   * Optional className for styling.
   */
  className: PropTypes.string,
  /**
   * Optional dataTestId for userflow and E2E tests.
   */
  dataTestId: PropTypes.string,
};

ActionBarIcon.defaultProps = {
  className: null,
  dataTestId: null,
};

export default ActionBarIcon;
