import { query } from 'app/common/actions/api';
import queryString from 'query-string';
import download from 'lib/helpers/download';

export default function exportUserActivity(
  selectedActivities,
  isSelectAllActivityTypes,
  { startDate, endDate },
  exportInternalUserActivities
) {
  const formattedStartDate = startDate.format('YYYY-MM-DD');
  const formattedEndDate = endDate.format('YYYY-MM-DD');
  const pathActivitiesQueryString = queryString.stringify({
    from: formattedStartDate,
    to: formattedEndDate,
    exportInternalUserActivities,
  });
  let pathActivitiesFilter = `/privates/activities?${pathActivitiesQueryString}`;

  if (!isSelectAllActivityTypes) {
    const activitiesFilter = selectedActivities
      ? selectedActivities
          .map(activity => `name[]=${activity}`)
          .toString()
          .replaceAll(',', '&')
      : '';
    pathActivitiesFilter += `&${activitiesFilter}`;
  }

  return async dispatch => {
    const response = await dispatch(
      query(pathActivitiesFilter, {
        getHeaders: true,
        responseType: 'text',
        headers: {
          Accept: 'text/csv',
        },
      })
    );
    const fileName = 'activity.csv'; // @todo extract from content-disposition
    const mimeType = response.headers['content-type'];
    download(response.data, fileName, mimeType);
  };
}
