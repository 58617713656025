import { useSelector } from 'react-redux';
import getIwdConfig from 'app/common/selectors/iwdConfig/getIwdConfig';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';

export default function useIsNetworkLibraryVisible(): boolean {
  const iwdConfig = useSelector(getIwdConfig);
  const { currentUser } = useCurrentUser();
  const { aclRight: canAccessLibrary } = useCurrentUserAclRight('legacy.library.access');

  return !iwdConfig.isAsp && !!currentUser?.settings.network.hasNetwork && canAccessLibrary;
}
