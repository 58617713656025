import { CRITERION_NAME, CRITERION_LAST_UPDATE } from 'app/display/guideline/list/common/sortCriteria';

export default function convertSortCriterionToApi(criterion) {
  switch (criterion) {
    case CRITERION_NAME:
      return 'name';
    default:
    case CRITERION_LAST_UPDATE:
      return 'state.modificationDate';
  }
}
