import { query } from 'app/common/actions/api';
import download from 'lib/helpers/download';
import getNextLinkFromHeaders from 'lib/helpers/httpHeaders';

async function buildCatalog(dispatch, uri, catalog) {
  const response = await dispatch(
    query(uri, {
      getHeaders: true,
      responseType: 'text',
      headers: {
        Accept: 'text/csv',
      },
    })
  );
  const nextUri = getNextLinkFromHeaders(response.headers);
  const newCatalog = {
    data: catalog.data.concat(response.data),
    fileName: catalog.fileName,
    mimeType: response.headers['content-type'],
  };

  if (nextUri) {
    return buildCatalog(dispatch, nextUri, newCatalog);
  }
  return newCatalog;
}

export default function exportCatalog() {
  return async dispatch => {
    const catalog = await buildCatalog(dispatch, '/items?archived=false&per_page=500', {
      data: '',
      fileName: 'catalog.csv',
      mimeType: '',
    });
    download(catalog.data, catalog.fileName, catalog.mimeType);
  };
}
