import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import sleep from 'lib/helpers/async';
import fetchAllUsers from 'app/common/user/actions/fetchAllUsers';
import fetchAllMarkets from 'app/common/actions/markets';

const MINIMUM_DELAY_BEFORE_APP_READY = 1;

export default function useFetchAppData() {
  const dispatch = useDispatch();
  const [isAppReady, setIsAppReady] = useState(false);

  const fetchData = useCallback(async () => {
    await Promise.all([dispatch(fetchAllUsers()), dispatch(fetchAllMarkets()), sleep(MINIMUM_DELAY_BEFORE_APP_READY)]);

    setIsAppReady(true);
  }, [dispatch, setIsAppReady]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return isAppReady;
}
