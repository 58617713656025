import React from 'react';
import ArrowIcon from 'assets/icons/arrow.svg';
import { HeadText5 } from 'app/common/typography';
import styles from './navigation.css';

export default function PrevPhoto() {
  return (
    <HeadText5 interactive>
      <ArrowIcon className={styles.prevPhoto} />
    </HeadText5>
  );
}
