import React, { useEffect, useState } from 'react';
import { ComponentProps, createModule, PluginProps, useLightboxState } from 'yet-another-react-lightbox';
import { useSelector } from 'react-redux';
import isFilmStripOpen from 'app/common/selectors/slideshow/isFilmStripOpen';
import classNames from 'classnames';
import useAction from 'app/display/common/hooks/useAction';
import { toggleFilmStrip } from 'app/common/actions/slideshow';
import IconResize from 'assets/icons/resize.svg';
import Slides from 'app/common/components/slideshow/filmStrip/slides';
import isRightSidePanelOpen from 'app/common/selectors/slideshow/isRightSidePanelOpen';
import styles from './index.css';

export const SLIDESHOW_FILMSTRIP_TESTID = 'slideshowFilmStrip';
export const SLIDESHOW_FILMSTRIP_ICON_TESTID = 'slideshowFilmStripIcon';
export const SLIDESHOW_FILMSTRIP_PLUGIN_ID = 'filmStrip';

function SlideshowFilmStrip({ children }: ComponentProps) {
  const ref = React.useRef<any>(null);
  const { slides } = useLightboxState();
  const [hasScroll, setHasScroll] = useState(false);

  const isFilmStrip = slides.length > 0;

  const isOpen = useSelector(isFilmStripOpen);
  const isPanelOpen = useSelector(isRightSidePanelOpen);
  const toggleFilmStripAction = useAction(toggleFilmStrip);

  useEffect(() => {
    if (slides.length === 1) {
      toggleFilmStripAction();
    }
  }, [toggleFilmStripAction, slides.length]);

  useEffect(() => {
    const filmstripHasScroll = ref?.current?.clientWidth < ref?.current?.scrollWidth;
    setHasScroll(filmstripHasScroll);
  }, [isOpen, isPanelOpen, ref]);

  const filmStripClassName = classNames(styles.filmStripWrapper, {
    [styles.isOpenFilmStrip]: isOpen,
    [styles.isPanelOpen]: isPanelOpen,
    [styles.isClosedFilmStrip]: !isOpen,
  });

  const openButtonClassName = classNames(styles.openButton, {
    [styles.isOpen]: isOpen && !hasScroll,
    [styles.isOpenWithScroll]: isOpen && hasScroll,
    [styles.isPanelOpen]: isPanelOpen,
    [styles.isClose]: !isOpen,
  });

  return isFilmStrip ? (
    <>
      <>
        <div className={openButtonClassName}>
          <button
            className={classNames(styles.button, 'yarl__button')}
            onClick={toggleFilmStripAction}
            type="button"
            data-testid={SLIDESHOW_FILMSTRIP_ICON_TESTID}
          >
            <IconResize className={styles.icon} />
          </button>
        </div>
        <div ref={ref} className={filmStripClassName} data-testid={SLIDESHOW_FILMSTRIP_TESTID}>
          <Slides slides={slides} />
        </div>
      </>
      {children}
    </>
  ) : null;
}

export default function FilmStrip({ addModule }: PluginProps) {
  const filmStripModule = createModule(SLIDESHOW_FILMSTRIP_PLUGIN_ID, SlideshowFilmStrip);
  addModule(filmStripModule);
}
