import isIWD from 'app/common/user/helpers/profile/isIWD';
import Settings from 'domain/user/settings';
import FullUser from 'domain/user/fullUser';
import Profile from 'domain/user/profile';
import { FullUserPayload } from 'domain/user/fullUserPayload';
import State from 'domain/state';
import sortListByName from 'app/common/helpers/sortListByName';
import NotificationSettings from 'domain/user/notificationSettings';

export default class FullUserFactory {
  public static createFromApiPayload(apiUser: FullUserPayload): FullUser {
    const enabled = apiUser.state.enabled || false;
    const modifierId = apiUser.state.enabledModifierId ? apiUser.state.enabledModifierId : '';
    const creationDate = new Date();
    const modificationDate = apiUser.state.enabledModificationDate
      ? new Date(apiUser.state.enabledModificationDate)
      : creationDate;
    return new FullUser(
      apiUser.id,
      apiUser.name,
      apiUser.firstName,
      apiUser.lastName,
      apiUser.email,
      new Profile(apiUser.profile.display, apiUser.profile.network, apiUser.profile.explore),
      apiUser.brands.map(brand => brand.id),
      apiUser.markets.map(market => market.id),
      new Settings(apiUser.settings.editors, apiUser.settings.unitOfLength, apiUser.settings.network),
      apiUser.photo?.url,
      isIWD(apiUser),
      apiUser.idv4,
      new State('', modifierId, creationDate, modificationDate, enabled),
      apiUser.job,
      apiUser.company,
      apiUser.phone,
      apiUser.notes,
      new NotificationSettings(
        apiUser.notificationSettings.commentNew,
        apiUser.notificationSettings.documentShared,
        apiUser.notificationSettings.guidelineShared,
        apiUser.notificationSettings.photoApprove,
        apiUser.notificationSettings.photoBestPractices,
        apiUser.notificationSettings.photoReplaced,
        apiUser.notificationSettings.photoDeleted,
        apiUser.notificationSettings.surveyCompletionReminder,
        apiUser.notificationSettings.surveyPublicationAvailable,
        apiUser.notificationSettings.surveyPublicationOwner
      )
    );
  }

  static createSortedCollectionFromApiPayload(payload: FullUserPayload[]): FullUser[] {
    const users = payload.map(FullUserFactory.createFromApiPayload);

    return sortListByName(users);
  }
}
