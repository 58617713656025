import React from 'react';
import PropTypes from 'prop-types';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from 'lib/queryClient';
import useKeydownCtrlAndKeyParam from 'app/display/common/hooks/useKeydownCtrlAndParamKey';

const AppQueryClientProvider = ({ children }) => {
  const areToolsEnabled = useKeydownCtrlAndKeyParam('q');
  return (
    <QueryClientProvider client={queryClient.getInstance()}>
      {children}
      <ReactQueryDevtools initialIsOpen={areToolsEnabled} />
    </QueryClientProvider>
  );
};

AppQueryClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppQueryClientProvider;
