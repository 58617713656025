import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';

import ClickBoundary from 'app/common/components/clickBoundary';

import DisplayLink from 'app/common/components/legacyNavMenu/navLink/display';
import NetworkLink from 'app/common/components/legacyNavMenu/navLink/network';

import { useSelector } from 'react-redux';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import isAppNavigationOpen from 'app/common/selectors/isAppNavigationOpen';
import useAction from 'app/display/common/hooks/useAction';
import closeAppNavigation from 'app/common/actions/appNavigation/close';
import DrawerLink from './link';
import styles from './index.css';
import IconNetwork from '../icons/network';
import IconDisplay from '../icons/display';

export function useSwitchDrawer() {
  const isSmallScreen = useSmallScreen();
  const appNavigationIsOpened = useSelector(isAppNavigationOpen);
  const closeNavigation = useAction(closeAppNavigation);

  return {
    isVisible: appNavigationIsOpened && !isSmallScreen,
    closeNavigation,
  };
}

/**
 * @example ./index.md
 */
export default function SwitchDrawer() {
  const { isVisible, closeNavigation } = useSwitchDrawer();
  return (
    <ClickBoundary
      className={classNames(styles.drawer, { [styles.isOpen]: isVisible })}
      isActive={isVisible}
      onClick={closeNavigation}
      onClickOutside={clickEvent => {
        clickEvent.stopPropagation();
        clickEvent.preventDefault();
      }}
    >
      <DrawerLink NavLink={DisplayLink} icon={<IconDisplay className={styles.icon} />} className={styles.display}>
        {i18next.t('display.subtitle')}
      </DrawerLink>
      <DrawerLink NavLink={NetworkLink} icon={<IconNetwork className={styles.icon} />} className={styles.network}>
        {i18next.t('network.subtitle')}
      </DrawerLink>
    </ClickBoundary>
  );
}
