import React from 'react';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import useToggleUserStatusModal from 'app/directory/user/common/modals/toggleUserStatus/index.hook';

export const DEACTIVATE_USER_MODAL_TEST_ID = 'deactivateUserModal';

export default function DeactivateUserModal({ userId }: { userId: string }) {
  const { onClose, onConfirm, onConfirmSuccess, onConfirmError, t, isFetching } = useToggleUserStatusModal(
    userId,
    'directory.userSheet.toggleStatusModal.deactivate.success'
  );

  return (
    <ProcessingConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      onConfirmError={onConfirmError}
      onConfirmSuccess={onConfirmSuccess}
      closeEnabled={true}
      confirmEnabled={!isFetching}
      confirmLabel={t('button.confirm')}
      title={t('directory.userSheet.toggleStatusModal.deactivate.title')}
      submitOnEnter={true}
      isLoading={isFetching}
    >
      <div data-testid={DEACTIVATE_USER_MODAL_TEST_ID}>
        {t('directory.userSheet.toggleStatusModal.deactivate.infoText')}
      </div>
    </ProcessingConfirmModal>
  );
}
