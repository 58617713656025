import {
  OPEN_FOLDER,
  CLOSE_FOLDER,
  SIDEBAR_EXPAND,
  OPEN_FOLDERS,
  SIDEBAR_COLLAPSE,
} from 'app/display/common/actions/sidebar';

export const initialState = {
  isExpanded: false,
  openedFolders: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIDEBAR_EXPAND:
      return { ...state, isExpanded: true };
    case SIDEBAR_COLLAPSE:
      return { ...state, isExpanded: false };
    case OPEN_FOLDER:
      return { ...state, openedFolders: [...state.openedFolders, action.id] };
    case OPEN_FOLDERS:
      return {
        ...state,
        openedFolders: [...state.openedFolders, ...action.folders],
      };
    case CLOSE_FOLDER:
      return {
        ...state,
        openedFolders: state.openedFolders.filter(id => id !== action.id && !action.childrenIds.includes(id)),
      };

    default:
      return state;
  }
}
