import { useMemo } from 'react';
import { reduxForm } from 'redux-form';
import reduxFormChangeOnEnterKeyDecorator from 'app/common/hoc/form';

/**
 * Return the given component wrapped in redux form
 */
export default function useReduxFormComponent(component, form, props) {
  // Memorization is essential here in order to avoid regenerating a new component each time this hook is call.
  return useMemo(() => {
    const reduxFormComponent = reduxForm(props)(component);
    return reduxFormChangeOnEnterKeyDecorator(reduxFormComponent, form);
  }, [component, form, props]);
}
