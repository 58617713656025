import HTTPBadRequestError from 'lib/errors/http/badRequestError';
import HTTPUnauthorizedError from 'lib/errors/http/unauthorizedError';
import HTTPForbiddenError from 'lib/errors/http/forbiddenError';
import HTTPNotFoundError from 'lib/errors/http/notFoundError';
import HTTPNotAcceptableError from 'lib/errors/http/notAcceptableError';
import HTTPRequestEntityTooLargeError from 'lib/errors/http/requestEntityTooLargeError';
import HTTPUnsupportedMediaTypeError from 'lib/errors/http/unsupportedMediaTypeError';
import HTTPUnprocessableEntityError from 'lib/errors/http/unprocessableEntityError';
import HTTPClientError from 'lib/errors/http/clientError';
import HTTPServiceUnavailableError from 'lib/errors/http/serviceUnavailableError';
import HTTPServerError from 'lib/errors/http/serverError';
import HTTPError from 'lib/errors/http/error';

function createExceptionFromHttpResponse(httpResponse) {
  const message = httpResponse.data?.message;

  if (httpResponse.status === 400) {
    return new HTTPBadRequestError(message, httpResponse);
  }
  if (httpResponse.status === 401) {
    return new HTTPUnauthorizedError(message, httpResponse);
  }
  if (httpResponse.status === 403) {
    return new HTTPForbiddenError(message, httpResponse);
  }
  if (httpResponse.status === 404) {
    return new HTTPNotFoundError(message, httpResponse);
  }
  if (httpResponse.status === 406) {
    return new HTTPNotAcceptableError(message, httpResponse);
  }
  if (httpResponse.status === 413) {
    return new HTTPRequestEntityTooLargeError(message, httpResponse);
  }
  if (httpResponse.status === 415) {
    return new HTTPUnsupportedMediaTypeError(message, httpResponse);
  }
  if (httpResponse.status === 422) {
    return new HTTPUnprocessableEntityError(message, httpResponse);
  }
  if (httpResponse.status >= 400 && httpResponse.status < 500) {
    return new HTTPClientError(message, httpResponse);
  }
  if (httpResponse.status === 503) {
    return new HTTPServiceUnavailableError(message, httpResponse);
  }
  if (httpResponse.status >= 500) {
    return new HTTPServerError(message, httpResponse);
  }

  return new HTTPError(message, httpResponse);
}

export default function HTTPErrorFactory(httpResponse) {
  if (httpResponse.statusText === 'OK') {
    return null;
  }

  const exception = createExceptionFromHttpResponse(httpResponse);

  // we do not want the stack to include HTTPErrorFactory
  // therefore if an error/exception is created here
  // we want to exclude HTTPErrorFactory from the stack.
  if (Error.captureStackTrace) {
    Error.captureStackTrace(exception, HTTPErrorFactory);
  } else {
    const fakeError = new Error();
    exception.stack = fakeError.stack || 'No stack!';
  }

  return exception;
}
