import { useCallback } from 'react';

export default function useLegacyEvent(eventName: string) {
  return useCallback(
    (detail: Record<any, any> | null = null) => {
      const legacyEventName = `fr:iwd:reactEvents:${eventName}`;
      document.dispatchEvent(new CustomEvent(legacyEventName, { detail }));
    },
    [eventName]
  );
}
