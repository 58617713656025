import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { GUIDELINE_3D_EDITOR_MODAL } from 'app/common/constants/userflow';
import classNames from 'classnames';
import LoadingBar from 'app/common/components/loading/bar';
import styles from 'app/display/common/components/modals/index.css';

type ModalProps = {
  children: React.ReactNode;
  onClose(): void;
  onClick(): void;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  className?: string | null;
  isLoading: boolean;
  isFadeOut: boolean;
};

export default function Modal({
  children,
  onClose,
  headerLeft = undefined,
  headerRight = undefined,
  className = null,
  isLoading = false,
  isFadeOut,
}: ModalProps) {
  const ESCAPE_KEY_CODE = 27;

  const el = useMemo(() => {
    return document.createElement('div');
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(el);

  useEffect(() => {
    document.body.appendChild(el);
    // Focus is required to have onKeyDown event working as soon as the modal is displayed
    wrapperRef.current.focus();
    return () => {
      document.body.removeChild(el);
    };
  }, [wrapperRef, el]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.keyCode === ESCAPE_KEY_CODE) {
        e.stopPropagation();
        onClose();
      }
    },
    [onClose]
  );

  const handleModalClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
  };

  const asideClass = classNames(styles.overlay, {
    [styles.fadeOut]: isFadeOut && !isLoading,
    [styles.fadeIn]: !isFadeOut,
  });

  return createPortal(
    <aside
      ref={wrapperRef}
      className={asideClass}
      onClick={onClose}
      onKeyDown={handleKeyDown}
      tabIndex={-1} // required to detect keyboard event
    >
      <div className={classNames(styles.wrapper, 'reactBoxModelFix')} data-testid={GUIDELINE_3D_EDITOR_MODAL}>
        <div className={classNames(styles.modal, className)} onClick={handleModalClick}>
          {isLoading && <LoadingBar />}
          <header className={styles.header}>
            <section className={styles.headerLeft}>{headerLeft}</section>
            <section className={styles.headerRight}>{headerRight}</section>
          </header>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </aside>,
    el
  );
}
