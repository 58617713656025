import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import useHttpDeleteRequest from 'app/common/hooks/useHttpDeleteRequest';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import queryClient from 'lib/queryClient';
import { PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import i18next from 'i18next';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import { SlideImage } from 'yet-another-react-lightbox';

export default function useManageBestPracticePhoto(photo: SlideImage | undefined) {
  const putBestPractice = useHttpPutRequest(`/privates/stores/photos/${photo?.id}/bestPractices`);
  const deleteBestPractice = useHttpDeleteRequest(`/privates/stores/photos/${photo?.id}/bestPractices`);
  const isBestPractice = photo?.state?.bestpractice ?? false;
  const handleBestPractice = () => (isBestPractice ? deleteBestPractice : putBestPractice);

  const refreshLegacyPhoto = useLegacyEvent('UPDATE_PHOTO');

  const onSuccess = () => {
    queryClient.getInstance().invalidateQueries([PHOTOS_CACHE_KEY, photo?.id]);
    refreshLegacyPhoto({ photoId: photo?.idv4 });
  };

  const { mutateAsync } = useHttpMutation(handleBestPractice(), { onSuccess });

  return async () => {
    const message = isBestPractice ? 'slideshow.bestPractice.error.put' : 'slideshow.bestPractice.error.delete';
    const messageTranslated = i18next.t(message);
    try {
      await mutateAsync({});
    } catch (error) {
      notifyError(messageTranslated);
    }
  };
}
