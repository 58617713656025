import axios from 'axios';
import format from 'lib/helpers/i18n/format';

const fallbackLocale = 'en';
// const locale = iwdConfig.locale != null ? iwdConfig.locale.split('_').shift() : fallbackLocale;

const i18nextConfig = {
  debug: process.env.NODE_ENV !== 'production',
  fallbackLng: fallbackLocale,
  detection: {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
    checkWhitelist: true,
    excludeCacheFor: ['cimode'],
  },
  supportedLngs: ['en', 'fr', 'de', 'es', 'it', 'ja', 'ko', 'pt', 'ru', 'zh'],
  load: 'languageOnly',
  // Current format we use is kinda hacky
  // we have json with keys containing dots.
  // with these translations, we cannot use full i18next
  // support for namspaces and key separators
  // (it's  basically gettext format with fallbacks being fully qualified keys)
  // See http://i18next.com/docs/ for other configuration options
  nsSeparator: false,
  keySeparator: false,
  usePureComponent: true,
  backend: {
    // path where resources get loaded from, or a function
    // returning a path:
    // function(lngs, namespaces) { return customPath; }
    // the returned path will interpolate lng, ns if provided like giving a static path
    loadPath: `${process.env.BASE_URL}/locales/{{lng}}.json?appVersion=${process.env.BUILD_HASH}`,
    // i8next-xhr-backend supports sending missing translation keys to an address
    // through the addPath configuration key
    // as well as crossDomain / credentials / ajax function override etc.
    // see https://github.com/i18next/i18next-xhr-backend
    parse: data => data,
    ajax: async (url, options, callback) => {
      try {
        const { data, request } = await axios.get(url);
        callback(data, request);
      } catch (err) {
        console.error('Global locales not loaded', err);
      }
    },
  },
  interpolation: {
    escapeValue: false,
    format,
  },
};

export default i18nextConfig;
