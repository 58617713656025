import React from 'react';
import i18next from 'i18next';
import LogoutIcon from 'assets/icons/logout.svg';
import { PATH_DETACH } from 'config/routePaths';
import { BodyText2 } from 'app/common/typography';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';

import styles from './index.css';

const Logout = () => (
  <UserMenuLink to={PATH_DETACH}>
    <UserMenuIcon IconComponent={LogoutIcon} />
    <BodyText2 className={styles.uppercase}>{i18next.t('display.user.popup.link.logout')}</BodyText2>
  </UserMenuLink>
);

export default Logout;
