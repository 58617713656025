import { createAction } from '@reduxjs/toolkit';

export const toggleInfoPanel = createAction('slideshow.toggleInfoPanel');
export const toggleSlideshow = createAction<string | undefined>('slideshow.toggleSlideshow');
export const toggleFilmStrip = createAction('slideshow.toggleFilmStrip');
export const displaySelectedPhotoId = createAction<string | null | undefined>('slideshow.selectedPhotoId');
export const displayDrawingSVG = createAction('slideshow.displayDrawingSVG');
export const toggleCommentsPanel = createAction('slideshow.toggleCommentsPanel');

export default {
  toggleInfoPanel,
  toggleSlideshow,
  toggleFilmStrip,
  displaySelectedPhotoId,
  displayDrawingSVG,
  toggleCommentsPanel,
};
