import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import LoadingBar from 'app/common/components/loading/bar';
import styles from './popOverModal.css';

export const POP_OVER_MODAL = 'pop-over-modal';

type PopOverModalProps = {
  children: React.ReactNode;
  onClose(): void;
  onClick(): void;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  className?: string | null;
  isLoading: boolean;
  isFadeOut: boolean;
  width?: number;
  positionRight?: number;
  positionLeft?: number;
  positionTop?: number;
  positionBottom?: number;
};

export default function PopOverModal({
  children,
  onClose,
  headerLeft = undefined,
  headerRight = undefined,
  className = null,
  isLoading = false,
  isFadeOut,
  width,
  positionRight,
  positionLeft,
  positionTop,
  positionBottom,
}: PopOverModalProps) {
  const ESCAPE_KEY_CODE = 27;

  const el = useMemo(() => {
    return document.createElement('div');
  }, []);

  const wrapperRef = useRef<HTMLDivElement>(el);

  useEffect(() => {
    const v5Element = document.getElementById('app-layer');
    const v4Element = document.getElementById('applayer');
    const maskEl = document.createElement('div');
    v5Element?.appendChild(el);
    v4Element?.appendChild(el);
    maskEl.className = styles.mask;
    v5Element?.appendChild(maskEl);
    v4Element?.appendChild(maskEl);
    return () => {
      v5Element?.removeChild(el);
      v4Element?.removeChild(el);
      v5Element?.removeChild(maskEl);
      v4Element?.removeChild(maskEl);
    };
  }, [wrapperRef, el]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.keyCode === ESCAPE_KEY_CODE) {
        e.stopPropagation();
        onClose();
      }
    },
    [onClose]
  );

  const handleModalClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
  };

  const asideClass = classNames(styles.overlay, {
    [styles.fadeOut]: isFadeOut && !isLoading,
    [styles.fadeIn]: !isFadeOut,
  });

  const wrapperStyle = { width, left: positionLeft, top: positionTop, right: positionRight, bottom: positionBottom };

  return createPortal(
    <aside
      ref={wrapperRef}
      className={asideClass}
      onClick={onClose}
      onKeyDown={handleKeyDown}
      tabIndex={-1} // required to detect keyboard event
    >
      <div className={classNames(styles.wrapper, 'reactBoxModelFix')} style={wrapperStyle} data-testid={POP_OVER_MODAL}>
        <div className={classNames(styles.modal, className)} onClick={handleModalClick}>
          {isLoading && <LoadingBar />}
          <header className={styles.header}>
            <section className={styles.headerLeft}>{headerLeft}</section>
            <section className={styles.headerRight}>{headerRight}</section>
          </header>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </aside>,
    el
  );
}
