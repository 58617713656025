import { PhotoReportFrequency } from 'domain/user/notificationSettings';

export const USER_SHEET_LICENSE_TAB_FORM = 'userSheetLicenseTabForm';
export const USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM = 'userSheetNotificationSettingsTabForm';
export const USER_SHEET_LICENSE_TAB_FORM_INITIAL_VALUES = {
  'license-projectId': null,
  'license-serviceCode': null,
  createRole: 'norole',
  executeRole: 'norole',
  has2d: null,
  has3d: null,
};

export const USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM_INITIAL_VALUES = {
  documentShared: true,
  guidelineShared: true,
  photoApprove: true,
  photoBestPractices: true,
  photoReplaced: true,
  photoDeleted: true,
  surveyCompletionReminder: true,
  surveyPublicationAvailable: true,
  surveyPublicationOwner: true,
  isActivityReportEnabled: true,
  photoReportFrequency: PhotoReportFrequency.monthly,
  isSurveyOwnerActivityReportEnabled: true,
  saveNotificationSettings: true,
};
