export type Middleware<Input = unknown, Output = Input> = (input: Input) => Output;

export default class MiddlewaresManager<Input, Output = Input> {
  private middlewares: Middleware<Input, Output>[] = [];

  /**
   * Add a middleware function to the stack
   */
  add(middleware: Middleware<Input, Output>) {
    this.middlewares.push(middleware);
  }

  /**
   * Runs all the added middleware functions on the given parameters
   */
  run(params: any) {
    return this.middlewares.reduce((result: any, middleware) => middleware(result) ?? result, params);
  }
}
