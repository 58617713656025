import { AVAILABLE_ACTIVITIES_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';

export type AvailableActivity = {
  code: string;
  description: string;
};

export type AvailableActivitiesQueryResult = {
  availableActivities: AvailableActivity[] | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
};

export default function useAvailableActivities(): AvailableActivitiesQueryResult {
  const getAvailableActivities = useHttpGetRequest<[]>('/privates/activities/available', {});

  const {
    data: availableActivities,
    isLoading,
    isError,
    error,
  } = useHttpQuery([AVAILABLE_ACTIVITIES_QUERY_CACHE_KEY], getAvailableActivities);

  return { availableActivities, isLoading, isError, error };
}
