import { useCallback, useState } from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal } from 'app/common/actions/modals';
import { FileLoaderFile } from '@iwd/iwd-fileloader';

type UsePhotoUploaderModalResult = {
  onClose(): void;
  t: TFunction;
  photoList: FileLoaderFile[];
  onChange(fileMovedIndex: number, newLocationIndex: number): void;
};

export default function usePhotoUploaderModal(files: FileLoaderFile[]): UsePhotoUploaderModalResult {
  const onClose = useAction(hideModal);
  const { t } = useTranslation();
  const [photoList, setPhotoList] = useState(files);

  const onChange = useCallback((dragIndex: number, dropIndex: number) => {
    if (dragIndex === dropIndex) {
      return null;
    }

    return setPhotoList(prevPhotoList => {
      const photos = [...prevPhotoList];
      // this is required to properly manage the changing array index
      const indexToMoveTo = dragIndex < dropIndex ? dropIndex : dropIndex + 1;
      photos.splice(dragIndex, 1);
      photos.splice(indexToMoveTo, 0, prevPhotoList[dragIndex]);
      return photos;
    });
  }, []);

  return { onClose, t, photoList, onChange };
}
