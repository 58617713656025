import { HIDE_LOADING_BAR, SHOW_LOADING_BAR } from 'app/common/actions/loadingBar/types';
import { AnyAction } from 'redux';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import { query } from 'app/common/actions/api';
import download from 'lib/helpers/download';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from 'lib/providers/store';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import i18next from 'i18next';

export default function exportAsCSV(guidelineIds: string[], storeId: string) {
  return async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      dispatch({ type: SHOW_LOADING_BAR });
      const response = await dispatch(
        query<Blob, { guidelineIds: string[] }>(`/stores/${storeId}/guidelines/export`, {
          method: 'POST',
          data: {
            guidelineIds,
          },
          getHeaders: true,
          responseType: 'text',
          headers: {
            Accept: 'text/csv',
          },
        })
      );
      const fileName = decodeURIComponent(response.headers['content-disposition'].split('filename*=')[1]);
      const mimeType = response.headers['content-type'];
      download(response.data, fileName, mimeType);
    } catch (error) {
      assertIsNotCriticalHttpException(error);
      notifyError(i18next.t('network.store.sheet.tabs.guidelines.exportAsCSV.error'));
    }

    dispatch({ type: HIDE_LOADING_BAR });
  };
}
