import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import noop from 'lib/helpers/noop';

import RoundedButton from 'app/display/common/components/roundedButton';
import PlusIcon from 'assets/icons/plus.svg';
import styles from './style.css';

/**
 * A PlusButton is a RoundedButton with a PlusIcon inside.
 * As it is a well-known Material Design component, it exists on its own.
 *
 * @example ./index.md
 */
const PlusButton = ({ className, isActive = false, onClick, icon, dataTestId }) => {
  return (
    <RoundedButton
      className={classNames(styles.plusButton, className, {
        [styles.isActive]: isActive,
      })}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {icon || <PlusIcon className={styles.icon} />}
    </RoundedButton>
  );
};

PlusButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  dataTestId: PropTypes.string,
};

PlusButton.defaultProps = {
  className: null,
  isActive: false,
  onClick: noop,
  icon: null,
  dataTestId: null,
};

export default PlusButton;
