export const APPSHELL = 'appshell';
export const APPSHELL_CONTENT_MASK = 'content-mask';
export const APPSHELL_IWD_LOGO = 'appshell-iwd-logo';
export const APPSHELL_CATALOG = 'appshell-catalog';
export const APPSHELL_GUIDELINES = 'appshell-guidelines';
export const APPSHELL_STORES = 'appshell-stores';
export const APPSHELL_LIBRARY = 'appshell-library';
export const APPSHELL_SURVEYS = 'appshell-surveys';
export const APPSHELL_REPORTS = 'appshell-reports';
export const APPSHELL_DOCUMENTS = 'appshell-documents';
export const APPSHELL_USER_MENU = 'appshell-user-menu';

export const USER_MENU_ACL = 'user-menu-acl';
export const USER_MENU_IMPORT = 'user-menu-import';
export const USER_MENU_TAGS = 'user-menu-tags';
export const USER_ACL_LIST_SECTION = 'user-acl-list-section';
export const USER_ACL_RIGHT_BY_ROLE = 'user-acl-right-by-role';
export const USER_ACL_ACTION_BAR_SAVE_BUTTON = 'user-acl-action-bar-save-button';

export const PLUS_BUTTON = 'plus-button';
export const PLUS_BUTTON_ITEM = 'plus-button-item';
export const PLUS_BUTTON_BRAND = 'plus-button-brand';
export const PLUS_BUTTON_CATALOG_FOLDER = 'plus-button-catalog-folder';
export const PLUS_BUTTON_ITEM_PRESET_VARIANT = 'plus-button-item-preset-variant';
export const PLUS_BUTTON_2D_GUIDELINE = 'plus-button-2d-guideline';
export const PLUS_BUTTON_3D_GUIDELINE = 'plus-button-3d-guideline';
export const PLUS_BUTTON_PERSONAL_FOLDER = 'plus-button-personal-folder';
export const PLUS_BUTTON_GLOBAL_FOLDER = 'plus-button-global-folder';

export const EDITOR_ACTION_BAR_LINK = 'action-bar-link';
export const CLOSE_BUTTON_ACTION_BAR = 'close-button-action-bar';
export const ACTION_BUTTON_ENTRY = 'action-button-entry';
export const ACTION_RENAME_FOLDER = 'action-rename-folder';
export const ACTION_POPUP_LIST = 'action-popup-list';

export const LIST_ROW_ELEMENT_ENTRY_LINK = 'list-row-element-entry-link';

export const ITEM_STATUS = 'item-status';

export const ITEM_SHEET_ACTION_MENU = 'item-sheet-action-menu';
export const ITEM_SHEET_ACTVATE_ACTION = 'item-sheet-activate-action';
export const ITEM_SHEET_DEACTVATE_ACTION = 'item-sheet-deactivate-action';
export const ITEM_SHEET_DUPLICATE_ACTION = 'item-sheet-duplicate-action';
export const ITEM_FORM_CREATE_BUTTON = 'item-form-create-button';
export const ITEM_FORM_SAVE_BUTTON = 'item-form-save-button';
export const ITEM_SHEET_MODIFY_BUTTON = 'item-sheet-modify-button';
export const ITEM_SHEET_VIEW_SUMMARY = 'item-sheet-view-summary';
export const ITEM_SHEET_MERCH_RULES = 'item-sheet-merch-rules';
export const ITEM_SHEET_MARKET_FORM = 'item-sheet-market-form';
export const ITEM_SHEET_THUMBNAIL = 'item-sheet-thumbnail';
export const ITEM_MODIFY_FORM = 'item-modify-form';
export const ITEM_SHEET_NAVITEMS = 'item-sheet-navitems';
export const ITEM_3D_MODEL_CHANGE_BUTTON = 'item-3d-model-change-button';
export const DOWNLOAD_3D_BUTTON = 'download-3d-button';

export const GUIDELINE_ACCESS_DOCUMENT_MENU_ACTION = 'access-document-menu-action';
export const GUIDELINE_ACCESS_DOCUMENT_HEADER = 'access-document-header';
export const GUIDELINE_SHEET_ACTION_MENU = 'guideline-sheet-action-menu';
export const DUPLICATE_GUIDELINE_ACTION_TEST_ID = 'duplicate-guideline-action-test-id';
export const SHARE_GUIDELINE_ACTION_TEST_ID = 'share-guideline-action-test-id';
export const EXPORT_GUIDELINE_ACTION_TEST_ID = 'export-guideline-action-test-id';
export const PRIVATE_GUIDELINE_STATUS = 'private-guideline-status';
export const SHARED_GUIDELINE_STATUS = 'shared-guideline-status';
export const SHAREDWITHHQ_GUIDELINE_STATUS = 'sharedWithHq-guideline-status';
export const GUIDELINE_SHEET_OPEN_EDITOR_2D_BUTTON = 'guideline-sheet-open-editor-2d-button';
export const GUIDELINE_SHEET_OPEN_EDITOR_3D_BUTTON = 'guideline-sheet-open-editor-3d-button';
export const GUIDELINE_SHEET_MENU_MANAGE_DOCUMENT_ACTION = 'guideline-sheet-menu-manage-document-action';
export const GUIDELINE_SHEET_VIEW_SUMMARY = 'guideline-sheet-view-summary';
export const GUIDELINE_FORM_CREATE_BUTTON = 'guideline-form-create-button';
export const GUIDELINE_FORM_SAVE_BUTTON = 'guideline-form-save-button';
export const GUIDELINE_FORM = 'guideline-form';
export const GUIDELINE_SHEET_MODIFY_BUTTON = 'guideline-sheet-modify-button';
export const GUIDELINE_MANAGE_CATALOG_BUTTON = 'guideline-manage-catalog-button';
export const GUIDELINE_MANAGE_CATALOG_MODALE_CONFIRM_BUTTON = 'guideline-manage-catalog-modal-confirm-button';
export const GUIDELINE_MANAGE_CATALOG_MODALE_BRAND = 'guideline-manage-catalog-modal-brand';
export const GUIDELINE_REMOVE_FOLDER = 'guideline-remove-folder';
export const GUIDELINE_SELECT_FOLDER_BUTTON = 'guideline-select-folder-button';
export const GUIDELINE_SELECT_FOLDER_MODAL_FOLDER = 'guideline-select-folder-modal-folder';
export const GUIDELINE_SEARCH_FILTER_TYPE = 'guideline-search-filter-type';

export const GUIDELINE_EDITOR = 'editor';
export const GUIDELINE_EDITOR_SCENE_CANVAS = 'scene-canvas';
export const GUIDELINE_3D_EDITOR_MODAL = 'guideline-3d-editor-modal';
export const GUIDELINE_EDITOR_MODAL_LINK = 'guideline-editor-modal-link';
export const GUIDELINE_DOCUMENT_LIVE_PREVIEW_BODY = 'guideline-document-live-preview-body';
export const GUIDELINE_DOCUMENT_LIVE_PREVIEW_BUTTON = 'guideline-document-live-preview-button';

export const CAMPAIGN_FORM_CREATE_BUTTON = 'campaign-form-create-button';
export const CAMPAIGN_FORM_SAVE_BUTTON = 'campaign-form-save-button';
export const CAMPAIGN_SHEET_MODIFY_BUTTON = 'campaign-sheet-modify-button';

export const ITEM_LIST_EMPTY = 'item-list-empty';
export const ITEM_LIST_FOLDER_TAB = 'item-list-folder-tab';
export const ITEM_LIST_SEARCH_TAB = 'item-list-search-tab';
export const ITEM_LIST_SEARCH_FIELD = 'item-list-search-field';
export const ITEM_LIST_ONLY_ON_FILTER = 'item-list-only-on-filter';
export const ITEM_LIST_ENTRIES = 'list-entries';
export const ITEM_LIST_ENTRY = 'item-list-entry';
export const ITEM_LIST_COUNT = 'item-list-count';
export const ITEM_LIST_CODE = 'item-list-code';
export const ITEM_LIST_NAME = 'item-list-name';
export const SEARCH_INPUT_MODEL_3D = 'search-input-model-3D';
export const SEARCH_RESULTS_LIST_MODEL_3D = 'search-results-list-model-3D';

export const CATALOG_SEARCH_ITEM_LIST = 'catalog-search-item-list';
export const CATALOG_SEARCH_MARKET_CHECKBOX = 'catalog-search-market-checkbox';
export const CATALOG_SEARCH_ITEM_CHECKBOX = 'catalog-search-item-checkbox';
export const CATALOG_SEARCH_AUTHOR_CHECKBOX = 'catalog-search-author-checkbox';
export const CATALOG_SEARCH_ITEMTYPE_CHECKBOX = 'catalog-search-itemtype-checkbox';
export const CATALOG_SORTED_BY_LIST = 'catalog-sorted-by-list';
export const CATALOG_SORTED_OPTION_LIST = 'catalog-sorted-option-list';

export const BUTTON_SELECT_ALL_LIST = 'catalog-list-select-all';
export const BUTTON_DESELECT_ALL_LIST = 'catalog-list-deselect-all';
export const ENABLED_SEACH_BUTTON = 'enabled-search-button';

export const GUIDELINE_LIST_EMPTY = 'guideline-list-empty';
export const GUIDELINE_LIST_FOLDER_TAB = 'guideline-list-folder-tab';
export const GUIDELINE_LIST_SEARCH_TAB = 'guideline-list-search-tab';
export const GUIDELINE_LIST_SEARCH_FIELD = 'guideline-list-search-field';
export const GUIDELINE_LIST_ONLY_ON_FILTER = 'guideline-list-only-on-filter';

export const PRESET_VARIANT_FORM_SELECT_FOLDERS_BUTTON = 'preset-variant-form-select-folders-button';

export const SELECT_FOLDER_MODAL_CONFIRM_BUTTON = 'select-folder-modal-confirm-button';

export const NOTIFICATION_SUCCESS = 'notification-success';

export const UNAUTHORIZED = 'unauthorized';

export const DASHBOARD_BANNER = 'dashboard-banner';

export const INSTANCE_SETTINGS_FORM = 'instance-settings-form';
