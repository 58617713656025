import featuresEnabledLocal from 'config/featuresEnabled.json';

type FeaturesEnabled = {
  [key: string]: boolean;
  platformMenu: boolean;
};

class FeatureFlags {
  private static instance: FeatureFlags | null;

  public readonly featuresEnabled: FeaturesEnabled;

  private constructor() {
    const featuresEnabled: any = {
      ...featuresEnabledLocal,
    };

    const params = new URLSearchParams(window.location.search);

    this.featuresEnabled = Object.keys(featuresEnabled).reduce((acc: any, flag) => {
      acc[flag] = params.has(`ff_${flag}`) || featuresEnabled[flag];
      return acc;
    }, {});
  }

  public static getInstance(): FeatureFlags {
    if (!FeatureFlags.instance) {
      FeatureFlags.instance = new FeatureFlags();
    }

    return FeatureFlags.instance;
  }

  // function only for testing
  // resets the instance so it can be reinstantiated
  public static reset() {
    this.instance = null;
  }
}

export default FeatureFlags;
