import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';
import { getCurrentLocation } from 'app/common/selectors/router';
import {
  PATH_CATALOG_EDITOR,
  PATH_GUIDELINE_OPEN,
  PATH_GUIDELINE_DOCUMENT,
  PATH_LOGOUT,
  PATH_DETACH,
  PATH_SLIDESHOW,
} from 'config/routePaths';

const isAppshellContentFullscreen = createSelector(
  [getCurrentLocation],
  ({ pathname }) =>
    !!matchPath(pathname, PATH_CATALOG_EDITOR) ||
    !!matchPath(pathname, PATH_GUIDELINE_OPEN) ||
    !!matchPath(pathname, { path: PATH_LOGOUT, exact: true }) ||
    !!matchPath(pathname, { path: PATH_DETACH, exact: true }) ||
    !!matchPath(pathname, { path: PATH_GUIDELINE_DOCUMENT, exact: true }) ||
    !!matchPath(pathname, { path: PATH_SLIDESHOW, exact: true })
);

export default isAppshellContentFullscreen;
