import useLocationMatch from 'app/common/hooks/useLocationMatch';
import {
  PATH_IMPORT_FILES,
  PATH_NETWORK_INDEX,
  PATH_SETTINGS,
  PATH_SURVEY_REPORT_VISITS,
  PATH_ACL_INDEX,
} from 'config/routePaths';
import { APP_DISPLAY, APP_NETWORK } from 'app/common/ui/constants';

export default function useCurrentApplicationModule() {
  const matchNetworkRoute = useLocationMatch(PATH_NETWORK_INDEX, { exact: false });
  const matchVisitsRoute = useLocationMatch(PATH_SURVEY_REPORT_VISITS, {
    exact: false,
  });
  const matchImportRoute = useLocationMatch(PATH_IMPORT_FILES, {
    exact: false,
  });
  const matchSettingsRoute = useLocationMatch(PATH_SETTINGS, {
    exact: false,
  });
  const matchACLRoute = useLocationMatch(PATH_ACL_INDEX, {
    exact: false,
  });

  if (matchNetworkRoute || matchVisitsRoute) {
    return APP_NETWORK;
  }

  if (!matchImportRoute && !matchSettingsRoute && !matchACLRoute) {
    return APP_DISPLAY;
  }

  return null;
}
