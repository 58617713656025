import React from 'react';
import Guideline from 'domain/guideline';
import TitlePanelWithSelectAll from 'app/network/store/sheet/tabs/guidelines/components/guidelinesList/titlePanelWithSelectAll';
import GuidelineListEntry from 'app/network/store/sheet/tabs/guidelines/components/guidelinesList/guidelineEntry';
import { CampaignNames } from 'app/network/store/sheet/tabs/guidelines/helpers/sortGuidelinesByCampaignName';
import styles from './campaign.css';

type StoreGuidelinesListCampaignProps = {
  campaignName: CampaignNames;
  campaignId: string | null;
  guidelines: Guideline[];
};

export default function StoreGuidelinesListCampaign({
  campaignName,
  guidelines,
  campaignId,
}: StoreGuidelinesListCampaignProps) {
  const guidelineIds = guidelines.map(({ id }) => id);
  return (
    <TitlePanelWithSelectAll campaignName={campaignName} guidelineIds={guidelineIds} campaignId={campaignId}>
      <div className={styles.guidelineList}>
        {guidelines.map(guideline => (
          <GuidelineListEntry guideline={guideline} key={guideline.id} campaignName={campaignName} />
        ))}
      </div>
    </TitlePanelWithSelectAll>
  );
}
