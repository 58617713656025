import React from 'react';
import i18next from 'i18next';
import { HeadText5 } from 'app/common/typography';
import styles from '../../components/searchDropDown/index.css';

function createLabel(inputValue: string, createLabelTranslationKey: string | undefined) {
  const label = createLabelTranslationKey
    ? i18next.t(createLabelTranslationKey, { value: inputValue })
    : i18next.t('display.common.form.createLabel', { value: inputValue });
  return <HeadText5 className={styles.createNew}>{label}</HeadText5>;
}

export default createLabel;
