import i18next from 'i18next';
import shouldReportApiException from 'app/common/helpers/api/shouldReportApiException';
import getTranslationKeyAndExceptionTypeFromApiException from 'app/common/helpers/api/getTranslationKeyAndExceptionTypeFromApiException';
import reportException from 'lib/bugsnag/helpers/reportException';
import ApiExceptionType from 'app/common/actions/api/constants';

type MessageAndExceptionTypeType = {
  message: string;
  exceptionType: ApiExceptionType;
};

export default function getMessageFromApiException(
  exception: unknown,
  serverErrorMessage?: string | null
): MessageAndExceptionTypeType {
  if (shouldReportApiException(exception)) {
    reportException(exception);
  }

  const { translationKey, exceptionType } = getTranslationKeyAndExceptionTypeFromApiException(exception);
  const translatedApiMessage = translationKey
    ? i18next.t(translationKey)
    : serverErrorMessage || i18next.t('common.unknownServerError');

  return { message: translatedApiMessage, exceptionType };
}
