import { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import isIE from 'lib/helpers/browser';
import { DRAG_PAYLOAD_SCOPE_TYPE_ALL, DRAG_PAYLOAD_SCOPE_TYPE_SELECTION } from 'app/display/common/constants/dragDrop';

function buildIsDragging(dragPayload) {
  if (dragPayload.scope?.type === DRAG_PAYLOAD_SCOPE_TYPE_ALL) {
    return monitor => monitor.getItem().scope?.filters != null;
  }
  if (dragPayload.scope?.type === DRAG_PAYLOAD_SCOPE_TYPE_SELECTION) {
    return monitor => monitor.getItem().scope?.ids?.includes(dragPayload.id);
  }

  return null;
}

export default function useDragZone(dragPayload, canDrag, onDragBegin, onDragEnd, onDrop, hideDefaultPreview) {
  const [{ isDragged }, drag, preview] = useDrag({
    item: dragPayload,
    canDrag,
    isDragging: buildIsDragging(dragPayload),
    collect: monitor => ({ isDragged: monitor.isDragging() }),
    begin: () => {
      onDragBegin();
      return dragPayload;
    },
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        const sourcePayload = monitor.getItem();
        const targetPayload = monitor.getDropResult();
        onDrop(sourcePayload, targetPayload);
      }
      onDragEnd();
    },
  });

  const showPlaceholder = isDragged && hideDefaultPreview && isIE();

  useEffect(() => {
    if (hideDefaultPreview) {
      preview(getEmptyImage(), { captureDraggingState: true });
    }
  }, [preview, hideDefaultPreview]);

  return { isDragged, drag, showPlaceholder };
}
