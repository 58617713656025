import { useSelector } from 'react-redux';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import getIwdConfig from 'app/common/selectors/iwdConfig/getIwdConfig';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';

export default function useCurrentUserHasFoldersAccess(): boolean {
  const { currentUser } = useCurrentUser();
  const iwdConfig = useSelector(getIwdConfig);
  const { aclRight: hasCampaignManage } = useCurrentUserAclRight('legacy.campaign.manage');

  return !iwdConfig.isAsp && !!currentUser?.settings.network.hasNetwork && hasCampaignManage;
}
