import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import noop from 'lib/helpers/noop';
import PlusButton from 'app/display/common/components/plusButton';
import PlusMenuButton from 'app/display/common/components/plusMenu/button';
import PlusMenuLink from 'app/display/common/components/plusMenu/link';
import SingleActionPlusButton from 'app/display/common/components/plusMenu/singleActionButton';
import SingleActionPlusLink from 'app/display/common/components/plusMenu/singleActionLink';
import styles from './style.css';

/**
 * PlusMenu is a material-like add menu.
 *
 * It provides a PlusButton by default to open/close the menu.
 * It accepts only [PlusMenuButton](#!/Button) & [PlusMenuLink](#!/Link) components as children.
 *
 * @see [Zeplin link](https://zpl.io/2pBPGJM)
 * @see see [PlusMenuButton](#!/Button)
 * @see see [PlusMenuLink](#!/Link)
 *
 * @example ./index.md
 */
const PlusMenu = ({ actions, onToggle, icon, className, dataTestId }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return () => {
      onToggle(false);
    };
  }, [onToggle]);

  const handleDocumentClick = useCallback(
    event => {
      const hasClickedOutside = ref.current != null && ref.current.contains(event.target) === false;
      if (hasClickedOutside) {
        // Whatever we have clicked on, this will cancel it, as we want a ghost click
        event.stopPropagation();
        event.preventDefault();
        setIsOpen(false);
        onToggle(false);
      }
      // we can stop listening to document click
      document.body.removeEventListener('click', handleDocumentClick, true);
    },
    [onToggle]
  );

  const handleClick = () => {
    if (!isOpen) {
      // we are currently CLOSED, so we will OPEN: we must listen to future document click,
      // as the menu must close itself if an "out of bound click" occurs
      document.body.addEventListener('click', handleDocumentClick, true);
    }
    setIsOpen(!isOpen);
    onToggle(!isOpen);
  };

  const classList = classNames(styles.menu, className, {
    [styles.isOpen]: isOpen,
  });

  if (actions.length === 0) {
    return null;
  }

  if (actions.length === 1) {
    const [action] = actions;

    return action.to ? (
      <SingleActionPlusLink to={action.to} label={action.label} icon={icon} dataTestId={action.dataTestId} />
    ) : (
      <SingleActionPlusButton
        onClick={action.onClick}
        label={action.label}
        icon={icon}
        dataTestId={action.dataTestId}
      />
    );
  }

  return (
    <div className={classList} ref={ref} onClick={handleClick}>
      <div className={styles.children}>
        {actions.map(action =>
          action.to ? (
            <PlusMenuLink
              key={action.label}
              label={action.label}
              icon={action.icon}
              to={action.to}
              dataTestId={action.dataTestId}
            />
          ) : (
            <PlusMenuButton
              key={action.label}
              label={action.label}
              icon={action.icon}
              onClick={action.onClick}
              dataTestId={action.dataTestId}
            />
          )
        )}
      </div>
      <PlusButton icon={icon} className={styles.plusButton} isActive={isOpen} dataTestId={dataTestId} />
    </div>
  );
};

PlusMenu.propTypes = {
  /**
   * Called when the menu is opened/closed.
   *
   * @param {bool} isOpen true when opened, false otherwise
   */
  onToggle: PropTypes.func,
  icon: PropTypes.node,
  className: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  dataTestId: PropTypes.string,
};

PlusMenu.defaultProps = {
  onToggle: noop,
  icon: null,
  className: null,
  dataTestId: null,
};

export default PlusMenu;
