import React from 'react';
import LicenseTabForm from 'app/directory/user/sheet/common/components/form/license';
import { USER_SHEET_LICENSE_TAB_FORM } from 'app/directory/user/sheet/common/constants';
import useEditUserFormInitialValues from 'app/directory/user/sheet/edit/hooks/useEditUserFormInitialValues';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import LicenseTabFormSkeleton from 'app/directory/user/sheet/common/components/form/license/index.skeleton';
import useValidateEditUserSheetForm from 'app/directory/user/sheet/common/hooks/useValidateEditUserSheetForm';
import useAction from 'app/display/common/hooks/useAction';
import { disableUserSheetSaveButton } from 'app/directory/user/sheet/common/actions';
import useReduxFormComponent from 'app/display/common/hooks/useReduxFormComponent';

type EditUserLicenseTabProps = {
  userId: string;
};

export default function EditUserLicenseTab({ userId }: EditUserLicenseTabProps) {
  const { initialValues, isLoading } = useEditUserFormInitialValues(userId);
  const FormComponent = useReduxFormComponent(LicenseTabForm, USER_SHEET_LICENSE_TAB_FORM);
  const submitForm = useLegacyEvent('SUBMIT_USER_FORM');
  const validateEditForm = useValidateEditUserSheetForm(userId);
  const disableSaveButton = useAction(disableUserSheetSaveButton);

  if (isLoading) {
    return <LicenseTabFormSkeleton />;
  }

  const handleSubmit = () => {
    disableSaveButton();
    submitForm();
  };

  return <FormComponent initialValues={initialValues} onSubmit={handleSubmit} validate={validateEditForm} />;
}
