import { query } from 'app/common/actions/api';

import { FETCH, FETCH_SUCCESS, FETCH_ERROR } from 'app/common/actions/marketsTypes';

export default function fetchAllMarkets() {
  return async dispatch => {
    try {
      dispatch({ type: FETCH });
      const markets = await dispatch(query('markets'));
      dispatch({ type: FETCH_SUCCESS, markets });
      return markets;
    } catch (error) {
      dispatch({
        type: FETCH_ERROR,
        error,
      });
      throw error;
    }
  };
}
