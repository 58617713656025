import React from 'react';
import PropTypes from 'prop-types';
import { BodyText1 } from 'app/common/typography';
import styles from './subtitle.css';

const SubTitle = ({ children }) => {
  return (
    <div className={styles.subtitle}>
      <BodyText1>{children}</BodyText1>
    </div>
  );
};

SubTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubTitle;
