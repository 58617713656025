import useCurrentUser from 'app/common/hooks/useCurrentUser';

type SettingsType = {
  hasCurrentUserSettings2DAccess: boolean;
  hasCurrentUserSettings3DAccess: boolean;
  isLoading: boolean;
};

export default function useCurrentUserDisplayAccess(): SettingsType {
  const { currentUser, isLoading } = useCurrentUser();
  const isCurrentUser = currentUser !== undefined;

  if (isLoading) {
    return { hasCurrentUserSettings2DAccess: false, hasCurrentUserSettings3DAccess: false, isLoading: true };
  }

  const has2DEditorAccess = isCurrentUser && currentUser.settings.editors.has2d;
  const has3DEditorAccess = isCurrentUser && currentUser.settings.editors.has3d;

  return {
    hasCurrentUserSettings2DAccess: has2DEditorAccess,
    hasCurrentUserSettings3DAccess: has3DEditorAccess,
    isLoading,
  };
}
