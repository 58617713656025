import User from 'domain/user';

/**
 * A user with a minimal set of information.
 */
export default class BasicUser extends User {
  getPhotoUrlFilter(): null | string {
    if (this.photoUrl === undefined) {
      return null;
    }
    return this.photoUrl;
  }

  getNameHidingIwd(): string {
    return this.isIwd ? 'IWD' : this.name;
  }

  isV4User(): boolean {
    return this.idv4 !== null;
  }
}
