import React from 'react';
import { addToolbarButton, PluginProps } from 'yet-another-react-lightbox';
import SlideshowDeletePhotoIcon from 'app/common/components/slideshow/deletePhoto/icon';

declare module 'yet-another-react-lightbox' {
  interface Callbacks {
    deletePhoto: (photoId?: string) => void;
  }
}

export const SLIDESHOW_DELETE_PHOTO_PLUGIN_ID = 'deletePhoto';
export default function DeletePhoto({ augment }: PluginProps) {
  augment(({ toolbar, ...restProps }) => {
    return {
      toolbar: addToolbarButton(
        toolbar,
        SLIDESHOW_DELETE_PHOTO_PLUGIN_ID,
        <SlideshowDeletePhotoIcon {...restProps} toolbar={toolbar} />
      ),
      ...restProps,
    };
  });
}
