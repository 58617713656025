import WebsocketMessage from 'domain/webSockets/message';
import WebSocketMessageTypes from 'domain/webSockets/messageTypes';
import { USER_NOTIFICATIONS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import UserNotification, { UserNotificationPayload } from 'domain/userNotifications';
import UserNotificationFactory from 'domain/userNotifications/factory';
import WebSocketMessageHandlerAbstract from 'domain/webSockets/messageHandler';

export default class UserNotificationsWebsocketMessageHandler extends WebSocketMessageHandlerAbstract {
  supportedMessageTypes = [WebSocketMessageTypes.NOTIFICATION_CREATE];

  processMessage(message: WebsocketMessage) {
    switch (message.type) {
      case WebSocketMessageTypes.NOTIFICATION_CREATE:
        this.handleNotificationCreate(message as WebsocketMessage<UserNotificationPayload>);
        break;
      default:
        console.warn(`Unhandled message: ${message.type}`);
    }
  }

  handleNotificationCreate(message: WebsocketMessage<UserNotificationPayload>) {
    this.queryClient.setQueryData([USER_NOTIFICATIONS_CACHE_KEY], (oldData?: UserNotification[]) => {
      if (!oldData) {
        return [UserNotificationFactory.createFromApiPayload(message.payload)];
      }

      if (oldData?.some(notification => notification.id === message.payload.id)) {
        return oldData;
      }

      return [UserNotificationFactory.createFromApiPayload(message.payload), ...oldData];
    });
  }
}
