import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';
import { MOVE_PERSONAL_FOLDER } from 'app/display/guideline/common/actions';
import {
  CREATE_PERSONAL_FOLDER_SUCCESS,
  RENAME_PERSONAL_FOLDER_SUCCESS,
  DELETE_PERSONAL_FOLDER_SUCCESS,
} from 'app/display/guideline/list/common/actions';

export const FETCH_PERSONAL_FOLDERS_START = 'guidelines.myfolders.list.fetch.start';
export const FETCH_PERSONAL_FOLDERS_ERROR = 'guidelines.myfolders.list.fetch.error';
export const FETCH_PERSONAL_FOLDERS_SUCCESS = 'guidelines.myfolders.list.fetch.success';

export const initialState = {
  status: STATUS_LOADING,
  list: [],
};

function removeFolderAndChildren(folderId, list) {
  const children = list.filter(({ parent }) => parent != null && parent.id === folderId);
  const filteredList = children.reduce(
    (acc, child) => {
      return removeFolderAndChildren(child.id, acc);
    },
    [...list]
  );
  return filteredList.filter(({ id }) => id !== folderId);
}

function updateFolderParent(list, folderId, parentId) {
  const parent = parentId == null ? null : { id: parentId };
  const folderIndex = list.findIndex(folder => folder.id === folderId);
  if (folderIndex > -1) {
    const newList = [...list];
    const folder = newList[folderIndex];
    newList[folderIndex] = { ...folder, parent };
    return newList;
  }
  return list;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PERSONAL_FOLDERS_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_PERSONAL_FOLDERS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        list: action.personalFolders,
      };
    case FETCH_PERSONAL_FOLDERS_ERROR:
      return {
        ...state,
        status: STATUS_ERROR,
      };
    case CREATE_PERSONAL_FOLDER_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.folder],
      };
    case RENAME_PERSONAL_FOLDER_SUCCESS: {
      const folderIndex = state.list.findIndex(({ id }) => id === action.folder.id);
      if (folderIndex !== -1) {
        const list = [...state.list];
        list[folderIndex] = action.folder;
        return {
          ...state,
          list,
        };
      }
      return state;
    }
    case DELETE_PERSONAL_FOLDER_SUCCESS: {
      const list = removeFolderAndChildren(action.folderId, state.list);
      return {
        ...state,
        list,
      };
    }
    case MOVE_PERSONAL_FOLDER: {
      const list = updateFolderParent(state.list, action.folderId, action.parentId);
      if (list !== state.list) {
        return { ...state, list };
      }
      return state;
    }
    default:
      return state;
  }
}
