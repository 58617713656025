import React from 'react';

import Settings from 'app/common/components/legacyNavMenu/settings';
import UserButton from 'app/common/components/appShell/containers/header/userButton';
import useCurrentApplicationModule from 'app/common/hooks/useCurrentApplicationModule';
import { APP_NETWORK } from 'app/common/ui/constants';
import styles from './index.css';

const NavActions = () => {
  const module = useCurrentApplicationModule();

  return (
    <div className={styles.actions}>
      <div className={styles.action}>
        {module !== APP_NETWORK && <UserButton />}
        {module === APP_NETWORK && <Settings />}
      </div>
    </div>
  );
};

export default NavActions;
