import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Mask from 'app/common/components/mask';

import isAppNavigationOpen from 'app/common/selectors/isAppNavigationOpen';
import isUserMenuVisible from 'app/common/selectors/appShell/isUserMenuVisible';
import isCatalogListActionsPopupVisible from 'app/display/catalog/list/common/selectors/isActionsPopupVisible';
import isCatalogSortByPopupOpen from 'app/display/catalog/list/common/selectors/isSortByPopupOpen';
import isCatalogAddEntryMenuOpen from 'app/display/catalog/list/common/selectors/isAddEntryMenuOpen';
import isCatalogSheetActionsPopupVisible from 'app/display/catalog/sheet/view/selectors/isActionsPopupVisible';
import isGuidelineAddEntryMenuOpen from 'app/display/guideline/common/selectors/isAddEntryMenuOpen';
import isGuidelineListActionsPopupVisible from 'app/display/guideline/list/common/selectors/isActionsPopupVisible';
import isGuidelineSortByPopupOpen from 'app/display/guideline/common/selectors/isSortByPopupOpen';
import isGuidelineSheetActionsPopupVisible from 'app/display/guideline/common/selectors/isActionsPopupVisible';
import isGuidelineOpenActionsPopupVisible from 'app/display/guideline/sheet/open/selectors/isActionsPopupVisible';
import isSurveyReportActionsPopupVisible from 'app/network/survey/report/selectors/actionsPopup/getVisibility';
import usePlatformMenu from 'app/common/components/platformMenu/index.hook';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import classNames from 'classnames';
import { APPSHELL_CONTENT_MASK } from 'app/common/constants/userflow';
import styles from './contentMask.css';

const useContentMask = () => {
  const isSmallScreen = useSmallScreen();
  const appNavigationIsOpen = useSelector(isAppNavigationOpen);
  const userMenuIsVisible = useSelector(isUserMenuVisible);
  const catalogListActionsPopupIsVisible = useSelector(isCatalogListActionsPopupVisible);
  const catalogSheetActionsPopupIsVisible = useSelector(isCatalogSheetActionsPopupVisible);
  const catalogSortByPopupIsOpen = useSelector(isCatalogSortByPopupOpen);
  const catalogAddEntryMenuIsOpen = useSelector(isCatalogAddEntryMenuOpen);
  const guidelineAddEntryMenuIsOpen = useSelector(isGuidelineAddEntryMenuOpen);
  const guidelineListActionsPopupIsVisible = useSelector(isGuidelineListActionsPopupVisible);
  const guidelineSortByPopupIsOpen = useSelector(isGuidelineSortByPopupOpen);
  const guidelineSheetActionsPopupIsVisible = useSelector(isGuidelineSheetActionsPopupVisible);
  const guidelineOpenActionsPopupIsVisible = useSelector(isGuidelineOpenActionsPopupVisible);
  const surveyReportActionsPopupIsVisible = useSelector(isSurveyReportActionsPopupVisible);

  const isActive =
    userMenuIsVisible ||
    catalogListActionsPopupIsVisible ||
    catalogSheetActionsPopupIsVisible ||
    catalogSortByPopupIsOpen ||
    catalogAddEntryMenuIsOpen ||
    guidelineAddEntryMenuIsOpen ||
    guidelineListActionsPopupIsVisible ||
    guidelineSortByPopupIsOpen ||
    guidelineSheetActionsPopupIsVisible ||
    guidelineOpenActionsPopupIsVisible ||
    surveyReportActionsPopupIsVisible;

  return { isMaskActive: isActive || (appNavigationIsOpen && !isSmallScreen) };
};

export default function ContentMask({ children }) {
  const { isMaskActive } = useContentMask();
  const { isFullScreen } = usePlatformMenu();
  const maskClassSet = classNames(styles.mask, { [styles.isFullScreen]: isFullScreen });
  return (
    <Mask className={maskClassSet} dim={isMaskActive} data-testid={APPSHELL_CONTENT_MASK}>
      {children}
    </Mask>
  );
}

ContentMask.propTypes = {
  children: PropTypes.node.isRequired,
};
