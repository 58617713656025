import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import InfoIcon from 'assets/icons/info.svg';
import Selectv5 from 'app/display/common/components/select';
import RequiredSymbol from 'app/common/components/requiredSymbol';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import { BodyText2, LegendText2 } from 'app/common/typography';

import styles from './select.css';

const Select = ({
  input,
  mainLabel,
  infoText,
  infoText1,
  selectedValue,
  descriptionText,
  children,
  disabled,
  selectStyle,
  labelClass,
  infoClass,
  wrapClass,
  errorClass,
  required,
  meta,
  dataTestId,
}) => (
  <div className={`${wrapClass}`} data-testid={dataTestId}>
    {mainLabel && (
      <BodyText2 className={classNames(styles.uppercase, styles.labelCenter, labelClass)}>{mainLabel}</BodyText2>
    )}
    <RequiredSymbol required={required} />
    <div className={classNames(infoClass)}>
      <Selectv5
        {...input}
        className={classNames(styles.select, selectStyle, {
          [styles.disabled]: meta?.submitting,
          [errorClass]: meta?.error,
        })}
        disabled={disabled || meta?.submitting}
      >
        {children}
      </Selectv5>
      {descriptionText && (
        <div className={styles.descriptionText}>
          <LegendText2>{descriptionText}</LegendText2>
        </div>
      )}
      {infoText && infoText1 && selectedValue && (
        <div className={styles.infoText}>
          <InfoIcon className={styles.infoIcon} />
          <div className={styles.row}>
            <LegendText2>{infoText}</LegendText2>
            <LegendText2>{infoText1}</LegendText2>
          </div>
        </div>
      )}
      {!!infoText && !infoText1 && (
        <div className={styles.infoText}>
          <InfoIcon className={styles.infoIcon} />
          <LegendText2>{infoText}</LegendText2>
        </div>
      )}
    </div>
  </div>
);

Select.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
  mainLabel: PropTypes.string,
  /**
   * Text to display below the <select> element. It will be preceded by the info
   * icon
   */
  infoText: PropTypes.string,
  infoText1: PropTypes.string,
  selectedValue: PropTypes.string,
  descriptionText: PropTypes.string,
  disabled: PropTypes.bool,
  selectStyle: PropTypes.string,
  labelClass: PropTypes.string,
  infoClass: PropTypes.string,
  wrapClass: PropTypes.string,
  errorClass: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  dataTestId: PropTypes.string,
};

Select.defaultProps = {
  mainLabel: null,
  infoText: null,
  infoText1: null,
  selectedValue: null,
  descriptionText: null,
  disabled: false,
  selectStyle: null,
  labelClass: null,
  infoClass: null,
  wrapClass: null,
  errorClass: null,
  required: false,
  dataTestId: null,
};

export default Select;
