import ItemState, { ItemStatus } from 'domain/item/state';
import { DeprecatedRelation } from 'lib/deprecated';
import { DisplayParametersPayloadType } from 'domain/item/display/displayParametersPayload';
import ItemFile, { ItemFileType } from 'domain/item/file';
import { ItemVariant } from 'domain/item/variant';
import { StatePayload } from 'domain/state/factory';
import { ItemType } from 'domain/item/type';
import FileCollection from 'domain/fileCollection';
import Model3d from 'domain/model3d';

export enum CapacityType {
  ML = 'ml',
  CL = 'cl',
  L = 'l',
  LBS = 'lbs',
  PIECES = 'pieces',
  OZ = 'oz',
  FLOZ = 'floz',
  KG = 'kg',
  G = 'g',
}

export type Capacity = { unit?: CapacityType; value?: number };
export type ItemMetadatas = {
  code2?: string | null;
  code3?: string | null;
  export?: boolean;
  name2?: string | null;
  capacity1?: Capacity | null;
  capacity2?: Capacity | null;
};

export enum ItemModel3dType {
  NONE = 'none',
  USER = 'user',
  AUTO = 'auto',
}

type ItemDefaultVariantIds = {
  hanging: string | null;
  laid: string | null;
  mannequin: string | null;
};

type ItemVariantsByShape = {
  hanging: ItemVariant[];
  laid: ItemVariant[];
  mannequin: ItemVariant[];
};

export type ItemVariants = {
  defaultVariantIds: ItemDefaultVariantIds;
  variantsByShape: ItemVariantsByShape;
};

export type ItemStatePayload = StatePayload & {
  archived: boolean;
  enabled: boolean;
  status: ItemStatus;
};

export type ItemStock = {
  type: string;
  value: number | null;
};

export default class Item {
  constructor(
    public readonly id: string,
    public readonly type: ItemType,
    public readonly brand: DeprecatedRelation,
    public readonly folder: DeprecatedRelation,
    public readonly name: string,
    public readonly code: string,
    public readonly description: string,
    public readonly idv4: number | null,
    public readonly metadatas: ItemMetadatas,
    public markets: DeprecatedRelation[],
    public files: FileCollection<ItemFile>,
    public readonly state: ItemState,
    public display: DisplayParametersPayloadType,
    public variants: ItemVariants,
    public stock: ItemStock,
    public readonly isLinkedToAnItem: boolean,
    public readonly hasLinkedItems: boolean,
    public readonly embeddedModel3ds: Model3d[],
    public linkedItems: Item[] = [],
    public readonly linkedToItems: Item[] = [],
    public quantity: number | undefined = undefined
  ) {
    // do nothing.
  }

  public hasTextureFile() {
    return typeof this.files.hasFileType === 'function' ? this.files.hasFileType(ItemFileType.TEXTURE) : false;
  }

  public getTextureFile(): ItemFile | undefined {
    return this.files.getFileByType(ItemFileType.TEXTURE);
  }

  public isModel3dTypeUser() {
    return this.display?.model3dType === ItemModel3dType.USER;
  }

  public getEmbeddedModel3d() {
    return this.embeddedModel3ds.find(model => model.id === this.display?.model3d?.id);
  }

  public getMarketIds(): string[] {
    return this.markets.map(market => market.id);
  }

  public getModel3dId() {
    return this.display?.model3d?.id;
  }
}
