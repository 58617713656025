import { SHOW_MODAL, HIDE_MODAL } from 'app/common/actions/modals/modalTypes';

export const initialState = {
  modal: null, // modal component
  props: null, // props send to the modal
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      if (!action.payload) {
        return initialState;
      }

      return {
        modal: action.payload.modal,
        props: action.payload.props,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}
