import React from 'react';
import { addToolbarButton, PluginProps } from 'yet-another-react-lightbox';
import SlideshowRotatePhotoIcon from 'app/common/components/slideshow/rotate/icon';

export const SLIDESHOW_ROTATE_IMAGE_PLUGIN_TESTID = 'slideShowPlugIn';
export default function RotateButton({ augment }: PluginProps) {
  augment(({ toolbar, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_ROTATE_IMAGE_PLUGIN_TESTID, <SlideshowRotatePhotoIcon />),
      ...restProps,
    };
  });
}
