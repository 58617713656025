import React, { ReactNode } from 'react';
import Action from 'app/common/components/popup/actions/action';
import NetworkPjaxLink, { NetworkPjaxLinkData } from 'app/network/common/components/pjaxLink';
import { resetDirectoryActionsPopup } from 'app/directory/user/sheet/common/actions';
import useAction from 'app/display/common/hooks/useAction';

type DirectoryActionProps = {
  icon: ReactNode;
  label: string;
  linkData: NetworkPjaxLinkData;
  dataTestId?: string;
};

export default function DirectoryAction({ linkData, label, icon, dataTestId }: DirectoryActionProps) {
  const onClick = useAction(resetDirectoryActionsPopup);
  return (
    <NetworkPjaxLink linkData={linkData} onClick={onClick} dataTestId={dataTestId}>
      <Action label={label} icon={icon} />
    </NetworkPjaxLink>
  );
}
