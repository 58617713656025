import { useDispatch } from 'react-redux';
import { toggleSlideshow } from 'app/common/actions/slideshow';

export default function useOpenSlideshow() {
  const dispatch = useDispatch();

  return function openSlideshow(photoId: string) {
    return dispatch(toggleSlideshow(photoId));
  };
}
