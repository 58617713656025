import React from 'react';
import i18next from 'i18next';
import Link from 'app/common/components/offCanvasPlatformMenu/links/link';
import CatalogIcon from 'assets/icons/catalog-grid.svg';
import { PATH_CATALOG_BROWSE } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useCurrentUserCatalogAccess from 'app/common/hooks/useCurrentUserCatalogAccess';

const CatalogLink = () => {
  const isActive = useLocationMatch(PATH_CATALOG_BROWSE, { exact: false });
  const { canAccess, discoveryPagesEnabled } = useCurrentUserCatalogAccess();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <Link to={PATH_CATALOG_BROWSE} Icon={CatalogIcon} isActive={isActive} isDisabled={!canAccess}>
      {i18next.t('display.submenu.catalog')}
    </Link>
  );
};

export default CatalogLink;
