import { useCallback, useEffect, useState } from 'react';
import useScrollEvent from 'lib/dom/hooks/useScrollEvent';
import isScrollAtTop from 'lib/dom/helpers/isScrollAtTop';

export default function useIsScrollAtTop(domElement) {
  const [isAtBottom, setIsAtBottom] = useState(isScrollAtTop(domElement));

  // Re-compute on DOM element change
  useEffect(() => {
    setIsAtBottom(isScrollAtTop(domElement));
  }, [domElement]);

  // Re-compute on scroll
  useScrollEvent(
    domElement,
    useCallback(() => {
      setIsAtBottom(isScrollAtTop(domElement));
    }, [domElement])
  );

  return isAtBottom;
}
