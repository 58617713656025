import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

const NavLinks = ({ className, children }) => {
  const classSet = classnames(styles.navLinks, className);
  return <div className={classSet}>{children}</div>;
};

NavLinks.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NavLinks.defaultProps = {
  className: null,
};

export default NavLinks;
