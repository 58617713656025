import React from 'react';

import { APP_NETWORK, APP_DISPLAY } from 'app/common/ui/constants';

import DisplayLink from 'app/common/components/legacyNavMenu/navLink/display';
import NetworkLink from 'app/common/components/legacyNavMenu/navLink/network';

import useCurrentApplicationModule from 'app/common/hooks/useCurrentApplicationModule';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import classnames from 'classnames';
import IconDisplay from '../icons/display';
import IconNetwork from '../icons/network';

import styles from './index.css';

/**
 * @example ./index.md
 */
export default function ActiveApp() {
  const isSmallScreen = useSmallScreen();
  const module = useCurrentApplicationModule();

  const activeAppClass = classnames(styles.activeApp, {
    [styles.onSmallScreen]: isSmallScreen,
  });

  if (module === APP_DISPLAY) {
    return (
      <DisplayLink className={activeAppClass}>
        <IconDisplay />
      </DisplayLink>
    );
  }

  if (module === APP_NETWORK) {
    return (
      <NetworkLink className={activeAppClass}>
        <IconNetwork />
      </NetworkLink>
    );
  }

  return null;
}
