import React from 'react';
import useShowModal from 'app/display/common/hooks/useShowModal';
import useCanDeleteSlideshowPhoto from 'app/common/hooks/slideshow/useCanDeleteSlideshowPhoto';
import SlideshowDeletePhotoModal from 'app/common/components/slideshow/deletePhoto/modal';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import Button from 'app/display/common/components/button';
import DeleteIcon from 'assets/icons/delete-grid.svg';
import { ComponentProps, useLightboxState } from 'yet-another-react-lightbox';
import styles from './icon.css';

export const SLIDESHOW_DELETE_PHOTO_ICON_TESTID = 'slideshowDeletePhotoIcon';

export default function SlideshowDeletePhotoIcon({ on }: ComponentProps) {
  const { currentSlide } = useLightboxState();
  const canDelete = useCanDeleteSlideshowPhoto();

  const handleDeleteSuccess = () => {
    on.deletePhoto(currentSlide?.id);
  };

  const openModal = useShowModal(SlideshowDeletePhotoModal, {
    handleDeleteSuccess,
    photo: currentSlide,
  });

  const handleClick = () => {
    openModal();
  };

  return (
    <div className={styles.icon}>
      <Button
        className="yarl__button"
        onClick={handleClick}
        isDisabled={!canDelete}
        dataTestId={SLIDESHOW_DELETE_PHOTO_ICON_TESTID}
      >
        <SlideshowButton Icon={DeleteIcon} />
      </Button>
    </div>
  );
}
