import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import isHttpUrl from 'is-http-url';

export const RedirectionWrapper = ({ Component, redirectTo, redirectDelay }) => {
  const [redirect, setRedirect] = useState(false);

  // initial implementation was with useEffect() hook
  // however, it is not testable with jest (useEffect is badly supported)
  // This implementation with useRef() is quite similar, at least functional, AND testable.
  const timeoutId = useRef();
  if (timeoutId.current) {
    clearTimeout(timeoutId.current);
  }

  timeoutId.current = setTimeout(() => setRedirect(redirectTo != null), redirectDelay * 1000);

  if (redirect) {
    if (!isHttpUrl(redirectTo)) {
      return <Redirect to={redirectTo} />;
    }
    window.location.replace(redirectTo);
  }

  return redirectDelay === 0 ? null : <Component />;
};

RedirectionWrapper.propTypes = {
  Component: PropTypes.elementType.isRequired,
  redirectTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  redirectDelay: PropTypes.number,
};

RedirectionWrapper.defaultProps = {
  redirectTo: null,
  redirectDelay: 2,
};

const DelayedRedirectRoute = ({ path, component, exact, strict, redirectTo, redirectDelay }) => {
  // We want to redirect only if route is matching, i.e. when component is really rendered.
  // This is why we must wrap component with RedirectionWrapper, which in charge of redirect if needed.

  return (
    <Route
      path={path}
      exact={exact}
      strict={strict}
      render={() => <RedirectionWrapper Component={component} redirectTo={redirectTo} redirectDelay={redirectDelay} />}
    />
  );
};

DelayedRedirectRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  redirectTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  redirectDelay: PropTypes.number,
};

DelayedRedirectRoute.defaultProps = {
  path: undefined,
  exact: undefined,
  strict: undefined,
  redirectTo: undefined,
  redirectDelay: 2,
};

export default DelayedRedirectRoute;
