import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import Modal from 'app/display/common/components/modals';
import Title from 'app/display/common/components/modals/processingConfirmModal/title';
import Buttons from 'app/display/common/components/modals/processingConfirmModal/buttons';
import styles from 'app/display/common/components/modals/processingConfirmModal/index.css';
import SubTitle from 'app/display/common/components/modals/processingConfirmModal/subtitle';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import useTimeout from 'app/display/common/hooks/useTimeout';
import { BodyText1 } from 'app/common/typography';

const ProcessingConfirmModal = ({
  onClose,
  onConfirm,
  onConfirmSuccess,
  onConfirmError,
  closeEnabled,
  confirmEnabled,
  confirmLabel,
  title,
  children,
  submitOnEnter,
  subTitle,
  contentClassName,
  isLoading,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isFadeOut, setIsFadOut] = useState(false);
  const { setCallback } = useTimeout();

  const handleClose = useCallback(() => {
    if (closeEnabled && !isProcessing && !isFadeOut) {
      setIsFadOut(true);
      setCallback(() => () => {
        onClose();
      });
    }
  }, [closeEnabled, isProcessing, isFadeOut, onClose, setCallback]);

  const handleConfirm = useCallback(async () => {
    if (confirmEnabled && !isProcessing) {
      setIsProcessing(true);
      try {
        const result = await onConfirm();
        handleClose();
        setIsProcessing(false);
        setCallback(() => () => {
          onConfirmSuccess(result);
        });
      } catch (exception) {
        assertIsNotCriticalHttpException(exception);
        setIsProcessing(false);
        handleClose();
        setCallback(() => () => {
          onConfirmError(exception);
        });
      }
    }
  }, [confirmEnabled, isProcessing, onConfirm, onConfirmSuccess, onConfirmError, setCallback, handleClose]);

  useEffect(() => {
    const handleKey = e => {
      if (e.key === 'Enter') {
        setCallback(() => () => {
          handleConfirm();
        });
      }
    };

    if (submitOnEnter) {
      window.addEventListener('keypress', handleKey);
    }

    return () => {
      window.removeEventListener('keypress', handleKey);
    };
  }, [confirmEnabled, handleConfirm, submitOnEnter, setCallback]);

  const contentClass = classNames(styles.label, {
    [styles.hasSubtitle]: subTitle,
    [contentClassName]: contentClassName,
    [styles.disabled]: isProcessing,
  });

  return (
    <Modal
      isLoading={isProcessing || isLoading}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isFadeOut={isFadeOut}
      headerLeft={<Title title={title} />}
      headerRight={
        <Buttons
          onClose={handleClose}
          onConfirm={handleConfirm}
          confirmEnabled={!isProcessing && confirmEnabled}
          confirmLabel={confirmLabel}
          closeEnabled={!isProcessing && closeEnabled}
        />
      }
    >
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <BodyText1 className={contentClass} disabled={isProcessing}>
        {children}
      </BodyText1>
    </Modal>
  );
};

ProcessingConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onConfirmSuccess: PropTypes.func.isRequired,
  onConfirmError: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  closeEnabled: PropTypes.bool,
  confirmEnabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  submitOnEnter: PropTypes.bool,
  subTitle: PropTypes.string,
  contentClassName: PropTypes.string,
  isLoading: PropTypes.bool,
};

ProcessingConfirmModal.defaultProps = {
  closeEnabled: true,
  confirmEnabled: true,
  confirmLabel: undefined,
  submitOnEnter: false,
  subTitle: null,
  contentClassName: null,
  isLoading: false,
};

export default ProcessingConfirmModal;
