export enum ItemDisplay {
  GRID = 'grid',
  LIST = 'list',
  LIST_2_COLUMNS = 'list2Columns',
}

export enum FixturePreviewSize {
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
  HIDDEN = 'hidden',
}
export enum FixturePosition {
  ABOVE = 'above',
  LEFT = 'left',
}

export enum DisplayOptions {
  DISPLAYED = 'displayed',
  HIDDEN = 'hidden',
}

export default class GuidelineDataSettings {
  // eslint-disable-next-line
  #showPath: boolean = false;

  // eslint-disable-next-line
  #itemDisplay: ItemDisplay = ItemDisplay.GRID;

  // eslint-disable-next-line
  #breakBeforeFirstFixture: boolean = true;

  // eslint-disable-next-line
  #displayGuidelineImage: DisplayOptions = DisplayOptions.HIDDEN;

  // eslint-disable-next-line
  #fixturePreviewSize: FixturePreviewSize = FixturePreviewSize.LARGE;

  // eslint-disable-next-line
  #fixturePosition: FixturePosition = FixturePosition.LEFT;

  // eslint-disable-next-line
  #showItems: boolean = true;

  showPath: boolean;

  showName: boolean;

  showName2: boolean;

  showCode: boolean;

  showCode2: boolean;

  showCode3: boolean;

  showMarketCode: boolean;

  showMarketCode2: boolean;

  itemDisplay: ItemDisplay;

  fixturePreviewSize: FixturePreviewSize;

  fixturePosition: FixturePosition;

  numberingOnGuideline: boolean;

  numberingOnBays: boolean;

  displayGuidelineImage: DisplayOptions;

  breakBeforeFirstFixture: boolean;

  breakAfterEveryFixture: boolean;

  displayLegend: boolean;

  showCapacity1: boolean;

  showCapacity2: boolean;

  showItems: boolean;

  showTextInBox: boolean;

  static isRequired: any;

  constructor(
    showName = true,
    showName2 = false,
    showCode = true,
    showCode2 = false,
    showCode3 = false,
    showMarketCode = false,
    showMarketCode2 = false,
    itemDisplay: ItemDisplay = ItemDisplay.GRID,
    showPath = false,
    fixturePreviewSize: FixturePreviewSize = FixturePreviewSize.NORMAL,
    fixturePosition: FixturePosition = FixturePosition.ABOVE,
    numberingOnGuideline = false,
    numberingOnBays = false,
    displayGuidelineImage: DisplayOptions = DisplayOptions.DISPLAYED,
    breakBeforeFirstFixture = true,
    breakAfterEveryFixture = true,
    displayLegend = true,
    showCapacity1 = false,
    showCapacity2 = false,
    showItems = true,
    showTextInBox = false
  ) {
    Object.defineProperties(this, {
      showPath: {
        enumerable: true,
        set(newValue: boolean) {
          this.#showPath = newValue && !this.isDisplayedAs(ItemDisplay.GRID);
        },
        get(): boolean {
          return this.#showPath;
        },
      },
      itemDisplay: {
        enumerable: true,
        set(newValue: ItemDisplay) {
          this.#itemDisplay = newValue;
          if (this.#itemDisplay === ItemDisplay.GRID) {
            this.showPath = false;
          }
        },
        get(): ItemDisplay {
          return this.#itemDisplay;
        },
      },
      breakBeforeFirstFixture: {
        enumerable: true,
        set(newValue: boolean) {
          this.#breakBeforeFirstFixture = newValue;
        },
        get(): boolean {
          return this.#breakBeforeFirstFixture;
        },
      },
      displayGuidelineImage: {
        enumerable: true,
        set(newValue: DisplayOptions) {
          this.#displayGuidelineImage = newValue;
          if (this.#displayGuidelineImage === DisplayOptions.HIDDEN) {
            this.breakBeforeFirstFixture = false;
          }
        },
        get(): DisplayOptions {
          return this.#displayGuidelineImage;
        },
      },
      fixturePreviewSize: {
        enumerable: true,
        set(newValue: FixturePreviewSize) {
          this.#fixturePreviewSize = newValue;
          if (
            this.#fixturePreviewSize === FixturePreviewSize.LARGE &&
            this.#itemDisplay === ItemDisplay.LIST_2_COLUMNS &&
            this.#fixturePosition === FixturePosition.LEFT
          ) {
            this.itemDisplay = ItemDisplay.LIST;
          }
        },
        get(): FixturePreviewSize {
          return this.#fixturePreviewSize;
        },
      },
      fixturePosition: {
        enumerable: true,
        set(newValue: FixturePosition) {
          this.#fixturePosition = newValue;
          if (
            this.#fixturePosition === FixturePosition.LEFT &&
            this.#itemDisplay === ItemDisplay.LIST_2_COLUMNS &&
            this.#fixturePreviewSize === FixturePreviewSize.LARGE
          ) {
            this.itemDisplay = ItemDisplay.LIST;
          }
        },
        get(): FixturePosition {
          return this.#fixturePosition;
        },
      },
      showItems: {
        enumerable: true,
        set(newValue: boolean) {
          this.#showItems = newValue;
          if (!newValue) {
            this.itemDisplay = ItemDisplay.GRID;
          }
        },
        get(): boolean {
          return this.#showItems;
        },
      },
    });

    this.showName = showName;
    this.showName2 = showName2;
    this.showCode = showCode;
    this.showCode2 = showCode2;
    this.showCode3 = showCode3;
    this.showMarketCode = showMarketCode;
    this.showMarketCode2 = showMarketCode2;
    this.fixturePreviewSize = fixturePreviewSize;
    this.fixturePosition = fixturePosition;
    this.itemDisplay = itemDisplay;
    this.showPath = showPath;
    this.numberingOnGuideline = numberingOnGuideline;
    this.numberingOnBays = numberingOnBays;
    this.displayGuidelineImage = displayGuidelineImage;
    this.breakBeforeFirstFixture = breakBeforeFirstFixture;
    this.breakAfterEveryFixture = breakAfterEveryFixture;
    this.displayLegend = displayLegend;
    this.showCapacity1 = showCapacity1;
    this.showCapacity2 = showCapacity2;
    this.showItems = showItems;
    this.showTextInBox = showTextInBox;
  }

  isDisplayedAs(mode: ItemDisplay): boolean {
    return this.itemDisplay === mode;
  }

  isGuidelineImageHidden(): boolean {
    return this.displayGuidelineImage === DisplayOptions.HIDDEN;
  }

  shouldDisplayNumberOnItems(): boolean {
    return this.numberingOnGuideline || this.numberingOnBays;
  }

  isFixtureLeft(): boolean {
    return this.fixturePosition === FixturePosition.LEFT;
  }

  isPreviewSmall(): boolean {
    return this.fixturePreviewSize === FixturePreviewSize.SMALL;
  }

  isPreviewNormal(): boolean {
    return this.fixturePreviewSize === FixturePreviewSize.NORMAL;
  }

  isPreviewLarge(): boolean {
    return this.fixturePreviewSize === FixturePreviewSize.LARGE;
  }

  isPreviewHidden(): boolean {
    return this.fixturePreviewSize === FixturePreviewSize.HIDDEN;
  }
}
