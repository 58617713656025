import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserNotificationFilterCategory } from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';
import { LegendText2 } from 'app/common/typography';
import classNames from 'classnames';
import styles from 'app/display/common/components/modals/userNotification/tab.css';

export default function UserNotificationTab({
  selectedTab,
  currentTab,
  onChange,
}: {
  selectedTab: UserNotificationFilterCategory;
  currentTab: UserNotificationFilterCategory;
  onChange: (el: UserNotificationFilterCategory) => void;
}) {
  const { t } = useTranslation();

  return (
    <div onClick={() => onChange(currentTab)}>
      <LegendText2 className={classNames(styles.tab, { [styles.selected]: selectedTab === currentTab })}>
        {t(`display.modal.notifications.tab.${currentTab}`)}
      </LegendText2>
    </div>
  );
}
