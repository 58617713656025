import React, { forwardRef, ReactNode } from 'react';
import useRefCallback from 'lib/hooks/useRefCallback';
import useInfiniteScroll from 'app/common/infiniteScroller/hooks/useInfiniteScroll';
import noop from 'lib/helpers/noop';
import styles from './index.css';

export type InfiniteScrollerProps = {
  onBottomReached?: () => void;
  pixelsOffset?: number;
  children: ReactNode;
  dataTestId?: string;
};

/**
 * A scroller with a callback to load more content when bottom reached (infinite scrolling).
 *
 * Optionally you can provide a ref that will be bound to the scroller, for example to get
 * the scroll state (e.g. offset) or to manipulate the scroll (e.g. scroll up/down)
 *
 * @example ./index.md
 */
const InfiniteScroller = forwardRef(function InfiniteScrollerWithRef(
  { children, onBottomReached = noop, pixelsOffset = 0, dataTestId }: InfiniteScrollerProps,
  ref
) {
  const { setRef, domNode } = useRefCallback(ref);
  useInfiniteScroll(domNode, onBottomReached, pixelsOffset);

  return (
    <div className={styles.scroller} ref={setRef} data-testid={dataTestId}>
      {children}
    </div>
  );
});

export default InfiniteScroller;
