import React from 'react';
import { Field } from 'redux-form';
import CheckboxLabel from 'app/display/common/components/checkboxlabel';
import { ALT_COLOR_PALETTE, BodyText1, HeadText5 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './index.css';

export default function NotificationsSettingRow({ name }: { name: string }) {
  const { t } = useTranslation();
  return (
    <Field component={CheckboxLabel} id={name} name={name} className={styles.settingRow} labelStyles={styles.label}>
      <HeadText5>{t(`directory.userSheet.notificationsTab.setting.${name}.label`)}</HeadText5>
      <BodyText1 className={styles.subText} palette={ALT_COLOR_PALETTE}>
        {t(`directory.userSheet.notificationsTab.setting.${name}.infoText`)}
      </BodyText1>
    </Field>
  );
}
