import React from 'react';
import Tooltip from 'app/common/components/tooltip';
import { BodyText1 } from 'app/common/typography';
import useOpenSlideshow from 'app/network/survey/report/hooks/useOpenSlideshow';
import styles from './photoThumbnail.css';

type PhotoThumbnailImageProps = {
  url: string;
  uuid: string;
  idv4: string;
  tooltipText: string;
  creationDate: string;
};

export default function PhotoThumbnailImage({ url, uuid, tooltipText, creationDate }: PhotoThumbnailImageProps) {
  const handleClick = useOpenSlideshow();
  const imageTooltipHtml = (
    <BodyText1>
      <div className={styles.tooltip}>{tooltipText}</div>
      <div className={styles.tooltip}>{creationDate}</div>
    </BodyText1>
  );

  return (
    <Tooltip arrow={true} html={imageTooltipHtml}>
      <div className={styles.image} style={{ backgroundImage: `url(${url})` }} onClick={() => handleClick(uuid)} />
    </Tooltip>
  );
}
