import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HTTPClientError from 'lib/errors/http/clientError';
import HTTPBadRequestError from 'lib/errors/http/badRequestError';
import HTTPUnauthorizedError from 'lib/errors/http/unauthorizedError';
import HTTPForbiddenError from 'lib/errors/http/forbiddenError';
import HTTPNotFoundError from 'lib/errors/http/notFoundError';
import HTTPNotAcceptableError from 'lib/errors/http/notAcceptableError';
import HTTPRequestEntityTooLargeError from 'lib/errors/http/requestEntityTooLargeError';
import HTTPUnsupportedMediaTypeError from 'lib/errors/http/unsupportedMediaTypeError';
import HTTPUnprocessableEntityError from 'lib/errors/http/unprocessableEntityError';
import HTTPServerError from 'lib/errors/http/serverError';
import { hideModal } from 'app/common/actions/modals';
import UnexpectedErrorModal from 'app/common/containers/modals/errors/unexpected';
import UnauthorizedModal from 'app/common/components/modals/errors/unauthorized';
import ForbiddenModal from 'app/common/components/modals/errors/forbidden';
import EntityTooLargeModal from 'app/common/components/modals/errors/entityTooLarge';
import NotFoundModal from 'app/common/components/modals/errors/notFound';
import UnprocessableEntityModal from 'app/common/components/modals/errors/unprocessableEntity';

class ModalErrorContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    baseUrl: PropTypes.string.isRequired,
    error: PropTypes.instanceOf(Error),
  };

  static defaultProps = {
    error: null,
  };

  handleCloseModal = () => {
    this.props.dispatch(hideModal());
  };

  render() {
    console.log('ModalErrorContainer', this.props);

    switch (true) {
      case this.props.error instanceof HTTPUnauthorizedError:
        return <UnauthorizedModal baseUrl={this.props.baseUrl} onHideModal={this.handleCloseModal} />;
      case this.props.error instanceof HTTPForbiddenError:
        return <ForbiddenModal onHideModal={this.handleCloseModal} />;
      case this.props.error instanceof HTTPNotFoundError:
        return <NotFoundModal error={this.props.error} onHideModal={this.handleCloseModal} />;
      case this.props.error instanceof HTTPRequestEntityTooLargeError:
        return <EntityTooLargeModal error={this.props.error} onHideModal={this.handleCloseModal} />;
      case this.props.error instanceof HTTPUnprocessableEntityError:
        return <UnprocessableEntityModal onHideModal={this.handleCloseModal} error={this.props.error} />;
      case this.props.error instanceof HTTPUnsupportedMediaTypeError:
      case this.props.error instanceof HTTPNotAcceptableError:
      case this.props.error instanceof HTTPBadRequestError:
      case this.props.error instanceof HTTPServerError:
      case this.props.error instanceof HTTPClientError:
      default:
        return <UnexpectedErrorModal />;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { iwdConfig } = state;
  return {
    error: ownProps.error,
    baseUrl: iwdConfig.config.instance.baseUrl,
  };
};

export { ModalErrorContainer };
export default connect(mapStateToProps)(ModalErrorContainer);
