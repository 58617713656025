export const ADD_GUIDELINES = 'guidelines.add';
export const REMOVE_GUIDELINES = 'guidelines.remove';
export const UPDATE_GUIDELINES = 'guidelines.update';
export const CLEAR_GUIDELINES = 'guidelines.clear';

export const FETCH_CAMPAIGNS_START = 'campaigns.fetch.start';
export const FETCH_CAMPAIGNS_SUCCESS = 'campaigns.fetch.success';
export const FETCH_CAMPAIGNS_ERROR = 'campaigns.fetch.error';
export const FETCH_CAMPAIGN_START = 'campaign.fetch.start';
export const FETCH_CAMPAIGN_SUCCESS = 'campaign.fetch.success';
export const FETCH_CAMPAIGN_ERROR = 'campaign.fetch.error';
export const ADD_CAMPAIGNS = 'campaigns.add';
export const REMOVE_CAMPAIGNS = 'campaigns.remove';
export const UPDATE_CAMPAIGN = 'campaign.update';

export const FETCH_GUIDELINE_START = 'guideline.fetch.start';
export const FETCH_GUIDELINE_SUCCESS = 'guideline.fetch.success';
export const FETCH_GUIDELINE_ERROR = 'guideline.fetch.error';

export const FETCH_GUIDELINE_PERSONAL_FOLDER_START = 'guideline.myfolder.fetch.start';
export const FETCH_GUIDELINE_PERSONAL_FOLDER_SUCCESS = 'guideline.myfolder.fetch.success';
export const FETCH_GUIDELINE_PERSONAL_FOLDER_ERROR = 'guideline.myfolder.fetch.error';

export const ACTIVATE_GUIDELINES_START = 'guideline.activate.start';
export const ACTIVATE_GUIDELINES_SUCCESS = 'guideline.activate.success';
export const ACTIVATE_GUIDELINES_ERROR = 'guideline.activate.error';

export const DEACTIVATE_GUIDELINES_START = 'guideline.deactivate.start';
export const DEACTIVATE_GUIDELINES_SUCCESS = 'guideline.deactivate.success';
export const DEACTIVATE_GUIDELINES_ERROR = 'guideline.deactivate.error';

export const ARCHIVE_GUIDELINES_START = 'guideline.archive.start';
export const ARCHIVE_GUIDELINES_SUCCESS = 'guideline.archive.success';
export const ARCHIVE_GUIDELINES_ERROR = 'guideline.archive.error';

export const UNARCHIVE_GUIDELINES_START = 'guideline.unarchive.start';
export const UNARCHIVE_GUIDELINES_SUCCESS = 'guideline.unarchive.success';
export const UNARCHIVE_GUIDELINES_ERROR = 'guideline.unarchive.error';

export const DUPLICATE_GUIDELINE_START = 'guideline.duplicate.start';
export const DUPLICATE_GUIDELINE_SUCCESS = 'guideline.duplicate.success';
export const DUPLICATE_GUIDELINE_ERROR = 'guideline.duplicate.error';
export const MOVE_PERSONAL_FOLDER = 'guideline.move.personalFolder';
export const MOVE_GLOBAL_FOLDER = 'guideline.move.globalFolder';

export const FETCH_GLOBAL_FOLDERS_START = 'fetch.global.folders.start';
export const FETCH_GLOBAL_FOLDERS_SUCCESS = 'fetch.global.folders.success';
export const FETCH_GLOBAL_FOLDERS_ERROR = 'fetch.global.folders.error';

export const TOGGLE_ACTIONS_POPUP_VISIBILITY = 'guideline.sheet.toggle.actions.popup.visibility';
