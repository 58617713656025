import { createReducer } from '@reduxjs/toolkit';
import {
  toggleDirectoryActionsPopup,
  resetDirectoryActionsPopup,
  openDirectoryActionsPopup,
} from 'app/directory/user/sheet/common/actions';

export const initialState = {
  visible: false,
};

const directoryUserSheetActionsPopupReducer = createReducer(initialState, builder => {
  builder
    .addCase(resetDirectoryActionsPopup, () => {
      return initialState;
    })
    .addCase(toggleDirectoryActionsPopup, (state, action) => {
      state.visible = action.payload != null ? action.payload : !state.visible;
    })
    .addCase(openDirectoryActionsPopup, state => {
      state.visible = true;
    });
});

export default directoryUserSheetActionsPopupReducer;
