import React, { useCallback } from 'react';
import { FileLoader, FileLoaderEvent } from '@iwd/iwd-fileloader';
import IconAdd from 'assets/icons/add.svg';
import { LegendText2, ALT_COLOR_PALETTE } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import PhotoUploaderModal from 'app/network/common/components/photoUploader/modal';
import useAction from 'app/display/common/hooks/useAction';
import { showModal } from 'app/common/actions/modals';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import prepareFilesForUpload from 'app/network/common/helpers/prepareFilesForUpload';
import populateFileDataUrls from 'app/network/common/helpers/populateFileDataUrls';
import styles from './index.css';

type PhotoUploaderProps = {
  folderId: string | null;
  folderName: string | null;
};

export default function PhotoUploader({ folderId = null, folderName = null }: PhotoUploaderProps) {
  const { t } = useTranslation();
  const openModal = useAction(showModal);
  const uploadPhotos = useLegacyEvent('UPLOAD_PHOTOS');

  const onSelect = useCallback(
    (event: FileLoaderEvent) => {
      if (event.files.length > 1) {
        return openModal(PhotoUploaderModal, {
          files: populateFileDataUrls(event.files),
          folderId,
          folderName,
          onSubmit: uploadPhotos,
        });
      }

      return uploadPhotos({ files: prepareFilesForUpload(event.files), folderId });
    },
    [folderId, folderName, openModal, uploadPhotos]
  );

  return (
    <FileLoader disabled={false} multiple={true} onSelect={onSelect} className={styles.container} accept="image/*">
      <div className={styles.innerContent}>
        <IconAdd className={styles.iconAdd} />
        <LegendText2 palette={ALT_COLOR_PALETTE} className={styles.infoText}>
          {t('display.import.fileLoader.content.label')}
        </LegendText2>
      </div>
    </FileLoader>
  );
}
