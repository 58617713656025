import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { HeadText5, CONTRAST_COLOR_PALETTE, TextHoverArea, ALT_COLOR_PALETTE } from 'app/common/typography';
import Link from 'app/common/components/link';

import { COLOR_SECONDARY, COLOR_PRIMARY } from 'app/common/style/colors';
import styles from './link.css';

const OffCanvasPlatformMenuLink = ({ isActive, to, href, children, Icon, isDisabled }) => {
  const palette = isDisabled ? ALT_COLOR_PALETTE : CONTRAST_COLOR_PALETTE;
  return (
    <Link to={to} href={href} className={classNames(styles.link, styles.alignCenter)}>
      <TextHoverArea
        className={classNames(styles.hoverArea, styles.alignCenter, {
          [styles.isActive]: isActive,
          [styles.isDisabled]: isDisabled,
        })}
        palette={palette}
      >
        <HeadText5 className={styles.alignCenter} palette={palette} on={isActive ? COLOR_SECONDARY : COLOR_PRIMARY}>
          <Icon className={styles.icon} />
          <span className={styles.label}>{children}</span>
        </HeadText5>
      </TextHoverArea>
    </Link>
  );
};

OffCanvasPlatformMenuLink.propTypes = {
  isActive: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  Icon: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

OffCanvasPlatformMenuLink.defaultProps = {
  isActive: false,
  to: null,
  href: null,
  isDisabled: false,
};

export default OffCanvasPlatformMenuLink;
