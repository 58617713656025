import { reducer as formReducer } from 'redux-form';
import marketsSelectionsFormReducerPlugin from 'app/common/form/reducers/plugins/marketsSelection';
import itemFacingErrorFormReducerPlugin from 'app/common/form/reducers/plugins/itemFacingError';
import { CREATE_ITEM_FORM_NAME, EDIT_ITEM_FORM_NAME } from 'app/display/catalog/sheet/common/forms/constants';
import { FORM_GUIDELINE_CREATE, FORM_GUIDELINE_EDIT } from 'app/display/guideline/common/constants/formNames';
import { FORM_CAMPAIGN_CREATE, FORM_CAMPAIGN_EDIT } from 'app/display/guideline/campaigns/common/constants/formNames';

function combineFormReducerPlugins(plugins) {
  return (state, action) => {
    return plugins.reduce((previousState, plugin) => plugin(previousState, action), state);
  };
}

// We can define custom reducer plugins to add additional behaviour
// to specific forms. We will define these in various parts of the app and
// combine them here. We must return a single object here!!
// For details see: https://redux-form.com/8.3.0/docs/api/reducerplugin.md/
const formReducerPlugins = {
  [EDIT_ITEM_FORM_NAME]: combineFormReducerPlugins([
    marketsSelectionsFormReducerPlugin,
    itemFacingErrorFormReducerPlugin,
  ]),
  [CREATE_ITEM_FORM_NAME]: combineFormReducerPlugins([
    marketsSelectionsFormReducerPlugin,
    itemFacingErrorFormReducerPlugin,
  ]),
  [FORM_GUIDELINE_CREATE]: marketsSelectionsFormReducerPlugin,
  [FORM_GUIDELINE_EDIT]: marketsSelectionsFormReducerPlugin,
  [FORM_CAMPAIGN_CREATE]: marketsSelectionsFormReducerPlugin,
  [FORM_CAMPAIGN_EDIT]: marketsSelectionsFormReducerPlugin,
};

export default formReducer.plugin(formReducerPlugins);
