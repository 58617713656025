import React, { useEffect, useState } from 'react';
import { RenderSlideProps } from 'yet-another-react-lightbox';
import { useSelector } from 'react-redux';
import useImageRefLoader from 'app/common/hooks/useImageRefLoader';
import getIsDrawingVisible from 'app/common/selectors/slideshow/getIsDrawingVisible';
import Drawing from 'app/common/components/slideshow/drawing/drawingSvg';
import UnavailableImage from 'assets/images/failed-image.svg';
import classNames from 'classnames';
import styles from './index.css';

const CustomSlide = ({ zoom, slide }: RenderSlideProps) => {
  const isVisible = useSelector(getIsDrawingVisible);
  const { isLoading, isError, imageRef } = useImageRefLoader(`${slide.src}?${slide.state.updated.date}`);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = imageRef.current;
    if (!img) return undefined;

    const updateSize = () => {
      setImageSize({
        width: img.clientWidth,
        height: img.clientHeight,
      });
    };

    const observer = new ResizeObserver(updateSize);
    observer.observe(img);

    // Initial size update
    updateSize();
    return () => {
      observer.disconnect();
    };
  }, [imageRef]);

  return (
    <div className={styles.customImage}>
      {isError ? <UnavailableImage className={classNames(styles.unavailableImage, styles.placeholder)} /> : null}
      <img
        ref={imageRef}
        src={`${slide.src}?${slide.state.updated.date}`}
        alt={`slideshow-${slide.type}`}
        draggable={false}
        className={classNames(styles.image, {
          [styles.loaded]: !isLoading && !isError,
        })}
      />
      {isVisible && !isLoading && !isError && zoom && (
        <Drawing width={imageSize.width} height={imageSize.height} zoom={zoom} />
      )}
    </div>
  );
};

export default CustomSlide;
