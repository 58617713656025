import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import isBugsnagEnabled from 'lib/bugsnag/helpers/isBugsnagEnabled';
import pingBugsnag from 'lib/bugsnag/helpers/pingBugsnag';
import notifyError from 'app/display/common/helpers/notifications/notifyError';

const NOTIFICATION_DURATION = 10000;
const HELP_LINK = 'https://support.iwd.io/hc/en-us/articles/4402363076754';

export default function useAdblockerNotification() {
  useEffect(() => {
    if (isBugsnagEnabled()) {
      pingBugsnag().catch(() => {
        notifyError(
          <Trans i18nKey="app.disableAdBlocker">
            Text
            <a href={HELP_LINK} rel="noopener noreferrer" target="_blank">
              link
            </a>
          </Trans>,
          {
            autoClose: NOTIFICATION_DURATION,
          }
        );
      });
    }
  }, []);
}
