import React from 'react';
import PropTypes from 'prop-types';

import { COLOR_PRIMARY, COLORS_ON } from 'app/common/style/colors';

import colorPalettes from 'app/common/typography/colors/palettes';
import { DEFAULT_COLOR_PALETTE } from 'app/common/typography/colors/palettes/default';
import TextHoverArea from './hoverArea';
import StaticText from './static';
import { FONTS } from '../fonts';
import { SIZES } from '../sizes';

export default function InteractiveText({ font, size, on, className, children, disabled, palette }) {
  return (
    <TextHoverArea on={on} palette={palette}>
      <StaticText font={font} size={size} on={on} disabled={disabled} palette={palette} className={className}>
        {children}
      </StaticText>
    </TextHoverArea>
  );
}

InteractiveText.propTypes = {
  font: PropTypes.oneOf(FONTS).isRequired,
  size: PropTypes.oneOf(SIZES).isRequired,
  on: PropTypes.oneOf(COLORS_ON),
  palette: PropTypes.oneOf(Object.keys(colorPalettes)),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

InteractiveText.defaultProps = {
  palette: DEFAULT_COLOR_PALETTE,
  on: COLOR_PRIMARY,
  className: null,
  disabled: false,
};
