import useLicenseProjects from 'app/directory/user/sheet/common/hooks/useLicenseProjects';
import first from 'lodash/first';
import { USER_SHEET_LICENSE_TAB_FORM_INITIAL_VALUES } from 'app/directory/user/sheet/common/constants';

export default function useCreateUserFormInitialValues() {
  const { projects, isLoading } = useLicenseProjects();

  if (isLoading) {
    return USER_SHEET_LICENSE_TAB_FORM_INITIAL_VALUES;
  }

  return {
    ...USER_SHEET_LICENSE_TAB_FORM_INITIAL_VALUES,
    'license-projectId': projects.length === 1 ? first(projects)?.projectId : null,
  };
}
