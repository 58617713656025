import parseLinks from 'parse-link-header';

/**
 * Return the URL of the link "next" from the HTTP header
 * @param headers HTTP headers
 * @returns {string|null}
 */
const getNextLinkFromHeaders = headers => {
  const links = parseLinks(headers.link);
  return links.next && links.next.url ? links.next.url : null;
};

export default getNextLinkFromHeaders;
