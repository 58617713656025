import React from 'react';
import { change, Field } from 'redux-form';
import Select from 'app/common/components/forms/select';
import ProjectFieldOptions from 'app/directory/user/sheet/common/components/form/license/project/options';
import styles from 'app/directory/user/sheet/common/components/form/license/index.css';
import useLicenseProjects from 'app/directory/user/sheet/common/hooks/useLicenseProjects';
import useAction from 'app/display/common/hooks/useAction';
import useReduxForm from 'app/display/common/hooks/useReduxForm';
import { useTranslation } from 'react-i18next';
import getFormFieldValue from 'app/display/settings/sheet/common/selectors/getFormFieldValue';
import useParamSelector from 'app/display/common/hooks/useParamSelector';

export const PROJECT_FIELD_SELECT_TESTID = 'projectFieldSelect';

export default function ProjectField() {
  const { t } = useTranslation();
  const { projects } = useLicenseProjects();
  const { form: formName } = useReduxForm();
  const changeFieldValue = useAction(change);
  const projectId = useParamSelector(getFormFieldValue, formName, 'license-projectId');
  const selectedProject = projects.find(project => project.projectId === projectId);
  const renewalDate = selectedProject?.renewalDate;

  const onChange = () => {
    changeFieldValue(formName, 'license-serviceCode', null);
    changeFieldValue(formName, 'executeRole', 'norole');
    changeFieldValue(formName, 'createRole', 'norole');
    changeFieldValue(formName, 'has2d', false);
    changeFieldValue(formName, 'has3d', false);
  };

  return (
    <>
      <Field
        id="license-projectId"
        name="license-projectId"
        component={Select}
        mainLabel={t('directory.userSheet.licenseTab.project.label')}
        wrapClass={styles.fieldWrapper}
        labelClass={styles.fieldLabel}
        selectStyle={styles.fieldInput}
        errorClass={styles.selectError}
        dataTestId={PROJECT_FIELD_SELECT_TESTID}
        onChange={onChange}
        infoText={`${t('directory.userSheet.licenseTab.renewalDate', { renewalDate: renewalDate || 'N/A' })}`}
        infoText1={`${t('directory.userSheet.licenseTab.projectId')} ${projectId}`}
        selectedValue={projectId}
      >
        <ProjectFieldOptions />
      </Field>
    </>
  );
}
