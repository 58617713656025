import React, { useState } from 'react';
import useAction from 'app/display/common/hooks/useAction';
import useUserNotificationsEnabled from 'app/display/common/hooks/modals/userNotifications/useUserNotificationsEnabled';
import noop from 'lib/helpers/noop';
import { hideModal } from 'app/common/actions/modals';
import PopOverModal from 'app/display/common/components/modals/PopOverModal';
import NotificationHeaderLeft from 'app/display/common/components/modals/userNotification/headerLeft';
import NotificationHeaderRight from 'app/display/common/components/modals/userNotification/headerRight';
import UserNotificationTabs from 'app/display/common/components/modals/userNotification/tabs';
import UserNotificationRows from 'app/display/common/components/modals/userNotification/content';
import { UserNotificationFilterCategory } from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';

export default function UserNotificationComponent() {
  const onClose = useAction(hideModal);
  const [selectedTab, setSelectedTab] = useState<UserNotificationFilterCategory>(UserNotificationFilterCategory.ALL);
  const { notificationsByCategory, isLoading, isError } = useUserNotificationsEnabled();
  const onClick = noop;
  const isFadeOut = false;

  return (
    <PopOverModal
      onClose={onClose}
      onClick={onClick}
      isLoading={isLoading}
      isFadeOut={isFadeOut}
      headerLeft={<NotificationHeaderLeft count={notificationsByCategory.ALL.unreadCount} />}
      headerRight={
        <NotificationHeaderRight
          selectedTab={selectedTab}
          hasUnRead={notificationsByCategory[selectedTab].unreadCount > 0}
        />
      }
      width={400}
      positionRight={76}
      positionTop={54}
    >
      <>
        <UserNotificationTabs
          selectedTab={selectedTab}
          onChange={setSelectedTab}
          notificationsByCategory={notificationsByCategory}
        />
        <UserNotificationRows
          selectedTab={selectedTab}
          notifications={notificationsByCategory[selectedTab].notifications}
          isLoading={isLoading}
          isError={isError}
        />
      </>
    </PopOverModal>
  );
}
