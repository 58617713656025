import React from 'react';
import classNames from 'classnames';
import styles from './index.css';

export default function SlideshowButtonSeparator({
  isLeft = false,
  className,
}: {
  isLeft?: boolean;
  className?: string;
}) {
  return (
    <div
      className={classNames(styles.separator, className, {
        [styles.left]: isLeft,
      })}
    />
  );
}
