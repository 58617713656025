import { JwtTokenPayload } from 'domain/user/jwtTokenPayload';

/**
 * Decode a JWT token payload.
 * https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript/38552294
 * A JWT token contains 3 parts separated by a period.
 * - The header
 * - The payload
 * - The signature
 * We only need the payload content.
 *
 * @param {String} token - The Base64-URL string representation of the JWT token.
 * @returns {(Object|null)} The JWT token decoded.
 */

export default function decodeJwtTokenPayload(token: string): JwtTokenPayload | null {
  try {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid JWT token');
    }
    // We only need the payload.
    // Replace "-" and "_" characters because they are special characters for a Base64-URL string.
    // https://en.wikipedia.org/wiki/Base64#URL_applications
    const base64PayloadUrl = parts[1].replace(/-/g, '+').replace(/_/g, '/');
    // IE 11 and modern browsers support atob, we don't need a polyfill
    return JSON.parse(window.atob(base64PayloadUrl));
  } catch (e) {
    console.error(e);
    return null;
  }
}
