import { useTranslation } from 'react-i18next';
import useUser from 'app/common/hooks/useUser';

export default function useValidateEditUserSheetForm(userId: string) {
  const { t } = useTranslation();
  const { user } = useUser(userId);

  return function getErrors(values: Record<any, any>) {
    const errors: Record<any, any> = {};

    if (values['license-projectId']) {
      if (!values['license-serviceCode']) {
        errors['license-serviceCode'] = t('directory.userSheet.licenseTab.license.error');
      }

      if (values.executeRole === 'norole' && values.createRole === 'norole' && values['license-serviceCode']) {
        errors.createRole = t('directory.userSheet.licenseTab.role.error');
        errors.executeRole = t('directory.userSheet.licenseTab.role.error');
      }
    } else if (!values['license-projectId'] && user?.state.enabled) {
      errors['license-projectId'] = t('directory.userSheet.licenseTab.project.error');
    }

    return errors;
  };
}
