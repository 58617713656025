import Cookies from 'js-cookie';
import InvalidLoginCookieException from 'lib/errors/invalidLoginCookieException';

function assertLoginPayloadIsValid(payload) {
  if (typeof payload !== 'object') {
    throw new InvalidLoginCookieException(`Invalid payload, object expected, got ${typeof payload}.`);
  }
  if (typeof payload.token !== 'string') {
    throw new InvalidLoginCookieException(`Invalid payload, string expected for token, got ${typeof payload.token}.`);
  }
  if (typeof payload.baseUrl !== 'string') {
    throw new InvalidLoginCookieException(
      `Invalid payload, string expected for baseUrl, got ${typeof payload.baseUrl}.`
    );
  }
  if (!payload.token) {
    throw new InvalidLoginCookieException('Invalid payload, token must not be empty.');
  }
  if (!payload.baseUrl) {
    throw new InvalidLoginCookieException('Invalid payload, baseUrl must not be empty.');
  }
}

export default function getLoginPayloadFromCookie() {
  let payload = Cookies.get('iwd-login');

  if (!payload) {
    return null;
  }

  try {
    payload = JSON.parse(payload);
  } catch (exception) {
    throw new InvalidLoginCookieException(exception.message);
  }

  assertLoginPayloadIsValid(payload);

  return payload;
}
