import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import styles from 'app/common/components/spinner/index.css';

/**
 * @example ./index.md
 */
export const SPINNER_TESTID = 'spinner';

class Spinner extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    const className = classNames(styles.loadingindicator, this.props.className);
    return (
      <div className={className} data-testid={SPINNER_TESTID}>
        <span className={styles.loadingindicatorSpinner} />
      </div>
    );
  }
}

export default Spinner;
