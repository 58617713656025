import React from 'react';
import { useSelector } from 'react-redux';

import ImportLink from 'app/common/components/legacyNavMenu/menu/import/links/import';
import hasCurrentUserDisplayAccess from 'app/common/user/selectors/hasCurrentUserDisplayAccess';
import NavLinks from 'app/common/components/legacyNavMenu/navLinks';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import { PATH_IMPORT_FILES } from 'config/routePaths';
import useSmallScreen from 'app/common/hooks/useSmallScreen';

export default function NavImport() {
  const isSmallScreen = useSmallScreen();
  const hasDisplayAccess = useSelector(hasCurrentUserDisplayAccess);
  const matchImportRoute = useLocationMatch(PATH_IMPORT_FILES, {
    exact: false,
  });

  if (!hasDisplayAccess || !matchImportRoute || isSmallScreen) {
    return null;
  }

  return (
    <NavLinks>
      <ImportLink />
    </NavLinks>
  );
}
