import React from 'react';
import { addToolbarButton, ComponentProps, createModule, PluginProps } from 'yet-another-react-lightbox';
import { useSelector } from 'react-redux';
import isInfoPanelOpen from 'app/common/selectors/slideshow/isInfoPanelOpen';
import SlideshowInformationIcon from 'app/common/components/slideshow/information/icon';
import classNames from 'classnames';
import SlideshowInformationContainer from 'app/common/components/slideshow/information/container';
import { HeadText3 } from 'app/common/typography';
import CloseIcon from 'assets/icons/close-light.svg';
import useAction from 'app/display/common/hooks/useAction';
import { toggleInfoPanel } from 'app/common/actions/slideshow';
import { useTranslation } from 'react-i18next';
import isFilmStripOpen from 'app/common/selectors/slideshow/isFilmStripOpen';
import Store from 'app/common/components/slideshow/information/content/store';
import Folder from 'app/common/components/slideshow/information/content/folder';
import Caption from 'app/common/components/slideshow/information/content/caption';
import Tags from 'app/common/components/slideshow/information/content/tags';
import PublishedBy from 'app/common/components/slideshow/information/content/publishedBy';
import ApprovedBy from 'app/common/components/slideshow/information/approvedBy';
import ScrollableList from 'app/display/common/components/list/scrollable';
import styles from './index.css';

export const SLIDESHOW_INFORMATION_PANEL_TESTID = 'slideshowInformationPanel';
export const SLIDESHOW_INFORMATION_PLUGIN_ID = 'information';

function SlideshowInformation({ children }: ComponentProps) {
  const isOpen = useSelector(isInfoPanelOpen);
  const onCloseClick = useAction(toggleInfoPanel);

  const { t } = useTranslation();

  const panelClassName = classNames(styles.informationPanel, {
    [styles.isOpen]: isOpen,
  });

  return (
    <>
      <div className={panelClassName} data-testid={SLIDESHOW_INFORMATION_PANEL_TESTID}>
        <header className={styles.header}>
          <HeadText3 className={styles.uppercase}>{t('slideshow.infoPanel.title')}</HeadText3>
          <CloseIcon className={styles.closeIcon} onClick={onCloseClick} />
        </header>
        <div className={styles.contentWrapper}>
          <ScrollableList>
            <div className={styles.content}>
              <Store />
              <Folder />
              <Caption />
              <Tags />
              <PublishedBy />
              <ApprovedBy />
            </div>
          </ScrollableList>
        </div>
      </div>
      {children}
    </>
  );
}

export default function Information({ addModule, augment }: PluginProps) {
  const informationModule = createModule(SLIDESHOW_INFORMATION_PLUGIN_ID, SlideshowInformation);
  addModule(informationModule);
  const isFilmstripOpen = useSelector(isFilmStripOpen);

  augment(({ toolbar, render, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_INFORMATION_PLUGIN_ID, <SlideshowInformationIcon />),
      render: {
        slideContainer: props => <SlideshowInformationContainer isFilmstripOpen={isFilmstripOpen} {...props} />,
        ...render,
      },
      ...restProps,
    };
  });
}
