import head from 'lodash/head';
import last from 'lodash/last';
import { CHECKED, HALF_CHECKED, UNCHECKED } from 'app/display/common/components/constants/checkboxStates';
import { BRAND_TYPE_GENERIC } from 'app/display/common/reducers/brands';

/**
 * Init Data
 *
 * @typedef {Object} InitData
 *
 * @property {Folder[]} tree
 * @property {boolean} isRecursiveSelection
 */

/**
 * A folder. It could be a Brand, or a Folder.
 *
 * @typedef {Object} Folder
 *
 * @property {string} id
 * @property {string} name
 * @property {Folder[]} children
 */

/**
 * A checkbox status
 *
 * @typedef {Object} CheckboxStatus
 *
 * @property {string} state - CHECKED, HALFCHECKED or UNCHECKED
 * @property {boolean} isDisabled
 */

/**
 * A selectable folder, i.e. a folder with a checkbox status
 *
 * @typedef {Object} SelectableFolder
 *
 * @property {string} id
 * @property {string} name
 * @property {boolean} isGeneric
 * @property {SelectableFolder[]} children
 * @property {CheckboxStatus} checkbox
 */

/**
 * A path. An array of Folder, the first element is the path root, the last element is the path end.
 *
 * @typedef {Folder[]} Path
 */

/**
 * Reducer state. Contains the current selection and the selectable folders tree.
 *
 * @typedef {Object} State
 *
 * @property {boolean} isRecursiveSelection
 * @property {Path[]} selectionPaths
 * @property {SelectableFolder[]} checkboxTree
 */

/**
 * Try find the provided folder in provided folders. Return the index and the folder as an array.
 *
 * @param {Folder} folder - the folder to look for
 * @param {Folder[]} folders - folders to look into
 *
 * @returns {Array}
 */
const findFolderIn = (folder, folders) => {
  //* @returns {[number, Folder]} - return an array, with folder index at first and folder found at second
  const index = folders.findIndex(({ id }) => id === folder.id);
  return [index, folders[index]];
};

/**
 * Apply provided state to provided folder and all its children, and returns it.
 *
 * @param {Folder} folder - folder to update
 * @param {Object} state - state to apply
 *
 * @returns {Folder} - folder updated
 */
const recursiveFolderStateUpdate = (folder, state) => {
  return {
    ...folder,
    ...state,
    children: folder.children.map(childFolder => recursiveFolderStateUpdate(childFolder, state)),
  };
};

/**
 * @param {SelectableFolder} folder - folder to check
 * @param {boolean} onlyChecked - if true, only compares with CHECKED. compares with CHECKED & HALF_CHECKED otherwise.
 *
 * @returns {boolean} - true if folder is considered as selected, false otherwise.
 */
const folderIsSelected = (folder, onlyChecked = false) => {
  if (onlyChecked === false) {
    return [CHECKED, HALF_CHECKED].includes(folder.checkbox.state);
  }
  return folder.isDisabled || folder.checkbox.state === CHECKED;
};

/**
 * @param {Folder} folderToFind - folder to find
 * @param {Folder[]} folders - folders to look into
 *
 * @returns {boolean} - true if folder in included in provided folders, false otherwise.
 */
const foldersIncludes = (folderToFind, folders) => {
  const [index] = findFolderIn(folderToFind, folders);
  return index !== -1;
};

/**
 * @param {Folder} targetFolder - folder to find
 * @param {Path[]} paths - paths to look into
 *
 * @returns {Path[]} - paths that do not contains targetFolder
 */
const excludePathsContaining = (targetFolder, paths) => {
  return paths.filter(path => !foldersIncludes(targetFolder, path));
};

/**
 * @param {Folder} folderToExclude - folder to exclude from folders
 * @param {Folder[]} folders - folders to look into
 *
 * @returns {Folder[]} - folders without folderToExclude
 */
const excludeFolderFrom = (folderToExclude, folders) => {
  return folders.filter(({ id }) => id !== folderToExclude.id);
};

/**
 * @param {Path} path
 * @returns {Folder} - first folder of path
 */
const getPathFirstFolder = path => {
  return head(path);
};

/**
 * @param {Path} path
 * @returns {Folder} - last folder of path
 */
const getPathLastFolder = path => {
  return last(path);
};

/**
 * Flag folder with isGeneric.
 * If folder type is a BRAND_TYPE_GENERIC, isGeneric will be true. False otherwise.
 *
 * @param {Folder} folder
 * @returns {Folder}
 */
const flagGeneric = folder => {
  return { ...folder, isGeneric: folder.type === BRAND_TYPE_GENERIC };
};

/**
 * Flag folder with isGeneric.
 * If folder type is a BRAND_TYPE_GENERIC, isGeneric will be true. False otherwise.
 *
 * @param {Folder} folder
 * @returns {Folder}
 */
const flagCheckboxProperties = folder => {
  return {
    ...folder,
    checkbox: { state: UNCHECKED, isDisabled: false },
    children: [...folder.children.map(flagCheckboxProperties)],
  };
};

const initCheckboxTree = foldersTree => {
  return foldersTree.map(folder => flagCheckboxProperties(flagGeneric(folder)));
};

/**
 * Recursively apply path as a positive selection (checked), or negative selection (unchecked) to provided folder (and its children - multi level), and returns it.
 *
 * @param {SelectableFolder} folder
 * @param {Path} path
 * @param {string} checkboxState
 *
 * @returns {SelectableFolder}
 */
const setFolderCheckboxStateMultiLevel = (folder, path, checkboxState) => {
  const isPathEnd = path.length === 1;
  let children = [...folder.children];
  let intermediateCheckboxState = HALF_CHECKED;

  // intermediate path (first or in-between, but not last path in selection)
  if (!isPathEnd) {
    const [, nextPath] = path;
    const [nextSelectedFolderIndex, nextSelectedFolder] = findFolderIn(nextPath, children);
    children[nextSelectedFolderIndex] = setFolderCheckboxStateMultiLevel(
      nextSelectedFolder,
      path.slice(1),
      checkboxState
    );

    // during a "positive" selection (select), we half check all intermediate paths
    // during a "negative" selection (unselect)
    if (checkboxState === UNCHECKED) {
      // we must determinate the half checked state based on children checkbox status
      //
      // └── [-] DIESEL
      //      ├── [-] CLOTH
      //      │    ├── [X] JEANS
      //      │    └── [X] SHIRT   <- we are unselecting this one
      //      ├── [ ] PRODUCT
      //      └── [ ] TESTER
      //
      // There 2 use cases:
      // - to determinate if CLOTH is UNCHECKED or HALFCHECKED, we must test if any CLOTH children is CHECKED, except SHIRT
      // - to determinate if DIESEL is UNCHECKED or HALFCHECKED, we must test if any DIESEL children is CHECKED or HALFCHECKED.

      // by default, we consider being in DIESEL use case
      let candidates = children;
      // testing if nextPath is the last one (CLOTH use case)
      if (getPathLastFolder(path).id === nextPath.id) {
        // if so, we exclude nextPath from candidates.
        candidates = excludeFolderFrom(nextPath, candidates);
      }

      // if any candidates are selected, status is HALFCHECKED. Otherwise, we use the default value (UNCHECKED).
      intermediateCheckboxState = candidates.some(candidate => folderIsSelected(candidate)) ? HALF_CHECKED : UNCHECKED;
    }
  } else {
    // this is the path end, i.e. the "real" folder the user selected/unselected
    // if there is any subsequent children, we must update their enabled & selected state accordingly
    const update = { checkbox: { state: checkboxState, isDisabled: checkboxState === CHECKED } };
    children = children.map(childFolder => recursiveFolderStateUpdate(childFolder, update));
  }

  return {
    ...folder,
    checkbox: { state: isPathEnd ? checkboxState : intermediateCheckboxState, isDisabled: false },
    children,
  };
};

/**
 * Recursively apply path as a positive selection (checked), or negative selection (unchecked) to provided folder(not for its children - single level), and returns it.
 *
 * @param {SelectableFolder} folder
 * @param {Path} path
 * @param {string} checkboxState
 *
 * @returns {SelectableFolder}
 */
const setFolderCheckboxStateSingleLevel = (folder, path, checkboxState) => {
  const isPathEnd = path.length === 1;
  const children = [...folder.children];
  const intermediateCheckboxState = folder.checkbox.state;

  // intermediate path (first or in-between, but not last path in selection)
  if (!isPathEnd) {
    const [, nextPath] = path;
    const [nextSelectedFolderIndex, nextSelectedFolder] = findFolderIn(nextPath, children);
    children[nextSelectedFolderIndex] = setFolderCheckboxStateSingleLevel(
      nextSelectedFolder,
      path.slice(1),
      checkboxState
    );
  }

  return {
    ...folder,
    checkbox: { state: isPathEnd ? checkboxState : intermediateCheckboxState, isDisabled: false },
    children,
  };
};

const setFolderCheckboxState = (folder, path, checkboxState, isRecursiveSelection) => {
  return isRecursiveSelection
    ? setFolderCheckboxStateMultiLevel(folder, path, checkboxState)
    : setFolderCheckboxStateSingleLevel(folder, path, checkboxState);
};

/*
  Exported function to be used by reducers
*/

/**
 * Build the initial state that need to be saved by reducer
 *
 * @param {InitData} initData
 *
 * @returns {State}
 */
export function initState(initData) {
  const checkboxTree = initData?.tree != null ? initCheckboxTree(initData.tree) : null;

  return {
    isRecursiveSelection: initData?.isRecursiveSelection ?? true,
    selectionPaths: [],
    checkboxTree,
  };
}

/**
 * Update selectionPaths when adding a selection
 *
 * @param {Path} currentPath - Path to add
 * @param {Path[]} paths - paths to look into
 * @param {boolean} isRecursiveSelection
 *
 * @returns {Path[]} - updated selection paths
 */

function updateSelectionPathsOnAdd(currentPath, paths, isRecursiveSelection) {
  return isRecursiveSelection
    ? [...excludePathsContaining(getPathLastFolder(currentPath), paths), currentPath]
    : [...paths, currentPath];
}

/**
 * Add a path to the current selection
 *
 * @param {Path} path
 * @param {State} state
 *
 * @returns {State}
 */
export function addToSelection(path, state) {
  const [idx, folder] = findFolderIn(getPathFirstFolder(path), state.checkboxTree);

  return {
    // it is simpler to exclude any paths containing selected folder, then add it again
    isRecursiveSelection: state.isRecursiveSelection,
    selectionPaths: updateSelectionPathsOnAdd(path, state.selectionPaths, state.isRecursiveSelection),
    checkboxTree: [
      ...state.checkboxTree.slice(0, idx),
      setFolderCheckboxState(folder, path, CHECKED, state.isRecursiveSelection),
      ...state.checkboxTree.slice(idx + 1),
    ],
  };
}

/**
 * Update selectionPaths when removing a selection
 *
 * @param {Path} currentPath - folder to remove
 * @param {Path[]} paths - paths to look into
 * @param {boolean} isRecursiveSelection
 *
 * @returns {Path[]} - updated selection paths
 */

function updateSelectionPathsOnRemove(currentPath, paths, isRecursiveSelection) {
  return isRecursiveSelection
    ? excludePathsContaining(getPathLastFolder(currentPath), paths)
    : paths.filter(selectionPath => {
        return getPathLastFolder(selectionPath).id !== getPathLastFolder(currentPath).id;
      });
}

/**
 * Remove a path from the current selection
 *
 * @param {Path} path
 * @param {State} state
 *
 * @returns {State}
 */
export function removeFromSelection(path, state) {
  const [idx, folder] = findFolderIn(getPathFirstFolder(path), state.checkboxTree);

  return {
    isRecursiveSelection: state.isRecursiveSelection,
    selectionPaths: updateSelectionPathsOnRemove(path, state.selectionPaths, state.isRecursiveSelection),
    checkboxTree: [
      ...state.checkboxTree.slice(0, idx),
      setFolderCheckboxState(folder, path, UNCHECKED, state.isRecursiveSelection),
      ...state.checkboxTree.slice(idx + 1),
    ],
  };
}

/**
 * Reset the current selection
 *
 * @param {State} state
 *
 * @returns {State}
 */
export function resetSelection(state) {
  return state.checkboxTree.reduce((acc, brand) => {
    if (brand?.isDisabled) {
      return acc;
    }

    return removeFromSelection([brand], acc);
  }, state);
}

/**
 * Set the current selection. Paths provided will be the only selection of the folders tree
 *
 * @param {Path[]} paths
 * @param {State} state
 *
 * @returns {State}
 */
export function setSelection(paths, state) {
  return paths.reduce((acc, path) => {
    return addToSelection(path, acc);
  }, resetSelection(state));
}

/**
 * Select all
 *
 * @param {State} state
 *
 * @returns {State}
 */
export function selectAll(state) {
  return state.checkboxTree.reduce((acc, brand) => {
    if (brand?.isDisabled) {
      return acc;
    }

    return addToSelection([brand], acc);
  }, state);
}

/**
 * Returns true if all is selected, false otherwise
 *
 * @param {SelectableFolder[]} checkboxTree
 *
 * @returns {boolean} true if all is selected, false otherwise
 */
export function isAllSelected(checkboxTree) {
  return !checkboxTree.some(brand => !folderIsSelected(brand, true));
}
