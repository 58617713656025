import React from 'react';
import { UserNotificationTypes } from 'domain/userNotifications';
import BestPracticeIcon from 'assets/icons/best-practice.svg';
import ValidationIcon from 'assets/icons/icons-validation.svg';
import Guideline2dIcon from 'assets/icons/icon-guideline.svg';
import DocumentIcon from 'assets/icons/icon-document.svg';
import Survey from 'assets/icons/survey-reminder.svg';
import DeletePhoto from 'assets/icons/photo-delete.svg';
import Paperplane from 'assets/icons/icon-paperplane.svg';
import CommentIcon from 'assets/icons/icon-discussion.svg';
import ReplacePhotoIcon from 'assets/icons/icons-update.svg';
import styles from 'app/display/common/components/modals/userNotification/content/notificationsIcon.css';
import classNames from 'classnames';

const NotificationIcon = ({ type }: { type: UserNotificationTypes }) => {
  const pillClasses = classNames(styles.pill, {
    [styles.bestPractice]: type === UserNotificationTypes.PHOTO_BEST_PRACTICE,
    [styles.approval]: type === UserNotificationTypes.PHOTO_APPROVE,
    [styles.survey]: type === UserNotificationTypes.SURVEY_REMINDER_COMPLETION,
    [styles.greyAction]:
      type === UserNotificationTypes.PHOTO_BEST_PRACTICE_REMOVE ||
      type === UserNotificationTypes.PHOTO_DELETE ||
      type === UserNotificationTypes.PHOTO_APPROVAL_REMOVE,
    [styles.blueAction]:
      type === UserNotificationTypes.SURVEY_NEW_PUBLICATION ||
      type === UserNotificationTypes.SURVEY_NEW_AVAILABLE ||
      type === UserNotificationTypes.DOCUMENT_NEW_AVAILABLE ||
      type === UserNotificationTypes.GUIDELINE_NEW_AVAILABLE ||
      type === UserNotificationTypes.PHOTO_REPLACE ||
      type === UserNotificationTypes.PHOTO_UPLOAD ||
      type === UserNotificationTypes.PHOTO_COMMENT,
  });

  switch (type) {
    case UserNotificationTypes.PHOTO_BEST_PRACTICE:
    case UserNotificationTypes.PHOTO_BEST_PRACTICE_REMOVE:
      return (
        <div className={pillClasses}>
          <BestPracticeIcon className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.PHOTO_APPROVE:
    case UserNotificationTypes.PHOTO_APPROVAL_REMOVE:
      return (
        <div className={pillClasses}>
          <ValidationIcon className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.PHOTO_DELETE:
      return (
        <div className={pillClasses}>
          <DeletePhoto className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.SURVEY_REMINDER_COMPLETION:
      return (
        <div className={pillClasses}>
          <Survey className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.SURVEY_NEW_PUBLICATION:
    case UserNotificationTypes.SURVEY_NEW_AVAILABLE:
      return (
        <div className={pillClasses}>
          <Paperplane className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.GUIDELINE_NEW_AVAILABLE:
      return (
        <div className={pillClasses}>
          <Guideline2dIcon className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.DOCUMENT_NEW_AVAILABLE:
      return (
        <div className={pillClasses}>
          <DocumentIcon className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.PHOTO_UPLOAD:
    case UserNotificationTypes.PHOTO_REPLACE:
      return (
        <div className={pillClasses}>
          <ReplacePhotoIcon className={styles.icon} />
        </div>
      );
    case UserNotificationTypes.PHOTO_COMMENT:
      return (
        <div className={pillClasses}>
          <CommentIcon className={styles.icon} />
        </div>
      );
    default:
      return null;
  }
};

export default NotificationIcon;
