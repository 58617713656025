import FileStatus from 'domain/guideline/fileStatus';

export enum GuidelineDataFileType {
  BAY_PREVIEW = 'bayPreview',
  FACING = 'facing',
  THUMBNAIL = 'thumbnail',
  DISPLAY_FOLDED = 'display-folded',
  DISPLAY_SIDE = 'display-side',
  DISPLAY_FACING_MEDIUM = 'display-facing-medium',
  DISPLAY_FOLDED_MEDIUM = 'display-folded-medium',
  DISPLAY_SIDE_MEDIUM = 'display-side-medium',
  DISPLAY_SOURCE = 'display-source',
  DISPLAY_SOURCE_V4 = 'display-source-v4',
  PREVIEW = 'preview',
  TEXTURE = 'texture',
  TEXTURE_MEDIUM = 'texture-medium',
  DOCUMENT_VIEW = 'documentView',
  DOCUMENT_TEXT_IN_BOX = 'documentViewTextInBox',
}

export default class GuidelineDataFile {
  constructor(
    public readonly id: string,
    public readonly type: GuidelineDataFileType,
    public readonly fileURL: string,
    public readonly url: string,
    public readonly status: FileStatus = FileStatus.READY
  ) {
    // do nothing.
  }

  public isGenerating(): boolean {
    return this.status === FileStatus.GENERATING;
  }

  public isReady(): boolean {
    return this.status === FileStatus.READY;
  }
}
