export default function areArraysEqual<T extends string | number>(arr1: T[], arr2: T[]) {
  // First check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Sort both arrays to ensure order doesn't matter
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  // Then check if each element is the same in both sorted arrays
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  // If both checks passed, the arrays are equal
  return true;
}
