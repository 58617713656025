import React from 'react';
import { useTranslation } from 'react-i18next';
import Section from 'app/common/components/slideshow/information/section';
import IconRemoveAll from 'assets/icons/cleanUp.svg';
import classNames from 'classnames';
import { HeadText5, LINK_COLOR_PALETTE } from 'app/common/typography';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import Skeleton from 'app/common/components/skeleton';
import { useLightboxState } from 'yet-another-react-lightbox';
import useShowModal from 'app/display/common/hooks/useShowModal';
import SlideshowResetApprovalsModal from 'app/common/components/slideshow/information/modal';
import ApprovedByRow from 'app/common/components/slideshow/information/approvedByRow';
import useIsCurrentUserAllowedToApproveSlideshowPhotos from 'app/common/hooks/acl/useIsCurrentUserAllowedToApproveSlideshowPhotos';
import useIsCurrentUserAllowedToResetAllApprovalSlideshowPhotos from 'app/common/hooks/acl/useIsCurrentUserAllowedToResetAllApprovalSlideshowPhotos';
import ApprovedByButton from 'app/common/components/slideshow/information/approvedByButton';
import styles from './index.css';

export const SLIDESHOW_INFORMATION_PANEL_APPROVED_BY_LINK_TESTID = 'slideshow-information-panel-approved-by-link';
export const SLIDESHOW_INFORMATION_PANEL_APPROVED_BY_LIST_TESTID = 'slideshow-information-panel-approved-by-list';

export type ApprovalType = {
  user: {
    id: string;
    name: string;
  };
  date: string;
};

export default function ApprovedBy() {
  const { t } = useTranslation();
  const { currentSlide } = useLightboxState();
  const canUserApprovePhoto = useIsCurrentUserAllowedToApproveSlideshowPhotos();
  const canUserResetAllApproval = useIsCurrentUserAllowedToResetAllApprovalSlideshowPhotos();
  const { currentUser, isLoading } = useCurrentUser();

  const approvals = currentSlide?.approvals || [];
  const hasApprovals = approvals.length > 0;
  const isCurrentUserApproval = approvals.some(approval => approval.user.id === currentUser?.id);
  const showRemoveAllButton =
    canUserResetAllApproval && ((approvals.length === 1 && !isCurrentUserApproval) || approvals?.length > 1);

  const openModal = useShowModal(SlideshowResetApprovalsModal, {
    photo: currentSlide,
  });

  const approveByListClasses = classNames(styles.approvedByList, {
    [styles.approveButton]: canUserApprovePhoto,
  });

  if (isLoading || !currentSlide) {
    return <Skeleton />;
  }

  return (
    <Section title={t('slideshow.infoPanel.approvedBy.title')} className={styles.approvedBy}>
      {canUserApprovePhoto ? (
        <ApprovedByButton
          currentSlide={currentSlide}
          dataTestid={SLIDESHOW_INFORMATION_PANEL_APPROVED_BY_LINK_TESTID}
          isCurrentUserApproval={isCurrentUserApproval}
        />
      ) : null}
      {hasApprovals && (
        <>
          <div className={approveByListClasses} data-testid={SLIDESHOW_INFORMATION_PANEL_APPROVED_BY_LIST_TESTID}>
            {approvals.map(approval => {
              return <ApprovedByRow approval={approval} currentUser={currentUser} key={approval.user.id} />;
            })}
          </div>
          {showRemoveAllButton && (
            <div onClick={openModal}>
              <HeadText5 palette={LINK_COLOR_PALETTE} interactive={true} className={styles.link}>
                <IconRemoveAll className={styles.icon} />
                {t('slideshow.infoPanel.removeAllApprovals')}
              </HeadText5>
            </div>
          )}
        </>
      )}
    </Section>
  );
}
