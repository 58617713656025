import React from 'react';
import PropTypes from 'prop-types';
import { BodyText2, ERROR_COLOR_PALETTE } from 'app/common/typography';
import styles from './requiredSymbol.css';

const RequiredSymbol = ({ required }) =>
  required ? (
    <BodyText2 palette={ERROR_COLOR_PALETTE} className={styles.required} interactive={false}>
      *
    </BodyText2>
  ) : null;

RequiredSymbol.propTypes = {
  required: PropTypes.bool.isRequired,
};

export default RequiredSymbol;
