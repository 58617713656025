import React from 'react';
import 'app/common/components/popup/actions/action.css';
import Button from 'app/display/common/components/button';
import InteractiveIcon from 'app/common/typography/text/icon';
import classNames from 'classnames';
import styles from './index.css';

type IconButtonProps = {
  onClick: () => void;
  Icon: React.FC<{ className?: string; style: React.CSSProperties }>;
  width?: number;
  height?: number;
  className?: string;
  isDisabled?: boolean;
};

export default function IconButton({ onClick, Icon, width, height, className, isDisabled = false }: IconButtonProps) {
  return (
    <Button
      type="button"
      className={classNames(styles.iconContainer, className)}
      onClick={onClick}
      isDisabled={isDisabled}
    >
      <InteractiveIcon disabled={isDisabled}>
        <Icon className={styles.icon} style={{ width, height }} />
      </InteractiveIcon>
    </Button>
  );
}
