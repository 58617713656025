import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import noop from 'lib/helpers/noop';
import styles from './unexpected.css';

class UnexpectedErrorModal extends React.PureComponent {
  el = document.createElement('div');

  static propTypes = {
    dashboardUrl: PropTypes.string,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    dashboardUrl: null,
    onClose: noop,
  };

  componentDidMount() {
    window.addEventListener('popstate', this.handlePopState);
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
    document.body.removeChild(this.el);
  }

  handlePopState = () => {
    this.closeModal();
  };

  handleDashboardClick = () => {
    this.closeModal();
  };

  closeModal = () => {
    this.props.onClose();
  };

  render() {
    return createPortal(
      <aside className={styles.overlay}>
        <div className={styles.modal}>
          <div className={styles.container}>
            <h2 className={styles.oops}>{i18next.t('display.error.oops')}</h2>
            <h1 className={styles.title}>{i18next.t('modal.error.unexpected.title')}</h1>
            <p className={styles.subtitle}>{i18next.t('modal.error.unexpected.message')}</p>
            <p className={styles.greetings}>{i18next.t('modal.error.unexpected.greetings')}</p>
            <div className={styles.subtitle}>
              {this.props.dashboardUrl && (
                <Trans
                  i18nKey="modal.error.unexpected.action"
                  values={{
                    dashboard: i18next.t('modal.error.unexpected.dashboard'),
                  }}
                  components={[
                    <a href={this.props.dashboardUrl} className={styles.dashboard} onClick={this.handleDashboardClick}>
                      dashboard.
                    </a>,
                  ]}
                />
              )}
            </div>
            <p className={styles.support}>{i18next.t('display.error.support')}</p>
          </div>
        </div>
      </aside>,
      this.el
    );
  }
}

export default UnexpectedErrorModal;
