import GuidelineDataSettings from 'domain/guideline/data/settings';
import GuidelineDataSettingsPayload from 'domain/guideline/data/settings/factory/GuidelineDataSettingsPayload';
import GuidelineDataSettingsFormValues from 'domain/guideline/data/settings/factory/GuidelineDataSettingsFormValues';

export default class GuidelineDataSettingsFactory {
  static createFromApiPayload(payload: GuidelineDataSettingsPayload): GuidelineDataSettings {
    return new GuidelineDataSettings(
      payload.showName,
      payload.showName2,
      payload.showCode,
      payload.showCode2,
      payload.showCode3,
      payload.showMarketCode,
      payload.showMarketCode2,
      payload.itemDisplay,
      payload.showPath,
      payload.fixturePreviewSize,
      payload.fixturePosition,
      payload.numberingOnGuideline,
      payload.numberingOnBays,
      payload.displayGuidelineImage,
      payload.breakBeforeFirstFixture,
      payload.breakAfterEveryFixture,
      payload.displayLegend,
      payload.showCapacity1,
      payload.showCapacity2,
      payload.showItems,
      payload.showTextInBox
    );
  }

  static createFromFormValues(values: GuidelineDataSettingsFormValues): GuidelineDataSettings {
    return new GuidelineDataSettings(
      values.showName,
      values.showName2,
      values.showCode,
      values.showCode2,
      values.showCode3,
      values.showMarketCode,
      values.showMarketCode2,
      values.itemDisplay,
      values.showPath,
      values.fixturePreviewSize,
      values.fixturePosition,
      values.numberingOnGuideline,
      values.numberingOnBays,
      values.displayGuidelineImage,
      values.breakBeforeFirstFixture,
      values.breakAfterEveryFixture,
      values.displayLegend === 'true',
      values.showCapacity1,
      values.showCapacity2,
      values.showItems,
      values.showTextInBox
    );
  }
}
