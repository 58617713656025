import React from 'react';
import i18next from 'i18next';
import Link from 'app/common/components/offCanvasPlatformMenu/links/link';
import DocumentsIcon from 'assets/icons/documents-grid.svg';
import { PATH_NETWORK_SHAREDDOCUMENTS } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import useCurrentUserDocumentPermissions from 'app/common/hooks/useCurrentUserDocumentPermissions';

const useDocumentsLink = () => {
  const isActive = useLocationMatch(PATH_NETWORK_SHAREDDOCUMENTS, { exact: false });
  const href = useParamSelector(getAbsoluteUrl, PATH_NETWORK_SHAREDDOCUMENTS);
  const { canAccess, discoveryPagesEnabled } = useCurrentUserDocumentPermissions();

  return {
    href,
    isActive,
    canAccess,
    discoveryPagesEnabled,
  };
};

const ReportsLink = () => {
  const { href, isActive, canAccess, discoveryPagesEnabled } = useDocumentsLink();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <Link href={href} Icon={DocumentsIcon} isActive={isActive} isDisabled={!canAccess}>
      {i18next.t('documents')}
    </Link>
  );
};

export default ReportsLink;
