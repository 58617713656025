import { ADD_STORES, UPDATE_STORES, REMOVE_STORES, CLEAR_STORES } from 'app/network/survey/report/actions/stores/types';
import array2map from 'lib/helpers/array2map';

export const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_STORES:
    case UPDATE_STORES: {
      const storesById = array2map(action.stores, 'id');
      return {
        ...state,
        ...storesById,
      };
    }
    case REMOVE_STORES: {
      const newState = {
        ...state,
      };
      action.stores.forEach(store => {
        delete newState[store.id];
      });
      return newState;
    }
    case CLEAR_STORES:
      return {};
    default:
      return state;
  }
}
