import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
import moment from 'moment';
import i18next from 'i18next';
import i18nextXHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextConfig from 'config/i18next';
import Error from 'app/common/components/errors/error';
import initLegacyComponents from 'lib/legacyComponents';

function initWhyDidYouRender() {
  if (process.env.NODE_ENV !== 'production' && process.env.WHY_DID_YOU_UPDATE === 'true') {
    // eslint-disable-next-line
    const whyDidYouRender = require('@welldone-software/why-did-you-render');

    // Add temporarily components names that you want to track in the following variables.
    // Regex are allowed, see https://github.com/welldone-software/why-did-you-render#include--exclude
    const include = [];
    const exclude = [];

    whyDidYouRender(React, { include, exclude });
  }
}

function initAxios() {
  // Default axios adapter does not work with nock that we use in out tests, httpAdapter is needed
  axios.defaults.adapter = httpAdapter;
}

function initMoment() {
  moment.locale(i18nextConfig.lng);

  // Keep language sync between i18n and moment
  i18next.on('languageChanged', lng => {
    moment.locale(lng);
  });
}

function initI18next() {
  // TMP hack to handle popups
  // TODO supprimer quand refactor notifications terminé (suppression layer)
  window.i18next = i18next;

  return i18next.use(i18nextXHR).use(LanguageDetector).init(i18nextConfig);
}

function initErrorListeners() {
  /**
   * Primitive error handler
   *
   * The error boundary already catch this kind of errors, this is just an additional guard.
   */
  window.addEventListener('error', ({ error }) => {
    // When React is built in development, the errors occurring in components will fire this event
    // before they can be caught by an error boundary:
    // - https://reactjs.org/docs/react-component.html#componentdidcatch
    // - https://github.com/facebook/react/issues/12897#issuecomment-410036991
    // - https://github.com/facebook/react/issues/10474
    //
    // But here we only want to display an error page when no error boundary caught it, and the base app layer
    // exists.
    //
    // The solution to this problem is to delay displaying the error page with a Promise, so that the error boundaries
    // can catch the error and set a flag on the error to indicate that the error has been caught, so when this flag
    // is set we don't display the error page.
    Promise.resolve().then(() => {
      if (!error.wasCaughtByErrorBoundary) {
        const rootNode = document.getElementById('app-layer');
        if (rootNode) {
          createRoot(rootNode).render(<Error error={error} />);
        }
      }
    });
  });

  /**
   * Primitive promise rejection handler.
   *
   * The error boundary may not catch this kind of errors.
   */
  window.addEventListener('unhandledrejection', ({ reason: error }) => {
    const rootNode = document.getElementById('app-layer');
    if (rootNode) {
      createRoot(rootNode).render(<Error error={error} />);
    }
  });
}

// Used for miscellaneous app setup
function initMisc() {
  document.documentElement.lang = navigator.language;
  initLegacyComponents();
}

export default function initApp() {
  return Promise.all([
    initWhyDidYouRender(),
    initAxios(),
    initMoment(),
    initI18next(),
    initErrorListeners(),
    initMisc(),
  ]);
}
