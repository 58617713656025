import React from 'react';
import classNames from 'classnames';
import styles from './index.css';

type LoadingBarProps = { className?: string };
const LoadingBar = ({ className }: LoadingBarProps) => (
  <div className={classNames(styles.container, className)}>
    <span className={styles.loadingBar} />
  </div>
);

export default LoadingBar;
