import React from 'react';

import RightsLink from 'app/common/components/legacyNavMenu/menu/acl/links/rights';
import NavLinks from 'app/common/components/legacyNavMenu/navLinks';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import { PATH_ACL_INDEX } from 'config/routePaths';
import useSmallScreen from 'app/common/hooks/useSmallScreen';

export default function NavACL() {
  const isSmallScreen = useSmallScreen();
  const matchACLRoute = useLocationMatch(PATH_ACL_INDEX, {
    exact: false,
  });

  if (!matchACLRoute || isSmallScreen) {
    return null;
  }

  return (
    <NavLinks>
      <RightsLink />
    </NavLinks>
  );
}
