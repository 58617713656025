import React from 'react';
import classNames from 'classnames';
import { BodyText1 } from 'app/common/typography';
import SearchIcon from 'assets/icons/loupe.svg';
import ClearIcon from 'assets/icons/clear.svg';
import { useTranslation } from 'react-i18next';
import styles from './input.css';

type TransferContentSelectUserInputPropTypes = {
  value?: string;
  onChange: (arg0: any) => void;
  onClear: () => void;
  className: string;
  type: string;
};

const TransferContentSelectUserInput = ({
  value,
  onChange,
  onClear,
  ...inputProps
}: TransferContentSelectUserInputPropTypes) => {
  const { t } = useTranslation();
  // must use destructing to get rid of bad properties
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { className, type, ...props } = inputProps;

  const searchClasses = classNames(styles.search, {
    [styles.noInput]: value?.length === 0,
  });

  return (
    <BodyText1 className={searchClasses} dataTestId="transferContentSelect">
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={styles.input}
        placeholder={t('search')}
        data-testid="transferContentInput"
        {...props}
      />
      <div className={styles.icon}>
        <ClearIcon
          className={styles.iconClear}
          role="button"
          onClick={onClear}
          data-testid="transferContentClearIcon"
        />
        <SearchIcon className={styles.iconSearch} role="button" />
      </div>
    </BodyText1>
  );
};

export default TransferContentSelectUserInput;
