import React from 'react';
import SlideshowStatusTag from 'app/common/components/slideshow/statusTag';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import classNames from 'classnames';
import hasUserApprovedImage from 'app/common/helpers/slideshow/hasUserApprovedImage';
import { useTranslation } from 'react-i18next';
import { SlideImage } from 'yet-another-react-lightbox';
import styles from './index.css';

export default function SlideshowApprovalsTag({ currentSlide }: { currentSlide: SlideImage }) {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  return currentSlide.approvals.length > 0 ? (
    <SlideshowStatusTag
      className={classNames(styles.approvalsTag, {
        [styles.approved]: hasUserApprovedImage(currentUser, currentSlide),
      })}
    >
      {t('slideshow.statusTags.approve')}
      <span className={styles.approvalsCount}>{currentSlide.approvals.length}</span>
    </SlideshowStatusTag>
  ) : null;
}
