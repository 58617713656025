import { useSelector } from 'react-redux';
import hasCurrentUserNetworkAccess from 'app/common/user/selectors/hasCurrentUserNetworkAccess';
import useLegacyAcl from 'app/common/hooks/useLegacyAcl';
import useInstanceSettingsDiscoveryPage from 'app/common/hooks/useInstanceSettingsDiscoveryPage';

type SurveyPermission = {
  canAccess: boolean;
  canManage: boolean;
  discoveryPagesEnabled?: boolean;
};

export default function useCurrentUserSurveyPermissions(): SurveyPermission {
  const hasNetworkAccess = useSelector(hasCurrentUserNetworkAccess);
  const { isAllowed: canManage } = useLegacyAcl('survey', 'manage');
  const { isAllowed: canFollow } = useLegacyAcl('survey', 'follow');
  const canAccess = hasNetworkAccess && (canManage === true || canFollow === true);
  const { discoveryPagesEnabled } = useInstanceSettingsDiscoveryPage();
  return { canAccess, canManage: canAccess && canManage, discoveryPagesEnabled };
}
