import React from 'react';
import i18next from 'i18next';
import { HeadText6 } from 'app/common/typography';
import { GuidelineTag } from 'domain/guideline/tag';
import ListSelection from 'app/display/common/components/list/selection';
import TopBarSeparator from 'app/display/common/components/list/topBar/actions/separator';
import DownloadButton from 'app/network/tag/guideline/components/actionbar/downloadButton';
import DeleteButton from 'app/network/tag/guideline/components/actionbar/deleteButton';
import RenameButton from 'app/network/tag/guideline/components/actionbar/renameButton';
import useShowModal from 'app/display/common/hooks/useShowModal';
import DeleteGuidelineTagModal from 'app/network/tag/guideline/components/modals/delete';
import EditGuidelineTagModal from 'app/network/tag/guideline/components/modals/edit';
import useExportGuidelineTagCsv from 'app/network/tag/guideline/hooks/useExportGuidelineTagCsv';
import styles from './index.css';

type GuidelineTagActionBarProps = {
  list?: GuidelineTag[];
  allTags?: GuidelineTag[];
  selectedTagIds?: string[];
  toggleAll: (isSelectAll: boolean) => void;
  setTagName: (value: string) => void;
  clearTagSelection: () => void;
};

export default function GuidelinesTagActionBar({
  list = [],
  allTags = [],
  selectedTagIds = [],
  toggleAll,
  setTagName,
  clearTagSelection,
}: GuidelineTagActionBarProps) {
  const exportGuidelineTagCsv = useExportGuidelineTagCsv();
  const count = list.length;
  const countSelected = selectedTagIds.length;
  const selectedTags = selectedTagIds.map(id => allTags.find(tag => tag.id === id)) || [];
  const isSelectDisabled = count === countSelected;
  const openDeleteModal = useShowModal(DeleteGuidelineTagModal, { selectedTags, clearTagSelection });
  const openRenameModal = useShowModal(EditGuidelineTagModal, { selectedTag: selectedTags[0], setTagName });

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftGroup}>
        <div className={styles.tagCounter}>
          <HeadText6>{i18next.t('network.guidelines.tag.list.count', { count })}</HeadText6>
        </div>
      </div>
      <div className={styles.rightGroup}>
        <ListSelection
          count={countSelected}
          selectionCountLabel={i18next.t('network.guidelines.tag.selected', { count: countSelected })}
          isSelectDisabled={isSelectDisabled}
          onSelectAll={() => toggleAll(true)}
          onDeselectAll={() => toggleAll(false)}
        />
        <TopBarSeparator />
        <div className={styles.buttonWrapper}>
          <RenameButton onClick={openRenameModal} disabled={countSelected !== 1} />
          <DeleteButton onClick={openDeleteModal} disabled={countSelected === 0} />
          <DownloadButton onClick={exportGuidelineTagCsv} />
        </div>
      </div>
    </div>
  );
}
