import React from 'react';
import classNames from 'classnames';
import { HeadText6, LegendText2, GUIDELINE_STATUS_COLOR_PALETTE, DEFAULT_COLOR_PALETTE } from 'app/common/typography';
import UserStatusAnnotationIcon from 'app/directory/user/common/components/statusAnnotation/statusIcon';
import { UserStatus } from 'domain/user/fullUser';
import styles from './statusTooltip.css';

export default function UserStatusAnnotationTooltip({
  userStatus,
  title,
  tooltip,
}: {
  userStatus?: UserStatus;
  title: string;
  tooltip: string;
}) {
  const iconClassName = classNames(styles.icon, {
    [styles.deactivated]: userStatus === UserStatus.DEACTIVATED,
  });

  const palette = userStatus === UserStatus.ACTIVATED ? GUIDELINE_STATUS_COLOR_PALETTE : DEFAULT_COLOR_PALETTE;

  return (
    <div className={styles.container}>
      <HeadText6 className={styles.title} palette={palette}>
        <UserStatusAnnotationIcon className={iconClassName} userStatus={userStatus} />
        {title}
      </HeadText6>
      <LegendText2 className={styles.infoText}>{tooltip}</LegendText2>
    </div>
  );
}
