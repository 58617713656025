import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Link from 'app/common/components/link';

import { NETWORK_COLOR_PALETTE, NETWORK_ALT_COLOR_PALETTE, HeadText5, TextHoverArea } from 'app/common/typography';
import styles from './link.css';

const NetworkLink = ({ className, href, to, children, isActive }) => {
  const palette = isActive ? NETWORK_ALT_COLOR_PALETTE : NETWORK_COLOR_PALETTE;
  return (
    <TextHoverArea
      className={classNames(styles.hoverArea, className, { [styles.isActive]: isActive })}
      palette={palette}
    >
      <Link href={href} to={to}>
        <HeadText5 palette={palette}>{children}</HeadText5>
      </Link>
    </TextHoverArea>
  );
};

NetworkLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

NetworkLink.defaultProps = {
  className: null,
  href: null,
  to: null,
};

export default NetworkLink;
