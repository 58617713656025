export const FETCH_STORE_START = 'fetch.store.start';
export const FETCH_STORE_ERROR = 'fetch.store.error';
export const FETCH_STORE_SUCCESS = 'fetch.store.success';

export const FETCH_STORES_START = 'fetch.stores.start';
export const FETCH_STORES_ERROR = 'fetch.stores.error';
export const FETCH_STORES_SUCCESS = 'fetch.stores.success';

export const ADD_STORES = 'stores.add';
export const UPDATE_STORES = 'stores.update';
export const REMOVE_STORES = 'stores.remove';
export const CLEAR_STORES = 'stores.clear';
