export const CATALOG_ITEM_VIEW_ACTIONSPOPUP_TOGGLE = 'catalog.item.view.actionsPopup.toggle';
export const CATALOG_ITEM_VIEW_ACTIONSPOPUP_OPEN = 'catalog.item.view.actionsPopup.open';
export const CATALOG_ITEM_VIEW_ACTIONSPOPUP_RESET = 'catalog.item.view.actionsPopup.reset';

export const initialState = {
  visible: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CATALOG_ITEM_VIEW_ACTIONSPOPUP_RESET:
      return {
        ...initialState,
      };
    case CATALOG_ITEM_VIEW_ACTIONSPOPUP_TOGGLE:
      return {
        ...state,
        visible: action.visible != null ? action.visible === true : !state.visible,
      };
    case CATALOG_ITEM_VIEW_ACTIONSPOPUP_OPEN:
      return {
        ...state,
        visible: true,
      };
    default:
      return state;
  }
}
