import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import {
  FETCH_GUIDELINE_ERROR,
  FETCH_GUIDELINE_START,
  FETCH_GUIDELINE_SUCCESS,
  TOGGLE_ACTIONS_POPUP_VISIBILITY,
} from 'app/display/guideline/common/actions';

export const initialState = {
  status: STATUS_LOADING,
  error: undefined,
  isActionsPopupVisible: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GUIDELINE_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_GUIDELINE_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
      };
    case FETCH_GUIDELINE_ERROR:
      return {
        ...state,
        status: STATUS_ERROR,
        error: action.error,
      };
    case TOGGLE_ACTIONS_POPUP_VISIBILITY:
      return {
        ...state,
        isActionsPopupVisible: !state.isActionsPopupVisible,
      };
    default:
      return state;
  }
}
