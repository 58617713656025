import React from 'react';
import NotificationsTabPhotoSection from 'app/directory/user/sheet/common/components/form/notifications/photo';
import NotificationsTabSurveySection from 'app/directory/user/sheet/common/components/form/notifications/survey';
import NotificationsTabGuidelineSection from 'app/directory/user/sheet/common/components/form/notifications/guideline';
import NotificationsTabDocumentSection from 'app/directory/user/sheet/common/components/form/notifications/document';
import NotificationsTabEmailReportsSection from 'app/directory/user/sheet/common/components/form/notifications/emailReports';
import styles from './index.css';

type NotificationsTabFormProps = {
  testPhotoReportHref: string;
  testSurveyReportHref: string;
  userIdV4: string;
};

export default function NotificationsTabForm({
  testPhotoReportHref,
  testSurveyReportHref,
  userIdV4,
}: NotificationsTabFormProps) {
  return (
    <div className={styles.notificationsForm}>
      <NotificationsTabPhotoSection />
      <NotificationsTabSurveySection />
      <NotificationsTabGuidelineSection />
      <NotificationsTabDocumentSection />
      <NotificationsTabEmailReportsSection
        testPhotoReportHref={testPhotoReportHref}
        testSurveyReportHref={testSurveyReportHref}
        userIdV4={userIdV4}
      />
    </div>
  );
}
