import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { TagPhotoModalProps } from 'app/common/components/slideshow/tagPhoto/modal';
import WizardStepWrapper from 'app/display/common/components/modals/wizard/wrapper';
import { WizardModalStepProps } from 'app/display/common/components/modals/wizard';
import { useTranslation } from 'react-i18next';
import { BodyText1 } from 'app/common/typography';
import usePhotoTags from 'app/common/hooks/usePhotoTags';
import SearchDropDownSingle from 'app/display/common/components/searchDropDown/searchDropDownSingle';
import { OptionSearchDropDown } from 'app/display/common/helpers/searchDropDown/convertValuesToOptions';
import { SingleValue } from 'react-select';
import ModalInput from 'app/display/common/components/modals/modalInput';
import classNames from 'classnames';
import ErrorMessage from 'app/common/components/errors/message';
import RequiredSymbol from 'app/common/components/requiredSymbol';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import Skeleton from 'app/common/components/skeleton';
import PhotoTag from 'domain/photo/tag';
import getGroupListOptions from 'app/common/components/slideshow/tagPhoto/helpers/getGroupListOptions';
import styles from './index.css';

export const PHOTO_TAG_NAME_ADD_NEW_INPUT = 'photo-tag-name-add-new';

export default function CreatePhotoTag({
  stepData,
  setConfirmHandlers,
  goToStep,
}: WizardModalStepProps<TagPhotoModalProps>) {
  const { t } = useTranslation();
  const { photoTags, isLoading } = usePhotoTags();
  const { searchTerm, setSearchTerm, selectedTags, setSelectedTags, newTags, setNewTags } = stepData;

  const [searchGroupTerm, setSearchGroupTerm] = useState('');
  const [tagName, setTagName] = useState(searchTerm);
  const [tagExists, setTagExists] = useState(false);
  const [invalidGroupName, setInvalidGroupName] = useState(false);
  const [invalidTagName, setInvalidTagName] = useState(false);
  const groupList = photoTags?.getTagCategories('');

  const onConfirmError = () => {
    notifyError(t('slideshow.tagPhoto.failed'));
    goToStep(1);
  };
  const onConfirmSuccess = () => {
    const fullTagName = searchGroupTerm.length > 0 ? `${searchGroupTerm}/${tagName}` : tagName;
    setSelectedTags([...selectedTags, fullTagName]);
    setNewTags([...newTags, new PhotoTag(fullTagName)]);
    goToStep(1);
  };

  useEffect(() => {
    const crtValue = searchGroupTerm.length > 0 ? `${searchGroupTerm}/${tagName}` : tagName;
    const exists = photoTags?.some(tag => tag.fullTagName === crtValue.trim()) || false;
    const existsAsNew = newTags.some(tag => tag.fullTagName === crtValue.trim());
    setTagExists(exists || existsAsNew);
    setInvalidGroupName(searchGroupTerm.includes('/'));
    setInvalidTagName(tagName.includes('/'));
  }, [searchGroupTerm, tagName]);

  useEffect(() => {
    const confirmEnabled = !tagExists && tagName.length > 0 && !invalidGroupName && !invalidTagName;
    setConfirmHandlers({
      confirmEnabled,
      onConfirmSuccess,
      onConfirmError,
    });
  }, [setConfirmHandlers, tagExists, tagName, invalidGroupName, invalidTagName]);

  const handleChangeSearchGroup = (optionDropDown: SingleValue<OptionSearchDropDown>) => {
    if (optionDropDown) {
      setSearchGroupTerm(optionDropDown.value);
    } else {
      setSearchGroupTerm('');
    }
  };

  const handleChangeTagName = useCallback(
    ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(value);
      setTagName(value);
    },
    [setSearchTerm, setTagName]
  );

  const inputClasses = classNames(styles.input, {
    [styles.isDuplicate]: tagExists,
    [styles.isInvalid]: invalidTagName,
  });
  const isSubmitting = false;
  const inputRef = useRef(null);

  return (
    <WizardStepWrapper wizardKey="createPhotoTag">
      <BodyText1 className={styles.labelSearch}>{t('slideshow.tagPhoto.group')}</BodyText1>
      <div className={styles.search}>
        {isLoading ? (
          <Skeleton width={510} height={35} className={styles.skeleton} />
        ) : (
          <>
            <SearchDropDownSingle
              fieldName="groupPhotoTag"
              options={getGroupListOptions(groupList, newTags)}
              canCreateEntries={true}
              placeholder={t('slideshow.tagPhoto.group.search')}
              onChange={handleChangeSearchGroup}
              showClearAll={true}
            />
            {invalidGroupName && (
              <div className={styles.error}>
                <ErrorMessage message={t('network.guidelines.tag.create.error.invalidCharacter')} />
              </div>
            )}
          </>
        )}
      </div>
      <BodyText1 className={styles.labelSearch}>{t('slideshow.tagPhoto.new.name')}</BodyText1>
      <div className={styles.search}>
        {isLoading ? (
          <Skeleton width={510} height={35} className={styles.skeleton} />
        ) : (
          <>
            <ModalInput
              id="photoTag"
              name="photoTag"
              placeholder=""
              inputRef={inputRef}
              type="text"
              maxLength={50}
              required={true}
              pattern=".*\S+.*"
              onChange={handleChangeTagName}
              className={inputClasses}
              defaultValue={tagName}
              isDisabled={isSubmitting}
              dataTestId={PHOTO_TAG_NAME_ADD_NEW_INPUT}
            />
            {tagExists && (
              <div className={styles.error}>
                <RequiredSymbol required />
                <ErrorMessage message={t('network.guidelines.tag.create.error.sameName')} />
              </div>
            )}
            {invalidTagName && (
              <div className={styles.error}>
                <ErrorMessage message={t('network.guidelines.tag.create.error.invalidCharacter')} />
              </div>
            )}
          </>
        )}
      </div>
    </WizardStepWrapper>
  );
}
