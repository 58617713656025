import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import ErrorPage from 'app/common/components/errors/error';

class NonCriticalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidUpdate(prevProps) {
    if (this.state.error && this.props.location.pathname !== prevProps.location.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ error: null });
    }
  }

  componentDidCatch(error) {
    assertIsNotCriticalHttpException(error);

    error.wasCaughtByErrorBoundary = true;

    this.setState({ error });
    console.error(error);
  }

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (error) {
      const Fallback = fallback;
      return <Fallback error={error} />;
    }

    return children;
  }
}

NonCriticalErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  fallback: PropTypes.elementType,
};

NonCriticalErrorBoundary.defaultProps = {
  fallback: ErrorPage,
};

export default withRouter(NonCriticalErrorBoundary);
