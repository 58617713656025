import { connect } from 'react-redux';

import showIfVisible from 'app/common/hoc/showIfVisible';
import isCurrentUserAllowedToAccessAcl from 'app/common/selectors/acl/isCurrentUserAllowedToAccessAcl';
import Rights from 'app/display/common/components/appShell/header/userMenu/links/rights';

const mapStateToProps = state => ({
  isVisible: isCurrentUserAllowedToAccessAcl(state),
});

export default connect(mapStateToProps)(showIfVisible(Rights));
