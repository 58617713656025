import React from 'react';
import { Redirect } from 'react-router-dom';
import { PATH_DISCOVER } from 'config/routePaths';
import HTTPForbiddenError from 'lib/errors/http/forbiddenError';
import DocumentTitle from 'app/common/helpers/translated-document-title';
import ErrorPage from 'app/common/components/errors/error';
import generatePathname from 'lib/helpers/router/generatePathname';
import { DISCOVER_GUIDELINES } from 'app/discover/constants/moduleNames';

const GuidelineDocumentViewErrorBoundary = ({ error }: { error: Error }) => {
  const guidelinesDiscoverURL = generatePathname(PATH_DISCOVER, {
    moduleName: DISCOVER_GUIDELINES,
  });

  if (error instanceof HTTPForbiddenError) {
    return <Redirect to={guidelinesDiscoverURL} />;
  }

  return (
    <>
      <DocumentTitle title="display.guideline.detail.unexpectedError.title" />
      <ErrorPage error={error} />
    </>
  );
};

export default GuidelineDocumentViewErrorBoundary;
