import React from 'react';
import BlockSelection from 'app/display/common/components/input/block/selection';
import PropTypes from 'prop-types';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import InputBlockSize from 'app/display/common/components/input/block/inputBlockSize';

const BlockCheckbox = ({ children, className, input, meta, disabled, ...props }) => {
  return (
    <InputBlockSize size={props.size}>
      <BlockSelection
        type="checkbox"
        inputClassName="checkbox"
        meta={meta}
        input={input}
        className={className}
        disabled={disabled}
        {...props}
      >
        {children}
      </BlockSelection>
    </InputBlockSize>
  );
};

BlockCheckbox.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

BlockCheckbox.defaultProps = {
  id: null,
  className: null,
  disabled: false,
  size: null,
};

export default BlockCheckbox;
