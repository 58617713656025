import { createReducer } from '@reduxjs/toolkit';
import {
  addToSelection,
  removeFromSelection,
  resetSelection,
  setSelection,
  initState,
  selectAll,
} from 'app/display/guideline/common/helpers/catalogTree/selection';

import selectAllAction from 'app/display/common/modules/catalogManager/actions/modal/selectAll';
import setSelectionAction from 'app/display/common/modules/catalogManager/actions/modal/setSelection';
import resetSelectionAction from 'app/display/common/modules/catalogManager/actions/modal/resetSelection';
import removeFromSelectionAction from 'app/display/common/modules/catalogManager/actions/modal/removeFromSelection';
import addToSelectionAction from 'app/display/common/modules/catalogManager/actions/modal/addToSelection';
import initStateAction from 'app/display/common/modules/catalogManager/actions/modal/initState';

export const initialState = initState();

export default createReducer(initialState, builder => {
  builder
    .addCase(initStateAction, (draftState, action) => initState(action.payload))
    .addCase(addToSelectionAction, (draftState, action) => addToSelection(action.payload, draftState))
    .addCase(removeFromSelectionAction, (draftState, action) => removeFromSelection(action.payload, draftState))
    .addCase(resetSelectionAction, draftState => resetSelection(draftState))
    .addCase(setSelectionAction, (draftState, action) => setSelection(action.payload, draftState))
    .addCase(selectAllAction, draftState => selectAll(draftState));
});
