import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';
import styles from 'app/display/common/components/modals/processingConfirmModal/buttons.css';

import { ALT_COLOR_PALETTE, HeadText5, HeadText6, LINK_COLOR_PALETTE } from 'app/common/typography';
import { SELECT_FOLDER_MODAL_CONFIRM_BUTTON } from 'app/common/constants/userflow';

const Buttons = ({ onConfirm, onClose, confirmEnabled, confirmLabel, closeEnabled }) => {
  const closeButtonClassSet = classNames(styles.uppercase, { [styles.defaultCursor]: !closeEnabled });
  const confirmButtonClassSet = classNames(styles.uppercase, { [styles.defaultCursor]: !confirmEnabled });
  return (
    <>
      <div onClick={onClose} className={styles.firstButton}>
        <HeadText6
          className={closeButtonClassSet}
          interactive={true}
          palette={ALT_COLOR_PALETTE}
          disabled={closeEnabled === false}
        >
          {i18next.t('button.cancel')}
        </HeadText6>
      </div>
      <div onClick={onConfirm} data-testid={SELECT_FOLDER_MODAL_CONFIRM_BUTTON}>
        <HeadText5
          className={confirmButtonClassSet}
          interactive={true}
          palette={LINK_COLOR_PALETTE}
          disabled={confirmEnabled === false}
        >
          {confirmLabel || i18next.t('button.confirm')}
        </HeadText5>
      </div>
    </>
  );
};

Buttons.propTypes = {
  onClose: PropTypes.func.isRequired,
  closeEnabled: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmEnabled: PropTypes.bool.isRequired,
  confirmLabel: PropTypes.string,
};

Buttons.defaultProps = {
  confirmLabel: null,
};

export default Buttons;
