import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Mask from 'app/common/components/mask';

import isAppNavigationOpen from 'app/common/selectors/isAppNavigationOpen';
import useSmallScreen from 'app/common/hooks/useSmallScreen';

import { APPSHELL } from 'app/common/constants/userflow';
import styles from './globalMask.css';

const useGlobalMask = () => {
  const isSmallScreen = useSmallScreen();
  const appNavigationIsOpen = useSelector(isAppNavigationOpen);

  return { isMaskActive: appNavigationIsOpen && isSmallScreen };
};

function GlobalMask({ children }) {
  const { isMaskActive } = useGlobalMask();
  const globalMaskClass = classNames(styles.mask, { [styles.showOffCanvas]: isMaskActive });
  return (
    <Mask className={globalMaskClass} dim={isMaskActive} data-testid={APPSHELL}>
      {children}
    </Mask>
  );
}

GlobalMask.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalMask;
