import React from 'react';
import classNames from 'classnames';

import AppToggleButton from 'app/common/components/appToggleButton';
import useCurrentApplicationModule from 'app/common/hooks/useCurrentApplicationModule';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import ActiveApp from './activeApp';

import Drawer from './drawer';
import styles from './index.css';

export const useNavSwitch = () => {
  const isSmallScreen = useSmallScreen();
  const module = useCurrentApplicationModule();

  return {
    noActiveApp: module == null || isSmallScreen,
    noBorder: isSmallScreen,
  };
};

/**
 * @example ./index.md
 */
export default function NavSwitch() {
  const { noActiveApp, noBorder } = useNavSwitch();

  const toggleClass = classNames(styles.toggle, {
    [styles.noActiveApp]: noActiveApp,
    [styles.noBorder]: noBorder,
  });

  return (
    <div className={styles.switch}>
      <div className={toggleClass}>
        <AppToggleButton />
        <ActiveApp />
      </div>
      <Drawer />
    </div>
  );
}
