import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { LegendText2, ALT_COLOR_PALETTE } from 'app/common/typography';
import { COLOR_PRIMARY } from 'app/common/style/colors';
import styles from './link.css';

export default function DrawerLink({ NavLink, icon, className, children }) {
  return (
    <NavLink className={classNames(styles.link, className)}>
      <div className={styles.icon}>{icon}</div>
      <LegendText2 className={styles.label} palette={ALT_COLOR_PALETTE} on={COLOR_PRIMARY}>
        {children}
      </LegendText2>
    </NavLink>
  );
}

DrawerLink.propTypes = {
  NavLink: PropTypes.elementType.isRequired,
  className: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};
