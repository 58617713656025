import { useSelector } from 'react-redux';
import useLegacyAcl from 'app/common/hooks/useLegacyAcl';
import hasCurrentUserNetworkAccess from 'app/common/user/selectors/hasCurrentUserNetworkAccess';
import isCurrentUserAllowedToAccessStore from 'app/common/selectors/acl/isCurrentUserAllowedToAccessStore';
import useInstanceSettingsDiscoveryPage from 'app/common/hooks/useInstanceSettingsDiscoveryPage';

type StoresPermissions = {
  canAccess: boolean;
  canManage: boolean;
  discoveryPagesEnabled?: boolean;
};

export default function useCurrentUserStoresPermissions(): StoresPermissions {
  const canManage = useLegacyAcl('pos', 'manage');
  const hasNetworkAccess = useSelector(hasCurrentUserNetworkAccess);
  const canAccess = useSelector(isCurrentUserAllowedToAccessStore);
  const { discoveryPagesEnabled } = useInstanceSettingsDiscoveryPage();

  return {
    canAccess: hasNetworkAccess && canAccess,
    canManage: hasNetworkAccess && canAccess && canManage.isAllowed,
    discoveryPagesEnabled,
  };
}
