import { createReducer } from '@reduxjs/toolkit';
import {
  toggleInfoPanel,
  toggleSlideshow,
  toggleFilmStrip,
  displaySelectedPhotoId,
  displayDrawingSVG,
  toggleCommentsPanel,
} from 'app/common/actions/slideshow';

export const initialState: {
  isInfoPanelOpen: boolean;
  isOpen: boolean;
  isFilmStripOpen: boolean;
  selectedPhotoId: string | null | undefined;
  isDrawingVisible: boolean;
  isCommentsPanelOpen: boolean;
} = {
  isInfoPanelOpen: false,
  isOpen: false,
  isFilmStripOpen: true,
  selectedPhotoId: null,
  isDrawingVisible: false,
  isCommentsPanelOpen: false,
};

export default createReducer(initialState, builder => {
  builder
    .addCase(toggleInfoPanel, draftState => {
      draftState.isInfoPanelOpen = !draftState.isInfoPanelOpen;
      draftState.isCommentsPanelOpen = false;
    })
    .addCase(toggleCommentsPanel, draftState => {
      draftState.isCommentsPanelOpen = !draftState.isCommentsPanelOpen;
      draftState.isInfoPanelOpen = false;
    })
    .addCase(toggleSlideshow, (draftState, action) => {
      draftState.isOpen = !draftState.isOpen;
      if (action.payload) {
        draftState.selectedPhotoId = action.payload;
      }
    })
    .addCase(displayDrawingSVG, draftState => {
      draftState.isDrawingVisible = !draftState.isDrawingVisible;
    })
    .addCase(toggleFilmStrip, draftState => {
      draftState.isFilmStripOpen = !draftState.isFilmStripOpen;
    })
    .addCase(displaySelectedPhotoId, (state, action) => {
      state.selectedPhotoId = action.payload;
    });
});
