import notifyError from 'app/display/common/helpers/notifications/notifyError';
import notifySuccess from 'app/display/common/helpers/notifications/notifySuccess';
import { useTranslation } from 'react-i18next';

const NOTIFICATION_SUCCESS = 'success';
const NOTIFICATION_ERRROR = 'error';

type NotificationOptions = {
  shouldTranslate?: boolean;
};

type NotificationEvent = {
  type: typeof NOTIFICATION_SUCCESS | typeof NOTIFICATION_ERRROR;
  message: string;
  options: NotificationOptions;
};

export default function useHandleNotification() {
  const { t } = useTranslation();

  return function handleNotification(event: CustomEvent<NotificationEvent>) {
    const message = event.detail.options?.shouldTranslate ? t(event.detail.message) : event.detail.message;

    if (event.detail.type === NOTIFICATION_ERRROR) {
      notifyError(message);
    }

    if (event.detail.type === NOTIFICATION_SUCCESS) {
      notifySuccess(message);
    }
  };
}
