import isCurrentUserAllowedToUpdateItem from 'app/common/selectors/acl/isCurrentUserAllowedToUpdateItem';
import isLoadingItem from 'app/display/catalog/sheet/common/selectors/isLoadingItem';
import getCurrentItem from 'app/display/catalog/common/selectors/getCurrentItem';

export default function isEditItemRouteAccessible(state) {
  const isLoading = isLoadingItem(state);
  if (isLoading) {
    return true;
  }

  const item = getCurrentItem(state);
  if (!item) {
    return true;
  }

  return isCurrentUserAllowedToUpdateItem(state) && !item.state.archived;
}
