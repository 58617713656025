import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';

export default function useHttpGetCsvRequest<ResponseData, RequestData = unknown>(url: string) {
  return useHttpGetRequest<ResponseData, RequestData>(url, {
    getHeaders: true,
    responseType: 'text',
    headers: {
      Accept: 'text/csv',
    },
  });
}
