import { AnyAction } from 'redux';
import { createAction, ThunkDispatch } from '@reduxjs/toolkit';
import log from 'lib/helpers/logger';
import { AppState } from 'lib/providers/store';
import { LOAD, LOAD_SUCCESS, LOAD_ERROR } from 'app/common/actions/iwdConfigTypes';
import validateConfig from 'app/common/helpers/validateConfig';
import fetchIwdConfig from 'app/common/actions/fetchIwdConfig';

export const loadAction = createAction(LOAD);
export const loadSuccessAction = createAction<{ config: IwdConfig }>(LOAD_SUCCESS);
export const loadErrorAction = createAction<{ error: any }>(LOAD_ERROR);

export type IwdConfig = {
  instance: {
    name: string;
    baseUrl: string;
    apiUrl: string;
    logoUrl: string;
  };
  inventoryTabs: {
    inventoryName: string;
    name: string;
    tradKey: string;
  }[];
  hasVisit: boolean;
  hasMassplan: boolean;
};

export default function loadConfig(): (dispatch: ThunkDispatch<AppState, any, AnyAction>) => Promise<void> {
  return async dispatch => {
    try {
      dispatch(loadAction());
      const config = await dispatch(fetchIwdConfig());

      try {
        validateConfig(config);
        dispatch(loadSuccessAction({ config }));
      } catch (error) {
        log.error(error);
        dispatch(loadErrorAction({ error }));
      }
    } catch (error) {
      dispatch(loadErrorAction({ error }));
      throw error;
    }
  };
}
