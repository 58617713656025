import State from 'domain/state';
import Period from 'domain/period';
import deprecated from 'lib/deprecated';
import DateFormatter from 'lib/date/dateFormatter';

export type CampaignMarket = {
  id: string;
};

export default class GuidelineCampaign {
  constructor(
    public id: string,
    public name: string,
    public description: string,
    public markets: CampaignMarket[],
    public state: State,
    public code: string | null = null,
    public readonly period: Period | null = null,
    public idv4: number | null = null
  ) {
    // do nothing.
  }

  public hasDates() {
    return this.period?.startDate != null && this.period.endDate != null;
  }

  /**
   * @deprecated use `period.startDate` instead
   */
  @deprecated()
  get startDate(): string | null {
    return this.period != null ? DateFormatter.formatForApiPayload(this.period.startDate) : null;
  }

  /**
   * @deprecated use `period.endDate` instead
   */
  @deprecated()
  get endDate(): string | null {
    return this.period != null ? DateFormatter.formatForApiPayload(this.period.endDate) : null;
  }
}
