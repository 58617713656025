import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowIcon from 'assets/icons/arrowthin-down.svg';
import styles from 'app/display/common/components/collapsePanel/index.css';
import CollapsePanel from 'app/common/components/collapsePanel/uncontrolled';
import { HeadText3, TextHoverArea } from 'app/common/typography';

class DisplayUncontrolledCollapsePanel extends PureComponent {
  static propTypes = {
    title: PropTypes.node.isRequired,
    isSubCollapsed: PropTypes.bool,
    isOpen: PropTypes.bool,
    children: PropTypes.node,
    classNameContent: PropTypes.string,
  };

  static defaultProps = {
    isSubCollapsed: false,
    isOpen: true,
    children: undefined,
    classNameContent: null,
  };

  renderHeader = isOpen => {
    const headClassSet = classNames({
      [styles.isOpen]: isOpen === true,
      [styles.header]: !this.props.isSubCollapsed,
      [styles.headerSubCollapse]: this.props.isSubCollapsed,
    });
    return (
      <TextHoverArea className={headClassSet}>
        <ArrowIcon className={styles.icon} />
        <HeadText3 className={classNames(styles.uppercase, styles.title)}>{this.props.title}</HeadText3>
      </TextHoverArea>
    );
  };

  render() {
    return (
      <CollapsePanel
        isOpen={this.props.isOpen}
        renderHeader={this.renderHeader}
        classNameContent={this.props.classNameContent}
      >
        {this.props.children}
      </CollapsePanel>
    );
  }
}

export default DisplayUncontrolledCollapsePanel;
