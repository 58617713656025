import { useImperativeHandle, useState } from 'react';

/**
 * Returns a ref callback, and the DOM node to which the latter is bind. This is especially useful when you
 * want to be notified when the DOM node changes.
 *
 * Optionally you can provide a ref as parameter, it will be automatically bound to the DOM node to which
 * the ref callback is bind. This may be especially useful when you have a component with a forwarded ref.
 *
 * @param {RefCallback|RefObject|null} ref
 * @return {{setRef: RefCallback, domNode: Element|null}}
 */
export default function useRefCallback(ref = null) {
  const [domNode, setDomNode] = useState(null);

  // Automatically binds the DOM node to the given ref
  useImperativeHandle(ref, () => domNode, [domNode]);

  return { setRef: setDomNode, domNode };
}
