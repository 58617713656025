import {
  SELECT_ALL_MARKETS_FORM,
  DESELECT_ALL_MARKETS_FORM,
  DESELECT_BULK_MARKETS_FORM,
} from 'app/common/actions/markets/form';

function updateMarkets(state, markets) {
  return {
    ...state,
    values: {
      ...state.values,
      markets,
    },
    fields: {
      ...(state.fields || {}),
      markets: {
        ...(state.fields?.markets || {}),
        // Mark the field as touched for potential errors to be displayed
        touched: true,
      },
    },
  };
}

function updateMarketsSelection(state, markets, marketIdsToChange, checked) {
  const newMarkets = Object.entries(markets).reduce((acc, [key, market]) => {
    if (marketIdsToChange.includes(market.id)) {
      acc[key] = { ...market, available: checked };
    } else {
      acc[key] = { ...market };
    }
    return acc;
  }, {});

  return updateMarkets(state, newMarkets);
}

export default function marketsSelectionsFormReducerPlugin(state, { type, markets, ...action }) {
  switch (type) {
    case SELECT_ALL_MARKETS_FORM:
      return updateMarketsSelection(state, markets, action.availableMarketIds, true);
    case DESELECT_ALL_MARKETS_FORM:
      return updateMarketsSelection(state, markets, action.availableMarketIds, false);
    case DESELECT_BULK_MARKETS_FORM:
      return updateMarketsSelection(state, markets, action.marketIdsToDeselect, false);
    default:
      return state;
  }
}
