import React from 'react';
import i18next from 'i18next';
import RightsIcon from 'assets/icons/rights.svg';
import { PATH_ACL_INDEX } from 'config/routePaths';
import { USER_MENU_ACL } from 'app/common/constants/userflow';
import { BodyText2 } from 'app/common/typography';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';

import styles from './index.css';

const Rights = () => (
  <UserMenuLink to={PATH_ACL_INDEX} dataTestId={USER_MENU_ACL}>
    <UserMenuIcon IconComponent={RightsIcon} />
    <BodyText2 className={styles.uppercase}>{i18next.t('display.user.popup.link.rights')}</BodyText2>
  </UserMenuLink>
);

export default Rights;
