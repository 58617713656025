import React from 'react';
import PropTypes from 'prop-types';
import styles from 'app/display/common/components/actionBar/index.css';

/**
 * The action bar component.
 *
 * @example ./index.md
 */
const ActionBar = ({ leftGroup, centerGroup, rightGroup }) => (
  <div className={styles.wrapper}>
    <div className={styles.leftGroup}>{leftGroup}</div>
    <div className={styles.centerGroup}>{centerGroup}</div>
    <div className={styles.rightGroup}>{rightGroup}</div>
  </div>
);

ActionBar.propTypes = {
  /**
   * Slot for the left part of the action bar.
   */
  leftGroup: PropTypes.node,
  /**
   * Slot for the center part of the action bar.
   */
  centerGroup: PropTypes.node,
  /**
   * Slot for the right part of the action bar.
   */
  rightGroup: PropTypes.node,
};

ActionBar.defaultProps = {
  leftGroup: undefined,
  centerGroup: undefined,
  rightGroup: undefined,
};

export default ActionBar;
