import { INSTANCE_SETTINGS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';

const useInstanceSettings = (options = {}) => {
  const fetchInstanceSettings = useHttpGetRequest('/privates/instance/settings');
  const { data, isLoading, isError, error } = useHttpQuery(
    [INSTANCE_SETTINGS_CACHE_KEY],
    fetchInstanceSettings,
    options
  );
  const sftpImportReportEmails = data?.sftpImportReportEmails?.toString().split(',').join('; ');
  const instanceSettings = { ...data, sftpImportReportEmails };

  return { instanceSettings, isLoading, isError, error };
};

export default useInstanceSettings;
