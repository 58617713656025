import React from 'react';
import { addToolbarButton, PluginProps } from 'yet-another-react-lightbox';
import Drawing from 'app/common/components/slideshow/drawing/icon';

export const SLIDESHOW_DRAWING_PLUGIN_ID = 'slideshowDrawingPlugin';

export default function DrawingPlugin({ augment }: PluginProps) {
  augment(({ toolbar, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_DRAWING_PLUGIN_ID, <Drawing />),
      ...restProps,
    };
  });
}
