import { connect } from 'react-redux';
import LinkStoreOrigin from 'app/common/components/linkStoreOrigin';
import { getCurrentLocation } from 'app/common/selectors/router';

const mapStateToProps = state => ({
  origin: getCurrentLocation(state),
});

// We pass {} as dispatchToProp to connect
// to avoid passing dispatch as prop to LinkStoreOrigin
export default connect(mapStateToProps, {})(LinkStoreOrigin);
