import React from 'react';
import classNames from 'classnames';
import ExportIcon from 'assets/icons/export.svg';
import Button from 'app/display/common/components/button';
import styles from './button.css';

export const GUIDELINE_TAG_DOWNLOAD_BUTTON = 'guidelineTagDownloadButton';

type DownloadButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

const DownloadButton = ({ onClick, disabled = false }: DownloadButtonProps) => {
  const iconClassSet = classNames(styles.icon, {
    [styles.isDisabled]: disabled,
  });
  return (
    <Button
      type="button"
      className={styles.button}
      onClick={onClick}
      isDisabled={disabled}
      dataTestId={GUIDELINE_TAG_DOWNLOAD_BUTTON}
    >
      <ExportIcon className={iconClassSet} />
    </Button>
  );
};

export default DownloadButton;
