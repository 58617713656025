import React from 'react';
import PropTypes from 'prop-types';
import IconCheckedbox from 'assets/icons/checkedbox.svg';
import { BodyText1 } from 'app/common/typography';
import { COLOR_SUCCESS } from 'app/common/style/colors';
import { NOTIFICATION_SUCCESS } from 'app/common/constants/userflow';
import styles from './success.css';

const Success = ({ children }) => (
  <>
    <IconCheckedbox className={styles.iconCheckedBox} />
    <BodyText1 on={COLOR_SUCCESS} dataTestId={NOTIFICATION_SUCCESS}>
      {children}
    </BodyText1>
  </>
);

Success.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Success;
