import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { composeWithDevTools } from 'redux-devtools-extension';
import log from 'lib/helpers/logger';
import router from 'lib/router';
import reducers from 'app/reducers';
import createSingleton from 'lib/helpers/createSingleton';

function createAppStore() {
  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  });

  const store = createStore(
    reducers.getInstance(),
    composeEnhancers(
      responsiveStoreEnhancer,
      // see https://github.com/gaearon/redux-thunk
      applyMiddleware(thunkMiddleware),
      // plug the router history to the redux store
      applyMiddleware(router.getInstance().routerMiddleware)
    )
  );

  log.connectStore(store);

  return store;
}

export default createSingleton(createAppStore);
