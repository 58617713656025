import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponseTransformer, Method } from 'axios';

export type RequestOptions = Omit<AxiosRequestConfig<never>, 'url' | 'method' | 'data'>;
export type PostRequestOptions = Omit<AxiosRequestConfig, 'url' | 'method'>;

type QueryOptions = Omit<AxiosRequestConfig, 'url'>;

export default class RequestParams<T = unknown> {
  constructor(private url: string, private method: Method, private options: RequestOptions = {}, private data: T) {
    // do nothing.
  }

  getUrl(): string {
    return this.url;
  }

  setUrl(url: string) {
    this.url = url;
  }

  getData(): T {
    return this.data;
  }

  setData(data: any): void {
    this.data = data;
  }

  buildQueryOptions(): QueryOptions {
    const options: QueryOptions = {
      ...this.options,
      method: this.method,
    };

    if (this.data !== undefined) {
      options.data = this.data;
    }

    if (options.transformResponse !== undefined) {
      options.transformResponse = RequestParams.buildQueryOptionTransformResponse(options.transformResponse);
    }

    return options;
  }

  private static buildQueryOptionTransformResponse(
    transformResponse: AxiosResponseTransformer | AxiosResponseTransformer[]
  ): AxiosResponseTransformer | AxiosResponseTransformer[] {
    const transformNonNullResponse = Array.isArray(transformResponse)
      ? transformResponse.map(RequestParams.buildQueryOptionTransformSuccessResponse)
      : RequestParams.buildQueryOptionTransformSuccessResponse(transformResponse);

    return RequestParams.buildQueryOptionTransformResponseWithDefaults(transformNonNullResponse);
  }

  private static buildQueryOptionTransformResponseWithDefaults(
    transformResponse: AxiosResponseTransformer | AxiosResponseTransformer[]
  ): AxiosResponseTransformer | AxiosResponseTransformer[] {
    // We must inject the default Axios response transformers in order to preserve JSON decode (amongst others...)
    // See https://github.com/axios/axios/issues/430
    if (axios.defaults.transformResponse !== undefined) {
      return ([] as AxiosResponseTransformer[]).concat(axios.defaults.transformResponse, transformResponse);
    }

    return transformResponse;
  }

  private static buildQueryOptionTransformSuccessResponse(
    transformResponse: AxiosResponseTransformer
  ): AxiosResponseTransformer {
    return (response: any, headers?: AxiosRequestHeaders): any => {
      if (RequestParams.isResponseEmpty(response) || RequestParams.isResponseNotOk(response)) {
        return response;
      }
      return transformResponse(response, headers);
    };
  }

  private static isResponseEmpty(response: any): boolean {
    return response == null;
  }

  private static isResponseNotOk(response: any): boolean {
    return response?.httpStatusCode && response?.httpStatusCode !== 200;
  }
}
