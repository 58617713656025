import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import isAppNavigationOpen from 'app/common/selectors/isAppNavigationOpen';
import closeAppNavigation from 'app/common/actions/appNavigation/close';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import useAction from 'app/display/common/hooks/useAction';
import usePlatform from 'app/common/hooks/usePlatform';

export default function useOffCanvasPlatformMenu() {
  const { isEnabled: isPlatformMenuEnabled } = usePlatform();
  const isSmallScreen = useSmallScreen();
  const appNavigationIsOpen = useSelector(isAppNavigationOpen);
  const closeNavigation = useAction(closeAppNavigation);

  useEffect(() => {
    if (appNavigationIsOpen && !isSmallScreen && isPlatformMenuEnabled) {
      closeNavigation();
    }
  }, [appNavigationIsOpen, isSmallScreen, isPlatformMenuEnabled, closeNavigation]);

  return { closeNavigation, isVisible: appNavigationIsOpen && isSmallScreen && isPlatformMenuEnabled };
}
