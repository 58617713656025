import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import isUserMenuVisible from 'app/common/selectors/appShell/isUserMenuVisible';
import useAction from 'app/display/common/hooks/useAction';
import toggleUserMenuVisibility from 'app/common/components/actions/toggleUserMenuVisibility';

type UserMenu = {
  isVisible: boolean;
  menuRef: React.RefObject<HTMLElement>;
};

const useUserMenu = (): UserMenu => {
  const menuRef = useRef<HTMLElement>(null);
  const isVisible = useSelector(isUserMenuVisible);
  const closeMenu = useAction(toggleUserMenuVisibility);

  const handleCloseUserMenu = useCallback(
    (clickEvent: any) => {
      if (isVisible) {
        const notInMenu = !menuRef.current || !menuRef.current.contains(clickEvent.target);
        if (notInMenu) {
          // ensure that other clicks trigger non-desirable actions
          clickEvent.stopPropagation();
          clickEvent.preventDefault();
        }
        closeMenu();
      }
    },
    [closeMenu, isVisible]
  );

  useEffect(() => {
    if (isVisible) {
      document.body.addEventListener('click', handleCloseUserMenu, true);
    }

    return () => {
      document.body.removeEventListener('click', handleCloseUserMenu, true);
    };
  }, [handleCloseUserMenu, isVisible]);

  return { isVisible, menuRef };
};

export default useUserMenu;
