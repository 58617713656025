import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import DisplayRoute from 'app/display/common/components/route/display';
import NotFound from 'app/common/components/errors/notFound';
import Spinner from 'app/common/components/spinner';
import catcher from 'app/common/helpers/importCatcher';
import Editor from 'app/display/catalog/sheet/editor';
import {
  PATH_CATALOG_BROWSE,
  PATH_CATALOG_CREATE,
  PATH_CATALOG_EDIT,
  PATH_CATALOG_EDITOR,
  PATH_CATALOG_SEARCH,
  PATH_CATALOG_SHOW,
  PATH_CATALOG_VARIANT_EDIT,
  PATH_DISCOVER,
} from 'config/routePaths';
import DisplayAccessControl from 'app/common/components/accessControl/display';

import { DISCOVER_CATALOG } from 'app/discover/constants/moduleNames';
import generatePathname from 'lib/helpers/router/generatePathname';

import useCatalogRoute from 'app/display/catalog/hooks/useCatalogRoute';

const List = Loadable({
  loader: () =>
    // eslint-disable-next-line import/no-cycle
    import(/* webpackChunkName: "display-catalog-list" */ 'app/display/catalog/list/routes').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/catalog/list/routes'),
});

export const Details = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-catalog-item" */ 'app/display/catalog/sheet/view').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/catalog/sheet/view'),
});

const Create = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-catalog-create" */ 'app/display/catalog/sheet/create').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/catalog/sheet/create'),
});

const Edit = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-catalog-edit" */ 'app/display/catalog/sheet/edit').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/catalog/sheet/edit'),
});

const EditVariant = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-catalog-variant-edit" */ 'app/display/catalog/variants/edit').catch(e =>
      catcher(e)
    ),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/catalog/variants/edit'),
});

const Routes = () => {
  const { isLoading, createItemIsAllowed, editItemIsAllowed } = useCatalogRoute();

  if (isLoading) {
    return <Spinner />;
  }

  const catalogDiscoverURL = generatePathname(PATH_DISCOVER, {
    moduleName: DISCOVER_CATALOG,
  });

  return (
    <DisplayAccessControl right="catalog.item.access" redirectTo={catalogDiscoverURL} redirectDelay={0}>
      <Switch>
        <DisplayRoute exact path={PATH_CATALOG_BROWSE} component={List} />
        <DisplayRoute path={PATH_CATALOG_SEARCH} component={List} />
        <DisplayRoute
          path={PATH_CATALOG_CREATE}
          component={Create}
          isAuthorized={createItemIsAllowed}
          redirectTo={PATH_CATALOG_BROWSE}
        />
        <DisplayRoute
          path={PATH_CATALOG_EDIT}
          component={Edit}
          isAuthorized={editItemIsAllowed}
          redirectTo={PATH_CATALOG_BROWSE}
        />
        <DisplayRoute path={PATH_CATALOG_EDITOR} component={Editor} redirectTo={PATH_CATALOG_BROWSE} />
        <DisplayRoute
          path={PATH_CATALOG_VARIANT_EDIT}
          component={EditVariant}
          isAuthorized={editItemIsAllowed}
          redirectTo={PATH_CATALOG_BROWSE}
        />
        <DisplayRoute path={PATH_CATALOG_SHOW} component={Details} />
        <DisplayRoute component={NotFound} />
      </Switch>
    </DisplayAccessControl>
  );
};

export default Routes;
