import omit from 'lodash/omit';
import { actionTypes } from 'redux-form';

const FACING_FIELDS = ['thumbnail', 'generateFacing'];
const FACING_ERROR_KEY = 'facing';

function clearFacingSubmitErrorOnFacingFieldsChange(state, action) {
  const isActionOnFacingField = FACING_FIELDS.includes(action.meta.field);
  const hasSubmitErrorOnFacing = state.submitErrors?.[FACING_ERROR_KEY] != null;

  if (isActionOnFacingField && hasSubmitErrorOnFacing) {
    return {
      ...state,
      submitErrors: omit(state.submitErrors, FACING_ERROR_KEY),
    };
  }

  return state;
}

export default function itemFacingErrorFormReducerPlugin(state, { type, ...action }) {
  switch (type) {
    case actionTypes.CHANGE:
      return clearFacingSubmitErrorOnFacingFieldsChange(state, action);
    default:
      return state;
  }
}
