import {
  UPLOAD_FILES_START,
  UPLOAD_FILES_DONE,
  UPLOAD_FILE_ERROR,
  INCREMENT_UPLOAD_PROGRESS_LOADED,
  SET_UPLOAD_PROGRESS_TOTAL,
  IMPORT_RESET,
} from 'app/display/import/actions';
import { EXCEPTION_CODE_IMPORT_INVALID_FILE_NAME } from 'app/common/actions/api/exception';

export const initialState = {
  isUploading: false,
  hasUploadError: false,
  invalidNameErrors: [],
  filesCount: 0,
  progressLoaded: 0,
  progressTotal: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILES_START:
      return {
        ...state,
        isUploading: true,
        hasUploadError: false,
        invalidNameErrors: [],
        filesCount: action.filesCount,
      };
    case UPLOAD_FILES_DONE:
      return {
        ...initialState,
        hasUploadError: state.hasUploadError,
        invalidNameErrors: state.invalidNameErrors,
      };
    case UPLOAD_FILE_ERROR:
      if (action.error?.response?.data?.code === EXCEPTION_CODE_IMPORT_INVALID_FILE_NAME) {
        return {
          ...state,
          invalidNameErrors: [
            ...state.invalidNameErrors,
            { error: action.error.response.data.message, name: action.file.name },
          ],
        };
      }
      return {
        ...state,
        hasUploadError: true,
      };
    case INCREMENT_UPLOAD_PROGRESS_LOADED:
      return {
        ...state,
        progressLoaded: state.progressLoaded + action.progressLoaded,
      };
    case SET_UPLOAD_PROGRESS_TOTAL:
      return {
        ...state,
        progressTotal: action.progressTotal,
      };
    case IMPORT_RESET:
      return initialState;
    default:
      return state;
  }
}
