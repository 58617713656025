import axios from 'axios';
import { query } from 'app/common/actions/api';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import { AUTH_CLEAR_DATA } from 'app/common/reducers/auth';
import { getParameters } from 'app/common/selectors/url';

export default function logout() {
  return async (dispatch, getState) => {
    // ATM we call the API logout endpoint AND the legacy one to be sure to logout from both envs.
    // TODO remove this call and redirection when the auth system is fully implemented in the webapp.
    const baseUrl = getInstanceBaseUrl(getState());
    const searchParams = getParameters(getState());

    let redirectUrl = baseUrl;
    try {
      const response = await dispatch(query('auth/logout', { method: 'post', getHeaders: true }));
      redirectUrl = response.headers.location || redirectUrl;
    } catch (exception) {
      console.error(exception);
    }

    await axios.get(`${baseUrl}/data/auth/logout`);

    dispatch({ type: AUTH_CLEAR_DATA });
    window.location.href = `${redirectUrl}${searchParams?.challenge ? `?challenge=${searchParams.challenge}` : ''}`;
  };
}
