import React from 'react';
import { addToolbarButton, PluginProps, useLightboxState } from 'yet-another-react-lightbox';
import classNames from 'classnames';
import ValidationIcon from 'assets/icons/icons-validation.svg';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useIsCurrentUserAllowedToApproveSlideshowPhotos from 'app/common/hooks/acl/useIsCurrentUserAllowedToApproveSlideshowPhotos';
import useManageApprovePhoto from 'app/network/common/hooks/useManageApprovePhoto';
import hasUserApprovedImage from 'app/common/helpers/slideshow/hasUserApprovedImage';
import SlideshowApprovalsTag from 'app/common/components/slideshow/approvePhoto/statusTag';
import styles from './index.css';

export const SLIDESHOW_VALIDATION_TESTID = 'slideshowValidationButton';
export const SLIDESHOW_APPROVE_PHOTO_PUGIN_ID = 'ApproveButton';

function ApprovePhoto() {
  const { currentSlide } = useLightboxState();
  const { currentUser } = useCurrentUser();
  const canUserApprovePhoto = useIsCurrentUserAllowedToApproveSlideshowPhotos();

  const hasUserApprovedPhoto = canUserApprovePhoto && hasUserApprovedImage(currentUser, currentSlide);
  const manageApprovalPhoto = useManageApprovePhoto(currentSlide, hasUserApprovedPhoto);

  return canUserApprovePhoto ? (
    <div className={styles.validationButton}>
      <button
        className={classNames('yarl__button', styles.icon)}
        type="button"
        onClick={manageApprovalPhoto}
        data-testid={SLIDESHOW_VALIDATION_TESTID}
      >
        <SlideshowButton Icon={ValidationIcon} />
      </button>
    </div>
  ) : null;
}

export default function ApprovePhotoPlugin({ augment }: PluginProps) {
  augment(({ toolbar, statusTags, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_APPROVE_PHOTO_PUGIN_ID, <ApprovePhoto />),
      statusTags: {
        ...statusTags,
        [SLIDESHOW_APPROVE_PHOTO_PUGIN_ID]: SlideshowApprovalsTag,
      },
      ...restProps,
    };
  });
}
