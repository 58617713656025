import React from 'react';
import { useSelector } from 'react-redux';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import isLoadingBarVisible from 'app/common/selectors/isLoadingBarVisible';
import LoadingBar from 'app/common/components/loading/bar';
import isModalOpen from 'app/common/selectors/isModalOpen';

const AppShellLoadingBar = () => {
  const isVisible = useSelector(isLoadingBarVisible);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const isOpenModal = useSelector(isModalOpen);

  if (isOpenModal) {
    return null;
  }

  if (!isVisible && !isFetching && !isMutating) {
    return null;
  }

  return <LoadingBar />;
};

export default AppShellLoadingBar;
