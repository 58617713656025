import React from 'react';
import FullUser from 'domain/user/fullUser';
import BasicUser from 'domain/user/basicUser';
import Avatar from './avatar';
import IwdUserAvatar from './iwdUserAvatar';

type UserAvatarType = {
  user: FullUser | BasicUser;
  className?: string;
};

const FullUserAvatar = ({ user, className }: UserAvatarType) => {
  if (user.isIwd) {
    return <IwdUserAvatar className={className} />;
  }
  return <Avatar name={user.name} url={user.photoUrl} className={className} />;
};

export default FullUserAvatar;
