import array2map from 'lib/helpers/array2map';
import { STATUS_SUCCESS, STATUS_LOADING, STATUS_ERROR } from 'app/common/reducers/status';
import {
  FETCH_CAMPAIGNS_START,
  FETCH_CAMPAIGNS_SUCCESS,
  FETCH_CAMPAIGNS_ERROR,
  ADD_CAMPAIGNS,
  UPDATE_CAMPAIGN,
  REMOVE_CAMPAIGNS,
} from 'app/display/guideline/common/actions';

export const initialState = {
  status: undefined,
  campaignsById: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CAMPAIGNS_START: {
      return {
        ...state,
        status: STATUS_LOADING,
      };
    }
    case ADD_CAMPAIGNS: {
      const campaignsById = array2map(action.campaigns, 'id');
      return {
        ...state,
        campaignsById,
      };
    }
    case UPDATE_CAMPAIGN: {
      return {
        ...state,
        campaignsById: {
          ...state.campaignsById,
          [action.campaign.id]: action.campaign,
        },
      };
    }
    case FETCH_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        status: STATUS_SUCCESS,
      };
    }
    case FETCH_CAMPAIGNS_ERROR: {
      return {
        ...state,
        status: STATUS_ERROR,
      };
    }
    case REMOVE_CAMPAIGNS: {
      const campaignsById = { ...state.campaignsById };
      action.campaigns.forEach(campaign => {
        delete campaignsById[campaign.id];
      });
      return {
        ...state,
        campaignsById,
      };
    }
    default:
      return state;
  }
}
