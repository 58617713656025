import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import DEFAULT_SEARCH_STRING from 'app/display/catalog/common/constants';
import {
  CLEAR_ITEMS,
  CREATE_ITEM_ERROR,
  CREATE_ITEM_SUCCESS,
  DUPLICATE_ITEM_SUCCESS,
  DUPLICATE_ITEMS_SUCCESS,
  PATCH_ITEM,
  PATCH_ITEMS,
  SET_CURRENT_ITEM,
  UPDATE_ITEM_DISPLAY_SUCCESS,
  UPDATE_ITEM_ERROR,
  UPDATE_ITEM_FILES_SUCCESS,
  UPDATE_ITEM_MARKETS_SUCCESS,
  UPDATE_ITEM_SUCCESS,
} from 'app/display/catalog/common/actions/items';

export const FETCH_ITEMS_BROWSE = 'catalog.fetch.items.browse';
export const FETCH_ITEMS_SEARCH = 'catalog.fetch.items.search';
export const UPDATE_BROWSE_FILTER = 'catalog.update.items.browse.filter';
export const UPDATE_SEARCH_FILTER = 'catalog.update.items.search.filter';
export const FETCH_ITEMS_START = 'catalog.fetch.items.start';
export const FETCH_ITEMS_SUCCESS = 'catalog.fetch.items.success';
export const FETCH_ITEMS_ERROR = 'catalog.fetch.items.error';
export const FETCH_ITEMS_FILTERS_START = 'catalog.fetch.items.filters.start';
export const FETCH_ITEMS_FILTERS_SUCCESS = 'catalog.fetch.items.filters.success';
export const FETCH_ITEMS_FILTERS_ERROR = 'catalog.fetch.items.filters.error';
export const SELECT_ITEM = 'catalog.select.item';
export const DESELECT_ITEM = 'catalog.deselect.item';
export const DESELECT_ALL_ITEMS = 'catalog.deselect.all.items';
export const UPDATE_ITEM_SELECTION = 'catalog.update.item.selection';

export const RESET_ITEM_STATUS = 'catalog.reset.item.status';
export const FETCH_ITEM_START = 'catalog.fetch.item.start';
export const FETCH_ITEM_SUCCESS = 'catalog.fetch.item.success';
export const FETCH_ITEM_ERROR = 'catalog.fetch.item.error';
export const FETCH_ITEM_MARKETDATA_START = 'catalog.fetch.item.marketdata.start';
export const FETCH_ITEM_MARKETDATA_SUCCESS = 'catalog.fetch.item.marketdata.success';
export const FETCH_ITEM_MARKETDATA_ERROR = 'catalog.fetch.item.marketdata.error';
export const UPDATE_ITEMS_SUCCESS = 'catalog.update.items.success';
export const FETCH_CURRENT_ITEM_FACING_SUCCESS = 'catalog.fetchCurrentItemFacing.success';

export const MOVE_ITEMS_ON_DRAG_DROP_START = 'catalog.item.moveOnDragDrop.start';
export const MOVE_ITEMS_ON_DRAG_DROP_SUCCESS = 'catalog.item.moveOnDragDrop.success';
export const MOVE_ITEMS_BULK_ON_DRAG_DROP_START = 'catalog.item.moveBulkOnDragDrop.start';
export const MOVE_ITEMS_BULK_ON_DRAG_DROP_SUCCESS = 'catalog.item.moveBulkOnDragDrop.success';

export const TOGGLE_ITEM_SORTBY_ORDER = 'catalog.toggle.item.sortby.order';

export const SORTBY_ITEM_NAME = 'catalog.sortby.item.name';
export const SORTBY_ITEM_CODE1 = 'catalog.sortby.item.code1';
export const SORTBY_ITEM_LASTUPDATED = 'catalog.sortby.item.lastupdated';
export const ACTION_ITEM_ACTIVATE = 'catalog.action.item.activate';
export const ACTION_ITEM_DEACTIVATE = 'catalog.action.item.deactivate';
export const ACTION_ITEM_DUPLICATE = 'catalog.action.item.duplicate';
export const ACTION_ITEM_EDIT = 'catalog.action.item.edit';
export const ACTION_ITEM_ARCHIVE = 'catalog.action.item.archive';
export const ACTION_ITEM_UNARCHIVE = 'catalog.action.item.unarchive';
export const ACTION_ITEM_LINKTOMARKET = 'catalog.action.item.linktomarket';
export const ACTION_ITEM_LINKTOORDER = 'catalog.action.item.linktoorder';
export const ACTION_ITEMS_ACTIVATE = 'catalog.action.items.activate';
export const ACTION_ITEMS_DEACTIVATE = 'catalog.action.items.deactivate';
export const ACTION_ITEMS_ARCHIVE = 'catalog.action.items.archive';
export const ACTION_ITEMS_UNARCHIVE = 'catalog.action.items.unarchive';
export const ACTION_ITEMS_MANAGE_MARKETS = 'catalog.action.items.manage.markets';
export const ACTION_ITEMS_MOVE = 'catalog.action.items.move';

export const FETCH_MORE_ITEMS_START = 'catalog.fetch.more.items.start';
export const FETCH_MORE_ITEMS_SUCCESS = 'catalog.fetch.more.items.success';
export const FETCH_MORE_ITEMS_ERROR = 'catalog.fetch.more.items.error';

export const initialState = {
  status: STATUS_LOADING,
  list: [],
  current: null,
  aggregations: null,
  browse: null, // keep track of the last filter applied when browsing
  search: DEFAULT_SEARCH_STRING, // keep track of the last filter applied when searching
  filters: null,
  nextUrl: null,
  totalCount: 0,
  freezeList: [],
  areAllFrozen: false,
};

function buildItemForState(item) {
  const markets = item.markets.map(({ id }) => ({ id }));

  return {
    ...item,
    markets,
  };
}

function isCurrentItem(state, item) {
  return state.current != null && state.current.id === item.id;
}

function isItemInList(state, item) {
  return state.list.find(({ id }) => id === item.id) != null;
}

function addItemInList(state, item) {
  if (isItemInList(state, item)) {
    return state;
  }

  return {
    ...state,
    list: [...state.list, item],
  };
}

function updateItemInList(state, item) {
  const itemIndex = state.list.findIndex(({ id }) => id === item.id);
  if (itemIndex === -1) {
    return state;
  }

  const newList = [...state.list];
  newList[itemIndex] = item;

  return {
    ...state,
    list: newList,
  };
}

function setCurrentItem(state, item) {
  return {
    ...state,
    current: item,
  };
}

function freezeItems(state, itemIds) {
  const oldFreezeList = state.freezeList.filter(id => !itemIds.includes(id));
  return { ...state, freezeList: [...oldFreezeList, ...itemIds] };
}

function unfreezeItems(state, itemIds) {
  return {
    ...state,
    freezeList: state.freezeList.filter(id => !itemIds.includes(id)),
  };
}

function createItemAndSetAsCurrent(state, item) {
  const itemToStore = buildItemForState(item);
  let newState = state;

  newState = addItemInList(newState, itemToStore);
  newState = setCurrentItem(newState, itemToStore);

  return newState;
}

function createItem(state, item) {
  const itemToStore = buildItemForState(item);
  return addItemInList(state, itemToStore);
}

function createItems(state, items) {
  return items.reduce(createItem, state);
}

function updateItem(state, item) {
  const itemToStore = buildItemForState(item);
  let newState = state;

  newState = updateItemInList(newState, itemToStore);
  if (isCurrentItem(newState, item)) {
    newState = setCurrentItem(newState, itemToStore);
  }

  return newState;
}

function updateItems(state, items) {
  return items.reduce(updateItem, state);
}

function patchItem(state, itemId, patch) {
  const oldItem = state.list.find(item => item.id === itemId);
  if (oldItem == null) {
    return state;
  }

  const newItem = { ...oldItem, ...patch };

  return updateItem(state, newItem);
}

function patchItems(state, itemIds, patches) {
  return itemIds.reduce((previousState, itemId, index) => {
    return patchItem(previousState, itemId, patches[index]);
  }, state);
}

function removeItems(state, itemIds) {
  const { list, totalCount } = state;
  const listWithoutItems = list.filter(({ id }) => !itemIds.includes(id));
  const deletionCount = list.length - listWithoutItems.length;

  if (deletionCount === 0) {
    return state;
  }

  const newState = {
    ...state,
    list: listWithoutItems,
    totalCount: totalCount - deletionCount,
  };

  return unfreezeItems(newState, itemIds);
}

function removeUnavailableMarketsInItemByMarketsData(state, itemId, itemMarketsData) {
  const item = state.list.find(({ id }) => id === itemId) || state.current;
  if (item == null) {
    return state;
  }

  const isInItemMarketsButNotAvailable = ({ id, available }) =>
    !available && item.markets.some(market => market.id === id);

  const marketsToRemove = itemMarketsData.filter(isInItemMarketsButNotAvailable).map(market => market.id);

  if (marketsToRemove.length === 0) {
    return state;
  }

  const filteredItemMarkets = item.markets.filter(market => !marketsToRemove.includes(market.id));

  return updateItem(state, {
    ...item,
    markets: filteredItemMarkets,
  });
}

function addAvailableMarketsInItemByMarketsData(state, itemId, itemMarketsData) {
  const item = state.list.find(({ id }) => id === itemId) || state.current;
  if (item == null) {
    return state;
  }

  const isAvailableButNotInItemMarkets = ({ id, available }) =>
    available && !item.markets.some(market => market.id === id);

  const marketsToAdd = itemMarketsData.filter(isAvailableButNotInItemMarkets).map(market => ({ id: market.id }));

  if (marketsToAdd.length === 0) {
    return state;
  }

  return updateItem(state, {
    ...item,
    markets: [...item.markets, ...marketsToAdd],
  });
}

function updateItemMarketsByMarketsData(state, itemId, itemMarketsData) {
  let newState = state;

  newState = removeUnavailableMarketsInItemByMarketsData(newState, itemId, itemMarketsData);
  newState = addAvailableMarketsInItemByMarketsData(newState, itemId, itemMarketsData);

  return newState;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEMS_START:
      return {
        ...state,
        status: STATUS_LOADING,
        totalCount: 0,
        list: [],
      };
    case FETCH_ITEMS_ERROR:
      return { ...state, status: STATUS_ERROR };
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        list: action.items,
        aggregations: action.aggregations,
        totalCount: parseInt(action.totalCount, 10),
        nextUrl: action.nextUrl,
      };
    case FETCH_MORE_ITEMS_START:
      return {
        ...state,
        status: STATUS_LOADING,
        nextUrl: null,
      };
    case FETCH_MORE_ITEMS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        list: [...state.list, ...action.items],
        nextUrl: action.nextUrl,
      };
    case FETCH_MORE_ITEMS_ERROR:
      return {
        ...state,
        status: STATUS_ERROR,
        nextUrl: null,
      };
    case FETCH_ITEM_START:
      return { ...state, current: null };
    case FETCH_ITEM_ERROR:
      return { ...state, current: null };
    case FETCH_ITEM_SUCCESS:
    case SET_CURRENT_ITEM:
      return { ...state, current: action.item };
    case FETCH_CURRENT_ITEM_FACING_SUCCESS:
      return updateItem(state, action.item);
    case FETCH_ITEM_MARKETDATA_SUCCESS:
      return updateItemMarketsByMarketsData(state, action.itemId, action.marketData);
    case UPDATE_ITEM_SUCCESS:
      return updateItem(state, action.item);
    case UPDATE_ITEM_ERROR:
      console.error('Failed to properly update the item', action.error);
      return state;
    case UPDATE_ITEM_MARKETS_SUCCESS:
      return updateItemMarketsByMarketsData(state, action.itemId, action.markets);
    case UPDATE_ITEM_FILES_SUCCESS:
    case UPDATE_ITEM_DISPLAY_SUCCESS:
      return updateItem(state, action.item);
    case PATCH_ITEM:
      return patchItem(state, action.id, action.patch);
    case PATCH_ITEMS:
      return patchItems(state, action.itemIds, action.patches);
    case CREATE_ITEM_SUCCESS:
      return createItemAndSetAsCurrent(state, action.item);
    case DUPLICATE_ITEM_SUCCESS:
      return createItem(state, action.item);
    case DUPLICATE_ITEMS_SUCCESS:
      return createItems(state, action.items);
    case CREATE_ITEM_ERROR:
      console.error('Failed to properly create the item', action.error);
      return state;
    case UPDATE_ITEMS_SUCCESS:
    case ACTION_ITEMS_ARCHIVE:
    case ACTION_ITEMS_UNARCHIVE:
      return updateItems(state, action.items);
    case FETCH_ITEMS_FILTERS_START:
      return { ...state, filters: null };
    case FETCH_ITEMS_FILTERS_SUCCESS:
      return { ...state, filters: action.filters };
    case FETCH_ITEMS_FILTERS_ERROR:
      console.error(action.error);
      return { ...state, filters: null };
    case UPDATE_BROWSE_FILTER:
      return { ...state, browse: action.filter };
    case UPDATE_SEARCH_FILTER:
      return { ...state, search: action.filter };
    case CLEAR_ITEMS:
      return {
        ...state,
        list: initialState.list,
        search: initialState.search,
        browse: initialState.browse,
        status: STATUS_SUCCESS,
        nextUrl: null,
        totalCount: initialState.totalCount,
      };
    case MOVE_ITEMS_ON_DRAG_DROP_START:
      return freezeItems(state, action.itemIds);
    case MOVE_ITEMS_ON_DRAG_DROP_SUCCESS:
      return removeItems(state, action.itemIds);
    case MOVE_ITEMS_BULK_ON_DRAG_DROP_START:
      return { ...state, areAllFrozen: true };
    case MOVE_ITEMS_BULK_ON_DRAG_DROP_SUCCESS:
      return { ...state, areAllFrozen: false };
    default:
      return state;
  }
}
