import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * This is the root component connected to the redux state used to show any modal.
 * It excepts a component and an optional props object.
 *
 * Usage:
 * import { showModal } from 'modals/actions';
 * import MyModal from 'path/to/myModal';
 *
 * const optionalProps ={
 *   toto: 'toto',
 * };
 * store.dispatch(showModal(MyModal, optionalProps);
 *
 * @param type Modal's component
 * @param props Modal's additional props
 */
const ModalRoot = ({ modal, props }) => {
  // if no modal component is passed, we render nothing.
  // The action to hide the modal simply set the modal component to null to hide it.
  if (!modal) {
    return null;
  }

  return React.createElement(modal, props);
};

ModalRoot.propTypes = {
  modal: PropTypes.elementType,
  // Impossible to type these props
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object,
};

ModalRoot.defaultProps = {
  modal: null,
  props: null,
};

export default connect(state => state.display.modal)(ModalRoot);
