import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';

export const DashboardPage = React.lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'app/dashboard/components/page')
);

const DashboardModule = () => (
  <Suspense fallback={<Spinner />}>
    <DashboardPage />
  </Suspense>
);

export default DashboardModule;
