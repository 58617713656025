import compareStringUsingNaturalOrder from 'lib/helpers/sort';

interface NamedObject {
  name: string;
}

function compareObjectsByName(object1: NamedObject, object2: NamedObject): number {
  return compareStringUsingNaturalOrder(object1.name, object2.name);
}

export default function sortListByName<T extends NamedObject>(objectList: T[]): T[] {
  return [...objectList].sort(compareObjectsByName);
}
