import axios from 'axios';
import getApiUrl from 'app/common/helpers/api/getApiUrl';

const httpClient = axios.create({
  baseURL: getApiUrl(),
  responseType: 'json',
  method: 'get', // default method
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-IWD-APPLICATION': 'WEB APP',
  },
});

export default httpClient;
