import React from 'react';
import { useTranslation } from 'react-i18next';
import DuplicateIcon from 'assets/icons/duplicate-user.svg';
import Action from 'app/common/components/popup/actions/action';
import useAction from 'app/display/common/hooks/useAction';
import { toggleDirectoryActionsPopup } from 'app/directory/user/sheet/common/actions';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';

export default function DirectoryDuplicateUserAction() {
  const { t } = useTranslation();
  const togglePopup = useAction(toggleDirectoryActionsPopup);
  const duplicateUser = useLegacyEvent('DUPLICATE_USER');
  return (
    <Action
      icon={<DuplicateIcon />}
      label={t('directory.actionsPopup.actions.duplicateUser')}
      onClick={() => {
        togglePopup();
        duplicateUser();
      }}
      dataTestId="duplicateUserAction"
    />
  );
}
