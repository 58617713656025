import React from 'react';
import ErrorBoundary from 'app/common/components/errors/boundary';
import AppQueryClientProvider from 'lib/providers/queryClient';
import AppStoreProvider from 'lib/providers/store';
import AppI18nextProvider from 'lib/providers/i18next';
import AppRouterProvider from 'lib/providers/router';
import AppRoot from 'app/root';
import WebSocketProvider from 'lib/providers/webSocket';

const App = () => (
  <ErrorBoundary>
    <AppQueryClientProvider>
      <AppStoreProvider>
        <WebSocketProvider>
          <AppI18nextProvider>
            <AppRouterProvider>
              <AppRoot />
            </AppRouterProvider>
          </AppI18nextProvider>
        </WebSocketProvider>
      </AppStoreProvider>
    </AppQueryClientProvider>
  </ErrorBoundary>
);
export default App;
