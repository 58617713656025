import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { COLOR_PRIMARY, COLORS_ON } from 'app/common/style/colors';
import colorStyles from 'app/common/style/colors/index.css';

import colorPalettes from 'app/common/typography/colors/palettes';
import { DEFAULT_COLOR_PALETTE } from 'app/common/typography/colors/palettes/default';
import paletteClassName from 'app/common/typography/colors/palettes/helpers/getClassName';
import { FONTS } from '../fonts';
import { SIZES } from '../sizes';

import styles from './style.css';
import fontStyles from '../fonts/index.css';
import sizeStyles from '../sizes/index.css';

export default function StaticText({
  font,
  size,
  on,
  className,
  children,
  disabled,
  interactive,
  palette,
  dataTestId,
}) {
  const { basic } = paletteClassName(colorPalettes[palette]);

  return (
    <div
      data-testid={dataTestId}
      className={classNames(styles.text, fontStyles[font], sizeStyles[size], colorStyles[basic(on)], className, {
        [styles.isDisabled]: disabled === true,
        [styles.isStatic]: interactive === false,
      })}
    >
      {children}
    </div>
  );
}

StaticText.propTypes = {
  font: PropTypes.oneOf(FONTS).isRequired,
  size: PropTypes.oneOf(SIZES).isRequired,
  on: PropTypes.oneOf(COLORS_ON),
  palette: PropTypes.oneOf(Object.keys(colorPalettes)),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  interactive: PropTypes.bool,
  dataTestId: PropTypes.string,
};

StaticText.defaultProps = {
  palette: DEFAULT_COLOR_PALETTE,
  on: COLOR_PRIMARY,
  className: null,
  disabled: false,
  interactive: null,
  dataTestId: null,
};
