import React from 'react';
import i18next from 'i18next';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal } from 'app/common/actions/modals';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import { useTranslation } from 'react-i18next';
import { ERROR_COLOR_PALETTE, HeadText5 } from 'app/common/typography';

export const DELETE_DRAWINGS_ROTATE_IMAGE_MODAL_TEST_ID = 'delete-drawings-rotate-image-modal';

type RotateImageModalProps = {
  onConfirm: () => void;
  isLoading: boolean;
};

export default function RotateImageModal({ onConfirm, isLoading }: RotateImageModalProps) {
  const { t } = useTranslation();
  const onConfirmSuccess = useAction(() =>
    closeModalWithSuccessNotificationMessage(i18next.t('slideshow.deletePhotoDrawings.success'))
  );
  const onConfirmError = useAction(() =>
    closeModalWithErrorNotificationMessage(i18next.t('slideshow.deletePhotoDrawings.error'))
  );

  return (
    <ProcessingConfirmModal
      onClose={useAction(hideModal)}
      onConfirm={onConfirm}
      onConfirmError={onConfirmError}
      onConfirmSuccess={onConfirmSuccess}
      confirmEnabled={!isLoading}
      confirmLabel={t('slideshow.deletePhotoDrawings.button.confirm')}
      title={t('slideshow.deletePhotoDrawings.title')}
      submitOnEnter={true}
      isLoading={isLoading}
      subTitle={t('slideshow.deletePhotoDrawings.infoText')}
    >
      <HeadText5 palette={ERROR_COLOR_PALETTE}>
        <div data-testid={DELETE_DRAWINGS_ROTATE_IMAGE_MODAL_TEST_ID}>
          {t('slideshow.deletePhotoDrawings.infoText1')}
        </div>
      </HeadText5>
    </ProcessingConfirmModal>
  );
}
