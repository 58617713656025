import { useEffect } from 'react';

/**
 * Use infinite scrolling on the given DOM node.
 *
 * This hook observes the scroll on the given DOM node and triggers the callback once when bottom is reached.
 * The callback won't be called again until the DOM node height increases and bottom is reached again.
 *
 * @param domNode The DOM node to observe
 * @param onBottomReached The function that will be called when bottom is reach in order to load new content
 * @param pixelsOffset Number of pixels from the bottom from where the bottom is considered reached
 */
export default function useInfiniteScroll(domNode, onBottomReached, pixelsOffset) {
  useEffect(() => {
    let isListeningForBottomReached = true;

    function handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      const pixelsTraveled = scrollHeight - scrollTop;
      const maxPixelsHeight = clientHeight + pixelsOffset;

      if (isListeningForBottomReached) {
        const hasReachedBottom = pixelsTraveled <= maxPixelsHeight;
        if (hasReachedBottom) {
          // The bottom has been reached, stop listening for it and call the onBottomReached prop.
          isListeningForBottomReached = false;
          if (onBottomReached != null) {
            onBottomReached();
          }
        }
      } else if (pixelsTraveled > maxPixelsHeight) {
        // There are more pixels to scroll which means new DOM elements have been added after the previous bottom reached detection.
        // Re-listen for a possible new bottom reached event.
        isListeningForBottomReached = true;
      }
    }

    domNode?.addEventListener('scroll', handleScroll);

    return () => {
      domNode?.removeEventListener('scroll', handleScroll);
    };
  }, [domNode, onBottomReached, pixelsOffset]);
}
