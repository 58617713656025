export const APP_SHELL_TOGGLE_USER_MENU = 'app.shell.toggle.user.menu';

export const initialState = {
  header: {
    isUserMenuVisible: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case APP_SHELL_TOGGLE_USER_MENU:
      return {
        ...state,
        header: {
          ...state.header,
          isUserMenuVisible: !state.header.isUserMenuVisible,
        },
      };
    default:
      return state;
  }
}
