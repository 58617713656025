type UserNotificationUserData = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type UserNotificationCreatedPayload = {
  user: UserNotificationUserData;
  date: string;
};
type UserNotificationCreated = {
  user: UserNotificationUserData;
  date: Date;
};

export type UserNotificationStatePayload = {
  created: UserNotificationCreatedPayload;
  readDate: string | null;
};

export default class UserNotificationState {
  constructor(public readonly created: UserNotificationCreated, public isRead: boolean) {}

  public markStateAsRead() {
    this.isRead = true;
  }
}
