import useUsers from 'app/common/hooks/useUsers';

export type Suggestion = {
  label: string;
  value: string;
};

type Result = {
  users: Suggestion[];
  isLoading: boolean;
};

export default function useSuggestions(excludeId: string): Result {
  const { users, isLoading } = useUsers();

  if (!isLoading && users) {
    return {
      users: users.filter(user => user.id !== excludeId).map(user => ({ label: user.name, value: user.id })),
      isLoading,
    };
  }

  return { users: [], isLoading };
}
