import React from 'react';
import PlatformMenuLink from 'app/common/components/platformMenu/links/link';
import i18next from 'i18next';
import { PATH_NETWORK_REPORTS, PATH_SURVEY_REPORT_VISITS } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useCurrentUserReportsAccess from 'app/common/hooks/useCurrentUserReportsAccess';
import { APPSHELL_REPORTS } from 'app/common/constants/userflow';

const useReportLink = () => {
  const href = useParamSelector(getAbsoluteUrl, PATH_NETWORK_REPORTS);
  const isActive = useLocationMatch(PATH_SURVEY_REPORT_VISITS, { exact: false });
  const { canAccess, discoveryPagesEnabled } = useCurrentUserReportsAccess();

  return {
    href,
    isActive,
    canAccess,
    discoveryPagesEnabled,
  };
};

export default function ReportLink() {
  const { href, isActive, canAccess, discoveryPagesEnabled } = useReportLink();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <PlatformMenuLink isActive={isActive} href={href} data-testid={APPSHELL_REPORTS} isDisabled={!canAccess}>
      {i18next.t('reports')}
    </PlatformMenuLink>
  );
}
