import React from 'react';
import PropTypes from 'prop-types';
import styles from './icon.css';

const Icon = ({ IconComponent }) => (
  <div className={styles.icon}>
    <IconComponent />
  </div>
);

Icon.propTypes = {
  IconComponent: PropTypes.func.isRequired,
};

export default Icon;
