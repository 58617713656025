// ERRORS
export const EXCEPTION_CODE_ITEM_NAME_EMPTY = 422016;
export const EXCEPTION_CODE_ITEM_NAME_TOO_LONG = 422017;
export const EXCEPTION_CODE_ITEM_CODE_EMPTY = 422018;
export const EXCEPTION_CODE_ITEM_CODE_TOO_LONG = 422019;
export const EXCEPTION_CODE_ITEM_CODE_UNIQUE = 422024;
export const EXCEPTION_CODE_ITEM_DESCRIPTION_TOO_LONG = 422020;
export const EXCEPTION_CODE_ITEM_FOLDER_SUBTREE_CONTAINS_ITEM = 409007;
export const EXCEPTION_CODE_ITEM_FOLDER_SUBTREE_LINKED_TO_PRESET_VARIANT = 409015;
export const EXCEPTION_CODE_SIBLING_ITEM_FOLDER_WITH_SAME_NAME = 409008;
export const EXCEPTION_CODE_BRAND_WITH_SAME_NAME = 409009;
export const EXCEPTION_CODE_BRAND_NAME_GENERIC_RESERVED = 422040;
export const EXCEPTION_CODE_MODEL_3D_INVALID_FILE = 422073;
export const EXCEPTION_CODE_ITEM_IMAGE_FILE_TYPE_INVALID = 422104;
export const EXCEPTION_CODE_ITEM_IMAGE_FILE_TOO_LARGE = 422431;
export const EXCEPTION_CODE_BRAND_CONTAINS_FOLDER = 409005;
export const EXCEPTION_CODE_BRAND_IS_ASSIGNED_TO_GUIDELINE = 409006;
export const EXCEPTION_CODE_MODEL3D_TOO_MANY_SHAPES_VARIANTS = 422244;
export const EXCEPTION_CODE_TOO_MANY_OWNERS = 403071;
export const EXCEPTION_CODE_OWNERS_LENGTH = 403074;
export const EXCEPTION_CODE_IMPORT_INVALID_FILE_NAME = 422308;
// WARNINGS
export const WARNING_CODE_ITEM_IMAGE_TOO_LARGE = 422158;
// TODO?: Upload logo will return this code when image is too large. Should I create a follow up US for this?
// export const WARNING_CODE_ITEM_IMAGE_TOO_LARGE = 422004;
export const WARNING_CODE_ITEM_IMAGE_TOO_LARGE_AUTOGENERATED = 422400;
export const EXCEPTION_CODE_GUIDELINE_CAMPAIGN_EXISTS_ALREADY = 422436;
