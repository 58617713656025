import useHttpQuery from 'app/common/hooks/useHttpQuery';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import { GUIDELINE_TAGS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import { GuidelineTag, GuidelineTagsPayload } from 'domain/guideline/tag';
import array2map from 'lib/helpers/array2map';

export type TagsQueryResult = {
  tags: GuidelineTag[] | undefined;
  tagsByName: Record<string, GuidelineTag> | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
};

type TagsData = {
  tags: GuidelineTag[];
  tagsByName: Record<string, GuidelineTag>;
};

function transformResponse(data: GuidelineTagsPayload): TagsData {
  const { tags } = data;
  const tagsByName = array2map(tags, 'name');

  return { tags, tagsByName };
}

export default function useGuidelineTags(): TagsQueryResult {
  const getGuidelineTags = useHttpGetRequest<TagsData>('/tags', { transformResponse });
  const { data, isLoading, isError, error } = useHttpQuery([GUIDELINE_TAGS_CACHE_KEY], getGuidelineTags);
  return { tags: data?.tags, tagsByName: data?.tagsByName, isLoading, isError, error };
}
