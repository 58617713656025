import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_SUCCESS,
  COLOR_ERROR,
  COLOR_CORNFLOWER,
  COLOR_BLUEJAY,
  COLOR_STORM,
  COLOR_SNOW,
} from 'app/common/style/colors';

import generator from 'app/common/typography/colors/palettes/helpers/generator';

export const DISPLAY_ALT_COLOR_PALETTE = 'display-alt-color-palette';

export default generator({
  basic: {
    [COLOR_PRIMARY]: COLOR_CORNFLOWER,
    [COLOR_SECONDARY]: COLOR_SNOW,
    [COLOR_SUCCESS]: COLOR_SNOW,
    [COLOR_ERROR]: COLOR_SNOW,
  },
  hover: {
    [COLOR_PRIMARY]: COLOR_CORNFLOWER,
    [COLOR_SECONDARY]: COLOR_SNOW,
    [COLOR_SUCCESS]: COLOR_SNOW,
    [COLOR_ERROR]: COLOR_SNOW,
  },
  active: {
    [COLOR_PRIMARY]: COLOR_BLUEJAY,
    [COLOR_SECONDARY]: COLOR_STORM,
    [COLOR_SUCCESS]: COLOR_SNOW,
    [COLOR_ERROR]: COLOR_SNOW,
  },
});
