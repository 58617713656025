import {
  EXCEPTION_CODE_BRAND_CONTAINS_FOLDER,
  EXCEPTION_CODE_BRAND_IS_ASSIGNED_TO_GUIDELINE,
  EXCEPTION_CODE_BRAND_NAME_GENERIC_RESERVED,
  EXCEPTION_CODE_BRAND_WITH_SAME_NAME,
  EXCEPTION_CODE_ITEM_CODE_EMPTY,
  EXCEPTION_CODE_ITEM_CODE_UNIQUE,
  EXCEPTION_CODE_ITEM_FOLDER_SUBTREE_CONTAINS_ITEM,
  EXCEPTION_CODE_ITEM_FOLDER_SUBTREE_LINKED_TO_PRESET_VARIANT,
  EXCEPTION_CODE_ITEM_IMAGE_FILE_TOO_LARGE,
  EXCEPTION_CODE_ITEM_IMAGE_FILE_TYPE_INVALID,
  EXCEPTION_CODE_ITEM_NAME_EMPTY,
  EXCEPTION_CODE_MODEL3D_TOO_MANY_SHAPES_VARIANTS,
  EXCEPTION_CODE_MODEL_3D_INVALID_FILE,
  EXCEPTION_CODE_OWNERS_LENGTH,
  EXCEPTION_CODE_SIBLING_ITEM_FOLDER_WITH_SAME_NAME,
  EXCEPTION_CODE_TOO_MANY_OWNERS,
  WARNING_CODE_ITEM_IMAGE_TOO_LARGE,
  WARNING_CODE_ITEM_IMAGE_TOO_LARGE_AUTOGENERATED,
  EXCEPTION_CODE_GUIDELINE_CAMPAIGN_EXISTS_ALREADY,
} from 'app/common/actions/api/exception';
import getCodeFromApiException from 'app/common/helpers/api/getCodeFromApiException';
import ApiExceptionType from 'app/common/actions/api/constants';

type TranslationKeyAndExceptionTypeFromApiException = {
  translationKey?: string;
  exceptionType: ApiExceptionType;
};

const TRANSLATION_KEY_AND_EXCEPTION_TYPE_MAPPING_BY_CODE: Record<
  number,
  TranslationKeyAndExceptionTypeFromApiException
> = {
  [EXCEPTION_CODE_ITEM_NAME_EMPTY]: {
    translationKey: 'form.validation.item.name.required',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_ITEM_CODE_EMPTY]: {
    translationKey: 'form.validation.item.code1.required',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_ITEM_CODE_UNIQUE]: {
    translationKey: 'form.validation.item.code1.unique',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_ITEM_FOLDER_SUBTREE_CONTAINS_ITEM]: {
    translationKey: 'catalog.folder.modal.delete.error.notEmpty',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_ITEM_FOLDER_SUBTREE_LINKED_TO_PRESET_VARIANT]: {
    translationKey: 'catalog.folder.modal.delete.error.linked',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_SIBLING_ITEM_FOLDER_WITH_SAME_NAME]: {
    translationKey: 'catalog.folder.modal.create.error.sameName',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_BRAND_WITH_SAME_NAME]: {
    translationKey: 'catalog.brand.modal.create.error.sameName',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_BRAND_NAME_GENERIC_RESERVED]: {
    translationKey: 'catalog.brand.modal.create.error.genericName',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_MODEL_3D_INVALID_FILE]: {
    translationKey: 'catalog.model3d.create.error.fileInvalid',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_ITEM_IMAGE_FILE_TYPE_INVALID]: {
    translationKey: 'catalog.item.create.error.image.file.type.invalid',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_ITEM_IMAGE_FILE_TOO_LARGE]: {
    translationKey: 'catalog.item.create.error.image.file.tooLarge',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_BRAND_CONTAINS_FOLDER]: {
    translationKey: 'catalog.action.brand.deleteBrand.warning',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_BRAND_IS_ASSIGNED_TO_GUIDELINE]: {
    translationKey: 'catalog.action.brand.deleteBrand.warning_secondary',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_MODEL3D_TOO_MANY_SHAPES_VARIANTS]: {
    translationKey: 'catalog.model3d.create.error.tooManyShapesVariants',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_TOO_MANY_OWNERS]: {
    translationKey: 'guideline.modal.manageOwners.error.tooManyOwners',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_OWNERS_LENGTH]: {
    translationKey: 'guideline.modal.manageOwners.error.ownersLength',
    exceptionType: ApiExceptionType.ERROR,
  },
  [EXCEPTION_CODE_GUIDELINE_CAMPAIGN_EXISTS_ALREADY]: {
    translationKey: 'guideline.campaign.code.duplicate.error',
    exceptionType: ApiExceptionType.ERROR,
  },
  [WARNING_CODE_ITEM_IMAGE_TOO_LARGE]: {
    translationKey: 'catalog.item.warning.image.tooLarge',
    exceptionType: ApiExceptionType.WARNING,
  },
  [WARNING_CODE_ITEM_IMAGE_TOO_LARGE_AUTOGENERATED]: {
    translationKey: 'catalog.item.warning.image.tooLarge.autoGenerated',
    exceptionType: ApiExceptionType.WARNING,
  },
};

export default function getTranslationKeyAndExceptionTypeFromApiException(
  exception: any
): TranslationKeyAndExceptionTypeFromApiException {
  const code = getCodeFromApiException(exception);
  const translationKeyAndExceptionType = TRANSLATION_KEY_AND_EXCEPTION_TYPE_MAPPING_BY_CODE[code];
  return translationKeyAndExceptionType || { translationKey: undefined, exceptionType: ApiExceptionType.ERROR };
}
