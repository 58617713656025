import { useQuery } from '@tanstack/react-query';
import assertIsHandledException from 'app/common/helpers/api/assertIsHandledException';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import { QueryKeyType, UseQueryOptionsType, UseQueryResultType } from 'domain/lib/react-query-types';

export type UseHttpQueryResult<ResponseData = unknown> = UseQueryResultType<ResponseData>;

export type UseHttpQueryOptions<ResponseData = unknown> = UseQueryOptionsType<ResponseData> & {
  throwOnUnhandledError?: boolean;
  useCache?: boolean;
};

export default function useHttpQuery<ResponseData = unknown>(
  queryKey: QueryKeyType,
  queryFn: () => Promise<ResponseData>,
  options?: UseHttpQueryOptions<ResponseData>
): UseQueryResultType<ResponseData> {
  const { throwOnUnhandledError = true, useCache, ...queryOptions } = options ?? {};

  if (useCache === true) {
    queryOptions.staleTime = Infinity;
  } else if (useCache === false) {
    queryOptions.staleTime = 0;
  }

  const result = useQuery(queryKey, queryFn, queryOptions);

  if (result.isError) {
    assertIsNotCriticalHttpException(result.error);

    if (throwOnUnhandledError) {
      assertIsHandledException(result.error);
    }
  }

  // To be able to go from react-query v3 to v4, we tweak the loading state to retain previous behaviour
  // https://tanstack.com/query/v4/docs/framework/react/guides/migrating-to-react-query-4
  const isLoading = typeof queryOptions.enabled === 'boolean' && !queryOptions.enabled ? false : result.isLoading;

  return {
    ...result,
    isLoading,
  } as UseQueryResultType<ResponseData>;
}
