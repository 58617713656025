import useInstanceSettings from 'app/common/hooks/useInstanceSettings';

type useInstanceSettingsDiscoveryPageResult = {
  discoveryPagesEnabled?: boolean;
};

export default function useInstanceSettingsDiscoveryPage(): useInstanceSettingsDiscoveryPageResult {
  const { instanceSettings } = useInstanceSettings();
  const isDiscoveryPagesEnabled = instanceSettings?.discoveryPagesEnabled ?? false;
  return { discoveryPagesEnabled: isDiscoveryPagesEnabled };
}
