export const UPLOAD_FILES_START = 'IMPORT_UPLOAD_FILES_START';
export const UPLOAD_FILE_START = 'IMPORT_UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'IMPORT_UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'IMPORT_UPLOAD_FILE_ERROR';
export const UPLOAD_FILE_DONE = 'IMPORT_UPLOAD_FILE_DONE';
export const UPLOAD_FILES_DONE = 'IMPORT_UPLOAD_FILES_DONE';

export const INCREMENT_UPLOAD_PROGRESS_LOADED = 'INCREMENT_UPLOAD_PROGRESS_LOADED';
export const SET_UPLOAD_PROGRESS_TOTAL = 'SET_UPLOAD_PROGRESS_TOTAL';

export const FETCH_UPLOADED_FILES_START = 'IMPORT_FETCH_UPLOADED_START';
export const FETCH_UPLOADED_FILES_SUCCESS = 'IMPORT_FETCH_UPLOADED_SUCCESS';
export const FETCH_UPLOADED_FILES_IS_LOCKED_SUCCESS = 'IMPORT_FETCH_UPLOADED_IS_LOCKED_SUCCESS';
export const FETCH_UPLOADED_FILES_ERROR = 'IMPORT_FETCH_UPLOADED_ERROR';

export const DELETE_FILES_START = 'import.delete.files.start';
export const DELETE_FILES_SUCCESS = 'import.delete.files.success';
export const DELETE_FILES_ERROR = 'import.delete.files.error';

export const RUN_IMPORT_START = 'import.run.start';
export const RUN_IMPORT_SUCCESS = 'import.run.success';
export const RUN_IMPORT_ERROR = 'import.run.error';

export const IMPORT_RESET = 'import.reset';

export const EXPORT_DATA_START = 'export.data.start';
export const EXPORT_DATA_SUCCESS = 'export.data.success';
export const EXPORT_DATA_ERROR = 'export.data.error';
