import React from 'react';
import Thumbnail from 'app/common/components/thumbnail';
import DefaultNotification from 'assets/icons/default-notification-icon.svg';
import DefaultNotificationDoc from 'assets/icons/default-notification-document.svg';
import NotificationsIcon from 'app/display/common/components/modals/userNotification/content/notificationsIcon';
import UserNotification, { UserNotificationCategory } from 'domain/userNotifications';
import styles from 'app/display/common/components/modals/userNotification/content/notificationThumbnail.css';

const UserNotificationThumbnail = ({ notification }: { notification: UserNotification }) => {
  const PlaceHolder =
    notification.category === UserNotificationCategory.DOCUMENT ? DefaultNotificationDoc : DefaultNotification;

  return (
    <div className={styles.thumbnailBlock}>
      <div className={styles.thumbnail}>
        <Thumbnail src={notification.thumbnailUrl} Placeholder={PlaceHolder} className={styles.image} />
      </div>
      <NotificationsIcon type={notification.type} />
    </div>
  );
};

export default UserNotificationThumbnail;
