import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';

export const PresetVariantEditPage = React.lazy(() =>
  import(/* webpackChunkName: "preset-variant-settings-edit" */ 'app/display/settings/sheet/edit/components/page')
);

const PresetVariantEdit = () => (
  <Suspense fallback={<Spinner />}>
    <PresetVariantEditPage />
  </Suspense>
);

export default PresetVariantEdit;
