import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';

export default function useCanEditUsers(userIds: string[]) {
  const { currentUser, isLoading: isLoadingCurrentUser } = useCurrentUser();
  const { aclRight: canEditOtherUsers, isLoading: isLoadingAcl } = useCurrentUserAclRight('admin.directory.manage');
  let canEditUsers = false;

  if (canEditOtherUsers) {
    canEditUsers = true;
  } else if (!canEditOtherUsers && userIds.length === 1 && userIds[0] === currentUser?.id) {
    canEditUsers = true;
  }

  return {
    canEditUsers,
    isLoading: isLoadingAcl || isLoadingCurrentUser,
  };
}
