import React, { useCallback, useEffect, useState } from 'react';
import { DIRECTORY_ITEM_SELECTION } from 'app/network/common/constants/legacyEvents';
import useAction from 'app/display/common/hooks/useAction';
import { showModal } from 'app/common/actions/modals';
import ToggleUserStatusModal from 'app/directory/user/list/components/modals/toggleUserStatus';
import ActivateIcon from 'assets/icons/activate-user.svg';
import DeactivateIcon from 'assets/icons/deactivate-user.svg';
import Tooltip from 'app/common/components/tooltip';
import { useTranslation } from 'react-i18next';
import useCanEditUsers from 'app/directory/user/list/hooks/useCanEditUsers';
import styles from './index.css';

export const TOGGLE_STATUS_ACTIVATE = 'activate';
export const TOGGLE_STATUS_DEACTIVATE = 'deactivate';

export const TOGGLE_STATUS_LIST_BUTTON_TESTID = 'toggleStatusListButton';
export const TOGGLE_STATUS_LIST_ACTIVATE_ICON_TESTID = 'toggleStatusListActivateIcon';
export const TOGGLE_STATUS_LIST_DEACTIVATE_ICON_TESTID = 'toggleStatusListDeactivateIcon';

type UserItemSelectionEvent = {
  type: typeof TOGGLE_STATUS_ACTIVATE | typeof TOGGLE_STATUS_DEACTIVATE;
  userIds: string[];
  isWholeSearchSelected: boolean;
};

type ToggleStatusSwitchProps = {
  url: string;
};

export default function ToggleStatusButton({ url }: ToggleStatusSwitchProps) {
  const { t } = useTranslation();
  const [{ userIds, type, isWholeSearchSelected }, setToggleStatusState] = useState<UserItemSelectionEvent>({
    userIds: [],
    type: TOGGLE_STATUS_DEACTIVATE,
    isWholeSearchSelected: false,
  });
  const showModalFn = useAction(showModal);
  const handleItemSelection = (event: CustomEvent<UserItemSelectionEvent>) => {
    setToggleStatusState(event.detail);
  };
  const handleModalOpen = useCallback(() => {
    return showModalFn(ToggleUserStatusModal, {
      userIds,
      status: type,
      url,
      isWholeSearchSelected,
    });
  }, [isWholeSearchSelected, showModalFn, type, url, userIds]);
  const { canEditUsers, isLoading } = useCanEditUsers(userIds);

  useEffect(() => {
    document.addEventListener(DIRECTORY_ITEM_SELECTION, handleItemSelection);

    return () => {
      document.removeEventListener(DIRECTORY_ITEM_SELECTION, handleItemSelection);
    };
  }, [handleModalOpen]);

  if (userIds.length === 0 || isLoading || !canEditUsers) {
    return null;
  }

  return (
    <div className={styles.toggleStatusButton} onClick={handleModalOpen} data-testid={TOGGLE_STATUS_LIST_BUTTON_TESTID}>
      <Tooltip title={t(`directory.list.toggleStatusButton.${type}.title`)}>
        {type === TOGGLE_STATUS_ACTIVATE ? (
          <ActivateIcon className={styles.icon} data-testid={TOGGLE_STATUS_LIST_ACTIVATE_ICON_TESTID} />
        ) : (
          <DeactivateIcon className={styles.icon} data-testid={TOGGLE_STATUS_LIST_DEACTIVATE_ICON_TESTID} />
        )}
      </Tooltip>
    </div>
  );
}
