import React from 'react';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal } from 'app/common/actions/modals';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import { useDispatch } from 'react-redux';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import { useTranslation } from 'react-i18next';
import { BodyText1, ERROR_COLOR_PALETTE, HeadText5 } from 'app/common/typography';
import useResetAllApprovePhoto from 'app/network/common/hooks/useResetAllApprovePhoto';
import { SlideImage } from 'yet-another-react-lightbox';
import styles from './modal.css';

export default function SlideshowResetApprovals({ photo }: { photo: SlideImage }) {
  const { t } = useTranslation();
  const handleClose = useAction(hideModal);
  const dispatch = useDispatch();
  const resetAllApprovePhoto = useResetAllApprovePhoto(photo.id);

  return (
    <ProcessingConfirmModal
      onClose={handleClose}
      onConfirmError={() => {
        dispatch(closeModalWithErrorNotificationMessage(t('slideshow.infoPanel.approve.error')));
      }}
      onConfirm={resetAllApprovePhoto}
      onConfirmSuccess={() => {
        dispatch(closeModalWithSuccessNotificationMessage(t('slideshow.infoPanel.approve.success')));
      }}
      title={t('slideshow.infoPanel.approve.title')}
      confirmLabel={t('slideshow.infoPanel.approve.confirmButton')}
    >
      <BodyText1>{t('slideshow.infoPanel.approve.bodyText')}</BodyText1>
      <HeadText5 className={styles.warning} palette={ERROR_COLOR_PALETTE}>
        {t('slideshow.infoPanel.approve.bodyText.warning')}
      </HeadText5>
    </ProcessingConfirmModal>
  );
}
