import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

import noop from 'lib/helpers/noop';
import styles from './index.css';

/**
 * @visibleName Modal (deprecated)
 * @deprecated use [this one](#!/Modal) instead.
 */
export class Modal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string, // additonal classes for modal
    onClose: PropTypes.func, // method called when the modal is closed
    hasHeader: PropTypes.bool, // turn on / off modal header
    hasFooter: PropTypes.bool, // turn on / off modal footer
    title: PropTypes.string, // title displayed in modal header
    hasCloseOnHeader: PropTypes.bool, // enable / disable button to close modal on header
    closeOnOverlayClick: PropTypes.bool, // turn on / off modal close on outside click
    hasBackColor: PropTypes.bool, // turn on / off back color on overlay layer
    noStyle: PropTypes.bool, // deactivated modal style
    useDefaultLayout: PropTypes.bool, // set it to false to have full DOM control
    headerStyle: PropTypes.string, // additional class styles for header
    dialogStyle: PropTypes.string, // additional class styles for dialog layer
    bodyStyle: PropTypes.string, // additional class styles for body
    footerStyle: PropTypes.string, // additional class styles for footer
    header: PropTypes.func, // optional custom header rendering function
    footer: PropTypes.func, // optional custom footer rendering function
  };

  static defaultProps = {
    children: null,
    className: null,
    hasHeader: true,
    hasFooter: true,
    closeOnOverlayClick: true,
    useDefaultLayout: true,
    hasBackColor: true,
    onClose: noop,
    noStyle: false,
    dialogStyle: null,
    headerStyle: null,
    bodyStyle: null,
    footerStyle: null,
    header: null,
    footer: null,
    hasCloseOnHeader: true,
    title: null,
  };

  componentDidMount() {
    if (this.props.onClose) {
      window.addEventListener('keydown', this.listenKeyboard, true);
    }
  }

  componentWillUnmount() {
    if (this.props.onClose) {
      window.removeEventListener('keydown', this.listenKeyboard, true);
    }
  }

  onOverlayClick = () => {
    if (this.props.closeOnOverlayClick) {
      this.props.onClose();
    }
  };

  onDialogClick = event => {
    event.stopPropagation();
  };

  listenKeyboard = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.onClose();
    }
  };

  renderHeader() {
    if (!this.props.hasHeader) {
      return null;
    }

    if (this.props.header) {
      return this.props.header();
    }

    return (
      <Header className={classNames(this.props.headerStyle)}>
        {this.renderTitle()}
        {this.renderClose()}
      </Header>
    );
  }

  renderTitle() {
    const { title } = this.props;
    if (title === null) {
      return null;
    }

    return <span>{title}</span>;
  }

  renderClose() {
    const { hasCloseOnHeader, onClose } = this.props;
    if (!hasCloseOnHeader || !onClose) {
      return null;
    }

    return <i className={styles.iconClose} onClick={onClose} onKeyPress={() => {}} />;
  }

  renderFooter() {
    if (!this.props.hasFooter) {
      return null;
    }

    if (this.props.footer) {
      return this.props.footer();
    }

    const { onClose } = this.props;
    return (
      <div>
        <button type="button" onClick={onClose} className={styles.buttonClose}>
          {i18next.t('button.ok')}
        </button>
      </div>
    );
  }

  renderContent() {
    if (!this.props.useDefaultLayout) {
      return this.props.children;
    }

    return (
      <Dialog onClick={this.onDialogClick} noStyle={this.props.noStyle} className={this.props.dialogStyle}>
        {this.renderHeader()}
        <Body className={classNames(this.props.bodyStyle)}>{this.props.children}</Body>
        <Footer className={classNames(this.props.footerStyle)}>{this.renderFooter()}</Footer>
      </Dialog>
    );
  }

  render() {
    return (
      <div className={classNames(this.props.className)}>
        <Overlay hasBackColor={this.props.hasBackColor} />
        <Content onClick={this.onOverlayClick}>{this.renderContent()}</Content>
      </div>
    );
  }
}

export const Overlay = props => {
  const classes = classNames(styles.overlay, props.hasBackColor ? styles.overlayBackColor : null);
  return <div className={classes} />;
};

Overlay.propTypes = {
  hasBackColor: PropTypes.bool,
};

Overlay.defaultProps = {
  hasBackColor: true,
};

export const Content = props => {
  return (
    <div className={styles.content} onClick={props.onClick} onKeyPress={() => {}}>
      {props.children}
    </div>
  );
};

Content.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Content.defaultProps = {
  children: null,
  onClick: noop,
};

const Dialog = props => {
  const dialogClass = classNames(props.className, {
    [styles.dialog]: !props.noStyle,
  });
  return (
    <div className={dialogClass} onClick={props.onClick} onKeyPress={() => {}}>
      {props.children}
    </div>
  );
};

Dialog.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  noStyle: PropTypes.bool,
  className: PropTypes.string,
};

Dialog.defaultProps = {
  children: null,
  onClick: noop,
  noStyle: false, // when set to true allow to have full control on modal content style
  className: null,
};

export const Header = props => <div className={classNames(styles.header, props.className)}>{props.children}</div>;

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Header.defaultProps = {
  children: null,
  className: null,
};

export const Body = props => <div className={classNames(styles.body, props.className)}>{props.children}</div>;

Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Body.defaultProps = {
  children: null,
  className: null,
};

export const Footer = props => <div className={classNames(styles.footer, props.className)}>{props.children}</div>;

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Footer.defaultProps = {
  children: null,
  className: null,
};

export default Modal;
