import FileState from 'domain/guideline/fileState';

export enum GuidelineFileType {
  THUMBNAIL = 'thumbnail',
  SOURCE = 'source',
  SOURCEV4 = 'sourcev4',
  PREVIEW = 'preview',
  TEXTURE = 'texture',
  BAY_PREVIEW = 'bayPreview',
}
export default class GuidelineFile {
  constructor(
    public id: string,
    public url: string,
    public type: GuidelineFileType,
    public fileURL: string,
    public state: FileState
  ) {
    // do nothing.
  }
}
