import React from 'react';
import { Switch } from 'react-router-dom';

import { PATH_ACL_INDEX, PATH_ACL_DISPLAY, PATH_ACL_NETWORK } from 'config/routePaths';
import NotFound from 'app/common/components/errors/notFound';
import InstanceAcl from 'app/display/acl/instance';
import ACLRoute from 'app/display/acl/instance/components/route/acl';

const AclRoutes = () => (
  <Switch>
    <ACLRoute exact path={[PATH_ACL_INDEX, PATH_ACL_DISPLAY, PATH_ACL_NETWORK]} component={InstanceAcl} />
    <ACLRoute component={NotFound} />
  </Switch>
);

export default AclRoutes;
