export default function download(data, fileName, mimeType) {
  if (navigator.msSaveBlob) {
    // IE10+
    navigator.msSaveBlob(
      new Blob([data], {
        type: mimeType,
      }),
      fileName
    );
  } else {
    const a = document.createElement('a');
    if (URL && 'download' in a) {
      // HTML5
      a.href = URL.createObjectURL(
        new Blob([data], {
          type: mimeType,
        })
      );
      a.setAttribute('download', fileName);
      a.click();
    } else {
      // Legacy
      const encodedData = encodeURIComponent(data);
      window.location.href = `data:application/octet-stream,${encodedData}`;
    }
  }
}
