import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import useInstanceSettingsDiscoveryPage from 'app/common/hooks/useInstanceSettingsDiscoveryPage';
import useCurrentUserDisplayAccess from 'app/common/hooks/useCurrentUserDisplayAccess';

type GuidelinesAccess = {
  canAccess: boolean;
  discoveryPagesEnabled?: boolean;
};

export default function useCurrentUserGuidelinesAccess(): GuidelinesAccess {
  const { aclRight } = useCurrentUserAclRight('guidelines.guideline.read');
  const { hasCurrentUserDisplayAccess: hasDisplayAccess } = useCurrentUserDisplayAccess();
  const { discoveryPagesEnabled } = useInstanceSettingsDiscoveryPage();
  return { canAccess: aclRight && hasDisplayAccess, discoveryPagesEnabled };
}
