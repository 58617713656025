export default function validateUserSheetForm(values: Record<any, any>, t: (translationKey: string) => string) {
  const errors: Record<any, any> = {};

  if (values['license-projectId']) {
    if (!values['license-serviceCode']) {
      errors['license-serviceCode'] = t('directory.userSheet.licenseTab.license.error');
    }

    if (values.executeRole === 'norole' && values.createRole === 'norole' && values['license-serviceCode']) {
      errors.createRole = t('directory.userSheet.licenseTab.role.error');
      errors.executeRole = t('directory.userSheet.licenseTab.role.error');
    }
  }

  return errors;
}
