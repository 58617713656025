import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';

import styles from './index.css';

const Link = ({ className, href, to, children, 'data-testid': dataTestId, ...props }) => {
  if (href != null) {
    return (
      <a className={classNames(styles.navLink, className)} href={href} data-testid={dataTestId} {...props}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink className={classNames(styles.navLink, className)} to={to} data-testid={dataTestId} {...props}>
      {children}
    </RouterLink>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node,
};

Link.defaultProps = {
  className: null,
  'data-testid': null,
  href: null,
  to: null,
  children: null,
};

export default Link;
