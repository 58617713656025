import React from 'react';
import DisplayUncontrolledCollapsePanel from 'app/display/common/components/collapsePanel/uncontrolled';
import { useTranslation } from 'react-i18next';
import NotificationsSettingRow from 'app/directory/user/sheet/common/components/form/notifications/settingRow';
import styles from './index.css';

export default function NotificationsTabPhotoSection() {
  const { t } = useTranslation();
  return (
    <DisplayUncontrolledCollapsePanel
      title={t('directory.userSheet.notificationsTab.photo.title')}
      classNameContent={styles.panelContent}
    >
      <NotificationsSettingRow name="photoBestPractices" />
      <NotificationsSettingRow name="photoApprove" />
      <NotificationsSettingRow name="photoReplaced" />
      <NotificationsSettingRow name="photoDeleted" />
    </DisplayUncontrolledCollapsePanel>
  );
}
