import Collection from 'domain/collection';
import GuidelineFile, { GuidelineFileType } from 'domain/guideline/file';

export default class GuidelineFileCollection extends Collection<GuidelineFile> {
  public hasFileType(type: GuidelineFileType): boolean {
    return this.some((file: GuidelineFile) => file.type === type);
  }

  public getFileByType(type: GuidelineFileType): GuidelineFile | undefined {
    return this.find((file: GuidelineFile) => file.type === type);
  }
}
