import {
  OPEN_APP_NAVIGATION,
  CLOSE_APP_NAVIGATION,
  TOGGLE_APP_NAVIGATION,
} from 'app/common/actions/appNavigation/types';

export const initialState = false;

export default function isAppNavigationOpen(state = initialState, action) {
  switch (action.type) {
    case OPEN_APP_NAVIGATION:
      return true;
    case CLOSE_APP_NAVIGATION:
      return false;
    case TOGGLE_APP_NAVIGATION:
      return !state;
    default:
      return state;
  }
}
