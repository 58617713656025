import 'react-dates/initialize';
import React from 'react';
import moment, { Moment } from 'moment';
import useDateRangePicker from 'app/display/import/components/modals/dateRangePicker.hook';
import IconArrowRight from 'assets/icons/arrowRight.svg';
import Error from 'app/common/components/forms/validation/error';
import { DateRangePicker } from 'react-dates';
import { BodyText1 } from 'app/common/typography';
import i18next from 'i18next';
import styles from './dateRangePicker.css';

type NullableMoment = Moment | null;

type Props = {
  startDate: Moment;
  setStartDate: (newDate: NullableMoment) => void;
  endDate: Moment;
  setEndDate: (newDate: NullableMoment) => void;
  isPeriodInvalid: boolean;
};

const DatePickerWrapper = ({ startDate, setStartDate, endDate, setEndDate, isPeriodInvalid }: Props) => {
  const { focusedInput, hasFocus, handleFocusChange, handleClose, handleOutsideRange } = useDateRangePicker();
  const isErrorVisible = !hasFocus && isPeriodInvalid;
  return (
    <>
      <BodyText1 className={styles.labelPeriod}>{i18next.t('drive.import.modal.export.activity.period')}</BodyText1>
      <DateRangePicker
        noBorder
        customArrowIcon={<IconArrowRight className={styles.arrowIcon} />}
        startDate={startDate}
        startDateId="startDate"
        endDate={endDate}
        endDateId="endDate"
        startDatePlaceholderText={i18next.t('datepicker.startDate')}
        endDatePlaceholderText={i18next.t('datepicker.endDate')}
        numberOfMonths={1}
        onDatesChange={(newDates: { startDate: NullableMoment; endDate: NullableMoment }) => {
          setStartDate(newDates.startDate ?? null);
          setEndDate(newDates.endDate ?? null);
        }}
        focusedInput={focusedInput}
        onFocusChange={handleFocusChange}
        onClose={handleClose}
        isOutsideRange={handleOutsideRange}
        displayFormat={moment.localeData().longDateFormat('L')}
        hideKeyboardShortcutsPanel={true}
      />
      <Error isVisible={isErrorVisible} className={styles.error}>
        {i18next.t('drive.import.modal.export.activity.period.error')}
      </Error>
    </>
  );
};

export default DatePickerWrapper;
