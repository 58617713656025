import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLightboxState } from 'yet-another-react-lightbox';
import styles from 'app/common/components/slideshow/information/content/tags.css';
import { LegendText2 } from 'app/common/typography';
import useShowModal from 'app/display/common/hooks/useShowModal';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import Edit from 'assets/icons/edit.svg';
import Section from '../section';
import TagPhotoModal from '../../tagPhoto/modal';

export const SLIDESHOW_TAG_PHOTO_PANEL_TESTID = 'slideshowTagPhotoButtonPanel';

const Tags = () => {
  const { currentSlide: photo } = useLightboxState();
  const { t } = useTranslation();
  const showModal = useShowModal(TagPhotoModal, {
    photo,
  });
  const { aclRight: canTagPhoto } = useCurrentUserAclRight('library.photo.tag');

  return (
    <Section
      title={t('slideshow.infoPanel.tags.title')}
      isAction={true}
      isHidden={!canTagPhoto}
      onClick={showModal}
      dataTestId={SLIDESHOW_TAG_PHOTO_PANEL_TESTID}
      Icon={Edit}
    >
      <div className={styles.tagsContent}>
        {photo?.tags.map(tag => (
          <div className={styles.tag} key={tag}>
            <LegendText2>{tag}</LegendText2>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default Tags;
