import React, { ReactNode } from 'react';
import { LegendText2 } from 'app/common/typography';
import classNames from 'classnames';
import { COLOR_SECONDARY } from 'app/common/style/colors';
import styles from './index.css';

type SlideshowStatusTagProps = {
  className?: string;
  children: NonNullable<ReactNode>;
};

export default function SlideshowStatusTag({ className, children }: SlideshowStatusTagProps) {
  return (
    <LegendText2 className={classNames(styles.statusTag, className)} on={COLOR_SECONDARY}>
      {children}
    </LegendText2>
  );
}
