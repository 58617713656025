import { EXPORT_DATA_START, EXPORT_DATA_SUCCESS, EXPORT_DATA_ERROR } from 'app/display/import/actions';
import exportMarketData from 'app/display/import/actions/exportData/market';
import exportCatalog from 'app/display/import/actions/exportData/catalog';
import exportCatalogMarketData from 'app/display/import/actions/exportData/catalogMarket';
import exportUserActivity from 'app/display/import/actions/exportData/userActivity';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import {
  EXPORT_ACTIVITY,
  EXPORT_CATALOG,
  EXPORT_CATALOG_MARKET,
  EXPORT_MARKET,
  EXPORT_NETWORK_DATA,
  EXPORT_NETWORK_DATA_TEMPLATES,
} from 'app/display/import/constants/export';
import generatePathname from 'lib/helpers/router/generatePathname';
import { PATH_NETWORK_EXPORT } from 'config/routePaths';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';

const actionsByDataType = {
  [EXPORT_MARKET.key]: exportMarketData,
  [EXPORT_CATALOG.key]: exportCatalog,
  [EXPORT_CATALOG_MARKET.key]: exportCatalogMarketData,
  [EXPORT_ACTIVITY.key]: exportUserActivity,
};

const getActionByDataType = dataType => actionsByDataType[dataType];

export default function exportData(dataType, filters, isSelectAll, period, exportInternalUserActivities) {
  return async (dispatch, getState) => {
    const baseUrl = getInstanceBaseUrl(getState());
    if (dataType === EXPORT_NETWORK_DATA.key) {
      return window.open(`${baseUrl}${generatePathname(PATH_NETWORK_EXPORT)}`, '_blank');
    }
    if (dataType === EXPORT_NETWORK_DATA_TEMPLATES.key) {
      return window.open(`${baseUrl}${generatePathname(PATH_NETWORK_EXPORT)}?templates=true`, '_blank');
    }

    const action = getActionByDataType(dataType);
    if (action == null) {
      throw new Error(`Invalid export dataType: ${dataType}`);
    }

    try {
      dispatch({
        type: EXPORT_DATA_START,
        dataType,
      });
      await dispatch(action(filters, isSelectAll, period, exportInternalUserActivities));
      return dispatch({
        type: EXPORT_DATA_SUCCESS,
        dataType,
      });
    } catch (error) {
      assertIsNotCriticalHttpException(error);
      return dispatch({
        type: EXPORT_DATA_ERROR,
        dataType,
        error,
      });
    }
  };
}
