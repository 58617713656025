import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import createSingleton from 'lib/helpers/createSingleton';
import getGdprCompliantUserToken from 'lib/bugsnag/helpers/getGdprCompliantUserToken';
import HTTPError from 'lib/errors/http/error';

function isReportFor2dEditor(report) {
  return report.stacktrace.length > 0 && report.stacktrace[0].file.includes('display2d');
}

function createBugsnagClient() {
  if (!process.env.BUGSNAG_API_KEY) {
    return null;
  }

  const userToken = getGdprCompliantUserToken();

  const client = bugsnag({
    collectUserIp: false,
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: process.env.APP_VERSION,
    releaseStage: process.env.BUGSNAG_STAGE,
    consoleBreadcrumbsEnabled: false,
    user: {
      ...userToken?.user,
      _comment: 'These data are extracted from the user token.',
    },
    metaData: { 'auth token': userToken },
    beforeSend: report => {
      if (isReportFor2dEditor(report)) {
        return false;
      }

      if (report.originalError instanceof HTTPError) {
        report.updateMetaData('api request', report.originalError.response);
      }

      return true;
    },
  });

  client.use(bugsnagReact, React);

  return client;
}

export default createSingleton(createBugsnagClient);
