import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lib/helpers/noop';

import RoundedButton from 'app/display/common/components/roundedButton';
import PlusIcon from 'assets/icons/plus.svg';
import { HeadText4 } from 'app/common/typography';
import styles from './style.css';

/**
 * A SingleActionPlusButton is a RoundedButton with a PlusIcon inside.
 * As it is a well-known Material Design component, it exists on its own.
 *
 * @example ./index.md
 */
const SingleActionPlusButton = ({ onClick, label, icon, dataTestId }) => {
  return (
    <div className={styles.plusButtonSingleAction} onClick={onClick} data-testid={dataTestId}>
      <RoundedButton className={styles.plusButton}>{icon || <PlusIcon className={styles.icon} />}</RoundedButton>
      <HeadText4 className={styles.label}>{label}</HeadText4>
    </div>
  );
};

SingleActionPlusButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  dataTestId: PropTypes.string,
};

SingleActionPlusButton.defaultProps = {
  label: null,
  onClick: noop,
  icon: null,
  dataTestId: null,
};

export default SingleActionPlusButton;
