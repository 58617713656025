import React from 'react';
import DisplayUncontrolledCollapsePanel from 'app/display/common/components/collapsePanel/uncontrolled';
import NotificationsSettingActionRow from 'app/directory/user/sheet/common/components/form/notifications/settingActionRow';
import { useTranslation } from 'react-i18next';
import { BodyText1 } from 'app/common/typography';
import { Field } from 'redux-form';
import Select from 'app/common/components/forms/select';
import { PhotoReportFrequency } from 'domain/user/notificationSettings';
import classNames from 'classnames';
import notificationsStyles from '../index.css';
import styles from './index.css';

type NotificationsTabEmailReportsSectionProps = {
  testPhotoReportHref: string;
  testSurveyReportHref: string;
  userIdV4: string;
};

export default function NotificationsTabEmailReportsSection({
  testPhotoReportHref,
  testSurveyReportHref,
  userIdV4,
}: NotificationsTabEmailReportsSectionProps) {
  const { t } = useTranslation();
  return (
    <DisplayUncontrolledCollapsePanel
      title={t('directory.userSheet.notificationsTab.emailReports.title')}
      classNameContent={notificationsStyles.panelContent}
    >
      <NotificationsSettingActionRow name="isActivityReportEnabled">
        <BodyText1 className={styles.infoText}>
          {t('directory.userSheet.notificationsTab.setting.isActivityReportEnabled.intoText.partOne')}
          <Field component={Select} selectStyle={styles.select} name="photoReportFrequency">
            {Object.keys(PhotoReportFrequency).map(frequency => (
              <option key={frequency} value={frequency}>
                {t(`directory.userSheet.notificationsTab.setting.photoReportFrequency.${frequency}`)}
              </option>
            ))}
          </Field>
          {t('directory.userSheet.notificationsTab.setting.isActivityReportEnabled.intoText.partTwo')}
          <a
            href={testPhotoReportHref}
            id="directory-activityReport-test"
            className={classNames('directory-activityReport-test', styles.testLink)}
            data-userid={userIdV4}
            data-frequency="weekly"
            data-parts="#empty"
          >
            {t('directory.userSheet.notificationsTab.emailReports.testNotificationLink')}
          </a>
        </BodyText1>
      </NotificationsSettingActionRow>
      <NotificationsSettingActionRow name="isSurveyOwnerActivityReportEnabled">
        <BodyText1 className={styles.infoText}>
          {t('directory.userSheet.notificationsTab.setting.isSurveyOwnerActivityReportEnabled.intoText')}
          <a
            href={testSurveyReportHref}
            id="directory-activityReport-test"
            className={classNames('pjax', 'directory-activityReport-test', styles.testLink)}
            data-parts="#empty"
            data-notification="#sending"
            data-history="false"
          >
            {t('directory.userSheet.notificationsTab.emailReports.testNotificationLink')}
          </a>
        </BodyText1>
      </NotificationsSettingActionRow>
      <Field component="input" type="hidden" name="saveNotificationSettings" />
    </DisplayUncontrolledCollapsePanel>
  );
}
