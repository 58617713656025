import React from 'react';
import i18next from 'i18next';
import { DEFAULT_COLOR_PALETTE, HeadText3, LegendText4 } from 'app/common/typography';
import { COLOR_SECONDARY } from 'app/common/style/colors';
import styles from './header.css';

type NotificationModalTitleProps = {
  count: number;
};

export default function NotificationHeaderLeft({ count }: NotificationModalTitleProps) {
  return (
    <div className={styles.header}>
      <HeadText3>{i18next.t('display.modal.notifications.title')}</HeadText3>
      {count > 0 && (
        <LegendText4 palette={DEFAULT_COLOR_PALETTE} on={COLOR_SECONDARY} className={styles.count}>
          {count}
        </LegendText4>
      )}
    </div>
  );
}
