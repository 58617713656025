import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { PATH_IMPORT_FILES } from 'config/routePaths';
import NotFound from 'app/common/components/errors/notFound';
import Spinner from 'app/common/components/spinner';
import catcher from 'app/common/helpers/importCatcher';
import ImportRoute from 'app/display/import/components/route/import';

const Files = Loadable({
  loader: () => import(/* webpackChunkName: "display-import-files" */ 'app/display/import').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/import'),
});

const ImportRoutes = () => {
  return (
    <Switch>
      <ImportRoute exact path={PATH_IMPORT_FILES} component={Files} />
      <ImportRoute component={NotFound} />
    </Switch>
  );
};

export default ImportRoutes;
