import { SHOW_LOADING_BAR, HIDE_LOADING_BAR } from 'app/common/actions/loadingBar/types';

export const initialState = false;

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING_BAR:
      return true;
    case HIDE_LOADING_BAR:
      return false;
    default:
      return state;
  }
}
