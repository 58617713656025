import { useLightboxState } from 'yet-another-react-lightbox';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useIsCurrentUserAllowedToDeleteSlideshowPhoto from 'app/common/hooks/acl/useIsCurrentUserAllowedToDeleteSlideshowPhoto';

export default function useCanDeleteSlideshowPhoto() {
  const { currentSlide } = useLightboxState();
  const { currentUser } = useCurrentUser();
  const canDelete = useIsCurrentUserAllowedToDeleteSlideshowPhoto();

  if (canDelete) {
    return true;
  }

  if (!currentUser) {
    return false;
  }

  return currentUser.id === currentSlide?.state.created.user.id;
}
