import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import { USER_ACL_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';

export const USER_ACL_STALE_TIME = 5 * 60 * 1000;

export default function useCurrentUserAcl(options) {
  const fetchAcl = useHttpGetRequest(`privates/user/acl`);
  const { data, isLoading, isError, error } = useHttpQuery([USER_ACL_QUERY_CACHE_KEY], fetchAcl, {
    ...options,
    staleTime: USER_ACL_STALE_TIME,
  });
  return { userAcl: data, isLoading, isError, error };
}
