import {
  PROFILE_DISPLAY_ADMIN,
  PROFILE_DISPLAY_ENDUSER,
  PROFILE_DISPLAY_SAMARKET,
  PROFILE_DISPLAY_SUPERADMIN,
  PROFILE_NETWORK_BASIC,
  PROFILE_NETWORK_COUNTRY,
  PROFILE_NETWORK_FREE,
  PROFILE_NETWORK_INTER,
  PROFILE_NETWORK_POS,
  PROFILE_NETWORK_SALES,
  PROFILE_NETWORK_ZONE,
} from 'app/common/user/helpers/profile/constants';

export const LICENSE_BASIC_HQ_FULL = 'BASIC_HQ_FULL';
export const LICENSE_BASIC_FIELD = 'BASIC_FIELD';
export const LICENSE_FREE = 'FREE';

export const LICENSE_PRO_HQ_FULL = 'PRO_HQ_FULL';
export const LICENSE_PRO_HQ_READONLY = 'PRO_HQ_READONLY';
export const LICENSE_PRO_FREE = 'PRO_FREE';
export const LICENSE_PRO_HQ_EXECUTE = 'PRO_HQ_EXECUTE';
export const LICENSE_PRO_FIELD = 'PRO_FIELD';

type RolesByLicense = {
  [key: string]: string[];
};

export const DISPLAY_ROLES_BY_LICENSE = {
  [LICENSE_BASIC_HQ_FULL]: [
    PROFILE_DISPLAY_SUPERADMIN,
    PROFILE_DISPLAY_SAMARKET,
    PROFILE_DISPLAY_ADMIN,
    PROFILE_DISPLAY_ENDUSER,
  ],
  [LICENSE_PRO_HQ_FULL]: [
    PROFILE_DISPLAY_SUPERADMIN,
    PROFILE_DISPLAY_SAMARKET,
    PROFILE_DISPLAY_ADMIN,
    PROFILE_DISPLAY_ENDUSER,
  ],
  [LICENSE_PRO_HQ_READONLY]: [PROFILE_DISPLAY_ENDUSER],
} as RolesByLicense;

export const NETWORK_ROLES_BY_LICENSE = {
  [LICENSE_PRO_HQ_EXECUTE]: [
    PROFILE_NETWORK_INTER,
    PROFILE_NETWORK_ZONE,
    PROFILE_NETWORK_COUNTRY,
    PROFILE_NETWORK_SALES,
    PROFILE_NETWORK_POS,
    PROFILE_NETWORK_BASIC,
    PROFILE_NETWORK_FREE,
  ],
  [LICENSE_PRO_HQ_READONLY]: [
    PROFILE_NETWORK_INTER,
    PROFILE_NETWORK_ZONE,
    PROFILE_NETWORK_COUNTRY,
    PROFILE_NETWORK_SALES,
    PROFILE_NETWORK_POS,
    PROFILE_NETWORK_BASIC,
    PROFILE_NETWORK_FREE,
  ],
  [LICENSE_BASIC_HQ_FULL]: [
    PROFILE_NETWORK_INTER,
    PROFILE_NETWORK_ZONE,
    PROFILE_NETWORK_COUNTRY,
    PROFILE_NETWORK_SALES,
    PROFILE_NETWORK_POS,
    PROFILE_NETWORK_BASIC,
    PROFILE_NETWORK_FREE,
  ],
  [LICENSE_PRO_HQ_FULL]: [
    PROFILE_NETWORK_INTER,
    PROFILE_NETWORK_ZONE,
    PROFILE_NETWORK_COUNTRY,
    PROFILE_NETWORK_SALES,
    PROFILE_NETWORK_POS,
    PROFILE_NETWORK_BASIC,
    PROFILE_NETWORK_FREE,
  ],
  [LICENSE_BASIC_FIELD]: [PROFILE_NETWORK_SALES, PROFILE_NETWORK_POS, PROFILE_NETWORK_BASIC, PROFILE_NETWORK_FREE],
  [LICENSE_PRO_FIELD]: [PROFILE_NETWORK_SALES, PROFILE_NETWORK_POS, PROFILE_NETWORK_BASIC, PROFILE_NETWORK_FREE],
  [LICENSE_PRO_FREE]: [PROFILE_NETWORK_FREE],
  [LICENSE_FREE]: [PROFILE_NETWORK_FREE],
} as RolesByLicense;
