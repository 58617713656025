import { useSelector } from 'react-redux';
import useLegacyAcl from 'app/common/hooks/useLegacyAcl';
import hasCurrentUserNetworkAccess from 'app/common/user/selectors/hasCurrentUserNetworkAccess';
import useInstanceSettingsDiscoveryPage from 'app/common/hooks/useInstanceSettingsDiscoveryPage';

type DocumentAccess = {
  canAccess: boolean;
  canManage: boolean;
  discoveryPagesEnabled?: boolean;
};

export default function useCurrentUserDocumentPermissions(): DocumentAccess {
  const canAccess = useLegacyAcl('sharedDocument', 'access');
  const canManage = useLegacyAcl('sharedDocument', 'manage');
  const hasNetworkAccess = useSelector(hasCurrentUserNetworkAccess);
  const { discoveryPagesEnabled } = useInstanceSettingsDiscoveryPage();
  return {
    canAccess: canAccess.isAllowed && hasNetworkAccess,
    canManage: hasNetworkAccess && canAccess && canManage.isAllowed,
    discoveryPagesEnabled,
  };
}
