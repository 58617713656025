import React from 'react';
import DocumentTitle from 'app/common/helpers/translated-document-title';

import Layout from 'app/auth/login/layout';

// Page title
const titleKey = 'iwd.login.document.title';

const LoginRoute = () => (
  <>
    <DocumentTitle title={titleKey} />
    <Layout />
  </>
);

export default LoginRoute;
