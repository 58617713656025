import User from 'domain/user';
import Profile from 'domain/user/profile';
import Settings from 'domain/user/settings';
import State from 'domain/state';
import deprecated, { DeprecatedRelation } from 'lib/deprecated';
import { UserflowAttributes } from 'domain/userflow/userflowAttributes';
import NotificationSettings from 'domain/user/notificationSettings';

export enum UserStatus {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
}

/**
 * A user with all possible information.
 */
export default class FullUser extends User {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string,
    public readonly profile: Profile,
    public readonly brandIds: string[],
    public readonly marketIds: string[],
    public readonly settings: Settings,
    public readonly photoUrl: string | undefined = undefined,
    public readonly isIwd: boolean = false,
    public readonly idv4: number | null = null,
    public state: State,
    public readonly job?: string,
    public readonly company?: string,
    public readonly phone?: string,
    public readonly notes?: string,
    public readonly notificationSettings?: NotificationSettings
  ) {
    super(id, name, photoUrl, isIwd, idv4);
  }

  /**
   * @deprecated use `brandIds` instead.
   */
  @deprecated()
  get brands() {
    return this.brandIds.map(brandId => ({
      id: brandId,
    }));
  }

  /**
   * @deprecated use `marketIds` instead.
   */
  @deprecated()
  get markets(): DeprecatedRelation[] {
    return this.marketIds.map(marketId => ({
      id: marketId,
    }));
  }

  getUserflowAttributes(): UserflowAttributes {
    return {
      firstname: this.firstName,
      lastname: this.lastName,
      email: this.email,
      measure_unit: this.settings.unitOfLength,
      name: this.name,
    };
  }

  toggleStatus() {
    this.state.enabled = !this.state.enabled;
  }

  get status(): UserStatus {
    return this.state?.enabled ? UserStatus.ACTIVATED : UserStatus.DEACTIVATED;
  }
}
