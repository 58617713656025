import useInstanceSettings from 'app/common/hooks/useInstanceSettings';

type UseIsPlatformResult = {
  isLoading: boolean;
  isPlatform: boolean;
};

export default function useIsPlatform(): UseIsPlatformResult {
  const { isLoading, instanceSettings } = useInstanceSettings();
  return { isLoading, isPlatform: instanceSettings?.isV5 || false };
}
