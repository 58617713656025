import {
  SHOW_ACTIONS_POPUP,
  HIDE_ACTIONS_POPUP,
  TOGGLE_ACTIONS_POPUP,
} from 'app/network/survey/report/actions/actionsPopup/types';

export const initialState = false;

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case SHOW_ACTIONS_POPUP:
      return true;
    case HIDE_ACTIONS_POPUP:
      return false;
    case TOGGLE_ACTIONS_POPUP:
      return !state;
    default:
      return state;
  }
}
