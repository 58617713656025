import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { showModal } from 'app/common/actions/modals';
import ExportModal from 'app/display/import/components/modals/export';
import useAction from 'app/display/common/hooks/useAction';
import ExportIcon from 'assets/icons/exportCloud.svg';
import { BodyText2 } from 'app/common/typography';
import UserMenuButton from 'app/common/components/appShell/header/userMenu/link/userMenuButton';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import useIsExportVisible from 'app/network/hooks/useIsExportVisible';
import styles from './index.css';

type ExportProps = {
  hasNetworkExport: boolean;
};

const Export = ({ hasNetworkExport }: ExportProps) => {
  const modalPop = useAction(showModal);
  const { isExportVisible, isLoading } = useIsExportVisible();
  const { t } = useTranslation();
  const toggleSettingsMenu = useLegacyEvent('TOGGLE_SETTINGS_MENU');

  const onClick = useCallback(() => {
    modalPop(ExportModal, { hasNetworkExport });
    toggleSettingsMenu();
  }, [modalPop, toggleSettingsMenu, hasNetworkExport]);

  return (isExportVisible || hasNetworkExport) && !isLoading ? (
    <UserMenuButton onClick={onClick}>
      <UserMenuIcon IconComponent={ExportIcon} />
      <BodyText2 className={styles.uppercase}>{t('display.user.popup.link.export')}</BodyText2>
    </UserMenuButton>
  ) : null;
};

export default Export;
