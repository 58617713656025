import React from 'react';
import Skeleton from 'app/common/components/skeleton';
import styles from './skeleton.css';

export default function CommentsSkeleton() {
  return (
    <div className={styles.skeletonsWrapper}>
      <Skeleton className={styles.version} width={70} height={19} />
      <div className={styles.photoRow}>
        <Skeleton className={styles.avatar} circle={true} width={30} height={30} />
        <Skeleton className={styles.userName} width={180} height={19} />
        <Skeleton className={styles.photo} width={127} height={127} />
      </div>
      <div className={styles.messageRow}>
        <Skeleton className={styles.avatar} circle={true} width={30} height={30} />
        <Skeleton className={styles.userName} width={180} height={19} />
        <Skeleton className={styles.message} width={278} height={19} />
        <div className={styles.actions}>
          <Skeleton className={styles.message} width={50} height={19} />
          <Skeleton className={styles.message} width={50} height={19} />
        </div>
      </div>
      <div className={styles.lastRow}>
        <Skeleton className={styles.avatar} circle={true} width={30} height={30} />
        <Skeleton className={styles.userName} width={180} height={19} />
      </div>
      <div className={styles.btnWrapper}>
        <Skeleton className={styles.btnNewPhoto} width={134} height={50} />
      </div>
    </div>
  );
}
