import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';

// We use MultiBackend as backend to handle both HTML5 and touch events.
// See http://react-dnd.github.io/react-dnd/docs/overview for explanations on the concept of backend
// See https://github.com/LouisBrunner/dnd-multi-backend/ for MultiBackend
const MultiDeviceDragDropContext = ({ children }) => (
  <DndProvider debugMode={true} options={HTML5toTouch}>
    {children}
  </DndProvider>
);

MultiDeviceDragDropContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MultiDeviceDragDropContext;
