import BrandFilePayload from 'domain/brand/file/factory/BrandFilePayload';
import BrandFile from 'domain/brand/file';
import StateFactory from 'domain/state/factory';
import ImageMetadataFactory from 'domain/image/metadata/factory';

export default class BrandFileFactory {
  static createFromApiPayload(payload: BrandFilePayload): BrandFile {
    const state = StateFactory.createFromApiPayload(payload.state);
    const metadata = ImageMetadataFactory.createFromApiPayload(payload.metadata);

    return new BrandFile(payload.id, payload.brandId, state, payload.type, payload.fileURL, metadata);
  }

  static createCollectionFromApiPayload(payload: BrandFilePayload[]): BrandFile[] {
    return payload.map(BrandFileFactory.createFromApiPayload);
  }
}
