import React from 'react';
import UserMenu from 'app/common/components/appShell/header/userMenu';
import Directory from 'app/common/components/appShell/containers/header/userMenu/links/directory';
import Rights from 'app/common/components/appShell/containers/header/userMenu/links/rights';
import Export from 'app/display/common/components/appShell/header/userMenu/links/export';
import Import from 'app/common/components/appShell/containers/header/userMenu/links/import';
import Help from 'app/display/common/components/appShell/header/userMenu/links/help';
import Settings from 'app/display/common/components/appShell/header/userMenu/links/settings';
import Logout from 'app/display/common/components/appShell/header/userMenu/links/logout';
import Tags from 'app/display/common/components/appShell/header/userMenu/links/tags';

const DisplayUserMenu = () => {
  return (
    <UserMenu>
      <Directory />
      <Rights />
      <Export />
      <Import />
      <Tags />
      <Help />
      <Settings />
      <Logout />
    </UserMenu>
  );
};

export default DisplayUserMenu;
