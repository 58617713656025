import ApiExceptionType from 'app/common/actions/api/constants';
import notifyWarning from 'app/display/common/helpers/notifications/notifyWarning';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import getMessageFromApiException from 'app/common/helpers/api/getMessageFromApiException';

export default function notifyException(exception: unknown, options = {}) {
  const { message, exceptionType } = getMessageFromApiException(exception);
  if (exceptionType === ApiExceptionType.WARNING) {
    notifyWarning(message, options);
  } else {
    notifyError(message, options);
  }
}
