import React from 'react';
import CloseIconImage from 'assets/icons/close-light.svg';
import { HeadText5 } from 'app/common/typography';
import styles from './index.css';

export default function CloseIcon() {
  return (
    <HeadText5 interactive>
      <CloseIconImage className={styles.closeIcon} />
    </HeadText5>
  );
}
