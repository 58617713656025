import { components, DropdownIndicatorProps } from 'react-select';
import classNames from 'classnames';
import ArrowIcon from 'assets/icons/react-select-arrow.svg';
import React from 'react';
import { OptionSearchDropDown } from 'app/display/common/helpers/searchDropDown/convertValuesToOptions';
import styles from './index.css';

const DropdownIndicator = (props: DropdownIndicatorProps<OptionSearchDropDown, true>) => {
  const { isFocused } = props;
  const cssClass = classNames(styles.dropDownArrow, {
    [styles.isFocused]: isFocused,
  });
  return (
    <components.DropdownIndicator {...props}>
      <ArrowIcon className={cssClass} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
