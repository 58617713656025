import React from 'react';
import PhotoNotificationsSection from 'app/directory/user/sheet/view/components/notifications/categories/photo';
import SurveyNotificationsSection from 'app/directory/user/sheet/view/components/notifications/categories/survey';
import GuidelineNotificationsSection from 'app/directory/user/sheet/view/components/notifications/categories/guideline';
import DocumentNotificationsSection from 'app/directory/user/sheet/view/components/notifications/categories/document';
import EmailReportNotificationsSection from 'app/directory/user/sheet/view/components/notifications/categories/emailReport';
import styles from 'app/directory/user/sheet/view/components/notifications/index.css';

type NotificationsProps = {
  userId: string;
  testPhotoReportHref: string;
  testSurveyReportHref: string;
  userIdV4: string;
  photoReportFrequency: string;
  isActivityReportEnabled: boolean;
  isSurveyOwnerActivityReportEnabled: boolean;
};

const NotificationsTab = ({
  userId,
  testPhotoReportHref,
  testSurveyReportHref,
  userIdV4,
  photoReportFrequency,
  isActivityReportEnabled,
  isSurveyOwnerActivityReportEnabled,
}: NotificationsProps) => {
  return (
    <div className={styles.notificationsWrapper}>
      <PhotoNotificationsSection userId={userId} />
      <SurveyNotificationsSection userId={userId} />
      <GuidelineNotificationsSection userId={userId} />
      <DocumentNotificationsSection userId={userId} />
      <EmailReportNotificationsSection
        userId={userId}
        testPhotoReportHref={testPhotoReportHref}
        testSurveyReportHref={testSurveyReportHref}
        userIdV4={userIdV4}
        photoReportFrequency={photoReportFrequency}
        isActivityReportEnabled={isActivityReportEnabled}
        isSurveyOwnerActivityReportEnabled={isSurveyOwnerActivityReportEnabled}
      />
    </div>
  );
};

export default NotificationsTab;
