import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import { USERS_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import FullUser from 'domain/user/fullUser';
import FullUserFactory from 'domain/user/factory/fullUser';
import { useMemo } from 'react';

type Options = {
  useCache?: boolean;
};

export type UsersById = Record<string, FullUser>;

type Result = {
  users: FullUser[] | undefined;
  usersById: UsersById | undefined;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};

export default function useUsers(options: Options = {}): Result {
  const getUsers = useHttpGetRequest<FullUser[]>('/users', {
    transformResponse: FullUserFactory.createSortedCollectionFromApiPayload,
  });

  const {
    data: users,
    isLoading,
    isFetching,
    isError,
    error,
  } = useHttpQuery([USERS_QUERY_CACHE_KEY], getUsers, options);
  const usersById = useMemo(() => {
    return users?.reduce((acc, user) => {
      acc[user.id] = user;
      return acc;
    }, {} as UsersById);
  }, [users]);

  return { users, usersById, isLoading, isFetching, isError, error };
}
