import { combineReducers } from 'redux';
import { createResponsiveStateReducer } from 'redux-responsive';
import router from 'lib/router';
import createSingleton from 'lib/helpers/createSingleton';
import surveyReportReducer from 'app/network/survey/report/reducer';
import iwdConfig from 'app/common/reducers/iwdConfig';
import slideshow from 'app/common/reducers/slideshow';
import users from 'app/common/user/reducers/users';
import currentUser from 'app/common/user/reducers/currentUser';
import currentUserACL from 'app/common/user/reducers/currentUserACL';
import visitsDashboardData from 'app/network/report/visits/reducers/visitsDashboardData';
import sidebar from 'app/network/report/visits/reducers/sidebar';
import isAppNavigationOpen from 'app/common/reducers/isAppNavigationOpen';
import isLoadingBarVisible from 'app/common/reducers/isLoadingBarVisible';
import userAppInfos from 'app/common/user/reducers/userAppInfos';
import authReducer from 'app/common/reducers/auth';
import displayReducer from 'app/display/reducer';
import formReducer from 'app/common/form/reducers';
import directoryReducer from 'app/directory/reducer';
import storeReducer from 'app/network/store/common/reducers/reducers';

function createReducers() {
  // Additional Field in browser state to define our breakpoint for mobile layout
  const responsiveStateReducer = createResponsiveStateReducer(null, {
    extraFields: ({ lessThan, is }) => {
      const isMobile = lessThan.medium || is.medium;

      return {
        isMobile,
      };
    },
  });

  return combineReducers({
    router: router.getInstance().routerReducer,
    form: formReducer, // redux-form
    visitsDashboardData,
    users,
    currentUser: combineReducers({
      user: currentUser,
      acl: currentUserACL,
    }),
    sidebar,
    isAppNavigationOpen,
    isLoadingBarVisible,
    userAppInfos,
    browser: responsiveStateReducer,
    display: displayReducer,
    iwdConfig,
    auth: authReducer,
    surveyReport: surveyReportReducer,
    directory: directoryReducer,
    slideshow,
    store: storeReducer,
  });
}

export default createSingleton(createReducers);
