import Service from 'app/common/services/service';
import getUserTokenFromLoginCookie from 'app/common/helpers/auth/getUserTokenFromLoginCookie';
import WebSocketMessageTypes from 'domain/webSockets/messageTypes';
import WebSocketMessageHandler from 'app/common/services/webSockets/messageHandler';
import { QueryClient } from '@tanstack/react-query';
import WebSocketClient from 'app/common/services/webSockets/client';

export type WebsocketMessageEventData = {
  type: string;
  payload: Record<string, any>;
};

export default class WebSocketsService extends Service {
  private static instance: WebSocketsService;

  private authenticated = false;

  private queryClient: QueryClient;

  private messageHandler: WebSocketMessageHandler;

  private wss?: WebSocketClient;

  static getInstance(queryClient: QueryClient, webSocketClient?: WebSocketClient) {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new WebSocketsService(queryClient, webSocketClient);
    return this.instance;
  }

  constructor(queryClient: QueryClient, webSocketClient?: WebSocketClient) {
    super();

    this.queryClient = queryClient;
    this.messageHandler = new WebSocketMessageHandler(this.queryClient);

    if (webSocketClient) {
      this.wss = webSocketClient;

      this.wss.onOpen(this.onOpen.bind(this));
      this.wss.onMessage(this.onMessage.bind(this));
      this.wss.onError(this.onError.bind(this));
    }
  }

  onOpen() {
    this.authenticate();
  }

  onMessage(message: MessageEvent) {
    const eventData: WebsocketMessageEventData = JSON.parse(message.data);

    switch (eventData.type) {
      case WebSocketMessageTypes.AUTHENTICATION_SUCCESS:
        this.handleAuthenticationSuccess();
        break;
      default:
        this.messageHandler.processMessage(message);
    }
  }

  onError() {
    // We don't need to throw an error since it will crash the app, and sockets are not application critical
    console.error(`Error establishing websocket connection`);
  }

  sendMessage(data: Record<string, any>) {
    if (!this.wss) {
      console.error('No websocket connection has been established');
    } else if (!this.authenticated) {
      console.error('Unable to send unauthenticated message');
    } else {
      this.wss.sendMessage(JSON.stringify(data));
    }
  }

  authenticate() {
    this.wss?.sendMessage(
      JSON.stringify({
        type: 'AUTHENTICATE',
        payload: {
          token: getUserTokenFromLoginCookie(),
        },
      })
    );
  }

  handleAuthenticationSuccess() {
    this.authenticated = true;
  }
}
