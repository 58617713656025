export const NETWORK_NOTIFICATION = 'fr:iwd:reactEvents:NETWORK_NOTIFICATION';
export const DIRECTORY_TOGGLE_USER_STATUS = 'fr:iwd:reactEvents:DIRECTORY_TOGGLE_USER_STATUS';
export const DIRECTORY_ITEM_SELECTION = 'fr:iwd:reactEvents:DIRECTORY_ITEM_SELECTION';
export const ENABLE_SAVE_BUTTON = 'fr:iwd:reactEvents:ENABLE_SAVE_BUTTON';
export const SET_LEGACY_LOADING_BAR = 'fr:iwd:reactEvents:SET_LEGACY_LOADING_BAR';
export const TOGGLE_SLIDESHOW = 'fr:iwd:reactEvents:TOGGLE_SLIDESHOW';
export const REGISTER_PHOTOS = 'fr:iwd:reactEvents:REGISTER_PHOTOS';
export const UPDATE_PHOTOS = 'fr:iwd:reactEvents:UPDATE_PHOTOS';

declare global {
  interface DocumentEventMap {
    [NETWORK_NOTIFICATION]: CustomEvent;
    [DIRECTORY_TOGGLE_USER_STATUS]: CustomEvent;
    [DIRECTORY_ITEM_SELECTION]: CustomEvent;
    [ENABLE_SAVE_BUTTON]: CustomEvent;
    [SET_LEGACY_LOADING_BAR]: CustomEvent;
    [TOGGLE_SLIDESHOW]: CustomEvent;
    [REGISTER_PHOTOS]: CustomEvent;
    [UPDATE_PHOTOS]: CustomEvent;
  }
}

export default {
  NETWORK_NOTIFICATION,
  DIRECTORY_TOGGLE_USER_STATUS,
  DIRECTORY_ITEM_SELECTION,
  ENABLE_SAVE_BUTTON,
  SET_LEGACY_LOADING_BAR,
  TOGGLE_SLIDESHOW,
  REGISTER_PHOTOS,
  UPDATE_PHOTOS,
};
