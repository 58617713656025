import useUsers from 'app/common/hooks/useUsers';
import FullUser from 'domain/user/fullUser';

type Result = {
  user?: FullUser;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: any;
};

export default function useUser(userId: string): Result {
  const { usersById, isLoading, isFetching, isError, error } = useUsers();

  if (isLoading) {
    return { user: undefined, isLoading, isFetching, isError, error };
  }

  return { user: usersById?.[userId], isLoading, isFetching, isError, error };
}
