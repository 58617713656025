import { connect } from 'react-redux';
import toggleUserMenuVisibility from 'app/common/components/actions/toggleUserMenuVisibility';
import isUserMenuVisible from 'app/common/selectors/appShell/isUserMenuVisible';
import UserButton from 'app/common/components/appShell/header/userButton';

const mapStateToProps = state => ({
  isUserMenuVisible: isUserMenuVisible(state),
});

const mapDispatchToProps = dispatch => {
  return {
    onClick: () => {
      dispatch(toggleUserMenuVisibility());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserButton);
