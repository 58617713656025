import React from 'react';
import classNames from 'classnames';
import UserProfileLink from 'app/common/components/appShell/containers/header/userMenu/userProfileLink';
import useUserMenu from 'app/common/components/appShell/header/userMenu/index.hook';
import styles from './index.css';

type UserMenuProps = {
  children: React.ReactNode;
};

const UserMenu = ({ children }: UserMenuProps) => {
  const { isVisible, menuRef } = useUserMenu();

  const classes = classNames(styles.menu, {
    [styles.isVisible]: isVisible,
  });

  return (
    <aside className={classes} ref={menuRef}>
      <UserProfileLink />
      <div className={styles.container}>{children}</div>
    </aside>
  );
};

export default UserMenu;
