import useHttpGetCsvRequest from 'app/common/hooks/useHttpGetCsvRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import { GENERAL_QUERY_KEY_FOR_NO_CACHING } from 'app/common/constants/queryCacheKeys';
import download from 'lib/helpers/download';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import notifyException from 'app/display/common/helpers/notifications/notifyException';

export type GuidelineTagCsvData = {
  data: string;
  headers: Record<string, string | number>;
};
function useExportGuidelineTagCsv() {
  const request = useHttpGetCsvRequest<GuidelineTagCsvData>('/tags');
  // Always use GENERAL_QUERY_KEY_FOR_NO_CACHING with cacheTime: 0 when we do not need caching
  const { refetch } = useHttpQuery([GENERAL_QUERY_KEY_FOR_NO_CACHING], request, { enabled: false, cacheTime: 0 });

  return async () => {
    try {
      const response = await refetch();
      if (response.data) {
        const fileName = 'tag.csv';
        const mimeType = response.data.headers['content-type'];
        download(response?.data?.data, fileName, mimeType);
      }
    } catch (error) {
      assertIsNotCriticalHttpException(error);
      notifyException(error);
    }
  };
}

export default useExportGuidelineTagCsv;
