import { useDispatch, useSelector } from 'react-redux';
import isEditItemRouteAccessible from 'app/display/catalog/common/selectors/isEditItemRouteAccessible';
import isCurrentUserAllowedToCreateItem from 'app/common/selectors/acl/isCurrentUserAllowedToCreateItem';
import { useEffect } from 'react';
import { loadClosedSections } from 'app/display/catalog/common/actions/ui';
import useItemTypes from 'app/display/common/hooks/useItemTypes';
import { FETCH_ITEM_TYPES_SUCCESS } from 'app/display/catalog/common/actions/items';

export default function useCatalogRoute() {
  const dispatch = useDispatch();
  const editItemIsAllowed = useSelector(isEditItemRouteAccessible);
  const createItemIsAllowed = useSelector(isCurrentUserAllowedToCreateItem);
  const onSuccess = data => dispatch({ type: FETCH_ITEM_TYPES_SUCCESS, types: data });
  const { isLoading } = useItemTypes({ onSuccess });

  useEffect(() => {
    dispatch(loadClosedSections());
  }, [dispatch]);

  return {
    isLoading,
    createItemIsAllowed,
    editItemIsAllowed,
  };
}
