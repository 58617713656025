import has from 'lodash/has';
import { IwdConfig } from 'app/common/actions/iwdConfig';

export const REQUIRED_CONFIG_KEYS = [
  'instance',
  'instance.name',
  'instance.baseUrl',
  'instance.apiURL',
  'instance.logoUrl',
  'inventoryTabs',
  'hasMassplan',
  'hasVisit',
];

const validateConfig: (config: unknown) => asserts config is IwdConfig = config => {
  const errorKeys = REQUIRED_CONFIG_KEYS.reduce((acc: string[], configKey: string) => {
    if (!has(config, configKey)) {
      return [...acc, configKey];
    }

    return acc;
  }, []);

  if (errorKeys.length > 0) {
    const errorMessage = `iwdConfig missing required values: ${errorKeys.join(', ')}`;
    throw new Error(errorMessage);
  }
};

export default validateConfig;
