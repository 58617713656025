import React from 'react';
import i18next from 'i18next';
import styles from './index.css';

export const GUIDELINE_TAG_LIST_HEADER = 'guidelineTagListHeader';

export default function GuidelinesTagListHeader() {
  return (
    <div className={styles.tagList} data-testid={GUIDELINE_TAG_LIST_HEADER}>
      <div className={styles.tagListHeader}>
        <div className={styles.leftColumn}>{i18next.t('network.guidelines.tag.list.header.name')}</div>
        <div className={styles.rightColumn}>{i18next.t('network.guidelines.tag.list.header.count')}</div>
      </div>
    </div>
  );
}
