import { useSelector } from 'react-redux';
import getNetworkUrl from 'app/common/selectors/navMenu/getNetworkUrl';
import getDisplayUrl from 'app/common/selectors/navMenu/getDisplayUrl';
import isAppNavigationOpen from 'app/common/selectors/isAppNavigationOpen';
import closeAppNavigation from 'app/common/actions/appNavigation/close';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import { PATH_CATALOG_BROWSE, PATH_GUIDELINE_BROWSE } from 'config/routePaths';
import useAction from 'app/display/common/hooks/useAction';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import usePlatform from 'app/common/hooks/usePlatform';

export default function useOffCanvasMenu() {
  const { isEnabled: isPlatformMenuEnabled } = usePlatform();
  const matchCatalogRoutes = useLocationMatch(PATH_CATALOG_BROWSE, { exact: false });
  const matchGuidelineRoutes = useLocationMatch(PATH_GUIDELINE_BROWSE, { exact: false });
  const isSmallScreen = useSmallScreen();
  const appNavigationIsOpen = useSelector(isAppNavigationOpen);
  const displayUrl = useSelector(getDisplayUrl);
  const networkUrl = useSelector(state => getNetworkUrl(state, true));
  const closeNavigation = useAction(closeAppNavigation);

  let activeRoute = null;
  if (matchCatalogRoutes) {
    activeRoute = PATH_CATALOG_BROWSE;
  }
  if (matchGuidelineRoutes) {
    activeRoute = PATH_GUIDELINE_BROWSE;
  }

  return {
    displayUrl,
    networkUrl,
    closeNavigation,
    activeRoute,
    isVisible: appNavigationIsOpen && isSmallScreen && !isPlatformMenuEnabled,
  };
}
