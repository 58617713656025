import deprecated, { DeprecatedRelation, getDeprecatedNullableRelation } from 'lib/deprecated';
import enumerableProperties from 'lib/decorators/enumerableProperties';
import GuidelineState from 'domain/guideline/state';
import GuidelineFile, { GuidelineFileType } from 'domain/guideline/file';
import Period from 'domain/period';
import DateFormatter from 'lib/date/dateFormatter';
import FullUser from 'domain/user/fullUser';
import FileCollection from 'domain/fileCollection';
import { StoreInGuideline } from 'domain/store';
import { Cluster } from 'domain/store/cluster';

type GuidelineMarket = DeprecatedRelation;
export type GuidelineOwner = DeprecatedRelation;

export type GuidelineCatalog = {
  brands: DeprecatedRelation[];
  itemFolders: DeprecatedRelation[];
};

export enum GuidelineType {
  Guideline2d = 'GUIDELINE_2D',
  Guideline3d = 'GUIDELINE_3D',
}

@enumerableProperties('startDate', 'endDate', 'campaign', 'useCampaignDates', 'folder', 'personalFolder')
export default class Guideline {
  constructor(
    public readonly id: string,
    public readonly type: GuidelineType,
    public readonly name: string,
    public catalog: GuidelineCatalog,
    public markets: GuidelineMarket[],
    public readonly files: FileCollection<GuidelineFile>,
    public readonly state: GuidelineState,
    public owners: GuidelineOwner[],
    public readonly globalFolderId: string | null = null, // @todo see later for having at least a globalFolderId or personalFolderId
    public readonly personalFolderId: string | null = null, // @todo see later for having at least a globalFolderId or personalFolderId
    public readonly period: Period | null = null,
    public readonly campaignId: string | null = null,
    public readonly useCampaignPeriod: boolean = false,
    public readonly idv4: number | null = null,
    public readonly description: string | null = null,
    public readonly caption: string | null = null,
    public readonly linkedStores: StoreInGuideline[],
    public readonly linkedClusters: Cluster[],
    public readonly tags: string[]
  ) {
    // do nothing.
  }

  public is2D(): boolean {
    return this.type === GuidelineType.Guideline2d;
  }

  public is3D(): boolean {
    return this.type === GuidelineType.Guideline3d;
  }

  public hasSourceFile() {
    return this.files.hasFileType(GuidelineFileType.SOURCE);
  }

  public hasPreviewFile() {
    return this.files.hasFileType(GuidelineFileType.PREVIEW);
  }

  public getPreviewFile(): GuidelineFile | undefined {
    return this.files.getFileByType(GuidelineFileType.PREVIEW);
  }

  public getPreviewFileUrl(): string | undefined {
    return this.getPreviewFile()?.fileURL;
  }

  public hasThumbnailFile() {
    return this.files.hasFileType(GuidelineFileType.THUMBNAIL);
  }

  public getThumbnailFile(): GuidelineFile | undefined {
    return this.files.getFileByType(GuidelineFileType.THUMBNAIL);
  }

  public getThumbnailFileUrl(): string | undefined {
    return this.getThumbnailFile()?.fileURL;
  }

  public isPreviewGenerating(): boolean {
    if (!this.hasPreviewFile()) {
      // When creating a new guideline, we may not have a preview yet, so we check if we have a source file,
      // which means that the preview is being generated
      return this.hasSourceFile();
    }

    return this.getPreviewFile()?.state.isGenerating() === true;
  }

  public isMigrated(): boolean {
    return !(this.files.hasFileType(GuidelineFileType.SOURCEV4) && !this.files.hasFileType(GuidelineFileType.SOURCE));
  }

  public hasCampaign(): boolean {
    return this.campaignId != null;
  }

  public enable() {
    this.state.enable();
  }

  public disable() {
    this.state.disable();
  }

  public archive() {
    this.state.archive();
  }

  public unarchive() {
    this.state.unarchive();
  }

  public isUpdatable(): boolean {
    return !this.state.isArchived();
  }

  public isArchived(): boolean {
    return this.state.isArchived();
  }

  public isCurrentUserOwner(currentUser: FullUser | undefined) {
    return this.owners.some(owner => owner.id === currentUser?.id);
  }

  public hasTags(): boolean {
    return this.tags.length > 0;
  }

  /**
   * @deprecated use `period.startDate` instead
   */
  @deprecated()
  get startDate(): string | null {
    return this.period != null ? DateFormatter.formatForApiPayload(this.period.startDate) : null;
  }

  /**
   * @deprecated use `period.endDate` instead
   */
  @deprecated()
  get endDate(): string | null {
    return this.period != null ? DateFormatter.formatForApiPayload(this.period.endDate) : null;
  }

  /**
   * @deprecated use `useCampaignPeriod` instead
   */
  @deprecated()
  get useCampaignDates(): boolean {
    return this.useCampaignPeriod;
  }

  /**
   * @deprecated use `globalFolderId` instead
   */
  @deprecated()
  get folder() {
    return getDeprecatedNullableRelation(this.globalFolderId);
  }

  /**
   * @deprecated use `personalFolderId` instead
   */
  @deprecated()
  get personalFolder() {
    return getDeprecatedNullableRelation(this.personalFolderId);
  }

  /**
   * @deprecated use `campaignId` instead
   */
  @deprecated()
  get campaign() {
    return getDeprecatedNullableRelation(this.campaignId);
  }
}
