import { FETCH, FETCH_SUCCESS, FETCH_ERROR } from 'app/common/user/reducers/users';
import {
  FETCH_USER_APP_INFOS,
  FETCH_USER_APP_INFOS_ERROR,
  FETCH_USER_APP_INFOS_SUCCESS,
} from 'app/common/user/reducers/userAppInfos';

export function fetch(idList) {
  return {
    type: FETCH,
    idList,
  };
}

export function fetchSuccess(list) {
  return {
    type: FETCH_SUCCESS,
    list,
  };
}

export function fetchError(error) {
  return {
    type: FETCH_ERROR,
    error,
  };
}

export function fetchUserAppInfos(userUUID, appName) {
  return {
    type: FETCH_USER_APP_INFOS,
    userUUID,
    appName,
  };
}

export function fetchUserAppInfosSuccess(userUUID, appName, appInfos) {
  return {
    type: FETCH_USER_APP_INFOS_SUCCESS,
    userUUID,
    appName,
    appInfos,
  };
}

export function fetchUserAppInfosError(userUUID, appName, error) {
  return {
    type: FETCH_USER_APP_INFOS_ERROR,
    userUUID,
    appName,
    error,
  };
}

export const APP_BRIEFCASE = 'briefcase';
