import PropTypes from 'prop-types';
import React from 'react';
import i18next from 'i18next';

import Modal from '..';

class ForbiddenModal extends React.PureComponent {
  static propTypes = {
    onHideModal: PropTypes.func.isRequired,
  };

  render() {
    console.info(__filename, 'ForbiddenModal::render()', this.props);
    return (
      <Modal onClose={this.props.onHideModal} title={i18next.t('popup.error.forbidden.title')}>
        <div>
          <span>{i18next.t('popup.error.forbidden.message')}</span>
        </div>
      </Modal>
    );
  }
}

export { ForbiddenModal };
export default ForbiddenModal;
