import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

import CloseIcon from 'assets/icons/close.svg';

import Button from 'app/display/common/components/button';
import TopBarSeparator from 'app/display/common/components/list/topBar/actions/separator';
import styles from 'app/display/common/components/list/selection.css';
import { DEFAULT_COLOR_PALETTE, HeadText5, TextHoverArea } from 'app/common/typography';
import { BUTTON_DESELECT_ALL_LIST, BUTTON_SELECT_ALL_LIST } from 'app/common/constants/userflow';
import { COLOR_PRIMARY, COLORS_ON } from 'app/common/style/colors';
import colorPalettes from 'app/common/typography/colors/palettes';

/**
 * @example ./selection.md
 */
class ListSelection extends PureComponent {
  static propTypes = {
    count: PropTypes.number,
    onDeselectAll: PropTypes.func.isRequired,
    onSelectAll: PropTypes.func.isRequired,
    selectionCountLabel: PropTypes.string.isRequired,
    isSelectDisabled: PropTypes.bool,
    isDeselectDisabled: PropTypes.bool,
    palette: PropTypes.oneOf(Object.keys(colorPalettes)),
    on: PropTypes.oneOf(COLORS_ON),
  };

  static defaultProps = {
    count: 0,
    isSelectDisabled: false,
    isDeselectDisabled: false,
    palette: DEFAULT_COLOR_PALETTE,
    on: COLOR_PRIMARY,
  };

  hasSelectedItems() {
    return this.props.count > 0;
  }

  renderItemSelected() {
    const { onDeselectAll, selectionCountLabel, isDeselectDisabled, palette, on } = this.props;

    return (
      <Button
        id="deselectAllButton"
        className={styles.deselectAllButton}
        onClick={onDeselectAll}
        role="button"
        tabIndex={0}
        isDisabled={isDeselectDisabled}
        dataTestId={BUTTON_DESELECT_ALL_LIST}
      >
        <TextHoverArea className={styles.textHoverArea} palette={palette} on={on}>
          <HeadText5 className={styles.iconWrapper} palette={palette} on={on}>
            <CloseIcon title={i18next.t('display.list.deselectAll')} className={styles.deselectAllIcon} />
          </HeadText5>
          <HeadText5 className={classNames(styles.uppercase, styles.selectionCountText)} palette={palette} on={on}>
            {selectionCountLabel}
          </HeadText5>
        </TextHoverArea>
      </Button>
    );
  }

  renderSelectAll() {
    const { onSelectAll, isSelectDisabled, palette, on } = this.props;
    return (
      <Button
        id="selectAllButton"
        onClick={onSelectAll}
        isDisabled={isSelectDisabled}
        data-testid={BUTTON_SELECT_ALL_LIST}
      >
        <HeadText5 className={styles.uppercase} interactive={!isSelectDisabled} palette={palette} on={on}>
          {i18next.t('display.list.selectAll')}
        </HeadText5>
      </Button>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        {this.hasSelectedItems() && this.renderItemSelected()}
        {this.hasSelectedItems() && <TopBarSeparator />}
        {this.renderSelectAll()}
      </div>
    );
  }
}

export { ListSelection };
export default ListSelection;
