import { combineReducers } from 'redux';

import marketReducer from 'app/common/reducers/markets';
import modal from 'app/common/reducers/modal';
import itemsReducer from 'app/display/catalog/common/reducers/items';
import catalogItemsMarketsDataReducer from 'app/display/catalog/common/reducers/items/marketsData';

import itemReducer from 'app/display/catalog/sheet/view/reducers/item';
import foldersReducer from 'app/display/catalog/common/reducers/folders';
import catalogUIReducer from 'app/display/catalog/common/reducers/ui';
import catalogItemTypesReducer from 'app/display/catalog/common/reducers/itemTypes';
import brands from 'app/display/common/reducers/brands';
import model3ds from 'app/display/common/reducers/model3ds';
import sidebarReducer from 'app/display/common/reducers/sidebar';
import listReducer from 'app/display/common/reducers/list';
import selectFolderReducer from 'app/display/common/ui/modals/reducers/selectFolder';
import appShellUIReducer from 'app/common/reducers/ui';

import catalogListSelectionReducer from 'app/display/catalog/list/common/reducers/selection';
import catalogListActionsPopupReducer from 'app/display/catalog/list/common/reducers/actionsPopup';
import catalogListSortByPopupReducer from 'app/display/catalog/list/common/reducers/sortByPopup';
import catalogListAddEntryMenuReducer from 'app/display/catalog/list/common/reducers/addEntryMenu';

import catalogItemViewActionsPopupReducer from 'app/display/catalog/sheet/view/reducers/actionsPopup';
import guidelineEditorActionsPopupReducer from 'app/display/guideline/sheet/open/reducers/actionsPopup';

import guidelineListSearchReducer from 'app/display/guideline/list/search/reducers';
import catalogManagerReducer from 'app/display/common/modules/catalogManager/reducers/catalogManagerModal';
import personalFoldersReducer from 'app/display/guideline/common/reducers/personalFolders';
import guidelineListReducer from 'app/display/guideline/list/common/reducers/list';
import guidelineSheetReducer from 'app/display/guideline/sheet/view/reducers/sheet';
import guidelineGlobalFiltersReducer from 'app/display/guideline/list/common/reducers/globalFilters';
import guidelinesByIdReducer from 'app/display/guideline/common/reducers/guidelinesById';
import campaignsByIdReducer from 'app/display/guideline/common/reducers/campaignsById';
import campaignViewReducer from 'app/display/guideline/campaigns/view/reducers/campaign';
import guidelineListAddEntryMenuReducer from 'app/display/guideline/common/reducers/addEntryMenu';
import guidelineListSortByPopupReducer from 'app/display/guideline/list/common/reducers/sortByPopup';
import guidelineGlobalFoldersReducer from 'app/display/guideline/common/reducers/globalFolders';

import importFilesReducer from 'app/display/import/reducers/files';
import importUploadReducer from 'app/display/import/reducers/upload';
import importProcessdReducer from 'app/display/import/reducers/import';

import guidelinesDataByIdReducer from 'app/display/guideline/document/settings/reducers/guidelinesDataById';

export default combineReducers({
  modal,
  items: itemsReducer,
  item: itemReducer,
  folders: foldersReducer,
  markets: marketReducer,
  brands,
  model3ds,
  sidebar: sidebarReducer,
  list: listReducer,
  import: combineReducers({
    files: importFilesReducer,
    upload: importUploadReducer,
    process: importProcessdReducer,
  }),
  ui: combineReducers({
    appshell: appShellUIReducer,
    catalog: catalogUIReducer,
    modals: combineReducers({
      selectFolder: selectFolderReducer,
      catalogManager: catalogManagerReducer,
    }),
  }),
  catalog: combineReducers({
    items: combineReducers({
      marketsData: catalogItemsMarketsDataReducer,
    }),
    list: combineReducers({
      actionsPopup: catalogListActionsPopupReducer,
      sortByPopup: catalogListSortByPopupReducer,
      selection: catalogListSelectionReducer,
      addEntryMenu: catalogListAddEntryMenuReducer,
    }),
    item: combineReducers({
      view: combineReducers({
        actionsPopup: catalogItemViewActionsPopupReducer,
      }),
    }),
    itemTypes: catalogItemTypesReducer,
  }),
  guideline: combineReducers({
    guidelinesById: guidelinesByIdReducer,
    guidelinesDataById: guidelinesDataByIdReducer,
    campaigns: campaignsByIdReducer,
    campaign: combineReducers({ view: campaignViewReducer }),
    sheet: guidelineSheetReducer,
    list: guidelineListReducer,
    personalFolders: personalFoldersReducer,
    globalFilters: guidelineGlobalFiltersReducer,
    addEntryMenu: guidelineListAddEntryMenuReducer,
    sortByPopup: guidelineListSortByPopupReducer,
    globalFolders: guidelineGlobalFoldersReducer,
    search: guidelineListSearchReducer,
    editor: combineReducers({
      actionsPopup: guidelineEditorActionsPopupReducer,
    }),
  }),
});
