import React from 'react';
import DisplayUncontrolledCollapsePanel from 'app/display/common/components/collapsePanel/uncontrolled';
import NotificationRow from 'app/directory/user/sheet/view/components/notifications/row';
import { useTranslation } from 'react-i18next';
import styles from 'app/directory/user/sheet/view/components/notifications/index.css';

const DocumentNotificationsSection = ({ userId }: { userId: string }) => {
  const { t } = useTranslation();
  return (
    <DisplayUncontrolledCollapsePanel
      title={t('directory.userSheet.notificationsTab.document.title')}
      classNameContent={styles.panelContent}
    >
      <NotificationRow name="documentShared" userId={userId} />
    </DisplayUncontrolledCollapsePanel>
  );
};

export default DocumentNotificationsSection;
