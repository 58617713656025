import React, { forwardRef } from 'react';
import classNames from 'classnames';
import useRefCallback from 'lib/hooks/useRefCallback';
import useIsScrollAtTop from 'lib/dom/hooks/useIsScrollAtTop';
import InfiniteScroller, { InfiniteScrollerProps } from 'app/common/infiniteScroller/components';
import { ReactChildren } from 'domain/lib/react';
import styles from './scrollable.css';

/**
 * Display a scrollable list with a shadow on top while scrolling.
 *
 * Optionally you can provide a ref that will be bound to the infiniteScroller, for example to get
 * the scroll state (e.g. offset) or to manipulate the scroll (e.g. scroll up/down).
 */
type ScrollableListProps = { fetchMoreItems?: () => void; children: ReactChildren };

const ScrollableList = forwardRef<InfiniteScrollerProps, ScrollableListProps>(function ScrollableListWithRef(
  { fetchMoreItems, children },
  ref
) {
  const { setRef, domNode } = useRefCallback(ref);
  const isScrollAtTop = useIsScrollAtTop(domNode);

  const classes = classNames(styles.scroller, {
    [styles.withShadow]: domNode != null && !isScrollAtTop,
  });

  return (
    <div className={classes}>
      <div className={styles.body}>
        <InfiniteScroller onBottomReached={fetchMoreItems} pixelsOffset={300} ref={setRef}>
          {children}
        </InfiniteScroller>
      </div>
    </div>
  );
});

export default ScrollableList;
