import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import DelayedRedirectRoute from 'app/common/components/route/delayedRedirect';
import ForbiddenPage from 'app/common/components/errors/forbidden';

const PrivateRoute = ({
  isAuthorized,
  path,
  component,
  forbiddenComponent,
  exact,
  strict,
  redirectTo,
  redirectDelay,
}) => {
  const finalComponent = isAuthorized ? component : forbiddenComponent;

  if (!isAuthorized && redirectTo != null) {
    return (
      <DelayedRedirectRoute
        path={path}
        component={finalComponent}
        exact={exact}
        strict={strict}
        redirectTo={redirectTo}
        redirectDelay={redirectDelay}
      />
    );
  }

  return <Route path={path} component={finalComponent} exact={exact} strict={strict} />;
};

/**
 * component property is not required, as you can create a non-authorized route and rely on default forbiddenComponent,
 * if, for ex., the authorization is computed before/already known
 *
 * ex: <PrivateRoute isAuthorized={false} redirectTo="http://www.google.com" />
 */
PrivateRoute.propTypes = {
  ...DelayedRedirectRoute.propTypes,
  isAuthorized: PropTypes.bool,
  forbiddenComponent: PropTypes.elementType,
  component: PropTypes.elementType,
};

PrivateRoute.defaultProps = {
  ...DelayedRedirectRoute.defaultProps,
  component: null,
  // We could set `isAuthorized` as a required property in propTypes
  // However, PropTypes validation is just a warning and
  // does not ensure `isAuthorized` will be set at all.
  //
  // Therefore, by default, ForbiddenRoutes are forbidden.
  isAuthorized: false,
  // ForbiddenPage covers 99% of our use cases.
  forbiddenComponent: ForbiddenPage,
};

export default PrivateRoute;
