import React from 'react';
import { RenderSlideContainerProps } from 'yet-another-react-lightbox';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import isRightSidePanelOpen from 'app/common/selectors/slideshow/isRightSidePanelOpen';
import styles from './container.css';

type SlideshowInformationContainerPropTypes = RenderSlideContainerProps & {
  isFilmstripOpen: boolean;
};

export default function SlideshowInformationContainer({
  children,
  isFilmstripOpen,
}: SlideshowInformationContainerPropTypes) {
  const isOpen = useSelector(isRightSidePanelOpen);
  return (
    <div
      className={classNames(styles.container, {
        [styles.infoPanelOpen]: isOpen,
        [styles.filmStripOpen]: isFilmstripOpen,
      })}
    >
      {children}
    </div>
  );
}
