import React from 'react';
import PropTypes from 'prop-types';
import Link from 'app/common/components/link';
import getDisplayUrl from 'app/common/selectors/navMenu/getDisplayUrl';
import useParamSelector from 'app/display/common/hooks/useParamSelector';

const DisplayLink = ({ className, target, alt, children }) => {
  const href = useParamSelector(getDisplayUrl, true);

  return (
    <Link className={className} href={href} target={target} alt={alt}>
      {children}
    </Link>
  );
};

DisplayLink.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
};

DisplayLink.defaultProps = {
  className: null,
  target: null,
  alt: null,
  children: null,
};

export default DisplayLink;
