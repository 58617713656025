import React, { useEffect } from 'react';
import ActionBar from 'app/display/common/components/actionBar';
import ActionBarIcon from 'app/display/common/components/actionBar/actionBarIcon';
import DirectoryActionBarCancelLink from 'app/directory/user/sheet/view/components/actionBar/cancelLink';
import DotsIcon from 'assets/icons/dots.svg';
import DirectoryPopupActions from 'app/directory/user/sheet/view/components/popupActions';
import useAction from 'app/display/common/hooks/useAction';
import { openDirectoryActionsPopup } from 'app/directory/user/sheet/common/actions';
import { useDispatch, useSelector } from 'react-redux';
import isDirectoryActionsPopupVisible from 'app/directory/user/sheet/common/selectors/isActionsPopupVisible';
import DirectoryContentMask from 'app/directory/user/sheet/view/components/actionBar/contentMask';
import DirectoryActionBarEditButton from 'app/directory/user/sheet/view/components/actionBar/editButton';
import ActionBarSeparator from 'app/display/common/components/actionBar/actionBarSeparator';
import loadConfig from 'app/common/actions/iwdConfig';
import DirectoryActionBarArrowButtons from 'app/directory/user/sheet/view/components/actionBar/arrowButtons';
import useCanEditUser from 'app/directory/user/sheet/common/hooks/useCanEditUser';

type DirectoryActionBarProps = {
  backLinkUrl: string;
  modifyUrl: string;
  transferContentUrl: string;
  canDuplicateUser: boolean;
  canTransferContent: boolean;
  canSeeDirectory: boolean;
  nextUserUrl: string | null;
  previousUserUrl: string | null;
  userId: string;
  isCurrentUser: boolean;
};

export default function DirectoryActionBar({
  backLinkUrl,
  canDuplicateUser,
  canTransferContent,
  modifyUrl,
  canSeeDirectory,
  nextUserUrl,
  previousUserUrl,
  userId,
  ...props
}: DirectoryActionBarProps) {
  const onMenuClick = useAction(openDirectoryActionsPopup);
  const directoryActionsPopupIsVisible = useSelector(isDirectoryActionsPopupVisible);
  const { canEditUser } = useCanEditUser(userId);
  const isMenuAvailable = canDuplicateUser || canEditUser || canTransferContent;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadConfig());
  }, [dispatch]);

  return (
    <>
      <DirectoryContentMask isVisible={directoryActionsPopupIsVisible} />
      {isMenuAvailable && (
        <DirectoryPopupActions
          {...props}
          canDuplicateUser={canDuplicateUser}
          canEditUser={canEditUser}
          canTransferContent={canTransferContent}
          modifyUrl={modifyUrl}
          userId={userId}
        />
      )}
      <ActionBar
        leftGroup={<DirectoryActionBarCancelLink to={backLinkUrl} />}
        rightGroup={
          <>
            <DirectoryActionBarEditButton modifyUrl={modifyUrl} userId={userId} />
            {isMenuAvailable && (
              <ActionBarIcon
                Icon={DotsIcon}
                width={30}
                height={30}
                onClick={onMenuClick}
                dataTestId="actionPopupOpen"
              />
            )}
            {canSeeDirectory && (
              <>
                <ActionBarSeparator />
                <DirectoryActionBarArrowButtons nextUserUrl={nextUserUrl} previousUserUrl={previousUserUrl} />
              </>
            )}
          </>
        }
      />
    </>
  );
}
