import React from 'react';
import { useLightboxState } from 'yet-another-react-lightbox';
import Button from 'app/display/common/components/button';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import TagIcon from 'assets/icons/tag-grid.svg';
import useShowModal from 'app/display/common/hooks/useShowModal';
import TagPhotoModal from 'app/common/components/slideshow/tagPhoto/modal';
import Pill from 'app/common/components/slideshow/buttons/pill';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import styles from './icon.css';

export const SLIDESHOW_TAG_PHOTO_ICON_TESTID = 'slideshowTagPhotoIcon';

export default function TagPhotoIcon() {
  const { currentSlide: photo } = useLightboxState();
  const showModal = useShowModal(TagPhotoModal, {
    photo,
  });
  const { aclRight: canTagPhoto } = useCurrentUserAclRight('library.photo.tag');

  return (
    <div className={styles.icon}>
      <Button
        className="yarl__button"
        onClick={showModal}
        isDisabled={!canTagPhoto}
        dataTestId={SLIDESHOW_TAG_PHOTO_ICON_TESTID}
      >
        <>
          <SlideshowButton Icon={TagIcon} />
          {photo && photo.tags.length > 0 && <Pill countNumber={photo?.tags.length} />}
        </>
      </Button>
    </div>
  );
}
