import React from 'react';
import styles from 'app/display/common/components/list/topBar/actions/separator.css';

/**
 * A vertical separator for the TopBarActions component.
 *
 * @see [Zeplin link](https://zpl.io/25NRmNQ)
 * @example ./separator.md
 */
const TopBarSeparator = () => {
  return <div className={styles.separator} />;
};

export default TopBarSeparator;
