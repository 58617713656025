import FullUser from 'domain/user/fullUser';
import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import queryClient from 'lib/queryClient';
import { USERS_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import findIndex from 'lodash/findIndex';

export default function useToggleUserStatus(userId: string) {
  const toggleUserStatusRequest = useHttpPutRequest(`privates/licensing/users/activate`);

  const { mutateAsync } = useHttpMutation(toggleUserStatusRequest, {
    onSuccess: async () => {
      return queryClient.getInstance().setQueryData<FullUser[]>([USERS_QUERY_CACHE_KEY], users => {
        const userToUpdateIndex = findIndex(users, user => user.id === userId);
        users?.[userToUpdateIndex].toggleStatus();
        return users;
      });
    },
  });

  return function toggleUserStatus(user: FullUser) {
    return mutateAsync([
      {
        userId: user.id,
        activated: !user.state.enabled,
      },
    ]);
  };
}
