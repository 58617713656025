import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Tooltip as TippyTooltip } from 'react-tippy';

const DEFAULT_DELAY = 0;
const DEFAULT_THEME = 'iwd light';

/**
 * Wrapper for Tooltip provided by react-tippy. It forces the delay and theme to
 * be respectively 500 and "iwd light". It also defines the default position to
 * "bottom".
 *
 * Example:
 *
 * <Tooltip title="It does something" position="top">
 *   <button>Do something</button>
 * </Tooltip>
 */
export default class Tooltip extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,

    /**
     * The title to display in the tooltip
     */
    title: PropTypes.string,

    /**
     * The position of the tootip, it is passed directly to Tippy Tooltip,
     * see https://github.com/tvkhoa/react-tippy#props.
     */
    position: PropTypes.oneOf([
      'top',
      'top-start',
      'top-end',
      'bottom',
      'bottom-start',
      'bottom-end',
      'left',
      'left-start',
      'left-end',
      'right',
      'right-start',
      'right-end',
    ]),
  };

  static defaultProps = {
    position: 'bottom',
    title: null,
  };

  render() {
    // this is to make sure Popper.js (the lib behind (react) tippy) moves the
    // tooltip so that it's fully visible. By default, it tries to do that based
    // on the size of the `body` but this is tricked because the `body` is wider
    // than the viewport.
    // Setting `boundariesElement` to `viewport` avoids that and is safe for
    // tooltips.
    // see https://popper.js.org/popper-documentation.html#modifiers..preventOverflow.boundariesElement
    const popperOptions = {
      modifiers: {
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
      },
    };
    const { children, ...otherProps } = this.props;

    return (
      <TippyTooltip
        useContext={true}
        delay={DEFAULT_DELAY}
        theme={DEFAULT_THEME}
        popperOptions={popperOptions}
        {...otherProps}
      >
        {children}
      </TippyTooltip>
    );
  }
}
