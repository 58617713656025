import UnableToFetchUserException from 'lib/errors/UnableToFetchUserException';

export default function validateProfileUser(profile: any) {
  if (profile.display === undefined) {
    throw new UnableToFetchUserException('Unabled to fetch Display profile role as it is undefined');
  }
  if (profile.explore === undefined) {
    throw new UnableToFetchUserException('Unabled to fetch Explore profile role as it is undefined');
  }
  if (profile.network === undefined) {
    throw new UnableToFetchUserException('Unabled to fetch Network profile role as it is undefined');
  }
}
