import array2map from 'lib/helpers/array2map';
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR, STATUS_INITIAL } from 'app/common/reducers/status';
import {
  CREATE_BRAND_START,
  CREATE_BRAND_SUCCESS,
  CREATE_BRAND_ERROR,
  UPDATE_BRAND_START,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_ERROR,
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_ERROR,
  UPLOAD_BRAND_LOGO_START,
  UPLOAD_BRAND_LOGO_ERROR,
  UPLOAD_BRAND_LOGO_SUCCESS,
  DELETE_BRAND_START,
  DELETE_BRAND_ERROR,
  DELETE_BRAND_SUCCESS,
} from 'app/display/common/actions';
import Brand from 'domain/brand';
import BrandFile from 'domain/brand/file';
import BrandFactory from 'domain/brand/factory';
import omit from 'lodash/omit';

export const BRAND_TYPE_GENERIC = 'generic';

export type BrandEntities = {
  [key: string]: Brand;
};

type BrandState = {
  status: string;
  entities: BrandEntities;
};

type BrandAction = {
  type: string;
  brands: Brand[] | null;
  brand: Brand | null;
  brandLogo: BrandFile | null;
  brandId: string | null;
};

export const initialState: BrandState = {
  status: STATUS_INITIAL,
  entities: {},
};

export default function brands(state: BrandState = initialState, action: BrandAction): BrandState {
  switch (action.type) {
    case FETCH_BRANDS_REQUEST:
      return {
        ...state,
        status: STATUS_LOADING,
        entities: {},
      };
    case FETCH_BRANDS_SUCCESS: {
      return {
        ...state,
        status: STATUS_SUCCESS,
        entities: array2map(action.brands, 'id'),
      };
    }
    case FETCH_BRANDS_ERROR:
      return {
        ...state,
        status: STATUS_ERROR,
        entities: {},
      };
    case UPDATE_BRAND_START:
      return state;
    case UPDATE_BRAND_ERROR:
      return state;
    case UPDATE_BRAND_SUCCESS:
      if (state.entities) {
        if (action.brand != null && state.entities[action.brand.id] != null) {
          const entities = {
            ...state.entities,
            [action.brand.id]: action.brand,
          };
          return {
            ...state,
            entities,
          };
        }
      }
      return state;
    case CREATE_BRAND_START:
      return state;
    case CREATE_BRAND_ERROR:
      return state;
    case CREATE_BRAND_SUCCESS:
      if (state.entities && action.brand != null) {
        const entities = { ...state.entities, [action.brand.id]: action.brand };
        return {
          ...state,
          entities,
        };
      }
      return state;
    case UPLOAD_BRAND_LOGO_START:
      return state;
    case UPLOAD_BRAND_LOGO_ERROR:
      return state;
    case UPLOAD_BRAND_LOGO_SUCCESS:
      if (state.entities && action.brandLogo != null) {
        const brand = state.entities[action.brandLogo.brandId];
        if (brand != null) {
          return {
            ...state,
            entities: {
              ...state.entities,
              [brand.id]: BrandFactory.createFromBrandAndLogo(brand, action.brandLogo),
            },
          };
        }
      }
      return state;
    case DELETE_BRAND_START:
      return state;
    case DELETE_BRAND_ERROR:
      return state;
    case DELETE_BRAND_SUCCESS:
      if (state.entities && action.brandId != null) {
        const newBrands = omit(state.entities, action.brandId);
        return {
          ...state,
          entities: newBrands,
        };
      }
      return state;
    default:
      return state;
  }
}
