export enum TransferUserContentSettings {
  shouldTransferFolders = 'shouldTransferFolders',
  shouldTransferGuidelines = 'shouldTransferGuidelines',
  shouldTransferPhotos = 'shouldTransferPhotos',
  shouldTransferSurveys = 'shouldTransferSurveys',
  shouldTransferDocument = 'shouldTransferDocument',
}

interface TransferUserContentFormValues extends Record<TransferUserContentSettings, boolean> {
  selectedUser: string;
}

export default TransferUserContentFormValues;
