import React from 'react';
import Form from 'app/display/common/components/form';
import LicenseTabForm from 'app/directory/user/sheet/common/components/form/license';
import { USER_SHEET_LICENSE_TAB_FORM } from 'app/directory/user/sheet/common/constants';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import validateUserSheetForm from 'app/directory/user/sheet/common/helpers/validateUserSheetForm';
import { useTranslation } from 'react-i18next';
import useCreateUserFormInitialValues from 'app/directory/user/sheet/create/hooks/useCreateUserFormInitialValues';
import useLicenseProjects from 'app/directory/user/sheet/common/hooks/useLicenseProjects';
import useLicenseProvisioningData from 'app/directory/user/sheet/common/hooks/useLicenseProvisioningData';
import LicenseTabFormSkeleton from 'app/directory/user/sheet/common/components/form/license/index.skeleton';
import useAction from 'app/display/common/hooks/useAction';
import { disableUserSheetSaveButton } from 'app/directory/user/sheet/common/actions';

export default function CreateUserForm() {
  const { t } = useTranslation();
  const submitForm = useLegacyEvent('SUBMIT_USER_FORM');
  const disableSaveButton = useAction(disableUserSheetSaveButton);
  const initialValues = useCreateUserFormInitialValues();

  const { isLoading: isLoadingProjects } = useLicenseProjects();
  const { isLoading: isLoadingProvisioning } = useLicenseProvisioningData();

  const isLoading = isLoadingProjects || isLoadingProvisioning;

  if (isLoading) {
    return <LicenseTabFormSkeleton />;
  }

  const handleSubmit = () => {
    disableSaveButton();
    submitForm();
  };

  return (
    <Form
      name={USER_SHEET_LICENSE_TAB_FORM}
      component={LicenseTabForm}
      initialValues={initialValues}
      enableReinitialize={true}
      destroyOnUnmount={false}
      onSubmit={handleSubmit}
      validate={values => validateUserSheetForm(values, t)}
    />
  );
}
