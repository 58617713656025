import React from 'react';
import Skeleton from 'app/common/components/skeleton';
import styles from './index.skeleton.css';

const NavMenuSkeleton = () => {
  return (
    <div className={styles.navMenu}>
      <div className={styles.navMenuLeft}>
        <div className={styles.switch}>
          <Skeleton width={173} height={20} />
        </div>
        <div className={styles.navItem}>
          <Skeleton width={90} height={19} />
        </div>
        <div className={styles.navItem}>
          <Skeleton width={90} height={19} />
        </div>
      </div>
      <div className={styles.userIcon}>
        <Skeleton width={34} height={34} circle={true} />
      </div>
    </div>
  );
};

export default NavMenuSkeleton;
