export const COLOR_PRIMARY = 'color-primary';
export const COLOR_SECONDARY = 'color-secondary';
export const COLOR_SUCCESS = 'color-success';
export const COLOR_ERROR = 'color-error';

export const COLOR_STORM = 'color-storm';
export const COLOR_KINGFISHER = 'color-kingfisher';
export const COLOR_CORNFLOWER = 'color-cornflower';
export const COLOR_SNOW = 'color-snow';
export const COLOR_PIGEON = 'color-pigeon';
export const COLOR_BLUEJAY = 'color-bluejay';
export const COLOR_PEBBLE = 'color-pebble';
export const COLOR_TIMBERWOLF = 'color-timberwolf';
export const COLOR_CLOUD = 'color-cloud';
export const COLOR_WILDSAND = 'color-wildsand';
export const COLOR_MACAW = 'color-macaw';
export const COLOR_PEACOCK = 'color-peacock';
export const COLOR_FERN = 'color-fern';
export const COLOR_MANTIS = 'color-mantis';
export const COLOR_AMBER = 'color-amber';
export const COLOR_APRICOT = 'color-apricot';
export const COLOR_FLAMINGO = 'color-flamingo';
export const COLOR_HIBISCUS = 'color-hibiscus';
export const COLOR_AMARANTH = 'color-amaranth';

export const COLORS = [
  COLOR_STORM,
  COLOR_KINGFISHER,
  COLOR_CORNFLOWER,
  COLOR_SNOW,
  COLOR_PIGEON,
  COLOR_BLUEJAY,
  COLOR_PEBBLE,
  COLOR_TIMBERWOLF,
  COLOR_CLOUD,
  COLOR_WILDSAND,
  COLOR_MACAW,
  COLOR_PEACOCK,
  COLOR_FERN,
  COLOR_MANTIS,
  COLOR_AMBER,
  COLOR_APRICOT,
  COLOR_FLAMINGO,
  COLOR_HIBISCUS,
  COLOR_AMARANTH,
  COLOR_BLUEJAY,
];

export const COLORS_ON = [COLOR_PRIMARY, COLOR_SECONDARY, COLOR_SUCCESS, COLOR_ERROR];
