import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { Field, change, getFormInitialValues } from 'redux-form';
import useLicenseProvisioningDataBySelectedProject from 'app/directory/user/sheet/common/hooks/useLicenseProvisioningDataBySelectedProject';
import LicenseFieldNoProjectSelected from 'app/directory/user/sheet/common/components/form/license/license/noProjectSelected';
import UnavailableLicenses from 'app/directory/user/sheet/common/components/form/license/license/unavailableLicenses';
import Radio from 'app/common/components/forms/radio';
import styles from 'app/directory/user/sheet/common/components/form/license/index.css';
import useReduxForm from 'app/display/common/hooks/useReduxForm';
import useAction from 'app/display/common/hooks/useAction';
import { LICENSE_FREE, LICENSE_PRO_FREE } from 'app/directory/user/sheet/common/constants/rolesByLicense';
import { PROFILE_NETWORK_FREE } from 'app/common/user/helpers/profile/constants';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import inputStyles from './index.css';

export default function LicenseField() {
  const { t } = useTranslation();
  const { licenseProvisioningData, projectSelected } = useLicenseProvisioningDataBySelectedProject();
  const { form: formName } = useReduxForm();
  const initialValues: Record<any, any> = useSelector(getFormInitialValues(formName));
  const changeFieldValue = useAction(change);
  const unavailableLicenses =
    licenseProvisioningData
      ?.filter(
        license =>
          license.seatsPurchased === license.seatsTaken && license.serviceCode !== initialValues['license-serviceCode']
      )
      .map(license => license.serviceName) || [];

  const onChange = (event: ChangeEvent, newValue?: any) => {
    changeFieldValue(formName, 'createRole', 'norole');
    changeFieldValue(formName, 'has2d', false);
    changeFieldValue(formName, 'has3d', false);

    if (newValue === LICENSE_FREE || newValue === LICENSE_PRO_FREE) {
      changeFieldValue(formName, 'executeRole', PROFILE_NETWORK_FREE);
    } else {
      changeFieldValue(formName, 'executeRole', 'norole');
    }
  };

  if (!projectSelected) {
    return <LicenseFieldNoProjectSelected />;
  }

  return (
    <div className={inputStyles.licenseBlock}>
      <div className={classNames(styles.fieldWrapper, inputStyles.fieldWrapper)}>
        <Field
          id="license-serviceCode"
          name="license-serviceCode"
          component={Radio}
          mainLabel={t('directory.userSheet.licenseTab.license.label')}
          labelClass={styles.fieldLabel}
          infoClass={inputStyles.options}
          optionClass={inputStyles.option}
          errorClass={inputStyles.errorMessage}
          options={licenseProvisioningData?.map(license => {
            const seatsRemaining = license.seatsPurchased - license.seatsTaken;
            const style = seatsRemaining === 0 ? styles.unavailableSeats : styles.availableSeats;
            return {
              label: license.serviceName,
              value: license.serviceCode,
              disabledOption: seatsRemaining === 0 && initialValues?.['license-serviceCode'] !== license.serviceCode,
              moreInfo: (
                <Trans t={t} components={[<span className={style} />]}>
                  {t('directory.userSheet.licenseTab.license.seatsInfo', {
                    seatsRemaining,
                    seatsPurchased: license.seatsPurchased,
                  })}
                </Trans>
              ),
            };
          })}
          onChange={onChange}
        />
      </div>
      <UnavailableLicenses licensesList={unavailableLicenses} />
    </div>
  );
}
