import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import Link from 'app/common/components/link';
import { HeadText5, LegendText2, DISPLAY_COLOR_PALETTE, ALT_COLOR_PALETTE } from 'app/common/typography';
import { COLOR_SECONDARY } from 'app/common/style/colors';
import { PATH_CATALOG_BROWSE, PATH_GUIDELINE_BROWSE } from 'config/routePaths';
import IconDisplay from 'assets/images/display.svg';
import IconNetwork from 'assets/images/network.svg';
import ClickBoundary from 'app/common/components/clickBoundary';
import useOffCanvasMenu from './index.hook';
import styles from './index.css';

const OffCanvasMenu = () => {
  const { isVisible, displayUrl, networkUrl, closeNavigation, activeRoute } = useOffCanvasMenu();

  const offCanvasClass = classNames(styles.offCanvasMenu, { [styles.isVisible]: isVisible });

  return (
    <ClickBoundary
      className={offCanvasClass}
      isActive={isVisible}
      onClick={closeNavigation}
      onClickOutside={clickEvent => {
        clickEvent.stopPropagation();
        clickEvent.preventDefault();
      }}
    >
      <div className={classNames(styles.menu, styles.isActive)}>
        <Link to={displayUrl} className={styles.headerLink} onClick={closeNavigation}>
          <IconDisplay className={styles.iconDisplay} />
          <LegendText2 className={styles.subtitle} palette={ALT_COLOR_PALETTE}>
            {i18next.t('display.subtitle')}
          </LegendText2>
        </Link>
        <Link
          to={PATH_CATALOG_BROWSE}
          className={classNames(styles.menuLink, { [styles.activeMenuLink]: activeRoute === PATH_CATALOG_BROWSE })}
          onClick={closeNavigation}
        >
          <HeadText5 className={styles.onPrimary} palette={DISPLAY_COLOR_PALETTE}>
            {i18next.t('display.submenu.catalog')}
          </HeadText5>
          <HeadText5 className={styles.onSecondary} palette={DISPLAY_COLOR_PALETTE} on={COLOR_SECONDARY} interactive>
            {i18next.t('display.submenu.catalog')}
          </HeadText5>
        </Link>
        <Link
          to={PATH_GUIDELINE_BROWSE}
          className={classNames(styles.menuLink, { [styles.activeMenuLink]: activeRoute === PATH_GUIDELINE_BROWSE })}
          onClick={closeNavigation}
        >
          <HeadText5 className={styles.onPrimary} palette={DISPLAY_COLOR_PALETTE}>
            {i18next.t('display.submenu.guidelines')}
          </HeadText5>
          <HeadText5 className={styles.onSecondary} palette={DISPLAY_COLOR_PALETTE} on={COLOR_SECONDARY} interactive>
            {i18next.t('display.submenu.guidelines')}
          </HeadText5>
        </Link>
      </div>
      <div className={styles.menu}>
        <Link href={networkUrl} className={styles.headerLink}>
          <IconNetwork className={styles.iconNetwork} />
          <LegendText2 className={styles.subtitle} palette={ALT_COLOR_PALETTE}>
            {i18next.t('network.subtitle')}
          </LegendText2>
        </Link>
      </div>
    </ClickBoundary>
  );
};

export default OffCanvasMenu;
