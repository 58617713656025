import { UserLicenseData } from 'domain/user/licenseData';

export default function formatRoleField(fieldValue: string, licenseData: UserLicenseData): string {
  const roles = [];

  if (licenseData.has2d) {
    roles.push('2D');
  }

  if (licenseData.has3d) {
    roles.push('3D');
  }

  return roles.length > 0 ? `${fieldValue} (${roles.join('/')})` : fieldValue;
}
