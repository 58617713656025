import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Modal from 'app/display/common/components/modals';

import Title from 'app/display/common/components/modals/confirmModal/title';
import Buttons from 'app/display/common/components/modals/confirmModal/buttons';
import styles from 'app/display/common/components/modals/confirmModal/index.css';

const ConfirmModal = ({ onClose, onConfirm, confirmEnabled, confirmLabel, title, children, className }) => {
  return (
    <Modal
      onClose={onClose}
      headerLeft={<Title title={title} />}
      headerRight={
        <Buttons onClose={onClose} onConfirm={onConfirm} confirmEnabled={confirmEnabled} confirmLabel={confirmLabel} />
      }
    >
      <div className={classNames(styles.label, className)}>{children}</div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  confirmEnabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  className: PropTypes.string,
};

ConfirmModal.defaultProps = {
  confirmEnabled: true,
  confirmLabel: undefined,
  className: undefined,
};

export default ConfirmModal;
