import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';

import {
  FETCH_GUIDELINES_GLOBAL_FILTERS_START,
  FETCH_GUIDELINES_GLOBAL_FILTERS_SUCCESS,
  FETCH_GUIDELINES_GLOBAL_FILTERS_ERROR,
} from 'app/display/guideline/list/common/actions';

export const initialState = {
  status: STATUS_LOADING,
  globalFilters: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GUIDELINES_GLOBAL_FILTERS_START:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case FETCH_GUIDELINES_GLOBAL_FILTERS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        globalFilters: action.filters,
      };
    case FETCH_GUIDELINES_GLOBAL_FILTERS_ERROR:
      return {
        ...state,
        status: STATUS_ERROR,
      };
    default:
      return state;
  }
}
