export const FETCH_INVENTORY_ITEM_START = 'fetch.inventory.item.start';
export const FETCH_INVENTORY_ITEM_ERROR = 'fetch.inventory.item.error';
export const FETCH_INVENTORY_ITEM_SUCCESS = 'fetch.inventory.item.success';

export const FETCH_INVENTORY_ITEMS_START = 'fetch.inventory.items.start';
export const FETCH_INVENTORY_ITEMS_ERROR = 'fetch.inventory.items.error';
export const FETCH_INVENTORY_ITEMS_SUCCESS = 'fetch.inventory.items.success';

export const ADD_INVENTORY_ITEMS = 'inventory.items.add';
export const UPDATE_INVENTORY_ITEMS = 'inventory.items.update';
export const REMOVE_INVENTORY_ITEMS = 'inventory.items.remove';
export const CLEAR_INVENTORY_ITEMS = 'inventory.items.clear';
