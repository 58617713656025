import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import getCurrentCampaignSelectionCount from 'app/network/store/sheet/tabs/guidelines/selectors/getCurrentCampaignSelectionCount';
import {
  deselectAllGuidelinesInCampaign,
  selectAllGuidelinesInCampaign,
} from 'app/network/store/sheet/tabs/guidelines/actions';
import Selection from 'app/display/common/components/list/selection';
import { HeadText5, LINK_COLOR_PALETTE } from 'app/common/typography';
import { COLOR_SECONDARY } from 'app/common/style/colors';
import classNames from 'classnames';
import { AppState } from 'lib/providers/store';
import {
  CampaignNames,
  GuidelinesWithNoCampaign,
} from 'app/network/store/sheet/tabs/guidelines/helpers/sortGuidelinesByCampaignName';
import styles from './titlePanelWithSelectAll.css';

type TitlePanelWithSelectAllProps = {
  campaignName: CampaignNames;
  campaignId: string | null;
  guidelineIds: string[];
  children?: React.ReactNode;
  dataTestId?: string;
};

const TitlePanelWithSelectAll = ({
  campaignName,
  guidelineIds,
  children,
  dataTestId,
  campaignId,
}: TitlePanelWithSelectAllProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const count = useSelector((state: AppState) => getCurrentCampaignSelectionCount(state, campaignId));
  const onDeselectAll = () => dispatch(deselectAllGuidelinesInCampaign(campaignId));
  const onSelectAll = () => dispatch(selectAllGuidelinesInCampaign(campaignId, guidelineIds));
  const totalCount = guidelineIds.length;
  const isSelectDisabled = totalCount === count;
  const title =
    campaignName === GuidelinesWithNoCampaign ? t('network.store.sheet.tabs.guidelines.noCampaign') : campaignName;
  const titleComposed = `${title} (${totalCount})`;
  const selectionCountLabel = t('display.guideline.list.selectionCount', { count });

  return (
    <div className={styles.wrapper} data-testid={dataTestId}>
      <div className={styles.headerWrapper}>
        <div className={styles.name}>
          <HeadText5
            className={classNames(styles.title, styles.uppercase)}
            palette={LINK_COLOR_PALETTE}
            on={COLOR_SECONDARY}
          >
            {titleComposed}
          </HeadText5>
        </div>
        <div className={styles.selection}>
          <Selection
            count={count}
            selectionCountLabel={selectionCountLabel}
            isSelectDisabled={isSelectDisabled}
            onSelectAll={onSelectAll}
            onDeselectAll={onDeselectAll}
            palette={LINK_COLOR_PALETTE}
            on={COLOR_SECONDARY}
          />
        </div>
      </div>
      <div className={styles.bodyWrapper}>{children}</div>
    </div>
  );
};

export default TitlePanelWithSelectAll;
