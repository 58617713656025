import { createReducer } from '@reduxjs/toolkit';
import { enableUserSheetSaveButton, disableUserSheetSaveButton } from 'app/directory/user/sheet/common/actions';

export const initialState = {
  enabled: true,
};

const directoryUserSheetSaveButtonReducer = createReducer(initialState, builder => {
  builder
    .addCase(enableUserSheetSaveButton, state => {
      state.enabled = true;
    })
    .addCase(disableUserSheetSaveButton, state => {
      state.enabled = false;
    });
});

export default directoryUserSheetSaveButtonReducer;
