import { combineReducers } from 'redux';
import directoryUserSheetActionsPopupReducer from 'app/directory/user/sheet/common/reducers/actionsPopup';
import directoryUserSheetSaveButtonReducer from 'app/directory/user/sheet/common/reducers/saveButton';

export default combineReducers({
  user: combineReducers({
    actionsPopup: directoryUserSheetActionsPopupReducer,
    saveButton: directoryUserSheetSaveButtonReducer,
  }),
});
