import queryString from 'query-string';
import moment from 'moment';
import { SCALE_MONTHLY, SCALE_ALL } from 'app/network/report/visits/scales';
import { query } from 'app/common/actions/api';
import { getCurrentSearch } from 'app/common/selectors/url';

export const FETCH = 'VISITS_DASHBOARD_DATA_FETCH';
export const FETCH_SUCCESS = 'VISITS_DASHBOARD_DATA_FETCH_SUCCESS';
export const FETCH_ERROR = 'VISITS_DASHBOARD_DATA_FETCH_ERROR';
export const DATE_INTERVAL_SUCCESS = 'VISITS_DASHBOARD_DATA_DATE_INTERVAL_SUCCESS';

export function fetch(dateFilter) {
  return {
    type: FETCH,
    dateFilter,
  };
}

function fetchSuccess(dashboardData) {
  return {
    type: FETCH_SUCCESS,
    dashboardData,
  };
}

function fetchError() {
  return {
    type: FETCH_ERROR,
  };
}

function dateIntervalSuccess(dateInterval) {
  return {
    type: DATE_INTERVAL_SUCCESS,
    dateInterval,
  };
}

function doFetchVisitsDashboardData(dateFilter) {
  return dispatch => {
    dispatch(fetch(dateFilter));

    const params = {};
    if (dateFilter.from !== undefined) {
      params.from = dateFilter.from;
    }
    if (dateFilter.to !== undefined) {
      params.to = dateFilter.to;
    }

    const qs = queryString.stringify(params);

    return dispatch(query(`visits?${qs}`))
      .then(visits => {
        // Build dashboard data
        const dashboardData = {
          dataByUser: {},
          visitsByDate: {},
          salesManagersCount: 0,
          visitsDoneCount: 0,
          visitsPlannedCount: 0,
          averageVisitsCount: 0,
          oldestVisit: undefined,
          latestVisit: undefined,
        };
        const now = new Date();
        let oldest = null;
        let latest = null;

        let totalVisitCount = 0;

        visits.map(visitItem => {
          const visit = visitItem;
          totalVisitCount += 1;

          // Compute visit date
          let dateStr = `${visit.date}`;
          if (visit.time) {
            // Note the T : important for IE10
            // Or else it will parse as "invalid date"
            dateStr += `T${visit.time}+00:00`;
          }
          const visitDate = new Date(dateStr);
          const visitDateArray = [
            visitDate.getFullYear(),
            `0${visitDate.getMonth() + 1}`.slice(-2),
            `0${visitDate.getDate()}`.slice(-2),
          ];
          const visitTimeArray = [`0${visitDate.getHours()}`.slice(-2), `0${visitDate.getMinutes()}`.slice(-2)];
          if (visit.time) {
            visit.date = visitDateArray.join('-');
            visit.time = visitTimeArray.join(':');
          }

          if (!(visit.user.id in dashboardData.dataByUser)) {
            dashboardData.salesManagersCount += 1;
            dashboardData.dataByUser[visit.user.id] = {
              uuid: visit.user.id,
              name: `${visit.user.firstName} ${visit.user.lastName}`,
              visitsDoneCount: 0,
              visitsPlannedCount: 0,
              averageVisitsCount: 0,
              visitsByDate: {},
              oldestVisit: undefined,
              latestVisit: undefined,
            };
          }

          // If visit date in the future => planned
          // else already done
          if (visitDate > now) {
            dashboardData.dataByUser[visit.user.id].visitsPlannedCount += 1;
            dashboardData.visitsPlannedCount += 1;
          } else {
            dashboardData.dataByUser[visit.user.id].visitsDoneCount += 1;
            dashboardData.visitsDoneCount += 1;
            // TODO : remove me, useful for tests
            // dashboardData.dataByUser[visit.user.id].visitsDoneCount
            // +=Math.floor(Math.random()*4)+1;
            // dashboardData.visitsDoneCount+=Math.floor(Math.random()*4)+1;
          }

          // We want to group visits by date
          if (!(visit.date in dashboardData.visitsByDate)) {
            dashboardData.visitsByDate[visit.date] = [];
          }
          dashboardData.visitsByDate[visit.date].push(visit);
          if (!(visit.date in dashboardData.dataByUser[visit.user.id].visitsByDate)) {
            dashboardData.dataByUser[visit.user.id].visitsByDate[visit.date] = [];
          }
          dashboardData.dataByUser[visit.user.id].visitsByDate[visit.date].push(visit);

          // Update the oldest / latest visit date for the user if needed
          if (
            dashboardData.dataByUser[visit.user.id].oldestVisit == null ||
            visitDate < dashboardData.dataByUser[visit.user.id].oldestVisit
          ) {
            dashboardData.dataByUser[visit.user.id].oldestVisit = visitDate;
          }

          if (
            dashboardData.dataByUser[visit.user.id].latestVisit == null ||
            visitDate > dashboardData.dataByUser[visit.user.id].latestVisit
          ) {
            dashboardData.dataByUser[visit.user.id].latestVisit = visitDate;
          }

          if (latest == null || visitDate > latest) {
            latest = visitDate;
          }
          if (oldest == null || visitDate < oldest) {
            oldest = visitDate;
          }

          return true;
        });

        // Compute total average and per user average
        const visitsCount = Object.keys(dashboardData.visitsByDate).length;
        dashboardData.averageVisitsCount = 0;
        if (visitsCount > 0) {
          dashboardData.averageVisitsCount = Math.round(totalVisitCount / (visitsCount * 100)) / 100;
        }

        Object.keys(dashboardData.dataByUser).map(key => {
          const userData = dashboardData.dataByUser[key];
          const dateKeys = Object.keys(userData.visitsByDate);
          const userVisitsCount = dateKeys.length;

          userData.averageVisitsCount = 0;
          if (userVisitsCount > 0) {
            userData.averageVisitsCount = Math.round(userData.visitsDoneCount / (userVisitsCount * 100)) / 100;
          }

          return true;
        });

        dashboardData.oldestVisit = oldest;
        dashboardData.latestVisit = latest;

        dispatch(fetchSuccess(dashboardData));
      })
      .catch(error => {
        dispatch(fetchError(error));
      });
  };
}

export function needToFetch(state, dateFilter) {
  return state.ready === false || dateFilter.from !== state.dateFilter.from || dateFilter.to !== state.dateFilter.to;
}

export function needToFetchDateInterval(state) {
  console.log('needToFetchDateInterval', state);
  return !state.dateInterval || state.dateInterval.oldest === undefined || state.dateInterval.latest === undefined;
}

/**
 * fetch the oldest and latest dates having a visit planned
 * returns a resolved promise with the bounds in parameters
 */
export function fetchDateInterval() {
  return (dispatch, getState) => {
    if (needToFetchDateInterval(getState().visitsDashboardData)) {
      return dispatch(query('privates/visits/datesbounds')).then(bounds => {
        const datesBounds = {
          oldest: bounds.firstBound,
          latest: bounds.lastBound,
        };
        dispatch(dateIntervalSuccess(datesBounds));
        return datesBounds;
      });
    }

    const bounds = getState().visitsDashboardData.dateInterval;
    return Promise.resolve(bounds);
  };
}

export function fetchVisitsDashboardData(dateFilter) {
  // eslint-disable-next-line
  return (dispatch, getState) => {
    const state = getState();
    if (needToFetch(state.visitsDashboardData, dateFilter)) {
      return dispatch(doFetchVisitsDashboardData(dateFilter));
    }
  };
}

export function initDashboard() {
  return (dispatch, getState) => {
    dispatch(fetchDateInterval()).then(datesBounds => {
      const state = getState();
      let filter = state.visitsDashboardData.dateFilter;

      // Compute the default dateFilter only if needed
      if (filter === undefined) {
        const search = getCurrentSearch(state);
        const queryParams = queryString.parse(search);

        const scaleParam = queryParams.scale;
        const fromParam = queryParams.from;
        const toParam = queryParams.to;

        // TODO why?
        // fromParam = fromParam ? fromParam : undefined;
        // toParam = toParam ? toParam : undefined;

        // Check if there is a dateFilter in queryString
        if (scaleParam && (scaleParam === SCALE_ALL || (fromParam && toParam))) {
          filter = {
            scale: scaleParam,
            from: fromParam,
            to: toParam,
          };
        }

        // If no dateFilter in queryString, build the default
        // one (current month if in bounds)
        if (!filter) {
          const now = moment();
          const latest = moment(datesBounds.latest);
          let refMoment = now;
          if (now > latest) {
            refMoment = latest;
          }
          const fromMoment = moment({
            year: refMoment.year(),
            month: refMoment.month(),
            day: 1,
          });
          const from = fromMoment.format('YYYY-MM-DD');
          const to = fromMoment.add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');
          filter = {
            scale: SCALE_MONTHLY,
            from,
            to,
          };
        }
      }

      dispatch(fetchVisitsDashboardData(filter));
    });
  };
}
