import {
  CURRENCY_CAD,
  CURRENCY_EUR,
  CURRENCY_GBP,
  CURRENCY_JPY,
  CURRENCY_USD,
} from 'app/display/catalog/common/constants/currencies';
import { CapacityType } from 'domain/item';

// items forms names
export const CREATE_ITEM_FORM_NAME = 'createItemForm';
export const EDIT_ITEM_FORM_NAME = 'editItemForm';

export const AVAILABLE_UNITS = ['currency', 'capacity', 'length'];

// see CurrencyUnit.as for whole list
export const CURRENCIES = [CURRENCY_EUR, CURRENCY_USD, CURRENCY_GBP, CURRENCY_JPY, CURRENCY_CAD];

export const CAPACITY_DEFAULT_UNIT = CapacityType.ML;

export const CAPACITY = [
  CAPACITY_DEFAULT_UNIT,
  CapacityType.CL,
  CapacityType.L,
  CapacityType.LBS,
  CapacityType.PIECES,
  CapacityType.OZ,
  CapacityType.FLOZ,
  CapacityType.KG,
  CapacityType.G,
];

export const STOCK_TYPE_CALCULATED = 'calculated';
export const STOCK_TYPE_DEFINED = 'defined';
export const stockTypes = [STOCK_TYPE_CALCULATED, STOCK_TYPE_DEFINED];
export const SHELF_POSITIONS = ['productZone', 'testerZone', 'shelfEdgeZone'];
export const BEHAVIOR_ITEM_TYPE = 'item';
export const BEHAVIOR_GLORIFIER_TYPE = 'glorifier';

export enum ScreenshotOrientationEnum {
  Top = 'top',
  Front = 'front',
  Perspective = 'perspective',
}
