import { useTranslation } from 'react-i18next';
import useUser from 'app/common/hooks/useUser';
import useAction from 'app/display/common/hooks/useAction';
import useToggleUserStatus from 'app/directory/user/common/hooks/useToggleUserStatus';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import { useCallback } from 'react';
import { hideModal } from 'app/common/actions/modals';
import HTTPError from 'lib/errors/http/error';
import getStatusErrorMessage from 'app/directory/user/common/helpers/getStatusErrorMessage';

export default function useToggleUserStatusModal(userId: string, successi18nKey: string) {
  const { t } = useTranslation();
  const { user, isFetching } = useUser(userId);
  const onClose = useAction(hideModal);
  const toggleUserStatus = useToggleUserStatus(userId);
  const closeModalWithSuccess = useAction(closeModalWithSuccessNotificationMessage);
  const closeModalWithError = useAction(closeModalWithErrorNotificationMessage);

  const onConfirm = useCallback(() => {
    if (user) {
      return toggleUserStatus(user);
    }

    return false;
  }, [user, toggleUserStatus]);

  const onConfirmSuccess = () => closeModalWithSuccess(t(successi18nKey));
  const onConfirmError = (exception: HTTPError) => {
    return closeModalWithError(getStatusErrorMessage(exception, userId));
  };

  return { onClose, onConfirm, onConfirmSuccess, onConfirmError, t, isFetching };
}
