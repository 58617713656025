import React from 'react';
import { useSelector } from 'react-redux';

import SettingsLink from 'app/common/components/legacyNavMenu/menu/settings/links/settings';
import hasCurrentUserDisplayAccess from 'app/common/user/selectors/hasCurrentUserDisplayAccess';
import NavLinks from 'app/common/components/legacyNavMenu/navLinks';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import { PATH_SETTINGS } from 'config/routePaths';
import useSmallScreen from 'app/common/hooks/useSmallScreen';

export default function NavSettings() {
  const isSmallScreen = useSmallScreen();
  const hasDisplayAccess = useSelector(hasCurrentUserDisplayAccess);
  const matchSettingsRoute = useLocationMatch(PATH_SETTINGS, {
    exact: false,
  });

  if (!hasDisplayAccess || !matchSettingsRoute || isSmallScreen) {
    return null;
  }

  return (
    <NavLinks>
      <SettingsLink />
    </NavLinks>
  );
}
