import React from 'react';
import Link from 'app/common/components/offCanvasPlatformMenu/links/link';
import SurveyIcon from 'assets/icons/survey-grid.svg';
import { PATH_SURVEY_PUBLISHED, PATH_SURVEY } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import i18next from 'i18next';
import useCurrentUserSurveyPermissions from 'app/common/hooks/useCurrentUserSurveyPermissions';

const useSurveysLink = () => {
  const isActive = useLocationMatch(PATH_SURVEY, { exact: false });
  const href = useParamSelector(getAbsoluteUrl, PATH_SURVEY_PUBLISHED);
  const { canAccess, discoveryPagesEnabled } = useCurrentUserSurveyPermissions();

  return {
    href,
    isActive,
    canAccess,
    discoveryPagesEnabled,
  };
};

const SurveysLink = () => {
  const { href, isActive, canAccess, discoveryPagesEnabled } = useSurveysLink();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <Link href={href} Icon={SurveyIcon} isActive={isActive} isDisabled={!canAccess}>
      {i18next.t('surveys')}
    </Link>
  );
};

export default SurveysLink;
