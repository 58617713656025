import Brand from 'domain/brand';
import compareStringUsingNaturalOrder from 'lib/helpers/sort';

function compareBrandsName(firstBrand: Brand, secondBrand: Brand): number {
  return compareStringUsingNaturalOrder(firstBrand.name, secondBrand.name);
}

function compareBrandsType(firstBrand: Brand, secondBrand: Brand): number {
  if (firstBrand.isGeneric() && !secondBrand.isGeneric()) {
    return -1;
  }
  if (!firstBrand.isGeneric() && secondBrand.isGeneric()) {
    return 1;
  }
  return 0;
}

export default function sortBrandsByName(brands: Brand[]): Brand[] {
  return [...brands].sort(compareBrandsName).sort(compareBrandsType);
}
