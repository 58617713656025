import PropTypes from 'prop-types';
import React from 'react';

const DefaultErrorBoundary = ({ DefaultErrorBoundaryComponent, FallbackComponent, children }) => (
  <DefaultErrorBoundaryComponent FallbackComponent={FallbackComponent}>{children}</DefaultErrorBoundaryComponent>
);

DefaultErrorBoundary.propTypes = {
  DefaultErrorBoundaryComponent: PropTypes.elementType.isRequired,
  FallbackComponent: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
};

export default DefaultErrorBoundary;
