import useDrawings from 'app/network/common/hooks/useDrawings';
import usePhotos from 'app/network/common/hooks/usePhotos';
import { Photo } from 'domain/photo';
import { DrawingUrlWithPhotoId } from 'app/network/common/hooks/useDrawingRequest';

function processPhotosAndDrawings(photos: Photo[], drawings: DrawingUrlWithPhotoId[]): Photo[] {
  return photos.map(photo => {
    const drawing = drawings.find(draw => draw.photoId === photo.id);
    return {
      ...photo,
      drawingUrl: drawing?.url ?? null,
    };
  });
}

export default function usePhotosAndDrawings(photoIds: string[]) {
  const {
    drawings,
    isLoading: areDrawingsLoading,
    isError: isDrawingsError,
    error: errorDrawings,
  } = useDrawings(photoIds);
  const { photos, isLoading: arePhotosLoading, isError: isPhotosError, error: errorPhotos } = usePhotos(photoIds);
  const isLoading = arePhotosLoading || areDrawingsLoading;
  const isError = isPhotosError || isDrawingsError;
  const error = errorPhotos || errorDrawings;
  const photosAndDrawings = processPhotosAndDrawings(photos, drawings);

  return {
    photosAndDrawings,
    isLoading,
    isError,
    error,
  };
}
