import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

const ClickBoundary = ({ className, children, onClickOutside, onClickInside, onClick, isActive }) => {
  const node = useRef(null);

  useEffect(() => {
    const onBodyClick = clickEvent => {
      if (node.current && isActive) {
        if (node.current.contains(clickEvent.target)) {
          onClickInside(clickEvent);
        } else {
          onClickOutside(clickEvent);
        }
        onClick(clickEvent);
      }
    };

    if (isActive) {
      document.body.addEventListener('click', onBodyClick, true);
    } else {
      document.body.removeEventListener('click', onBodyClick, true);
    }

    return () => {
      document.body.removeEventListener('click', onBodyClick, true);
    };
  }, [isActive, onClickOutside, onClickInside, onClick]);

  return (
    <div ref={node} className={className}>
      {children}
    </div>
  );
};

ClickBoundary.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onClickInside: PropTypes.func,
  onClickOutside: PropTypes.func,
};

ClickBoundary.defaultProps = {
  className: null,
  isActive: false,
  onClick: () => {},
  onClickInside: () => {},
  onClickOutside: () => {},
};

export default ClickBoundary;
