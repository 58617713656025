// import { query } from 'app/common/actions/api';

// mimic Monolog levels
const DEBUG = 100;
const INFO = 200;
const NOTICE = 250;
const WARNING = 300;
const ERROR = 400;
const CRITICAL = 500;
const ALERT = 550;
const EMERGENCY = 600;
// business
const BUSINESS = 700;
// mapping between logs level, console method's name and level name
const LEVELS = {
  [DEBUG]: {
    console: 'debug',
    name: 'debug',
  },
  [INFO]: {
    console: 'info',
    name: 'info',
  },
  [NOTICE]: {
    console: 'info',
    name: 'notice',
  },
  [WARNING]: {
    console: 'warn',
    name: 'warning',
  },
  [ERROR]: {
    console: 'error',
    name: 'error',
  },
  [CRITICAL]: {
    console: 'error',
    name: 'critical',
  },
  [ALERT]: {
    console: 'error',
    name: 'alert',
  },
  [EMERGENCY]: {
    console: 'error',
    name: 'emergency',
  },
  [BUSINESS]: {
    console: 'log',
    name: 'info',
  },
};

// logs channel (same as the backend)
const CHANNEL_DEV = 'dev';
const CHANNEL_EXCEPTION = 'exception';
const CHANNEL_BUSINESS = 'business';

const BUFFER_MAX_SIZE = 50;
// Minimal logs level send to backend
let postLevel = NOTICE;
// We need to access to the store to dispatch and get the current state
let store = null;
// interval between each post request (default: every minute)
const POST_INTERVAL = 60 * 1000;
let buffer = [];

/**
 * Change the minimal logs level send to backend
 * @param level
 */
function setPostLevel(level) {
  postLevel = level;
}

/**
 * Connect to the store
 * @param f
 */
function connectStore(s) {
  store = s;
}

/**
 * Send logs to backend
 * @return {Promise.<*>}
 */
async function post() {
  console.log('sending logs', buffer);
  if (store === null) {
    console.warn('logger not connected to the store, cannot send logs!');
    return;
  }

  try {
    // TODO backend endpoints and enable it
    // await store.dispatch(query('private/logs'));
  } catch (e) {
    console.error(e);
  } finally {
    buffer = [];
  }
}

async function log(...args) {
  if (args.length < 2) {
    console.warn('not enough args to log');
    return;
  }
  const [level] = args;
  let [, message] = args;
  let context = args.slice(2);
  let channel = args[0] === BUSINESS ? CHANNEL_BUSINESS : CHANNEL_DEV;

  console[LEVELS[level].console](__filename, ...[message].concat(context));

  // send instantly logs if it's an error
  let mustSend = buffer.length + 1 >= BUFFER_MAX_SIZE;

  if (level >= postLevel || level === BUSINESS) {
    context = context.map(m => {
      let msg = m;
      // we add the state only when it's an error
      if (m instanceof Error) {
        // spread operators doesn't work on error
        msg = {
          message: m.message,
          stack: m.stack,
          state: store.getState() || {},
        };
        mustSend = true;
        ({ message } = m.message);
        channel = CHANNEL_EXCEPTION;
      }
      return msg;
    });

    const msg = {
      level: LEVELS[level].name,
      channel,
      date: new Date(),
      message,
      version: process.env.APP_VERSION,
      application: 'webapp',
      context,
    };

    buffer.push(msg);

    if (mustSend) {
      await post();
    }
  }
}

setInterval(async () => {
  await post();
}, POST_INTERVAL);

export default {
  emergency: log.bind(null, EMERGENCY),
  alert: log.bind(null, ALERT),
  error: log.bind(null, ERROR),
  warn: log.bind(null, WARNING),
  notice: log.bind(null, NOTICE),
  info: log.bind(null, INFO),
  debug: log.bind(null, DEBUG),
  log: log.bind(null, NOTICE),
  business: log.bind(null, BUSINESS),
  setPostLevel,
  connectStore,
};
