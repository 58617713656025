import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';
import { HeadText1, HeadText4, ALT_COLOR_PALETTE } from 'app/common/typography';
import styles from './oops.css';

const OopsHeader = ({ title }) => {
  return (
    <div className={styles.oops}>
      <HeadText1 className={classNames(styles.uppercase, styles.title)} palette={ALT_COLOR_PALETTE}>
        {title}
      </HeadText1>
      <HeadText4 className={classNames(styles.uppercase, styles.message)}>{i18next.t('display.error.oops')}</HeadText4>
    </div>
  );
};

OopsHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default OopsHeader;
