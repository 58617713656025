import React from 'react';
import { BodyText1, BodyText2 } from 'app/common/typography';
import classNames from 'classnames';
import styles from './field.css';

type LicenseTabFieldProps = {
  label: string;
  value?: string;
  className?: string;
};

export default function LicenseTabField({ label, value, className }: LicenseTabFieldProps) {
  return (
    <div className={classNames(styles.infoRow, className)}>
      <div className={styles.label}>
        <BodyText2 className={styles.labelText}>{label}</BodyText2>
      </div>
      <BodyText1 className={styles.value}>{value || '-'}</BodyText1>
    </div>
  );
}
