import { useSelector } from 'react-redux';
import useLegacyAcl from 'app/common/hooks/useLegacyAcl';
import hasCurrentUserNetworkAccess from 'app/common/user/selectors/hasCurrentUserNetworkAccess';
import useInstanceSettingsDiscoveryPage from 'app/common/hooks/useInstanceSettingsDiscoveryPage';

type ReportsAccess = {
  canAccess: boolean;
  discoveryPagesEnabled?: boolean;
};

export default function useCurrentUserReportsAccess(): ReportsAccess {
  const canAccess = useLegacyAcl('report', 'manage');
  const hasNetworkAccess = useSelector(hasCurrentUserNetworkAccess);
  const { discoveryPagesEnabled } = useInstanceSettingsDiscoveryPage();
  return { canAccess: hasNetworkAccess && canAccess.isAllowed, discoveryPagesEnabled };
}
