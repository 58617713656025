import Guideline from 'domain/guideline';
import GuidelineCampaign from 'domain/guideline/campaign';

export const GuidelinesWithNoCampaign = 'guidelinesWithNoCampaign';
export type CampaignNames = string | typeof GuidelinesWithNoCampaign;

type GuidelinesByCampaignName = {
  [key in CampaignNames]: Guideline[];
};

export default function sortGuidelinesByCampaignName(
  guidelines: Guideline[],
  campaigns: GuidelineCampaign[]
): GuidelinesByCampaignName {
  const initialMap: GuidelinesByCampaignName = {};
  return guidelines.reduce((acc, guideline) => {
    if (guideline.campaignId) {
      const campaign = campaigns.find(guidelineCampaign => guidelineCampaign.id === guideline.campaignId);

      if (!campaign) {
        return acc;
      }

      if (!acc[campaign.name]) {
        acc[campaign.name] = [];
      }
      acc[campaign.name].push(guideline);
    } else {
      if (!acc[GuidelinesWithNoCampaign]) {
        acc[GuidelinesWithNoCampaign] = [];
      }
      acc[GuidelinesWithNoCampaign].push(guideline);
    }

    return acc;
  }, initialMap);
}
