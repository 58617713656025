import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';

export const InstanceAclPage = React.lazy(() => import('app/display/acl/instance/components/page'));

const InstanceAcl = () => (
  <Suspense fallback={<Spinner />}>
    <InstanceAclPage />
  </Suspense>
);

export default InstanceAcl;
