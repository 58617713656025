import WebsocketMessage from 'domain/webSockets/message';
import { QueryClient } from '@tanstack/react-query';
import WebSocketMessageTypes from 'domain/webSockets/messageTypes';

export default abstract class WebSocketMessageHandlerAbstract {
  protected queryClient: QueryClient;

  constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
  }

  abstract processMessage(message: WebsocketMessage): void;

  abstract supportedMessageTypes: Array<WebSocketMessageTypes>;
}
