import { useEffect, useState } from 'react';
import { DEFAULT_TIMEOUT_MILLIS } from 'app/display/common/constants/timer';

function useTimeout({ timeoutMillis = DEFAULT_TIMEOUT_MILLIS }: { timeoutMillis?: number } = {}) {
  const [callback, setCallback] = useState<() => void>();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (callback) {
      timeout = setTimeout(callback, timeoutMillis);
    }
    return () => clearTimeout(timeout);
  }, [callback, timeoutMillis]);

  return { callback, setCallback };
}

export default useTimeout;
