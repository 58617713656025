import getUsers from 'app/common/user/selectors/getUsers';
import BasicUserFactory from 'domain/user/factory';

const defaultBasicUser = BasicUserFactory.createDefaultBasicUser();

function getUser(state, id) {
  return getUsers(state)?.[id] ?? defaultBasicUser;
}

export default getUser;
