import useReduxFormValue from 'app/display/common/hooks/useReduxFormValue';
import { change } from 'redux-form';
import {
  LICENSE_BASIC_HQ_FULL,
  LICENSE_PRO_HQ_FULL,
  DISPLAY_ROLES_BY_LICENSE,
  LICENSE_PRO_HQ_READONLY,
} from 'app/directory/user/sheet/common/constants/rolesByLicense';
import useReduxForm from 'app/display/common/hooks/useReduxForm';
import useAction from 'app/display/common/hooks/useAction';
import { ChangeEvent } from 'react';
import { PROFILE_DISPLAY_NOROLE } from 'app/common/user/helpers/profile/constants';

export default function useCreateField() {
  const { form } = useReduxForm();
  const changeFieldValue = useAction(change);
  const license = useReduxFormValue('license-serviceCode');
  const createRole = useReduxFormValue('createRole');
  const roles = DISPLAY_ROLES_BY_LICENSE[license];

  const isDisabled =
    license !== LICENSE_BASIC_HQ_FULL && license !== LICENSE_PRO_HQ_FULL && license !== LICENSE_PRO_HQ_READONLY;
  const has2dDisabled = isDisabled || !createRole || createRole === PROFILE_DISPLAY_NOROLE;
  const has3dDisabled =
    isDisabled || !createRole || license === LICENSE_BASIC_HQ_FULL || createRole === PROFILE_DISPLAY_NOROLE;

  const onChangeRole = (_event: ChangeEvent, newValue?: unknown) => {
    if (newValue) {
      if (newValue === PROFILE_DISPLAY_NOROLE) {
        changeFieldValue(form, 'has2d', false);
        changeFieldValue(form, 'has3d', false);
      } else {
        changeFieldValue(form, 'has2d', true);

        if (license !== LICENSE_BASIC_HQ_FULL) {
          changeFieldValue(form, 'has3d', true);
        }
      }
    }
  };

  return { roles, isDisabled, has2dDisabled, has3dDisabled, onChangeRole };
}
