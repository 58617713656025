import React from 'react';

import UserButton from 'app/common/components/appShell/containers/header/userButton';
import UserNotificationCenter from 'app/common/components/appShell/userNotificationCenter';
import useFeatureFlag from 'app/common/hooks/useFeatureFlag';
import styles from './index.css';

const NavActions = () => {
  const { isEnabled } = useFeatureFlag('thread');
  return (
    <div className={styles.actions}>
      {isEnabled && <UserNotificationCenter />}
      <div className={styles.action}>
        <UserButton />
      </div>
    </div>
  );
};

export default NavActions;
