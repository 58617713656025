import { useSelector } from 'react-redux';
import hasCurrentUserNetworkAccess from 'app/common/user/selectors/hasCurrentUserNetworkAccess';
import useLegacyAcl from 'app/common/hooks/useLegacyAcl';
import generatePathname from 'lib/helpers/router/generatePathname';
import { PATH_DISCOVER } from 'config/routePaths';
import { DISCOVER_REPORTS, DISCOVER_SURVEYS } from 'app/discover/constants/moduleNames';

export default function useNetworkRoutes() {
  const hasNetworkAccess = useSelector(hasCurrentUserNetworkAccess);
  const { isAllowed: canManageReport } = useLegacyAcl('report', 'manage');
  const { isAllowed: canPublishSurvey } = useLegacyAcl('survey', 'publish');
  const { isAllowed: canManageSurvey } = useLegacyAcl('survey', 'manage');
  const { isAllowed: canFollowSurvey } = useLegacyAcl('survey', 'follow');
  const surveysDiscoveryPagePath = generatePathname(PATH_DISCOVER, { moduleName: DISCOVER_SURVEYS });
  const reportsDiscoveryPagePath = generatePathname(PATH_DISCOVER, { moduleName: DISCOVER_REPORTS });

  return {
    canAccessSurveys: hasNetworkAccess && (canManageSurvey || canFollowSurvey || canPublishSurvey),
    canAccessReport: hasNetworkAccess && canManageReport,
    surveysDiscoveryPagePath,
    reportsDiscoveryPagePath,
  };
}
