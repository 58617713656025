import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import { LicenseProvisioningData } from 'domain/license/provisioning';
import { LICENSE_PROVISIONING_DATA_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import { useQuery } from '@tanstack/react-query';

export default function useLicenseProvisioningData() {
  const getProvisioningData = useHttpGetRequest<LicenseProvisioningData[]>('privates/licensing/provisioning');

  const {
    data: licenseProvisioningData,
    isLoading,
    isError,
    error,
  } = useQuery([LICENSE_PROVISIONING_DATA_CACHE_KEY], getProvisioningData);

  return {
    licenseProvisioningData,
    isLoading,
    isError,
    error,
  };
}
