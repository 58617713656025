import HTTPUnauthorizedError from 'lib/errors/http/unauthorizedError';
import HTTPServiceUnavailableError from 'lib/errors/http/serviceUnavailableError';
import UnableToFetchUserException from 'lib/errors/UnableToFetchUserException';

export default function assertIsNotCriticalHttpException(exception) {
  if (
    exception instanceof HTTPUnauthorizedError ||
    exception instanceof HTTPServiceUnavailableError ||
    exception instanceof UnableToFetchUserException
  ) {
    throw exception;
  }
}
