import React, { useEffect } from 'react';
import Notifications from 'app/display/common/components/notifications';
import { NETWORK_NOTIFICATION } from 'app/network/common/constants/legacyEvents';
import useHandleNotification from 'app/network/hooks/useHandleNotifications';

export default function NetworkNotifications({ className = null }: { className?: string | null }) {
  const handleNotification = useHandleNotification();

  useEffect(() => {
    document.addEventListener(NETWORK_NOTIFICATION, handleNotification);

    return () => {
      document.removeEventListener(NETWORK_NOTIFICATION, handleNotification);
    };
  }, [handleNotification]);

  return <Notifications className={className} />;
}
