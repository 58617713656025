import { createReducer } from '@reduxjs/toolkit';
import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';
import {
  FETCH_CAMPAIGN_START,
  FETCH_CAMPAIGN_SUCCESS,
  FETCH_CAMPAIGN_ERROR,
} from 'app/display/guideline/common/actions';

export const initialState = {
  status: STATUS_LOADING,
  error: undefined,
};

const guidelineCampaignViewReducer = createReducer(initialState, builder => {
  builder
    .addCase(FETCH_CAMPAIGN_START, draftState => {
      draftState.status = STATUS_LOADING;
    })
    .addCase(FETCH_CAMPAIGN_SUCCESS, draftState => {
      draftState.status = STATUS_SUCCESS;
    })
    .addCase(FETCH_CAMPAIGN_ERROR, (draftState, action) => {
      draftState.status = STATUS_ERROR;
      draftState.error = action.error;
    });
});

export default guidelineCampaignViewReducer;
