import FeatureFlags from 'lib/featureFlags';

type FeatureFlagEnabled = {
  isEnabled: boolean;
};

export default function useFeatureFlag(flagName: string): FeatureFlagEnabled {
  const features = FeatureFlags.getInstance().featuresEnabled;
  return { isEnabled: features[flagName] === true };
}
