import { useSelector } from 'react-redux';

import isAppshellContentFullscreen from 'app/common/selectors/appShell/isAppshellContentFullscreen';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import usePlatform from 'app/common/hooks/usePlatform';

export default function useNavMenu() {
  const isSmallScreen = useSmallScreen();
  const isFullScreen = useSelector(isAppshellContentFullscreen);
  const { isEnabled: isPlatformMenuEnabled } = usePlatform();

  return {
    isFullScreen,
    isSmallScreen,
    isPlatformMenuEnabled,
  };
}
