import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';

export const SettingsPage = React.lazy(() =>
  import(/* webpackChunkName: "display-settings" */ 'app/display/settings/mainPage/page')
);

const Settings = () => (
  <Suspense fallback={<Spinner />}>
    <SettingsPage />
  </Suspense>
);

export default Settings;
