import React from 'react';
import useReduxFormComponent from 'app/display/common/hooks/useReduxFormComponent';
import NotificationsTabForm from 'app/directory/user/sheet/common/components/form/notifications';
import { USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM } from 'app/directory/user/sheet/common/constants';
import useEditUserNotificationsSettingsInitialValues from 'app/directory/user/sheet/edit/hooks/useEditUserNotificationsSettingsInitialValues';

type EditUserNotificationsTabProps = {
  initialValues: Record<string, boolean>;
  testPhotoReportHref: string;
  testSurveyReportHref: string;
  userIdV4: string;
  userId: string;
};

export default function EditUserNotificationsTab({
  initialValues,
  testPhotoReportHref,
  testSurveyReportHref,
  userIdV4,
  userId,
}: EditUserNotificationsTabProps) {
  const FormComponent = useReduxFormComponent(NotificationsTabForm, USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM, {
    testPhotoReportHref,
    testSurveyReportHref,
    userIdV4,
    destroyOnUnmount: false,
  });
  const { initialValues: formInitialValues, isLoading } = useEditUserNotificationsSettingsInitialValues(
    userId,
    initialValues
  );

  if (isLoading || !formInitialValues) {
    return null;
  }

  return <FormComponent initialValues={formInitialValues} />;
}
