import React from 'react';
import AccessGuidelineListButton from 'app/network/store/sheet/tabs/guidelines/components/guidelinesActions/accessGuidelineListButton';
import ExportGuidelinesCSV from 'app/network/store/sheet/tabs/guidelines/components/guidelinesActions/exportGuidelinesCSV';
import Tooltip from 'app/common/components/tooltip';
import { useTranslation } from 'react-i18next';
import styles from './index.css';

export default function GuidelinesActions({ storeId }: { storeId: string }) {
  const { t } = useTranslation();
  return (
    <div className={styles.guidelinesActions}>
      <Tooltip title={t('network.store.sheet.tabs.guidelines.accessGuidelineList')} arrow={true}>
        <AccessGuidelineListButton storeId={storeId} />
      </Tooltip>
      <Tooltip title={t('network.store.sheet.tabs.guidelines.downloadCsv')} arrow={true}>
        <ExportGuidelinesCSV storeId={storeId} />
      </Tooltip>
    </div>
  );
}
