import useCurrentGuideline from 'app/display/guideline/sheet/common/hooks/useCurrentGuideline';
import useIsCurrentUserAllowedToEditGuideline from 'app/display/guideline/common/hooks/useIsCurrentUserAllowedToEditGuideline';

export default function useIsGuidelineEditRouteAccessible() {
  const { guideline, isLoading } = useCurrentGuideline();
  const canEditGuideline = useIsCurrentUserAllowedToEditGuideline(guideline);

  if (isLoading) {
    return true;
  }

  if (!guideline) {
    return true;
  }

  return canEditGuideline;
}
