import { Base64 } from 'js-base64';
import axios, { AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import log from 'lib/helpers/logger';
import HTTPErrorFactory from 'lib/errors/http/factory';
import HTTPServerError from 'lib/errors/http/serverError';
import { AppState } from 'lib/providers/store';
import httpClient from 'app/common/helpers/api/httpClient';
import { showModal } from 'app/common/actions/modals';
import ModalError from 'app/common/containers/modals/errors';
import { USER_AUTH_ERROR, USER_AUTH_REQUESTED, USER_AUTH_SUCCESS } from 'app/common/reducers/auth';
import getInstanceName from 'app/common/selectors/iwdConfig/getInstanceName';
import getOrRefreshUserToken from 'app/auth/token/actions/refreshToken';
import getExpirationTimeFromToken from 'app/auth/token/helpers/getExpirationTimeFromToken';
import getDeviceId from 'app/common/selectors/getDeviceId';

interface ActionUserAuthSuccess extends Action {
  type: typeof USER_AUTH_SUCCESS;
  email: string;
  token: string;
  tokenExpirationTime: number | null;
}

function userAuthSuccess(email: string, token: string, tokenExpirationTime: number | null): ActionUserAuthSuccess {
  return {
    type: USER_AUTH_SUCCESS,
    email,
    token,
    tokenExpirationTime,
  };
}

interface ActionUserAuthError extends Action {
  type: typeof USER_AUTH_ERROR;
  email: string;
  error: string;
}

function userAuthError(email: string, error: string): ActionUserAuthError {
  return {
    type: USER_AUTH_ERROR,
    email,
    error,
  };
}

interface ActionUserAuthRequested extends Action {
  type: typeof USER_AUTH_REQUESTED;
  email: string;
}

function userAuthRequested(email: string): ActionUserAuthRequested {
  return {
    type: USER_AUTH_REQUESTED,
    email,
  };
}

export type PartialAxiosRequestConfig<RequestData> = Omit<AxiosRequestConfig<RequestData>, 'url'>;

type ResponseWithHeaders<ResponseData> = { data: ResponseData; headers: AxiosResponseHeaders };

function extractMessageFromUnknownError(error: unknown): string {
  let message = 'An error occurred.';
  if (axios.isAxiosError(error)) {
    if (error.response) {
      const errorContent = error.response.data;
      if (typeof errorContent === 'object' && errorContent !== null) {
        if ('message' in errorContent) {
          const errorWithMessage = errorContent as { message: unknown };
          const messageFromError = errorWithMessage.message;
          if (typeof messageFromError === 'string') {
            message = messageFromError;
          }
        }
      }
    }
  }

  return message;
}

async function executeQuery<ResponseData, RequestData>(
  servicePath: string,
  options: PartialAxiosRequestConfig<RequestData>
): Promise<ResponseWithHeaders<ResponseData>> {
  try {
    const response = await httpClient.request<ResponseData>({
      ...options,
      url: servicePath,
    });

    return { data: response.data, headers: response.headers };
  } catch (error) {
    log.error(error);

    if (axios.isAxiosError(error) && error.response) {
      throw HTTPErrorFactory(error.response);
    }

    throw error;
  }
}

async function executeQueryWithoutResponseHeaders<ResponseData, RequestData>(
  servicePath: string,
  options: PartialAxiosRequestConfig<RequestData>
): Promise<ResponseData> {
  const responseWithHeaders = await executeQuery<ResponseData, RequestData>(servicePath, options);
  return new Promise<ResponseData>(resolve => resolve(responseWithHeaders.data));
}

type AuthenticationApiResponse = { token: string };

export function authenticate(
  email: string,
  password: string
): ThunkAction<Promise<AuthenticationApiResponse>, AppState, any, AnyAction> {
  return async (dispatch, getState) => {
    try {
      const instance = getState().iwdConfig?.config?.instance.name;
      if (!instance) {
        throw new Error('Missing config for instance');
      }
      const credentials = Base64.encode(`${email}:${password}`);

      dispatch(userAuthRequested(email));

      const result = await executeQueryWithoutResponseHeaders<AuthenticationApiResponse, never>('auth/token', {
        method: 'post',
        headers: {
          Authorization: `Basic ${credentials}`,
          'X-IWD-INSTANCE': instance,
        },
        withCredentials: true,
      });

      dispatch(userAuthSuccess(email, result.token, getExpirationTimeFromToken(result.token)));

      return result;
    } catch (error) {
      log.error(error);

      const message = extractMessageFromUnknownError(error);
      dispatch(userAuthError(email, message));

      if (error instanceof HTTPServerError) {
        dispatch(showModal(ModalError, { error }));
      }

      throw error;
    }
  };
}

export function query<ResponseData, RequestData = null>(
  servicePath: string,
  options?: PartialAxiosRequestConfig<RequestData> & { getHeaders?: false },
  token?: string
): ThunkAction<Promise<ResponseData>, AppState, any, AnyAction>;

export function query<ResponseData, RequestData = null>(
  servicePath: string,
  options?: PartialAxiosRequestConfig<RequestData> & { getHeaders: true }
): ThunkAction<Promise<ResponseWithHeaders<ResponseData>>, AppState, any, AnyAction>;

export function query<ResponseData, RequestData = null>(
  servicePath: string,
  options: PartialAxiosRequestConfig<RequestData> & { getHeaders?: boolean } = {},
  token?: string
): ThunkAction<Promise<ResponseWithHeaders<ResponseData> | ResponseData>, AppState, any, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const userToken = token ?? (await dispatch(getOrRefreshUserToken()));
    const instanceName = getInstanceName(state);
    const appVersion = process.env.BUILD_HASH || '';
    const deviceId = getDeviceId();

    try {
      const { getHeaders, ...requestOptions } = options;
      const preparedOptions = {
        ...requestOptions,
        headers: {
          ...requestOptions.headers,
          Authorization: `Bearer ${userToken}`,
          // for now, we need this in every query
          // https://taiga.iwd.re/project/admin-friwd/task/2394
          'X-IWD-INSTANCE': instanceName,
          'X-IWD-APPLICATION-VERSION': appVersion,
          'X-IWD-RUPT-DEVICE-ID': deviceId,
        },
        withCredentials: true,
      };
      if (getHeaders === true) {
        return await executeQuery<ResponseData, RequestData>(servicePath, preparedOptions);
      }
      return await executeQueryWithoutResponseHeaders<ResponseData, RequestData>(servicePath, preparedOptions);
    } catch (error) {
      log.error(error);

      throw error;
    }
  };
}
