import i18next from 'i18next';
import HTTPNotFoundError from 'lib/errors/http/notFoundError';
import getMessageFromApiException from 'app/common/helpers/api/getMessageFromApiException';
import useValidateGuidelineData from 'app/common/hooks/useValidateGuidelineData';

export default function useAssertGuidelineHasDocument(guidelineId) {
  const { refetch } = useValidateGuidelineData(guidelineId, {
    enabled: false,
    retry: 0,
    throwOnUnhandledError: false,
  });

  return async function assertGuidelineHasDocument() {
    const { data, isSuccess, isError, error } = await refetch();

    if (isSuccess) {
      if (!(data?.children?.length > 0)) {
        // There is no guideline document (guideline data are empty)
        throw new Error(i18next.t('action.guideline.report.error'));
      }
    } else if (isError) {
      if (error instanceof TypeError) {
        // Opening new tab is block by the browser
        throw new Error(i18next.t('action.guideline.report.openError'));
      } else if (error instanceof HTTPNotFoundError) {
        // There is no guideline document (guideline data are missing)
        throw new Error(i18next.t('action.guideline.report.error'));
      } else {
        const { message } = getMessageFromApiException(error);
        throw new Error(message);
      }
    }
  };
}
