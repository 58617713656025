import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { BodyText1, ERROR_COLOR_PALETTE } from 'app/common/typography';

const ErrorMessage = ({ message, className }) => (
  <BodyText1 palette={ERROR_COLOR_PALETTE} className={className}>
    {i18next.t(message)}
  </BodyText1>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: null,
};

export default ErrorMessage;
