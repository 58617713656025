import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';

type WizardStepWrapperProps = {
  children: ReactNode;
  wizardKey: string;
};

const WizardStepWrapper = ({ children, wizardKey }: WizardStepWrapperProps) => (
  <motion.div
    key={wizardKey}
    animate={{ opacity: 1 }}
    initial={{ opacity: 0 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);

export default WizardStepWrapper;
