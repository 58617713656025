import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import useBidirectionalControlledInput from 'app/display/common/hooks/useBidirectionalControlledInput';
import { BodyText1 } from 'app/common/typography';
import SearchIcon from 'assets/icons/loupe.svg';
import ClearIcon from 'assets/icons/clear.svg';
import useFocus from 'app/common/hooks/useFocus';
import styles from './searchInput.css';

export const SEARCH_INPUT_THEME_DEFAULT = 'search.input.theme.default';
export const SEARCH_INPUT_THEME_ALT = 'search.input.theme.alt';
/**
 * A text input styled as a search input.
 * All properties are not documented (there is many more), as it is a regular input and behave as expected.
 *
 * @example ./searchInput.md
 */
const SearchInput = ({ value, placeholder, onChange, onClear, theme, dataTestId }) => {
  const { callback: debouncedOnChange } = useDebouncedCallback(onChange, 500);
  const [inputValue, handleInputChange] = useBidirectionalControlledInput(value, debouncedOnChange);
  const [inputRef, setInputFocus] = useFocus();

  const classes = classNames(styles.search, {
    [styles.themeDefault]: theme === SEARCH_INPUT_THEME_DEFAULT,
    [styles.themeAlt]: theme === SEARCH_INPUT_THEME_ALT,
    [styles.noInput]: inputValue.length === 0,
  });

  const focusInput = () => {
    setTimeout(setInputFocus, 0);
  };

  return (
    <BodyText1 className={classes}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        className={styles.input}
        placeholder={placeholder}
        data-testid={dataTestId}
        ref={inputRef}
      />
      <div className={styles.icon}>
        <ClearIcon className={styles.iconClear} onClick={onClear} role="button" />
        <SearchIcon className={styles.iconSearch} onClick={focusInput} role="button" />
      </div>
    </BodyText1>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf([SEARCH_INPUT_THEME_DEFAULT, SEARCH_INPUT_THEME_ALT]),
  dataTestId: PropTypes.string,
};

SearchInput.defaultProps = {
  value: '',
  placeholder: false,
  theme: SEARCH_INPUT_THEME_DEFAULT,
  dataTestId: null,
};

export default SearchInput;
