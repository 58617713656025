import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'app/display/common/components/button';

import styles from 'app/display/common/components/block/style.css';

import getSkinClassName from 'app/display/common/components/block/theme/getSkinClassName';
import { PRIMARY, SECONDARY } from 'app/display/common/components/block/theme/constants';

/**
 * A BlockButton.
 * Theme can be applied to use a variant (see Zeplin link).
 *
 * @see [Zeplin link](https://zpl.io/V00pQ1x)
 * @example ./button.md
 */
const BlockButton = ({ children, className, theme, ...props }) => {
  /*
   * I really need the hoisting on themes here, so var it is.
   * eslint no-var & vars-on-top rules are getting in the way
   */
  const cx = classNames(styles.block, className, getSkinClassName(theme));

  return (
    <Button className={cx} {...props}>
      {children}
    </Button>
  );
};

BlockButton.propTypes = {
  /**
   * theme to apply.
   */
  theme: PropTypes.oneOf([PRIMARY, SECONDARY]),
  /**
   * Additionnal CSS class
   */
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BlockButton.defaultProps = {
  className: null,
  theme: PRIMARY,
};

export default BlockButton;
