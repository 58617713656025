import React from 'react';
import PropTypes from 'prop-types';

import UserPlaceholder from 'assets/icons/user-default.svg';
import Thumbnail from 'app/common/components/thumbnail';

const UserThumbnail = ({ src, alt, className }) => (
  <Thumbnail src={src} alt={alt} className={className} Placeholder={UserPlaceholder} />
);

UserThumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

UserThumbnail.defaultProps = {
  className: null,
  src: null,
  alt: null,
};

export default UserThumbnail;
