import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'app/common/reducers/status';

import {
  FETCH_SURVEY_REPORT_REQUEST,
  FETCH_SURVEY_REPORT_SUCCESS,
  FETCH_SURVEY_REPORT_ERROR,
  removeSurveyPhoto,
} from 'app/network/survey/report/actions/reportTypes';
import { createReducer } from '@reduxjs/toolkit';

export const initialState = {
  entity: undefined,
  status: STATUS_LOADING,
  error: null,
};

function removePhoto(surveyReport, photoId) {
  return surveyReport.reduce((surveyReportAcc, currentReport) => {
    return [
      ...surveyReportAcc,
      {
        ...currentReport,
        questions: currentReport.questions.map(question => ({
          ...question,
          answers: {
            ...question.answers,
            photos: question.answers?.photos?.filter(photo => photo.id !== photoId),
          },
        })),
      },
    ];
  }, []);
}

const reportReducer = createReducer(initialState, builder => {
  builder
    .addCase(FETCH_SURVEY_REPORT_REQUEST, () => {
      return {
        entity: null,
        status: STATUS_LOADING,
        error: null,
      };
    })
    .addCase(FETCH_SURVEY_REPORT_SUCCESS, (state, action) => {
      return {
        entity: action.report,
        status: STATUS_SUCCESS,
        error: null,
      };
    })
    .addCase(FETCH_SURVEY_REPORT_ERROR, (state, action) => {
      return {
        entity: undefined,
        error: action.error,
        status: STATUS_ERROR,
      };
    })
    .addCase(removeSurveyPhoto, (state, action) => {
      return {
        ...state,
        entity: removePhoto(state.entity, action.payload),
      };
    });
});

export default reportReducer;
