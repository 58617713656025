import React from 'react';
import { HeadText4 } from 'app/common/typography';
import styles from 'app/display/common/components/actionBar/links/cancelLink.css';
import { useTranslation } from 'react-i18next';

type DirectoryActionBarCancelLinkProps = {
  to: string;
};

export default function DirectoryActionBarCancelLink({ to }: DirectoryActionBarCancelLinkProps) {
  const { t } = useTranslation();
  return (
    <a href={to} data-testid="action-bar-link">
      <HeadText4 className={styles.uppercase} interactive={true}>
        {t('directory.actionBar.cancelLink')}
      </HeadText4>
    </a>
  );
}
