import Collection from 'domain/collection';
import GuidelineDataFile, { GuidelineDataFileType } from 'domain/guideline/data/file';

export default class GuidelineDataFileCollection extends Collection<GuidelineDataFile> {
  public hasFileType(type: GuidelineDataFileType): boolean {
    return this.some((file: GuidelineDataFile) => file.type === type);
  }

  public getFileByType(type: GuidelineDataFileType): GuidelineDataFile | undefined {
    return this.find((file: GuidelineDataFile) => file.type === type);
  }
}
