import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_ERROR,
  DATE_INTERVAL_SUCCESS,
} from 'app/network/report/visits/actions/visitsDashboardData';

export const initialState = {
  isLoading: false,
  ready: false,
  dateInterval: {
    oldest: undefined,
    latest: undefined,
  },
  // default date filter has to be
  // set using dates bounds
  dateFilter: undefined,
  dashboardData: {
    dataByUser: {},
    visitsByDate: {},
    salesManagersCount: 0,
    visitsDoneCount: 0,
    visitsPlannedCount: 0,
    averageVisitsCount: 0,
    oldestVisit: undefined,
    latestVisit: undefined,
  },
};

export default function visitsDashboardData(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        isLoading: true,
        ready: false,
        dateFilter: action.dateFilter,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ready: true,
        dashboardData: action.dashboardData,
      };
    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        ready: false,
      };
    case DATE_INTERVAL_SUCCESS:
      return {
        ...state,
        dateInterval: action.dateInterval,
      };
    default:
      return state;
  }
}
