import React, { ReactElement, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import useReduxFormComponent from 'app/display/common/hooks/useReduxFormComponent';
import useReduxFormRegisterFields from 'app/display/common/hooks/useReduxFormRegisterFields';
import startLoading from 'app/common/actions/loadingBar/showLoadingBar';
import stopLoading from 'app/common/actions/loadingBar/hideLoadingBar';
import notifySuccess from 'app/display/common/helpers/notifications/notifySuccess';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import notifyWarning from 'app/display/common/helpers/notifications/notifyWarning';
import noop from 'lib/helpers/noop';

type FormProps = {
  name: string;
  component: (...args: any[]) => ReactElement;
  initialValues?: Partial<unknown> | null;
  dynamicFields?: string[] | null;
  validate?: (values: any) => any | null;
  successNotification?: string;
  errorNotification?: string;
  onSubmit?: (values: any) => any;
  onSubmitSuccess?: (result: any) => any;
  onSubmitFail?: (validationErrors: Record<any, unknown>, globalErrorMessage: string) => any | null;
  [key: string]: any;
};

const Form = ({
  name,
  component,
  initialValues = {},
  dynamicFields = null,
  validate = noop,
  successNotification = undefined,
  errorNotification = undefined,
  onSubmit = noop,
  onSubmitSuccess = undefined,
  onSubmitFail = undefined,
  ...props
}: FormProps) => {
  const dispatch = useDispatch();

  // We need to memoize the props so that useReduxFormComponent doesn't regenerate a component on re-render
  const reduxFormProps = useMemo(() => ({ touchOnChange: true, validate }), [validate]);
  const ReduxFormComponent = useReduxFormComponent(component, name, reduxFormProps);

  useReduxFormRegisterFields(name, dynamicFields);

  const handleSubmit = (values: Record<any, any>) => {
    dispatch(startLoading());
    return onSubmit(values);
  };

  const handleSubmitSuccess = async (result: any) => {
    if (successNotification) {
      notifySuccess(successNotification);
    }

    if (onSubmitSuccess) {
      await onSubmitSuccess(result);
    }

    dispatch(stopLoading());
  };

  const handleSubmitFail = async (errors: Record<any, any>, d: any, exception: Error) => {
    assertIsNotCriticalHttpException(exception);

    const { _error: globalErrorMessage, _warning: warning, ...validationErrors } = errors;
    if (warning) {
      notifyWarning(warning);
    } else if (globalErrorMessage) {
      notifyError(globalErrorMessage);
    } else if (errorNotification) {
      notifyError(errorNotification);
    }

    if (onSubmitFail) {
      await onSubmitFail(validationErrors, globalErrorMessage);
    }

    dispatch(stopLoading());
  };

  return (
    <ReduxFormComponent
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
      onSubmitFail={handleSubmitFail}
      {...props}
    />
  );
};

export default Form;
