import { QueryClient } from '@tanstack/react-query';
import createSingleton from 'lib/helpers/createSingleton';

function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 2 * 1000,
        retry: false,
      },
    },
  });
}

export default createSingleton(createQueryClient);
