import React from 'react';
import { RenderSlideContainerProps } from 'yet-another-react-lightbox';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import isCommentsPanelOpen from 'app/common/selectors/slideshow/isCommentsPanelOpen';
import styles from './container.css';

type SlideshowCommentsContainerPropTypes = RenderSlideContainerProps & {
  isFilmstripOpen: boolean;
};

export default function SlideshowCommentsContainer({ children, isFilmstripOpen }: SlideshowCommentsContainerPropTypes) {
  const isOpen = useSelector(isCommentsPanelOpen);
  return (
    <div
      className={classNames(styles.container, {
        [styles.commentsOpen]: isOpen,
        [styles.filmStripOpen]: isFilmstripOpen,
      })}
    >
      {children}
    </div>
  );
}
