import State from 'domain/state';
import ImageMetadata from 'domain/image/metadata';

// API defines brand file type as an enum
// but only with a single value.
// here we force the value
export type BrandFileType = 'logo';

export default class BrandFile {
  constructor(
    public id: string,
    public brandId: string,
    public state: State,
    public type: BrandFileType,
    public fileURL: string,
    public metadata: ImageMetadata
  ) {
    // do nothing.
  }
}
