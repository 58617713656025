import React from 'react';
import classnames from 'classnames';

import NavACL from 'app/common/components/legacyNavMenu/menu/acl';
import NavSettings from 'app/common/components/legacyNavMenu/menu/settings';
import NavImport from 'app/common/components/legacyNavMenu/menu/import';
import NavDisplay from 'app/common/components/legacyNavMenu/menu/display';
import NavRetailNetwork from 'app/common/components/legacyNavMenu/menu/network';
import NavSwitch from 'app/common/components/legacyNavMenu/switch';
import NavActions from 'app/common/components/legacyNavMenu/actions';

import useNavMenu from './index.hook';

import styles from './index.css';

const NavMenu = () => {
  const { isSmallScreen, isFullScreen, isPlatformMenuEnabled } = useNavMenu();

  if (isFullScreen || isPlatformMenuEnabled) {
    return null;
  }

  const navMenuClasses = classnames(styles.navMenu, {
    [styles.onSmallScreen]: isSmallScreen,
  });

  return (
    <nav className={navMenuClasses}>
      <NavSwitch />
      <div className={styles.appMenu}>
        <NavRetailNetwork />
        <NavDisplay />
        <NavImport />
        <NavSettings />
        <NavACL />
      </div>
      <NavActions />
    </nav>
  );
};

export default NavMenu;
