import { STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import {
  GUIDELINES_LOADING,
  FETCH_GUIDELINES_SUCCESS,
  SELECT_GUIDELINE,
  DESELECT_GUIDELINE,
  SELECT_ALL_GUIDELINES,
  DESELECT_ALL_GUIDELINES,
  CHANGE_SORT_CRITERION,
  CHANGE_SORT_ORDER,
  TOGGLE_ACTIONS_POPUP_VISIBILITY,
  HIGHLIGHT_ENTRY,
  UNHIGHLIGHT_ENTRY,
  ADD_GUIDELINES_LIST,
  TOGGLE_GUIDELINE_BROWSE,
  LOAD_GUIDELINES_START,
  REFRESH_GUIDELINES_SUCCESS,
  CLEAR_GUIDELINES_BROWSE,
  CLEAR_SELECTED_ID_LIST,
  CLEAR_HIGHLIGHTED_ENTRY,
} from 'app/display/guideline/list/common/actions';
import { CRITERION_LAST_UPDATE } from 'app/display/guideline/list/common/sortCriteria';
import { ORDER_DESC } from 'app/common/selectors/url';
import { HIDE_MODAL } from 'app/common/actions/modals/modalTypes';

export const GLOBAL_FOLDERS = 'global.folders';
export const PERSONAL_FOLDERS = 'personal.folders';

function toggleListType(type) {
  return type === PERSONAL_FOLDERS ? GLOBAL_FOLDERS : PERSONAL_FOLDERS;
}

export const initialState = {
  status: STATUS_LOADING,
  idList: [],
  nextUrl: null,
  filters: {},
  totalCount: 0,
  selectedIdList: [],
  highlightedEntry: null,
  sortCriterion: CRITERION_LAST_UPDATE,
  sortOrder: ORDER_DESC,
  isActionsPopupVisible: false,
  type: PERSONAL_FOLDERS,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_SELECTED_ID_LIST:
      return {
        ...state,
        selectedIdList: [],
      };
    case CLEAR_HIGHLIGHTED_ENTRY:
      return {
        ...state,
        highlightedEntry: null,
      };
    case LOAD_GUIDELINES_START:
      return {
        ...state,
        idList: [],
        totalCount: 0,
        status: STATUS_LOADING,
        selectedIdList: [],
        highlightedEntry: null,
      };
    case REFRESH_GUIDELINES_SUCCESS:
      return {
        ...state,
        selectedIdList: [],
        highlightedEntry: null,
      };
    case FETCH_GUIDELINES_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCESS,
        idList: action.guidelines.map(guideline => guideline.id),
        filters: action.filters,
        totalCount: action.totalCount,
        nextUrl: action.nextUrl || null,
      };
    case GUIDELINES_LOADING:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case ADD_GUIDELINES_LIST:
      return {
        ...state,
        status: STATUS_SUCCESS,
        idList: [...state.idList, ...action.guidelineIds],
        nextUrl: action.nextUrl || null,
      };
    case SELECT_GUIDELINE:
      if (state.selectedIdList.includes(action.guidelineId)) {
        return state;
      }
      return {
        ...state,
        selectedIdList: [...state.selectedIdList, action.guidelineId],
      };
    case DESELECT_GUIDELINE:
      return {
        ...state,
        selectedIdList: state.selectedIdList.filter(id => id !== action.guidelineId),
      };
    case SELECT_ALL_GUIDELINES:
      return {
        ...state,
        selectedIdList: action.guidelineIds || [],
      };
    case DESELECT_ALL_GUIDELINES:
      return {
        ...state,
        selectedIdList: [],
      };
    case CHANGE_SORT_CRITERION:
      return {
        ...state,
        sortCriterion: action.criterion,
      };
    case CHANGE_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.order,
      };
    case TOGGLE_ACTIONS_POPUP_VISIBILITY:
      return {
        ...state,
        isActionsPopupVisible: !state.isActionsPopupVisible,
      };
    case HIGHLIGHT_ENTRY:
      return {
        ...state,
        highlightedEntry: action.entry,
      };
    case HIDE_MODAL:
    case UNHIGHLIGHT_ENTRY:
      if (state.highlightedEntry == null) {
        return state;
      }
      return {
        ...state,
        highlightedEntry: null,
      };
    case TOGGLE_GUIDELINE_BROWSE:
      return { ...state, type: toggleListType(state.type) };
    case CLEAR_GUIDELINES_BROWSE:
      return {
        ...state,
        status: STATUS_SUCCESS,
        idList: [],
        filters: [],
        totalCount: null,
        nextUrl: null,
      };
    default:
      return state;
  }
}
