import React from 'react';
import PropTypes from 'prop-types';
import Button from 'app/display/common/components/button';
import classNames from 'classnames';
import styles from './index.css';
import userMenuButton from './userMenuButton.css';

const UserMenuButton = ({ children, onClick }) => {
  return (
    <Button onClick={onClick} className={classNames(styles.link, userMenuButton.button)}>
      {children}
    </Button>
  );
};

UserMenuButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UserMenuButton;
