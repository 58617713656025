import getUserTokenFromLoginCookie from 'app/common/helpers/auth/getUserTokenFromLoginCookie';
import getDecodedUserTokenFromLoginCookie from 'app/common/helpers/auth/getDecodedUserTokenFromLoginCookie';
import { AnyAction } from 'redux';

export const USER_AUTH_SUCCESS = 'UserAuthSuccess';
export const USER_AUTH_ERROR = 'UserAuthError';
export const USER_AUTH_REQUESTED = 'UserAuthRequested';
export const APP_AUTH_SUCCESS = 'AppAuthSuccess';
export const APP_AUTH_ERROR = 'AppAuthError';
export const APP_AUTH_REQUESTED = 'AppAuthRequested';
export const AUTH_CLEAR_DATA = 'auth.clear.data';

export const FORK_TOKEN_START = 'auth.token.fork.start';
export const FORK_TOKEN_SUCCESS = 'auth.token.fork.success';
export const FORK_TOKEN_ERROR = 'auth.token.fork.error';

export const REFRESH_TOKEN_START = 'auth.token.refresh.start';
export const REFRESH_TOKEN_SUCCESS = 'auth.token.refresh.success';
export const REFRESH_TOKEN_ERROR = 'auth.token.refresh.error';

export type AuthState = {
  userToken?: string | null;
  apiKey: string | null;
  appToken: string | null;
  appAuthenticating: boolean;
  userAuthenticating: boolean;
  email?: string | null;
  id?: string | null;
  loadingEditor3dToken: boolean;
  tokenForkingError: Error | null;
  tokenExpirationTime?: number | null;
};

// Since this file is load at runtime and getUserTokenFromLoginCookie / getDecodedUserTokenFromLoginCookie
// may throw errors, we have to delay the creation of the initial state in a function,
// otherwise the errors won't be catch by the error handler (which is not yet initialized).
export function createInitialState(): AuthState {
  const userToken = getUserTokenFromLoginCookie();
  const decodedUserToken = getDecodedUserTokenFromLoginCookie();

  return {
    userToken,
    apiKey: null,
    appToken: null,
    appAuthenticating: false,
    userAuthenticating: false,
    email: decodedUserToken?.user?.email,
    id: decodedUserToken?.user?.id,
    loadingEditor3dToken: false,
    tokenForkingError: null,
    tokenExpirationTime: decodedUserToken?.exp,
  };
}

const reducer = (state = createInitialState(), action: AnyAction): AuthState => {
  switch (action.type) {
    case USER_AUTH_REQUESTED:
      return {
        ...state,
        userAuthenticating: true,
      };
    case USER_AUTH_SUCCESS:
      return {
        ...state,
        userAuthenticating: false,
        email: action.email,
        userToken: action.token,
        tokenExpirationTime: action.tokenExpirationTime,
      };
    case USER_AUTH_ERROR:
      return {
        ...state,
        userAuthenticating: false,
      };
    case APP_AUTH_REQUESTED:
      return {
        ...state,
        appAuthenticating: true,
      };
    case AUTH_CLEAR_DATA:
      // TODO simply return initialState with the following default values when
      // the login system is implemented.
      return {
        userToken: null,
        apiKey: null,
        appToken: null,
        appAuthenticating: false,
        userAuthenticating: false,
        email: null,
        id: null,
        tokenExpirationTime: null,
        loadingEditor3dToken: false,
        tokenForkingError: null,
      };
    case FORK_TOKEN_START:
      return {
        ...state,
        loadingEditor3dToken: true,
        tokenForkingError: null,
      };
    case FORK_TOKEN_SUCCESS:
      return {
        ...state,
        loadingEditor3dToken: false,
        tokenForkingError: null,
      };
    case FORK_TOKEN_ERROR:
      return {
        ...state,
        loadingEditor3dToken: false,
        tokenForkingError: action.error,
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        userToken: action.token,
        tokenExpirationTime: action.tokenExpirationTime,
      };
    default:
      return state;
  }
};

export default reducer;
