import React from 'react';
import UserStatusAnnotation from 'app/directory/user/common/components/statusAnnotation';
import useShowModal from 'app/display/common/hooks/useShowModal';
import useUser from 'app/common/hooks/useUser';
import DeactivateUserModal from 'app/directory/user/common/modals/toggleUserStatus/deactivate';
import ActivateUserModal from 'app/directory/user/common/modals/toggleUserStatus/activate';
import useCanEditUser from 'app/directory/user/sheet/common/hooks/useCanEditUser';
import styles from './index.css';

export default function SheetUserStatusAnnotation({ userId, isIwdUser }: { userId: string; isIwdUser: boolean }) {
  const { user } = useUser(userId);
  const ToggleStatusModal = user?.state.enabled ? DeactivateUserModal : ActivateUserModal;
  const { canEditUser, isLoading } = useCanEditUser(userId);
  const openToggleStatusModal = useShowModal(ToggleStatusModal, { userId });
  const onClick = canEditUser && !isIwdUser ? openToggleStatusModal : undefined;

  return (
    <div className={styles.sheetAnnotationsContainer}>
      <UserStatusAnnotation userId={userId} onClick={onClick} isLoading={isLoading} />
    </div>
  );
}
