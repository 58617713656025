import { StatePayload } from 'domain/state/factory';
import { RelationWithUrlPayloadType } from 'domain/types';
import FileState from 'domain/guideline/fileState';

export type ItemFileMetadata = {
  extension: string;
  hasTransparentEdges?: boolean;
  height?: number;
  mimeType: string;
  path: string;
  size: number;
  width?: number;
};

export type ItemFileStatePayload = StatePayload & {
  status: 'ready' | 'generating';
};

export enum ItemFileType {
  DISPLAY_FACING = 'facing',
  DISPLAY_FACING_MEDIUM = 'display-facing-medium',
  DISPLAY_FOLDED = 'display-folded',
  DISPLAY_FOLDED_MEDIUM = 'display-folded-medium',
  DISPLAY_SIDE = 'display-side',
  DISPLAY_SIDE_MEDIUM = 'display-side-medium',
  DISPLAY_SOURCE = 'display-source',
  DISPLAY_SOURCE_V4 = 'display-source-v4',
  TEXTURE = 'texture',
  TEXTURE_MEDIUM = 'texture-medium',
  THUMBNAIL = 'thumbnail',
}

export default class ItemFile {
  constructor(
    public id: string,
    public item: RelationWithUrlPayloadType,
    public metadata: ItemFileMetadata,
    public metadatas: ItemFileMetadata,
    public url: string,
    public type: ItemFileType,
    public fileURL: string,
    public state: FileState
  ) {
    // do nothing.
  }
}
