import BrandPayload from 'domain/brand/factory/BrandPayload';
import Brand from 'domain/brand';
import StateFactory from 'domain/state/factory';
import BrandFileFactory from 'domain/brand/file/factory';
import sortBrandsByName from 'app/display/common/helpers/sortBrandsByName';
import BrandFile from 'domain/brand/file';

export default class BrandFactory {
  static createFromApiPayload(payload: BrandPayload): Brand {
    const state = StateFactory.createFromApiPayload(payload.state);
    const files = BrandFileFactory.createCollectionFromApiPayload(payload.files);

    return new Brand(payload.id, payload.name, payload.type, state, files, payload.color, payload.idv4);
  }

  static createSortedCollectionFromApiPayload(payload: BrandPayload[]): Brand[] {
    const brands = payload.map(BrandFactory.createFromApiPayload);

    return sortBrandsByName(brands);
  }

  static createFromBrandAndLogo(brand: Brand, logo: BrandFile): Brand {
    return new Brand(brand.id, brand.name, brand.type, brand.state, [logo], brand.color, brand.idv4);
  }
}
