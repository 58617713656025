import useGuidelineTags from 'app/display/common/hooks/useGuidelineTags';

/**
 * Checks if the value passed is a guideline tag duplicate and ignores text case format.
 *
 * When id is provided will exclude the tag with that id from the list before duplicate check.
 * This is useful when editing a tag and just a letter case is changed
 *
 * Note: value.toLowerCase() is used to compare
 *
 * @param value: string | undefined
 * @param id: string
 * @returns boolean
 */
export default function useIsGuidelineTagDuplicate() {
  const { tags } = useGuidelineTags();
  const allTags = tags || [];

  return (value: string | undefined, id = '') => {
    if (!value) {
      return false;
    }
    const isDuplicate = allTags
      .filter(tag => tag.id !== id)
      .map(tag => tag.name.toLowerCase())
      .includes(value.trim().toLowerCase());
    return isDuplicate;
  };
}
