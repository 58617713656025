import moment from 'moment';

export default class DateFormatter {
  static formatForApiPayload(date: Date): string {
    return date.toISOString();
  }

  static formatAsDateString(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  static getTimeNow(): string {
    return new Date().toISOString();
  }
}
