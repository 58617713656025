import Guideline from 'domain/guideline';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useIsCurrentUserAllowedToUpdateOthersGuidelines from 'app/display/guideline/common/hooks/acl/useIsCurrentUserAllowedToUpdateOthersGuidelines';

export default function useIsCurrentUserAllowedToEditGuideline(guideline: Guideline | undefined): boolean {
  const { currentUser } = useCurrentUser();
  const rightToEditOthers = useIsCurrentUserAllowedToUpdateOthersGuidelines();

  if (!guideline) {
    return false;
  }

  return rightToEditOthers || guideline.isCurrentUserOwner(currentUser);
}
