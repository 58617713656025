import React from 'react';
import { AvailableActivity } from 'app/display/import/hooks/useAvailableActivities';
import List from 'app/display/common/components/search/list';
import ActivityTypeListRow from './activityTypeListRow';

type ActivityTypeListType = {
  activityTypes: AvailableActivity[];
  selectedActivityTypes: string[];
  onClick: (id: string) => void;
  isSelectAllActivityTypes: boolean;
  searchTerm?: string;
};

const ActivityTypeList = ({
  activityTypes,
  selectedActivityTypes,
  onClick,
  isSelectAllActivityTypes,
  searchTerm,
}: ActivityTypeListType) => {
  let uniqKey = 0;
  const isEmpty = activityTypes?.length === 0;
  return (
    <List isEmpty={isEmpty}>
      {activityTypes?.map(activityType => {
        const alreadySelectedLength = selectedActivityTypes.find(activity => activityType?.code === activity)?.length;
        const alreadySelected = (alreadySelectedLength && alreadySelectedLength > 0) || false;
        const isSelected = isSelectAllActivityTypes || alreadySelected;
        const isDisabled = false;
        return (
          <ActivityTypeListRow
            activityType={activityType}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onClick={onClick}
            searchTerm={searchTerm}
            key={`row-${activityType.code}-${uniqKey++}`}
          />
        );
      })}
    </List>
  );
};

export default ActivityTypeList;
