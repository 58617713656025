import React from 'react';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import DrawingIcon from 'assets/icons/icon-show.svg';
import HideDrawingIcon from 'assets/icons/icon-eye-hide.svg';
import Button from 'app/display/common/components/button';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import useAction from 'app/display/common/hooks/useAction';
import { displayDrawingSVG } from 'app/common/actions/slideshow';
import getIsDrawingVisible from 'app/common/selectors/slideshow/getIsDrawingVisible';
import styles from 'app/common/components/slideshow/drawing/icon.css';
import { useLightboxState } from 'yet-another-react-lightbox';

export const SLIDESHOW_DRAWING_ICON_TESTID = 'slideshowDrawingIcon';
export const SLIDESHOW_SHOW_DRAWING_ICON_TESTID = 'showDrawingIcon';
export const SLIDESHOW_HIDE_DRAWING_ICON_TESTID = 'hideDrawingIcon';

export default function SlideshowDrawingIcon() {
  const isVisible = useSelector(getIsDrawingVisible);
  const { currentSlide } = useLightboxState();

  const hasDrawing = currentSlide?.drawingUrl !== null;
  const buttonClassNames = classNames('yarl__button', {
    [styles.isDisabled]: !hasDrawing,
  });

  const displayDrawing = useAction(displayDrawingSVG);

  const handleOnClick = () => {
    displayDrawing();
  };

  return (
    <div className={styles.icon}>
      <Button
        className={buttonClassNames}
        type="button"
        onClick={handleOnClick}
        isDisabled={!hasDrawing}
        data-testid={SLIDESHOW_DRAWING_ICON_TESTID}
      >
        {isVisible ? (
          <SlideshowButton Icon={DrawingIcon} isActive={hasDrawing} dataTestid={SLIDESHOW_SHOW_DRAWING_ICON_TESTID} />
        ) : (
          <SlideshowButton
            Icon={HideDrawingIcon}
            isActive={hasDrawing}
            dataTestid={SLIDESHOW_HIDE_DRAWING_ICON_TESTID}
          />
        )}
      </Button>
    </div>
  );
}
