import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CurrentUserAvatar from 'app/common/user/components/currentUserAvatar';
import DisplayUserMenu from 'app/display/common/components/appShell/header/userMenu';
import { APPSHELL_USER_MENU } from 'app/common/constants/userflow';
import styles from './userButton.css';

const UserButton = ({ onClick, isUserMenuVisible }) => (
  <>
    <button
      type="button"
      className={classNames(styles.userButton, {
        [styles.bordered]: isUserMenuVisible,
      })}
      onClick={onClick}
      data-testid={APPSHELL_USER_MENU}
    >
      <CurrentUserAvatar />
    </button>
    <DisplayUserMenu />
  </>
);

UserButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isUserMenuVisible: PropTypes.bool.isRequired,
};

export default UserButton;
