import React from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from 'assets/icons/edit.svg';
import DirectoryAction from 'app/directory/user/sheet/view/components/popupActions/action';

type EditUserActionProps = {
  to: string;
};

export default function DirectoryEditUserAction({ to }: EditUserActionProps) {
  const { t } = useTranslation();

  return (
    <DirectoryAction
      icon={<EditIcon />}
      label={t('directory.actionsPopup.actions.modify')}
      linkData={{ href: to }}
      dataTestId="popupUserEditButton"
    />
  );
}
