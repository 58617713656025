import { createReducer } from '@reduxjs/toolkit';
import {
  addToSelection,
  removeFromSelection,
  resetSelection,
  setSelection,
  initState,
} from 'app/display/guideline/common/helpers/catalogTree/selection';
import {
  INIT_STATE,
  REMOVE_FROM_SELECTION,
  ADD_TO_SELECTION,
  SET_SELECTION,
  RESET_SELECTION,
} from 'app/display/guideline/list/search/actions/sidebar/catalogFilter';

export const initialState = initState();

export default createReducer(initialState, builder => {
  builder
    .addCase(INIT_STATE, (draftState, action) => initState(action.tree))
    .addCase(ADD_TO_SELECTION, (draftState, action) => addToSelection(action.path, draftState))
    .addCase(REMOVE_FROM_SELECTION, (draftState, action) => removeFromSelection(action.path, draftState))
    .addCase(RESET_SELECTION, draftState => resetSelection(draftState))
    .addCase(SET_SELECTION, (draftState, action) => setSelection(action.paths, draftState));
});
