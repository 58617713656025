import styles from 'app/common/components/slideshow/information/index.css';
import FullUserAvatar from 'app/common/user/components/fullUserAvatar';
import { BodyText1, BodyText2 } from 'app/common/typography';
import React from 'react';
import { ApprovalType } from 'app/common/components/slideshow/information/approvedBy';
import { useTranslation } from 'react-i18next';
import Skeleton from 'app/common/components/skeleton';
import useUserAnonymized from 'app/common/hooks/useUserAnonymized';
import FullUser from 'domain/user/fullUser';
import moment from 'moment/moment';

type ApprovedByRowProps = {
  approval: ApprovalType;
  currentUser?: FullUser;
};

export default function ApprovedByRow({ approval, currentUser }: ApprovedByRowProps) {
  const { t } = useTranslation();
  const { user, isLoading } = useUserAnonymized(approval.user.id);
  const momentDate = moment(approval.date);

  if (isLoading) {
    return (
      <div className={styles.skeletons}>
        <Skeleton width={22} height={22} circle={true} />
        <Skeleton width={200} height={22} />
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className={styles.approval}>
      <div className={styles.userData}>
        <FullUserAvatar className={styles.avatar} user={user} />
        <BodyText2 className={styles.userName}>{user.name}</BodyText2>
        <BodyText2 className={styles.date}>{momentDate.fromNow()}</BodyText2>
      </div>
      {approval.user.id === currentUser?.id && (
        <BodyText1 className={styles.you}>({t('slideshow.infoPanel.you')})</BodyText1>
      )}
    </div>
  );
}
