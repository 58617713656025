import React from 'react';
import getSearchParameters from 'app/common/helpers/getSearchParameters';
import { BodyText1 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './message.css';

export default function LoginMessage() {
  const searchParams = getSearchParameters();
  const { t } = useTranslation();

  if (!searchParams.get('challenge')) {
    return null;
  }

  return (
    <div className={styles.messageLayout}>
      <div className={styles.message}>
        <BodyText1>{t('login.message.challenge')}</BodyText1>
      </div>
    </div>
  );
}
