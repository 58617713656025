import React from 'react';
import { BodyText2 } from 'app/common/typography';
import classNames from 'classnames';
import Button from 'app/display/common/components/button';
import styles from './index.css';
import SlideshowButton from '../buttons/button';

export type InformationSectionProps = {
  title: string;
  children?: React.ReactNode;
  className?: string;
  isAction?: boolean;
  isHidden?: boolean;
  onClick?: () => void;
  dataTestId?: string;
  Icon?: any;
};

export default function Section({
  title,
  children,
  className,
  isAction = false,
  isHidden = false,
  onClick,
  dataTestId,
  Icon,
}: InformationSectionProps) {
  const cssSectionContent = classNames(styles.sectionContent, className);
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <BodyText2 className={styles.subtitle}>{title}</BodyText2>
        {isAction &&
          (!isHidden ? (
            <Button className="yarl__button" onClick={onClick} dataTestId={dataTestId}>
              <SlideshowButton Icon={Icon} className={styles.editIcon} isActive={true} />
            </Button>
          ) : null)}
      </div>
      <div className={cssSectionContent}>{children}</div>
    </div>
  );
}
