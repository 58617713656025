import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_BLUEJAY, COLOR_STORM } from 'app/common/style/colors';

import generator from 'app/common/typography/colors/palettes/helpers/generator';

export const GUIDELINE_STATUS_ALT_COLOR_PALETTE = 'guideline-status-alt-color-palette';

export default generator({
  basic: {
    [COLOR_PRIMARY]: COLOR_BLUEJAY,
    [COLOR_SECONDARY]: COLOR_BLUEJAY,
  },
  hover: {
    [COLOR_PRIMARY]: COLOR_BLUEJAY,
    [COLOR_SECONDARY]: COLOR_BLUEJAY,
  },
  active: {
    [COLOR_PRIMARY]: COLOR_STORM,
    [COLOR_SECONDARY]: COLOR_STORM,
  },
});
