import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';
import NonCriticalErrorBoundary from 'app/display/common/components/errorBoundary/nonCritical';

export const SlideshowPage = React.lazy(() => import(/* webpackChunkName: "slideshow" */ 'app/slideshow/page'));

const SlideshowPageWrapper = () => (
  <Suspense fallback={<Spinner />}>
    <NonCriticalErrorBoundary>
      <SlideshowPage />
    </NonCriticalErrorBoundary>
  </Suspense>
);

export default SlideshowPageWrapper;
