export const CREATE_BRAND_START = 'brand.create.start';
export const CREATE_BRAND_SUCCESS = 'brand.create.success';
export const CREATE_BRAND_ERROR = 'brand.create.error';

export const UPDATE_BRAND_START = 'brands.update.start';
export const UPDATE_BRAND_SUCCESS = 'brands.update.success';
export const UPDATE_BRAND_ERROR = 'brands.update.error';

export const UPLOAD_BRAND_LOGO_START = 'brands.upload.logo.start';
export const UPLOAD_BRAND_LOGO_SUCCESS = 'brands.upload.logo.success';
export const UPLOAD_BRAND_LOGO_ERROR = 'brands.upload.logo.error';

export const FETCH_BRANDS_REQUEST = 'brands.fetch.request';
export const FETCH_BRANDS_SUCCESS = 'brands.fetch.success';
export const FETCH_BRANDS_ERROR = 'brands.fetch.error';

export const UPDATE_FOLDER_START = 'catalog.update.folder.start';
export const UPDATE_FOLDER_SUCCESS = 'catalog.update.folder.success';
export const UPDATE_FOLDER_ERROR = 'catalog.update.folder.error';

export const FETCH_MODEL_3DS_START = 'models3ds.fetch.start';
export const FETCH_MODEL_3DS_SUCCESS = 'model3ds.fetch.success';
export const FETCH_MODEL_3DS_ERROR = 'model3ds.fetch.error';

export const CREATE_MODEL_3D_START = 'model3d.create.start';
export const CREATE_MODEL_3D_SUCCESS = 'model3d.create.success';
export const CREATE_MODEL_3D_ERROR = 'model3d.create.error';

export const DELETE_BRAND_START = 'catalog.delete.brand.start';
export const DELETE_BRAND_SUCCESS = 'catalog.delete.brand.success';
export const DELETE_BRAND_ERROR = 'catalog.delete.brand.error';
