const ONE_DAY_IN_SEC = 60 * 60 * 24;

/**
 * If (current time + timeBeforeExpirationInSec) is greater then tokenExpirationTimeInSec return true
 *
 * @param tokenExpirationTimeInSec
 * @param timeBeforeExpirationInSec
 */
export default function isTokenExpired(
  tokenExpirationTimeInSec?: number | null,
  timeBeforeExpirationInSec = ONE_DAY_IN_SEC
) {
  const currentTimeInSec = Date.now() / 1000;
  return tokenExpirationTimeInSec != null && currentTimeInSec + timeBeforeExpirationInSec > tokenExpirationTimeInSec;
}
