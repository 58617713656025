import React, { useEffect, useState } from 'react';
import useGuidelineTags from 'app/display/common/hooks/useGuidelineTags';
import sortListByName from 'app/common/helpers/sortListByName';
import GuidelinesTagListHeader from 'app/network/tag/guideline/components/list/listHeader';
import GuidelinesTagActionBar from 'app/network/tag/guideline/components/actionbar';
import GuidelinesTagListBody from 'app/network/tag/guideline/components/list/listBody';
import { GuidelineTag } from 'domain/guideline/tag';
import GuidelineTagPagePlusMenu from 'app/network/tag/guideline/components/plusMenu';
import { BodyText1 } from 'app/common/typography';
import SearchIcon from 'assets/icons/loupe.svg';
import useFocus from 'app/common/hooks/useFocus';
import styles from './index.css';

export const GUIDELINE_TAG_LIST_PAGE = 'guideline-tag-list-page';

export default function GuidelinesTagList() {
  const { tags, isLoading } = useGuidelineTags();
  const [searchTerm, setSearchTerm] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [tagList, setTagList] = useState<GuidelineTag[]>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const [newTagName, setNewTagName] = useState<string | null>(null);
  const [inputRef, setInputFocus] = useFocus();

  const focusInput = () => {
    setTimeout(setInputFocus, 0);
  };

  const scrollToNewTag = () => {
    if (newTagName) {
      const newTag = tagList.find(tag => tag.name.toLowerCase() === newTagName.toLowerCase());
      const el = document.querySelector(`#guidelineTag-${newTag?.id}`);
      el?.scrollIntoView();
      setNewTagName(null);
    }
  };

  const handleSubmitSearchTerm = () => {
    setSearchTerm(inputValue);
    focusInput();
  };

  const handleChangeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitSearchTerm();
    }
  };

  const clearTagSelection = () => {
    setSelectedTagIds([]);
  };

  const toggleTagSelection = (id: string) => {
    const isSelected = selectedTagIds.some(tagId => tagId === id);
    if (isSelected) {
      setSelectedTagIds(selectedTagIds.filter(tagId => tagId !== id));
    } else {
      setSelectedTagIds([...selectedTagIds, id]);
    }
  };

  const toggleAll = (isSelectAll: boolean) => {
    if (isSelectAll) {
      // We use tags here, tagList can be filtered at this point
      const result = tags || [];
      setSelectedTagIds(result.map(tag => tag.id));
    } else {
      clearTagSelection();
    }
  };

  useEffect(() => {
    const sortedList = tags ? sortListByName(tags) : [];
    if (searchTerm.length > 0) {
      // Update tagList when searching
      const filteredList = sortedList.filter(tag => tag.name.toLowerCase().includes(searchTerm.toLowerCase()));
      setTagList(filteredList);
    } else {
      // Reset the search
      setTagList(sortedList);
    }
  }, [tags, searchTerm]);

  useEffect(() => {
    // Scroll to the new tag after the tagList was updated
    scrollToNewTag();
  }, [tagList]);

  if (isLoading) {
    return null;
  }

  return (
    <div className={styles.tagList} data-testid={GUIDELINE_TAG_LIST_PAGE}>
      <div className={styles.searchWrapper}>
        <BodyText1 className={styles.search}>
          <input
            type="text"
            value={inputValue}
            onChange={handleChangeInputValue}
            onKeyDown={handleOnKeyDown}
            className={styles.input}
            ref={inputRef}
          />
          <div className={styles.icon}>
            <SearchIcon onClick={handleSubmitSearchTerm} role="button" />
          </div>
        </BodyText1>
      </div>
      <GuidelinesTagActionBar
        list={tagList}
        allTags={tags}
        selectedTagIds={selectedTagIds}
        toggleAll={toggleAll}
        setTagName={setNewTagName}
        clearTagSelection={clearTagSelection}
      />
      <div className={styles.tagList}>
        <GuidelinesTagListHeader />
        <GuidelinesTagListBody list={tagList} selectedTagIds={selectedTagIds} toggleTagSelection={toggleTagSelection} />
      </div>
      <GuidelineTagPagePlusMenu className={styles.addButtonWrapper} setNewTagName={setNewTagName} />
    </div>
  );
}
