import React from 'react';
import classnames from 'classnames';

import PlatformLogo from 'app/common/components/platformMenu/logo';
import PlatformLinks from 'app/common/components/platformMenu/links';
import PlatformActions from 'app/common/components/platformMenu/actions';

import usePlatformMenu from './index.hook';

import styles from './index.css';

const PlatformMenu = () => {
  const { isSmallScreen, isFullScreen, isPlatformMenuEnabled } = usePlatformMenu();

  if (isFullScreen || !isPlatformMenuEnabled) {
    return null;
  }

  const platformMenuClasses = classnames(styles.platformMenu, {
    [styles.onSmallScreen]: isSmallScreen,
  });

  return (
    <nav className={platformMenuClasses}>
      <PlatformLogo />
      <PlatformLinks />
      <PlatformActions />
    </nav>
  );
};

export default PlatformMenu;
