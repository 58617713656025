import React from 'react';
import { useSelector } from 'react-redux';
import { PATH_LOGOUT } from 'config/routePaths';
import PrivateRoute from 'app/common/components/route/private';

import { getUserToken } from 'app/common/selectors/auth';

const AuthenticatedRoute = ({
  path,
  component,
  isAuthorized,
  exact,
  strict,
  forbiddenComponent,
  redirectTo,
  redirectDelay,
}) => {
  const isAuthenticated = useSelector(getUserToken) != null;

  return (
    <PrivateRoute
      path={path}
      component={component}
      isAuthorized={isAuthenticated && isAuthorized}
      exact={exact}
      strict={strict}
      forbiddenComponent={forbiddenComponent}
      redirectTo={isAuthenticated ? redirectTo : PATH_LOGOUT}
      redirectDelay={redirectDelay}
    />
  );
};

AuthenticatedRoute.propTypes = {
  ...PrivateRoute.propTypes,
};

AuthenticatedRoute.defaultProps = {
  ...PrivateRoute.defaultProps,
  isAuthorized: true,
};

export default AuthenticatedRoute;
