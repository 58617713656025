export const PATH_INDEX = '/';

/* Guideline */
export const PATH_GUIDELINE_BROWSE = '/guidelines';
export const PATH_GUIDELINE_SEARCH = '/guidelines/search';
export const PATH_GUIDELINE_CREATE_2D = '/guidelines/create/2d';
export const PATH_GUIDELINE_CREATE_3D = '/guidelines/create/3d';
export const PATH_GUIDELINE_EDIT = '/guidelines/:guidelineId/edit';
export const PATH_GUIDELINE_OPEN = '/guidelines/:guidelineId/open';
export const PATH_GUIDELINE_SHOW = '/guidelines/:guidelineId';
export const PATH_GUIDELINE_MANAGE_DOCUMENT = '/guidelines/:guidelineId/document/manage';
export const PATH_GUIDELINE_DOCUMENT = '/guidelines/:guidelineId/document';

/* Campaign */

export const PATH_GUIDELINE_EDIT_CAMPAIGN_CREATE = `${PATH_GUIDELINE_EDIT}/campaigns/create`;
export const PATH_GUIDELINE_CREATE_2D_CAMPAIGN_CREATE = `${PATH_GUIDELINE_CREATE_2D}/campaigns/create`;
export const PATH_GUIDELINE_CREATE_3D_CAMPAIGN_CREATE = `${PATH_GUIDELINE_CREATE_3D}/campaigns/create`;
export const PATH_GUIDELINE_EDIT_CAMPAIGN_VIEW = `${PATH_GUIDELINE_EDIT}/campaigns/view/:campaignId`;
export const PATH_GUIDELINE_CREATE_2D_CAMPAIGN_VIEW = `${PATH_GUIDELINE_CREATE_2D}/campaigns/view/:campaignId`;
export const PATH_GUIDELINE_CREATE_3D_CAMPAIGN_VIEW = `${PATH_GUIDELINE_CREATE_3D}/campaigns/view/:campaignId`;
export const PATH_GUIDELINE_EDIT_CAMPAIGN_EDIT = `${PATH_GUIDELINE_EDIT}/campaigns/edit/:campaignId`;
export const PATH_GUIDELINE_CREATE_2D_CAMPAIGN_EDIT = `${PATH_GUIDELINE_CREATE_2D}/campaigns/edit/:campaignId`;
export const PATH_GUIDELINE_CREATE_3D_CAMPAIGN_EDIT = `${PATH_GUIDELINE_CREATE_3D}/campaigns/edit/:campaignId`;

export const PATH_GUIDELINE_CAMPAIGN_VIEW = '/guidelines/campaigns/:campaignId';
export const PATH_GUIDELINE_CAMPAIGN_EDIT = '/guidelines/campaigns/:campaignId/edit';

/* Catalog */
export const PATH_CATALOG_BROWSE = '/catalog';
export const PATH_CATALOG_SHOW = '/catalog/:itemId';
export const PATH_CATALOG_SEARCH = '/catalog/search';
export const PATH_CATALOG_CREATE = '/catalog/create';
export const PATH_CATALOG_EDIT = '/catalog/:itemId/edit';
export const PATH_CATALOG_EDITOR = '/catalog/:itemId/editor';
export const PATH_CATALOG_VARIANT_EDIT = '/catalog/:itemId/variants/:variantId/edit';

/* Import */
export const PATH_IMPORT_FILES = '/import';

/* Login */
export const PATH_LOGIN = '/login';
export const PATH_DETACH = '/logout/detach';
export const PATH_LOGOUT = '/logout';
export const PATH_LOGOUT_LEGACY = '/data/auth/logout';

/* Acl */
export const PATH_ACL_INDEX = '/acl';
export const PATH_ACL_DISPLAY = '/acl/display';
export const PATH_ACL_NETWORK = '/acl/network';

export const PATH_ASSETS_LEGACY = '/#/library/list';

export const PATH_NETWORK_INDEX = '/retailnetwork';
export const PATH_NETWORK_STORES = '/retailnetwork/pointofsale';
export const PATH_NETWORK_STORE_SHEET = '/retailnetwork/pointofsale/info?id=:id';
export const PATH_NETWORK_STORE_SHEET_SURVEY_ANSWER =
  '/retailnetwork/pointofsale/info?id=:id&tab=survey&showSurveyAnswer=:surveyAnswerId';
export const PATH_NETWORK_STORES_ADD = '/retailnetwork/pointofsale/add';
export const PATH_NETWORK_STORES_MAP = '/retailnetwork/pointofsale/map';
export const PATH_NETWORK_SHAREDDOCUMENTS = '/retailnetwork/shareddocument';
export const PATH_NETWORK_SHAREDDOCUMENTS_LIST_ALL = '/retailnetwork/shareddocument/list/all';
export const PATH_NETWORK_SHAREDDOCUMENTS_EDIT = '/retailnetwork/shareddocument/edit';
export const PATH_NETWORK_REPORTS = '/retailnetwork/report';
export const PATH_NETWORK_LIBRARY = '/retailnetwork/library';
export const PATH_NETWORK_CAMPAIGN = '/retailnetwork/campaign';
export const PATH_NETWORK_TAG = '/retailnetwork/tag';
export const PATH_NETWORK_ACL = '/retailnetwork/acl';
export const PATH_NETWORK_ADMIN_STORE_TABS = '/retailnetwork/admin/pos/tabs';
export const PATH_NETWORK_DASHBOARD = '/retailnetwork/dashboard';
export const PATH_NETWORK_EXPORT = '/retailnetwork/export';
/* Survey report */
export const PATH_SURVEY = '/retailnetwork/survey';
export const PATH_SURVEY_PUBLISHED = '/retailnetwork/survey/published';
export const PATH_SURVEY_REPORT = '/retailnetwork/survey/:id/report';
export const PATH_SURVEY_REPORT_ANSWERS = '/retailnetwork/survey/:id/report/answers';
export const PATH_SURVEY_REPORT_PROGRESSION = '/retailnetwork/survey/:id/report/progression';
export const PATH_SURVEY_EDIT = '/retailnetwork/survey/edit';

export const PATH_SURVEY_REPORT_VISITS = '/reports/visits';
export const PATH_SURVEY_REPORT_VISITS_SHOW = '/reports/visits/:uuid';

export const PATH_DIRECTORY_INDEX = '/directory';
export const PATH_DIRECTORY_ADD = '/directory/add';
export const PATH_DIRECTORY_MYACCOUNT = '/directory/myaccount';
export const PATH_DIRECTORY_INFO = '/directory/info?id=:id';
export const PATH_HELP_CENTER = '/zendesk/login';

/* Settings */
export const PATH_SETTINGS = '/settings';
export const PATH_SETTINGS_PRESET_VARIANT = '/settings/presetvariant';
export const PATH_SETTINGS_PRESET_VARIANT_EDIT = '/settings/presetvariant/:presetVariantId';

export const PATH_DASHBOARD = '/dashboard';
export const PATH_DISCOVER = '/discover/:moduleName';

export const PATH_SLIDESHOW = '/slideshow/:photoId';
