import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COLOR_PRIMARY, COLORS_ON } from 'app/common/style/colors';

import colorPalettes from 'app/common/typography/colors/palettes';
import { DEFAULT_COLOR_PALETTE } from 'app/common/typography/colors/palettes/default';

import Text from '../text';

import { FONT_AVENIR } from '../fonts';
import { SIZE_L } from '../sizes';

import styles from './headText4.css';

/**
 * HeadText4 typography.
 *
 * @example ./headText4.md
 */
export default function HeadText4({ on, children, palette, interactive, disabled, className }) {
  const props = {
    on,
    palette,
    disabled,
    interactive,
    className: classNames(className, styles.headText4),
    font: FONT_AVENIR,
    size: SIZE_L,
  };

  return <Text {...props}>{children}</Text>;
}

HeadText4.propTypes = {
  palette: PropTypes.oneOf(Object.keys(colorPalettes)),
  on: PropTypes.oneOf(COLORS_ON),
  children: PropTypes.node.isRequired,
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

HeadText4.defaultProps = {
  palette: DEFAULT_COLOR_PALETTE,
  on: COLOR_PRIMARY,
  interactive: null,
  disabled: false,
  className: null,
};
