import UserNotification from 'domain/userNotifications';
import StoreTabs from 'domain/store/storeTabs';

export enum UserNotificationFilterCategory {
  ALL = 'ALL',
  PHOTO = 'PHOTO',
  SURVEY = 'SURVEY',
  GUIDELINE = 'GUIDELINE',
  DOCUMENT = 'DOCUMENT',
}

type UserNotificationProcessedData = {
  notifications: UserNotification[];
  unreadCount: number;
  isTabEnabled: boolean;
};

export type ProcessedUserNotifications = Record<UserNotificationFilterCategory, UserNotificationProcessedData>;

const createNotificationData = (isTabEnabled: boolean): UserNotificationProcessedData => ({
  notifications: [],
  unreadCount: 0,
  isTabEnabled,
});

const createDefaultData = (storeTabs?: StoreTabs): ProcessedUserNotifications => ({
  [UserNotificationFilterCategory.ALL]: createNotificationData(true),
  [UserNotificationFilterCategory.PHOTO]: createNotificationData(!!storeTabs?.photosTabEnabled),
  [UserNotificationFilterCategory.SURVEY]: createNotificationData(!!storeTabs?.surveysTabEnabled),
  [UserNotificationFilterCategory.GUIDELINE]: createNotificationData(!!storeTabs?.guidelinesTabEnabled),
  [UserNotificationFilterCategory.DOCUMENT]: createNotificationData(!!storeTabs?.documentsTabEnabled),
});

export default function processNotificationsByCategory(
  notifications?: UserNotification[],
  storeTabs?: StoreTabs
): ProcessedUserNotifications {
  const defaultData = createDefaultData(storeTabs);

  if (!notifications || !storeTabs) return defaultData;

  return notifications.reduce((acc, notification) => {
    const { category } = notification;

    if (acc[category]?.isTabEnabled) {
      const isRead = notification.isRead();
      acc[UserNotificationFilterCategory.ALL].notifications.push(notification);
      acc[category].notifications.push(notification);

      if (!isRead) {
        acc[UserNotificationFilterCategory.ALL].unreadCount++;
        acc[category].unreadCount++;
      }
    }

    return acc;
  }, defaultData);
}
