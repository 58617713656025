import { CATALOG_LIST_MODE_DETAILS } from 'app/display/common/ui/constants';

export const CATALOG_LIST_MODE_CHANGE = 'CATALOG_LIST_MODE_CHANGE';
export const CATALOG_ITEM_OPEN_SECTION = 'CATALOG_ITEM_OPEN_SECTION';
export const CATALOG_ITEM_CLOSE_SECTION = 'CATALOG_ITEM_CLOSE_SECTION';
export const CATALOG_ITEM_SET_SECTIONS_STATUS = 'CATALOG_ITEM_SET_SECTIONS_STATUS';
export const CATALOG_ITEM_LOAD_SECTIONS_STATUS = 'CATALOG_ITEM_LOAD_SECTIONS_STATUS';

export const SECTIONS_STATUS_KEY = 'v5.ui.catalog.collapse.status';
export const SECTION_OPENED = 'opened';
export const SECTION_CLOSED = 'closed';
export const CATALOG_LIST_ADDMENU_TOGGLE = 'CATALOG_LIST_ADDMENU_TOGGLE';

export const BREADCRUMB_TOGGLE = 'v5.ui.catalog.breadcrumb.toggle';
export const BREADCRUMB_OPEN = 'v5.ui.catalog.breadcrumb.open';
export const BREADCRUMB_CLOSE = 'v5.ui.catalog.breadcrumb.close';

export const initialState = {
  list: {
    mode: CATALOG_LIST_MODE_DETAILS,
  },
  collapse: {
    status: {},
  },
  breadcrumb: {
    isOpen: false,
  },
  sidebar: {
    isExpanded: false,
  },
};

export default function reducer(state = initialState, action) {
  let status = { ...state.collapse.status };
  switch (action.type) {
    case CATALOG_LIST_MODE_CHANGE:
      return {
        ...state,
        list: {
          ...state.list,
          mode: action.mode,
        },
      };
    case CATALOG_ITEM_CLOSE_SECTION:
      if (action.section != null) {
        status[action.section] = SECTION_CLOSED;
      }
      return {
        ...state,
        collapse: {
          status: { ...status },
        },
      };
    case CATALOG_ITEM_OPEN_SECTION:
      if (action.section != null) {
        status[action.section] = SECTION_OPENED;
      }
      return {
        ...state,
        collapse: {
          status: { ...status },
        },
      };
    case CATALOG_ITEM_SET_SECTIONS_STATUS:
      if (action.sections != null) {
        status = action.sections;
      }
      return {
        ...state,
        collapse: {
          status: { ...status },
        },
      };
    case CATALOG_LIST_ADDMENU_TOGGLE:
      return {
        ...state,
        list: {
          ...state.list,
          isOpen: action.isOpen,
        },
      };
    case BREADCRUMB_TOGGLE:
      return {
        ...state,
        breadcrumb: {
          isOpen: !state.breadcrumb.isOpen,
        },
      };
    case BREADCRUMB_OPEN:
      return {
        ...state,
        breadcrumb: {
          isOpen: true,
        },
      };
    case BREADCRUMB_CLOSE:
      return {
        ...state,
        breadcrumb: {
          isOpen: false,
        },
      };
    default:
      return state;
  }
}
