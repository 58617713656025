import React, { useEffect } from 'react';
import Spinner from 'app/common/components/spinner';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import Rupt from 'rupt';
import { PATH_LOGOUT } from 'config/routePaths';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';

export default function Detach() {
  const { currentUser } = useCurrentUser();
  const logoutUrl = useParamSelector(getAbsoluteUrl, PATH_LOGOUT);

  const onDetach = () => {
    window.location.href = logoutUrl;
  };

  const detachTimout = setTimeout(() => onDetach(), 2000);

  useEffect(() => {
    Rupt.detach({
      client_id: process.env.RUPT_CLIENT_ID || '',
      account: currentUser?.email || '',
      device: window.localStorage.getItem('ruptDeviceId') || '',
    }).then(() => {
      clearTimeout(detachTimout);
      onDetach();
    });
  }, []);

  return <Spinner />;
}
