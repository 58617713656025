/**
 * Class decorator - Makes the given properties enumerable (eg. the ones implemented with accessors)
 */
export default function enumerableProperties(...propertyKeys: string[]) {
  return <T extends { new (...args: any[]): any }>(constructor: T): any => {
    return new Proxy(constructor, {
      construct(target, args) {
        const instance = Reflect.construct(target, args);

        propertyKeys.forEach(propertyKey => {
          Object.defineProperty(instance, propertyKey, {
            ...Object.getOwnPropertyDescriptor(target.prototype, propertyKey),
            enumerable: true,
          });
        });

        return instance;
      },
    });
  };
}
