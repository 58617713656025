export const CREATE_ITEM_START = 'catalog.create.item.start';
export const CREATE_ITEM_SUCCESS = 'catalog.create.item.success';
export const CREATE_ITEM_ERROR = 'catalog.create.item.error';

export const DUPLICATE_ITEM_SUCCESS = 'catalog.duplicate.item.success';
export const DUPLICATE_ITEMS_SUCCESS = 'catalog.duplicate.items.success';

export const UPDATE_ITEM_START = 'catalog.update.item.start';
export const UPDATE_ITEM_SUCCESS = 'catalog.update.item.success';
export const UPDATE_ITEM_ERROR = 'catalog.update.item.error';

export const UPDATE_ITEM_FILES_START = 'catalog.update.item.files.start';
export const UPDATE_ITEM_FILES_SUCCESS = 'catalog.update.item.files.success';
export const UPDATE_ITEM_FILES_ERROR = 'catalog.update.item.files.error';

export const UPDATE_ITEM_MARKETS_START = 'catalog.update.item.markets.start';
export const UPDATE_ITEM_MARKETS_SUCCESS = 'catalog.update.item.markets.success';
export const UPDATE_ITEM_MARKETS_ERROR = 'catalog.update.item.markets.error';

export const UPDATE_ITEM_DISPLAY_START = 'catalog.update.item.display.start';
export const UPDATE_ITEM_DISPLAY_SUCCESS = 'catalog.update.item.display.success';
export const UPDATE_ITEM_DISPLAY_ERROR = 'catalog.update.item.display.error';

export const EDIT_ITEM_BY_FORM_VALUES_START = 'catalog.edit.item.byFormValues.start';
export const EDIT_ITEM_BY_FORM_VALUES_SUCCESS = 'catalog.edit.item.byFormValues.success';
export const EDIT_ITEM_BY_FORM_VALUES_ERROR = 'catalog.edit.item.byFormValues.error';

export const CREATE_ITEM_BY_FORM_VALUES_START = 'catalog.create.item.byFormValues.start';
export const CREATE_ITEM_BY_FORM_VALUES_SUCCESS = 'catalog.create.item.byFormValues.success';
export const CREATE_ITEM_BY_FORM_VALUES_ERROR = 'catalog.create.item.byFormValues.error';

export const FETCH_ITEM_TYPES_START = 'catalog.fetch.item.types.start';
export const FETCH_ITEM_TYPES_SUCCESS = 'catalog.fetch.item.types.success';
export const FETCH_ITEM_TYPES_ERROR = 'catalog.fetch.item.types.error';

export const CLEAR_ITEMS = 'catalog.action.items.clear';

export const PATCH_ITEM = 'catalog.action.patch.item';
export const PATCH_ITEMS = 'catalog.action.patch.items';
export const SET_CURRENT_ITEM = 'catalog.action.set.current.item';
