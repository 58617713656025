import React from 'react';
import { ERROR_COLOR_PALETTE, LegendText1 } from 'app/common/typography';
import { Trans, useTranslation } from 'react-i18next';
import WarningIcon from 'assets/icons/warning.svg';
import styles from './index.css';

export const LICENSE_FIELD_NO_LICENSES_AVAILABLE_TESTID = 'licenseFieldNoLicensesAvailable';

type UnavailableLicensesProp = {
  licensesList?: string[];
};

export default function UnavailableLicenses({ licensesList }: UnavailableLicensesProp) {
  const { t } = useTranslation();
  if (!licensesList || !licensesList.length) {
    return null;
  }
  const [lastLicense, ...restLicenses] = licensesList.reverse();
  return (
    <div className={styles.fieldWrapper} data-testid={LICENSE_FIELD_NO_LICENSES_AVAILABLE_TESTID}>
      <div className={styles.unavailableLicenses}>
        <WarningIcon className={styles.icon} />
        <LegendText1 palette={ERROR_COLOR_PALETTE}>
          <span className={styles.fontWeight}>
            <Trans
              t={t}
              components={{ bold: <strong /> }}
              values={{ elements: restLicenses.join(', '), lastElement: lastLicense }}
            >
              {t('directory.userSheet.licenseTab.license.warningUnavailableLicenses', { count: licensesList.length })}
            </Trans>
          </span>
        </LegendText1>
      </div>
    </div>
  );
}
