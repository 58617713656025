import array2map from 'lib/helpers/array2map';

export const FETCH = 'USERS_FETCH';
export const FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const FETCH_ERROR = 'USERS_FETCH_ERROR';

export const initialState = {
  isLoading: false,
  list: {},
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUCCESS: {
      const usersMap = array2map(action.list, 'id');
      return {
        ...state,
        isLoading: false,
        list: {
          ...state.list,
          ...usersMap,
        },
      };
    }
    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
