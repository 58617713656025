import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import { AppState } from 'lib/providers/store';

type ReduxDispatch = ThunkDispatch<AppState, any, AnyAction>;

export default function useAppDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
