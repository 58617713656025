import React, { Suspense } from 'react';
import Spinner from 'app/common/components/spinner';

export const DiscoverPage = React.lazy(() => import(/* webpackChunkName: "discover" */ 'app/discover/components/page'));

const DiscoverModule = () => (
  <Suspense fallback={<Spinner />}>
    <DiscoverPage />
  </Suspense>
);

export default DiscoverModule;
