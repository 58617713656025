import React from 'react';
import { useSelector } from 'react-redux';
import Popup from 'app/common/components/popup/actions';
import useAction from 'app/display/common/hooks/useAction';
import { toggleDirectoryActionsPopup } from 'app/directory/user/sheet/common/actions';
import isDirectoryActionsPopupVisible from 'app/directory/user/sheet/common/selectors/isActionsPopupVisible';
import { useTranslation } from 'react-i18next';
import DirectoryEditUserAction from 'app/directory/user/sheet/view/components/popupActions/actions/editUser';
import DirectoryTransferContentAction from 'app/directory/user/sheet/view/components/popupActions/actions/transferContent';
import DirectoryDuplicateUserAction from 'app/directory/user/sheet/view/components/popupActions/actions/duplicateUser';
import DirectoryTransferContentPlatformAction from 'app/directory/user/sheet/view/components/popupActions/actions/transferContentPlatform';
import useIsPlatform from 'app/common/hooks/useIsPlatform';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import styles from './index.css';

type DirectoryPopupActionsProps = {
  modifyUrl: string;
  transferContentUrl: string;
  canDuplicateUser: boolean;
  canEditUser: boolean;
  canTransferContent: boolean;
  userId: string;
};

export default function DirectoryPopupActions({
  modifyUrl,
  transferContentUrl,
  canDuplicateUser,
  canTransferContent,
  canEditUser,
  userId,
}: DirectoryPopupActionsProps) {
  const { t } = useTranslation();
  const onClose = useAction(toggleDirectoryActionsPopup);
  const isVisible = useSelector(isDirectoryActionsPopupVisible);
  const { isPlatform } = useIsPlatform();
  const { aclRight: canTransferContentPlatform } = useCurrentUserAclRight('admin.directory.transferContent');
  const viewDirectoryTransferAction = canTransferContent && !isPlatform;
  const viewDirectoryTransferPlatformAction = canTransferContentPlatform && isPlatform;

  return (
    <Popup
      title={t('directory.actionsPopup.title')}
      onClose={onClose}
      visible={isVisible}
      onOutOfBoundsClick={() => {
        onClose();
      }}
      className={styles.actionsPopup}
    >
      {canEditUser && <DirectoryEditUserAction to={modifyUrl} />}
      <div className={styles.separator}>
        {canDuplicateUser && <DirectoryDuplicateUserAction />}
        {viewDirectoryTransferAction && <DirectoryTransferContentAction transferContentUrl={transferContentUrl} />}
        {viewDirectoryTransferPlatformAction && <DirectoryTransferContentPlatformAction userId={userId} />}
      </div>
    </Popup>
  );
}
