import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { COLOR_PRIMARY, COLOR_SECONDARY } from 'app/common/style/colors';

import colorPalettes from 'app/common/typography/colors/palettes';
import paletteClassName from 'app/common/typography/colors/palettes/helpers/getClassName';
import { DEFAULT_COLOR_PALETTE } from 'app/common/typography/colors/palettes/default';

import styles from './style.css';

export default function HoverArea({ className, on, children, palette }) {
  const { hover: hoverColor, active: activeColor } = paletteClassName(colorPalettes[palette]);

  return (
    <div className={classNames(className, styles.hoverArea, styles[hoverColor(on)], styles[activeColor(on)])}>
      {children}
    </div>
  );
}

HoverArea.propTypes = {
  className: PropTypes.string,
  on: PropTypes.oneOf([COLOR_PRIMARY, COLOR_SECONDARY]),
  palette: PropTypes.oneOf(Object.keys(colorPalettes)),
  children: PropTypes.node.isRequired,
};

HoverArea.defaultProps = {
  on: COLOR_PRIMARY,
  className: null,
  palette: DEFAULT_COLOR_PALETTE,
};
