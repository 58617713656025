import React from 'react';
import PropTypes from 'prop-types';
import { HeadText3 } from 'app/common/typography';

import styles from './title.css';

const Title = ({ title }) => <HeadText3 className={styles.uppercase}>{title}</HeadText3>;

Title.propTypes = {
  title: PropTypes.node.isRequired,
};

export default Title;
