import useLicenseProvisioningData from 'app/directory/user/sheet/common/hooks/useLicenseProvisioningData';
import { LicenseProvisioningData } from 'domain/license/provisioning';
import useCurrentFormGetFieldValue from 'app/display/common/hooks/form/useCurrentFormGetFieldValue';

type ResultLoading = {
  isLoading: true;
  projectSelected: boolean;
  licenseProvisioningData: null;
};

type ResultSuccess = {
  isLoading: false;
  projectSelected: boolean;
  licenseProvisioningData?: LicenseProvisioningData[] | null;
};

type Result = ResultLoading | ResultSuccess;

export default function useLicenseProvisioningDataBySelectedProject(): Result {
  const projectId = useCurrentFormGetFieldValue('license-projectId');
  const { licenseProvisioningData, isLoading } = useLicenseProvisioningData();
  const projectSelected = !!projectId;

  if (isLoading) {
    return { isLoading: true, licenseProvisioningData: null, projectSelected };
  }

  if (!licenseProvisioningData) {
    return { isLoading, licenseProvisioningData: null, projectSelected };
  }

  return {
    licenseProvisioningData: licenseProvisioningData?.filter(
      (provisioningData: LicenseProvisioningData) => provisioningData.projectId === projectId
    ),
    isLoading,
    projectSelected,
  };
}
