import React from 'react';
import i18next from 'i18next';
import classNames from 'classnames';
import { ALT_COLOR_PALETTE, HeadText5, HeadText6, LINK_COLOR_PALETTE } from 'app/common/typography';
import { SELECT_FOLDER_MODAL_CONFIRM_BUTTON } from 'app/common/constants/userflow';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './buttons.css';

type WizardButtonsProps = {
  onClose: () => void;
  closeEnabled: boolean;
  onConfirm: (args: any) => any;
  confirmEnabled: boolean;
  confirmLabel?: string | null;
  hasConfirm?: boolean;
};

const WizardButtons = ({
  onConfirm,
  onClose,
  confirmEnabled,
  closeEnabled,
  hasConfirm = false,
  confirmLabel = null,
}: WizardButtonsProps) => {
  const closeButtonClassSet = classNames(styles.uppercase, { [styles.defaultCursor]: !closeEnabled });
  const confirmButtonClassSet = classNames(styles.uppercase, { [styles.defaultCursor]: !confirmEnabled });
  return (
    <AnimatePresence initial={false}>
      <div onClick={onClose} key="closeButton">
        <HeadText6
          className={closeButtonClassSet}
          interactive={true}
          palette={ALT_COLOR_PALETTE}
          disabled={!closeEnabled}
        >
          {i18next.t('button.cancel')}
        </HeadText6>
      </div>
      {hasConfirm && (
        <motion.div
          onClick={onConfirm}
          data-testid={SELECT_FOLDER_MODAL_CONFIRM_BUTTON}
          key="confirmButton"
          initial={{ opacity: 0, marginLeft: 0, maxWidth: 0 }}
          animate={{ opacity: 1, marginLeft: '35px', maxWidth: '100px' }}
          exit={{ opacity: 0, marginLeft: 0, maxWidth: 0 }}
        >
          <HeadText5
            className={confirmButtonClassSet}
            interactive={true}
            palette={LINK_COLOR_PALETTE}
            disabled={!confirmEnabled}
          >
            {confirmLabel || i18next.t('button.confirm')}
          </HeadText5>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WizardButtons;
