import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import RequiredSymbol from 'app/common/components/requiredSymbol';
import { BodyText2 } from 'app/common/typography';
import styles from './numeric.css';

class Numeric extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    input: PropTypes.shape(fieldInputPropTypes).isRequired,
    meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    vertical: PropTypes.bool,
    style: PropTypes.string,
    labelClass: PropTypes.string,
    infoClass: PropTypes.string,
    wrapClass: PropTypes.string,
    errorClass: PropTypes.string,
    className: PropTypes.string,
    pattern: PropTypes.instanceOf(RegExp),
    displayError: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: null,
    disabled: false,
    required: false,
    vertical: true,
    label: null,
    style: null,
    labelClass: null,
    infoClass: null,
    wrapClass: null,
    errorClass: null,
    className: null,
    pattern: /^[+-]?([0-9]+(\.[0-9]*?)?)?$/,
    displayError: true,
  };

  constructor(props) {
    super(props);
    this.onChange = this.handleNumberOnChange(props.input.onChange);
  }

  handleNumberOnChange(inputOnchange) {
    return event => {
      const { value } = event.currentTarget;
      if (value === '' || value.match(this.props.pattern)) {
        inputOnchange(value);
      }
    };
  }

  render() {
    const {
      meta: { error, touched, submitting },
      displayError,
    } = this.props;

    const style = this.props.className || this.props.style;

    const wrapperClass = classNames({
      [this.props.wrapClass]: this.props.wrapClass !== null,
    });
    const inputClass = classNames(styles.input, {
      [style]: style !== null,
      [this.props.infoClass]: this.props.infoClass !== null,
      [styles.inputError]: touched && error !== undefined,
      [styles.disabled]: submitting,
    });
    const labelClass = classNames(styles.label, {
      [styles.inputLabel]: this.props.vertical,
      [styles.inputLabelHorizontal]: !this.props.vertical,
      [this.props.labelClass]: this.props.labelClass !== null,
      [this.props.labelClass]: this.props.labelClass !== null,
    });
    const errorClass = classNames(styles.error, {
      [this.props.errorClass]: this.props.errorClass !== null,
    });

    const {
      input,
      id,
      disabled,
      label,
      placeholder,
      required,
      meta: { warning },
    } = this.props;

    input.onChange = this.onChange;

    return (
      <div className={wrapperClass}>
        {label && (
          <label htmlFor={id} className={labelClass}>
            <BodyText2>{label}</BodyText2>
            <RequiredSymbol required={required} />
          </label>
        )}
        <input
          {...input} // contains name, value, onChange...
          className={inputClass}
          id={id}
          type="text"
          placeholder={placeholder}
          disabled={disabled || submitting}
        />
        {displayError &&
          touched &&
          ((error && <span className={errorClass}>{error}</span>) ||
            (warning && <span className={styles.warning}>{warning}</span>))}
      </div>
    );
  }
}

export default Numeric;
