import React from 'react';
import Checkbox from 'app/display/acl/instance/components/selection/checkbox';
import PhotoTag from 'domain/photo/tag';
import { CHECKED, UNCHECKED } from 'app/display/common/components/constants/checkboxStates';
import { BodyText1 } from 'app/common/typography';
import styles from './tag.css';

type PhotoTagProps = {
  photoTag: PhotoTag;
  isSelected: boolean;
  onClick: (actionTag: PhotoTag) => void;
};

export default function Tag({ photoTag, isSelected, onClick }: PhotoTagProps) {
  const handleClick = () => {
    onClick(photoTag);
  };

  return (
    <div className={styles.tag}>
      <Checkbox
        disabled={false}
        onClick={handleClick}
        state={isSelected ? CHECKED : UNCHECKED}
        className={styles.checkbox}
      />
      <BodyText1>{photoTag.tag}</BodyText1>
    </div>
  );
}
