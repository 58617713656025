import React from 'react';
import classNames from 'classnames';

import IWDLogo from 'app/common/components/platformMenu/logo/iwd';
import AppToggleButton from 'app/common/components/appToggleButton';
import useSmallScreen from 'app/common/hooks/useSmallScreen';
import styles from './index.css';

const PlatformLogo = () => {
  const isSmallScreen = useSmallScreen();

  return (
    <div className={classNames(styles.platformLogo, { [styles.onSmallScreen]: isSmallScreen })}>
      <div className={styles.iwd}>
        <IWDLogo className={styles.iwdLogo} />
      </div>
      <div className={styles.bento}>
        <AppToggleButton />
      </div>
    </div>
  );
};

export default PlatformLogo;
