import { useEffect } from 'react';
import Rupt from 'rupt';
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import { PATH_LOGOUT } from 'config/routePaths';
import isCurrentUserUsurped from 'app/auth/token/helpers/isCurrentUserUsurped';
import stringToBoolOrUndefined from 'lib/helpers/casts/stringToBoolOrUndefined';
import useInstanceId from 'app/common/hooks/useInstanceId';
import generatePathname from 'lib/helpers/router/generatePathname';
import { RUPT_NEW_ACCOUNT_URL } from 'app/common/constants/externalUrls';
import useIsIwdMobileApp from 'app/common/hooks/useIsIwdMobileApp';

export const RUPT_DEVICE_ID_KEY = 'ruptDeviceId';

export default function useAttachRupt() {
  const { currentUser } = useCurrentUser({ cacheTime: 5 * 60 * 1000 });
  const instanceId = useInstanceId();
  const logoutUrl = useParamSelector(getAbsoluteUrl, PATH_LOGOUT);
  const isUsurpedUser = isCurrentUserUsurped();
  const isRuptInProductionMode = stringToBoolOrUndefined(process.env.RUPT_PRODUCTION);
  const isIwdMobileApp = useIsIwdMobileApp();

  const newAccountUrl = generatePathname(RUPT_NEW_ACCOUNT_URL, {
    clientInstance: instanceId,
    email: currentUser?.email,
  });

  useEffect(() => {
    const attachRupt = async () => {
      if (
        !isIwdMobileApp &&
        ((isRuptInProductionMode && !currentUser?.isIwd) || !isRuptInProductionMode) &&
        !isUsurpedUser
      ) {
        const ruptReturn = await Rupt.attach({
          client_id: process.env.RUPT_CLIENT_ID || '',
          account: currentUser?.email || '',
          email: currentUser?.email || '',
          metadata: {
            instance: instanceId,
            email: currentUser?.email,
          },
          redirect_urls: {
            logout_url: logoutUrl,
            new_account_url: newAccountUrl,
          },
          groups: [{ id: instanceId, name: instanceId }],
          on_current_device_logout: ({ challenge }) => {
            if (challenge) {
              window.location.href = `${logoutUrl}?challenge=${challenge}`;
              return false;
            }

            window.location.href = `${logoutUrl}?challenge=false`;
            return true;
          },
        });

        if (ruptReturn?.device_id) {
          localStorage.setItem(RUPT_DEVICE_ID_KEY, ruptReturn.device_id);
        }
      }
    };

    if (currentUser) {
      attachRupt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
