import { createAction, createReducer } from '@reduxjs/toolkit';
import { STATUS_ERROR, STATUS_INITIAL, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import FullUser from 'domain/user/fullUser';

const FETCH_CURRENT_USER_START = 'currentuser.fetch.start';
const FETCH_CURRENT_USER_SUCCESS = 'currentuser.fetch.success';
const FETCH_CURRENT_USER_ERROR = 'currentuser.fetch.error';

export const fetchCurrentUserStartAction = createAction(FETCH_CURRENT_USER_START);
export const fetchCurrentUserSuccessAction = createAction(FETCH_CURRENT_USER_SUCCESS, (fullUser = {} as FullUser) => ({
  payload: fullUser,
}));
export const fetchCurrentUserErrorAction = createAction<unknown>(FETCH_CURRENT_USER_ERROR);

export const initialState = {
  status: STATUS_INITIAL,
  data: {} as FullUser,
  error: null as null | unknown,
};

export default createReducer(initialState, builder => {
  builder.addCase(fetchCurrentUserStartAction, draftState => {
    draftState.status = STATUS_LOADING;
  });
  builder.addCase(fetchCurrentUserSuccessAction, (draftState, action) => {
    draftState.status = STATUS_SUCCESS;
    draftState.data = action.payload;
    draftState.error = null;
  });
  builder.addCase(fetchCurrentUserErrorAction, (draftState, action) => {
    draftState.status = STATUS_ERROR;
    draftState.data = {} as FullUser;
    draftState.error = action.payload;
  });
});
