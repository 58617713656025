import useHttpMutation from 'app/common/hooks/useHttpMutation';
import { GuidelineTag } from 'domain/guideline/tag';
import queryClient from 'lib/queryClient';
import { GUIDELINE_TAGS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';

export type UpdateGuidelineTagRequestData = {
  name: string;
};

export default function useUpdateGuidelineTag(id: string) {
  const request = useHttpPutRequest<GuidelineTag[], UpdateGuidelineTagRequestData>(`/tags/${id}`);

  const { mutateAsync } = useHttpMutation(request, {
    onSuccess: data => {
      queryClient.getInstance().setQueryData<GuidelineTag[]>([GUIDELINE_TAGS_CACHE_KEY], data, {});
    },
  });

  return async (value: string) => {
    return mutateAsync({ name: value });
  };
}
