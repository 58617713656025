import WebsocketMessage from 'domain/webSockets/message';
import { QueryClient } from '@tanstack/react-query';
import UserNotificationsWebsocketMessageHandler from 'domain/userNotifications/websocketMessageHandler';
import WebSocketMessageHandlerAbstract from 'domain/webSockets/messageHandler';

export default class WebSocketMessageHandler {
  queryClient: QueryClient;

  messageHandlers: WebSocketMessageHandlerAbstract[] = [];

  constructor(queryClient: QueryClient) {
    this.queryClient = queryClient;
    this.messageHandlers.push(new UserNotificationsWebsocketMessageHandler(this.queryClient));
  }

  processMessage(event: MessageEvent) {
    this.dispatchMessage(this.deserializeMessage(event.data));
  }

  deserializeMessage(message: string): WebsocketMessage {
    return JSON.parse(message) as WebsocketMessage;
  }

  dispatchMessage(message: WebsocketMessage) {
    this.messageHandlers.forEach(messageHandler => {
      if (messageHandler.supportedMessageTypes.includes(message.type)) {
        messageHandler.processMessage(message);
      }
    });
  }
}
