import { PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import queryClient from 'lib/queryClient';
import { SlideImage } from 'yet-another-react-lightbox';

export default function useUpdatePhotoCaption(selectedPhotoId: string | null | undefined) {
  const request = useHttpPutRequest<SlideImage>(`/stores/photos/${selectedPhotoId}/caption`);
  const onSuccess = () => {
    return queryClient.getInstance().invalidateQueries([PHOTOS_CACHE_KEY]);
  };

  const { mutateAsync } = useHttpMutation(request, { onSuccess });

  return function updatePhotoCaption(value?: string) {
    return mutateAsync({
      caption: value,
    });
  };
}
