import { createReducer } from '@reduxjs/toolkit';
import { IwdConfig, loadAction, loadErrorAction, loadSuccessAction } from 'app/common/actions/iwdConfig';

export const initialState: {
  isLoading: boolean;
  config: IwdConfig | null;
} = {
  isLoading: false,
  config: null,
};

export default createReducer(initialState, builder => {
  builder
    .addCase(loadAction, draftState => {
      draftState.isLoading = true;
    })
    .addCase(loadSuccessAction, (draftState, action) => {
      draftState.isLoading = false;
      draftState.config = action.payload.config;
    })
    .addCase(loadErrorAction, draftState => {
      draftState.isLoading = false;
    });
});
