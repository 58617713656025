import Guideline from 'domain/guideline';
import GuidelineFactory from 'domain/guideline/factory';
import { GUIDELINE_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery, { UseHttpQueryOptions, UseHttpQueryResult } from 'app/common/hooks/useHttpQuery';

type UseGuidelineOptions = Pick<UseHttpQueryOptions, 'retry' | 'enabled' | 'throwOnUnhandledError'>;

type UseGuidelineResult = Pick<
  UseHttpQueryResult<Guideline | undefined>,
  'isLoading' | 'isError' | 'error' | 'refetch'
> & {
  guideline: Guideline | undefined;
};

export default function useGuideline(guidelineId: string, options: UseGuidelineOptions = {}): UseGuidelineResult {
  const getGuideline = useHttpGetRequest<Guideline>(`guidelines/${guidelineId}`, {
    transformResponse: GuidelineFactory.createFromApiPayload,
  });

  const {
    data: guideline,
    isLoading,
    isError,
    error,
    refetch,
  } = useHttpQuery([GUIDELINE_QUERY_CACHE_KEY, guidelineId], getGuideline, options);

  return { guideline, isLoading, isError, error, refetch };
}
