import { query } from 'app/common/actions/api';
import setReportUser from 'lib/bugsnag/helpers/setReportUser';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from 'lib/providers/store';
import { AnyAction } from 'redux';
import FullUserFactory from 'domain/user/factory/fullUser';
import {
  fetchCurrentUserErrorAction,
  fetchCurrentUserStartAction,
  fetchCurrentUserSuccessAction,
} from 'app/common/user/reducers/currentUser';
import validateProfileUser from 'app/common/helpers/validateProfileUser';
import { FullUserPayload } from 'domain/user/fullUserPayload';

export default function fetchCurrentUser() {
  return async (dispatch: ThunkDispatch<AppState, any, AnyAction>) => {
    try {
      dispatch(fetchCurrentUserStartAction());
      const user = await dispatch(query<FullUserPayload>('/privates/me'));

      dispatch(fetchCurrentUserSuccessAction(FullUserFactory.createFromApiPayload(user)));
      validateProfileUser(user.profile);
      setReportUser(user);
    } catch (error) {
      assertIsNotCriticalHttpException(error);
      dispatch(fetchCurrentUserErrorAction(error));
    }
  };
}
