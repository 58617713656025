import React from 'react';
import classNames from 'classnames';

import CatalogLink from 'app/common/components/platformMenu/links/catalog';
import GuidelinesLink from 'app/common/components/platformMenu/links/guidelines';
import StoresLink from 'app/common/components/platformMenu/links/stores';
import LibraryLink from 'app/common/components/platformMenu/links/library';
import SurveysLink from 'app/common/components/platformMenu/links/surveys';
import ReportLink from 'app/common/components/platformMenu/links/report';
import DocumentsLink from 'app/common/components/platformMenu/links/documents';

import useSmallScreen from 'app/common/hooks/useSmallScreen';
import styles from './index.css';

export default function PlatformLinks() {
  const isSmallScreen = useSmallScreen();

  return (
    <div className={classNames(styles.links, { [styles.onSmallScreen]: isSmallScreen })}>
      <CatalogLink />
      <GuidelinesLink />
      <StoresLink />
      <LibraryLink />
      <SurveysLink />
      <ReportLink />
      <DocumentsLink />
    </div>
  );
}
