import React from 'react';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import { PATH_ASSETS_LEGACY } from 'config/routePaths';
import IconAssets from 'assets/icons/assets.svg';
import { BodyText2 } from 'app/common/typography';
import useCurrentUserAssetsAccess from 'app/network/hooks/useCurrentUserAssetsAccess';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import styles from './index.css';

const AssetsLink = () => {
  const baseUrl = useSelector(getInstanceBaseUrl);

  const showAssets = useCurrentUserAssetsAccess();

  if (!showAssets) {
    return null;
  }

  return (
    <UserMenuLink to={`${baseUrl}${PATH_ASSETS_LEGACY}`}>
      <UserMenuIcon IconComponent={IconAssets} />
      <BodyText2 className={styles.uppercase}>{i18next.t('network.userMenu.assets')}</BodyText2>
    </UserMenuLink>
  );
};

export default AssetsLink;
