import React from 'react';
import { addToolbarButton, PluginProps, useLightboxProps, useLightboxState } from 'yet-another-react-lightbox';
import styles from './index.css';

declare module 'yet-another-react-lightbox' {
  interface LightboxProps {
    statusTags?: Record<string, React.FC<{ currentSlide: SlideImage }>>;
  }
}

export const SLIDESHOW_STATUS_TAGS_PLUGIN_ID = 'StatusTags';

function StatusTags() {
  const { currentSlide } = useLightboxState();
  const { statusTags } = useLightboxProps();

  return currentSlide ? (
    <div className={styles.statusTags}>
      {statusTags &&
        Object.keys(statusTags).map(statusTag => (
          <React.Fragment key={statusTag}>{statusTags[statusTag]({ currentSlide })}</React.Fragment>
        ))}
    </div>
  ) : null;
}

export default function StatusTagsPlugin({ augment }: PluginProps) {
  augment(({ toolbar, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, SLIDESHOW_STATUS_TAGS_PLUGIN_ID, <StatusTags />),
      ...restProps,
    };
  });
}
