import React from 'react';

// TODO redesign
const ErrorOverlay = () => (
  <div>
    <p>An error occurred.</p>
    <p>TODO design + lien vers dashboard</p>
  </div>
);

export default ErrorOverlay;
