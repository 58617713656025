import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import getUser from 'app/common/user/selectors/getUser';
import Avatar from 'app/common/user/components/avatar';
import IwdUserAvatar from 'app/common/user/components/iwdUserAvatar';

const AvatarContainer = ({ isIwd, name, url, className }) => {
  if (isIwd) {
    return <IwdUserAvatar className={className} />;
  }

  return <Avatar name={name} url={url} className={className} />;
};

const mapStateToProps = (state, ownProps) => {
  const user = getUser(state, ownProps.userId);
  if (user != null) {
    return {
      isIwd: user.isIwd,
      name: user.name,
      url: user.photoUrl,
      className: ownProps.className,
    };
  }
  return {};
};

AvatarContainer.propTypes = {
  isIwd: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};

AvatarContainer.defaultProps = {
  isIwd: null,
  name: null,
  url: null,
  className: null,
};

export { AvatarContainer };
export default connect(mapStateToProps)(AvatarContainer);
