import GuidelineFile, { GuidelineFileType } from 'domain/guideline/file';
import GuidelineFileCollection from 'domain/guideline/guidelineFileCollection';
import FileStateFactory, { FileStatePayload } from 'domain/guideline/factory/fileState';

export type GuidelineFilePayload = {
  id: string;
  url: string;
  type: GuidelineFileType;
  fileURL: string;
  state: FileStatePayload;
};

export default class GuidelineFileFactory {
  private static createFromApiPayload(filePayload: GuidelineFilePayload): GuidelineFile {
    return new GuidelineFile(
      filePayload.id,
      filePayload.url,
      filePayload.type,
      filePayload.fileURL,
      FileStateFactory.createFromApiPayload(filePayload.state)
    );
  }

  static createCollectionFromApiPayload(filesListPayload: GuidelineFilePayload[]): GuidelineFileCollection {
    return new GuidelineFileCollection(filesListPayload.map(GuidelineFileFactory.createFromApiPayload));
  }
}
