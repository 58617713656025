import React from 'react';
import PropTypes from 'prop-types';
import hasScheme from 'lib/helpers/string/hasScheme';
import LinkStoreOrigin from 'app/common/containers/linkStoreOrigin';
import styles from './index.css';

const UserMenuLink = ({ to, children, newTab, dataTestId }) => {
  if (hasScheme(to)) {
    if (newTab) {
      return (
        <a href={to} className={styles.link} target="_blank" rel="noopener noreferrer" data-testid={dataTestId}>
          {children}
        </a>
      );
    }

    return (
      <a href={to} className={styles.link} data-testid={dataTestId}>
        {children}
      </a>
    );
  }

  return (
    <LinkStoreOrigin to={to} className={styles.link} dataTestId={dataTestId}>
      {children}
    </LinkStoreOrigin>
  );
};

UserMenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
  dataTestId: PropTypes.string,
};

UserMenuLink.defaultProps = {
  newTab: false,
  dataTestId: null,
};

export default UserMenuLink;
