import React from 'react';
import { HeadText5 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import UncheckedNotification from 'assets/icons/close.svg';
import CheckedNotification from 'assets/icons/checkedbox.svg';
import styles from './index.css';

export default function NotificationsActionRow({
  name,
  isActivityReportEnabled,
  isSurveyOwnerActivityReportEnabled,
  children,
}: {
  name: string;
  userId: string;
  isActivityReportEnabled?: boolean;
  isSurveyOwnerActivityReportEnabled?: boolean;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();

  const isChecked =
    (name === 'isActivityReportEnabled' && isActivityReportEnabled) ||
    (name === 'isSurveyOwnerActivityReportEnabled' && isSurveyOwnerActivityReportEnabled);

  return (
    <div className={styles.settingActionRow}>
      {isChecked ? (
        <CheckedNotification className={styles.checked} />
      ) : (
        <UncheckedNotification className={styles.unChecked} />
      )}
      <div className={styles.label}>
        <HeadText5>{t(`directory.userSheet.notificationsTab.setting.${name}.label`)}</HeadText5>
        {children}
      </div>
    </div>
  );
}
