import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useCurrentUserAcl from 'app/common/hooks/useCurrentUserAcl';
import { PROFILE_NETWORK_FREE, PROFILE_NETWORK_POS } from 'app/common/user/helpers/profile/constants';
import get from 'lodash/get';

export default function useIsNetworkSurveyVisible(): boolean {
  const { currentUser } = useCurrentUser();
  const { userAcl } = useCurrentUserAcl();

  const canManageSurveys = get(userAcl, 'legacy.survey.manage', false);
  const canPublishSurveys = get(userAcl, 'legacy.survey.publish', false);
  const canFollowSurveys = get(userAcl, 'legacy.survey.follow', false);

  return (
    !!currentUser?.settings.network.hasNetwork &&
    currentUser?.profile.network !== PROFILE_NETWORK_POS &&
    currentUser?.profile.network !== PROFILE_NETWORK_FREE &&
    (canManageSurveys || canPublishSurveys || canFollowSurveys)
  );
}
