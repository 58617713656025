import userflow from 'userflow.js';
import createSingleton from 'lib/helpers/createSingleton';

class UserflowClient {
  private initialized = false;

  constructor() {
    if (process.env.USERFLOW_TOKEN) {
      userflow.init(process.env.USERFLOW_TOKEN);
      this.initialized = true;
    }
  }

  public isInitialized() {
    return this.initialized;
  }

  public identify(userId: string, attributes: Record<string, any>) {
    userflow.identify(userId, attributes);
  }
}

function createUserflowClient() {
  const handler = {
    get(target: UserflowClient, prop: keyof UserflowClient) {
      if (typeof target[prop] === 'function' && prop !== 'isInitialized' && !target.isInitialized()) {
        return () => {
          // nothing
        };
      }
      return target[prop].bind(target);
    },
  };
  return new Proxy(new UserflowClient(), handler);
}

export default createSingleton(createUserflowClient);
