import { connect } from 'react-redux';

import showIfVisible from 'app/common/hoc/showIfVisible';
import isCurrentUserAllowedToImport from 'app/common/selectors/acl/isCurrentUserAllowedToImport';
import Import from 'app/display/common/components/appShell/header/userMenu/links/import';

const mapStateToProps = state => ({
  isVisible: isCurrentUserAllowedToImport(state),
});

export default connect(mapStateToProps)(showIfVisible(Import));
