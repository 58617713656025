import React from 'react';
import ProjectField from 'app/directory/user/sheet/common/components/form/license/project';
import LicenseField from 'app/directory/user/sheet/common/components/form/license/license';
import CreateField from 'app/directory/user/sheet/common/components/form/license/create';
import ExecuteField from 'app/directory/user/sheet/common/components/form/license/execute';
import RoleError from 'app/directory/user/sheet/common/components/form/license/roleError';
import { HeadText3 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './index.css';

export default function LicenseTabForm() {
  const { t } = useTranslation();
  return (
    <div className={styles.licenseForm}>
      <ProjectField />
      <LicenseField />
      <hr className={styles.divider} />
      <HeadText3 className={styles.rolesLabel}>{t('directory.userSheet.licenseTab.roles.label')}</HeadText3>
      <CreateField />
      <ExecuteField />
      <RoleError />
    </div>
  );
}
