import React from 'react';
import Button from 'app/display/common/components/button';
import RotateIcon from 'app/common/components/slideshow/buttons/rotate';
import SlideshowButtonSeparator from 'app/common/components/slideshow/buttons/separator';
import { useLightboxState } from 'yet-another-react-lightbox';
import SlideshowButton from 'app/common/components/slideshow/buttons/button';
import useShowModal from 'app/display/common/hooks/useShowModal';
import RotateImageModal from 'app/common/components/slideshow/rotate/modal/rotateModal';
import styles from 'app/common/components/slideshow/rotate/icon.css';
import useRotatePhoto from 'app/network/common/hooks/useRotatePhoto';

export const SLIDESHOW_ROTATE_PHOTO__ICON_TESTID = 'slideshowRotateIcon';

export default function SlideshowRotatePhotoIcon() {
  const { currentSlide } = useLightboxState();
  const { isLoading, rotatePhoto } = useRotatePhoto(currentSlide);

  // Show modal only if the photo has drawings
  const showModal = useShowModal(RotateImageModal, { onConfirm: rotatePhoto, isLoading });
  const onClick = currentSlide?.drawingUrl ? showModal : rotatePhoto;

  return (
    <div className={styles.icon}>
      <Button
        className="yarl__button"
        type="button"
        onClick={onClick}
        data-testid={SLIDESHOW_ROTATE_PHOTO__ICON_TESTID}
        isDisabled={isLoading}
      >
        <SlideshowButton Icon={RotateIcon} />
      </Button>
      <SlideshowButtonSeparator className={styles.separator} />
    </div>
  );
}
