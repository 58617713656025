import React, { useEffect } from 'react';
import usePhotoTags from 'app/common/hooks/usePhotoTags';
import PhotoTag from 'domain/photo/tag';
import SearchInput, { SEARCH_INPUT_THEME_ALT } from 'app/display/common/components/searchInput';
import PhotoTagListSkeleton from 'app/common/components/slideshow/tagPhoto/modal/tagList.skeleton';
import TagList from 'app/common/components/slideshow/tagPhoto/modal/tagList';
import { TagPhotoModalProps } from 'app/common/components/slideshow/tagPhoto/modal';
import WizardStepWrapper from 'app/display/common/components/modals/wizard/wrapper';
import { WizardModalStepProps } from 'app/display/common/components/modals/wizard';
import useUpdatePhoto from 'app/common/hooks/useUpdatePhoto';
import PlusMenu from 'app/display/common/components/plusMenu';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import closeModalWithErrorNotificationMessage from 'app/common/actions/modals/closeModalWithErrorNotificationMessage';
import closeModalWithSuccessNotificationMessage from 'app/common/actions/modals/closeModalWithSuccessNotificationMessage';
import areArraysEqual from 'app/common/helpers/areArraysEqual';
import styles from './index.css';

export const ADD_PHOTO_TAG_BLUE_BUTTON = 'add-photo-tag-blue-button';

export default function AddPhotoTag({
  stepData,
  nextStep,
  setConfirmHandlers,
}: WizardModalStepProps<TagPhotoModalProps>) {
  const { searchTerm, photo, setSearchTerm, selectedTags, setSelectedTags, newTags } = stepData;
  const updatePhoto = useUpdatePhoto(photo);
  const { photoTags, isLoading } = usePhotoTags();
  const photoTagsFiltered = photoTags?.filterBySearchTerm(searchTerm);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleTagClick = (actionTag: PhotoTag) => {
    const isAddTag =
      !selectedTags.includes(actionTag.fullTagName) &&
      actionTag.fullTagName.toLocaleLowerCase().search(searchTerm.toLocaleLowerCase()) >= 0;
    if (isAddTag) {
      setSelectedTags([...selectedTags, actionTag.fullTagName]);
    } else {
      setSelectedTags(selectedTags.filter(tag => actionTag.fullTagName !== tag));
    }
  };
  const handleChangeSearchTerm = (value: string) => {
    setSearchTerm(value);
  };

  const onConfirmError = () => {
    dispatch(closeModalWithErrorNotificationMessage(t('slideshow.tagPhoto.failed')));
  };

  const onConfirmSuccess = () => {
    dispatch(closeModalWithSuccessNotificationMessage(t('slideshow.tagPhoto.success')));
  };

  const actions = [
    {
      label: i18next.t('slideshow.tagPhoto.label.add'),
      onClick: nextStep,
      dataTestId: ADD_PHOTO_TAG_BLUE_BUTTON,
    },
  ];

  useEffect(() => {
    setConfirmHandlers({
      confirmEnabled: !areArraysEqual(photo.tags, selectedTags),
      onConfirm: () => updatePhoto({ tags: selectedTags }),
      onConfirmSuccess,
      onConfirmError,
    });
  }, [photo.tags.length, selectedTags, selectedTags.length, setConfirmHandlers]);

  return (
    <WizardStepWrapper wizardKey="addPhotoTag">
      <div className={styles.search}>
        <SearchInput
          onChange={handleChangeSearchTerm}
          onClear={() => handleChangeSearchTerm('')}
          value={searchTerm}
          placeholder={t('slideshow.tagPhoto.search')}
          theme={SEARCH_INPUT_THEME_ALT}
        />
      </div>
      {isLoading || !photoTagsFiltered ? (
        <PhotoTagListSkeleton />
      ) : (
        <div className={styles.tagList}>
          <TagList
            onTagClick={handleTagClick}
            tagsByCategory={photoTagsFiltered.groupByCategory()}
            selectedTags={selectedTags}
            newTags={newTags}
          />
          <div className={styles.addButton}>
            <PlusMenu actions={actions} dataTestId={ADD_PHOTO_TAG_BLUE_BUTTON} />
          </div>
        </div>
      )}
    </WizardStepWrapper>
  );
}
