import React, { Suspense, createRef, RefObject } from 'react';
import { createRoot, Root } from 'react-dom/client';
import NETWORK_LEGACY_COMPONENTS from 'app/network/common/constants/legacyComponents';
import AppStoreProvider from 'lib/providers/store';
import AppQueryClientProvider from 'lib/providers/queryClient';
import AppI18nextProvider from 'lib/providers/i18next';
import uuid from 'uuid/v4';
import AppRouterProvider from 'lib/providers/router';
import WebSocketProvider from 'lib/providers/webSocket';

type LegacyComponentMap = {
  [key: string]: React.FC<React.PropsWithChildren<any>>;
};

const componentMap: LegacyComponentMap = {
  ...NETWORK_LEGACY_COMPONENTS,
};

type ComponentRegistryEntry = {
  component: RefObject<any>;
  domNode: HTMLElement;
  componentRoot: Root;
};

export const componentRegistry = new Map<string, ComponentRegistryEntry>();

function getComponent(componentId: string) {
  return componentMap[componentId] || null;
}

function parseProps(props: string) {
  try {
    return JSON.parse(props);
  } catch (error) {
    return {};
  }
}

function cleanupRegistry() {
  componentRegistry.forEach((componentEntry, componentId) => {
    if (!componentEntry.component?.current?.isConnected) {
      componentEntry.componentRoot.unmount();
      componentRegistry.delete(componentId);
    }
  });
}

function mountComponents() {
  cleanupRegistry();
  const domRoots = document.querySelectorAll('[data-react-component]') as NodeListOf<HTMLElement>;

  domRoots.forEach((domRoot: HTMLElement) => {
    const componentId = domRoot.dataset.reactComponent as string;
    const Component = getComponent(componentId);
    const doesHaveInnerContent = !!domRoot.innerHTML;
    const props = parseProps(domRoot.dataset.reactProps as string);
    const componentRef = createRef<HTMLDivElement>();

    if (Component && !doesHaveInnerContent) {
      const componentRoot = createRoot(domRoot);
      const componentUuid = `${componentId}-${uuid()}`;
      componentRegistry.set(componentUuid, {
        domNode: domRoot,
        componentRoot,
        component: componentRef,
      });

      componentRoot.render(
        <div ref={componentRef}>
          <AppQueryClientProvider>
            <AppStoreProvider>
              <WebSocketProvider>
                <AppI18nextProvider>
                  <AppRouterProvider>
                    <Suspense fallback={null}>
                      <Component {...props} />
                    </Suspense>
                  </AppRouterProvider>
                </AppI18nextProvider>
              </WebSocketProvider>
            </AppStoreProvider>
          </AppQueryClientProvider>
        </div>
      );
    }
  });
}

export default function initLegacyComponents() {
  document.addEventListener('DOMContentLoaded', mountComponents);
  document.addEventListener('fr:iwd:network:Ajax:dom:success:react', mountComponents);
  document.addEventListener('fr:iwd:network:buildSettingsMenu', mountComponents);
}
