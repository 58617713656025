import Collection from 'domain/collection';
import Guideline from 'domain/guideline';

export default class GuidelineCollection extends Collection<Guideline> {
  public areSomeGuidelinesArchived(): boolean {
    return this.some((guideline: Guideline) => guideline.isArchived());
  }

  public areSomeGuidelinesUpdatable(): boolean {
    return this.some((guideline: Guideline) => guideline.isUpdatable());
  }

  public areGuidelinesUpdatable(): boolean {
    return !this.areSomeGuidelinesArchived();
  }

  public isEmptyCollection(): boolean {
    return this.length === 0;
  }

  public hasOnlyOneElement(): boolean {
    return this.length === 1;
  }

  public getIds(): string[] {
    return this.map(guideline => guideline.id);
  }
}
