import React from 'react';
import PlatformMenuLink from 'app/common/components/platformMenu/links/link';
import i18next from 'i18next';
import { PATH_SURVEY, PATH_SURVEY_PUBLISHED } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useCurrentUserSurveyPermissions from 'app/common/hooks/useCurrentUserSurveyPermissions';
import { APPSHELL_SURVEYS } from 'app/common/constants/userflow';

const useSurveysLink = () => {
  const href = useParamSelector(getAbsoluteUrl, PATH_SURVEY_PUBLISHED);
  const isActive = useLocationMatch(PATH_SURVEY, { exact: false });
  const { canAccess, discoveryPagesEnabled } = useCurrentUserSurveyPermissions();

  return {
    href,
    isActive,
    canAccess,
    discoveryPagesEnabled,
  };
};

export default function SurveysLink() {
  const { href, isActive, canAccess, discoveryPagesEnabled } = useSurveysLink();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <PlatformMenuLink isActive={isActive} href={href} data-testid={APPSHELL_SURVEYS} isDisabled={!canAccess}>
      {i18next.t('surveys')}
    </PlatformMenuLink>
  );
}
