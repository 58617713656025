import React from 'react';
import { Field } from 'redux-form';
import Select from 'app/common/components/forms/select';
import formStyles from 'app/directory/user/sheet/common/components/form/license/index.css';
import { useTranslation } from 'react-i18next';
import useCreateField from 'app/directory/user/sheet/common/components/form/license/create/index.hook';
import BlockCheckbox from 'app/display/common/components/input/block/checkbox';
import CreateFieldNoRole from 'app/directory/user/sheet/common/components/form/license/create/noRole';
import styles from './index.css';

export default function CreateField() {
  const { t } = useTranslation();
  const { roles, isDisabled, has3dDisabled, has2dDisabled, onChangeRole } = useCreateField();

  if (!roles || roles.length === 0) {
    return <CreateFieldNoRole />;
  }

  return (
    <div className={styles.createField}>
      <Field
        id="createRole"
        name="createRole"
        component={Select}
        mainLabel={t('directory.userSheet.licenseTab.create.label')}
        wrapClass={formStyles.fieldWrapperSmall}
        labelClass={formStyles.fieldLabel}
        selectStyle={formStyles.fieldInput}
        errorClass={formStyles.selectError}
        disabled={isDisabled}
        onChange={onChangeRole}
      >
        <>
          <option value="norole" />
          {roles?.map(role => (
            <option key={role} value={role}>
              {t(`acl.role.${role}.title`)}
            </option>
          ))}
        </>
      </Field>
      <Field
        id="has2d"
        name="has2d"
        component={BlockCheckbox}
        className={styles.checkbox}
        type="checkbox"
        disabled={has2dDisabled}
      >
        {t('directory.userSheet.licenseTab.2d.label')}
      </Field>
      <Field
        id="has3d"
        name="has3d"
        component={BlockCheckbox}
        className={styles.checkbox}
        type="checkbox"
        disabled={has3dDisabled}
      >
        {t('directory.userSheet.licenseTab.3d.label')}
      </Field>
    </div>
  );
}
