import React from 'react';
import Skeleton from 'app/common/components/skeleton';
import times from 'lodash/times';
import styles from './tagList.css';

export default function PhotoTagListSkeleton() {
  return (
    <>
      {times(2, index => (
        <div className={styles.category} key={`category_${index}`}>
          <div className={styles.categoryName}>
            <Skeleton height={14} width={100} />
          </div>
          <div>
            <Skeleton height={14} width={100} />
          </div>
          <div>
            <Skeleton height={14} width={100} />
          </div>
          <div>
            <Skeleton height={14} width={100} />
          </div>
          <div>
            <Skeleton height={14} width={100} />
          </div>
        </div>
      ))}
    </>
  );
}
