import useHttpQuery, { UseHttpQueryOptions } from 'app/common/hooks/useHttpQuery';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import { GUIDELINE_DATA_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import GuidelineData from 'domain/guideline/data';
import GuidelineDataFactory from 'domain/guideline/data/factory';

type ValidateGuidelineDataQueryResult = {
  guidelineData: GuidelineData | undefined;
  isLoading: boolean;
  isError: boolean;
  error: any;
  refetch: any;
};

type UseGuidelineDataOptions = UseHttpQueryOptions<GuidelineData>;

const useValidateGuidelineData = (
  guidelineId: string,
  options: UseGuidelineDataOptions = {}
): ValidateGuidelineDataQueryResult => {
  const fetchGuidelineData = useHttpGetRequest<GuidelineData>(`/privates/guidelines/${guidelineId}/export`, {
    transformResponse: GuidelineDataFactory.createFromApiPayload,
  });
  const {
    data: guidelineData,
    isLoading,
    isError,
    error,
    refetch,
  } = useHttpQuery([GUIDELINE_DATA_QUERY_CACHE_KEY, guidelineId, null], fetchGuidelineData, options);
  return { guidelineData, isLoading, isError, error, refetch };
};

export default useValidateGuidelineData;
