import React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayUncontrolledCollapsePanel from 'app/display/common/components/collapsePanel/uncontrolled';
import styles from 'app/directory/user/sheet/common/components/form/notifications/index.css';
import NotificationsSettingRow from 'app/directory/user/sheet/common/components/form/notifications/settingRow';

export default function NotificationsTabDocumentSection() {
  const { t } = useTranslation();
  return (
    <DisplayUncontrolledCollapsePanel
      title={t('directory.userSheet.notificationsTab.document.title')}
      classNameContent={styles.panelContent}
    >
      <NotificationsSettingRow name="documentShared" />
    </DisplayUncontrolledCollapsePanel>
  );
}
