import { query } from 'app/common/actions/api';
import download from 'lib/helpers/download';

export default function exportCatalogMarketData() {
  return async dispatch => {
    const response = await dispatch(
      query('/items/markets/export', {
        getHeaders: true,
        responseType: 'text',
        headers: {
          Accept: 'text/csv',
        },
      })
    );
    const fileName = 'catalog-market.csv'; // @todo extract from content-disposition
    const mimeType = response.headers['content-type'];
    download(response.data, fileName, mimeType);
  };
}
