export const CLEAR_SELECTED_ID_LIST = 'guidelines.list.clear.selected';
export const CLEAR_HIGHLIGHTED_ENTRY = 'guidelines.list.clear.highlighted.entry';

export const FETCH_GUIDELINES_START = 'guidelines.list.fetch.start';
export const FETCH_GUIDELINES_ERROR = 'guidelines.list.fetch.error';
export const FETCH_GUIDELINES_SUCCESS = 'guidelines.list.fetch.success';

export const LOAD_GUIDELINES_START = 'guidelines.list.load.start';
export const LOAD_GUIDELINES_ERROR = 'guidelines.list.load.error';
export const LOAD_GUIDELINES_SUCCESS = 'guidelines.list.load.success';

export const REFRESH_GUIDELINES_START = 'guidelines.list.refresh.start';
export const REFRESH_GUIDELINES_ERROR = 'guidelines.list.refresh.error';
export const REFRESH_GUIDELINES_SUCCESS = 'guidelines.list.refresh.success';

export const FETCH_GUIDELINES_GLOBAL_FILTERS_START = 'guideline.search.globalFilters.fetch.start';
export const FETCH_GUIDELINES_GLOBAL_FILTERS_SUCCESS = 'guideline.search.globalFilters.fetch.success';
export const FETCH_GUIDELINES_GLOBAL_FILTERS_ERROR = 'guideline.search.globalFilters.fetch.error';

export const SELECT_GUIDELINE = 'guideline.list.select';
export const DESELECT_GUIDELINE = 'guideline.list.deselect';
export const SELECT_ALL_GUIDELINES = 'guideline.list.selectAll';
export const DESELECT_ALL_GUIDELINES = 'guideline.list.deselectAll';

export const CHANGE_SORT_CRITERION = 'guideline.list.changeSortCriterion';
export const CHANGE_SORT_ORDER = 'guideline.list.changeSortOrder';

export const CREATE_PERSONAL_FOLDER_START = 'guidelines.list.createPersonalFolder.start';
export const CREATE_PERSONAL_FOLDER_SUCCESS = 'guidelines.list.createPersonalFolder.success';
export const CREATE_PERSONAL_FOLDER_ERROR = 'guidelines.list.createPersonalFolder.error';

export const CREATE_GLOBAL_FOLDER_START = 'guidelines.list.createGlobalFolder.start';
export const CREATE_GLOBAL_FOLDER_SUCCESS = 'guidelines.list.createGlobalFolder.success';
export const CREATE_GLOBAL_FOLDER_ERROR = 'guidelines.list.createGlobalFolder.error';

export const TOGGLE_ACTIONS_POPUP_VISIBILITY = 'guidelines.list.toggle.actions.popup.visibility';

export const OPEN_SORT_BY_POPUP = 'guideline.list.sortByPopup.open';
export const CLOSE_SORT_BY_POPUP = 'guideline.list.sortByPopup.close';

export const HIGHLIGHT_ENTRY = 'guideline.list.highlightEntry';
export const UNHIGHLIGHT_ENTRY = 'guideline.list.unhighlightEntry';

export const RENAME_PERSONAL_FOLDER_START = 'guideline.list.renameFolder.start';
export const RENAME_PERSONAL_FOLDER_SUCCESS = 'guideline.list.renameFolder.success';
export const RENAME_PERSONAL_FOLDER_ERROR = 'guideline.list.renameFolder.error';

export const DELETE_PERSONAL_FOLDER_START = 'guideline.list.delete.folder.start';
export const DELETE_PERSONAL_FOLDER_SUCCESS = 'guideline.list.delete.folder.success';
export const DELETE_PERSONAL_FOLDER_ERROR = 'guideline.list.delete.folder.error';

export const RENAME_GLOBAL_FOLDER_START = 'guideline.list.renameGlobalFolder.start';
export const RENAME_GLOBAL_FOLDER_SUCCESS = 'guideline.list.renameGlobalFolder.success';
export const RENAME_GLOBAL_FOLDER_ERROR = 'guideline.list.renameGlobalFolder.error';

export const DELETE_GLOBAL_FOLDER_START = 'guideline.list.delete.globalFolder.start';
export const DELETE_GLOBAL_FOLDER_SUCCESS = 'guideline.list.delete.globalFolder.success';
export const DELETE_GLOBAL_FOLDER_ERROR = 'guideline.list.delete.globalFolder.error';

export const GUIDELINES_LOADING = 'guidelines.list.loading';
export const ADD_GUIDELINES_LIST = 'guideline.list.add.guidelines';

export const SET_GUIDELINES_CAMPAIGN_BY_SEARCH_START = 'guidelines.list.changeCampaign.bySearch.start';
export const SET_GUIDELINES_CAMPAIGN_BY_SEARCH_SUCCESS = 'guidelines.list.changeCampaign.bySearch.success';
export const SET_GUIDELINES_CAMPAIGN_BY_SEARCH_ERROR = 'guidelines.list.changeCampaign.bySearch.error';

export const SET_GUIDELINES_CAMPAIGN_BY_IDS_START = 'guidelines.list.changeCampaign.byIds.start';
export const SET_GUIDELINES_CAMPAIGN_BY_IDS_SUCCESS = 'guidelines.list.changeCampaign.byIds.success';
export const SET_GUIDELINES_CAMPAIGN_BY_IDS_ERROR = 'guidelines.list.changeCampaign.byIds.error';

export const ENABLE_PERSONAL = 'ENABLE_PERSONAL';
export const ENABLE_GLOBAL = 'ENABLE_GLOBAL';
export const TOGGLE_GUIDELINE_BROWSE = 'toggle.guideline.browse';

export const CLEAR_GUIDELINES_BROWSE = 'clear.guideline.browse';
