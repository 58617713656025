import React from 'react';
import i18next from 'i18next';
import { PATH_ACL_INDEX } from 'config/routePaths';
import DisplayLink from 'app/common/components/legacyNavMenu/menu/display/link';
import useLocationMatch from 'app/common/hooks/useLocationMatch';

const useRightsLink = () => {
  const isActive = useLocationMatch(PATH_ACL_INDEX, { exact: false });

  return {
    to: PATH_ACL_INDEX,
    isActive,
  };
};

const RightsLink = () => {
  const { to, isActive } = useRightsLink();

  return (
    <DisplayLink isActive={isActive} to={to}>
      {i18next.t('display.submenu.acl')}
    </DisplayLink>
  );
};

export default RightsLink;
