import React from 'react';
import ConfirmModal from 'app/display/common/components/modals/confirmModal';
import { BodyText1 } from 'app/common/typography';
import PhotoUploaderModalList from 'app/network/common/components/photoUploader/modal/list';
import MultiDeviceDragDropContext from 'app/common/components/multiDeviceDragDropContext';
import usePhotoUploaderModal from 'app/network/common/components/photoUploader/modal/index.hook';
import { FileLoaderFile } from '@iwd/iwd-fileloader';
import prepareFilesForUpload from 'app/network/common/helpers/prepareFilesForUpload';
import styles from './index.css';

type FileUpload = {
  files: File[];
  folderId: string | null;
};

type PhotoUploaderModalProps = {
  files: FileLoaderFile[];
  folderId: string | null;
  folderName: string | null;
  onSubmit(fileUpload: FileUpload): void;
};

export default function PhotoUploaderModal({
  files,
  folderId = null,
  folderName = null,
  onSubmit,
}: PhotoUploaderModalProps) {
  const { onClose, t, photoList, onChange } = usePhotoUploaderModal(files);

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={() => {
        onSubmit({ files: prepareFilesForUpload(photoList), folderId });
        return onClose();
      }}
      title={t('network.legacyComponents.photoUploader.modal.title')}
      confirmLabel={t('network.legacyComponents.photoUploader.modal.confirm')}
    >
      <>
        <BodyText1>
          {t('network.legacyComponents.photoUploader.modal.infoText', { photoCount: files.length, folderName })}
        </BodyText1>
        <MultiDeviceDragDropContext>
          <PhotoUploaderModalList files={photoList} className={styles.list} onChange={onChange} />
        </MultiDeviceDragDropContext>
      </>
    </ConfirmModal>
  );
}
