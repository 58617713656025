import React from 'react';
import i18next from 'i18next';
import { showModal } from 'app/common/actions/modals';
import ExportModal from 'app/display/import/components/modals/export';
import useAction from 'app/display/common/hooks/useAction';
import { useSelector } from 'react-redux';
import isCurrentUserAllowedToExportAnyCsv from 'app/common/selectors/appShell/isCurrentUserAllowedToExportAnyCsv';
import ExportIcon from 'assets/icons/exportCloud.svg';
import { BodyText2 } from 'app/common/typography';
import UserMenuButton from 'app/common/components/appShell/header/userMenu/link/userMenuButton';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import styles from './index.css';

const Export = () => {
  const modalPop = useAction(showModal);
  const isVisible = useSelector(isCurrentUserAllowedToExportAnyCsv);
  const { aclRight: hasNetworkExport } = useCurrentUserAclRight('legacy.admin.access');

  return isVisible || hasNetworkExport ? (
    <UserMenuButton onClick={() => modalPop(ExportModal, { hasNetworkExport })}>
      <UserMenuIcon IconComponent={ExportIcon} />
      <BodyText2 className={styles.uppercase}>{i18next.t('display.user.popup.link.export') as string}</BodyText2>
    </UserMenuButton>
  ) : null;
};

export default Export;
