import React from 'react';
import WarningIcon from 'assets/icons/icons-discussion-warning.svg';
import { BodyText1 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './index.css';

export default function CommentsWarning() {
  const { t } = useTranslation();
  return (
    <div className={styles.warning}>
      <WarningIcon className={styles.icon} />
      <BodyText1 className={styles.text}>{t('slideshow.comments.warning')}</BodyText1>
    </div>
  );
}
