import { connect } from 'react-redux';

import showIfVisible from 'app/common/hoc/showIfVisible';
import isCurrentUserAllowedToAccessDirectory from 'app/common/selectors/acl/isCurrentUserAllowedToAccessDirectory';
import Directory from 'app/display/common/components/appShell/header/userMenu/links/directory';

const mapStateToProps = state => ({
  isVisible: isCurrentUserAllowedToAccessDirectory(state),
});

export default connect(mapStateToProps)(showIfVisible(Directory));
