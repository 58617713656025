import React, { ReactNode, useContext, createContext } from 'react';
import WebSocketsService from 'app/common/services/webSockets';
import { useQueryClient } from '@tanstack/react-query';
import WebSocketClient from 'app/common/services/webSockets/client';

const WebSocketContext = createContext<any>(null);

const WebSocketProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const webSocketServer = process.env.WEBSOCKET_SERVER;
  const webSocketClient = webSocketServer ? WebSocketClient.getInstance(`wss://${webSocketServer}`) : undefined;

  const webSocketService = WebSocketsService.getInstance(queryClient, webSocketClient);

  return <WebSocketContext.Provider value={{ webSocketService }}>{children}</WebSocketContext.Provider>;
};

export const useWebSocketContext = () => useContext(WebSocketContext);
export default WebSocketProvider;
