import React from 'react';
import PropTypes from 'prop-types';

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export default function showIfVisible(WrappedComponent) {
  const Component = ({ isVisible, ...props }) => {
    return isVisible ? <WrappedComponent {...props} /> : null;
  };

  Component.displayName = `ShowIfVisible(${getDisplayName(WrappedComponent)})`;

  Component.propTypes = {
    isVisible: PropTypes.bool.isRequired,
  };

  return Component;
}
