import React from 'react';
import PlatformMenuLink from 'app/common/components/platformMenu/links/link';
import i18next from 'i18next';
import { PATH_GUIDELINE_BROWSE } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import useDisplayVersion from 'app/common/hooks/useDisplayVersion';
import useCurrentUserGuidelinesAccess from 'app/common/hooks/useCurrentUserGuidelinesAccess';
import { APPSHELL_GUIDELINES } from 'app/common/constants/userflow';

const useGuidelineLink = () => {
  const { matchV5 } = useDisplayVersion();
  const href = useParamSelector(getAbsoluteUrl, PATH_GUIDELINE_BROWSE);
  const isActive = useLocationMatch(PATH_GUIDELINE_BROWSE, { exact: false });
  const { canAccess, discoveryPagesEnabled } = useCurrentUserGuidelinesAccess();

  const result = { isActive };

  if (matchV5) {
    result.to = PATH_GUIDELINE_BROWSE;
  } else {
    result.href = href;
  }

  return { canAccess, discoveryPagesEnabled, ...result };
};

export default function GuidelineLink() {
  const { to, href, isActive, canAccess, discoveryPagesEnabled } = useGuidelineLink();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <PlatformMenuLink isActive={isActive} to={to} href={href} data-testid={APPSHELL_GUIDELINES} isDisabled={!canAccess}>
      {i18next.t('display.submenu.guidelines')}
    </PlatformMenuLink>
  );
}
