import React from 'react';
import Tooltip from 'app/common/components/tooltip';
import { HeadText6, TextHoverArea, GUIDELINE_STATUS_COLOR_PALETTE, ERROR_COLOR_PALETTE } from 'app/common/typography';
import { COLOR_PRIMARY } from 'app/common/style/colors';
import UserStatusAnnotationTooltip from 'app/directory/user/common/components/statusAnnotation/statusTooltip';
import { useTranslation } from 'react-i18next';
import UserStatusAnnotationIcon from 'app/directory/user/common/components/statusAnnotation/statusIcon';
import useUser from 'app/common/hooks/useUser';
import { UserStatus } from 'domain/user/fullUser';
import classNames from 'classnames';
import UserStatusAnnotationSkeleton from 'app/directory/user/common/components/statusAnnotation/index.skeleton';
import LastUpdate from 'app/display/common/components/lastUpdate';
import noop from 'lib/helpers/noop';
import { Provider } from 'react-redux';
import store from 'lib/store';
import DateFormatter from 'lib/date/dateFormatter';
import styles from './index.css';

type UserStatusAnnotationProps = {
  isLoading?: boolean;
  userId: string;
  onClick?: () => void;
};

export const USER_STATUS_ANNOTATION_TEST_ID = 'userStatusAnnotation';
export const USER_STATUS_ANNOTATION_CONTENT_TEST_ID = 'userStatusAnnotationContent';

export default function UserStatusAnnotation({ userId, onClick = noop, isLoading = false }: UserStatusAnnotationProps) {
  const { user, isLoading: isLoadingUser } = useUser(userId);
  const { t } = useTranslation();

  const palette = user?.status === UserStatus.ACTIVATED ? GUIDELINE_STATUS_COLOR_PALETTE : ERROR_COLOR_PALETTE;

  const annotationClassName = classNames(styles.annotation, {
    [styles.hasOnClick]: onClick !== noop,
  });

  const iconClassName = classNames(styles.icon, {
    [styles.deactivated]: user?.status === UserStatus.DEACTIVATED,
  });
  const modifierId = user?.state.modifierId ? user?.state.modifierId : null;
  const modificationDate = user?.state.modificationDate ? user.state.modificationDate : new Date();
  const modificationDateAsString = DateFormatter.formatForApiPayload(modificationDate);
  const storeInstance = store.getInstance();

  return isLoading || isLoadingUser ? (
    <UserStatusAnnotationSkeleton />
  ) : (
    <div data-testid={USER_STATUS_ANNOTATION_TEST_ID}>
      <Tooltip
        arrow={true}
        title="User Status Tooltip"
        style={{ display: 'flex', pointerEvents: 'auto' }}
        html={
          <Provider store={storeInstance}>
            <UserStatusAnnotationTooltip
              userStatus={user?.status}
              title={t(`directory.userSheet.userStatus.${user?.status}`)}
              tooltip={t(`directory.userSheet.userStatus.${user?.status}.tooltip`)}
            />
            {modifierId && (
              <LastUpdate userId={modifierId} date={modificationDateAsString} className={styles.modifierLastUpdate} />
            )}
          </Provider>
        }
      >
        <TextHoverArea on={COLOR_PRIMARY} palette={palette}>
          <div className={annotationClassName} data-testid={USER_STATUS_ANNOTATION_CONTENT_TEST_ID} onClick={onClick}>
            <HeadText6 palette={palette} className={styles.text}>
              <UserStatusAnnotationIcon className={iconClassName} userStatus={user?.status} />
              {t(`directory.userSheet.userStatus.${user?.status}`)}
            </HeadText6>
          </div>
        </TextHoverArea>
      </Tooltip>
    </div>
  );
}
