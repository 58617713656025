import PhotoTagPayload from 'domain/photo/factory/tagPayload';
import PhotoTag from 'domain/photo/tag';
import PhotoTagCollection from 'domain/photo/tagCollection';

export default class PhotoTagFactory {
  static createFromApiPayload(payload: PhotoTagPayload): PhotoTag {
    return new PhotoTag(payload.name);
  }

  static createCollectionFromApiPayload(payload: PhotoTagPayload[]): PhotoTagCollection {
    return new PhotoTagCollection(payload.map(PhotoTagFactory.createFromApiPayload));
  }
}
