import React from 'react';
import Photo from 'app/common/components/slideshow/filmStrip/image';
import { SlideImage } from 'yet-another-react-lightbox';

const Slide = ({ slides }: { slides: SlideImage[] }) => {
  return (
    <>
      {slides.map((slide: SlideImage, index) => {
        return <Photo key={slide.id} slide={slide} slideIndex={index} />;
      })}
    </>
  );
};

export default Slide;
