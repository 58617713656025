import DateFormatter from 'lib/date/dateFormatter';
import { StatePayloadElement } from 'domain/state/factory';
import deprecated, { getDeprecatedRelation } from 'lib/deprecated';

export default class State {
  archived = false;

  constructor(
    public readonly creatorId: string,
    public readonly modifierId: string,
    public readonly creationDate: Date = new Date(),
    public readonly modificationDate: Date = new Date(),
    public enabled: boolean = false
  ) {}

  /**
   * @deprecated use `creatorId` and `creationDate` instead.
   */
  @deprecated()
  get created(): StatePayloadElement {
    return {
      user: getDeprecatedRelation(this.creatorId),
      date: DateFormatter.formatForApiPayload(this.creationDate),
    };
  }

  /**
   * @deprecated use `modifier` and `creationDate` instead.
   */
  @deprecated()
  get updated(): StatePayloadElement {
    return {
      user: getDeprecatedRelation(this.modifierId),
      date: DateFormatter.formatForApiPayload(this.modificationDate),
    };
  }
}
