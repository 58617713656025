import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import validateAccessControlRight from 'app/common/propTypes/accessControl/right';
import AuthenticatedRoute from 'app/common/components/route/authenticated';

const AccessControl = ({
  right,
  redirectTo,
  children,
  loadingFallback,
  notAuthorizedFallback,
  isLoading,
  isAuthorized,
  redirectDelay,
}) => {
  const { aclRight, isLoading: aclIsLoading } = useCurrentUserAclRight(right);

  if (aclIsLoading || isLoading) {
    return loadingFallback;
  }

  if (!aclRight && !isAuthorized) {
    if (redirectTo) {
      return (
        <Switch>
          <AuthenticatedRoute isAuthorized={false} redirectTo={redirectTo} redirectDelay={redirectDelay} />
        </Switch>
      );
    }

    return notAuthorizedFallback;
  }

  return children;
};

AccessControl.propTypes = {
  // eslint-disable-next-line react/require-default-props
  right: validateAccessControlRight,
  children: PropTypes.node,
  redirectTo: PropTypes.string,
  loadingFallback: PropTypes.node,
  notAuthorizedFallback: PropTypes.node,
  isLoading: PropTypes.bool,
  isAuthorized: PropTypes.bool,
  redirectDelay: PropTypes.number,
};

AccessControl.defaultProps = {
  redirectTo: null,
  entity: null,
  loadingFallback: null,
  notAuthorizedFallback: null,
  isLoading: false,
  isAuthorized: false,
};

export default AccessControl;
