import React from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { HeadText2, LegendText2, ALT_COLOR_PALETTE, LINK_COLOR_PALETTE } from 'app/common/typography';
import styles from './index.css';

const ErrorLayout = ({ header, title, hint }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.head}>{header}</div>
      <div className={styles.body}>
        <HeadText2 className={classNames(styles.uppercase, styles.title)}>{title}</HeadText2>
        <div className={styles.hint}>{hint}</div>
        <HeadText2 className={classNames(styles.uppercase, styles.support)} palette={LINK_COLOR_PALETTE}>
          {i18next.t('display.error.support')}
        </HeadText2>
      </div>
      <div className={styles.footer}>
        <LegendText2 palette={ALT_COLOR_PALETTE}>{i18next.t('display.error.footer.line1')}</LegendText2>
        <LegendText2 palette={ALT_COLOR_PALETTE}>{i18next.t('display.error.footer.line2')}</LegendText2>
      </div>
    </div>
  );
};

ErrorLayout.propTypes = {
  header: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  hint: PropTypes.node.isRequired,
};

export default ErrorLayout;
