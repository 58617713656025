import compareStringUsingNaturalOrder from 'lib/helpers/sort';
import { AvailableActivity } from 'app/display/import/hooks/useAvailableActivities';

const escapeString = (s: string) => {
  return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export default function filterAvailableActivitiesBySearchTerm(
  availableActivities: AvailableActivity[] | undefined,
  searchTerm: string
) {
  if (availableActivities && searchTerm) {
    const reg = new RegExp(escapeString(searchTerm), 'gi');
    return availableActivities
      .filter(availableActivity => availableActivity.description.search(reg) >= 0)
      .sort((a, b) => compareStringUsingNaturalOrder(a.description, b.description));
  }
  return availableActivities?.sort((a, b) => compareStringUsingNaturalOrder(a.description, b.description));
}
