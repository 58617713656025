import React from 'react';
import PropTypes from 'prop-types';
import { LAST_UPDATE_COLOR_PALETTE, LegendText2 } from 'app/common/typography';
import { COLOR_PRIMARY } from 'app/common/style/colors';
import getUser from 'app/common/user/selectors/getUser';
import useParamSelector from 'app/display/common/hooks/useParamSelector';

const Username = ({ userId }) => {
  const user = useParamSelector(getUser, userId);

  if (!user) {
    return null;
  }
  const userName = user.getNameHidingIwd();

  return (
    <LegendText2 palette={LAST_UPDATE_COLOR_PALETTE} on={COLOR_PRIMARY}>
      {userName}
    </LegendText2>
  );
};

Username.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default Username;
