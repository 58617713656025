import React from 'react';
import PropTypes from 'prop-types';
import urlToObject from 'lib/helpers/router/urlToObject';
import { Link } from 'react-router-dom';

/**
 * This container handle the extends Link to save an origin in the location object
 * The origin will be used to go back to the last saved route
 */
const LinkStoreOrigin = ({ to, origin, children, dataTestId, ...restProps }) => {
  let props = {};
  if (typeof to === 'string') {
    props = { ...urlToObject(to), origin };
  } else if (Object(to) === to) {
    props = { ...to, origin };
  }

  return (
    <Link to={props} {...restProps} data-testid={dataTestId}>
      {children}
    </Link>
  );
};

LinkStoreOrigin.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]).isRequired,
  origin: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
};

LinkStoreOrigin.defaultProps = {
  dataTestId: null,
};

export default LinkStoreOrigin;
