import useCurrentUser from 'app/common/hooks/useCurrentUser';
import { useSelector } from 'react-redux';
import getIwdConfig from 'app/common/selectors/iwdConfig/getIwdConfig';

export default function useCurrentUserAssetsAccess(): boolean {
  const { currentUser } = useCurrentUser();
  const iwdConfig = useSelector(getIwdConfig);

  return (
    !iwdConfig?.isAsp &&
    !!currentUser?.isIwd &&
    (currentUser.settings.editors.has2d || currentUser.settings.editors.has3d)
  );
}
