import BasicError from 'lib/errors';

class HTTPError extends BasicError {
  constructor(message, httpResponse) {
    super(message);
    this.response = httpResponse;
  }
}

export default HTTPError;
