import PropTypes from 'prop-types';
import { DROP_TYPE_FOLDER, DROP_TYPE_PHOTO } from 'app/display/common/constants/dragDrop';

export const folderDropPayload = PropTypes.shape({
  type: PropTypes.oneOf([DROP_TYPE_FOLDER]).isRequired,
  droppedFolderId: PropTypes.string.isRequired,
});

export const genericDropPayload = PropTypes.shape({
  type: PropTypes.oneOf([DROP_TYPE_PHOTO]).isRequired,
  droppedElementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export default PropTypes.oneOfType([folderDropPayload, genericDropPayload]);
