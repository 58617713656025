import React from 'react';
import PropTypes from 'prop-types';
import { COLOR_PRIMARY, COLORS_ON } from 'app/common/style/colors';

import colorPalettes from 'app/common/typography/colors/palettes';
import { DEFAULT_COLOR_PALETTE } from 'app/common/typography/colors/palettes/default';

import { FONTS } from '../fonts';
import { SIZES } from '../sizes';

import StaticText from './static';
import InteractiveText from './interactive';

export default function Text({ font, size, palette, on, children, interactive, disabled, className, dataTestId }) {
  const props = {
    on,
    palette,
    disabled,
    interactive,
    font,
    size,
    className,
    dataTestId,
  };

  if (interactive === true) {
    return <InteractiveText {...props}>{children}</InteractiveText>;
  }

  return <StaticText {...props}>{children}</StaticText>;
}

Text.propTypes = {
  font: PropTypes.oneOf(FONTS).isRequired,
  size: PropTypes.oneOf(SIZES).isRequired,
  palette: PropTypes.oneOf(Object.keys(colorPalettes)),
  on: PropTypes.oneOf(COLORS_ON),
  children: PropTypes.node.isRequired,
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

Text.defaultProps = {
  palette: DEFAULT_COLOR_PALETTE,
  on: COLOR_PRIMARY,
  interactive: null,
  disabled: false,
  className: null,
  dataTestId: null,
};
