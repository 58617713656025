import {
  FETCH_UPLOADED_FILES_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  DELETE_FILES_SUCCESS,
  IMPORT_RESET,
  FETCH_UPLOADED_FILES_IS_LOCKED_SUCCESS,
} from 'app/display/import/actions';

export const initialState = {
  csv: [],
  image: [],
  '3dasset': [],
  isLocked: false,
};

function groupFilesByType(files, state) {
  return files.reduce((currentState, file) => {
    return {
      ...currentState,
      [file.type]: [...currentState[file.type], file.name],
    };
  }, state);
}

function addFile(uploadedFile, state) {
  const { type, name } = uploadedFile;
  if (state[type].includes(name)) {
    return state;
  }
  const newState = { ...state };
  newState[type] = [...newState[type], name];

  return newState;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UPLOADED_FILES_SUCCESS:
      return { ...groupFilesByType(action.files, state), isLocked: action.isLocked };
    case FETCH_UPLOADED_FILES_IS_LOCKED_SUCCESS:
      return { ...state, isLocked: action.isLocked };
    case UPLOAD_FILE_SUCCESS: {
      return addFile(action.file, state);
    }
    case DELETE_FILES_SUCCESS:
    case IMPORT_RESET:
      return { ...initialState };
    default:
      return state;
  }
}
