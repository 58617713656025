import React from 'react';
import { BodyText1, LegendText4 } from 'app/common/typography';
import parseMessageContent from 'app/display/common/helpers/modals/userNotification/parseMessageContent';
import UserNotification from 'domain/userNotifications';
import moment from 'moment/moment';
import classNames from 'classnames';
import styles from 'app/display/common/components/modals/userNotification/content/notificationContent.css';

const UserNotificationContent = ({ notification }: { notification: UserNotification }) => {
  const momentDate = moment(notification.state.created.date);
  const result = parseMessageContent(notification.getContent());

  return (
    <div className={styles.content}>
      <BodyText1>
        {result.map(el => {
          return (
            <span className={classNames({ [styles.isBold]: el.isBold })} key={el.text}>
              {el.text}
            </span>
          );
        })}
      </BodyText1>
      <LegendText4 className={styles.date}>{momentDate.fromNow()}</LegendText4>
    </div>
  );
};

export default UserNotificationContent;
