import React from 'react';
import classNames from 'classnames';
import PhotoUploaderModalPhoto from 'app/network/common/components/photoUploader/modal/photo';

import { FileLoaderFile } from '@iwd/iwd-fileloader';
import styles from './list.css';

type PhotoUploaderModalListProps = {
  files: FileLoaderFile[];
  className?: string | null;
  onChange(fileMovedIndex: number, newLocationIndex: number): void;
};

export default function PhotoUploaderModalList({ files, className = null, onChange }: PhotoUploaderModalListProps) {
  return (
    <div className={classNames(className, styles.list)}>
      {files.map((file, index) => (
        <PhotoUploaderModalPhoto file={file} fileIndex={index} key={file.name} onChange={onChange} />
      ))}
    </div>
  );
}
