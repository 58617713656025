import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Input from 'app/common/components/forms/input';
import styles from './form.css';

export class LoginForm extends PureComponent {
  static propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: undefined,
  };

  renderError() {
    return <div className={styles.error}>{this.props.error}</div>;
  }

  render() {
    const { error, handleSubmit, onSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && this.renderError()}
        <Field
          id="email"
          name="email"
          component={Input}
          label={i18next.t('common.email')}
          type="email"
          placeholder={i18next.t('common.email')}
          style={styles.inputLogin}
          required
        />
        <Field
          id="password"
          name="password"
          component={Input}
          label={i18next.t('common.password')}
          type="password"
          placeholder={i18next.t('common.password')}
          style={styles.inputLogin}
          required
        />
        <div className={styles.centeredRow}>
          <button id="btn-submit" className={styles.submitButton} type="submit">
            {i18next.t('common.login')}
          </button>
        </div>
      </form>
    );
  }
}

export default LoginForm;
