import React, { FC } from 'react';
import { HeadText5 } from 'app/common/typography';
import classNames from 'classnames';
import styles from './button.css';

interface IconProps {
  className?: any;
}

export default function SlideshowButton({
  Icon,
  isActive = false,
  className,
  dataTestid,
}: {
  Icon: FC<IconProps>;
  isActive?: boolean;
  className?: string;
  dataTestid?: string;
}) {
  const classNameSet = classNames(
    styles.button,
    {
      [styles.isActive]: isActive && !className,
    },
    isActive && className
  );

  return (
    <HeadText5 interactive>
      <div data-testid={dataTestid}>
        <Icon className={classNameSet} />
      </div>
    </HeadText5>
  );
}
