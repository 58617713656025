export enum categoryPaletteColor {
  ALT_COLOR_PALETTE = 'alt-color-palette',
  DEFAULT_COLOR_PALETTE = 'default-color-palette',
  BRAND_COLOR_PALETTE = 'brand-color-palette',
}

export enum brandPaletteColor {
  BRAND_COLOR_PALETTE = 'brand-color-palette',
  DEFAULT_COLOR_PALETTE = 'default-color-palette',
}

export enum onColor {
  COLOR_PRIMARY = 'color-primary',
  COLOR_SECONDARY = 'color-secondary',
}
