import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';
import {
  UPDATE_ITEM_MARKETS_SUCCESS,
  UPDATE_ITEM_MARKETS_ERROR,
  UPDATE_ITEM_SUCCESS,
  PATCH_ITEM,
  PATCH_ITEMS,
} from 'app/display/catalog/common/actions/items';
import {
  FETCH_ITEM_MARKETDATA_ERROR,
  FETCH_ITEM_MARKETDATA_START,
  FETCH_ITEM_MARKETDATA_SUCCESS,
} from 'app/display/catalog/common/reducers/items';

export const initialState = {
  statusByItemId: {},
  marketsDataByItemId: {},
};

function updateItemMarketsData(state, itemId, itemMarketsData) {
  return {
    ...state,
    marketsDataByItemId: {
      ...state.marketsDataByItemId,
      [itemId]: itemMarketsData,
    },
  };
}

function updateItemMarketsDataByItemMarkets(state, itemId, markets) {
  const itemMarketData = state.marketsDataByItemId[itemId];
  if (!itemMarketData) {
    return state;
  }

  let updatedItemMarketData = itemMarketData;
  return itemMarketData.reduce((newState, market, index) => {
    const available = markets.some(({ id }) => id === market.id);
    if (market.available === available) {
      return newState;
    }
    updatedItemMarketData = [...updatedItemMarketData];
    updatedItemMarketData[index] = { ...market, available };
    return updateItemMarketsData(newState, itemId, updatedItemMarketData);
  }, state);
}

function updateItemMarketsDataByItemPatch(state, itemId, patch) {
  if (!patch.markets) {
    return state;
  }
  return updateItemMarketsDataByItemMarkets(state, itemId, patch.markets);
}

function updateItemMarketsDataByItemPatches(state, itemIds, patches) {
  return itemIds.reduce(
    (newState, itemId, index) => updateItemMarketsDataByItemPatch(newState, itemId, patches[index]),
    state
  );
}

function updateItemStatus(state, itemId, status) {
  return {
    ...state,
    statusByItemId: {
      ...state.statusByItemId,
      [itemId]: status,
    },
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ITEM_MARKETDATA_START:
      return updateItemStatus(state, action.itemId, STATUS_LOADING);
    case FETCH_ITEM_MARKETDATA_ERROR:
      console.error('Failed to fetch the item markets data', action.error);
      return updateItemStatus(state, action.itemId, STATUS_ERROR);
    case FETCH_ITEM_MARKETDATA_SUCCESS: {
      let newState = state;
      newState = updateItemMarketsData(newState, action.itemId, action.marketData);
      newState = updateItemStatus(newState, action.itemId, STATUS_SUCCESS);
      return newState;
    }
    case UPDATE_ITEM_MARKETS_SUCCESS:
      return updateItemMarketsData(state, action.itemId, action.markets);
    case UPDATE_ITEM_MARKETS_ERROR:
      console.error('Failed to properly update the item markets data', action.error);
      return state;
    case UPDATE_ITEM_SUCCESS:
      return updateItemMarketsDataByItemMarkets(state, action.item.id, action.item.markets);
    case PATCH_ITEM:
      return updateItemMarketsDataByItemPatch(state, action.id, action.patch);
    case PATCH_ITEMS:
      return updateItemMarketsDataByItemPatches(state, action.itemIds, action.patches);
    default:
      return state;
  }
}
