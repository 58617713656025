import React from 'react';
import styles from 'app/directory/user/sheet/common/components/form/license/index.css';
import { BodyText2, BodyText1 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';

export const LICENSE_FIELD_NO_PROJECT_TESTID = 'licenseFieldNoProject';

export default function LicenseFieldNoProjectSelected() {
  const { t } = useTranslation();

  return (
    <div className={styles.fieldWrapper} data-testid={LICENSE_FIELD_NO_PROJECT_TESTID}>
      <div className={styles.fieldLabel}>
        <BodyText2>{t('directory.userSheet.licenseTab.license.label')}</BodyText2>
      </div>
      <div className={styles.fieldInput}>
        <BodyText1>{t('directory.userSheet.licenseTab.license.noProject')}</BodyText1>
      </div>
    </div>
  );
}
