import UserNotification, { UserNotificationTypes } from 'domain/userNotifications';
import redirectTo from 'app/common/helpers/redirect/redirectTo';
import guidelineDocumentPath from 'app/common/helpers/redirect/path/guidelineDocumentPath';
import storeSurveyAnswerPath from 'app/common/helpers/redirect/path/storeSurveyAnswerPath';
import surveyReportPath from 'app/common/helpers/redirect/path/surveyReportPath';
import slideshowPagePath from 'app/common/helpers/redirect/path/slideshowPagePath';

export default function redirectToNotificationPage(notification: UserNotification, absoluteUrl: string) {
  let redirectPath: string | null = null;
  switch (notification.type) {
    case UserNotificationTypes.GUIDELINE_NEW_AVAILABLE:
      redirectPath = absoluteUrl + guidelineDocumentPath(notification.subjectId);
      break;
    case UserNotificationTypes.DOCUMENT_NEW_AVAILABLE:
      if (notification.context.fileUrl) {
        redirectPath = notification.context.fileUrl;
      }
      break;
    case UserNotificationTypes.SURVEY_NEW_PUBLICATION:
      redirectPath = absoluteUrl + surveyReportPath(notification.subjectId);
      break;
    case UserNotificationTypes.SURVEY_NEW_AVAILABLE:
    case UserNotificationTypes.SURVEY_REMINDER_COMPLETION:
      if (notification.context.storeIdV4 && notification.context.surveyAnswerIdV4) {
        redirectPath =
          absoluteUrl + storeSurveyAnswerPath(notification.context.storeIdV4, notification.context.surveyAnswerIdV4);
      }
      break;
    case UserNotificationTypes.PHOTO_APPROVE:
    case UserNotificationTypes.PHOTO_APPROVAL_REMOVE:
    case UserNotificationTypes.PHOTO_BEST_PRACTICE:
    case UserNotificationTypes.PHOTO_BEST_PRACTICE_REMOVE:
      redirectPath = absoluteUrl + slideshowPagePath(notification.subjectId);
      break;
    default:
      redirectPath = null;
  }

  return () => {
    if (redirectPath) {
      redirectTo(redirectPath);
    }
  };
}
