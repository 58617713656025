import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NetworkSVG from 'assets/images/network.svg';

import styles from './index.css';

export default function IconNetwork({ className }) {
  return <NetworkSVG className={classNames(styles.icon, styles.network, className)} />;
}

IconNetwork.propTypes = {
  className: PropTypes.string,
};

IconNetwork.defaultProps = {
  className: null,
};
