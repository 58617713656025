import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import noop from 'lib/helpers/noop';
import styles from 'app/common/components/collapsePanel/index.css';

class ControlledCollapsePanel extends PureComponent {
  static propTypes = {
    renderHeader: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node,
    classNameContent: PropTypes.string,
  };

  static defaultProps = {
    isOpen: true,
    onClick: noop,
    children: undefined,
    classNameContent: null,
  };

  handleClick = () => {
    this.props.onClick(this.props.isOpen);
  };

  render() {
    const { renderHeader, children, isOpen, classNameContent } = this.props;
    const classSet = classNames(styles.collapsePanel, {
      [styles.isOpen]: isOpen,
    });
    const contentClassSet = classNames(styles.content, classNameContent, {
      [styles.isOpen]: isOpen,
    });
    return (
      <div className={classSet}>
        <div className={styles.headerWrapper} onClick={this.handleClick}>
          {renderHeader(isOpen)}
        </div>
        <div className={styles.bodyWrapper}>
          <div className={contentClassSet}>{children}</div>
        </div>
      </div>
    );
  }
}

export default ControlledCollapsePanel;
