import { ITEM_TYPES_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';

const useItemTypes = (options = {}) => {
  const fetchItemTypes = useHttpGetRequest(`items/types`);
  const { data, isLoading, isError, error } = useHttpQuery([ITEM_TYPES_CACHE_KEY], fetchItemTypes, options);

  return { itemTypes: data, isLoading, isError, error };
};

export default useItemTypes;
