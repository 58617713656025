import React from 'react';
import i18next from 'i18next';
import styles from './unexpected.css';

const UnexpectedError = () => (
  <div className={styles.unexpectedError}>
    <div className={styles.content}>
      <h2 className={styles.oops}>{i18next.t('unexpectedError.oops')}</h2>
      <h1 className={styles.title}>{i18next.t('unexpectedError.title')}</h1>
      <p className={styles.subtitle}>{i18next.t('unexpectedError.message')}</p>
      <p className={styles.greetings}>{i18next.t('unexpectedError.greetings')}</p>
      <p className={styles.support}>{i18next.t('unexpectedError.support')}</p>
    </div>
  </div>
);

export default UnexpectedError;
