import { useEffect, useState } from 'react';

/**
 * Toggle a value when *Ctrl + keyParam* key combination is used
 * @param keyParam as 'a', 'b', 'c' ... pressed keyboard
 */
export default function useKeydownCtrlAndKeyParam(keyParam: string) {
  const [onOff, setOnOff] = useState(false);
  useEffect(() => {
    const toggle = () => setOnOff(old => !old);
    window.addEventListener('keydown', e => {
      if (e.key === keyParam && e.ctrlKey) {
        toggle();
      }
    });
    return () => window.removeEventListener('keydown', toggle);
  }, [setOnOff]);

  return onOff;
}
