import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COLOR_PRIMARY, COLORS_ON } from 'app/common/style/colors';

import colorPalettes from 'app/common/typography/colors/palettes';
import { DEFAULT_COLOR_PALETTE } from 'app/common/typography/colors/palettes/default';

import Text from '../text';

import { FONT_AVENIR } from '../fonts';
import { SIZE_L } from '../sizes';

import styles from './bodyText3.css';

/**
 * BodyText3 typography.
 *
 * @example ./bodyText3.md
 */
export default function BodyText3({ palette, on, children, interactive, disabled, className }) {
  const props = {
    on,
    palette,
    disabled,
    interactive,
    className: classNames(className, styles.bodyText3),
    font: FONT_AVENIR,
    size: SIZE_L,
  };

  return <Text {...props}>{children}</Text>;
}

BodyText3.propTypes = {
  palette: PropTypes.oneOf(Object.keys(colorPalettes)),
  on: PropTypes.oneOf(COLORS_ON),
  children: PropTypes.node.isRequired,
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

BodyText3.defaultProps = {
  palette: DEFAULT_COLOR_PALETTE,
  on: COLOR_PRIMARY,
  interactive: false,
  disabled: false,
  className: null,
};
