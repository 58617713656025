import React, { useEffect, useState } from 'react';
import { REGISTER_PHOTOS, UPDATE_PHOTOS } from 'app/network/common/constants/legacyEvents';
import Slideshow from 'app/common/components/slideshow';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import { useDispatch, useSelector } from 'react-redux';
import isSlideShowOpen from 'app/common/selectors/slideshow/isSlideshowOpen';
import { displaySelectedPhotoId, toggleSlideshow } from 'app/common/actions/slideshow';
import getSelectedId from 'app/common/selectors/getSelectedId';
import queryClient from 'lib/queryClient';
import { PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import usePhotosAndDrawings from 'app/network/common/hooks/usePhotosAndDrawings';

export default function LegacySlideshow({ photoIds }: { photoIds: string[] }) {
  const dispatch = useDispatch();
  const [registeredPhotoIds, setRegisteredPhotoIds] = useState<string[]>(photoIds);
  const { photosAndDrawings: photos, isLoading } = usePhotosAndDrawings(registeredPhotoIds);
  const isSlideshowOpen = useSelector(isSlideShowOpen);
  const removePhotoLegacy = useLegacyEvent('REMOVE_PHOTO');
  const selectedPhotoId = useSelector(getSelectedId);

  const handleCloseSlideshow = () => {
    dispatch(toggleSlideshow());
  };

  const handleDeletePhoto = (photoId?: string) => {
    removePhotoLegacy({ photoId });

    if (registeredPhotoIds.length > 0) {
      const currentPhotoIndex = photos?.findIndex(photo => photo.id === photoId) || 0;
      if (currentPhotoIndex >= 0 && currentPhotoIndex + 1 < photos.length) {
        dispatch(displaySelectedPhotoId(photos[currentPhotoIndex + 1].id));
      } else {
        dispatch(displaySelectedPhotoId(photos[currentPhotoIndex - 1].id));
      }
    } else {
      handleCloseSlideshow();
    }

    setRegisteredPhotoIds(registeredPhotoIds.filter(photo => photo !== photoId));
  };

  useEffect(() => {
    const handleRegisterPhotos = (event: CustomEvent) => {
      setRegisteredPhotoIds(event.detail.photoIds);
    };

    const handleUpdatePhotos = (event: CustomEvent<{ photoIds: string[] }>) => {
      const queryClientInstance = queryClient.getInstance();

      Promise.all(
        event.detail.photoIds.map(photoId =>
          queryClientInstance.invalidateQueries({
            queryKey: [PHOTOS_CACHE_KEY, photoId],
          })
        )
      );
    };

    document.addEventListener(REGISTER_PHOTOS, handleRegisterPhotos);
    document.addEventListener(UPDATE_PHOTOS, handleUpdatePhotos);

    return () => {
      document.removeEventListener(REGISTER_PHOTOS, handleRegisterPhotos);
      document.removeEventListener(UPDATE_PHOTOS, handleUpdatePhotos);
    };
  }, [isSlideshowOpen, dispatch]);

  return (
    <Slideshow
      onClose={handleCloseSlideshow}
      isOpen={isSlideshowOpen}
      index={photos?.findIndex(photo => photo.id === selectedPhotoId) || 0}
      photos={photos}
      onDeletePhoto={handleDeletePhoto}
      isLoading={isLoading}
    />
  );
}
