import { useSelector } from 'react-redux';
import getIwdConfig from 'app/common/selectors/iwdConfig/getIwdConfig';
import useCurrentUser from 'app/common/hooks/useCurrentUser';

export default function useIsNetworkStoresVisible(): boolean {
  const iwdConfig = useSelector(getIwdConfig);
  const { currentUser } = useCurrentUser();

  return !iwdConfig.isAsp && !!currentUser?.settings.network.hasNetwork;
}
