import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import getCurrentUser from 'app/common/user/selectors/getCurrentUser';
import Avatar from 'app/common/user/components/avatar';

const CurrentUserAvatar = ({ className }) => {
  const user = useSelector(getCurrentUser);
  return <Avatar name={user.name} url={user.photo?.url} className={className} />;
};

CurrentUserAvatar.propTypes = {
  className: PropTypes.string,
};

CurrentUserAvatar.defaultProps = {
  className: null,
};

export default CurrentUserAvatar;
