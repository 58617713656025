import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import noop from 'lib/helpers/noop';
import useDragZone from 'app/display/common/hooks/useDragZone';
import dragPayloadPropTypes from 'app/display/common/propTypes/dragPayload';
import styles from './dragZone.css';

const DragZone = ({ dragPayload, canDrag, beginDrag, endDrag, onDrop, hideDefaultPreview, children }) => {
  const { isDragged, drag, showPlaceholder } = useDragZone(
    dragPayload,
    canDrag,
    beginDrag,
    endDrag,
    onDrop,
    hideDefaultPreview
  );

  const mainClassNames = classNames(
    'dragZone',
    { isDragged },
    {
      [styles.hidden]: showPlaceholder,
    }
  );

  const placeholderClassNames = classNames('dragZone', { isDragged });

  return (
    <>
      <div className={mainClassNames} ref={drag}>
        {children}
      </div>
      {showPlaceholder && <div className={placeholderClassNames}>{children}</div>}
    </>
  );
};

DragZone.propTypes = {
  children: PropTypes.node.isRequired,
  dragPayload: dragPayloadPropTypes.isRequired,
  onDrop: PropTypes.func.isRequired,
  canDrag: PropTypes.bool,
  beginDrag: PropTypes.func,
  endDrag: PropTypes.func,
  hideDefaultPreview: PropTypes.bool,
};

DragZone.defaultProps = {
  canDrag: true,
  beginDrag: noop,
  endDrag: noop,
  hideDefaultPreview: false,
};

export default DragZone;
