/**
 * Used to handle catch in dynamic import.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';

import log from 'lib/helpers/logger';
import ErrorOverlay from 'app/common/components/errors/errorOverlay';

export default error => {
  log.error(error);
  const container = document.getElementById('popup-layer');
  const root = createRoot(container);
  root.render(<ErrorOverlay />);
};
