import { useParams } from 'react-router-dom';
import useGuideline from 'app/display/guideline/common/hooks/useGuideline';

type UseCurrentGuidelineResult = ReturnType<typeof useGuideline> & { guidelineId?: string };

export default function useCurrentGuideline(options = {}): UseCurrentGuidelineResult {
  const { guidelineId: id } = useParams<{ guidelineId: string }>();
  const result = useGuideline(id, {
    ...options,
    enabled: id != null,
  });
  return { ...result, guidelineId: id };
}
