import React from 'react';
import useReadUserNotification from 'app/display/common/hooks/modals/userNotifications/useReadUserNotification';
import UserNotification from 'domain/userNotifications';
import UserNotificationThumbnail from 'app/display/common/components/modals/userNotification/content/notificationThumbnail';
import UserNotificationContent from 'app/display/common/components/modals/userNotification/content/notificationContent';
import UserNotificationReadDate from 'app/display/common/components/modals/userNotification/content/notificationReadDate';
import styles from 'app/display/common/components/modals/userNotification/content/notificationRow.css';

const UserNotificationRow = ({ notification }: { notification: UserNotification }) => {
  const onClick = useReadUserNotification(notification);
  return (
    <div className={styles.notificationRow} onClick={onClick}>
      <UserNotificationThumbnail notification={notification} />
      <UserNotificationContent notification={notification} />
      <UserNotificationReadDate isRead={notification.isRead()} />
    </div>
  );
};

export default UserNotificationRow;
