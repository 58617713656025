import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import NotFound from 'app/common/components/errors/notFound';
import DisplayRoute from 'app/display/common/components/route/display';
import { PATH_SETTINGS, PATH_SETTINGS_PRESET_VARIANT, PATH_SETTINGS_PRESET_VARIANT_EDIT } from 'config/routePaths';
import Settings from 'app/display/settings/mainPage';
import PresetVariantSettings from 'app/display/settings/sheet/create';
import isCurrentUserAllowedToAccessSettings from 'app/common/selectors/acl/isCurrentUserAllowedToAccessSettings';
import PresetVariantEdit from 'app/display/settings/sheet/edit';

const SettingsRoutes = () => {
  return (
    <Switch>
      <DisplayRoute
        path={PATH_SETTINGS}
        component={Settings}
        isAuthorized={useSelector(isCurrentUserAllowedToAccessSettings)}
        exact
      />
      <DisplayRoute
        path={PATH_SETTINGS_PRESET_VARIANT}
        component={PresetVariantSettings}
        isAuthorized={useSelector(isCurrentUserAllowedToAccessSettings)}
        exact
      />
      <DisplayRoute
        path={PATH_SETTINGS_PRESET_VARIANT_EDIT}
        component={PresetVariantEdit}
        isAuthorized={useSelector(isCurrentUserAllowedToAccessSettings)}
        exact
      />
      <DisplayRoute component={NotFound} />
    </Switch>
  );
};

export default SettingsRoutes;
