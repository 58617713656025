import React from 'react';
import useReduxForm from 'app/display/common/hooks/useReduxForm';
import { BodyText1, ERROR_COLOR_PALETTE } from 'app/common/typography';
import { useSelector } from 'react-redux';
import { FormErrors, getFormSyncErrors } from 'redux-form';
import { AppState } from 'lib/providers/store';
import { LicenseFormData } from 'domain/license/form';

export default function RoleError() {
  const { form } = useReduxForm();
  const formErrors = useSelector<AppState, FormErrors<LicenseFormData>>(getFormSyncErrors(form));

  if (!formErrors.createRole) {
    return null;
  }

  return <BodyText1 palette={ERROR_COLOR_PALETTE}>{formErrors.createRole}</BodyText1>;
}
