import React from 'react';
import i18next from 'i18next';
import { PATH_GUIDELINE_BROWSE } from 'config/routePaths';
import DisplayLink from 'app/common/components/legacyNavMenu/menu/display/link';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import { APPSHELL_GUIDELINES } from 'app/common/constants/userflow';

const useGuidelineLink = () => {
  const isActive = useLocationMatch(PATH_GUIDELINE_BROWSE, { exact: false });

  return {
    to: PATH_GUIDELINE_BROWSE,
    isActive,
  };
};

const GuidelineLink = () => {
  const { to, isActive } = useGuidelineLink();

  return (
    <DisplayLink isActive={isActive} to={to} data-testid={APPSHELL_GUIDELINES}>
      {i18next.t('display.submenu.guidelines')}
    </DisplayLink>
  );
};

export default GuidelineLink;
