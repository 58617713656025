import React from 'react';
import useFetchAppShellData from 'app/common/hooks/useFetchAppShellData';
import useFetchAppData from 'app/common/hooks/useFetchAppData';
import useUserflow from 'app/common/hooks/useUserflow';
import AppRoutes from 'app/routes';
import AppShell from 'app/common/components/appShell';
import AppLoading from 'app/common/components/loading/app';
import ModalRoot from 'app/common/containers/modals';
import Notifications from 'app/display/common/components/notifications';
import Spinner from 'app/common/components/spinner';
import NonCriticalErrorBoundary from 'app/display/common/components/errorBoundary/nonCritical';

const AppRoot = () => {
  const isAppShellReady = useFetchAppShellData();
  const isAppReady = useFetchAppData();

  useUserflow();

  if (!isAppShellReady) {
    return <AppLoading />;
  }

  return (
    <>
      <AppShell>
        <NonCriticalErrorBoundary>{isAppReady ? <AppRoutes /> : <Spinner />}</NonCriticalErrorBoundary>
      </AppShell>
      <Notifications className="app-notifications" />
      <ModalRoot />
    </>
  );
};

export default AppRoot;
