import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DisplaySVG from 'assets/images/display.svg';

import styles from './index.css';

export default function IconDisplay({ className }) {
  return <DisplaySVG className={classNames(styles.icon, styles.display, className)} />;
}

IconDisplay.propTypes = {
  className: PropTypes.string,
};

IconDisplay.defaultProps = {
  className: null,
};
