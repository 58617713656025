import { PATH_NETWORK_INDEX } from 'config/routePaths';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';

/**
 * Return the URL to the V4 dashboard (DISPLAY or NETWORK) depending of the current URL.
 *
 * We should add something in the future to detect clients that have been migrated to
 * the V5 in order to redirect to the V5 dashboards.
 */
export default function getDashboardUrl(state) {
  const instanceUrl = getInstanceBaseUrl(state);
  const { pathname } = window.location;

  // /retailnetwork is the root of all routes in NETWORK V4
  if (pathname.indexOf(PATH_NETWORK_INDEX) !== -1) {
    return `${instanceUrl}${PATH_NETWORK_INDEX}`;
  }

  return instanceUrl;
}
