import decodeJwtTokenPayload from 'lib/helpers/decodeJwtTokenPayload';

/**
 * Extract from JWT token the expiration time (in sec).
 * Return null if token is not valid
 *
 * @param token
 */
export default function getExpirationTimeFromToken(token: string) {
  const jwtDecoded = decodeJwtTokenPayload(token);
  if (jwtDecoded == null) {
    return null;
  }
  const { exp: expirationInSeconds } = jwtDecoded;
  return expirationInSeconds;
}
