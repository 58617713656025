import React, { useEffect } from 'react';
import classNames from 'classnames';
import PhotoThumbnailIconBar from 'app/network/library/photoThumbnailIconBar';
import PhotoThumbnailImage from 'app/network/library/photoThumbnailImage';
import PhotoThumbnailName from 'app/network/library/photoThumbnailName';
import styles from './photoThumbnail.css';

type PhotoThumbnailProps = {
  url: string;
  posParams: { url: string; name: string };
  uuid: string;
  creationDate: string;
  isCommented?: boolean;
  commentResolved?: boolean;
  isBestPractice?: boolean;
  isFromSurvey?: boolean;
  isValidated?: boolean;
  validationList?: string[];
  mouseovertitle?: string;
  taglist?: string[];
  className?: string;
  extraAttributes?: Record<string, any>;
};

export const LEGACY_PHOTO_THUMBNAIL_TESTID = 'photo-thumbnail';
export default function PhotoThumbnail({
  url,
  posParams,
  extraAttributes,
  creationDate,
  isCommented = false,
  commentResolved = false,
  isBestPractice = false,
  isFromSurvey = false,
  isValidated = false,
  validationList = [],
  mouseovertitle = '',
  taglist = [],
  className = '',
}: PhotoThumbnailProps) {
  const uuid = extraAttributes?.['data-uuid'];
  const idv4 = extraAttributes?.['data-id'];
  const [isSelected, setIsSelected] = React.useState(false);

  useEffect(() => {
    const domEl = document.getElementById(`photo_wrapper_${idv4}`);
    setIsSelected(domEl?.className === 'isSelected');
  }, []);

  return (
    <div
      className={classNames(className, styles.photoWrapper, {
        'is-selected': isSelected,
        bestpractice: isBestPractice,
        validate: isValidated,
        tag: taglist.length > 0,
      })}
      data-testid={LEGACY_PHOTO_THUMBNAIL_TESTID}
      {...extraAttributes}
    >
      <PhotoThumbnailImage url={url} uuid={uuid} idv4={idv4} creationDate={creationDate} tooltipText={mouseovertitle} />
      <PhotoThumbnailIconBar
        isCommented={isCommented}
        commentResolved={commentResolved}
        isBestPractice={isBestPractice}
        isFromSurvey={isFromSurvey}
        isValidated={isValidated}
        tagList={taglist}
        validationList={validationList}
      />
      <PhotoThumbnailName posParams={posParams} isSelected={isSelected} />
    </div>
  );
}
