import { createReducer } from '@reduxjs/toolkit';
import {
  FETCH_GUIDELINE_DATA_ERROR,
  FETCH_GUIDELINE_DATA_START,
  FETCH_GUIDELINE_DATA_SUCCESS,
} from 'app/display/guideline/document/settings/actions';
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from 'app/common/reducers/status';

export const initialState = {
  status: undefined,
  data: {},
};

const guidelinesDataByIdReducer = createReducer(initialState, builder => {
  builder
    .addCase(FETCH_GUIDELINE_DATA_START, draftState => {
      draftState.status = STATUS_LOADING;
    })
    .addCase(FETCH_GUIDELINE_DATA_SUCCESS, (draftState, action) => {
      draftState.status = STATUS_SUCCESS;
      draftState.data[action.guidelineId] = action.guidelineData;
    })
    .addCase(FETCH_GUIDELINE_DATA_ERROR, draftState => {
      draftState.status = STATUS_ERROR;
    });
});

export default guidelinesDataByIdReducer;
