// has network access
import useCurrentUser from 'app/common/hooks/useCurrentUser';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';

export default function useCurrentUserHasDirectoryAccess() {
  const { currentUser } = useCurrentUser();
  const { aclRight: currentUserCanSeeDirectory } = useCurrentUserAclRight('legacy.user.seeDirectory');

  return (
    (currentUser?.settings.network?.hasNetwork ||
      currentUser?.settings.editors.has2d ||
      currentUser?.settings.editors.has3d) &&
    currentUserCanSeeDirectory
  );
}
