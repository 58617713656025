import { useState, useEffect, useRef, MutableRefObject } from 'react';

export type ImageRefLoaderReturnType = {
  isLoading: boolean;
  isError: boolean;
  imageRef: MutableRefObject<HTMLImageElement | null>;
};

function useImageRefLoader(url: string): ImageRefLoaderReturnType {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const imageCurrent = imageRef.current;

    function onLoad() {
      setIsError(false);
      setIsLoading(false);
    }

    function onError() {
      setIsError(true);
      setIsLoading(false);
    }

    imageCurrent?.addEventListener('load', onLoad, false);
    imageCurrent?.addEventListener('error', onError, false);

    setIsLoading(url != null);
    setIsError(url == null);

    if (url != null && imageCurrent) {
      imageCurrent.src = url;
      // in any case the image is already in cache
      if (imageCurrent?.complete) {
        setIsLoading(false);
        setIsError(false);
      }
    }

    return () => {
      imageCurrent?.removeEventListener('load', onLoad);
      imageCurrent?.removeEventListener('error', onError);
    };
  }, [url, imageRef]);

  return { isLoading, isError, imageRef };
}

export default useImageRefLoader;
