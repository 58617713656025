import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import SettingsIcon from 'assets/icons/settings.svg';
import { PATH_SETTINGS } from 'config/routePaths';
import { BodyText2 } from 'app/common/typography';
import isCurrentUserAllowedToAccessSettings from 'app/common/selectors/acl/isCurrentUserAllowedToAccessSettings';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';

import styles from './index.css';

const Settings = () => {
  const isVisible = useSelector(isCurrentUserAllowedToAccessSettings);

  return isVisible ? (
    <UserMenuLink to={PATH_SETTINGS}>
      <UserMenuIcon IconComponent={SettingsIcon} />
      <BodyText2 className={styles.uppercase}>{i18next.t('display.user.popup.link.settings')}</BodyText2>
    </UserMenuLink>
  ) : null;
};

export default Settings;
