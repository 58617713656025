import { StatePayload } from 'domain/state/factory';
import FileState from 'domain/guideline/fileState';
import FileStatus from 'domain/guideline/fileStatus';

export type FileStatePayload = Omit<StatePayload, 'enabled' | 'enabledModifierId' | 'enabledModificationDate'> & {
  status: FileStatus;
};

export default class FileStateFactory {
  static createFromApiPayload(statePayload: FileStatePayload): FileState {
    return new FileState(
      statePayload.created.user.id,
      statePayload.updated.user.id,
      new Date(statePayload.created.date),
      new Date(statePayload.updated.date),
      statePayload.status
    );
  }
}
