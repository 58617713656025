import React from 'react';
import LoginForm from 'app/auth/login/containers/form';
import styles from './layout.css';

export const Layout = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.horizontalCenteredWrapper}>
        <div className={styles.logo}>DRIVE</div>
      </div>
      <div className={styles.horizontalCenteredWrapper}>
        <LoginForm />
      </div>
    </div>
  );
};

export default Layout;
