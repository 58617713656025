import PropTypes from 'prop-types';
import {
  DRAG_PAYLOAD_SCOPE_TYPE_ALL,
  DRAG_PAYLOAD_SCOPE_TYPE_SELECTION,
  DRAG_TYPE_FOLDER,
  DRAG_TYPE_ITEM,
  DRAG_TYPE_PHOTO,
} from 'app/display/common/constants/dragDrop';

export const itemDragPayloadPropTypes = PropTypes.shape({
  type: PropTypes.oneOf([DRAG_TYPE_ITEM, 'PHOTOS']).isRequired,
  draggedItemId: PropTypes.string.isRequired,
  scope: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([DRAG_PAYLOAD_SCOPE_TYPE_SELECTION]).isRequired,
      ids: PropTypes.arrayOf(PropTypes.string).isRequired,
      count: PropTypes.number.isRequired,
    }),
    PropTypes.shape({
      type: PropTypes.oneOf([DRAG_PAYLOAD_SCOPE_TYPE_ALL]).isRequired,
      filters: PropTypes.object.isRequired,
      count: PropTypes.number.isRequired,
    }),
  ]).isRequired,
});

export const folderDragPayloadPropTypes = PropTypes.shape({
  type: PropTypes.oneOf([DRAG_TYPE_FOLDER]).isRequired,
  draggedFolderId: PropTypes.string.isRequired,
  scope: PropTypes.shape({
    type: PropTypes.oneOf([DRAG_PAYLOAD_SCOPE_TYPE_SELECTION]).isRequired,
    ids: PropTypes.arrayOf(PropTypes.string).isRequired,
    count: PropTypes.number.isRequired,
  }),
});

export const genericDragPayloadPropTypes = PropTypes.shape({
  type: PropTypes.oneOf([DRAG_TYPE_PHOTO]).isRequired,
  draggedElementId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export default PropTypes.oneOfType([itemDragPayloadPropTypes, folderDragPayloadPropTypes, genericDragPayloadPropTypes]);
