import { useState, useEffect } from 'react';

/**
 * Load an image URL, so it can be directly displayed.
 * Returns
 * - isError: `true` if any error occurs, `false` otherwise.
 * - isLoading: `false` if not loading, `true` otherwise.
 *
 * @param url {string} image URL
 *
 * @returns {{isLoading: boolean, isError: boolean}}
 */
function useImageLoader(url) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const img = new Image();

    function onLoad() {
      setIsError(false);
      setIsLoading(false);
    }

    function onError() {
      setIsError(true);
      setIsLoading(false);
    }

    img.addEventListener('load', onLoad, false);
    img.addEventListener('error', onError, false);

    setIsLoading(url != null);
    setIsError(url == null);

    if (url != null) {
      img.src = url;
      // in any case the image is already in cache
      if (img.complete) {
        setIsLoading(false);
        setIsError(false);
      }
    }

    return () => {
      img.removeEventListener('load', onLoad);
      img.removeEventListener('error', onError);
    };
  }, [url]);

  return { isLoading, isError };
}

export default useImageLoader;
