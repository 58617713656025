import React from 'react';
import { Switch } from 'react-router-dom';
import { PATH_SURVEY, PATH_SURVEY_REPORT_VISITS } from 'config/routePaths';
import Loadable from 'react-loadable';
import Spinner from 'app/common/components/spinner';
import catcher from 'app/common/helpers/importCatcher';
import NetworkRoute from 'app/network/common/components/route/network';
import NotFound from 'app/common/components/errors/notFound';
import useNetworkRoutes from 'app/network/hooks/useNetworkRoutes';

const VisitsReport = Loadable({
  loader: () =>
    import(/* webpackChunkName: "reports-visits" */ 'app/network/report/visits/routes').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/network/report/visits/routes'),
});

const SurveyRoutes = Loadable({
  loader: () => import(/* webpackChunkName: "survey" */ 'app/network/survey/routes').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/network/survey/routes'),
});

export default function NetworkRoutes() {
  const { canAccessSurveys, surveysDiscoveryPagePath, canAccessReport, reportsDiscoveryPagePath } = useNetworkRoutes();

  return (
    <Switch>
      <NetworkRoute
        path={PATH_SURVEY_REPORT_VISITS}
        component={VisitsReport}
        isAuthorized={canAccessReport}
        redirectTo={reportsDiscoveryPagePath}
        redirectDelay={0}
      />
      <NetworkRoute
        path={PATH_SURVEY}
        component={SurveyRoutes}
        isAuthorized={canAccessSurveys}
        redirectTo={surveysDiscoveryPagePath}
        redirectDelay={0}
      />
      <NetworkRoute component={NotFound} />
    </Switch>
  );
}
