import useHttpDeleteRequest from 'app/common/hooks/useHttpDeleteRequest';
import useHttpMutation from 'app/common/hooks/useHttpMutation';

export default function useDeletePhoto(storeId: string, photoId: string) {
  const deletePhotoRequest = useHttpDeleteRequest(`privates/stores/${storeId}/photos/${photoId}`);

  const { mutateAsync } = useHttpMutation(deletePhotoRequest);

  return mutateAsync;
}
