import i18next from 'i18next';

/**
 * Login form validation
 * @param values
 * @returns {{}}
 */
export default values => {
  const errors = {};
  if (!values.email) {
    errors.email = i18next.t('form.validation.required');
  }
  if (!values.password) {
    errors.password = i18next.t('form.validation.required');
  }

  return errors;
};
