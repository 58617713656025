import { PHOTO_FOLDER_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';

type UseuseFolderByPhoto = {
  folder: any;
  isLoading: boolean;
  isError: boolean;
  error?: Error | null;
};

export default function useFolderPhotoByStore(
  storeId: string | undefined,
  folderId: string | undefined
): UseuseFolderByPhoto {
  const getFolder = useHttpGetRequest(`/stores/${storeId}/folders/${folderId}`);

  const { data, isLoading, isError, error } = useHttpQuery([PHOTO_FOLDER_CACHE_KEY, storeId, folderId], getFolder);

  return { folder: data, isLoading, isError, error };
}
