import useCurrentUserAcl from 'app/common/hooks/useCurrentUserAcl';
import { get } from 'lodash';

type Result = {
  isExportVisible: boolean;
  isLoading: boolean;
};

export default function useIsExportVisible(): Result {
  const { userAcl, isLoading } = useCurrentUserAcl();

  const isExportVisible =
    get(userAcl, 'admin.export.activity') ||
    get(userAcl, 'admin.export.exportCatalog') ||
    get(userAcl, 'admin.export.market');

  return { isExportVisible, isLoading };
}
