import React from 'react';
import { addToolbarButton, PluginProps, useLightboxState } from 'yet-another-react-lightbox';
import useStoreById from 'app/common/hooks/useStoreById';
import Skeleton from 'app/common/components/skeleton';
import StoreNameImage from 'app/common/components/slideshow/storeName/image';
import { BodyText1, LINK_COLOR_PALETTE } from 'app/common/typography';
import { COLOR_PRIMARY } from 'app/common/style/colors';
import useStoreSheetPath from 'app/common/hooks/useStoreSheetPath';
import NetworkPjaxLink from 'app/network/common/components/pjaxLink';
import styles from './index.css';

function StoreName() {
  const { currentSlide } = useLightboxState();
  const { store, isLoading } = useStoreById(currentSlide?.storeId);
  const storePath = useStoreSheetPath(store?.idv4);

  if (isLoading || !store) {
    return (
      <div className={styles.storeName}>
        <Skeleton width={30} height={30} className={styles.image} />
        <Skeleton width={150} height={19} className={styles.title} />
      </div>
    );
  }

  return (
    <div className={styles.storeName}>
      <StoreNameImage store={store} className={styles.image} />
      <NetworkPjaxLink linkData={{ href: storePath || '', replaceId: '#main' }}>
        <BodyText1 className={styles.title} on={COLOR_PRIMARY} palette={LINK_COLOR_PALETTE} interactive>
          {store.name}
        </BodyText1>
      </NetworkPjaxLink>
    </div>
  );
}

export default function StoreNamePlugin({ augment }: PluginProps) {
  augment(({ toolbar, ...restProps }) => {
    return {
      toolbar: addToolbarButton(toolbar, 'StoreName', <StoreName />),
      ...restProps,
    };
  });
}
