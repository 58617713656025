import React, { useEffect } from 'react';
import Spinner from 'app/common/components/spinner';
import logout from 'app/auth/logout/actions/logout';
import useAction from 'app/display/common/hooks/useAction';

export default function Logout() {
  const logoutAction = useAction(logout);

  useEffect(() => {
    logoutAction();
  }, []);

  return <Spinner />;
}
