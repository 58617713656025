import React from 'react';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import useToggleUserStatusModal from 'app/directory/user/common/modals/toggleUserStatus/index.hook';

export const ACTIVATE_USER_MODAL_TEST_ID = 'activateUserModal';

export default function ActivateUserModal({ userId }: { userId: string }) {
  const { onClose, onConfirm, onConfirmSuccess, onConfirmError, t, isFetching } = useToggleUserStatusModal(
    userId,
    'directory.userSheet.toggleStatusModal.activate.success'
  );

  return (
    <ProcessingConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      onConfirmError={onConfirmError}
      onConfirmSuccess={onConfirmSuccess}
      closeEnabled={true}
      confirmEnabled={!isFetching}
      confirmLabel={t('button.confirm')}
      title={t('directory.userSheet.toggleStatusModal.activate.title')}
      submitOnEnter={true}
      isLoading={isFetching}
    >
      <div data-testid={ACTIVATE_USER_MODAL_TEST_ID}>
        {t('directory.userSheet.toggleStatusModal.activate.infoText')}
      </div>
    </ProcessingConfirmModal>
  );
}
