export enum StoreTabsPayloadType {
  CONTACTS = 'contacts',
  INFO = 'info',
  FILES = 'files',
  PHOTOS = 'photos',
  SURVEY = 'survey',
  STAFF = 'staff',
  INVENTORY = 'inventory',
  GUIDELINES = 'guidelines',
  FIELDS = 'fields',
}

export type StoreTabPayload = {
  id: number;
  index: number;
  name: string;
  translationKey: string;
  type: StoreTabsPayloadType;
};

export default class StoreTabs {
  photosTabEnabled = false;

  surveysTabEnabled = false;

  guidelinesTabEnabled = false;

  documentsTabEnabled = false;

  constructor(storeTabs: StoreTabPayload[]) {
    storeTabs.forEach(tab => {
      if (tab.type === StoreTabsPayloadType.PHOTOS) {
        this.photosTabEnabled = true;
      } else if (tab.type === StoreTabsPayloadType.SURVEY) {
        this.surveysTabEnabled = true;
      } else if (tab.type === StoreTabsPayloadType.GUIDELINES) {
        this.guidelinesTabEnabled = true;
      } else if (tab.type === StoreTabsPayloadType.FILES) {
        this.documentsTabEnabled = true;
      }
    });
  }
}
