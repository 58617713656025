import { query } from 'app/common/actions/api';
import {
  FETCH_CURRENTUSER_ACL_START,
  FETCH_CURRENTUSER_ACL_SUCCESS,
  FETCH_CURRENTUSER_ACL_ERROR,
} from 'app/common/user/reducers/currentUserACL';
import assertIsNotCriticalHttpException from 'app/common/helpers/api/assertIsNotCriticalHttpException';
import updateCurrentUserAclCache from 'app/common/user/actions/updateCurrentUserAclCache';

export default function fetchCurrentUserACL() {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_CURRENTUSER_ACL_START });
      const acl = await dispatch(query('/privates/user/acl'));
      updateCurrentUserAclCache(acl);
      dispatch({ type: FETCH_CURRENTUSER_ACL_SUCCESS, acl });
    } catch (error) {
      assertIsNotCriticalHttpException(error);
      dispatch({ type: FETCH_CURRENTUSER_ACL_ERROR, error });
    }
  };
}
