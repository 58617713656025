import React from 'react';
import PropTypes from 'prop-types';
import RoundedButton from 'app/display/common/components/roundedButton';
import PlusIcon from 'assets/icons/plus.svg';
import { HeadText4 } from 'app/common/typography';
import { Link as RouterLink } from 'react-router-dom';
import styles from './style.css';

/**
 * A SingleActionPlusButton is a RoundedButton with a PlusIcon inside.
 * As it is a well-known Material Design component, it exists on its own.
 *
 * @example ./index.md
 */
const SingleActionPlusLink = ({ to, label, icon, dataTestId }) => {
  return (
    <RouterLink className={styles.plusButtonSingleAction} to={to} data-testid={dataTestId}>
      <RoundedButton className={styles.plusButton}>{icon || <PlusIcon className={styles.icon} />}</RoundedButton>
      <HeadText4 className={styles.label}>{label}</HeadText4>
    </RouterLink>
  );
};

SingleActionPlusLink.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.node,
  dataTestId: PropTypes.string,
};

SingleActionPlusLink.defaultProps = {
  label: null,
  to: null,
  icon: null,
  dataTestId: null,
};

export default SingleActionPlusLink;
