import State from 'domain/state';
import BrandFile from 'domain/brand/file';

export enum BrandType {
  OWNER = 'owner',
  GENERIC = 'generic',
}

export default class Brand {
  constructor(
    public id: string,
    public name: string,
    public type: BrandType,
    public state: State,
    public files: BrandFile[] = [],
    public color: string | null = null,
    public idv4: number | null = null,
    public url: string | null = null
  ) {
    // do nothing.
  }

  isGeneric(): boolean {
    return this.type === BrandType.GENERIC;
  }
}
