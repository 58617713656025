import React from 'react';
import i18next from 'i18next';
import { BodyText2 } from 'app/common/typography';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import { PATH_NETWORK_TAG } from 'config/routePaths';
import { USER_MENU_TAGS } from 'app/common/constants/userflow';
import TagsIcon from 'assets/icons/tags-action.svg';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import styles from './index.css';

const Tags = () => {
  const href = useParamSelector(getAbsoluteUrl, PATH_NETWORK_TAG);
  const { aclRight: hasMaintainGuidelineTags } = useCurrentUserAclRight('guidelines.guideline.maintainTags');
  const { aclRight: hasNetworkManageTags } = useCurrentUserAclRight('legacy.tag.manage');

  return hasMaintainGuidelineTags || hasNetworkManageTags ? (
    <UserMenuLink to={href} dataTestId={USER_MENU_TAGS}>
      <UserMenuIcon IconComponent={TagsIcon} />
      <BodyText2 className={styles.uppercase}>{i18next.t('display.user.popup.link.tags')}</BodyText2>
    </UserMenuLink>
  ) : null;
};

export default Tags;
