import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Omit from 'lodash/omit';
import classNames from 'classnames';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import noop from 'lib/helpers/noop';
import styles from 'app/display/common/components/checkboxlabel.css';

class CheckboxLabel extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    labelStyles: PropTypes.string,
    checkboxStyles: PropTypes.string,
    input: PropTypes.shape(fieldInputPropTypes),
    disabled: PropTypes.bool,
    meta: PropTypes.shape(fieldMetaPropTypes),
  };

  static defaultProps = {
    id: null,
    className: null,
    labelStyles: '',
    checkboxStyles: '',
    input: {
      name: 'checkbox-label',
      onBlur: noop,
      onChange: noop,
      onDragStart: noop,
      onDrop: noop,
      onFocus: noop,
    },
    disabled: false,
    meta: {
      submitting: false,
      active: false,
      asyncValidating: true,
      autofilled: true,
      dirty: false,
      dispatch: noop,
      form: '',
      invalid: false,
      pristine: true,
      submitFailed: false,
      touched: false,
      valid: true,
      visited: false,
    },
  };

  render() {
    const { children, className, labelStyles, checkboxStyles, input, meta, ...props } = this.props;
    const inputProps = Omit(props, ['labelClass', 'infoClass', 'inputStyle', 'selectStyle', 'errorStyle']);

    const inputClassSet = classNames('checkbox', 'is-disableable', {
      [styles.checkbox]: true,
      [checkboxStyles]: checkboxStyles != null,
      [styles.isDisabled]: meta?.submitting,
    });

    const labelClassSet = classNames(styles.label, 'is-disableable', {
      [labelStyles]: labelStyles != null,
      disabled: props.disabled,
      [styles.isDisabled]: meta?.submitting,
    });

    return (
      <div className={className}>
        <input
          type="checkbox"
          className={inputClassSet}
          checked={props.checked || input.value === true}
          {...input}
          {...inputProps}
        />
        <label className={labelClassSet} htmlFor={props.id}>
          {children}
        </label>
      </div>
    );
  }
}

export default CheckboxLabel;
