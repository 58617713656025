import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserNotificationFilterCategory } from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';
import { HeadText4 } from 'app/common/typography';
import EmptyUserNotificationsListSvg from 'assets/icons/empty-notifications.svg';
import styles from 'app/display/common/components/modals/userNotification/content/empty.css';

const EmptyUserNotificationsList = ({ selectedTab }: { selectedTab: UserNotificationFilterCategory }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <EmptyUserNotificationsListSvg className={styles.svg} />
      <HeadText4 className={styles.text}>{t(`display.modal.notifications.tab.empty.${selectedTab}`)}</HeadText4>
    </div>
  );
};

export default EmptyUserNotificationsList;
