import React from 'react';
import i18next from 'i18next';
import { PATH_SETTINGS } from 'config/routePaths';
import DisplayLink from 'app/common/components/legacyNavMenu/menu/display/link';
import useLocationMatch from 'app/common/hooks/useLocationMatch';

const useSettingsLink = () => {
  const isActive = useLocationMatch(PATH_SETTINGS, { exact: false });

  return {
    to: PATH_SETTINGS,
    isActive,
  };
};

const SettingsLink = () => {
  const { to, isActive } = useSettingsLink();

  return (
    <DisplayLink isActive={isActive} to={to}>
      {i18next.t('display.submenu.settings')}
    </DisplayLink>
  );
};

export default SettingsLink;
