import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import getNetworkUrl from 'app/common/selectors/navMenu/getNetworkUrl';
import Link from 'app/common/components/link';

const NetworkLink = ({ className, target, alt, children }) => {
  const networkUrl = useSelector(state => getNetworkUrl(state, true));

  return (
    <Link className={className} href={networkUrl} target={target} alt={alt}>
      {children}
    </Link>
  );
};

NetworkLink.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string,
  alt: PropTypes.string,
  children: PropTypes.node,
};

NetworkLink.defaultProps = {
  className: null,
  target: null,
  alt: null,
  children: null,
};

export default NetworkLink;
