import React from 'react';
import i18next from 'i18next';
import ErrorLayout from 'app/common/components/errors/layout';
import OopsHeader from 'app/common/components/errors/layout/header/oops';
import DashboardHint from 'app/common/components/errors/layout/hint/dashboard';

const Forbidden = () => (
  <ErrorLayout
    title={i18next.t('display.error.forbidden.title')}
    header={<OopsHeader title="403" />}
    hint={<DashboardHint />}
  />
);

export default Forbidden;
