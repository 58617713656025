import { useCallback, useEffect, useState } from 'react';

export default function useBidirectionalControlledInput(value, onChange) {
  const [inputValue, setInputValue] = useState(value);

  /**
   * This effect update the input value when the value passed as parameter changes
   */
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  /**
   * This callback handle input change by updating the input value with the new value
   * and triggering the onChange callback passed as parameter with the new value
   */
  const handleInputChange = useCallback(
    event => {
      setInputValue(event.target.value);
      onChange(event.target.value);
    },
    [setInputValue, onChange]
  );

  return [inputValue, handleInputChange];
}
