import PhotoUploader from 'app/network/common/components/photoUploader';
import ModalRoot from 'app/common/containers/modals';
import UserflowRoot from 'app/network/common/components/userflowRoot';
import DirectoryActionBar from 'app/directory/user/sheet/view/components/actionBar';
import ExportLink from 'app/network/common/components/userMenu/links/export';
import NetworkNotifications from 'app/network/common/components/notifications';
import LicenseTab from 'app/directory/user/sheet/view/components/license';
import Notificationstab from 'app/directory/user/sheet/view/components/notifications';
import SheetUserStatusAnnotation from 'app/directory/user/sheet/view/components/sheetStatusAnnotation';
import ListUserStatusAnnotation from 'app/directory/user/list/components/listStatusAnnotation';
import CreateUserForm from 'app/directory/user/sheet/create';
import ToggleStatusButton from 'app/directory/user/list/components/toggleStatusButton';
import UserSheetSaveButton from 'app/directory/user/sheet/common/components/form/saveButton';
import LegacyLoadingBar from 'app/common/components/legacyLoadingBar';
import StoreGuidelinesList from 'app/network/store/sheet/tabs/guidelines/components/guidelinesList';
import LoginMessage from 'app/auth/login/components/message';
import GuidelinesTagList from 'app/network/tag/guideline/components/list';
import LegacySlideshow from 'app/network/legacySlideshow';
import PhotoThumbnail from 'app/network/library/photoThumbnail';
import UserNotificationCenter from 'app/common/components/appShell/userNotificationCenter';
import EditUserLicenseTab from 'app/directory/user/sheet/edit/license';
import EditUserNotificationsTab from 'app/directory/user/sheet/edit/notifications';
import CreateUserNotificationsTab from 'app/directory/user/sheet/create/notifications';
import GuidelinesActions from 'app/network/store/sheet/tabs/guidelines/components/guidelinesActions';

const NETWORK_LEGACY_COMPONENTS = {
  'loading-bar': LegacyLoadingBar,
  'photo-uploader': PhotoUploader,
  'modal-layer': ModalRoot,
  'userflow-root': UserflowRoot,
  'notification-root': NetworkNotifications,
  'directory-action-bar': DirectoryActionBar,
  'export-menu-button': ExportLink,
  'directory-sheet-license-tab': LicenseTab,
  'directory-sheet-license-tab-edit-form': EditUserLicenseTab,
  'directory-sheet-license-tab-create-form': CreateUserForm,
  'directory-sheet-notifications-tab-create-form': CreateUserNotificationsTab,
  'directory-sheet-notifications-tab-edit-form': EditUserNotificationsTab,
  'directory-sheet-notifications-tab': Notificationstab,
  'directory-user-sheet-status': SheetUserStatusAnnotation,
  'directory-user-list-status': ListUserStatusAnnotation,
  'directory-user-list-toggle-status': ToggleStatusButton,
  'directory-sheet-form-submit-button': UserSheetSaveButton,
  'pos-guidelines-actions': GuidelinesActions,
  'pos-guidelines-list': StoreGuidelinesList,
  'login-message': LoginMessage,
  'tag-guideline-list': GuidelinesTagList,
  slideshow: LegacySlideshow,
  'photo-thumbnail': PhotoThumbnail,
  'user-notification-center': UserNotificationCenter,
};

export default NETWORK_LEGACY_COMPONENTS;
