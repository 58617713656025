import React from 'react';
import UserMenu from 'app/common/components/appShell/header/userMenu';
import DirectoryLink from 'app/network/common/components/userMenu/links/directory';
import FoldersLink from 'app/network/common/components/userMenu/links/folders';
import TagsLink from 'app/network/common/components/userMenu/links/tags';
import HelpLink from 'app/network/common/components/userMenu/links/help';
import AclLink from 'app/network/common/components/userMenu/links/acl';
import AssetsLink from 'app/network/common/components/userMenu/links/assets';
import TabsLink from 'app/network/common/components/userMenu/links/tabs';
import LogoutLink from 'app/network/common/components/userMenu/links/logout';

const NetworkUserMenu = () => (
  <UserMenu>
    <DirectoryLink />
    <FoldersLink />
    <TagsLink />
    <HelpLink />
    <AclLink />
    <AssetsLink />
    <TabsLink />
    <LogoutLink />
  </UserMenu>
);

export default NetworkUserMenu;
