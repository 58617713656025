import { query } from 'app/common/actions/api';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AppState } from 'lib/providers/store';
import { AnyAction } from 'redux';
import { REFRESH_TOKEN_ERROR, REFRESH_TOKEN_START, REFRESH_TOKEN_SUCCESS } from 'app/common/reducers/auth';
import { getUserToken } from 'app/common/selectors/auth';
import isTokenExpired from 'app/auth/token/helpers/isTokenExpired';
import getExpirationTimeFromToken from 'app/auth/token/helpers/getExpirationTimeFromToken';
import getTokenExpirationTime from 'app/common/selectors/auth/getTokenExpirationTime';
import setLoginPayloadCookie from 'app/common/helpers/auth/setLoginPayloadCookie';

function refreshTokenStart() {
  return {
    type: REFRESH_TOKEN_START,
  };
}
function refreshTokenSuccess(token: string, tokenExpirationTime: number | null) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    token,
    tokenExpirationTime,
  };
}
function refreshTokenError() {
  return {
    type: REFRESH_TOKEN_ERROR,
  };
}

function refreshToken() {
  return async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => any) => {
    let result = '';
    dispatch(refreshTokenStart());
    const previousToken = getUserToken(getState());
    try {
      const response = await dispatch(
        query<{ token: string }>('auth/token/refresh', { method: 'POST' }, previousToken)
      );
      if (response?.token != null) {
        dispatch(refreshTokenSuccess(response.token, getExpirationTimeFromToken(response.token)));
        result = response.token;
        setLoginPayloadCookie({ token: response.token });
      } else {
        dispatch(refreshTokenError());
      }
    } catch (exception) {
      console.error(exception);
      dispatch(refreshTokenError());
    }
    return result;
  };
}

let refreshTokenPromise: Promise<string> | null;

export default function getOrRefreshUserToken() {
  return async (dispatch: ThunkDispatch<AppState, any, AnyAction>, getState: () => AppState) => {
    const state = getState();
    const currentUserToken = getUserToken(state);
    const tokenExpirationTime = getTokenExpirationTime(state);

    if (isTokenExpired(tokenExpirationTime)) {
      if (refreshTokenPromise == null) {
        refreshTokenPromise = dispatch(refreshToken());
      }
      try {
        const newToken = await refreshTokenPromise;
        refreshTokenPromise = null;
        if (newToken != null && newToken.length > 0) return newToken;
      } catch (error) {
        // nothing to do
      }
    }
    return currentUserToken;
  };
}
