import UserNotificationState, { UserNotificationStatePayload } from 'domain/userNotifications/state';
import i18next from 'i18next';

type TranslatedContent = {
  en: string;
  fr: string;
  [langCode: string]: string;
};

type UserNotificationTitle = TranslatedContent;

type UserNotificationContents = TranslatedContent;

type UserNotificationContext = {
  folderId?: string;
  folderIdV4?: number;
  storeId?: string;
  storeIdV4?: number;
  surveyAnswerIdV4?: number;
  surveyId?: string;
  fileUrl?: string;
  legacyThumbnailUrl?: string;
};

export enum UserNotificationTypes {
  GUIDELINE_NEW_AVAILABLE = 'GUIDELINE_NEW_AVAILABLE',
  DOCUMENT_NEW_AVAILABLE = 'DOCUMENT_NEW_AVAILABLE',
  SURVEY_NEW_PUBLICATION = 'SURVEY_NEW_PUBLICATION',
  SURVEY_NEW_AVAILABLE = 'SURVEY_NEW_AVAILABLE',
  SURVEY_REMINDER_COMPLETION = 'SURVEY_REMINDER_COMPLETION',
  PHOTO_DELETE = 'PHOTO_DELETE',
  PHOTO_APPROVE = 'PHOTO_APPROVE',
  PHOTO_APPROVAL_REMOVE = 'PHOTO_APPROVAL_REMOVE',
  PHOTO_BEST_PRACTICE = 'PHOTO_BEST_PRACTICE',
  PHOTO_BEST_PRACTICE_REMOVE = 'PHOTO_BEST_PRACTICE_REMOVE',
  PHOTO_UPLOAD = 'PHOTO_UPLOAD',
  PHOTO_COMMENT = 'PHOTO_COMMENT',
  PHOTO_REPLACE = 'PHOTO_REPLACE',
}

export enum UserNotificationCategory {
  GUIDELINE = 'GUIDELINE',
  DOCUMENT = 'DOCUMENT',
  SURVEY = 'SURVEY',
  PHOTO = 'PHOTO',
}

export type UserNotificationPayload = {
  id: string;
  titles: UserNotificationTitle;
  contents: UserNotificationContents;
  type: UserNotificationTypes;
  category: UserNotificationCategory;
  recipientId: string;
  subjectId: string;
  thumbnailUrl: string | null;
  context: UserNotificationContext;
  state: UserNotificationStatePayload;
};

export type UserNotificationPayloadData = {
  notifications: UserNotificationPayload[];
};

export default class UserNotification {
  constructor(
    public readonly id: string,
    public readonly titles: UserNotificationTitle,
    public readonly contents: UserNotificationContents,
    public readonly type: UserNotificationTypes,
    public readonly category: UserNotificationCategory,
    public readonly recipientId: string,
    public readonly subjectId: string,
    public readonly thumbnailUrl: string | null,
    public readonly context: UserNotificationContext,
    public readonly state: UserNotificationState
  ) {}

  public isRead() {
    return this.state.isRead;
  }

  public getContent(): string {
    const lng = i18next.language.substring(0, 2);
    return this.contents[lng] ?? this.contents.en;
  }

  public markAsRead() {
    this.state.markStateAsRead();

    return this;
  }
}
