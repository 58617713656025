import React from 'react';
import PrevPhoto from 'app/common/components/slideshow/navigation/prevPhoto';
import { useController, useLightboxProps, useNavigationState } from 'yet-another-react-lightbox';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import isFilmStripOpen from 'app/common/selectors/slideshow/isFilmStripOpen';
import styles from './prevPhotoButton.css';

export const SLIDESHOW_FILMSTRIP_PREVPHOTO_TESTID = 'slideshowInformationPrevPhoto';

export default function PrevPhotoButton() {
  const { prev } = useController();
  const { prevDisabled } = useNavigationState();
  const { animation } = useLightboxProps();
  const isStripOpen = useSelector(isFilmStripOpen);

  const onClick = () => {
    animation.swipe = 500;
    animation.fade = 500;
    prev();
  };

  return (
    <button
      type="button"
      className={classNames('yarl__button', 'yarl__navigation_prev', {
        [styles.filmStripOpen]: isStripOpen,
      })}
      onClick={onClick}
      disabled={prevDisabled}
      data-testid={SLIDESHOW_FILMSTRIP_PREVPHOTO_TESTID}
    >
      <PrevPhoto />
    </button>
  );
}
