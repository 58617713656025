import React from 'react';
import classNames from 'classnames';
import { BodyText1 } from 'app/common/typography';
import { COLOR_PRIMARY, COLOR_SECONDARY } from 'app/common/style/colors';
import Checkbox from 'app/display/acl/instance/components/selection/checkbox';
import noop from 'lib/helpers/noop';
import getCheckboxState from 'app/display/common/helpers/getCheckboxState';
import { AvailableActivity } from 'app/display/import/hooks/useAvailableActivities';
import highlightSearchTermInText from 'app/display/common/components/search/highlightSearchTermInText';
import styles from './activityTypeListRow.css';

type ActivityTypeListRowType = {
  activityType: AvailableActivity;
  onClick: (code: string) => void;
  isSelected: boolean;
  isDisabled: boolean;
  searchTerm?: string;
};

const ActivityTypeListRow = ({
  activityType,
  onClick,
  isSelected,
  isDisabled,
  searchTerm,
}: ActivityTypeListRowType) => {
  const rowClasses = classNames(styles.row, {
    [styles.isSelected]: isSelected,
    [styles.isDisabled]: isDisabled,
  });

  const on = isSelected ? COLOR_SECONDARY : COLOR_PRIMARY;
  const onChange = () => (isDisabled ? noop : onClick(activityType.code));
  const inputState = getCheckboxState(isSelected, false);
  let uniqKey = 0;

  return (
    <div key={`activity-row-${uniqKey++}`} className={rowClasses} onClick={onChange}>
      <Checkbox
        state={inputState}
        onClick={onChange}
        disabled={isDisabled || false}
        dataTestId={`checkbox-${activityType.code}`}
      />
      <div className={styles.activityTypeInfo} title={activityType.code}>
        <BodyText1 on={on}>{highlightSearchTermInText(activityType.description, searchTerm)}</BodyText1>
      </div>
    </div>
  );
};

export default ActivityTypeListRow;
