import React from 'react';
import { Link } from 'react-router-dom';
import IWDLogo from 'assets/icons/iwd-logo.svg';
import PropTypes from 'prop-types';

import { PATH_DASHBOARD } from 'config/routePaths';
import { APPSHELL_IWD_LOGO } from 'app/common/constants/userflow';
import styles from './iwd.css';

const IwdPlatformLogo = ({ className }) => {
  return (
    <Link className={className} to={PATH_DASHBOARD} data-testid={APPSHELL_IWD_LOGO}>
      <IWDLogo className={styles.logo} />
    </Link>
  );
};

IwdPlatformLogo.propTypes = {
  className: PropTypes.string.isRequired,
};

export default IwdPlatformLogo;
