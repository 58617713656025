export default class Period {
  constructor(public readonly startDate: Date, public readonly endDate: Date) {
    Period.assertStartDateIsEarlierThanEndDate(startDate, endDate);
  }

  private static assertStartDateIsEarlierThanEndDate(startDate: Date, endDate: Date) {
    if (startDate > endDate) {
      throw new Error('Start date must be earlier than end date.');
    }
  }
}
