import React, { useMemo } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Download from 'yet-another-react-lightbox/plugins/download';
import CloseIcon from 'app/common/components/slideshow/buttons/close';
import ZoomButton from 'app/common/components/slideshow/buttons/zoom';
import StoreNamePlugin from 'app/common/components/slideshow/storeName';
import BestPracticePhoto from 'app/common/components/slideshow/bestPracticePhoto';
import DownloadButton from 'app/common/components/slideshow/buttons/download';
import Information from 'app/common/components/slideshow/information';
import Comments from 'app/common/components/slideshow/comments';
import ApprovePhotoPlugin from 'app/common/components/slideshow/approvePhoto';
import DeletePhoto from 'app/common/components/slideshow/deletePhoto';
import FilmStrip from 'app/common/components/slideshow/filmStrip';
import TagPhoto from 'app/common/components/slideshow/tagPhoto';
import RotateImageButton from 'app/common/components/slideshow/rotate';
import DrawingButton from 'app/common/components/slideshow/drawing';
import StatusTagsPlugin from 'app/common/components/slideshow/statusTags';
import { Photo } from 'domain/photo';
import CustomSlide from 'app/common/components/slideshow/slide';
import PrevPhotoButton from 'app/common/components/slideshow/navigation/prevPhotoButton';
import NextPhotoButton from 'app/common/components/slideshow/navigation/nextPhotoButton';
import { displaySelectedPhotoId } from 'app/common/actions/slideshow';
import { useDispatch } from 'react-redux';
import { StatePayload } from 'domain/state/factory';
import styles from './index.css';

declare module 'yet-another-react-lightbox' {
  interface SlideImage {
    storeId: string;
    id: string;
    idv4: number;
    state: StatePayload;
    approvals: [
      {
        date: string;
        user: {
          id: string;
          name: string;
        };
      }
    ];
    tags: string[];
    url: string;
    folderId: string;
    caption?: string;
    drawingUrl: string | null;
  }
}

export type SlideshowProps = {
  onClose: () => void;
  isOpen: boolean;
  photos?: Photo[];
  onDeletePhoto: (photoId?: string) => void;
  index: number;
  isLoading: boolean;
};

export default function Slideshow({ onClose, isOpen, photos, onDeletePhoto, index, isLoading }: SlideshowProps) {
  const dispatch = useDispatch();
  const slides = useMemo(
    () =>
      photos?.map(photo => ({
        ...photo,
        src: photo.url,
        width: photo.variations?.find(el => el.type === 'source')?.metadata.width,
        height: photo.variations?.find(el => el.type === 'source')?.metadata.height,
        drawingUrl: photo.drawingUrl,
      })),
    [photos]
  );
  const zoomRef = React.useRef(null);

  return (
    <Lightbox
      open={isOpen}
      close={onClose}
      slides={slides}
      carousel={{ finite: true }}
      index={index}
      plugins={[
        Information,
        Comments,
        Zoom,
        DeletePhoto,
        Download,
        TagPhoto,
        BestPracticePhoto,
        ApprovePhotoPlugin,
        RotateImageButton,
        DrawingButton,
        StatusTagsPlugin,
        StoreNamePlugin,
        FilmStrip,
      ]}
      render={{
        slide: CustomSlide,
        buttonZoom: ZoomButton,
        iconClose: CloseIcon,
        buttonNext: NextPhotoButton,
        buttonPrev: PrevPhotoButton,
        iconDownload: DownloadButton,
      }}
      on={{
        deletePhoto: onDeletePhoto,
        view: ({ index: slideIndex }) => {
          !isLoading && dispatch(displaySelectedPhotoId(slides?.[slideIndex]?.id));
        },
      }}
      className={styles.slideshow}
      zoom={{
        maxZoomPixelRatio: 4,
        ref: zoomRef,
      }}
    />
  );
}
