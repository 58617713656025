import React from 'react';
import classNames from 'classnames';
import EditIcon from 'assets/icons/edit.svg';
import Button from 'app/display/common/components/button';
import styles from './button.css';

type RenameButtonProps = {
  onClick: () => void;
  disabled?: boolean;
};

export const GUIDELINE_TAG_RENAME_BUTTON = 'guidelineTagRenameButton';

const RenameButton = ({ onClick, disabled = false }: RenameButtonProps) => {
  const iconClassSet = classNames(styles.icon, {
    [styles.isDisabled]: disabled,
  });
  return (
    <Button
      type="button"
      className={styles.button}
      onClick={onClick}
      isDisabled={disabled}
      dataTestId={GUIDELINE_TAG_RENAME_BUTTON}
    >
      <EditIcon className={iconClassSet} />
    </Button>
  );
};

export default RenameButton;
