import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Link from 'app/common/components/link';

import {
  LINK_COLOR_PALETTE,
  DEFAULT_COLOR_PALETTE,
  HeadText5,
  TextHoverArea,
  ALT_COLOR_PALETTE,
} from 'app/common/typography';
import styles from './link.css';

const PlatformMenuLink = ({ className, href, to, children, isActive, 'data-testid': dataTestId, isDisabled }) => {
  let palette = isActive ? LINK_COLOR_PALETTE : DEFAULT_COLOR_PALETTE;
  palette = isDisabled ? ALT_COLOR_PALETTE : palette;

  return (
    <TextHoverArea className={classNames(styles.hoverArea, className)} palette={palette}>
      <Link
        href={href}
        to={to}
        data-testid={dataTestId}
        className={classNames(styles.link, { [styles.isActive]: isActive })}
      >
        <HeadText5 palette={palette} className={styles.text}>
          {children}
        </HeadText5>
      </Link>
    </TextHoverArea>
  );
};

PlatformMenuLink.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
};

PlatformMenuLink.defaultProps = {
  className: null,
  isActive: false,
  'data-testid': null,
  href: null,
  to: null,
  isDisabled: false,
};

export default PlatformMenuLink;
