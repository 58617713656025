import { PHOTOS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import useHttpDeleteRequest from 'app/common/hooks/useHttpDeleteRequest';
import useHttpMutation from 'app/common/hooks/useHttpMutation';
import queryClient from 'lib/queryClient';
import { SlideImage } from 'yet-another-react-lightbox';

export default function useResetAllApprovePhoto(selectedPhotoId: string | undefined) {
  const deleteAllApprovals = useHttpDeleteRequest<SlideImage>(`/stores/photos/${selectedPhotoId}/approvals/reset`);
  const onSuccess = () => {
    return queryClient.getInstance().invalidateQueries([PHOTOS_CACHE_KEY]);
  };

  const { mutateAsync } = useHttpMutation(deleteAllApprovals, { onSuccess });

  return async () => {
    await mutateAsync({});
  };
}
