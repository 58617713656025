import { CHECK_ITEMS, UNCHECK_ITEMS } from 'app/display/catalog/list/common/actions';
import { MOVE_ITEMS_ON_DRAG_DROP_SUCCESS } from 'app/display/catalog/common/reducers/items';
import { HIDE_MODAL } from 'app/common/actions/modals/modalTypes';

export const CHECK_ITEM = 'catalog.list.check.item';
export const UNCHECK_ITEM = 'catalog.list.uncheck.item';
export const TOGGLE_CHECK_ITEM = 'catalog.list.toggle.check.item';
export const HIGHLIGHT_ELEMENT = 'catalog.list.highlight.element';
export const UNHIGHLIGHT_ELEMENT = 'catalog.list.unhighlight.element';

export const DRAG_ITEM_START = 'catalog.list.drag.item.start';
export const DRAG_ITEM_END = 'catalog.list.drag.item.end';

export const DRAG_FOLDER_START = 'catalog.list.drag.folder.start';
export const DRAG_FOLDER_END = 'catalog.list.drag.folder.end';

export const initialState = {
  checkedItems: {},
  hasSelection: false,
  highlightedItem: null,
  isDragging: false,
};

function uncheckItem(state, itemId) {
  if (!Object.hasOwnProperty.call(state.checkedItems, itemId)) {
    return state;
  }

  const checkedItems = { ...state.checkedItems };
  delete checkedItems[itemId];

  const hasSelection = Object.keys(checkedItems).length > 0;
  const highlightedItem = hasSelection ? null : state.highlightedItem;

  return {
    ...state,
    hasSelection,
    checkedItems,
    highlightedItem,
  };
}

export default function reducer(state = initialState, action) {
  const selection = { ...state.checkedItems };
  let hasSelection;

  switch (action.type) {
    case DRAG_ITEM_START:
      return {
        ...state,
        isDragging: true,
      };
    case DRAG_ITEM_END:
      return {
        ...state,
        isDragging: false,
      };
    case TOGGLE_CHECK_ITEM:
      if (Object.hasOwnProperty.call(selection, action.id)) {
        delete selection[action.id];
      } else {
        selection[action.id] = null;
      }

      hasSelection = Object.keys(selection).length > 0;

      return {
        ...state,
        hasSelection,
        checkedItems: selection,
        highlightedItem: hasSelection ? null : state.highlightedItem,
      };
    case CHECK_ITEM:
      if (Object.hasOwnProperty.call(selection, action.id)) {
        // if already selected, return current state
        return state;
      }

      selection[action.id] = null;

      return {
        ...state,
        checkedItems: selection,
        highlightedItem: null,
        hasSelection: true,
      };
    case UNCHECK_ITEM:
      return uncheckItem(state, action.id);
    case CHECK_ITEMS:
      action.ids.forEach(id => {
        selection[id] = null;
      });

      hasSelection = Object.keys(selection).length > 0;

      return {
        ...state,
        hasSelection,
        checkedItems: selection,
        highlightedItem: hasSelection ? null : state.highlightedItem,
      };
    case UNCHECK_ITEMS:
      if (action.ids != null) {
        return action.ids.reduce(uncheckItem, state);
      }
      return {
        ...state,
        checkedItems: {},
        hasSelection: false,
      };
    case HIGHLIGHT_ELEMENT:
      return {
        ...state,
        highlightedItem: action.details,
      };
    case HIDE_MODAL:
    case UNHIGHLIGHT_ELEMENT:
      if (state.highlightedItem == null) {
        return state;
      }
      return {
        ...state,
        highlightedItem: null,
      };
    case MOVE_ITEMS_ON_DRAG_DROP_SUCCESS:
      return action.itemIds.reduce(uncheckItem, state);
    default:
      return state;
  }
}
