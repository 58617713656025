import React from 'react';
import PlatformMenuLink from 'app/common/components/platformMenu/links/link';
import i18next from 'i18next';
import { PATH_CATALOG_BROWSE } from 'config/routePaths';
import useLocationMatch from 'app/common/hooks/useLocationMatch';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import useParamSelector from 'app/display/common/hooks/useParamSelector';
import useDisplayVersion from 'app/common/hooks/useDisplayVersion';
import useCurrentUserCatalogAccess from 'app/common/hooks/useCurrentUserCatalogAccess';
import { APPSHELL_CATALOG } from 'app/common/constants/userflow';

const useCatalogLink = () => {
  const { matchV5 } = useDisplayVersion();
  const href = useParamSelector(getAbsoluteUrl, PATH_CATALOG_BROWSE);
  const isActive = useLocationMatch(PATH_CATALOG_BROWSE, { exact: false });
  const { canAccess, discoveryPagesEnabled } = useCurrentUserCatalogAccess();

  const result = { isActive };

  if (matchV5) {
    result.to = PATH_CATALOG_BROWSE;
  } else {
    result.href = href;
  }

  return { canAccess, discoveryPagesEnabled, ...result };
};

export default function CatalogLink() {
  const { to, href, isActive, canAccess, discoveryPagesEnabled } = useCatalogLink();

  if (!canAccess && !discoveryPagesEnabled) {
    return null;
  }

  return (
    <PlatformMenuLink isActive={isActive} href={href} to={to} data-testid={APPSHELL_CATALOG} isDisabled={!canAccess}>
      {i18next.t('display.submenu.catalog')}
    </PlatformMenuLink>
  );
}
