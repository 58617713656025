export type ExportFiles =
  | typeof EXPORT_MARKET
  | typeof EXPORT_CATALOG
  | typeof EXPORT_ACTIVITY
  | typeof EXPORT_NETWORK_DATA
  | typeof EXPORT_NETWORK_DATA_TEMPLATES;

export const EXPORT_MARKET = {
  key: 'market',
  fileName: 'market.csv',
};

export const EXPORT_CATALOG = {
  key: 'catalog',
  fileName: 'catalog.csv',
};

export const EXPORT_CATALOG_MARKET = {
  key: 'catalogMarket',
  fileName: 'catalog-market.csv',
};

export const EXPORT_ACTIVITY = {
  key: 'activities',
  fileName: 'activity.csv',
};

export const EXPORT_NETWORK_DATA = {
  key: 'networkData',
  fileName: 'networkData',
};

export const EXPORT_NETWORK_DATA_TEMPLATES = {
  key: 'networkDataTemplates',
  fileName: 'networkDataTemplates',
};
