import React from 'react';
import UserMenuLink from 'app/common/components/appShell/header/userMenu/link';
import UserMenuIcon from 'app/common/components/appShell/header/userMenu/link/icon';
import { PATH_NETWORK_CAMPAIGN } from 'config/routePaths';
import IconFolder from 'assets/icons/folder.svg';
import { BodyText2 } from 'app/common/typography';
import useCurrentUserHasFoldersAccess from 'app/network/hooks/useCurrentUserHasFoldersAccess';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import getInstanceBaseUrl from 'app/common/selectors/iwdConfig/getInstanceBaseUrl';
import styles from './index.css';

const FoldersLink = () => {
  const baseUrl = useSelector(getInstanceBaseUrl);

  const showFolders = useCurrentUserHasFoldersAccess();

  if (!showFolders) {
    return null;
  }

  return (
    <UserMenuLink to={`${baseUrl}${PATH_NETWORK_CAMPAIGN}`}>
      <UserMenuIcon IconComponent={IconFolder} />
      <BodyText2 className={styles.uppercase}>{i18next.t('network.userMenu.folders')}</BodyText2>
    </UserMenuLink>
  );
};

export default FoldersLink;
