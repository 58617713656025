import { COLOR_PRIMARY, COLOR_KINGFISHER, COLOR_STORM } from 'app/common/style/colors';

import generator from 'app/common/typography/colors/palettes/helpers/generator';

export const LAST_UPDATE_COLOR_PALETTE = 'last-update-color-palette';

export default generator({
  basic: {
    [COLOR_PRIMARY]: COLOR_KINGFISHER,
  },
  hover: {
    [COLOR_PRIMARY]: COLOR_KINGFISHER,
  },
  active: {
    [COLOR_PRIMARY]: COLOR_STORM,
  },
});
