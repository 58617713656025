import getCurrentUser from 'app/common/user/selectors/getCurrentUser';
import validateProfileUser from 'app/common/helpers/validateProfileUser';

export default function getCurrentUserProfiles(state) {
  const { profile } = getCurrentUser(state);

  validateProfileUser(profile);

  return profile;
}
