import useIsCurrentUserAllowedToManageCampaign from 'app/display/guideline/common/hooks/acl/useIsCurrentUserAllowedToManageCampaign';
import useIsCurrentUserAllowedToCreateGuideline2d from 'app/display/guideline/common/hooks/acl/useIsCurrentUserAllowedToCreateGuideline2d';
import useIsCurrentUserAllowedToCreateGuideline3d from 'app/display/guideline/common/hooks/acl/useIsCurrentUserAllowedToCreateGuideline3d';
import useIsGuidelineEditRouteAccessible from 'app/display/guideline/hooks/useIsGuidelineEditRouteAccessible';

const useGuidelineRoutes = () => {
  const createGuideline2dAllowed = useIsCurrentUserAllowedToCreateGuideline2d();
  const createGuideline3dAllowed = useIsCurrentUserAllowedToCreateGuideline3d();
  const editGuidelineAllowed = useIsGuidelineEditRouteAccessible();
  const manageCampaignAllowed = useIsCurrentUserAllowedToManageCampaign();

  return {
    createGuideline2dAllowed,
    createGuideline3dAllowed,
    editGuidelineAllowed,
    manageCampaignAllowed,
  };
};

export default useGuidelineRoutes;
