import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BlockButton from 'app/display/common/components/block/button';

import styles from './style.css';

/**
 * A rounded button, which is like a block button... but round :)
 * Radius value is exposed as property
 *
 * @see [Zeplin link](https://zpl.io/aN40ODQ)
 * @example ./index.md
 */
const RoundedButton = ({ children, radius, className, ...props }) => {
  const classSet = classNames(styles.roundedButton, className);

  const style = {};
  if (radius != null) {
    style.height = radius;
    style.width = radius;
  }

  return (
    <BlockButton className={classSet} style={style} {...props}>
      {children}
    </BlockButton>
  );
};

RoundedButton.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * Value of radius. If Number is provided, will be interpreted as pixels.
   */
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

RoundedButton.defaultProps = {
  radius: null,
  className: null,
};

export default RoundedButton;
