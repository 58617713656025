import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import moment from 'moment';
import ProcessingConfirmModal from 'app/display/common/components/modals/processingConfirmModal';
import ExportSelector from 'app/display/import/components/exportSelector';
import useAvailableExportValues from 'app/display/import/hooks/useAvailableExportValues';
import useAction from 'app/display/common/hooks/useAction';
import { hideModal, showModal } from 'app/common/actions/modals';
import exportData from 'app/display/import/actions/exportData';
import ModalError from 'app/common/containers/modals/errors';
import SearchInput, { SEARCH_INPUT_THEME_ALT } from 'app/display/common/components/searchInput';
import { BodyText1, BodyText2 } from 'app/common/typography';
import { EXPORT_ACTIVITY } from 'app/display/import/constants/export';
import ActivityTypeList from 'app/display/import/components/modals/activityTypeList';
import useAvailableActivities from 'app/display/import/hooks/useAvailableActivities';
import toggleValueInArray from 'lib/helpers/array/toggleValueInArray';
import filterEntityByPropAndSearchTerm from 'app/display/guideline/common/helpers/filterAvailableActivitiesBySearchTerm';
import ListSelection from 'app/display/common/components/list/selection';
import getTodayDateFormatted from 'app/display/common/helpers/dates/getTodayDateFormatted';
import getOneMonthAgoFormatted from 'app/display/common/helpers/dates/getOneMonthAgoFormatted';
import DatePickerWrapper from 'app/display/import/components/modals/dateRangePicker';
import classNames from 'classnames';
import styles from './export.css';

export const EXPORT_IWD_ACTIVITIES_DATA_TEST_ID = 'exportIWDActivities';

function usePeriodForRangePicker() {
  const [startDate, setStartDate] = useState(moment(getOneMonthAgoFormatted()));
  const [endDate, setEndDate] = useState(moment(getTodayDateFormatted()));
  const isPeriodInvalid = startDate == null || endDate == null;

  return { startDate, setStartDate, endDate, setEndDate, isPeriodInvalid };
}

const ExportModal = ({ hasNetworkExport }) => {
  const { availableExportValues, isLoading: isLoadingAvailableExportValues } =
    useAvailableExportValues(hasNetworkExport);
  const { availableActivities, isLoading: isLoadingAvailableActivityTypes } = useAvailableActivities();
  const isLoading = isLoadingAvailableExportValues && isLoadingAvailableActivityTypes;

  const defaultExportValue = availableExportValues?.[0];
  const [selectedType, setSelectedType] = useState(defaultExportValue?.key);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedActivityTypes, setSelectedActivityTypes] = useState([]);
  const [isSelectAllActivityTypes, setIsSelectAllActivityTypes] = useState(false);
  const onClearSearch = () => setSearchValue('');
  const onClose = useAction(hideModal);
  const onConfirm = useAction(exportData);
  const onConfirmError = error => dispatch => {
    dispatch(showModal(ModalError, { error }));
  };
  const filteredActivityTypes = filterEntityByPropAndSearchTerm(availableActivities, searchValue);
  const rangePickerProps = usePeriodForRangePicker();
  const [exportInternalUserActivities, setExportInternalUserActivities] = useState(false);

  useEffect(() => {
    setSelectedType(defaultExportValue?.key);
  }, [defaultExportValue]);

  useEffect(() => {
    const selectedActivities = availableActivities ? availableActivities.map(activity => activity.code) : [];
    setSelectedActivityTypes(selectedActivities);
    setIsSelectAllActivityTypes(true);
  }, [availableActivities]);

  useEffect(() => {
    if (selectedActivityTypes?.length === availableActivities?.length) {
      setIsSelectAllActivityTypes(true);
    } else {
      setIsSelectAllActivityTypes(false);
    }
  }, [selectedActivityTypes, availableActivities]);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    const period = { startDate: rangePickerProps.startDate, endDate: rangePickerProps.endDate };
    await onConfirm(
      selectedType,
      selectedActivityTypes,
      isSelectAllActivityTypes,
      period,
      exportInternalUserActivities
    );
  };

  const isActivityType = selectedType === EXPORT_ACTIVITY.key;
  const toggleActivityType = code => {
    const result = toggleValueInArray(selectedActivityTypes, code);
    setSelectedActivityTypes(result);
  };

  const onSelectAll = () => {
    const selectedActivities = availableActivities ? availableActivities.map(activity => activity.code) : [];
    setSelectedActivityTypes(selectedActivities);
  };

  const onDeselectAll = () => {
    setSelectedActivityTypes([]);
  };

  const confirmEnabled = isActivityType ? selectedActivityTypes.length > 0 && !rangePickerProps.isPeriodInvalid : true;

  return (
    <ProcessingConfirmModal
      onClose={onClose}
      onConfirm={handleConfirm}
      onConfirmSuccess={onClose}
      onConfirmError={onConfirmError}
      confirmEnabled={confirmEnabled}
      isLoading={isLoading}
      title={i18next.t('drive.import.modal.export.title')}
    >
      <ExportSelector
        selectedValue={selectedType}
        onChange={setSelectedType}
        availableExportValues={availableExportValues}
        isLoading={isSubmitting || isLoading}
      />
      {isActivityType && (
        <>
          <DatePickerWrapper {...rangePickerProps} />
          <div className={styles.optionRow}>
            <input
              type="checkbox"
              onChange={event => setExportInternalUserActivities(event?.target.checked)}
              name="exportIWDActivities"
              checked={exportInternalUserActivities}
              className={classNames('checkbox', styles.checkbox)}
              id="exportIWDActivities"
              data-testid={EXPORT_IWD_ACTIVITIES_DATA_TEST_ID}
            />
            <label htmlFor="exportIWDActivities">
              <BodyText1>{i18next.t('drive.import.modal.export.activity.includeInternal')}</BodyText1>
            </label>
          </div>
          <div className={styles.search}>
            <BodyText2 className={styles.label}>{i18next.t('drive.import.modal.export.typesOfActivity')}</BodyText2>
            <SearchInput
              onChange={setSearchValue}
              onClear={onClearSearch}
              value={searchValue}
              placeholder={i18next.t('search')}
              theme={SEARCH_INPUT_THEME_ALT}
            />
            <div className={styles.selectionButtons}>
              <ListSelection
                count={selectedActivityTypes.length}
                onDeselectAll={onDeselectAll}
                onSelectAll={onSelectAll}
                selectionCountLabel={i18next.t('display.guideline.list.selectionCount', {
                  count: selectedActivityTypes.length,
                })}
                isSelectDisabled={isSelectAllActivityTypes}
              />
            </div>
          </div>
          <ActivityTypeList
            activityTypes={filteredActivityTypes}
            selectedActivityTypes={selectedActivityTypes}
            onClick={toggleActivityType}
            isSelectAllActivityTypes={isSelectAllActivityTypes}
            searchTerm={searchValue}
          />
        </>
      )}
    </ProcessingConfirmModal>
  );
};

ExportModal.propTypes = {
  hasNetworkExport: PropTypes.bool,
};

ExportModal.defaultProps = {
  hasNetworkExport: false,
};

export default ExportModal;
