import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { registerField } from 'redux-form';

/**
 * Register the given fields on the given redux form
 */
export default function useReduxFormRegisterFields(form, fields) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (fields != null) {
      fields.forEach(field => dispatch(registerField(form, field, 'Field')));
    }
  }, [dispatch, form, fields]);
}
