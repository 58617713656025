import { useCallback, useState } from 'react';

type FocusedInput = 'startDate' | 'endDate' | null;

export default function useDateRangePicker() {
  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus = useCallback(() => setHasFocus(true), [setHasFocus]);
  const handleClose = useCallback(() => setHasFocus(false), [setHasFocus]);
  const handleFocusChange = (nextFocusedInput: FocusedInput) => {
    setFocusedInput(nextFocusedInput);
    if (handleFocus) {
      handleFocus();
    }
  };
  const handleOutsideRange = () => false;

  return {
    focusedInput,
    hasFocus,
    handleFocusChange,
    handleClose,
    handleOutsideRange,
  };
}
