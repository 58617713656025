import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import GuidelineCampaignFactory from 'domain/guideline/campaign/factory';
import GuidelineCampaign from 'domain/guideline/campaign';
import { CAMPAIGNS_QUERY_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import { useMemo } from 'react';
import array2map from 'lib/helpers/array2map';

export default function useCampaigns(options = {}) {
  const fetchCampaigns = useHttpGetRequest<GuidelineCampaign[]>(`/guidelines/campaigns`, {
    transformResponse: GuidelineCampaignFactory.createCollectionFromApiPayload,
  });
  const { data, isLoading, isError, error } = useHttpQuery([CAMPAIGNS_QUERY_CACHE_KEY], fetchCampaigns, options);
  const campaignsById: Record<string, GuidelineCampaign> = useMemo(() => {
    if (data) {
      return array2map(data, 'id');
    }
    return {};
  }, [data]);
  return { campaigns: data, campaignsById, isLoading, isError, error };
}
