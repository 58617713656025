import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyText2 } from 'app/common/typography';
import AutoComplete from 'app/common/components/autocomplete';
import TransferContentSelectUserInput from 'app/directory/user/sheet/view/components/modals/transferContent/selectUser/input';
import useSuggestions, {
  Suggestion,
} from 'app/directory/user/sheet/view/components/modals/transferContent/selectUser/useSuggestions';
import styles from './index.css';
import autocompleteTheme from './autocompleteTheme.css';

export default function TransferContentSelectUser({
  className,
  onChange,
  currentUserId,
}: {
  className?: string;
  onChange: (value: string) => void;
  currentUserId: string;
}) {
  const { t } = useTranslation();
  const { users } = useSuggestions(currentUserId);

  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const updateSuggestions = ({ value }: Record<any, any>) => {
    const newSuggestions = users.filter(s => s.label.toLowerCase().indexOf(value.trim().toLowerCase()) !== -1);
    setSuggestions(newSuggestions);
  };

  const clearValue = () => {
    setInputValue('');
    onChange('');
  };

  if (users.length < 1) {
    return null;
  }

  return (
    <div className={className}>
      <BodyText2 className={styles.title}>{t('directory.modals.transferContent.selectUser.title')}</BodyText2>
      <AutoComplete
        value={inputValue}
        name="selectedUser"
        placeholder={t('directory.modals.transferContent.selectUser.searchAuthor')}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setInputValue(event?.target?.value);
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={updateSuggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        onSuggestionSelected={(e: Event, selected: any) => {
          setInputValue(selected.suggestion.label);
          onChange(selected.suggestion.value);
        }}
        renderSuggestion={(suggestion: Suggestion) => {
          return suggestion.label;
        }}
        inputComponent={TransferContentSelectUserInput}
        theme={autocompleteTheme}
        onClear={clearValue}
      />
    </div>
  );
}
