import React from 'react';
import PropTypes from 'prop-types';
import validateAccessControlRight from 'app/common/propTypes/accessControl/right';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from 'app/common/components/route/authenticated';
import useDisplayAccessControl from 'app/common/hooks/accessControl/useDisplayAccessControl';

const DisplayAccessControl = ({
  children,
  right,
  loadingFallback,
  isAuthorized,
  notAuthorizedFallback,
  redirectTo,
  redirectDelay,
}) => {
  const { isLoading: renderLoadingFallback, canAccess } = useDisplayAccessControl(right);

  if (renderLoadingFallback) {
    return loadingFallback;
  }

  if (!canAccess || !isAuthorized) {
    if (redirectTo) {
      return (
        <Switch>
          <AuthenticatedRoute isAuthorized={false} redirectTo={redirectTo} redirectDelay={redirectDelay} />
        </Switch>
      );
    }

    return notAuthorizedFallback;
  }

  return children;
};

DisplayAccessControl.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/require-default-props
  right: validateAccessControlRight,
  loadingFallback: PropTypes.node,
  isAuthorized: PropTypes.bool,
  notAuthorizedFallback: PropTypes.node,
  redirectTo: PropTypes.string,
  redirectDelay: PropTypes.number,
};

DisplayAccessControl.defaultProps = {
  loadingFallback: null,
  isAuthorized: true,
  notAuthorizedFallback: null,
  redirectTo: null,
  redirectDelay: null,
};

export default DisplayAccessControl;
