/**
 * Notes about application routing and routing in general with
 * react-router v4.
 *
 * This file contains the main routing of the application.
 * It defines the namespaces for the different "domain modules" of the application
 *
 * Each domain modules can (and generally needs to) define its own sub routes
 * ex: vr/routes that contains all sub-routes for the vr backoffice part
 * It also allows each of those modules to use a different wrapper component. (~ App Shell)
 *
 * Take care to use a Switch component and put the routes in the right order in it
 * Switch allows to render ONLY THE FIRST matching route present in the switch
 * (if Switch is not used, it renders every route that matches the current path)
 * Since Switch renders the first route that matches, you need to take care to define the
 * more specific rules first. (ex : /myPage/subPage needs to go before /myPage)
 *
 * Lazy Loading Routes
 * webpack code splitting allows to create a bundle for each route
 * to do this, we need to use import() that returns a promise.
 * (stage-3 proposal)
 * react-loadable allows to do this quite easily with some nice features
 * https://medium.com/@thejameskyle/react-loadable-2674c59de178#.qui0rhtt0
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  PATH_NETWORK_INDEX,
  PATH_INDEX,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_SURVEY_REPORT_VISITS,
  PATH_DASHBOARD,
  PATH_DISCOVER,
  PATH_DETACH,
  PATH_SLIDESHOW,
} from 'config/routePaths';
import DisplayRoutes from 'app/display/routes';
import Dashboard from 'app/dashboard';
import Discover from 'app/discover';
import Slideshow from 'app/slideshow';
import NetworkRoutes from 'app/network/routes';
import AuthenticatedRoute from 'app/common/components/route/authenticated';
import Login from 'app/auth/login';
import Logout from 'app/auth/logout/components/logout';
import Detach from 'app/auth/logout/components/detach';

const AppRoutes = () => {
  return (
    <Switch>
      <Route path={PATH_LOGIN} component={Login} />
      <Route path={PATH_LOGOUT} component={Logout} exact />
      <Route path={PATH_DETACH} component={Detach} exact />
      <AuthenticatedRoute path={PATH_DASHBOARD} component={Dashboard} />
      <AuthenticatedRoute path={PATH_DISCOVER} component={Discover} />
      <AuthenticatedRoute path={PATH_SLIDESHOW} component={Slideshow} />
      <AuthenticatedRoute path={[PATH_NETWORK_INDEX, PATH_SURVEY_REPORT_VISITS]} component={NetworkRoutes} />
      <Redirect exact from={PATH_INDEX} to={PATH_DASHBOARD} />
      <AuthenticatedRoute path={PATH_INDEX} component={DisplayRoutes} />
    </Switch>
  );
};

export default AppRoutes;
