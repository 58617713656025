import React from 'react';
import { useSelector } from 'react-redux';

import AuthenticatedRoute from 'app/common/components/route/authenticated';
import isCurrentUserAllowedToImport from 'app/common/selectors/acl/isCurrentUserAllowedToImport';

const ImportRoute = ({ isAuthorized, path, component, forbiddenComponent, exact, strict, redirectTo }) => (
  <AuthenticatedRoute
    path={path}
    component={component}
    forbiddenComponent={forbiddenComponent}
    isAuthorized={useSelector(isCurrentUserAllowedToImport) && isAuthorized}
    redirectTo={redirectTo}
    exact={exact}
    strict={strict}
  />
);

ImportRoute.propTypes = {
  ...AuthenticatedRoute.propTypes,
};

ImportRoute.defaultProps = {
  ...AuthenticatedRoute.defaultProps,
};

export default ImportRoute;
