import notifyError from 'app/display/common/helpers/notifications/notifyError';
import i18next from 'i18next';

type WindowOptions = { target?: string; focus?: boolean };

const defaultWindowOptions = {
  target: '_blank',
  focus: true,
};

export default (options: WindowOptions = defaultWindowOptions) => {
  const customOptions = { ...defaultWindowOptions, ...options };
  return (url?: string | URL) => {
    const popup = window.open(url, customOptions.target);
    if (customOptions.focus) {
      if (popup) {
        popup.focus();
      } else {
        notifyError(i18next.t('toast.error.permission.popup'));
      }
    }
  };
};
