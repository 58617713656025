import { useSelector } from 'react-redux';
import hasCurrentUserDisplayAccess from 'app/common/user/selectors/hasCurrentUserDisplayAccess';
import useCurrentUserAclRight from 'app/common/hooks/useCurrentUserAclRight';
import useInstanceSettingsDiscoveryPage from 'app/common/hooks/useInstanceSettingsDiscoveryPage';

type CatalogAccess = {
  canAccess: boolean | null;
  discoveryPagesEnabled?: boolean;
};

export default function useCurrentUserCatalogAccess(): CatalogAccess {
  const hasDisplayAccess = useSelector(hasCurrentUserDisplayAccess);
  const { aclRight } = useCurrentUserAclRight('catalog.item.access');
  const { discoveryPagesEnabled } = useInstanceSettingsDiscoveryPage();
  return { canAccess: hasDisplayAccess && aclRight, discoveryPagesEnabled };
}
