import React from 'react';
import useReduxFormComponent from 'app/display/common/hooks/useReduxFormComponent';
import NotificationsTabForm from 'app/directory/user/sheet/common/components/form/notifications';
import {
  USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM,
  USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM_INITIAL_VALUES,
} from 'app/directory/user/sheet/common/constants';

type CreateUserNotificationsTabProps = {
  initialValues: Record<string, boolean>;
  testPhotoReportHref: string;
  testSurveyReportHref: string;
  userIdV4: string;
};

export default function CreateUserNotificationsTab({
  testPhotoReportHref,
  testSurveyReportHref,
  userIdV4,
}: CreateUserNotificationsTabProps) {
  const FormComponent = useReduxFormComponent(NotificationsTabForm, USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM, {
    testPhotoReportHref,
    testSurveyReportHref,
    userIdV4,
    destroyOnUnmount: false,
  });

  return <FormComponent initialValues={USER_SHEET_NOTIFICATION_SETTINGS_TAB_FORM_INITIAL_VALUES} />;
}
