import Period from 'domain/period';

export default class PeriodFactory {
  static createFromStringDatesIfNotNull(startDate?: string | null, endDate?: string | null): Period | null {
    if (startDate == null || endDate == null) {
      return null;
    }

    return PeriodFactory.createFromStringDates(startDate, endDate);
  }

  static createFromDatesIfNotNull(startDate?: Date | null, endDate?: Date | null): Period | null {
    if (startDate == null || endDate == null) {
      return null;
    }

    return PeriodFactory.createFromDates(startDate, endDate);
  }

  static createFromStringDates(startDate: string, endDate: string): Period {
    return PeriodFactory.createFromDates(new Date(startDate), new Date(endDate));
  }

  static createFromDates(startDate: Date, endDate: Date): Period {
    return new Period(startDate, endDate);
  }
}
