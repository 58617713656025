import Skeleton from 'app/common/components/skeleton';
import React from 'react';
import { BodyText2, HeadText3 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import styles from './index.css';

export const LICENSE_TAB_FORM_SKELETON_TESTID = 'LICENSE_TAB_FORM_SKELETON';

export default function LicenseTabFormSkeleton() {
  const { t } = useTranslation();
  return (
    <div className={styles.licenseForm} data-testid={LICENSE_TAB_FORM_SKELETON_TESTID}>
      <div className={styles.fieldWrapper}>
        <div className={styles.fieldLabel}>
          <BodyText2>{t('directory.userSheet.licenseTab.project.label')}</BodyText2>
        </div>
        <div>
          <Skeleton width={220} height={30} />
        </div>
      </div>
      <div className={styles.fieldWrapper}>
        <div className={styles.fieldLabel}>
          <BodyText2>{t('directory.userSheet.licenseTab.license.label')}</BodyText2>
        </div>
        <div className={styles.fieldInput}>
          <Skeleton width={220} height={30} />
        </div>
      </div>
      <hr className={styles.divider} />
      <HeadText3 className={styles.rolesLabel}>{t('directory.userSheet.licenseTab.roles.label')}</HeadText3>
      <div className={styles.roleWrapper}>
        <div className={styles.fieldLabel}>
          <BodyText2>{t('directory.userSheet.licenseTab.create.label')}</BodyText2>
        </div>
        <div>
          <Skeleton width={220} height={30} />
        </div>
      </div>
      <div className={styles.roleWrapper}>
        <div className={styles.fieldLabel}>
          <BodyText2>{t('directory.userSheet.licenseTab.execute.label')}</BodyText2>
        </div>
        <div>
          <Skeleton width={220} height={30} />
        </div>
      </div>
    </div>
  );
}
