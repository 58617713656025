import {
  ADD_INVENTORY_ITEMS,
  UPDATE_INVENTORY_ITEMS,
  REMOVE_INVENTORY_ITEMS,
  CLEAR_INVENTORY_ITEMS,
} from 'app/network/survey/report/actions/inventory/types';
import array2map from 'lib/helpers/array2map';

export const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_INVENTORY_ITEMS:
    case UPDATE_INVENTORY_ITEMS: {
      const inventoryItemsById = array2map(action.inventoryItems, 'id');
      return {
        ...state,
        ...inventoryItemsById,
      };
    }
    case REMOVE_INVENTORY_ITEMS: {
      const newState = {
        ...state,
      };
      action.inventoryItems.forEach(item => {
        delete newState[item.id];
      });
      return newState;
    }
    case CLEAR_INVENTORY_ITEMS:
      return {};
    default:
      return state;
  }
}
