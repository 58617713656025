import React from 'react';
import AccessGuidelineListIcon from 'assets/icons/store-redirect-list.svg';
import useDefaultGuidelineSearchUrl from 'app/network/store/sheet/tabs/guidelines/hooks/useDefaultGuidelineSearchUrl';
import useCurrentUserGuidelinesAccess from 'app/common/hooks/useCurrentUserGuidelinesAccess';
import styles from './index.css';

type AccessGuidelineListButtonProps = {
  storeId: string;
};

export const ACCESS_GUIDELINE_LIST_BUTTON_TESTID = 'accessGuidelineListButton';

export default function AccessGuidelineListButton({ storeId }: AccessGuidelineListButtonProps) {
  const searchUrl = useDefaultGuidelineSearchUrl(storeId);
  const { canAccess: isVisible } = useCurrentUserGuidelinesAccess();

  return isVisible ? (
    <a href={searchUrl} className={styles.accessGuidelineListButton} data-testid={ACCESS_GUIDELINE_LIST_BUTTON_TESTID}>
      <AccessGuidelineListIcon className={styles.button} />
    </a>
  ) : null;
}
