import PropTypes from 'prop-types';
import React from 'react';
import i18next from 'i18next';

import Modal from '..';
import styles from '../index.css';

class UnauthorizedModal extends React.PureComponent {
  static propTypes = {
    baseUrl: PropTypes.string.isRequired,
    onHideModal: PropTypes.func.isRequired,
  };

  renderFooter = () => {
    return (
      <a href={`${this.props.baseUrl}`} className={styles.buttonClose}>
        {i18next.t('login')}
      </a>
    );
  };

  render() {
    console.info(__filename, 'UnauthorizedModal::render()', this.props);
    return (
      <Modal
        onClose={this.props.onHideModal}
        title={i18next.t('popup.error.unauthorized.title')}
        footer={this.renderFooter}
      >
        <div>
          <p>{i18next.t('popup.error.unauthorized.message')}</p>
        </div>
      </Modal>
    );
  }
}

export { UnauthorizedModal };
export default UnauthorizedModal;
