import brandColorPalette, { BRAND_COLOR_PALETTE } from 'app/common/typography/colors/palettes/brand';
import defaultColorPalette, { DEFAULT_COLOR_PALETTE } from './default';
import altColorPalette, { ALT_COLOR_PALETTE } from './alt';
import linkColorPalette, { LINK_COLOR_PALETTE } from './link';
import contrastColorPalette, { CONTRAST_COLOR_PALETTE } from './contrast';
import errorColorPalette, { ERROR_COLOR_PALETTE } from './error';
import successColorPalette, { SUCCESS_COLOR_PALETTE } from './success';
import warningColorPalette, { WARNING_COLOR_PALETTE } from './warning';
import displayColorPalette, { DISPLAY_COLOR_PALETTE } from './display';
import displayAltColorPalette, { DISPLAY_ALT_COLOR_PALETTE } from './displayAlt';
import networkColorPalette, { NETWORK_COLOR_PALETTE } from './network';
import networkAltColorPalette, { NETWORK_ALT_COLOR_PALETTE } from './networkAlt';
import guidelineStatusColorPalette, { GUIDELINE_STATUS_COLOR_PALETTE } from './guideline';
import guidelineStatusAltColorPalette, { GUIDELINE_STATUS_ALT_COLOR_PALETTE } from './guidelineAlt';
import lastUpdateColorPalette, { LAST_UPDATE_COLOR_PALETTE } from './lastUpdate';

export default {
  [DEFAULT_COLOR_PALETTE]: defaultColorPalette,
  [ALT_COLOR_PALETTE]: altColorPalette,
  [LINK_COLOR_PALETTE]: linkColorPalette,
  [CONTRAST_COLOR_PALETTE]: contrastColorPalette,
  [ERROR_COLOR_PALETTE]: errorColorPalette,
  [SUCCESS_COLOR_PALETTE]: successColorPalette,
  [WARNING_COLOR_PALETTE]: warningColorPalette,
  [BRAND_COLOR_PALETTE]: brandColorPalette,
  [DISPLAY_COLOR_PALETTE]: displayColorPalette,
  [DISPLAY_ALT_COLOR_PALETTE]: displayAltColorPalette,
  [NETWORK_COLOR_PALETTE]: networkColorPalette,
  [NETWORK_ALT_COLOR_PALETTE]: networkAltColorPalette,
  [GUIDELINE_STATUS_COLOR_PALETTE]: guidelineStatusColorPalette,
  [GUIDELINE_STATUS_ALT_COLOR_PALETTE]: guidelineStatusAltColorPalette,
  [LAST_UPDATE_COLOR_PALETTE]: lastUpdateColorPalette,
};
