import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import i18next from 'i18next';

import styles from './reportLink.css';

class SendReportLink extends React.PureComponent {
  static propTypes = {
    error: PropTypes.instanceOf(Error).isRequired,
  };

  getEmailSubject() {
    return encodeURIComponent('IWD Webapp Issue');
  }

  getEmailBody() {
    const now = moment().utc().format();

    // TODO get email from api
    // const email = window.iwdConfig.credentials.email;
    const email = 'tmp@iwd.fr';

    return encodeURIComponent(
      `
Description:
Please explain what occured

Steps to reproduce:
Please explain what your were doing


user: ${email}
time (UTC): ${now}
URL: ${window.location.href}
code: ${this.props.error.code}
error: ${this.props.error.message}
stack: ${this.props.error.stack}
            `
    );
  }

  render() {
    const subject = this.getEmailSubject();
    const body = this.getEmailBody();

    return (
      <a className={styles.reportLink} href={`mailto:support@iwd.io?subject=${subject}&body=${body}`}>
        {i18next.t('popup.error.sendreportlink')}
      </a>
    );
  }
}

export default SendReportLink;
