import useHttpGetRequest from 'app/common/hooks/useHttpGetRequest';
import useHttpQuery from 'app/common/hooks/useHttpQuery';
import { STORE_TABS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';
import StoreTabsFactory from 'domain/store/storeTabs/factory';
import StoreTabs from 'domain/store/storeTabs';

export default function useStoreTabs() {
  const request = useHttpGetRequest<StoreTabs>('privates/storeTabs', {
    transformResponse: StoreTabsFactory.createStoreTabsBooleansFromApiPayload,
  });
  const { data, isLoading, isError, error } = useHttpQuery([STORE_TABS_CACHE_KEY], request, {
    throwOnUnhandledError: false,
  });
  return { storeTabs: data, isLoading, isError, error };
}
