import { PickProprietiesWithStringValues } from 'domain/utils';

export default function array2map<TypeToBeMapped>(
  arr: TypeToBeMapped[] | undefined | null,
  field: keyof PickProprietiesWithStringValues<TypeToBeMapped>
) {
  let map = {} as Record<keyof PickProprietiesWithStringValues<TypeToBeMapped>, TypeToBeMapped>;
  if (arr) {
    map = Object.fromEntries(arr.map(e => [e[field], e]));
  }
  return map;
}
