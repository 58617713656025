import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

const AppI18nextProvider = ({ children }) => <I18nextProvider i18n={i18next}>{children}</I18nextProvider>;

AppI18nextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppI18nextProvider;
