import React from 'react';
import { ALT_COLOR_PALETTE, BodyText1, HeadText5 } from 'app/common/typography';
import { useTranslation } from 'react-i18next';
import UncheckedNotification from 'assets/icons/close.svg';
import CheckedNotification from 'assets/icons/checkedbox.svg';
import useUser from 'app/common/hooks/useUser';
import Skeleton from 'app/common/components/skeleton';
import NotificationSettings from 'domain/user/notificationSettings';
import styles from './index.css';

export default function NotificationsRow({
  name,
  userId,
  children,
}: {
  name: string;
  userId: string;
  children?: React.ReactNode;
}) {
  const { t } = useTranslation();
  const { user, isLoading } = useUser(userId);

  if (isLoading) {
    return <Skeleton />;
  }

  const isChecked = user?.notificationSettings?.[name as keyof NotificationSettings];
  return (
    <div className={styles.settingRow}>
      {isChecked ? (
        <CheckedNotification className={styles.checked} />
      ) : (
        <UncheckedNotification className={styles.unChecked} />
      )}
      <div className={styles.label}>
        <HeadText5>{t(`directory.userSheet.notificationsTab.setting.${name}.label`)}</HeadText5>
        <BodyText1 className={styles.subText} palette={ALT_COLOR_PALETTE}>
          {t(`directory.userSheet.notificationsTab.setting.${name}.infoText`)}
        </BodyText1>
      </div>
      {children}
    </div>
  );
}
