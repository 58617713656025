/**
 * Check if a string contains an URI scheme.
 *
 * Useful to to detect if a link should be rendered using the Link component from react-router or
 * with a classic HTML link to go outside of the Router context.
 *
 * @param {String} value
 */
export default function hasScheme(value) {
  return value.indexOf('://') !== -1;
}
