import React from 'react';
import DocumentsLink from 'app/common/components/offCanvasPlatformMenu/links/documents';
import ReportsLink from 'app/common/components/offCanvasPlatformMenu/links/reports';
import SurveysLink from 'app/common/components/offCanvasPlatformMenu/links/surveys';
import LibraryLink from 'app/common/components/offCanvasPlatformMenu/links/library';
import StoresLink from 'app/common/components/offCanvasPlatformMenu/links/stores';
import CatalogLink from 'app/common/components/offCanvasPlatformMenu/links/catalog';
import GuidelinesLink from 'app/common/components/offCanvasPlatformMenu/links/guidelines';

import IwdPlatformLogo from 'app/common/components/platformMenu/logo/iwd';
import styles from './index.css';

const OffCanvasPlatformMenuLinks = () => {
  return (
    <div className={styles.links}>
      <IwdPlatformLogo className={styles.logo} />
      <CatalogLink />
      <GuidelinesLink />
      <StoresLink />
      <LibraryLink />
      <SurveysLink />
      <ReportsLink />
      <DocumentsLink />
    </div>
  );
};

export default OffCanvasPlatformMenuLinks;
