import uniqBy from 'lodash/uniqBy';
import useLicenseProvisioningData from 'app/directory/user/sheet/common/hooks/useLicenseProvisioningData';
import { LicenseProvisioningData } from 'domain/license/provisioning';
import { useMemo } from 'react';

type Project = {
  projectName: string;
  projectId: string;
  renewalDate?: Date;
};

type Result = {
  projects: Project[];
  isLoading: boolean;
  isError: boolean;
  error: unknown;
};

export default function useLicenseProjects(): Result {
  const { licenseProvisioningData, isLoading, isError, error } = useLicenseProvisioningData();

  const projects = useMemo(() => {
    if (!licenseProvisioningData) {
      return [];
    }

    return uniqBy(
      licenseProvisioningData?.map((project: LicenseProvisioningData) => ({
        projectId: project.projectId,
        projectName: project.projectName,
        renewalDate: new Date(project.renewalDate),
      })),
      'projectId'
    );
  }, [licenseProvisioningData]);

  return { projects, isLoading, isError, error };
}
