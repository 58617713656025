import React from 'react';
import NextPhoto from 'app/common/components/slideshow/navigation/nextPhoto';
import { useController, useLightboxProps, useNavigationState } from 'yet-another-react-lightbox';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import isFilmStripOpen from 'app/common/selectors/slideshow/isFilmStripOpen';
import isRightSidePanelOpen from 'app/common/selectors/slideshow/isRightSidePanelOpen';
import styles from './nextPhotoButton.css';

export const SLIDESHOW_INFORMATION_NEXTPHOTO_TESTID = 'slideshowNextPhotoButton';

export default function NextPhotoButton() {
  const { next } = useController();
  const { nextDisabled } = useNavigationState();
  const { animation } = useLightboxProps();
  const isPanelOpen = useSelector(isRightSidePanelOpen);
  const isStripOpen = useSelector(isFilmStripOpen);

  const onClick = () => {
    animation.swipe = 500;
    animation.fade = 500;
    next();
  };

  return (
    <button
      type="button"
      className={classNames('yarl__button', 'yarl__navigation_next', {
        [styles.infoPanelOpen]: isPanelOpen,
        [styles.filmStripOpen]: isStripOpen,
        [styles.InfoPanelAndFilmStripOpen]: isPanelOpen && isStripOpen,
      })}
      onClick={onClick}
      disabled={nextDisabled}
      data-testid={SLIDESHOW_INFORMATION_NEXTPHOTO_TESTID}
    >
      <NextPhoto />
    </button>
  );
}
