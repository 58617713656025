import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import {
  PATH_GUIDELINE_BROWSE,
  PATH_GUIDELINE_SEARCH,
  PATH_GUIDELINE_CREATE_2D,
  PATH_GUIDELINE_CREATE_3D,
  PATH_GUIDELINE_EDIT,
  PATH_GUIDELINE_OPEN,
  PATH_GUIDELINE_SHOW,
  PATH_GUIDELINE_CAMPAIGN_VIEW,
  PATH_GUIDELINE_CAMPAIGN_EDIT,
  PATH_GUIDELINE_MANAGE_DOCUMENT,
  PATH_GUIDELINE_DOCUMENT,
  PATH_DISCOVER,
} from 'config/routePaths';
import Spinner from 'app/common/components/spinner';
import catcher from 'app/common/helpers/importCatcher';
import NotFound from 'app/common/components/errors/notFound';
import Open from 'app/display/guideline/sheet/open';
import GuidelineDocumentSettings from 'app/display/guideline/document/settings';
import GuidelineDocumentView from 'app/display/guideline/document/view';
import DisplayRoute from 'app/display/common/components/route/display';
import useGuidelineRoutes from 'app/display/guideline/hooks/useGuidelineRoutes';
import { DISCOVER_GUIDELINES } from 'app/discover/constants/moduleNames';
import generatePathname from 'lib/helpers/router/generatePathname';
import AuthenticatedRoute from 'app/common/components/route/authenticated';
import AccessControl from 'app/common/components/accessControl';

const List = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-guideline-list" */ 'app/display/guideline/list/routes').catch(e => catcher(e)),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/guideline/list/routes'),
});

const Details = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-guideline-details" */ 'app/display/guideline/sheet/view').catch(e =>
      catcher(e)
    ),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/guideline/sheet/view'),
});

const Edit = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-guideline-details" */ 'app/display/guideline/sheet/edit').catch(e =>
      catcher(e)
    ),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/guideline/sheet/edit'),
});

const Create = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-guideline-create" */ 'app/display/guideline/sheet/create').catch(e =>
      catcher(e)
    ),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/guideline/sheet/create'),
});

const CampaignView = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-guideline-campaign-view" */ 'app/display/guideline/campaigns/view').catch(e =>
      catcher(e)
    ),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/guideline/campaigns/view'),
});

const CampaignEdit = Loadable({
  loader: () =>
    import(/* webpackChunkName: "display-guideline-campaign-edit" */ 'app/display/guideline/campaigns/edit').catch(e =>
      catcher(e)
    ),
  loading: Spinner,
  webpackRequireWeakId: () => require.resolveWeak('app/display/guideline/campaigns/edit'),
});

const GuidelineRoutes = () => {
  const { createGuideline2dAllowed, createGuideline3dAllowed, editGuidelineAllowed, manageCampaignAllowed } =
    useGuidelineRoutes();

  const guidelinesDiscoverURL = generatePathname(PATH_DISCOVER, {
    moduleName: DISCOVER_GUIDELINES,
  });

  return (
    <AccessControl right="guidelines.guideline.read" redirectTo={guidelinesDiscoverURL} redirectDelay={0}>
      <Switch>
        <DisplayRoute
          exact
          path={[PATH_GUIDELINE_BROWSE, PATH_GUIDELINE_SEARCH]}
          component={List}
          redirectTo={guidelinesDiscoverURL}
          redirectDelay={0}
        />
        <DisplayRoute
          path={PATH_GUIDELINE_CREATE_2D}
          component={Create}
          isAuthorized={createGuideline2dAllowed}
          redirectTo={PATH_GUIDELINE_BROWSE}
        />
        <DisplayRoute
          path={PATH_GUIDELINE_CREATE_3D}
          component={Create}
          isAuthorized={createGuideline3dAllowed}
          redirectTo={PATH_GUIDELINE_BROWSE}
        />
        <DisplayRoute
          path={PATH_GUIDELINE_EDIT}
          component={Edit}
          isAuthorized={editGuidelineAllowed}
          redirectTo={PATH_GUIDELINE_BROWSE}
        />
        <DisplayRoute path={PATH_GUIDELINE_OPEN} component={Open} />
        <DisplayRoute exact path={PATH_GUIDELINE_MANAGE_DOCUMENT} component={GuidelineDocumentSettings} />
        <AuthenticatedRoute exact path={PATH_GUIDELINE_DOCUMENT} component={GuidelineDocumentView} />
        <DisplayRoute
          exact
          path={PATH_GUIDELINE_SHOW}
          component={Details}
          redirectTo={guidelinesDiscoverURL}
          redirectDelay={0}
        />
        <DisplayRoute exact path={PATH_GUIDELINE_CAMPAIGN_VIEW} component={CampaignView} />
        <DisplayRoute
          path={PATH_GUIDELINE_CAMPAIGN_EDIT}
          component={CampaignEdit}
          isAuthorized={manageCampaignAllowed}
          redirectTo={PATH_GUIDELINE_BROWSE}
        />
        <DisplayRoute component={NotFound} />
      </Switch>
    </AccessControl>
  );
};

export default GuidelineRoutes;
