import React from 'react';
import PhotoTag from 'domain/photo/tag';
import Tag from 'app/common/components/slideshow/tagPhoto/modal/tag';
import { HeadText5 } from 'app/common/typography';
import { forEach } from 'lodash';
import styles from './tagList.css';

type TagListProps = {
  tagsByCategory: Record<string, PhotoTag[]>;
  onTagClick: (actionTag: PhotoTag) => void;
  selectedTags: string[];
  newTags: PhotoTag[];
};

export default function PhotoTagList({ tagsByCategory, onTagClick, selectedTags, newTags }: TagListProps) {
  const noCategory = 'noCategory';
  const areNewTags = newTags.length > 0;
  forEach(newTags, (newTag: PhotoTag) => {
    if (areNewTags && newTag.category !== undefined) {
      if (!tagsByCategory[newTag.category]) {
        tagsByCategory[newTag.category] = [];
      }
      tagsByCategory[newTag.category].push(newTag);
    } else {
      tagsByCategory[noCategory].push(newTag);
    }
  });

  return (
    <>
      {Object.keys(tagsByCategory).map(categoryName => {
        if (categoryName === noCategory) {
          return (
            <div className={styles.category} key={`photoTag-category-${categoryName}`}>
              {tagsByCategory[categoryName].map(tag => (
                <Tag
                  photoTag={tag}
                  isSelected={selectedTags.includes(tag.fullTagName)}
                  onClick={onTagClick}
                  key={`photoTag-${tag.fullTagName}`}
                />
              ))}
            </div>
          );
        }

        return (
          <div key={`photoTag-category-${categoryName}`} className={styles.category}>
            <HeadText5 className={styles.categoryName}> {categoryName}</HeadText5>
            {tagsByCategory[categoryName].map(tag => (
              <Tag
                photoTag={tag}
                isSelected={selectedTags.includes(tag.fullTagName)}
                onClick={onTagClick}
                key={`photoTag-${tag.fullTagName}`}
              />
            ))}
          </div>
        );
      })}
    </>
  );
}
