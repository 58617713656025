import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import { connect } from 'react-redux';

/**
 * HOC that add an event listener on the key down event for our forms.
 *
 * This HOC exists because the onChange event from redux-form is too heavy for low end PC / tablet.
 *
 * The onChange event from redux-form do many things whose update the values in the state and trigger the
 * sync validation.
 * As we removed the onChange event from all inputs, the only event that update the state and trigger sync validation
 * is onBlur.
 * The problem is that the onBlur event is not triggered when we submit the form with the Enter key.
 * As as a result, we have to manually update the state when the Enter key is pressed.
 *
 * This is what this HOC does.
 *
 * The form submission must be triggered from a standard html button of type submit or programmatically with the
 * redux-form action creator `submit(...)`.
 *
 */
export default function reduxFormChangeOnEnterKeyDecorator(ReduxFormComponent, formName) {
  if (formName === undefined) {
    throw new Error('Form name required');
  }

  const FormEnhancer = ({ onKeyDown, ...rest }) => {
    return (
      <div onKeyDown={onKeyDown}>
        <ReduxFormComponent {...rest} form={formName} />
      </div>
    );
  };

  FormEnhancer.propTypes = {
    onKeyDown: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = dispatch => {
    return {
      onKeyDown: e => {
        if (e.target.type !== 'textarea' && e.key === 'Enter' && e.shiftKey === false) {
          dispatch(change(formName, e.target.name, e.target.value));
        }
      },
    };
  };

  return connect(null, mapDispatchToProps)(FormEnhancer);
}
