import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLightboxState } from 'yet-another-react-lightbox';
import useUpdatePhotoCaption from 'app/network/common/hooks/useUpdatePhotoCaption';
import Section from 'app/common/components/slideshow/information/section';
import styles from 'app/common/components/forms/input.css';
import captionStyle from 'app/common/components/slideshow/information/content/caption.css';

export const FORM_CAPTION_INFORMATION_PANEL = 'slideshowTagPhotoButtonPanel';

const Caption = () => {
  const { currentSlide } = useLightboxState();
  const { t } = useTranslation();
  const updatePhotoCaption = useUpdatePhotoCaption(currentSlide?.id);

  const [value, setValue] = useState<string | undefined>(currentSlide?.caption ?? '');

  useEffect(() => {
    return setValue(currentSlide?.caption ?? '');
  }, [currentSlide?.id]);

  const handleBlur = () => {
    updatePhotoCaption(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      updatePhotoCaption(value);
    }
  };

  return (
    <Section title={t('slideshow.infoPanel.caption.title')}>
      <div className={captionStyle.captionContent}>
        <input
          id="caption"
          name="caption"
          type="text"
          placeholder={t('slideshow.infoPanel.caption.placeHolder')}
          value={value}
          onChange={(e: { target: { value: React.SetStateAction<string | undefined> } }) => setValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          className={styles.input}
        />
      </div>
    </Section>
  );
};

export default Caption;
