import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import useInstanceName from 'app/common/hooks/useInstanceName';

const TranslatedDocumentTitle = ({ title, params }) => {
  const { instance } = useInstanceName();
  const titleParams = { instance, ...params };

  return (
    <Helmet>
      <title>{i18next.t(title, titleParams)}</title>
    </Helmet>
  );
};

TranslatedDocumentTitle.propTypes = {
  title: PropTypes.string,
  params: PropTypes.shape({
    itemName: PropTypes.string,
    name: PropTypes.string,
    guidelineName: PropTypes.string,
  }),
};

TranslatedDocumentTitle.defaultProps = {
  title: '',
  params: null,
};

export default TranslatedDocumentTitle;
