import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form';
import styles from './selection.css';

const BlockSelection = ({
  children,
  className,
  inputClassName,
  input,
  meta,
  type,
  disabled,
  isCleanStyle,
  ...props
}) => {
  const containerClassSet = classNames(styles.blockSelection, className, {
    [styles.isChecked]: input.checked,
    [styles.isDisabled]: disabled,
    [styles.isCleanStyle]: isCleanStyle,
  });

  const inputClassSet = classNames(inputClassName, 'is-disableable', styles.input);
  const labelClassSet = classNames(styles.label, 'is-disableable');

  return (
    <div className={containerClassSet}>
      <label className={labelClassSet}>
        <input type={type} className={inputClassSet} {...input} {...props} disabled={disabled} />
        <span className={styles.text}>{children}</span>
      </label>
    </div>
  );
};

BlockSelection.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  className: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  isCleanStyle: PropTypes.bool,
};

BlockSelection.defaultProps = {
  id: null,
  className: null,
  inputClassName: null,
  disabled: false,
  isCleanStyle: false,
};

export default BlockSelection;
