import React from 'react';
import classNames from 'classnames';
import { displaySelectedPhotoId } from 'app/common/actions/slideshow';
import { useDispatch } from 'react-redux';
import { SlideImage, useLightboxState } from 'yet-another-react-lightbox';
import useImageLoader from 'app/common/hooks/useImageLoader';
import Thumbnail from 'app/common/components/thumbnail';
import Skeleton from 'app/common/components/skeleton';
import UnavailableImage from 'assets/images/failed-image.svg';
import styles from 'app/common/components/slideshow/filmStrip/image.css';

export const SLIDESHOW_SLIDE_TESTID = 'slide-';

const SlideshowFilmStripPhoto = ({ slide, slideIndex }: { slide: SlideImage; slideIndex: number }) => {
  const dispatch = useDispatch();
  const { currentIndex } = useLightboxState();
  const { isLoading, isError } = useImageLoader(slide.src);

  const ImgPlaceholder = isLoading ? Skeleton : UnavailableImage;
  const imgSrc = isError || isLoading ? null : slide.src;
  const isSelected = slideIndex === currentIndex;

  const slideClassName = classNames(styles.slideFrame, {
    [styles.isSelected]: isSelected,
  });

  const onClick = () => {
    dispatch(displaySelectedPhotoId(slide.id));
  };

  const handleRef = (node: HTMLDivElement) => {
    if (node && isSelected) {
      node.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  };

  return (
    <div
      ref={handleRef}
      id={`slide-${slide.id}`}
      className={slideClassName}
      onClick={onClick}
      data-testid={`${SLIDESHOW_SLIDE_TESTID}${slide.id}`}
    >
      <Thumbnail src={imgSrc} alt={slide.id} Placeholder={ImgPlaceholder} />
    </div>
  );
};

export default SlideshowFilmStripPhoto;
