import { Method } from 'axios';
import useAppDispatch from 'lib/hooks/useAppDispatch';
import RequestParams, { PostRequestOptions, RequestOptions } from 'lib/helpers/RequestParams';
import MiddlewaresManager, { Middleware } from 'lib/helpers/MiddlewaresManager';
import { query } from 'app/common/actions/api';

export interface HttpRequest<ResponseData = unknown, RequestData = unknown> {
  (data?: RequestData): Promise<ResponseData>;
  addMiddleware: (middleware: Middleware<RequestParams<RequestData>>) => void;
}

export type UseHttpRequestOptions = RequestOptions & {
  getHeaders?: boolean;
};
export type UseHttpPostRequestOptions = PostRequestOptions & {
  getHeaders?: boolean;
};

export default function useHttpRequest<ResponseData, RequestData = unknown>(
  url: string,
  method: Method,
  options: UseHttpRequestOptions = {}
): HttpRequest<ResponseData, RequestData> {
  const dispatch = useAppDispatch();
  const middlewaresManager = new MiddlewaresManager<RequestParams<RequestData>>();

  const request = function httpRequest(data?: RequestData): Promise<ResponseData> {
    const requestParams = new RequestParams(url, method, options, data);

    middlewaresManager.run(requestParams);

    return dispatch(query<ResponseData, RequestData>(requestParams.getUrl(), requestParams.buildQueryOptions()));
  };

  request.addMiddleware = function addMiddleware(middleware: Middleware<RequestParams<RequestData>>) {
    middlewaresManager.add(middleware);
  };

  return request;
}
