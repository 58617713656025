import React from 'react';
import useLicenseProjects from 'app/directory/user/sheet/common/hooks/useLicenseProjects';

export default function ProjectFieldOptions() {
  const { projects, isLoading } = useLicenseProjects();

  if (isLoading) {
    return null;
  }

  return (
    <>
      <option value="" />
      {projects?.map(project => (
        <option value={project.projectId} key={project.projectId}>
          {project.projectName}
        </option>
      ))}
    </>
  );
}
