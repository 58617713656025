import React from 'react';
import classNames from 'classnames';
import DropZone from 'app/display/common/components/dragDrop/dropZone';
import DragZone from 'app/display/common/components/dragDrop/dragZone';
import { DRAG_TYPE_PHOTO, DROP_TYPE_PHOTO } from 'app/display/common/constants/dragDrop';
import { FileLoaderFile } from '@iwd/iwd-fileloader';
import styles from './photo.css';

type PhotoUploaderModalPhotoProps = {
  file: FileLoaderFile;
  fileIndex: number;
  onChange(fileMovedIndex: number, newLocationIndex: number): void;
};

export default function PhotoUploaderModalPhoto({ file, fileIndex, onChange }: PhotoUploaderModalPhotoProps) {
  return (
    <>
      <div className={styles.photoContainer}>
        {fileIndex === 0 && (
          <DropZone
            acceptedDragTypes={[DRAG_TYPE_PHOTO]}
            dropPayload={{ type: DROP_TYPE_PHOTO, droppedElementId: fileIndex - 1 }}
          >
            <div
              className={classNames(styles.dropTarget, styles.firstDropTarget, {
                [styles.isDropping]: false,
              })}
            >
              <div className={styles.dropHighlight} />
            </div>
          </DropZone>
        )}
        <DragZone
          onDrop={(sourcePayload, targetPayload) => {
            onChange(sourcePayload.draggedElementId, targetPayload.droppedElementId);
          }}
          dragPayload={{
            type: DRAG_TYPE_PHOTO,
            draggedElementId: fileIndex,
          }}
        >
          <div
            className={classNames(styles.photo, {
              [styles.isDragged]: false,
            })}
          >
            <img src={file.dataURL} alt="" />
          </div>
        </DragZone>
        <DropZone
          acceptedDragTypes={[DRAG_TYPE_PHOTO]}
          dropPayload={{ type: DROP_TYPE_PHOTO, droppedElementId: fileIndex }}
        >
          <div
            className={classNames(styles.dropTarget, {
              [styles.isDropping]: false,
            })}
          >
            <div className={styles.dropHighlight} />
          </div>
        </DropZone>
      </div>
    </>
  );
}
