import React from 'react';
import ConfirmModal from 'app/display/common/components/modals/confirmModal';
import { hideModal } from 'app/common/actions/modals';
import useAction from 'app/display/common/hooks/useAction';
import { useTranslation } from 'react-i18next';
import useLegacyEvent from 'app/network/hooks/useLegacyEvent';
import {
  TOGGLE_STATUS_ACTIVATE,
  TOGGLE_STATUS_DEACTIVATE,
} from 'app/directory/user/list/components/toggleStatusButton';

export const ACTIVATE_USER_MODAL_LIST_TEST_ID = 'activateUserModalList';

export default function ToggleUserStatusModal({
  userIds,
  status,
  url,
  isWholeSearchSelected,
}: {
  userIds: string[];
  status: typeof TOGGLE_STATUS_ACTIVATE | typeof TOGGLE_STATUS_DEACTIVATE;
  url: string;
  isWholeSearchSelected: boolean;
}) {
  const { t } = useTranslation();
  const onClose = useAction(hideModal);
  const toggleUserStatus = useLegacyEvent('DIRECTORY_TOGGLE_USER_STATUS');
  const onConfirm = () => {
    toggleUserStatus({
      url,
      enable: status === TOGGLE_STATUS_ACTIVATE,
      userIds,
      isWholeSearchSelected,
    });
    onClose();
  };

  return (
    <ConfirmModal
      onClose={onClose}
      onConfirm={onConfirm}
      confirmEnabled={true}
      confirmLabel={t('button.confirm')}
      title={t(`directory.list.toggleStatusModal.${status}.title${isWholeSearchSelected ? '.wholeSearch' : ''}`, {
        count: userIds.length,
      })}
    >
      <div data-testid={ACTIVATE_USER_MODAL_LIST_TEST_ID}>
        {t(`directory.list.toggleStatusModal.${status}.infoText${isWholeSearchSelected ? '.wholeSearch' : ''}`, {
          count: userIds.length,
        })}
      </div>
    </ConfirmModal>
  );
}
