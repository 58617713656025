import React from 'react';
import { components, NoticeProps } from 'react-select';
import { BodyText1 } from 'app/common/typography';
import i18next from 'i18next';
import { OptionSearchDropDown } from 'app/display/common/helpers/searchDropDown/convertValuesToOptions';
import styles from './index.css';

const NoOptionsMessage = (props: NoticeProps<OptionSearchDropDown, true>) => {
  return (
    <components.NoOptionsMessage {...props}>
      <BodyText1 className={styles.noResults}>{i18next.t('display.common.form.noResults')}</BodyText1>
    </components.NoOptionsMessage>
  );
};

export default NoOptionsMessage;
