import React from 'react';
import IconWarning from 'assets/icons/warning.svg';
import { BodyText1 } from 'app/common/typography';
import { COLOR_ERROR } from 'app/common/style/colors';
import styles from './warning.css';

type WarningProps = {
  children: string;
};

const Warning = ({ children }: WarningProps) => (
  <>
    <IconWarning className={styles.iconWarning} />
    <BodyText1 on={COLOR_ERROR}>{children}</BodyText1>
  </>
);

export default Warning;
