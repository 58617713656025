import React from 'react';
import useReadAllUserNotifications from 'app/display/common/hooks/modals/userNotifications/useReadAllUserNotifications';
import { UserNotificationFilterCategory } from 'app/display/common/helpers/modals/userNotification/processNotificationsByCategory';
import noop from 'lib/helpers/noop';
import IconButton from 'app/display/common/components/iconButton';
import SettingsIcon from 'assets/icons/settings.svg';
import ReadStatusIcon from 'assets/icons/notification-read-status.svg';
import styles from './header.css';

export default function NotificationHeaderRight({
  selectedTab,
  hasUnRead,
}: {
  selectedTab: UserNotificationFilterCategory;
  hasUnRead: boolean;
}) {
  const onClickReadAll = useReadAllUserNotifications(selectedTab);
  const onClickSettings = noop;
  return (
    <div className={styles.header}>
      <IconButton
        Icon={ReadStatusIcon}
        onClick={onClickReadAll}
        isDisabled={!hasUnRead}
        width={30}
        height={30}
        className={styles.button}
      />
      <IconButton
        Icon={SettingsIcon}
        onClick={onClickSettings}
        isDisabled
        width={30}
        height={30}
        className={styles.button}
      />
    </div>
  );
}
