import { useTranslation } from 'react-i18next';
import useHttpPutRequest from 'app/common/hooks/useHttpPutRequest';
import { useDispatch, useSelector } from 'react-redux';
import updateElementInArray from 'app/display/common/helpers/userNotifications/updateElementInArray';
import redirectToNotificationPage from 'app/display/common/helpers/modals/userNotification/redirectToNotificationPage';
import notifyError from 'app/display/common/helpers/notifications/notifyError';
import { hideModal } from 'app/common/actions/modals';
import getAbsoluteUrl from 'app/common/selectors/getAbsoluteUrl';
import queryClient from 'lib/queryClient';
import UserNotification from 'domain/userNotifications';
import UserNotificationFactory from 'domain/userNotifications/factory';
import { USER_NOTIFICATIONS_CACHE_KEY } from 'app/common/constants/queryCacheKeys';

export default function useReadUserNotification(notification: UserNotification) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const markAsRead = useHttpPutRequest<UserNotification>(`/notifications/${notification.id}/read`, {
    transformResponse: UserNotificationFactory.createFromApiPayload,
  });
  const markAsReadErrorMsg = t('display.modal.notifications.markAsRead.error');
  const absoluteUrl = useSelector(getAbsoluteUrl);
  const redirectToNotification = redirectToNotificationPage(notification, absoluteUrl);

  return async () => {
    try {
      if (!notification.isRead()) {
        const updatedNotification = await markAsRead();
        queryClient
          .getInstance()
          .setQueryData([USER_NOTIFICATIONS_CACHE_KEY], updateElementInArray(updatedNotification));
      }
      redirectToNotification();
      dispatch(hideModal());
    } catch {
      notifyError(markAsReadErrorMsg);
    }
  };
}
